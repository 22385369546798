import { PROXY_CORE_PATH, formatDateToYYYYMMDD } from '~/shared/utils';
import { TMapper } from '~/types/api';

export const FETCH_CARDS = `${PROXY_CORE_PATH}v2.0/intercash_cards`;

export const CRUD_MESSAGES = {
  createSuccessful: 'Your card has been successfully created.',
  createMultipleSuccessful: 'Your cards have been successfully created.',
  deleteSuccessful: 'Your card has been successfully deleted.',
  deleteMultipleSuccessful: 'Your cards have been successfully deleted.',
  updateSuccessful: 'Your card has been successfully updated.',
  updateMultipleSuccessful: 'Your cards have been successfully updated.',
};

export const CARDS_BODY_MAPPER: TMapper = {
  issueId: {
    parser: (value?: string | null) => ({
      issueId: value === null ? undefined : value,
    }),
  },
  expiryDate: {
    parser: (date: string) => ({
      expiryDate: date ? formatDateToYYYYMMDD(new Date(date)) : null,
    }),
  },
};
