import { useContext } from 'react';

import { TSetRefreshFn } from '~/types/refetch';
import { ITab } from '~/types/tabs';

import { TabManagement as TabManagementContainer } from '../../../containers/TabManagement';
import { TTabsIds } from '../context/types';
import { userPageContext } from '../context/userPageContext';

import { IUserTabComponentProps } from './types';

interface ITabManagementProps {
  tabs: ITab<TTabsIds, IUserTabComponentProps>[];
  userId: number;
  userName: string;
  setRefetch: TSetRefreshFn;
}

export const TabManagement = ({
  tabs,
  userId,
  userName,
  setRefetch,
}: ITabManagementProps) => {
  const { dirtyTabs } = useContext(userPageContext);

  return (
    <TabManagementContainer
      componentProps={{ userId, userName, setRefetch }}
      dirtyTabs={dirtyTabs}
      tabs={tabs}
    />
  );
};
