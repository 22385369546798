import { isEqual } from 'lodash';

import { IStatePasswordManager } from '../types';

export function isEqualStates(state: IStatePasswordManager) {
  const { initial, data } = state;

  const keys = Object.keys(data) as (keyof IStatePasswordManager['data'])[];

  // eslint-disable-next-line no-unreachable-loop,no-restricted-syntax
  for (const key of keys) {
    if (key === 'authFlow') {
      const firstStepIsChanged = !isEqual(
        [...data[key].firstStep],
        [...initial[key].firstStep],
      );
      const secondStepsAreChanged = !isEqual(
        [...data[key].secondSteps],
        [...initial[key].secondSteps],
      );

      if (firstStepIsChanged || secondStepsAreChanged) {
        return false;
      }
    }

    return !isEqual(data[key], initial[key]);
  }

  return true;
}
