import { getTableId } from '~/shared/utils';

export const GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID = getTableId(
  'GlobalSummaryByAccounts',
);
export const GLOBAL_SUMMARY_BY_POSITION_TABLE_ID = getTableId(
  'GlobalSummaryByPosition',
);
export const GLOBAL_SUMMARY_TABLE_ID = getTableId('GlobalSummary');
export const GLOBAL_SUMMARY_CURRENCY_RISK = getTableId(
  'GlobalSummaryCurrencyRisk',
);

export const POLLING_INTERVAL = 5000;

export const NOT_NEED_COLUMNS = new Set([
  'accountId',
  'accountPurpose',
  'accountType',
  'leverageRate',
]);
export const CURRENCY_RISK_COLUMNS = ['convertedValue', 'currency', 'value'];
