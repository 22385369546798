import { includes } from 'lodash';
import { Notification } from 'react-ui-kit-exante';

import {
  DEFAULT_ACCOUNT_ACCESS,
  accountAccessTypes,
} from '~/api/accounts/constants';
import { AccountAccessType } from '~/api/accounts/types';
import { createServiceResponse } from '~/shared/utils';
import { IAccountPermissionByUsername } from '~/types/accountPermissions';
import {
  IAtpPermissionsResponse,
  TAtpPermissions,
} from '~/types/accountPermissions/atpPermissions';
import { TDefaultAccountAccess } from '~/types/accountPermissions/defaultAccountAccess';
import { IServiceResponse, IServiceResponseRTK } from '~/types/api';
import { TFormHandlerNew } from '~/types/form';

import { IFormData } from '../types';

import { IPrevPermissionsData } from './types';

export const getDefaultValues = (
  accountPermissions: IAccountPermissionByUsername[],
  defaultAccountAccess: TDefaultAccountAccess,
): IFormData => ({
  defaultAccountAccess: defaultAccountAccess || 'blocked',
  permissions: accountPermissions,
});

interface ICreateHandlersMapArguments {
  userName: string;
  previousPermissions: IAccountPermissionByUsername[];
  setPreviousPermissions: IPrevPermissionsData['setPreviousPermissions'];
  atpPermissions: TAtpPermissions | null;
  updatePermissions: (props: {
    userId: string;
    permissions: IAccountPermissionByUsername[];
    previousPermissions: IAccountPermissionByUsername[];
    setPreviousPermissions: IPrevPermissionsData['setPreviousPermissions'];
  }) => Promise<
    | IServiceResponse<IAccountPermissionByUsername[]>
    | IServiceResponseRTK<IAccountPermissionByUsername[]>
  >;
  updateAtpPermissions: (props: {
    userId: string;
    atpPermissions: IAtpPermissionsResponse;
  }) => Promise<
    | IServiceResponse<TDefaultAccountAccess>
    | IServiceResponseRTK<TDefaultAccountAccess>
  >;
}

export const createHandlersMap = ({
  userName,
  previousPermissions,
  setPreviousPermissions,
  atpPermissions,
  updatePermissions,
  updateAtpPermissions,
}: ICreateHandlersMapArguments): TFormHandlerNew<IFormData> | null => {
  return {
    permissions: async (permissions) => {
      const response = await updatePermissions({
        userId: userName,
        permissions,
        previousPermissions,
        setPreviousPermissions,
      });

      if (!(`error` in response)) {
        Notification.success({
          title: 'Account permissions are successfully updated',
        });
      }

      return response;
    },
    defaultAccountAccess: async (defaultAccountAccess) => {
      if (!atpPermissions) {
        return Promise.resolve(
          createServiceResponse({
            data: defaultAccountAccess,
            messages: { error: 'Atp permissions empty' },
          }),
        );
      }

      const response = await updateAtpPermissions({
        userId: userName,
        atpPermissions: {
          ...atpPermissions,
          superuser: isAccountAccessType(defaultAccountAccess)
            ? defaultAccountAccess
            : DEFAULT_ACCOUNT_ACCESS,
        },
      });

      if (!(`error` in response)) {
        Notification.success({
          title: 'Default account access is successfully updated.',
        });
      }

      return response;
    },
  };
};

function isAccountAccessType(value: string | null): value is AccountAccessType {
  return includes(accountAccessTypes, value);
}
