import { TMarginMode } from '~/types/margin';

export const marginColorMap: Record<TMarginMode, Record<string, string>> = {
  normal: {
    positionMargin: '#409F6B',
    totalOrderMargin: '#7FBE9B',
    totalBlockedMargin: '#D2D6D7',
  },
  overrun: {
    positionMargin: '#ce2100',
    totalOrderMargin: '#fd5a3b',
    totalBlockedMargin: '#ffc1b5',
  },
};
export const OVERRUN_MODE_VALUE = 105; // 105%
