import * as Yup from 'yup';

const commissionsSchema = Yup.object()
  .shape({
    name: Yup.string(),
    nonFilledName: Yup.string(),
    value: Yup.string(),
    type: Yup.string(),
    min: Yup.string(),
    max: Yup.string(),
    commission: Yup.string(),
    isFree: Yup.boolean(),
  })
  .test(
    'non-trading-commission-validation',
    (
      {
        commission,
        isFree,
        max,
        min,
        name,
        nonFilledName,
        type,
        value,
        interval,
      },
      { createError, path },
    ) => {
      // If commission is empty initially and name wasn't changed, then it's valid
      if (
        nonFilledName &&
        name &&
        nonFilledName === name &&
        !commission &&
        !isFree &&
        !max &&
        !min &&
        !type &&
        !value &&
        !interval
      ) {
        return true;
      }

      // in this case it's new commission without any filled fields
      if (
        !commission &&
        !isFree &&
        !max &&
        !min &&
        !type &&
        !value &&
        !nonFilledName &&
        !name &&
        !interval
      ) {
        return true;
      }

      const errors = [];

      if (type === 'regular' && !interval) {
        errors.push(
          createError({
            message: 'Interval is required',
            path: `${path}.interval`,
          }),
        );
      }

      if (!name?.trim()) {
        errors.push(
          createError({
            message: 'Commission name is required',
            path: `${path}.name`,
          }),
        );
      }

      if (!value?.trim()) {
        errors.push(
          createError({
            message: 'Value is required',
            path: `${path}.value`,
          }),
        );
      }

      if (!type?.trim()) {
        errors.push(
          createError({
            message: 'Type is required',
            path: `${path}.type`,
          }),
        );
      }

      if (!isFree && min?.trim() && !Number.isFinite(+min)) {
        errors.push(
          createError({
            message: 'Min should be a number',
            path: `${path}.min`,
          }),
        );
      }

      if (!isFree && max?.trim() && !Number.isFinite(+max)) {
        errors.push(
          createError({
            message: 'Max should be a number',
            path: `${path}.max`,
          }),
        );
      }

      if (!isFree && !commission?.trim()) {
        errors.push(
          createError({
            message: 'Commission is required',
            path: `${path}.commission`,
          }),
        );
      }

      if (!isFree && commission?.trim() && !Number.isFinite(+commission)) {
        errors.push(
          createError({
            message: 'Commission should be a number',
            path: `${path}.commission`,
          }),
        );
      }

      if (errors.length) {
        return new Yup.ValidationError(errors);
      }

      return true;
    },
  );

export const formSchema = Yup.object({
  commissions: Yup.array().of(commissionsSchema),
});
