import { PROXY_CORE_PATH } from '~/shared/utils';
import { LimitSetResponse } from '~/types/limits';

import { limitsApi } from './limitsApi';

export const { useAddGroupLimitsMutation } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    addGroupLimits: builder.mutation<LimitSetResponse, string>({
      query: (name) => ({
        url: `${PROXY_CORE_PATH}v2.0/limits_set`,
        method: 'POST',
        data: {
          name,
        },
      }),
    }),
  }),
});
