import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { Panel, Table, useTableData } from 'react-ui-kit-exante';

import { useLazyGetAccountReferralsQuery } from '~/api';
import { NO_DATA_HEIGHT } from '~/constants';
import { usePickUserPermissions } from '~/hooks';
import { getTableId } from '~/shared/utils';
import { TAccountReferrals } from '~/types/accounts';

import { columns } from './columns';

const PAGE_SIZE = 10;
const PAGE_SIZES = [10, 20, 50];

interface IReferralsProps {
  accountId: string;
}

export const ReferralsContainer = ({ accountId }: IReferralsProps) => {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: rebate settings for account',
  ]);
  const [fetchReferrals] = useLazyGetAccountReferralsQuery();

  const hasWriteSettingsAccess =
    userPermissions['Account info'].write &&
    userPermissions['Account info: rebate settings for account'].write;

  const getReferrals = useCallback(async () => {
    const response = await fetchReferrals({ accountId });

    if ('error' in response || !response.data) {
      return null;
    }
    return response.data;
  }, [accountId, fetchReferrals]);

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getReferrals },
    }),
    [getReferrals],
  );

  const { data, isLoading } = useTableData<TAccountReferrals | null>(
    tableDataArgs,
  );

  const tableId = getTableId('Referrals');

  if (!userPermissions['Account info: rebate settings for account'].read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled: !hasWriteSettingsAccess,
      })}
      disableBodyPaddings
      title="Referrals"
    >
      <Table
        columns={columns}
        data={data || []}
        disableSortBy
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        noDataHeight={NO_DATA_HEIGHT}
        pageSize={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        saveViewParamsAfterLeave
        showScrollbar
        tableId={tableId}
      />
    </Panel>
  );
};
