export const getStylePropValue = (
  querySelector: string,
  styleProperty: string,
): number => {
  const el = document.querySelector(querySelector);

  if (!el) {
    return 0;
  }

  const elStyles = window.getComputedStyle(el);
  return parseInt(elStyles.getPropertyValue(styleProperty), 10);
};

export const getElementHeight = (querySelector: string): number => {
  const el = document.querySelector(querySelector);

  return el?.clientHeight || 0;
};
