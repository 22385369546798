import { mixed } from 'yup';

export const oneOf = <T extends readonly unknown[]>(
  options: T,
  defaultValue: T[number],
  required?: boolean,
) => {
  const mixedSchema = mixed<(typeof options)[number]>()
    .oneOf([...options])
    .default(defaultValue);

  return required ? mixedSchema.required() : mixedSchema.optional();
};
