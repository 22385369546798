import { TAccessRightsActions, TFormAccessRights } from '~/types/users';

import { IAccessRightsMap, IUserAccessRights } from './types';

export function userAccessRightsMapper(permissions: IUserAccessRights[]) {
  return permissions.reduce<IAccessRightsMap>(
    (acc, curr: IUserAccessRights) => {
      acc[curr.operation] = curr.actions;

      return acc;
    },
    {},
  );
}

export const prepareAccessRightsForSend = (accessRights: TFormAccessRights) => {
  const payloadValues: IUserAccessRights[] = [];
  Object.keys(accessRights).forEach((operation) => {
    const actions: TAccessRightsActions = {};
    if (!accessRights[operation].actions.disabledFields?.read) {
      actions.read = accessRights[operation].values.read;
    }
    if (!accessRights[operation].actions.disabledFields?.write) {
      actions.write = accessRights[operation].values.write;
    }
    payloadValues.push({
      operation,
      actions,
    });
  });

  return payloadValues;
};
