import { ICurrency } from '~/types/currencies';

import { LOCAL_STORAGE_KEY } from './constants';
import { PreparedCurrency } from './types';

export const createCurrencyChangeEvent = () => {
  const event = new CustomEvent('onChangeCurrency', {
    detail: {
      getCurrentCurrency: () => {
        try {
          const currency = localStorage.getItem(LOCAL_STORAGE_KEY);
          const parsed: PreparedCurrency[number] = currency
            ? JSON.parse(currency)
            : {};

          return parsed;
        } catch {
          return {};
        }
      },
    },
  });

  return event;
};

export const prepareCurrency = (currencies: ICurrency[]) => {
  const filtered = currencies.reduce<PreparedCurrency>((acc, item) => {
    if (item.isNav) {
      acc.push({
        ...item,
        id: item.currency,
      });
    }

    return acc;
  }, []);

  const sorted = filtered.sort((a, b) => {
    const diff = b.weight - a.weight;
    if (diff !== 0) {
      return diff;
    }

    return a.currency.localeCompare(b.currency);
  });

  return sorted;
};
