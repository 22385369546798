import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetExanteCounterpartiesOptionsQuery } from '~/api';
import { useGetOperationTypesQuery } from '~/api/types/types.api';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { selectCurrenciesOptionsByWeight } from '~/store/currencies';

export function useOptions() {
  const currenciesOptions = useSelector(selectCurrenciesOptionsByWeight);

  const { data: externalCounterpartyOptions = [] } =
    useGetExanteCounterpartiesOptionsQuery();

  const { data: operationTypeOptions } = useGetOperationTypesQuery(
    {
      withSystemOnly: false,
    },
    {
      selectFromResult: ({ data = { values: [] } }) => {
        return {
          data: transformVariantsToSelectOptions(data.values, {
            shouldSortAlphabetically: true,
            capitalized: true,
          }),
        };
      },
    },
  );

  return useMemo(
    () => ({
      currencies: currenciesOptions,
      externalCounterparty: externalCounterpartyOptions,
      operationType: operationTypeOptions,
      transactionType: [
        { value: 'deposit', label: 'Deposit' },
        { value: 'withdraw', label: 'Withdraw' },
      ],
    }),
    [currenciesOptions, externalCounterpartyOptions, operationTypeOptions],
  );
}
