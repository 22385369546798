import { AxiosResponse } from 'axios';
import { pick } from 'lodash';

import { is403GetError, displayErrorNotification } from '~/shared/utils';

import {
  IEditedUserPermission,
  INewUserPermission,
  TUsersPermissionResponse,
} from './types';
import { UsersPermissionRepository } from './usersPermission.repository';

export class UsersPermissionService {
  public async resolveUsersPermission(accountId: string) {
    try {
      const { data } = await UsersPermissionRepository.fetchUsersPermission(
        accountId,
      );

      return data;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  private async editUsers(editedUsersPermission: IEditedUserPermission[]) {
    const getUserDataRequests = editedUsersPermission.map(
      async (accountUser) => {
        try {
          const response = await UsersPermissionRepository.fetchUsersPermission(
            accountUser.accountId,
          );
          if ('data' in response) {
            const userData = response.data.find(
              (user) => user.accountId === accountUser.accountId,
            );
            if (userData) {
              return {
                ...accountUser,
                username: accountUser.userId,
                userId: userData.id,
              };
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            if (!is403GetError(error)) {
              displayErrorNotification(error);
            }
          }
        }
        return null;
      },
    );

    const enrichedUsersData = await Promise.all(getUserDataRequests);

    const updateUserRequests = enrichedUsersData.reduce<
      Promise<AxiosResponse<TUsersPermissionResponse[], unknown>>[]
    >((acc, item) => {
      const dataForRequest = pick(item, [
        'accountId',
        'overrideAccountStatus',
        'status',
        'id',
      ]);

      if (dataForRequest.id) {
        acc.push(
          UsersPermissionRepository.updateUserPermission(dataForRequest),
        );
      }

      return acc;
    }, []);

    await Promise.all(updateUserRequests);

    return editedUsersPermission;
  }

  private async addUsers(newUsersPermission: INewUserPermission[]) {
    const preparedNewItems = newUsersPermission.map((item) =>
      pick(item, ['accountId', 'overrideAccountStatus', 'status', 'userId']),
    );

    if (preparedNewItems.length) {
      await UsersPermissionRepository.addUserPermission(preparedNewItems);
      return this.resolveUsersPermission(newUsersPermission[0].accountId);
    }

    return [];
  }

  public async updateUsersPermission({
    editedUsersPermission,
    newUsersPermission,
  }: {
    editedUsersPermission: IEditedUserPermission[];
    newUsersPermission: INewUserPermission[];
  }) {
    try {
      const [updatedEditedItems = [], newItems = []] = await Promise.all([
        this.editUsers(editedUsersPermission),
        this.addUsers(newUsersPermission),
      ]);
      return {
        newItems,
        editedUsersPermission: updatedEditedItems,
      };
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  public async removeUserAccount(userAccountId: string) {
    try {
      await UsersPermissionRepository.removeUserPermission(userAccountId);
      return true;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return false;
    }
  }
}
