import { apiRequest, paramsTransformer } from '~/shared/utils';
import { TParams } from '~/types/api';
import { ICard, TEditableCard } from '~/types/cardsManagement';

import { CARDS_BODY_MAPPER, FETCH_CARDS } from './constants';
import { getCardModificationAPI } from './endpoints';

export class CardsManagementRepository {
  public static fetchCards(accountId: string, params?: TParams) {
    const preparedParams = { ...params, accountId };

    return apiRequest<ICard[]>({ url: FETCH_CARDS, params: preparedParams });
  }

  public static createCard(accountId: string, body: TEditableCard) {
    const preparedBody = paramsTransformer({
      params: body,
      mapper: CARDS_BODY_MAPPER,
    });

    return apiRequest({
      url: FETCH_CARDS,
      method: 'POST',
      data: { ...preparedBody, accountId },
    });
  }

  public static editCard(
    accountId: string,
    cardId: number,
    body: TEditableCard,
  ) {
    const preparedBody = paramsTransformer({
      params: body,
      mapper: CARDS_BODY_MAPPER,
    });

    return apiRequest({
      url: getCardModificationAPI(cardId),
      method: 'POST',
      data: { ...preparedBody, accountId },
    });
  }

  public static deleteCard(cardId: number) {
    return apiRequest({
      url: getCardModificationAPI(cardId),
      method: 'DELETE',
    });
  }
}
