import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TAddButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'> & {
  label?: string;
};

export const AddButton = ({ label, ...props }: TAddButtonProps) => {
  return (
    <IconButton
      {...props}
      iconName="AddIcon"
      iconColor="action"
      label={label || 'Add'}
      type="button"
      iconSize={24}
    />
  );
};
