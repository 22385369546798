import { ReactNode, useCallback, useMemo, useState } from 'react';

import { useDirtyTabs } from '~/hooks';
import { useTabsContext } from '~/hooks/useTabs/useTabs';
import { IRefreshActiveTabQuery } from '~/types/refetch';

import { DEFAULT_DIRTY_TABS_STATE, tabsIds } from './constants';
import { IUserPageContext, TTabsIds } from './types';
import { UserPageContextProvider } from './userPageContext';

export const UserPageContextManager = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { dirtyTabs, setDirtyTabs } = useDirtyTabs<TTabsIds>(
    DEFAULT_DIRTY_TABS_STATE,
  );

  const [activeTab] = useTabsContext();
  const [refreshQueries, setRefreshQueries] = useState<
    Record<string, IRefreshActiveTabQuery>
  >({});

  const activeTabName = tabsIds[Number(activeTab)];

  const handleSetRefreshFn = useCallback(
    (key: string, refreshFn: IRefreshActiveTabQuery) => {
      setRefreshQueries((prev) => ({
        ...prev,
        [key]: refreshFn,
      }));
    },
    [],
  );

  const contextValue = useMemo<IUserPageContext>(
    () => ({
      dirtyTabs,
      setDirtyTabs,
      activeTabName,
      refreshActiveTabQuery: refreshQueries?.[activeTabName] || {},
      setRefreshFn: handleSetRefreshFn,
    }),
    [
      activeTabName,
      dirtyTabs,
      handleSetRefreshFn,
      refreshQueries,
      setDirtyTabs,
    ],
  );

  return (
    <UserPageContextProvider value={contextValue}>
      {children}
    </UserPageContextProvider>
  );
};
