export const ACCOUNT_PERMISSIONS_FIELDS_BY_USERNAME = [
  'id',
  'accountId',
  'status',
  'overrideAccountStatus',
];

export const ACCOUNT_PERMISSIONS_FIELDS_BY_ACCOUNT_ID = [
  'id',
  'userId',
  'status',
  'overrideAccountStatus',
];
