import { useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { useGetCoreUserQuery, useGetUserAccessRightsQuery } from '~/api';
import { useGetAccountPermissionDataQuery } from '~/api/accountPermissions';
import { servicesService } from '~/resources';
import { useGetServicesQuery } from '~/store/user';
import { IUseQueryReturn } from '~/types/rtkQuery';
import { IAccessRights } from '~/types/users';

import { buildServicesState } from './helpers';
import { IUserPageDataReturn } from './types';

// refetchOnMountOrArgChange is workaround for update data after react-router navigation.
// good solution is to update queryState after data updating,
// but I can't do this because of confusing data structure
export const useAccessRightsData = (
  userId: number,
  userName: string,
): IUserPageDataReturn => {
  const userQuery = useGetCoreUserQuery({ id: userId });
  const user = userQuery.data;

  const accessRightsQuery = useGetUserAccessRightsQuery<
    IUseQueryReturn<IAccessRights>
  >(userName, { skip: !userName, refetchOnMountOrArgChange: true });

  const servicesQuery = useGetServicesQuery(
    { userId },
    { refetchOnMountOrArgChange: true },
  );

  const fetchUserServicesParams = useMemo(() => {
    return {
      onFetch: () => servicesService.getUserServices(userId),
    };
  }, [userId]);

  const {
    fetchData: fetchUserServices,
    data: userServices,
    isLoading: loadingUserServices,
  } = useData(fetchUserServicesParams);

  const accountPermissionsQuery = useGetAccountPermissionDataQuery(
    { userId: userName },
    { refetchOnMountOrArgChange: true },
  );

  const accountTypes = user?.info.accountTypes || [];
  const legalEntities = user?.info.legalEntities || [];
  const brandingPermission = user?.info.brandingPermission || [];

  const modifiedServices = userServices
    ? buildServicesState(
        user,
        servicesQuery.data,
        accountPermissionsQuery.data,
        userServices,
      )
    : null;

  const isAccessRightsLoading =
    accessRightsQuery.isLoading ||
    accessRightsQuery.isFetching ||
    accountPermissionsQuery.isFetching;
  const isServicesLoading = servicesQuery.isLoading || servicesQuery.isFetching;

  const isDataLoading = isAccessRightsLoading || isServicesLoading;
  const isDataFetching =
    accessRightsQuery.isFetching ||
    accountPermissionsQuery.isFetching ||
    servicesQuery.isFetching;

  const hasAccessRightsData = Boolean(accessRightsQuery.data);
  const hasServicesData = Boolean(servicesQuery.data);

  const userServicesQuery = useMemo(() => {
    return {
      refetch: fetchUserServices,
      isFetching: loadingUserServices,
    };
  }, [fetchUserServices, loadingUserServices]);

  useEffect(() => {
    fetchUserServices();
  }, [fetchUserServices]);

  return {
    queries: {
      accessRightsQuery,
      servicesQuery,
      accountPermissionsQuery,
      userQuery,
      userServicesQuery,
    },
    isDataLoading,
    isDataFetching,
    dataAvailabilityState: {
      hasAccessRightsData,
      hasServicesData,
    },
    data: {
      accountTypes,
      legalEntities,
      brandingPermission,
      services: modifiedServices,
      userServices,
    },
  };
};
