import { apiRequest, FETCH_PROXY_AUTH_DB } from '~/shared/utils';

export class SwaggerService {
  public async resolveSwaggerToken(sessionId: string): Promise<string | null> {
    try {
      const resp = await apiRequest({
        url: `${FETCH_PROXY_AUTH_DB}1.0/auth/session/${sessionId}/token`,
        data: { service: 'backoffice' },
        method: 'POST',
      });
      return resp?.data?.token;
    } catch (error) {
      return null;
    }
  }
}
