import { useState } from 'react';
import { get } from 'react-hook-form';
import { Checkbox, Tooltip } from 'react-ui-kit-exante';

import { useTimer } from '~/hooks';

import {
  NOT_ALLOWED_TO_CHANGE_MSG,
  SHOW_WARNING_TIME,
  tooltipStyles,
} from '../../constants';
import { useRiskArrayContext } from '../../context/RiskArrayTreeContext';
import { toExpandedTreePath } from '../../helpers';
import { ITreeNode } from '../../types';

interface IParentCheckBoxCellProps {
  name: string;
  path: string;
  subRows: ITreeNode[];
  isOption?: boolean;
}

export const ParentCheckboxCell = ({
  name,
  path,
  subRows,
  isOption,
}: IParentCheckBoxCellProps) => {
  const {
    getValue,
    setValue,
    addRequestToUpdate,
    removeRequestToUpdate,
    getOriginalCache,
  } = useRiskArrayContext();
  const { startWithDelay } = useTimer();
  const [showWarning, setShowWarning] = useState(false);

  const fullPath = `${toExpandedTreePath(path)}.${name}`;
  const subRowsIndexes = subRows.map(({ index, id }) => ({ index, id }));
  const originalCache = getOriginalCache();

  const allChecked = subRowsIndexes.every(({ index }) =>
    getValue({ name, path: index }),
  );

  const handleChange = () => {
    if (isOption) {
      setShowWarning(true);
      startWithDelay(() => setShowWarning(false), SHOW_WARNING_TIME);
      return;
    }

    subRowsIndexes.forEach(({ index, id }, i) => {
      const newValue = !allChecked;
      setValue({ name, path: index, value: newValue });

      const originalValue = get(originalCache, fullPath);
      if (originalValue !== newValue) {
        addRequestToUpdate({
          id,
          name,
          path: `${path}.${i}`,
          value: newValue,
        });
      } else {
        removeRequestToUpdate({ name, id, path });
      }
    });
  };

  return (
    <Tooltip
      title={NOT_ALLOWED_TO_CHANGE_MSG}
      sx={tooltipStyles}
      placement="top"
      open={showWarning}
    >
      <Checkbox onChange={handleChange} checked={allChecked} />
    </Tooltip>
  );
};
