import { Link } from 'react-ui-kit-exante';

import { swaggerService } from '~/resources';
import { getApiDomain, getSessionIdFromCookie } from '~/shared/utils';

export const SwaggerPage = () => {
  const sessionId = getSessionIdFromCookie() || '';

  const clickBOHandler = async () => {
    const token = await swaggerService.resolveSwaggerToken(sessionId);
    if (token) {
      const apiDomain = getApiDomain('BO_SWAGGER');

      window.open(
        `https://${apiDomain}/api/v2.0/setcookie?token=${token}`,
        '_blank',
      );
      const newTab = window.open();
      setTimeout(
        // eslint-disable-next-line no-return-assign
        () => {
          if (newTab?.location) {
            newTab.location.href = `https:/${apiDomain}/api-docs/swagger/index.html`;
          }
        },
        1000,
      );
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link component="button" onClick={clickBOHandler}>
      Open BOCore swagger in browser
    </Link>
  );
};
