/**
 * Formats a minimum and maximum value as a currency string.
 *
 * @param {string | null} currency - The currency code (e.g., "EUR") to format the values. If null, no currency symbol is used.
 * @param {string} min - The minimum value in string format. It's formatted as a currency if the currency code is provided.
 * @param {string} max - The maximum value in string format. Similar to `min`, it's formatted as a currency if the currency code is provided.
 * @returns {string} A string representing the formatted min and/or max values. Depending on the provided parameters, the return value might include currency symbols and is constructed according to the specified rules: including both min and max values, only min, only max, or neither, with or without a currency symbol.
 *
 * @example
 * // Formats with both currency and min/max values
 * console.log(getMinMax("EUR", "10", "75"));
 * // Expected output: "min €10 / max €75"
 *
 * @example
 * // Formats with only currency and min value
 * console.log(getMinMax("EUR", "10", null));
 * // Expected output: "min €10"
 *
 * @example
 * // Formats with no currency, but with min and max values
 * console.log(getMinMax(null, "10", "75"));
 * // Expected output: "min 10 / max 75"
 *
 * @example
 * // Formats with only min value, no currency
 * console.log(getMinMax(null, "10", null));
 * // Expected output: "min 10"
 */
export function getMinMax(
  currency: string | null,
  min?: string,
  max?: string,
): string {
  if (!min && !max) {
    return '';
  }

  let result = '';
  let minFormatted = '';
  let maxFormatted = '';

  if (currency) {
    const formatOptions = {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    };
    const formatter = new Intl.NumberFormat('en-IE', formatOptions);

    if (min) {
      minFormatted = formatter.format(Number(min));
    }

    if (max) {
      maxFormatted = formatter.format(Number(max));
    }
  } else {
    if (min) {
      minFormatted = min;
    }
    if (max) {
      maxFormatted = max;
    }
  }

  if (minFormatted) {
    result += `min ${minFormatted}`;
  }

  if (maxFormatted) {
    result += result ? ` / max ${maxFormatted}` : `max ${maxFormatted}`;
  }

  return result;
}
