import Big from 'big.js';

import {
  CommissionRulesAsArray,
  CommissionRulesAsObject,
} from '~/types/commissions';

/**
 * Regrouping a rules object
 *
 * @param {Object} rules - The object to be transformed.
 * @returns {Object} - The transformed object with 'type' as keys and array of rules.
 *
 * @example
 * // Example input object
 * const input = {
 *     "rebate": {
 *         "rate": "0.7",
 *         "rule": "percent",
 *         "type": "rebate"
 *     },
 *     "rebate3": {
 *         "rate": "0.9",
 *         "rule": "fixed",
 *         "type": "rebate"
 *     },
 *     "exchange": {
 *         "rate": "0.6",
 *         "rule": "fixed",
 *         "type": "exchange"
 *     },
 *     "internal": {
 *         "rate": "0.8",
 *         "rule": "fixed",
 *         "type": "internal"
 *     }
 * };
 *
 * const rulesStructure = convertCommissionsRulesToArray(input);
 * console.log(rulesStructure);
 * // Output object:
 * const output = {
 *     "rebate": [
 *         {
 *             "rate": "7",
 *             "rule": "percent",
 *             "name": "rebate"
 *         },
 *        {
 *             "rate": "0.9",
 *             "rule": "fixed",
 *             "name": "rebate3"
 *         }
 *     ],
 *    "exchange": [
 *         {
 *             "rate": "0.6",
 *             "rule": "fixed",
 *             "name": "exchange"
 *         },
 *     ],
 *     "internal": [
 *         {
 *             "rate": "0.8",
 *             "rule": "fixed",
 *             "name": "internal"
 *         },
 *     ],
 * };
 */
export function convertCommissionsRulesToArray(
  rules: CommissionRulesAsObject,
): CommissionRulesAsArray {
  const result: CommissionRulesAsArray = {
    internal: [],
    rebate: [],
    exchange: [],
  };

  Object.entries(rules).forEach(([name, { type, rule, rate }]) => {
    result[type].push({
      rule,
      rate: rule === 'percent' ? new Big(rate).times(100).toString() : rate,
      name,
    });
  });

  return result;
}
