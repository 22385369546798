import { styled } from 'react-ui-kit-exante';

export const StyledWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTitle = styled('h3')(({ theme }) => ({
  fontSize: '20px',
  color: theme?.color.typo.primary,
}));
