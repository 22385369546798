import { createContext } from 'react';

import { EMPTY_FUNCTION } from '~/constants';
import { DEFAULT_REFRESH_QUERY_STATE } from '~/constants/refreshQuery';

import { DEFAULT_DIRTY_TABS_STATE } from './constants';
import { IClientPageContext } from './types';

export const defaultValues: IClientPageContext = {
  clientAccountsSummary: {
    filters: {
      date: new Date(),
      setDate: EMPTY_FUNCTION,
      resetDate: EMPTY_FUNCTION,
    },
  },
  dirtyTabs: DEFAULT_DIRTY_TABS_STATE,
  setDirtyTabs: EMPTY_FUNCTION,
  refreshActiveTabQuery: DEFAULT_REFRESH_QUERY_STATE,
  setRefreshFn: EMPTY_FUNCTION,
  activeTabName: '',
};

export const clientPageContext = createContext(defaultValues);
export const { Provider: ClientPageContextProvider } = clientPageContext;
