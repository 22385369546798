import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '~/hooks';
import {
  selectCurrentLimitSet,
  selectDataForResetFormAfterSuccessSubmit,
  setDataForResetGlobalCashForm,
  updateGlobalCashFormState,
} from '~/store/limits';
import { selectEntity } from '~/store/treeStructure';

import {
  StyledForm,
  StyledWrapper,
  StyledFormInput,
} from './GlobalCashForm.styled';
import { GLOBAL_CASH_VALIDATION_SCHEMA } from './validationSchema';

export const GlobalCashForm: React.FC = () => {
  const dispatch = useDispatch();
  const [
    shouldResetFormAfterChangeEntity,
    setShouldResetFormAfterChangeEntity,
  ] = useState(false);

  const { negativeGlobalCashLimit, positiveGlobalCashLimit } =
    useSelector(selectCurrentLimitSet) || {};
  const dataForResetFormAfterSuccessSubmit = useSelector(
    selectDataForResetFormAfterSuccessSubmit,
  );
  const entity = useSelector(selectEntity);

  const prevNegativeGlobalCashLimit = usePrevious(negativeGlobalCashLimit);
  const prevPositiveGlobalCashLimit = usePrevious(positiveGlobalCashLimit);
  const prevEntity = usePrevious(entity);

  const formInstance = useForm({
    defaultValues: {
      negativeGlobalCashLimit: negativeGlobalCashLimit || '',
      positiveGlobalCashLimit: positiveGlobalCashLimit || '',
    },
    resolver: yupResolver(GLOBAL_CASH_VALIDATION_SCHEMA),
    mode: 'all',
  });

  const {
    formState: { isDirty, isValid, dirtyFields },
    watch,
    reset,
  } = formInstance;

  const watchNegativeGlobalCashLimit = watch('negativeGlobalCashLimit');
  const watchPositiveGlobalCashLimit = watch('positiveGlobalCashLimit');

  // update store state on form change
  useEffect(() => {
    dispatch(
      updateGlobalCashFormState({
        formValues: {
          negativeGlobalCashLimit: watchNegativeGlobalCashLimit,
          positiveGlobalCashLimit: watchPositiveGlobalCashLimit,
        },
        isDirty,
        isValid,
      }),
    );
  }, [
    dispatch,
    isDirty,
    isValid,
    watchNegativeGlobalCashLimit,
    watchPositiveGlobalCashLimit,
  ]);

  // reset form after successful submit
  useEffect(() => {
    if (dataForResetFormAfterSuccessSubmit) {
      reset({
        negativeGlobalCashLimit:
          dataForResetFormAfterSuccessSubmit.negativeGlobalCashLimit || '',
        positiveGlobalCashLimit:
          dataForResetFormAfterSuccessSubmit.positiveGlobalCashLimit || '',
      });

      dispatch(setDataForResetGlobalCashForm(null));
    }
  }, [
    dispatch,
    reset,
    watchNegativeGlobalCashLimit,
    watchPositiveGlobalCashLimit,
    dataForResetFormAfterSuccessSubmit,
  ]);

  useEffect(() => {
    if (prevEntity !== entity) {
      setShouldResetFormAfterChangeEntity(true);
    }
  }, [entity, prevEntity]);

  useEffect(() => {
    if (
      prevNegativeGlobalCashLimit !== negativeGlobalCashLimit ||
      prevPositiveGlobalCashLimit !== positiveGlobalCashLimit
    ) {
      reset({
        negativeGlobalCashLimit: negativeGlobalCashLimit || '',
        positiveGlobalCashLimit: positiveGlobalCashLimit || '',
      });
    }
  }, [
    negativeGlobalCashLimit,
    positiveGlobalCashLimit,
    prevNegativeGlobalCashLimit,
    prevPositiveGlobalCashLimit,
    reset,
  ]);

  useEffect(() => {
    if (shouldResetFormAfterChangeEntity) {
      reset({
        negativeGlobalCashLimit: negativeGlobalCashLimit || '',
        positiveGlobalCashLimit: positiveGlobalCashLimit || '',
      });

      setShouldResetFormAfterChangeEntity(false);
    }
  }, [
    reset,
    negativeGlobalCashLimit,
    positiveGlobalCashLimit,
    shouldResetFormAfterChangeEntity,
  ]);

  return (
    <FormProvider {...formInstance}>
      <StyledForm>
        <StyledWrapper>
          <StyledFormInput
            isDirty={!!dirtyFields.negativeGlobalCashLimit}
            isEmptyDefaultValue={!negativeGlobalCashLimit}
            InputLabelProps={{ shrink: true }}
            label="Negative global cash limit"
            name="negativeGlobalCashLimit"
            type="number"
          />

          <StyledFormInput
            isDirty={!!dirtyFields.positiveGlobalCashLimit}
            isEmptyDefaultValue={!positiveGlobalCashLimit}
            InputLabelProps={{ shrink: true }}
            label="Positive global cash limit"
            name="positiveGlobalCashLimit"
            type="number"
          />
        </StyledWrapper>
      </StyledForm>
    </FormProvider>
  );
};
