import { FilterValue, ICellValue } from 'react-ui-kit-exante';

import { fromExponential } from '~/shared/utils/fromExponential';
import { IIntermonthSpreadMargin } from '~/types/dictionary';

export const getColumns = (
  currenciesOptions: string[],
  onFilter: (col: string, value: FilterValue) => void,
  onRemove: (col: string) => void,
) => [
  {
    Header: 'Underlying',
    accessor: 'underlying',
    onFilter,
    onRemove,
  },
  {
    Header: 'Сurrency',
    accessor: 'currency',
    disableFilters: true,
    editParams: {
      editable: true,
      inputType: 'select',
      options: currenciesOptions,
    },
  },
  {
    Header: 'Last front month',
    accessor: 'lastFrontMonth',
    disableFilters: true,
    editParams: {
      editable: true,
    },

    disableSortBy: true,
  },
  {
    Header: 'Front Front Charge',
    accessor: 'frontFrontCharge',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.frontFrontCharge),
  },
  {
    Header: 'Front Back Charge',
    accessor: 'frontBackCharge',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.frontBackCharge),
  },
  {
    Header: 'Back Back Charge',
    accessor: 'backBackCharge',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.backBackCharge),
  },
  {
    Header: 'Short Option Minimum',
    accessor: 'shortOptionMinimum',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.shortOptionMinimum),
  },
  {
    Header: 'Long Option Minimum',
    accessor: 'longOptionMinimum',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.longOptionMinimum),
  },
  {
    Header: 'Extreme Leverage Rate',
    accessor: 'extremeLeverageRate',
    disableFilters: true,
    editParams: {
      editable: true,
    },
    Cell: ({ row: { values } }: ICellValue<IIntermonthSpreadMargin>) =>
      fromExponential(values?.extremeLeverageRate),
  },
];
