import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

interface IGetAdditionalFilters {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export function getAdditionalFilters({
  onFilter,
  onRemove,
}: IGetAdditionalFilters): TAdditionalFilter<Record<string, unknown>>[] {
  return [
    {
      Header: 'Archived',
      accessor: 'archived',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
  ];
}

export const getDefaultFilters = () => ({ archived: false });
