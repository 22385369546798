import { pickBy } from 'lodash';
import qs from 'qs';

/**
 * Serializes an object into a query string, removing keys with empty string values.
 *
 * This function uses `pickBy` from `lodash` to filter out any keys with empty string values
 * from the provided `params` object. It then serializes the filtered object into a query string
 * using `qs.stringify`, with arrays formatted using commas and null values skipped.
 *
 * @param {Record<string, unknown>} params - The parameters to serialize into a query string.
 * @returns {string} - The serialized query string.
 */
export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = pickBy(params, (value) => value !== '');

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}
