import { useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { NO_DATA_HEIGHT } from '~/constants';
import { useCurrency } from '~/hooks';
import { getTableId } from '~/shared/utils';
import { getFooterPropsWithTotal } from '~/shared/utils/table';
import { IBlockedFunds } from '~/types/accounts/metrics';

import { StyledWrapper } from '../MarginStructure.styled';

import { DISPLAYED_COLUMN_KEYS, getColumns } from './columns';

interface ILockedAssetsProps {
  data: IBlockedFunds[];
  isLoading: boolean;
}

const tableId = getTableId('BlockedFunds');

export const BlockedFunds = ({ data, isLoading }: ILockedAssetsProps) => {
  const currency = useCurrency();
  const columns = useMemo(() => getColumns(currency), [currency]);

  return (
    <StyledWrapper>
      <Table
        columns={columns}
        data={data}
        displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
        getFooterProps={getFooterPropsWithTotal}
        isFlexLayout
        isLoading={isLoading}
        noDataHeight={NO_DATA_HEIGHT}
        saveViewParamsAfterLeave
        showTableInfo
        tableId={tableId}
      />
    </StyledWrapper>
  );
};
