import { createApi } from '@reduxjs/toolkit/query/react';
import { omitBy, isUndefined, get } from 'lodash';
import { MutableRefObject } from 'react';

import {
  PROXY_CORE_PATH,
  baseQueryHandler,
  createServiceResponse,
  getArrayDifference,
  paramsTransformer,
} from '~/shared/utils';
import { IDataWithPagination, IServiceResponse, TParams } from '~/types/api';
import { IOption } from '~/types/form';
import {
  ICoreFormUser,
  ICoreUser,
  ICreateUserPayload,
  IFormUserClient,
  IGeneralPermissions,
  IUser,
  IUserAuthflow,
  IUserClient,
  IUserGroupSettings,
  IUserToken,
  IUsersState,
} from '~/types/users';

import {
  CREATE_USER,
  FETCH_MASTER_USERS,
  FETCH_SUB_USERS,
  FETCH_USER_BRANDINGS,
  FETCH_USER_CLIENTS,
  FETCH_USER_CURRENT_LEGAL_ENTITIES,
  FETCH_USERS_LIST,
  FETCH_USERS,
  getUserAuthflowEndpoint,
  getUserByIdAPI,
  getUserPermissionsEndpoint,
  getUserPermissionsSetsEndpoint,
  getUserTokensEndpoint,
} from './endpoints';
import { usersMapper } from './helpers';
import { USERS_PARAMS_TRANSFORMER } from './users.api.constants';
import {
  coreFormUserMapper,
  transformFormUserClientsToOriginal,
  transformFormUserToCoreUser,
} from './users.api.mappers';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['UserBrandings', 'User', 'UserClients'],
  endpoints: (builder) => ({
    searchByUsers: builder.query<
      {
        total: number;
        canceled?: boolean;
        nextSkip: number | null;
        options: Array<IOption>;
      },
      {
        skip: number | null;
        search: string;
        limit?: number;
        archived?: boolean;
      }
    >({
      queryFn: async (
        { skip, search, limit = 100, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        if (skip === null) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
            },
          };
        }

        const queryFields = ['username'];
        const response = await fetchWithBaseQuery({
          url: FETCH_USERS_LIST,
          params: omitBy(
            {
              fields: queryFields,
              limit,
              skip,
              username: search,
              ...restParams,
            },
            isUndefined,
          ),
          requestOptions: {},
        });

        const users = usersMapper(response.data);

        return {
          data: {
            // TODO: total now is required bu Autocomplete in react-ui-kit
            total: -1,
            nextSkip: limit === users.length ? skip + limit : null,
            options: users.map(({ username }) => ({
              label: username,
              value: username,
            })),
          },
        };
      },
    }),
    addUserToken: builder.mutation<
      IUserToken,
      { userId: number; addedToken: IUserToken }
    >({
      async queryFn({ userId, addedToken }, _, __, fetchWithBaseQuery) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId),
          method: 'POST',
          data: addedToken,
        });

        return result;
      },
    }),
    changeUserToken: builder.mutation<
      IUserToken,
      {
        userId: number;
        changedTokenId: number;
        changedToken: Omit<IUserToken, 'id'>;
      }
    >({
      async queryFn(
        { userId, changedTokenId, changedToken },
        _,
        __,
        fetchWithBaseQuery,
      ) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId, changedTokenId),
          method: 'PUT',
          data: changedToken,
        });

        return result;
      },
    }),
    deleteUserToken: builder.mutation({
      async queryFn({ userId, deletedTokenId }, _, __, fetchWithBaseQuery) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId, deletedTokenId),
          method: 'DELETE',
        });

        return result;
      },
    }),
    getUserBrandings: builder.query<string[], void>({
      query: () => ({
        url: FETCH_USER_BRANDINGS,
      }),
      providesTags: ['UserBrandings'],
    }),
    getUsers: builder.query<IUsersState, TParams>({
      query: (params) => ({
        url: FETCH_USERS,
        params: paramsTransformer({
          params,
          mapper: USERS_PARAMS_TRANSFORMER,
        }),
        ignoreForbiddenError: true,
      }),
      transformResponse: (response: IDataWithPagination<IUser[]>) => {
        return {
          users: usersMapper(response.data),
          pagination: response.pagination,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({ id }) => ({ type: 'User' as const, id })),
              'User',
            ]
          : ['User'],
    }),
    getCoreUser: builder.query<
      ICoreFormUser,
      { id: number | string; params?: TParams }
    >({
      query: ({ id, params = {} }) => ({
        url: getUserByIdAPI(id),
        ignoreForbiddenError: true,
        params,
      }),
      transformResponse: (response: ICoreUser) => {
        return coreFormUserMapper(response);
      },
      providesTags: (response) => {
        return [{ type: 'User', id: response?.id }];
      },
    }),
    updateUser: builder.mutation<
      IServiceResponse<ICoreFormUser>,
      { id: number; data: ICoreFormUser }
    >({
      query: ({ id, data }) => ({
        url: getUserByIdAPI(id),
        method: 'PUT',
        data: transformFormUserToCoreUser(data).info,
      }),
      transformResponse: (response) => {
        return createServiceResponse({
          data: coreFormUserMapper(response),
          messages: { success: 'User is successfully updated.' },
        });
      },
      invalidatesTags: (_result, _error, args) => {
        return [{ type: 'User', id: args.id }];
      },
    }),
    updateGeneralPermissions: builder.mutation<
      IServiceResponse<IGeneralPermissions>,
      { userId: number; generalPermissions: IGeneralPermissions }
    >({
      query: ({ userId, generalPermissions }) => ({
        url: getUserByIdAPI(userId),
        method: 'PUT',
        data: generalPermissions,
      }),
      transformResponse: (response) => {
        return createServiceResponse({
          data: {
            legalEntities: response.info.legalEntities,
            accountTypes: response.info.accountTypes,
            brandingPermission: response.info.brandingPermission,
          },
          messages: { success: 'User is successfully updated.' },
        });
      },
    }),
    createUser: builder.mutation<ICoreUser, { data: ICreateUserPayload }>({
      query: ({ data }) => ({
        url: CREATE_USER,
        method: 'POST',
        data: {
          ...data,
          branding: data.branding === '' ? null : data.branding,
        },
      }),
    }),
    getCoreUserClients: builder.query<IFormUserClient[], { userName: string }>({
      query: ({ userName }) => ({
        url: FETCH_USER_CLIENTS,
        params: { userId: userName },
        ignoreForbiddenError: true,
      }),
    }),
    getUserClients: builder.query<IFormUserClient[], { userName: string }>({
      queryFn: async ({ userName }, _, __, fetchWithBaseQuery) => {
        const userClients = await fetchWithBaseQuery({
          url: FETCH_USER_CLIENTS,
          params: { userId: userName },
          ignoreForbiddenError: true,
        });

        if (userClients.error) {
          return { error: userClients.error };
        }

        const responses = await Promise.all(
          userClients.data.map(({ clientId }: IUserClient) =>
            fetchWithBaseQuery({
              url: `${PROXY_CORE_PATH}v2.0/clients/${clientId}`,
              ignoreForbiddenError: true,
            }),
          ),
        ).then((response) =>
          response.map<IFormUserClient | null>((coreClient, index) => {
            if (!coreClient) {
              return null;
            }

            const name = coreClient.data.name.trim();
            const currentUserClient: IUserClient = get(userClients.data, index);

            return {
              ...currentUserClient,
              clientId: {
                value: currentUserClient.clientId,
                label: name
                  ? `${currentUserClient.clientId} – ${name}`
                  : currentUserClient.clientId,
              },
            };
          }),
        );

        const filteredData: IFormUserClient[] = [];

        responses.forEach((t) => {
          if (t) {
            filteredData.push(t);
          }
        });

        return { data: filteredData };
      },
      providesTags: (_, __, args) => [
        { type: 'UserClients' as const, id: args.userName },
        'UserClients',
      ],
    }),

    updateUserClients: builder.mutation<
      IServiceResponse<IFormUserClient[]>,
      {
        userClients: IFormUserClient[];
        previousUserClients: MutableRefObject<IFormUserClient[]>;
      }
    >({
      queryFn: async (
        { userClients, previousUserClients },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const messages: { success: string[]; error: string[] } = {
          success: [],
          error: [],
        };
        const extractedPreviousUserClients = transformFormUserClientsToOriginal(
          previousUserClients.current,
        );
        const extractedUserClients: IUserClient[] =
          transformFormUserClientsToOriginal(userClients);

        const { deletedElements, editedElements, createdElements } =
          getArrayDifference(
            extractedPreviousUserClients,
            extractedUserClients,
            'clientId',
          );

        const deletedPromises = Promise.allSettled(
          deletedElements.map((item) =>
            fetchWithBaseQuery({
              url: FETCH_USER_CLIENTS,
              method: 'DELETE',
              params: { userId: item.userId, clientId: item.clientId },
            }),
          ),
        ).then((responses) => {
          responses.forEach((res, index) => {
            const currentUserClient = deletedElements[index].clientId;

            if (res.status === 'fulfilled') {
              messages.success.push(
                `User client with id ${currentUserClient} successfully deleted.`,
              );
            }

            if (res.status === 'rejected') {
              messages.error.push(
                `User client with id ${currentUserClient} deleting failed ${res.reason.messages}`,
              );
            }
          });
        });

        const postUserData = editedElements.concat(createdElements);

        const updatePromises = postUserData.length
          ? fetchWithBaseQuery({
              url: FETCH_USER_CLIENTS,
              method: 'POST',
              data: postUserData,
            }).then((res) => {
              if (!('error' in res)) {
                messages.success.push('User clients are successfully updated.');
              }
            })
          : null;

        await Promise.all([deletedPromises, updatePromises]);

        // it is ok because it is react ref
        // eslint-disable-next-line no-param-reassign
        previousUserClients.current = userClients;

        return {
          data: createServiceResponse({
            data: userClients,
            messages,
          }),
        };
      },

      invalidatesTags: (_, __, args) => [
        { type: 'UserClients' as const, id: args.userClients[0].userId },
        'UserClients',
      ],
    }),
    getUserGroupSettings: builder.query<
      IUserGroupSettings,
      { userName: string }
    >({
      query: ({ userName }) => ({
        url: getUserPermissionsSetsEndpoint(),
        ignoreForbiddenError: true,
        params: { userId: userName },
      }),
      transformResponse: (response) => {
        return {
          permissionsSetId: response[0]?.permissionsSetId || null,
        };
      },
    }),
    updateUserGroupSettings: builder.mutation<
      IServiceResponse<{
        permissionsSetId: number;
      }>,
      {
        userName: string;
        permissionsSetId: number | null;
      }
    >({
      queryFn: async (
        { userName, permissionsSetId },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        let result = null;
        const { data: permissionsSetsResponse } = await fetchWithBaseQuery({
          params: { userId: userName },
          url: getUserPermissionsSetsEndpoint(),
        });

        if (permissionsSetsResponse.length && permissionsSetId === null) {
          await fetchWithBaseQuery({
            url: getUserPermissionsSetsEndpoint(
              get(permissionsSetsResponse, '0.id'),
            ),
            method: 'DELETE',
          });
        } else if (permissionsSetsResponse && permissionsSetId) {
          const { data: updatedPermissionsSetsResponse } =
            await fetchWithBaseQuery({
              url: getUserPermissionsSetsEndpoint(
                get(permissionsSetsResponse, '0.id'),
              ),
              method: 'POST',
              data: {
                permissionsSetId,
                userId: userName,
              },
            });

          if (updatedPermissionsSetsResponse) {
            result = updatedPermissionsSetsResponse.permissionsSetId;
          }
        }

        return {
          data: createServiceResponse({
            data: {
              permissionsSetId: result,
            },
            messages: { success: 'Group settings are successfully updated' },
          }),
        };
      },
    }),
    getUserOverrides: builder.query<
      { permissions: boolean } | null,
      { id: string }
    >({
      query: ({ id }) => ({
        url: getUserPermissionsEndpoint(id),
        ignoreForbiddenError: true,
      }),
      transformResponse: (response) => {
        return {
          permissions: Boolean(response.length),
        };
      },
    }),
    getUserLegalEntities: builder.query<unknown[], void>({
      query: () => ({
        url: FETCH_USER_CURRENT_LEGAL_ENTITIES,
      }),
    }),
    getUserAuthFlow: builder.query<IUserAuthflow, { userId: number }>({
      query: ({ userId }) => ({
        url: getUserAuthflowEndpoint(userId),
      }),
    }),
    updateUserAuthFlow: builder.mutation<
      { status: number } | null,
      { userId: number; data: IUserAuthflow }
    >({
      query: ({ userId, data }) => ({
        url: getUserAuthflowEndpoint(userId),
        method: 'POST',

        data,
      }),
    }),
  }),
});

export const {
  useAddUserTokenMutation,
  useChangeUserTokenMutation,
  useCreateUserMutation,
  useDeleteUserTokenMutation,
  useGetCoreUserQuery,
  useGetUserAuthFlowQuery,
  useGetUserBrandingsQuery,
  useGetUserClientsQuery,
  useGetUserGroupSettingsQuery,
  useGetUserLegalEntitiesQuery,
  useGetUserOverridesQuery,
  useLazyGetCoreUserClientsQuery,
  useLazyGetUserGroupSettingsQuery,
  useLazyGetUsersQuery,
  useLazySearchByUsersQuery,
  useUpdateGeneralPermissionsMutation,
  useUpdateUserAuthFlowMutation,
  useUpdateUserClientsMutation,
  useUpdateUserGroupSettingsMutation,
  useUpdateUserMutation,
} = usersApi;
