import { TAccountPurposes, TTypes } from '~/types/types';

import { TAccountFormState } from './types';

export const defaultValues: TAccountFormState = {
  id: '',
  type: 'inherit',
  accountPurpose: 'inherit',
  status: 'blocked',
  template: '',
};

export const ACCOUNT_ID_MAX_LENGTH = 18;

export interface IValidationSchemaProps {
  accountPurposes: TAccountPurposes[];
  accountStatuses: TTypes;
}
