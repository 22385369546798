export const DEFAULT_POSITION = {
  previous: null,
  current: null,
  next: null,
};
export const DEFAULT_SKIP = 0;
export const DEFAULT_CURSOR = 0;
export const DEFAULT_LIMIT = 50;
export const MINIMUM_REQUIRED_INDEX_COUNT = 3;
export const MINIMAL_REQUEST_BORDER_INDEX = 2;
