import { createContext, Dispatch } from 'react';

import { FilterLayers } from '~/types/symbolPermissions';

import type {
  IStateSymbolPermissions,
  TActionsSymbolPermissionsState,
} from '../types';

export enum ActionTypes {
  CHANGED_DEFAULT_NODES_RESET = 'CHANGED_DEFAULT_NODES_RESET',
  CHANGED_DEFAULT_NODES_UPDATE = 'CHANGED_DEFAULT_NODES_UPDATE',
  DOWNLOADED_PATHS_ADD = 'DOWNLOADED_PATHS_ADD',
  DOWNLOADED_PATHS_RESET = 'DOWNLOADED_PATHS_RESET',
  EXPANDED_ROWS_RESET = 'EXPANDED_ROWS_RESET',
  EXPANDED_ROWS_SET = 'EXPANDED_ROWS_SET',
  FILTERS_ACCOUNT_SET = 'FILTERS_ACCOUNT_SET',
  FILTERS_GROUP_SET = 'FILTERS_GROUP_SET',
  FILTERS_LAYER_SET = 'FILTERS_LAYER_SET',
  FILTERS_RELATED_GROUP_SET = 'FILTERS_RELATED_GROUP_SET',
  FILTERS_RESET = 'FILTERS_RESET',
  FILTERS_USER_SET = 'FILTERS_USER_SET',
  FILTERS_WITH_EXPIRED_SET = 'FILTERS_WITH_EXPIRED_SET',
  NEED_REFRESH_PAGE_SET = 'NEED_REFRESH_PAGE_SET',
  PERMISSIONS_BY_PATHS_RESET = 'PERMISSIONS_BY_PATHS_RESET',
  PERMISSIONS_BY_PATHS_SET = 'PERMISSIONS_BY_PATHS_SET',
  PERMISSIONS_BY_PATHS_UPDATE = 'PERMISSIONS_BY_PATHS_UPDATE',
  PERMISSIONS_FOR_INSTRUMENT_SET = 'PERMISSIONS_FOR_INSTRUMENT_SET',
  PERMISSIONS_FOR_NODE_SET = 'PERMISSIONS_FOR_NODE_SET',
  PERMISSIONS_RESET = 'PERMISSIONS_RESET',
}

export const INITIAL_STATE: IStateSymbolPermissions = {
  changedDefaultNodes: {},
  downloadedPaths: new Set(),
  expandedRows: {},
  filters: {
    select: {
      layer: FilterLayers.Default,
      account: null,
      user: null,
      group: null,
    },
    queryParams: {
      layer: FilterLayers.Default,
    },
    relatedGroup: null,
    withExpired: false,
  },
  needRefreshPage: false,
  permissions: {
    changed: {},
    initial: {},
  },
  permissionsByPath: {},
};

export const SymbolPermissionsContext = createContext<
  [IStateSymbolPermissions, Dispatch<TActionsSymbolPermissionsState>]
>([INITIAL_STATE, () => {}]);
