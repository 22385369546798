import { useCallback, useEffect, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { interestRatesApi } from '~/api';
import { TInterestRatesMap } from '~/api/interestRates/types';
import { TGroupId } from '~/types/interestRates/interestRates';

import {
  injectOverriddenRates,
  prepareDataForUpdate,
  haveBeenUpdated,
} from '../../helpers';
import {
  TInterestRateWithOverriddenMap,
  TInterestRateWithOverridden,
} from '../../types';

interface IUseRatesByGroupProps {
  group: TGroupId | null;
  defaultRates: TInterestRatesMap;
  preCall?: () => void;
}

export const useRatesByGroup = ({
  group,
  defaultRates,
  preCall,
}: IUseRatesByGroupProps) => {
  const [data, setData] =
    useState<TInterestRateWithOverriddenMap>(defaultRates);
  const [loading, setLoading] = useState(false);
  const { data: groupRates, refetch } =
    interestRatesApi.useGetInterestRatesByGroupQuery(group as TGroupId, {
      skip: !group,
    });

  const [updateGroupRate, { isLoading: updateGroupRateLoading }] =
    interestRatesApi.useUpdateGroupRateMutation();
  const [removeGroupRate, { isLoading: removeGroupRateLoading }] =
    interestRatesApi.useRemoveGroupRateMutation();

  useEffect(() => {
    if (group) {
      if (preCall) {
        preCall();
      }

      if (groupRates) {
        const newData = injectOverriddenRates({ ...defaultRates }, groupRates);
        setData(newData);
      }
    }
  }, [defaultRates, group, groupRates, preCall]);

  const handleUpdate = useCallback(
    async (
      previous: TInterestRateWithOverridden,
      updated: TInterestRateWithOverridden,
    ) => {
      if (!group) {
        Notification.warning({
          title: 'Please select a group',
        });
        return;
      }

      try {
        const { currency } = previous;

        setLoading(true);
        const preparedDataForUpdate = prepareDataForUpdate(previous, updated);
        const shouldBeOverridden = !previous.overridden && updated.overridden;
        const resetToDefault = previous.overridden && !updated.overridden;

        let response;

        if (resetToDefault) {
          response = await removeGroupRate({
            currency,
            groupId: group,
          });
        } else if (
          shouldBeOverridden ||
          haveBeenUpdated(preparedDataForUpdate)
        ) {
          response = await updateGroupRate({
            groupId: group,
            payload: preparedDataForUpdate,
          });
        } else {
          return;
        }
        if (response instanceof Error) {
          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [group, removeGroupRate, updateGroupRate],
  );

  return {
    ratesByGroup: group ? data : defaultRates,
    onUpdate: handleUpdate,
    loading: loading || updateGroupRateLoading || removeGroupRateLoading,
    refetch,
  };
};
