import { ReactNode } from 'react';

interface IServiceProps {
  service: ReactNode;
  servicePermissions: ReactNode;
}

export const Service = ({ service, servicePermissions }: IServiceProps) => {
  return (
    <div>
      {service}
      {servicePermissions}
    </div>
  );
};
