import { useController, useFormContext } from 'react-hook-form';
import { Switch } from 'react-ui-kit-exante';

interface IFormSwitchProps {
  label: string;
  name: string;
}

export const FormSwitch = ({ label, name }: IFormSwitchProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  return <Switch checked={value} label={label} onChange={onChange} />;
};
