import * as Yup from 'yup';

import {
  emptyStringToNull,
  validationFreeSoloAutocompleteAsync,
} from '~/shared/utils/validation';

export const TRANSACTION_ADD_FORM_VALIDATION_SCHEMA = Yup.object({
  accountId: Yup.object()
    .test('accountId', 'Account is required', (value) => Boolean(value?.value))
    .nullable(),
  asset: validationFreeSoloAutocompleteAsync('Asset is required', {
    isRequired: true,
  }),
  symbolId: validationFreeSoloAutocompleteAsync('Symbol is required', {
    isNullable: true,
  }),
  transactionType: Yup.string().required('Type is required'),
  amount: Yup.number()
    .typeError('Amount is required')
    .positive('Amount should be positive number')
    .required('Amount is required'),
  price: Yup.number()
    .transform(emptyStringToNull)
    .nullable()
    .typeError('Price should be number'),
  extraData: Yup.object({
    marketPrice: Yup.number()
      .transform(emptyStringToNull)
      .nullable()
      .typeError('Market should be number'),
  }),
  operationType: Yup.string().required('Operation type is required'),
  transferId: Yup.string(),
  clientCounterparty: Yup.string(),
  exanteCounterparty: Yup.string(),
  valueDate: Yup.string().nullable(),
  settlementCounterparty: Yup.string(),
  counterpartyAccountId: Yup.string(),
  executionCounterparty: Yup.string(),
  useAutoCashConversion: Yup.boolean(),
  comment: Yup.string(),
  internalComment: Yup.string(),
  takeCommission: Yup.boolean(),
  commission: Yup.object().when('takeCommission', {
    is: true,
    then: Yup.object({
      currency: Yup.string().required('Currency is required'),
      value: Yup.number()
        .required('Value is required')
        .typeError('Value should be number'),
      type: Yup.string().required('Operation type is required'),
    }),
  }),
});
