import { at, pick } from 'lodash';
import { Dispatch } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { symbolPermissionsService } from '~/resources';
import { IAccountGroupSettings, IAccountLimitSettings } from '~/types/accounts';
import { IServiceResponse } from '~/types/api';
import { FilterLayers } from '~/types/symbolPermissions';

import { ActionTypes, FIELDS_FOR_NODE } from '../constants';
import {
  IStateSymbolPermissions,
  TActionsSymbolPermissionsState,
  IChangePermission,
  ISymbolPermissions,
} from '../types';

function createPermissionUpdater(
  changedDefaultNodes: Record<string, Partial<ISymbolPermissions>>,
  id: IChangePermission['id'],
) {
  return (key: keyof ISymbolPermissions, label: string) => {
    return key in changedDefaultNodes[id]
      ? { [label]: changedDefaultNodes[id][key] }
      : {};
  };
}

export async function saveData(
  state: IStateSymbolPermissions,
  dispatch: Dispatch<TActionsSymbolPermissionsState>,
  updateGroupSettings: (params: {
    accountId: string;
    data: IAccountGroupSettings;
  }) => Promise<
    { data: IServiceResponse<IAccountLimitSettings> } | { error: unknown }
  >,
  updateUserGroupSettings: (params: {
    userName: string;
    permissionsSetId: number | null;
  }) => Promise<
    | {
        data: IServiceResponse<{
          permissionsSetId: number;
        }>;
      }
    | { error: unknown }
  >,
) {
  const {
    filters: {
      select: { layer, account, user, group },
      relatedGroup,
    },
    changedDefaultNodes,
    permissions: { changed: changedPermissions },
  } = state;

  let result;

  const data = Object.values(changedPermissions).map((permissions) => {
    const isNonDefaultNode = permissions.type && layer !== FilterLayers.Default;
    const isNonDefaultInstrument =
      !permissions.type && layer !== FilterLayers.Default;
    const isDefaultInstrument =
      !permissions.type && layer === FilterLayers.Default;

    const baseData = {
      allowShort: permissions.short,
      canTrade: permissions.trade,
      canView: permissions.view,
      public: permissions.public,
    };

    if (isNonDefaultNode) {
      return {
        ...baseData,
        ...pick(permissions, FIELDS_FOR_NODE),
        override: permissions.override,
      };
    }

    if (isNonDefaultInstrument) {
      return {
        ...baseData,
        override: permissions.override,
        symbolId: permissions.id,
      };
    }

    if (isDefaultInstrument) {
      return {
        ...baseData,
        symbolId: permissions.id,
      };
    }

    // if default node
    const getChangedPermission = createPermissionUpdater(
      changedDefaultNodes,
      permissions.id,
    );

    return {
      path: at(permissions, FIELDS_FOR_NODE).filter(Boolean).join('::'),
      ...getChangedPermission('public', 'public'),
      ...getChangedPermission('short', 'allowShort'),
      ...getChangedPermission('trade', 'canTrade'),
      ...getChangedPermission('view', 'canView'),
    };
  });

  const entity = account || user;

  if (layer === FilterLayers.Users && relatedGroup !== group && entity) {
    const response = await updateUserGroupSettings({
      userName: entity,
      permissionsSetId: group,
    });

    if (!('error' in response)) {
      Notification.success({
        title: response.data.messages?.success,
      });

      dispatch({
        type: ActionTypes.FILTERS_RELATED_GROUP_SET,
        payload: group,
      });
    }
  } else if (
    layer === FilterLayers.Accounts &&
    relatedGroup !== group &&
    entity
  ) {
    const permissionsSetData: IAccountGroupSettings = {
      permissionsSetId: group,
    };
    const response = await updateGroupSettings({
      accountId: entity,
      data: permissionsSetData,
    });

    if ('data' in response) {
      Notification.success({
        title: response.data.messages?.success,
      });

      dispatch({
        type: ActionTypes.FILTERS_RELATED_GROUP_SET,
        payload: group,
      });
    }
  } else if (data.length) {
    result = await symbolPermissionsService.postPermissions(
      data,
      layer,
      layer === FilterLayers.Groups ? String(group) : entity,
    );
  }

  if (result) {
    dispatch({ type: ActionTypes.PERMISSIONS_RESET, payload: null });
    dispatch({ type: ActionTypes.CHANGED_DEFAULT_NODES_RESET, payload: null });
  }
}
