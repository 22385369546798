import { isDate } from 'date-fns';

export const toDate = (
  value: unknown,
  defaultValue: Date | null = null,
): Date | null => {
  if (isDate(value)) {
    return value as Date;
  }

  if (typeof value === 'string' || typeof value === 'number') {
    const date = new Date(value);

    if (date.toString() === 'Invalid Date') {
      return defaultValue;
    }
    return date;
  }

  return null;
};
