import { ReactNode } from 'react';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import AccountSettingsStyles from './AccountSettings.module.css';

interface IAccountComponentProps {
  availableCurrencies: ReactNode;
  general: ReactNode;
  groupSettings: ReactNode;
  header: ReactNode;
  rebates: ReactNode;
  referrals: ReactNode;
  regularCommissions: ReactNode;
  risk: ReactNode;
  routingTags: ReactNode;
  originatorIds: ReactNode;
}

export const AccountSettings = ({
  availableCurrencies,
  general,
  groupSettings,
  header,
  originatorIds,
  rebates,
  referrals,
  regularCommissions,
  risk,
  routingTags,
}: IAccountComponentProps) => {
  return (
    <>
      {header}
      <PanelGroup panelsPerLine={2}>
        <div className={AccountSettingsStyles.SubPanel}>
          <Panel title="General">{general}</Panel>
          {risk}
        </div>
        <div className={AccountSettingsStyles.SubPanel}>
          {originatorIds}
          {routingTags}
          {regularCommissions}
          {rebates}
          {referrals}
          {availableCurrencies}
          {groupSettings}
        </div>
      </PanelGroup>
    </>
  );
};
