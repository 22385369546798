import { useMemo } from 'react';

import { DEFAULT_LOCALE } from '~/constants';
import { currencyFormatter } from '~/shared/utils';
import { IAccountSummary } from '~/types/accounts';

import { MarginIndicatorContainer } from '../MarginIndicatorContainer';

import {
  DetailedAccountInfo,
  HistoricalMode,
  DottedItem,
  InternalComment,
} from './components';
import {
  getAccountValues,
  getAvailableValues,
  getMarginData,
  getMetricNumbers,
} from './helpers';

export interface IDetailedAccountInfoContainerProps {
  currency: string;
  accountSummary: Omit<IAccountSummary, 'positions'>;
  date: Date | null;
  setDate(date: Date | null): void;
  resetDate(): void;
  internalComment?: string | null;
}

export const DetailedAccountInfoContainer = ({
  accountSummary: { metrics, closeSessionTime },
  currency,
  setDate,
  date,
  resetDate,
  internalComment,
}: IDetailedAccountInfoContainerProps) => {
  const numberedMetrics = useMemo(() => getMetricNumbers(metrics), [metrics]);
  const { netAssetValue, totalOrderMargin, totalBlockedMargin } =
    numberedMetrics;

  const marginData = useMemo(
    () => getMarginData(numberedMetrics),
    [numberedMetrics],
  );

  const formatter = useMemo(
    () => currencyFormatter(DEFAULT_LOCALE, currency),
    [currency],
  );

  const accountValues = useMemo(
    () =>
      getAccountValues({
        currencyFormatter: formatter,
        metrics: numberedMetrics,
      }),
    [formatter, numberedMetrics],
  );
  const availableValues = useMemo(
    () =>
      getAvailableValues({
        currencyFormatter: formatter,
        metrics: numberedMetrics,
        marginData,
      }),
    [formatter, numberedMetrics, marginData],
  );
  const accountValuesList = useMemo(
    () =>
      accountValues.map(({ label, value }, index) => (
        <DottedItem key={index} label={label} value={value} />
      )),
    [accountValues],
  );
  const availableValuesList = useMemo(
    () =>
      availableValues.map(({ label, value, legendColor }, index) => (
        <DottedItem
          key={index}
          label={label}
          value={value}
          legendColor={legendColor}
        />
      )),
    [availableValues],
  );

  return (
    <DetailedAccountInfo
      accountValuesList={accountValuesList}
      availableList={availableValuesList}
      internalComment={
        internalComment ? <InternalComment comment={internalComment} /> : null
      }
      historicalMode={
        <HistoricalMode
          setDate={setDate}
          date={date}
          closeSessionTime={closeSessionTime}
          resetDate={resetDate}
        />
      }
      marginIndicator={
        <MarginIndicatorContainer
          netAssetValue={netAssetValue}
          totalBlockedMargin={totalBlockedMargin}
          totalOrderMargin={totalOrderMargin}
          marginData={marginData}
        />
      }
    />
  );
};
