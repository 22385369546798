import { ICellValue } from 'react-ui-kit-exante';

import { formatCurrency } from './formatCurrency';

import { defaultLocaleFormatter } from './index';

export const formatCellToCurrency =
  <Data extends Partial<Record<keyof Data, unknown>>>(field: keyof Data) =>
  ({ row: { values } }: ICellValue<Data>) =>
    formatCurrency(defaultLocaleFormatter, values[field]);
