import { isFunction, pick } from 'lodash';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Bookmarks,
  Table,
  useTableData,
  Notification,
  Modal,
  OnSaveEditableRow,
  IOnFetchArguments,
} from 'react-ui-kit-exante';

import {
  useGetTransactionColumnsQuery,
  useLazyGetTransactionsQuery,
  useRollbackTransactionMutation,
  useUpdateTransactionMutation,
} from '~/api';
import {
  useGetAccountTypesQuery,
  useGetCategoriesTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOperationTypesQuery,
} from '~/api/types/types.api';
import { NO_DATA_HEIGHT } from '~/constants';
import {
  useAutocomplete,
  useCurrentUserHasAllBrandingPermission,
  useLogHandleTime,
  useSyncTableViewParams,
} from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { RollbackIcon } from '~/images/icons/Rollback';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  clearTableLocalStorageForActionColumn,
  updateAccountIdInParams,
  sendNotification,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { getIsFieldExistsAndEmpty } from '~/shared/utils/table';
import { IRefreshActiveTabQuery } from '~/types/refetch';
import { ITransaction, ITransactionsState } from '~/types/transactions';

import {
  DEFAULT_TABLE_ENTITY,
  DISPLAYED_COLUMNS,
  TABLE_ID,
  TRANSACTION_COLUMNS_ALL,
} from './constants';
import { getAdditionalFilters, getColumns, getDefaultFilters } from './filters';
import { useAdditionalActions } from './hooks';
import { DEFAULT_SORTING_TS, getDefaultSorting } from './sorting';
import { TGlobalFilters } from './types';

interface ITransactionsContainerProps {
  entity?: string;
  globalFilters?: TGlobalFilters;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
  withAddButton?: boolean;
  selectTable?: ReactNode;
}

export const Transactions: FC<
  ITransactionsContainerProps & IBookmarkResponseProps
> = ({
  entity = DEFAULT_TABLE_ENTITY,
  globalFilters,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleSaveBookmark,
  handleShareBookmark,
  selectedBookmark,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
  selectTable,
}) => {
  const [rollbackTransaction] = useRollbackTransactionMutation();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);
  const [fetchTransactions] = useLazyGetTransactionsQuery();
  const [updateTransaction, { isLoading: isUpdateTransactionLoading }] =
    useUpdateTransactionMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rollbackId, setRollbackId] = useState<null | number>(null);
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const { data: categoryTypes } = useGetCategoriesTypesQuery();
  const { data: operationTypes } = useGetOperationTypesQuery();
  const { data: transactionColumnsData } = useGetTransactionColumnsQuery();
  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();

  const transactionColumns = useMemo(() => {
    if (!transactionColumnsData?.length) {
      return TRANSACTION_COLUMNS_ALL;
    }

    return transactionColumnsData;
  }, [transactionColumnsData]);

  const getTransactions = useCallback(
    async ({ params }: IOnFetchArguments) => {
      const isEmptyAccountsList =
        globalFilters && getIsFieldExistsAndEmpty(globalFilters, 'accountId');
      const defaultState = { transactions: [], pagination: { total: 0 } };

      if (isEmptyAccountsList) {
        return { transactions: [], pagination: { total: 0 } };
      }

      const preparedParams = updateAccountIdInParams(
        {
          ...globalFilters,
          ...params,
        },
        'accountId',
      );

      setStartHandleTime();

      const { data, error } = await fetchTransactions(preparedParams);

      logHandleTime();

      if (error || !data) {
        return defaultState;
      }

      return data;
    },
    [fetchTransactions, globalFilters, logHandleTime, setStartHandleTime],
  );

  const tableArgs = useMemo(
    () => ({
      data: { onFetch: getTransactions },
      filters: { getDefaultFilters, required: ['fromTo'] },
      sorting: { getDefaultSorting },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getTransactions, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<ITransactionsState>(tableArgs);
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const getAssetsAutocompleteFn = useAutocomplete('assets');

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [updateRefetch, refetch, isLoading]);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types: {
          legalEntity: legalEntityTypes?.values || [],
          categories: categoryTypes?.values || [],
          operations: operationTypes?.values || [],
        },
        fetchAccounts: getAccountsAutocompleteFn(),
        fetchAssets: getAssetsAutocompleteFn(),
        transactionColumns,
      }),
    [
      categoryTypes?.values,
      getAccountsAutocompleteFn,
      getAssetsAutocompleteFn,
      legalEntityTypes?.values,
      operationTypes?.values,
      removeFilter,
      setFilter,
      transactionColumns,
    ],
  );

  const additionalActions = useAdditionalActions(
    withAddButton,
    columns,
    globalFilters,
    filters,
  );

  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [removeFilter, setFilter, accountTypesOptions],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : [...DISPLAYED_COLUMNS, 'actions'],
    [selectedBookmark.columns],
  );

  const successCallback = useCallback(() => {
    sendNotification('Trade has been updated', 'success');
  }, []);

  const errorCallback = useCallback(() => {
    sendNotification('Error updating trade', 'error');
  }, []);

  const onSaveRowHandler: OnSaveEditableRow<ITransaction> = useCallback(
    async (_, newRow) => {
      const result = await updateTransaction(
        pick(newRow, ['uuid', 'comment', 'internalComment']),
      );

      refetch();

      if (result) {
        successCallback();
      } else {
        errorCallback();
      }
    },
    [refetch, updateTransaction, successCallback, errorCallback],
  );

  const handleRollbackTransaction = useCallback(async () => {
    if (rollbackId === null) {
      return;
    }

    setShowConfirmModal(false);

    const res = await rollbackTransaction(rollbackId);

    if ('error' in res) {
      Notification.error({
        title: `Error while rolling back: ${res.error}`,
      });
    } else {
      Notification.success({
        title: 'Transaction was rolled back',
      });
    }

    setRollbackId(null);
  }, [rollbackId, rollbackTransaction]);

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onSaveRowHandler,
      additionalActions: [
        {
          label: <RollbackIcon />,
          title: 'Rollback',
          order: 0,
          onClick: ({ id }: ITransaction) => {
            if (id) {
              setRollbackId(id);
              setShowConfirmModal(true);
            }
          },
          show: (_: unknown, values: ITransaction) =>
            values.operationType !== 'TRADE',
        },
      ],
      order: 1,
    }),
    [onSaveRowHandler],
  );

  useEffect(() => {
    clearTableLocalStorageForActionColumn(tableId);
  }, [tableId]);

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={data?.transactions || []}
        defaultSortBy={DEFAULT_SORTING_TS}
        displayedColumnKeys={displayedColumnKeys}
        filteringProps={filteringProps}
        filtersExpanded
        filtersRightPanelComponent={bookmarkComponent}
        hasFilters
        hasPagination
        isFlexLayout
        isLoading={isLoading || isUpdateTransactionLoading}
        isPinnedHeader={entity === DEFAULT_TABLE_ENTITY}
        manualSortBy
        noDataHeight={NO_DATA_HEIGHT}
        onSort={setSorting}
        rowActions={
          currentUserHasAllBrandingPermission ? rowActions : undefined
        }
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        showTableInfo
        tableId={tableId}
        title={selectTable || 'Transactions'}
      />
      <Modal
        title=""
        isOpened={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setRollbackId(null);
        }}
        confirmButton={{
          handleConfirm: handleRollbackTransaction,
          confirmButtonName: 'Rollback',
        }}
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        keepMounted={false}
      >
        Rollback transaction?
      </Modal>
    </>
  );
};

export const TransactionsContainer: FC<ITransactionsContainerProps> = ({
  entity = DEFAULT_TABLE_ENTITY,
  globalFilters,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
  selectTable,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Transactions');

    return (
      <Transactions
        entity={entity}
        globalFilters={globalFilters}
        handleDeleteBookmark={handleDeleteBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleShareBookmark={handleShareBookmark}
        selectTable={selectTable}
        selectedBookmark={selectedBookmark}
        tableId={tableId}
        updateRefetch={updateRefetch}
        withAddButton={withAddButton}
      />
    );
  }

  return (
    <WithBookmarks
      component={Transactions}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Transactions"
      tableId={tableId}
      updateRefetch={updateRefetch}
      withAddButton={withAddButton}
    />
  );
};
