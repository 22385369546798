import {
  IColumn,
  IRowExpand,
  TAutocompleteAsyncFetchData,
  TTableState,
} from 'react-ui-kit-exante';

import { IOption } from './form';
import { LimitsTree } from './limits';

export enum FilterLayers {
  Default = 'default',
  Groups = 'groups',
  Accounts = 'accounts',
}

export type TreeStructureIsLoadingKeys = 'instruments';

export interface TreeStructure {
  layer: FilterLayers;
  group: {
    groupId: null | number;
    defaultGroupId: null | number;
  };
  account: {
    accountId: null | number;
    groupId: {
      initial: null | number;
      userSelected: null | number;
    };
  };
  isLoading: Record<TreeStructureIsLoadingKeys, boolean>;
}

export interface UseHandleCellClick {
  column: IColumn<LimitsTree>;
  row: IRowExpand<LimitsTree>;
  tableState: TTableState;
}

export interface TabAccount {
  getGroupOptions: () => Promise<IOption[]>;
  getAccounts: TAutocompleteAsyncFetchData;
  getAccountInfo: (accountId?: string) => Promise<{
    accountId: string;
    groupId: number | null | undefined;
  }>;
}

export interface TabGroup {
  addGroup: (newGroupName: string) => Promise<number | null>;
  editGroup: (newGroupName: string, id: number) => Promise<number | null>;
  deleteGroup: () => Promise<string>;
  getOptions: () => Promise<IOption[]>;
}
