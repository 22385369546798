import { styled } from 'react-ui-kit-exante';

export const StyledLoader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.color.bg.primary,
  display: 'flex',
  height: '90vh',
  justifyContent: 'center',
  margin: 0,
  padding: 0,
  width: '100%',
}));
