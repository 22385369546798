import { uniqueId } from 'lodash';
import { useEffect, useRef } from 'react';

export const useTimer = () => {
  const timeoutRef = useRef<Map<string, NodeJS.Timeout>>(new Map());

  const startWithDelay = (cb: () => unknown, delay: number) => {
    const timerId = uniqueId('timer');

    const newTimer = setTimeout(() => {
      cb();
      timeoutRef.current.delete(timerId);
    }, delay);

    timeoutRef.current.set(timerId, newTimer);
  };

  useEffect(() => {
    return () => {
      const { current: timers } = timeoutRef;

      if (timers.size > 0) {
        [...timers].forEach(([, timer]) => {
          clearTimeout(timer);
        });
        timers.clear();
      }
    };
  }, []);

  return { startWithDelay };
};
