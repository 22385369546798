import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TotalFooter } from '~/shared/components/TotalFooter';
import { formatCellToCurrency } from '~/shared/utils/formatters/formatCellToCurrency';
import { formatUnderScoreFields } from '~/shared/utils/formatters/formatUnderScoreFields';
import { calculateColumnValuesSum } from '~/shared/utils/table';
import { IBlockedFunds } from '~/types/accounts/metrics';

export const DISPLAYED_COLUMN_KEYS: (keyof IBlockedFunds)[] = [
  'type',
  'lockId',
  'asset',
  'lockedQtty',
  'reason',
  'margin',
  'currency',
  'convertedMargin',
];

export const getColumns = (currency: string): IColumn<IBlockedFunds> => [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ row: { values } }: ICellValue<IBlockedFunds>) =>
      formatUnderScoreFields(values.type),
    Footer: TotalFooter,
  },
  {
    Header: 'Id',
    accessor: 'lockId',
  },
  {
    Header: 'Asset',
    accessor: 'asset',
  },
  {
    Header: 'Quantity',
    accessor: 'lockedQtty',
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    formatting: 'number',
    Cell: formatCellToCurrency<IBlockedFunds>('margin'),
  },
  {
    Header: 'Currency',
    accessor: 'currency',
  },
  {
    Header: `Margin, ${currency}`,
    accessor: 'convertedMargin',
    formatting: 'number',
    Cell: formatCellToCurrency<IBlockedFunds>('convertedMargin'),
    Footer: calculateColumnValuesSum<IBlockedFunds>('convertedMargin'),
  },
  {
    Header: 'Leverage rate',
    accessor: 'leverageRate',
    formatting: 'number',
  },
  {
    Header: 'Extreme margin',
    accessor: 'extremeMargin',
    formatting: 'number',
  },

  {
    Header: `Extreme margin, ${currency}`,
    accessor: 'convertedExtremeMargin',
    formatting: 'number',
    minWidth: 184,
  },
];
