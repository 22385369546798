import cn from 'classnames';

import { LeftArrowIcon, RightArrowIcon } from '~/images/icons';

import ArrowStyles from './Arrow.module.css';

interface IArrowProps {
  direction: 'left' | 'right';
  disabled?: boolean;
  className?: string;
  onClick(): void;
  title?: string;
}

export const Arrow = ({
  direction,
  disabled = false,
  className,
  onClick,
  title,
}: IArrowProps) => {
  return (
    <button
      title={title}
      className={cn(ArrowStyles.Button, className)}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {direction === 'left' && <LeftArrowIcon />}
      {direction === 'right' && <RightArrowIcon />}
    </button>
  );
};
