import { MirroringRuleInfoContainer } from '~/containers/MirroringRuleInfoContainer';
import { ITab } from '~/types/tabs';

import { TTabsIds } from '../context/types';

import { IMirroringRuleTabComponentProps } from './types';

export const TABS: ITab<TTabsIds, IMirroringRuleTabComponentProps>[] = [
  { id: 'info', title: 'Info', Component: MirroringRuleInfoContainer },
];
