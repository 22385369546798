import { getTableId } from '~/shared/utils';

export interface IPositionConfigItem {
  id: string;
  title: string;
  order: number;
  iconName: string;
  types: string[];
  requiredColumnKeys?: string[];
}

export const POSITIONS_CONFIG: Record<string, IPositionConfigItem> = {
  cash: {
    id: 'cash',
    title: 'Cash Balance',
    order: 0,
    iconName: 'R',
    types: ['CURRENCY', 'FOREX'],
    requiredColumnKeys: ['description'],
  },
  forex: {
    id: 'forex',
    title: 'Forex',
    order: 1,
    iconName: 'F',
    types: ['FX_SPOT'],
    requiredColumnKeys: ['symbolId'],
  },
  futures: {
    id: 'futures',
    title: 'Futures',
    order: 2,
    iconName: 'I',
    types: ['FUTURE', 'CALENDAR_SPREAD'],
    requiredColumnKeys: ['symbolId'],
  },
  options: {
    id: 'options',
    title: 'Options',
    order: 3,
    iconName: 'C',
    types: ['OPTION'],
    requiredColumnKeys: ['symbolId'],
  },
  bonds: {
    id: 'bonds',
    title: 'Bonds',
    order: 4,
    iconName: 'B',
    types: ['BOND'],
    requiredColumnKeys: ['symbolId'],
  },
  stocks: {
    id: 'stocks',
    title: 'Stocks & ETFs',
    order: 5,
    iconName: 'S',
    types: ['STOCK'],
    requiredColumnKeys: ['symbolId'],
  },
  funds: {
    id: 'funds',
    title: 'Funds & OTC',
    order: 6,
    iconName: 'O',
    types: ['FUND'],
    requiredColumnKeys: ['symbolId'],
  },
  swaps: {
    id: 'swaps',
    title: 'Swaps',
    order: 7,
    iconName: 'CFD',
    types: ['CFD'],
    requiredColumnKeys: ['symbolId'],
  },
};

export const CASH_GROUP = 'CURRENCY';
export const ACCOUNT_ASSET_ACCORDION_LS_KEY = 'asset-accordion';
export const REQUIRED_COLUMNS = ['symbolId'];
export const ACCOUNT_ASSET_TABLE_ID = getTableId('Asset');
export const TABLE_IDS = Object.keys(POSITIONS_CONFIG).map(
  (key) => `${ACCOUNT_ASSET_TABLE_ID}-${key}`,
);
export const TABLE_ROW_HEIGHT = 46;
export const MAX_ROWS_TO_SHOW = 10;
