import { PROXY_CORE_PATH } from '~/shared/utils';

export const GLOBAL_SUMMARY_API = '/api/global-summary';
export const HISTORICAL_GLOBAL_SUMMARY_API = `${PROXY_CORE_PATH}v2.0/summary`;

interface ICreateGlobalSummaryAPIArguments {
  currency: string;
  date: string | null;
  isAggregatedView?: boolean;
  isByPositionSide?: boolean;
  isCurrencyRisk?: boolean;
}

export function createGlobalSummaryAPI({
  currency,
  date,
  isAggregatedView = false,
  isByPositionSide = false,
  isCurrencyRisk = false,
}: ICreateGlobalSummaryAPIArguments) {
  const baseApi = `${GLOBAL_SUMMARY_API}`;

  if (date) {
    return `${baseApi}/${currency}?byPositionSide=${isByPositionSide}&aggregatedView=${isAggregatedView}&currencyRisk=${isCurrencyRisk}&startReportDate=${date}&endReportDate=${date}`;
  }

  return `${baseApi}/${currency}?byPositionSide=${isByPositionSide}&aggregatedView=${isAggregatedView}&currencyRisk=${isCurrencyRisk}`;
}

export const getHistoricalGlobalSummaryEndpoint = (
  date: string,
  currency: string,
) => `${HISTORICAL_GLOBAL_SUMMARY_API}/${date}/${currency}`;
