import { FilterLayers } from '~/types/symbolPermissions';

import { IStateSymbolPermissions, TPrepareQueryParams } from '../types';

export function getQueryParams({
  account,
  group,
  layer,
  relatedGroup,
  user,
}: TPrepareQueryParams): IStateSymbolPermissions['filters']['queryParams'] {
  const groupLayerIsSelected = layer === FilterLayers.Groups && group;
  const accountLayerIsSelected = layer === FilterLayers.Accounts && account;
  const userLayerIsSelected = layer === FilterLayers.Users && user;
  const effectiveLayerIsSelected =
    layer === FilterLayers.Effective && account && user;

  if (layer === FilterLayers.Default) {
    return { layer };
  }

  if (groupLayerIsSelected) {
    return { layer, groupId: group };
  }

  if (effectiveLayerIsSelected) {
    return { layer, accountId: account, username: user };
  }

  if (accountLayerIsSelected || userLayerIsSelected) {
    const groupFilterHasNotBeenChanged = relatedGroup === group;

    if (groupFilterHasNotBeenChanged) {
      if (userLayerIsSelected) {
        return {
          layer,
          username: user,
        };
      }

      if (accountLayerIsSelected) {
        return {
          layer,
          accountId: account,
        };
      }
    }

    return {
      layer: FilterLayers.Groups,
      groupId: group,
    };
  }

  return false;
}
