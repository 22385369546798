import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EMPTY_ARRAY } from '~/constants';
import { selectCurrencies } from '~/store/currencies';
import { EStatus } from '~/types/api';
import { ICurrency, TCurrency } from '~/types/currencies';

export const useCurrenciesMap = () => {
  const { data: currencies = EMPTY_ARRAY, status } =
    useSelector(selectCurrencies);

  const currenciesMap = useMemo(() => {
    const map = new Map<TCurrency, Omit<ICurrency, 'currency'>>();

    if (status === EStatus.Succeeded) {
      currencies.forEach(({ currency, ...rest }) => map.set(currency, rest));
    }

    return map;
  }, [currencies, status]);

  const getCurrency = (currency: TCurrency) =>
    currenciesMap.has(currency) && currenciesMap.get(currency);

  return {
    currenciesMap,
    getCurrency,
  };
};
