import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IColumn,
  IRowExpand,
  ISelectOption,
  Notification,
  Select,
} from 'react-ui-kit-exante';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { selectCurrenciesOptionsByWeightWithDefault } from '~/store/currencies';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

const SX = {
  width: 100,
};

interface ICurrencyCell {
  column: IColumn<ICommissionTree>;
  row: IRowExpand<ICommissionTree>;
  value: null | string | number;
}

export const CurrencyCell = ({ row, value, column }: ICurrencyCell) => {
  const currenciesOptions = useSelector(
    selectCurrenciesOptionsByWeightWithDefault,
  );
  const dispatch = useDispatch();
  const { layer } = column.getProps();

  const isNodeCell = row.original.rowType === RowType.Node;
  const isNonDefaultLayer = layer !== FilterLayers.Default;
  const isInheritedOnDefaultLayer =
    layer === FilterLayers.Default && row.original.inherited;

  const disabled = isNonDefaultLayer || isInheritedOnDefaultLayer;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const actionForUpdateValue = isNodeCell
        ? updateNodeValue
        : updateInstrumentValue;

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: event.target.value,
          column: column.id,
        }),
      );
    },
    [column, dispatch, isNodeCell, row],
  );

  const onClickHandler = useCallback(() => {
    if (isInheritedOnDefaultLayer) {
      Notification.warning({
        title: `You can not change inherited ${
          isNodeCell ? 'node' : 'instrument'
        }`,
      });
    }

    if (isNonDefaultLayer) {
      Notification.warning({
        title: `You can not change currency on non-default layer`,
      });
    }
  }, [isNodeCell, isNonDefaultLayer, isInheritedOnDefaultLayer]);

  return (
    <Select
      disabled={disabled}
      onChange={onChangeHandler}
      onClick={onClickHandler}
      options={currenciesOptions as ISelectOption[]}
      size="small"
      sx={SX}
      value={`${value}`}
    />
  );
};
