import { get } from 'lodash';
import { Notification } from 'react-ui-kit-exante';

import { parseError } from './parseError';

/**
 * Displays an error notification based on the provided error object.
 *
 * This function extracts relevant information from the error object and displays an error notification
 * using the `Notification` component from 'react-ui-kit-exante'.
 *
 * @param {unknown} error - The error object to display a notification for.
 */
export function displayErrorNotification(error: unknown) {
  const incorrectEndpoint =
    get(error, 'response.data.message') === 'Not found' &&
    get(error, 'response.status') === 404;

  Notification.error(
    incorrectEndpoint
      ? {
          title: get(error, 'message'),
        }
      : parseError(error),
  );
}
