import { defaultThemeBreakpoints } from './constants';
import { KitThemeExtensions } from './theme.types';

const figmaColors: {
  [figmaThemeVariableName: string]: string;
} = {
  backgroundGlobal: '#000000', // backgroung/global-ca_run
  backgroundBlock: '#141919', // backgroung/block
  backgroundBack: '#111515', // backgroung/back
  backgroundModal: '#0E1112', // backgroung/modal
  surfacePrimary: '#DBE1E2', // surface/primary
  surfacePrimaryAction: '#209F59', // surface/primary-action
  surfaceSecondary: '#CFD7D9', // surface/secondary
  surfaceSecondaryAction: '#A0AFB3', // surface/secondary-action
  surfaceInverse: '#FFFFFF', // surface/inverce
  surfaceRadical: '#E99458', // surface/radical
  surfaceSource: '#618CB5', // surface/source
  surfaceGhost: '#70868C', // surface/ghost
  surfaceWarning: '#DCC500', // surface/warning
  tableBasic1: '#121616', // table/base/basic1
  tableBasic2: '#141919', // table/base/basic2
  tableHover: '#374245', // table/base/hover
  tableFocus: '#0A0C0C', // table/base/focus
  tableSourceDefault: '#141F2A', // table/source/default
  tableWarningDefault: '#373100', // table/warning/default
  tableRadicalDefault: '#38271B', // table/radical/default
  tableBlueDefault: '#031E34', // table/blue/default
  buttonPrimaryDefault: '#209F59', // button/primary/default
  buttonPrimaryHover: '#25BA68', // button/primary/hover
  buttonPrimaryFocus: '#136036', // button/primary/focus
  buttonSecondaryDefault: '#A0AFB3', // button/secondary/default
  buttonSecondaryHover: '#CFD7D9', // button/secondary/hover
  buttonSecondaryFocus: '#DBE1E2', // button/secondary/focus
  buttonRadicalDefault: '#E99458', // button/radical/default
  buttonRadicalHover: '#ECA471', // button/radical/hover
  buttonRadicalFocus: '#865736', // button/radical/focus
  controlsWarningDefault: '#DCC500', // controls/warning/default
  controlsWarningHover: '#F0D700', // controls/warning/hover
  controlsWarningFocus: '#7F7117', // controls/warning/focus
  controlsStateDefault: '#242A2C', // controls/state/default
  controlsStateBorder: '#4C5B5F', // controls/state/border
  controlsStateFocus: '#2E3639', // controls/state/focus
  controlsStateHover: '#374245', // controls/state/hover
  borderSecondary: '#202729', // border/secondary
  multiColorMultiBonds: '#2196F3', // multicolor/multi-bonds
};

export const darkThemeValues: KitThemeExtensions = {
  color: {
    bg: {
      basic: figmaColors.backgroundGlobal,
      primary: figmaColors.backgroundBlock,
      back: figmaColors.backgroundBack,
    },
    button: {
      primary: {
        bg: {
          default: figmaColors.buttonPrimaryDefault,
          hover: figmaColors.buttonPrimaryHover,
          focus: figmaColors.buttonPrimaryFocus,
        },
        typo: {
          default: figmaColors.surfaceInverse,
          hover: figmaColors.surfaceInverse,
          focus: figmaColors.surfaceInverse,
          disabled: figmaColors.surfaceInverse,
        },
      },
      radical: {
        bg: {
          default: figmaColors.buttonRadicalDefault,
          hover: figmaColors.buttonRadicalHover,
          focus: figmaColors.buttonRadicalFocus,
        },
        typo: {
          default: figmaColors.surfaceInverse,
          hover: figmaColors.surfaceInverse,
          focus: figmaColors.surfaceInverse,
          disabled: figmaColors.surfaceInverse,
        },
      },
      secondary: {
        bg: {
          default: figmaColors.buttonSecondaryDefault,
          hover: figmaColors.buttonSecondaryHover,
          focus: figmaColors.buttonSecondaryFocus,
        },
        typo: {
          default: figmaColors.buttonSecondaryDefault,
          hover: figmaColors.buttonSecondaryHover,
          focus: figmaColors.buttonSecondaryFocus,
          disabled: figmaColors.buttonSecondaryDefault,
        },
      },
    },
    checkbox: {
      primary: {
        checked: {
          bg: figmaColors.buttonPrimaryDefault,
          border: {
            default: figmaColors.buttonPrimaryDefault,
            focus: figmaColors.buttonPrimaryDefault,
            hover: figmaColors.buttonPrimaryDefault,
          },
          iconColor: figmaColors.surfaceInverse,
        },
        nonChecked: {
          bg: figmaColors.backgroundBlock,
          border: {
            default: figmaColors.surfaceSecondaryAction,
            hover: figmaColors.surfaceSecondary,
            focus: figmaColors.buttonPrimaryDefault,
          },
          iconColor: figmaColors.surfaceInverse,
        },
      },
      warning: {
        checked: {
          bg: figmaColors.controlsWarningDefault,
          border: {
            default: figmaColors.controlsWarningDefault,
            focus: figmaColors.controlsWarningDefault,
            hover: figmaColors.controlsWarningHover,
          },
          iconColor: figmaColors.surfaceInverse,
        },
        nonChecked: {
          bg: figmaColors.backgroundBlock,
          border: {
            default: figmaColors.controlsWarningDefault,
            hover: figmaColors.controlsWarningHover,
            focus: figmaColors.controlsWarningFocus,
          },
          iconColor: figmaColors.surfaceInverse,
        },
      },
      radical: {
        checked: {
          bg: figmaColors.buttonRadicalDefault,
          border: {
            default: figmaColors.buttonRadicalDefault,
            focus: figmaColors.buttonRadicalDefault,
            hover: figmaColors.buttonRadicalHover,
          },
          iconColor: figmaColors.surfaceInverse,
        },
        nonChecked: {
          bg: figmaColors.backgroundBlock,
          border: {
            default: figmaColors.buttonRadicalDefault,
            hover: figmaColors.buttonRadicalHover,
            focus: figmaColors.buttonRadicalFocus,
          },
          iconColor: figmaColors.surfaceInverse,
        },
      },
    },
    dropdown: {
      bg: {
        default: figmaColors.controlsStateDefault,
      },
      border: figmaColors.controlsStateBorder,
      error: figmaColors.surfaceRadical,
      list: {
        bg: {
          focus: figmaColors.controlsStateFocus,
          hover: figmaColors.controlsStateHover,
        },
      },
      chip: {
        bg: figmaColors.controlsStateFocus,
      },
    },
    icon: {
      action: figmaColors.surfacePrimaryAction,
      ghost: figmaColors.surfaceGhost,
      inverse: figmaColors.surfaceInverse,
      primary: figmaColors.surfacePrimary,
      promo: figmaColors.surfaceSecondary,
      radical: figmaColors.surfaceRadical,
      secondary: figmaColors.surfaceSecondaryAction,
      source: figmaColors.surfaceSource,
      warning: figmaColors.surfaceWarning,
      active: figmaColors.multiColorMultiBonds,
    },
    indicator: {
      bg: {
        basic: figmaColors.backgroundGlobal,
      },
    },
    circularProgress: {
      background: figmaColors.backgroundGlobal,
      foreground: figmaColors.surfaceSource,
    },
    input: {
      bg: {
        default: figmaColors.controlsStateDefault,
        hover: figmaColors.controlsStateHover,
        focus: figmaColors.controlsStateFocus,
      },
      border: figmaColors.controlsStateBorder,
      error: figmaColors.surfaceRadical,
      warning: figmaColors.surfaceWarning,
    },
    modal: {
      bg: figmaColors.backgroundModal,
    },
    line: {
      primary: figmaColors.borderSecondary,
    },
    notifications: {
      success: figmaColors.surfaceSource,
      error: figmaColors.surfaceRadical,
      warning: figmaColors.surfaceWarning,
    },
    typo: {
      action: figmaColors.surfacePrimaryAction,
      ghost: figmaColors.surfaceGhost,
      inverse: figmaColors.surfaceInverse,
      primary: figmaColors.surfacePrimary,
      promo: figmaColors.surfaceSecondary,
      radical: figmaColors.surfaceRadical,
      secondary: figmaColors.surfaceSecondaryAction,
      source: figmaColors.surfaceSource,
      warning: figmaColors.surfaceWarning,
      active: figmaColors.multiColorMultiBonds,
    },
    table: {
      bg: {
        basic1: figmaColors.tableBasic1,
        basic2: figmaColors.tableBasic2,
        radical: figmaColors.tableRadicalDefault,
        warning: figmaColors.tableWarningDefault,
        source: figmaColors.tableSourceDefault,
        hover: figmaColors.tableHover,
        active: figmaColors.tableBlueDefault,
        focus: figmaColors.tableFocus,
      },
      boxShadow: {
        default: '0 4px 8px 0 rgba(37, 44, 46, 0.1)',
        basic1: `inset 0 1px 0 ${figmaColors.borderSecondary}`,
      },
    },
    datepicker: {
      background: figmaColors.backgroundBlock,
      colorWeek: figmaColors.surfacePrimary,
      colorDay: figmaColors.surfaceSecondaryAction,
      daySelected: figmaColors.controlsStateFocus,
      dayHover: figmaColors.controlsStateHover,
      dayBorder: figmaColors.controlsStateFocus,
    },
    loader: {
      color: figmaColors.surfaceSecondaryAction,
    },
    // to do: component Tree requires design and further refactoring according to it
    tree: {
      hover: '#F5F5F5',
      focused: '#007f3933',
      expanded: '#ECF5F0',
    },
  },
  effect: {
    controls: {
      warning:
        'inset 0 -4.8px 9.6px rgba(236, 159, 11, 0.24), inset 0 4.8px 9.6px rgba(236, 159, 11, 0.41)',
      inter:
        'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
      drop: '0 2.32257px 6.19352px rgba(0, 0, 0, 0.15)',
    },
  },
  font: {
    main: 'Inter, sans-serif',
    header: 'FoxSansPro, sans-serif',
  },
  size: {
    loader: {
      md: '16px',
      lg: '32px',
    },
    text: {
      sm: '12px',
      md: '13px',
      lg: '15px',
    },
  },
  breakpoints: defaultThemeBreakpoints,
};
