import { ElementType } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBookmark } from '~/hooks';

import { StaticCenteredLoader } from '../StaticCenteredLoader';

export type TWithBookmarksProps = {
  component: ElementType;
  pageName: string;
  tableId: string;
  [key: string]: unknown;
};

export const WithBookmarks = ({
  component: WrappedComponent,
  pageName,
  tableId,
  ...wrappedComponentProps
}: TWithBookmarksProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isLoading, ...bookmarkProps } = useBookmark({
    pageName,
    setSearchParams,
    tableId,
  });

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="80vh" />;
  }

  return <WrappedComponent {...bookmarkProps} {...wrappedComponentProps} />;
};
