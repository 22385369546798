import { createElement } from 'react';
import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { SEX_OPTIONS, USER_TYPE_OPTIONS } from '~/constants/options';
import { COMPANY_CLASSIFICATION_OPTIONS } from '~/containers/UserGeneralSettingsContainer/SettingsFormContainer/constants';
import { TooltipWrapper } from '~/shared/components/TooltipWrapper';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';
import { IUser } from '~/types/users';

export const DISPLAYED_COLUMN_KEYS = [
  'username',
  'id',
  'creationDate',
  'residence',
  'preferableLanguage',
  'lastLoginTime',
  'lastLoginService',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  brandingList: IOption[];
  brandingListWithoutAll: IOption[];
}

export function getColumns({
  onFilter,
  onRemove,
  brandingList,
  brandingListWithoutAll,
}: IGetColumns): IColumn<IUser>[] {
  return [
    {
      Header: 'Username',
      accessor: 'username',
      onFilter,
      onRemove,
    },
    {
      Header: 'Id',
      accessor: 'id',
      onFilter,
      onRemove,
    },
    {
      Header: 'Creation date',
      accessor: 'creationDate',
      type: 'dateRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      sortDescFirst: true,
      formatting: 'dateUTC',
    },
    { Header: 'Residence', accessor: 'residence', onFilter, onRemove },
    {
      Header: 'Preferable language',
      accessor: 'preferableLanguage',
      onFilter,
      onRemove,
    },
    {
      Header: 'Last login time',
      accessor: 'lastLoginTime',
      type: 'dateTimeRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Last login service',
      accessor: 'lastLoginService',
      onFilter,
      onRemove,
    },
    {
      Header: 'Branding',
      accessor: 'branding',
      type: 'select',
      filterOptions: brandingListWithoutAll,
      onFilter,
      onRemove,
    },
    {
      Header: 'Branding permission',
      accessor: 'brandingPermission',
      onFilter,
      onRemove,
      type: 'select',
      filterOptions: brandingList,
    },
    {
      Header: 'Archived',
      accessor: 'archived',
      type: 'checkbox',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Blocked',
      accessor: 'blocked',
      type: 'checkbox',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Birthdate',
      accessor: 'birthDate',
      type: 'dateRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      formatting: 'dateUTC',
    },
    {
      Header: 'Company',
      accessor: 'company',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Company classification',
      accessor: 'companyClassification',
      disableSortBy: true,
      onFilter,
      onRemove,
      type: 'select',
      filterOptions: COMPANY_CLASSIFICATION_OPTIONS,
    },
    { Header: 'Email', accessor: 'email', onFilter, onRemove },
    {
      Header: 'Extra data',
      accessor: 'extraData',
      disableSortBy: true,
      disableFilters: true,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'First name',
      accessor: 'firstName',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Jurisdiction',
      accessor: 'jurisdiction',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    { Header: 'Lei', accessor: 'lei', disableSortBy: true, onFilter, onRemove },
    {
      Header: 'Middle name',
      accessor: 'middleName',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Notifications',
      accessor: 'notifications',
      disableSortBy: true,
      disableFilters: true,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'Originator ids',
      accessor: 'originatorIds',
      disableSortBy: true,
      disableFilters: true,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'Passport',
      accessor: 'passport',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Preferable currency',
      accessor: 'preferableCurrency',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Primary phone',
      accessor: 'primaryPhone',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Sex',
      accessor: 'sex',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: SEX_OPTIONS,
      onFilter,
      onRemove,
    },
    {
      Header: 'User type',
      accessor: 'userType',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(
        USER_TYPE_OPTIONS.map(({ value }) => value),
        {
          capitalized: true,
        },
      ),
      onFilter,
      onRemove,
    },
  ];
}
