import { get } from 'lodash';

/**
 * Parses an error object and extracts relevant information to create an error object with title and description.
 *
 * @param {unknown} error - The error object to parse.
 * @returns {{ title: string, description: string }} - The parsed error object with title and description.
 */
export function parseError(error: unknown) {
  const reject = {
    description: get(error, 'response.data.data.description'),
    error: get(error, 'response.data.data.error'),
    useValidation: get(
      error,
      'response.data.data.parameter_permissions.useValidation',
      [],
    ),
    descriptionWfe: get(error, 'response.data.message.description'),
  };

  // common case
  if (typeof reject.description === 'string') {
    return {
      title: get(error, 'response.data.data.message', 'Unknown error message'),
      description: reject.description,
    };
  }

  // common case
  if (typeof reject.description === 'object') {
    return {
      title: get(error, 'response.data.data.message', 'Unknown error message'),
      description: Object.entries(reject.description)
        .map(
          ([key, value]) =>
            `${key}: ${
              typeof value === 'object' ? JSON.stringify(value) : value
            }`,
        )
        .join('; '),
    };
  }

  // case when user lost authorization, for example you can turn off Mod Header after loading page
  if (typeof reject.error === 'string') {
    return {
      title: reject.error,
      description: '',
    };
  }

  // case when user doesn't have some permission, for example "Transfer without validation"
  if (reject.useValidation.length && typeof reject.error === 'string') {
    return {
      title: reject.error,
      description: reject.useValidation.join('; '),
    };
  }

  /**
   * Case when error message is from different endpoint
   *
   * {
      "message": {
        "message": "Bad data",
        "description": {
          "3": {
            "amount": "value is not a valid decimal"
          }
        }
      },
      "statusCode": 400
   * }
   */
  if (typeof reject.descriptionWfe === 'object') {
    return {
      title: get(
        error,
        'response.data.message.message',
        'Unknown error message',
      ),
      description: Object.entries(reject.descriptionWfe)
        .map(
          ([key, value]) =>
            `${key}: ${
              typeof value === 'object' ? JSON.stringify(value) : value
            }`,
        )
        .join('; '),
    };
  }

  return {
    title: get(error, 'response.data.message', 'Unknown error message'),
    description: '',
  };
}
