import * as Yup from 'yup';

export const DEFAULT_VALUES = {
  from: { value: '', label: '' },
  to: { value: '', label: '' },
  username: { value: '', label: '' },
  asset: '',
  currency: '',
  comment: '',
  internalComment: '',
  amount: '',
  type: 'asset',
  price: '',
};

export const getTransferAddFormValidationSchema = (type: string) => {
  return Yup.object({
    from: Yup.object()
      .test('from', 'From is required', (value) => Boolean(value?.value))
      .nullable(),
    to: Yup.object()
      .test('to', 'To is required', (value) => Boolean(value?.value))
      .nullable(),
    username: Yup.object()
      .test('user', 'User is required', (value) => Boolean(value?.value))
      .nullable(),
    asset:
      type === 'asset'
        ? Yup.string().required().typeError('Asset is required')
        : Yup.string().nullable(),
    currency:
      type === 'currency'
        ? Yup.string().required().typeError('Currency is required')
        : Yup.string().nullable(),
    amount: Yup.number()
      .typeError('Amount is required')
      .required('Amount is required'),
    comment: Yup.string(),
    internalComment: Yup.string(),
  });
};

export const TYPE_OPTIONS = [
  { label: 'Asset', value: 'asset' },
  { label: 'Currency', value: 'currency' },
];
