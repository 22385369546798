import { useCallback, useMemo } from 'react';
import { Modal } from 'react-ui-kit-exante';

interface IDeleteGroupModalProps {
  onClose: () => void;
  onDelete: () => Promise<void>;
}

export const DeleteGroupModal = ({
  onClose,
  onDelete,
}: IDeleteGroupModalProps) => {
  const handleDelete = useCallback(async () => {
    await onDelete();
  }, [onDelete]);

  const confirmButton = useMemo(
    () => ({
      handleConfirm: handleDelete,
    }),
    [handleDelete],
  );

  return (
    <Modal
      confirmButton={confirmButton}
      isOpened
      keepMounted={false}
      onClose={onClose}
      title="Are you sure?"
    >
      Do you want to delete this group?
    </Modal>
  );
};
