import { ICreateClientPayload } from '~/resources/clients';

export const defaultValues: ICreateClientPayload = {
  id: '',
  incorporationType: 'INDIVIDUAL JOINT',
  legalEntity: 'Malta',
  name: '',
  role: 'CLIENT',
  clientType: 'PROFESSIONAL',
  branding: '',
};

export const EMPTY_STRING = ' ';
