import { ReactNode } from 'react';

import { StyledWrapper } from './Wrapper.styled';

interface IWrapperProps {
  children: ReactNode;
}

export const Wrapper = ({ children }: IWrapperProps) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};
