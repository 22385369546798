import { Panel } from 'react-ui-kit-exante';

import { useGetAccountsQuery } from '~/api';
import { IClientTabComponentProps } from '~/pages/ClientPage/TabManagement/types';
import { StaticCenteredLoader } from '~/shared/components';

import { ClientSummary } from './ClientSummary';

export const ClientSummaryTabContainer = ({
  clientId,
}: IClientTabComponentProps) => {
  const {
    data: clientAccounts,
    isLoading,
    isFetching,
  } = useGetAccountsQuery(
    { clientId },
    {
      skip: !clientId,
    },
  );

  if (isLoading || isFetching) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  const accounts = clientAccounts?.accounts;
  const accountIDs = accounts?.map((item) => item.id) || [];

  if (!accountIDs.length) {
    return <Panel>No data</Panel>;
  }

  return <ClientSummary accountIDs={accountIDs} />;
};
