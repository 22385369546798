import { get, set, omit, pick } from 'lodash';

import { REGEX_DOUBLE_COLON } from '~/constants';
import {
  ISymbolPermissions,
  TChangedPermissions,
} from '~/pages/SymbolPermissionsPage/types';
import { RowType } from '~/types/common';
import {
  ISymbolTreeStructure,
  ISymbolFlatStructure,
  ISymbolTreeResponse,
} from '~/types/symbolPermissions';

import { DEFAULT_SYMBOLS_PERMISSIONS_SET } from './constants';

export function convertPermissionsStructureFromFlatToTree(
  items: ISymbolFlatStructure[],
  permissionsByPath: Record<string, ISymbolPermissions>,
  changedPermissions: TChangedPermissions,
): ISymbolTreeResponse {
  const tree: ISymbolTreeStructure[] = [];
  const matchIdAndPositionInNonSearchTable: Record<string, string | number> =
    {};
  const newPermissionsByPath: Record<string, ISymbolPermissions> = {};

  items.forEach((item) => {
    const [parent, current] = item.path.split(REGEX_DOUBLE_COLON);

    if (!current) {
      const pushItem: ISymbolTreeStructure = {
        ...DEFAULT_SYMBOLS_PERMISSIONS_SET,
        ...omit(item, 'permissions'),
        ...item.permissions,
        ...(permissionsByPath[item.path]?.override
          ? permissionsByPath[item.path]
          : {}),
        ...(permissionsByPath[item.path]?.override === false
          ? {
              ...item.permissions,
              override: false,
            }
          : {}),
        ...changedPermissions[item.path],
        rowType: RowType.Node,
      };

      tree.push(pushItem);
      newPermissionsByPath[item.path] = pick(
        pushItem,
        Object.keys(DEFAULT_SYMBOLS_PERMISSIONS_SET),
      ) as ISymbolPermissions;
      matchIdAndPositionInNonSearchTable[item.path] = tree.length - 1;
    } else {
      const subRows: ISymbolTreeStructure[] = get(
        tree,
        `${matchIdAndPositionInNonSearchTable[parent]}.subRows`,
        [],
      );
      subRows.push({
        ...DEFAULT_SYMBOLS_PERMISSIONS_SET,
        ...omit(item, 'permissions'),
        ...item.permissions,
        ...(permissionsByPath[item.path]?.override
          ? permissionsByPath[item.path]
          : {}),
        ...(permissionsByPath[item.path]?.override === false
          ? {
              ...item.permissions,
              override: false,
            }
          : {}),
        ...changedPermissions[item.path],
        displayName: item.displayName || current,
        rowType: RowType.Node,
      });

      matchIdAndPositionInNonSearchTable[item.path] = `${
        matchIdAndPositionInNonSearchTable[parent]
      }.subRows.${subRows.length - 1}`;

      set(
        tree,
        `${matchIdAndPositionInNonSearchTable[parent]}.subRows`,
        subRows,
      );
    }
  });

  return { tree, matchIdAndPositionInNonSearchTable, newPermissionsByPath };
}
