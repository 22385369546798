import { object, string } from 'yup';

import { BO_SUPPORTED_ACCOUNT_TYPES } from '~/constants/accountTypes';

import { IValidationSchemaProps } from '../constants';

import { withInherit } from './withInherit';

export const getValidationSchema = ({
  accountPurposes,
  accountStatuses,
}: IValidationSchemaProps) =>
  object({
    id: string(),
    accountPurpose: string().oneOf([...withInherit(accountPurposes), null]),
    type: string().oneOf(withInherit(BO_SUPPORTED_ACCOUNT_TYPES)).required(),
    status: string().oneOf(accountStatuses).required(),
    template: string(),
  });
