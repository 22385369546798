import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';

export function getNearestFieldBySubkey<T>(
  object: Record<string, T>,
  path: string,
): T | null {
  if (object[path]) {
    return object[path];
  }

  let enumerablePath = path.replace(REGEX_LAST_SEGMENT_IN_PATH, '');

  while (!object[enumerablePath]) {
    if (!enumerablePath.match(REGEX_LAST_SEGMENT_IN_PATH)) {
      return null;
    }

    enumerablePath = enumerablePath.replace(REGEX_LAST_SEGMENT_IN_PATH, '');
  }

  return object[enumerablePath];
}
