import { IPosition } from '~/types/accounts';
import { IFooter } from '~/types/table';

export const DescriptionFooter = ({
  column: { preFilteredRows },
}: IFooter<IPosition>) => {
  const values = preFilteredRows[0].values;

  if (preFilteredRows.length === 1 || Boolean(values.symbolId)) {
    return null;
  }

  return <b>Total</b>;
};
