import { get, set } from 'lodash';

import { REGEX_DOUBLE_COLON, DEFAULT_OPTION_NULLABLE } from '~/constants';
import { transformDataToSelectOptions } from '~/shared/utils';
import {
  convertCommissionsRulesToArray,
  getTotal,
} from '~/shared/utils/commissions';
import { ICommissionTree, ICommissionTreeResponse } from '~/types/commissions';
import { RowType } from '~/types/common';
import { IOption } from '~/types/form';

import {
  IConvertCommissionsStructureFromFlatToTreeReturn,
  ICommissionGroupsResponse,
} from './types';

export function transformCommissionsGroupsToOptions({
  data,
}: {
  data?: ICommissionGroupsResponse[];
}) {
  if (!data) {
    return {
      data: [],
    };
  }

  return {
    data: [
      DEFAULT_OPTION_NULLABLE,
      ...transformDataToSelectOptions(data, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as IOption[],
  };
}

export function convertCommissionsStructureFromFlatToTree(
  items: ICommissionTreeResponse[],
): IConvertCommissionsStructureFromFlatToTreeReturn {
  const tree: ICommissionTree[] = [];
  const positionByIdInTree: Record<string, string> = {};

  items.forEach(({ commission: { rules, ...commission }, ...item }) => {
    const [parent, current] = item.path.split(REGEX_DOUBLE_COLON);

    if (!current) {
      const arrayedRules = convertCommissionsRulesToArray(rules);

      tree.push({
        ...item,
        ...commission,
        ...arrayedRules,
        total: getTotal(arrayedRules),
        rowType: RowType.Node,
        subRows: [],
      });
      positionByIdInTree[item.path] = String(tree.length - 1);
    } else {
      const subRows: ICommissionTree[] = get(
        tree,
        `${positionByIdInTree[parent]}.subRows`,
        [],
      );

      const arrayedRules = convertCommissionsRulesToArray(rules);

      subRows.push({
        ...item,
        ...commission,
        ...arrayedRules,
        total: getTotal(arrayedRules),
        rowType: RowType.Node,
        subRows: [],
      });
      positionByIdInTree[item.path] = `${positionByIdInTree[parent]}.subRows.${
        subRows.length - 1
      }`;
      set(tree, `${positionByIdInTree[parent]}.subRows`, subRows);
    }
  });

  return { tree, positionByIdInTree };
}
