import { useState, useEffect, useMemo } from 'react';
import { Tooltip, Loader } from 'react-ui-kit-exante';

import { FormCheckboxContainer } from '~/containers/form';

import TakeCommissionCheckboxStyles from './TakeCommissionCheckbox.module.css';

const SHOW_WARNING_DURATION = 5000;

export const TakeCommissionCheckbox = ({
  prefix,
  commission,
  isLoading,
  watchFields,
  listOfFields,
}: {
  prefix: string;
  commission: string | null;
  isLoading: boolean;
  watchFields: unknown[];
  listOfFields: readonly string[];
}) => {
  const [showCommissionTooltip, setShowCommissionTooltip] = useState(false);
  const [takeCommissionTimer, setCommissionTimer] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (takeCommissionTimer !== null) {
        clearTimeout(takeCommissionTimer);
      }
    };
  }, [takeCommissionTimer]);

  const requiredFields = useMemo(
    () =>
      listOfFields
        .slice(0, listOfFields.length - 1)
        .map((field) => field.split('.')[1])
        .join(', '),
    [listOfFields],
  );

  const handleChange = (
    _: unknown,
    __: unknown,
    defaultCallback: () => unknown,
  ) => {
    if (
      watchFields
        .slice(0, watchFields.length - 1)
        .every(
          (field: unknown) =>
            !['', 'null', 'undefined'].includes(String(field).trim()),
        )
    ) {
      setShowCommissionTooltip(false);
      defaultCallback();
    } else {
      setShowCommissionTooltip(true);
      const timerId = setTimeout(() => {
        setShowCommissionTooltip(false);
        setCommissionTimer(null);
      }, SHOW_WARNING_DURATION);

      setCommissionTimer(timerId);
    }
  };

  return (
    <Tooltip
      placement="right"
      title={`To calculate the commission, please fill required fields: ${requiredFields}`}
      open={showCommissionTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <>
        <FormCheckboxContainer
          label="Take commission"
          name={`${prefix}.takeCommission`}
          onChangeHandler={handleChange}
        />

        {isLoading || commission ? (
          <div className={TakeCommissionCheckboxStyles.CommissionContainer}>
            : {isLoading ? <Loader /> : commission}
          </div>
        ) : (
          <span>: 0</span>
        )}
      </>
    </Tooltip>
  );
};
