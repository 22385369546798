import { useEffect, useState } from 'react';
import { Checkbox, Tooltip } from 'react-ui-kit-exante';

import { PATH_DELIMITER } from '~/constants';
import { useTimer } from '~/hooks';
import { useCopyPasteValues } from '~/pages/RiskArrays/context/CopyPasteValuesContext';

import {
  NOT_ALLOWED_TO_CHANGE_MSG,
  rowTypes,
  SHOW_WARNING_TIME,
  tooltipStyles,
} from '../../constants';
import { useRiskArrayContext } from '../../context/RiskArrayTreeContext';
import { ITreeNode } from '../../types';
import { ParentCheckboxCell } from '../ParentCheckboxCell';

interface ICheckBoxCellProps {
  id: string;
  name: string;
  path: string;
  type: keyof typeof rowTypes;
  subRows?: ITreeNode[];
  root?: string;
}

export const CheckBoxCell = ({
  id,
  name,
  path,
  type,
  subRows,
  root,
}: ICheckBoxCellProps) => {
  const {
    getValue,
    setValue,
    addRequestToUpdate,
    removeRequestToUpdate,
    isDirtyField,
  } = useRiskArrayContext();
  const { addQueryToUpdate } = useCopyPasteValues();
  const { startWithDelay } = useTimer();
  const [showWarning, setShowWarning] = useState(false);

  const checked = getValue({ name, path });
  const hasBeenChanged = isDirtyField(name, path);
  const hasLeaves = subRows && subRows.at(0)?.type === rowTypes.leaf;
  const [rootName] = root ? root.split(PATH_DELIMITER) : [];
  const isOption = rootName === 'OPTION';

  const handleChange = ({
    target: { value },
  }: {
    target: { value?: unknown };
  }) => {
    if (isOption) {
      setShowWarning(true);
      startWithDelay(() => setShowWarning(false), SHOW_WARNING_TIME);
      return;
    }
    const newValue = typeof value === 'boolean' ? value : !checked;

    if (newValue !== checked) {
      setValue({ name, path, value: newValue });
      addRequestToUpdate({
        id,
        name,
        path,
        value: newValue,
      });
    } else {
      removeRequestToUpdate({ name, id, path });
    }
  };

  useEffect(() => {
    addQueryToUpdate({ id, name, handleChange });
  }, []);

  if (hasLeaves) {
    return (
      <ParentCheckboxCell
        name={name}
        path={path}
        subRows={subRows}
        isOption={isOption}
      />
    );
  }

  if (type !== rowTypes.leaf) {
    return null;
  }

  return (
    <Tooltip
      title={NOT_ALLOWED_TO_CHANGE_MSG}
      sx={tooltipStyles}
      placement="top"
      open={showWarning}
    >
      <Checkbox
        onChange={handleChange}
        checked={Boolean(checked)}
        warning={hasBeenChanged}
      />
    </Tooltip>
  );
};
