import { uniq } from 'lodash';
import { TestContext, ValidationError } from 'yup';

import { findAllIndexes } from '~/shared/utils';
import { IOption } from '~/types/form';

export const createArraysValidation =
  <T extends Record<keyof T, IOption | string | null>>(uniqKey: keyof T) =>
  (initialValues: T[], { path, createError }: TestContext) => {
    const emptyIndexes: number[] = [];
    const idKeys: string[] = [];

    initialValues.forEach((item, index) => {
      const currentItem = item[uniqKey];

      if (currentItem === null) {
        emptyIndexes.push(index);
      } else {
        idKeys.push(
          typeof currentItem !== 'string' ? currentItem.value : currentItem,
        );
      }
    });

    const indexes = idKeys.reduce<number[][]>((acc, curr) => {
      const allKeyIndexes = findAllIndexes(idKeys, curr);

      return [...acc, allKeyIndexes];
    }, []);
    const duplicateIndexes = uniq(
      indexes.reduce((acc, curr) => {
        if (curr.length > 1) {
          return acc.concat(curr);
        }

        return acc;
      }, []),
    );

    if (duplicateIndexes.length > 0) {
      return new ValidationError(
        duplicateIndexes.map((item) =>
          createError({
            message: `Field should be unique`,
            path: `${path}.${item}.${uniqKey as string}`,
          }),
        ),
      );
    }

    if (emptyIndexes.length > 0) {
      return new ValidationError(
        emptyIndexes.map((item) =>
          createError({
            message: 'Field can not be empty',
            path: `${path}.${item}.${uniqKey as string}`,
          }),
        ),
      );
    }

    return true;
  };
