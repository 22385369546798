import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { Checkbox, IColumn, SearchInput } from 'react-ui-kit-exante';

import { useOutsideClick } from '~/hooks';
import { SettingsIcon } from '~/images/icons';

import ColumnsSelectStyles from './ColumnsSelect.module.css';

interface IColumnsSelectProps {
  columns: IColumn<Record<string, unknown>>[];
  selectedColumn: Record<string, boolean>;
  onChange(key: string, value: boolean): void;
  onReset(): void;
}

export const ColumnsSelect = ({
  columns,
  selectedColumn,
  onChange,
  onReset,
}: IColumnsSelectProps) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const toggleMenu = () => setOpen((prev) => !prev);
  const handleMenuClose = () => {
    setOpen(false);
    setSearchValue('');
  };

  const filteredColumns = useMemo(
    () =>
      columns.filter((column) =>
        column.Header.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [columns, searchValue],
  );

  useOutsideClick(ref, handleMenuClose);

  const handleKeyChange =
    (key: string) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
      onChange(key, checked);

  return (
    <div className={ColumnsSelectStyles.Container} ref={ref}>
      <button
        className={ColumnsSelectStyles.Button}
        onClick={toggleMenu}
        type="button"
      >
        <SettingsIcon />
      </button>

      {isOpen && (
        <div className={ColumnsSelectStyles.ListWrapper}>
          <div className={ColumnsSelectStyles.SearchWrapper}>
            <SearchInput onChange={setSearchValue} />
          </div>

          <ul className={ColumnsSelectStyles.List}>
            {filteredColumns.map(({ accessor, Header }) => (
              <li key={accessor} className={ColumnsSelectStyles.Item}>
                <Checkbox
                  label={Header}
                  checked={selectedColumn[accessor]}
                  onChange={handleKeyChange(accessor)}
                />
              </li>
            ))}

            <li className={ColumnsSelectStyles.Item}>
              <button
                className={ColumnsSelectStyles.ResetButton}
                type="button"
                onClick={onReset}
              >
                Reset to defaults
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
