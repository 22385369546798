import { DEFAULT_OPTION_NULLABLE } from '~/constants';
import { transformDataToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';
import { LimitSetResponse } from '~/types/limits';

import { ILimitsOptionsReduce } from '../types';

// We should use this params scheme because this function uses as `selectFromResult` in use query hook
export const transformLimitGroupsToOptions = ({
  data,
  isLoading = false,
  isFetching = false,
}: Partial<{
  data?: LimitSetResponse[];
  isLoading: boolean;
  isFetching: boolean;
}>) => {
  if (!data) {
    return {
      data: [],
      isLoading,
      isFetching,
    };
  }

  const processedData = data.reduce<ILimitsOptionsReduce>(
    (acc, { isDefault, id, name }) => {
      if (isDefault) {
        acc.defaultOption = {
          value: String(id),
          label: DEFAULT_OPTION_NULLABLE.label,
        };
      } else {
        acc.transformData.push({ name, id });
      }
      return acc;
    },
    {
      defaultOption: {
        value: '',
        label: '',
      },
      transformData: [],
    },
  );

  return {
    data: [
      processedData.defaultOption,
      ...transformDataToSelectOptions(processedData?.transformData, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as IOption[],
    isLoading,
    isFetching,
  };
};
