import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_ARRAY } from '~/constants';
import { EStatus } from '~/types/api';
import { ICurrency } from '~/types/currencies';
import { IStateField } from '~/types/state';

import { fetchCurrencies } from './currencies.actions';

const initialState: IStateField<ICurrency[]> = {
  status: EStatus.Idle,
  error: null,
  data: EMPTY_ARRAY,
};

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrencies.pending, (state) => {
      state.status = EStatus.Loading;
      state.error = null;
    });

    builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = EStatus.Succeeded;
    });

    builder.addCase(fetchCurrencies.rejected, (state, action) => {
      state.error = action.payload || null;
      state.status = EStatus.Failed;
    });
  },
});
