import { Notification } from 'react-ui-kit-exante';

import { isChangeCurrencyEvent } from './isChangeCurrencyEvent';

export function getNewCurrencyFromEvent(event: Event) {
  if (isChangeCurrencyEvent(event)) {
    const { detail } = event;
    const { getCurrentCurrency } = detail;
    let data = getCurrentCurrency();

    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch {
        Notification.error({
          title: 'Unexpected error while getting currency',
        });
        return '';
      }
    }

    const { id } = data;

    return id;
  }

  return null;
}
