import { createApi } from '@reduxjs/toolkit/query/react';

import { SESSION_ID_RUN_UI_WORKFLOW_KEY } from '~/constants';
import {
  baseQueryHandler,
  getAccessTokenFromCookies,
  getSessionIdFromCookie,
  paramsTransformer,
  sendNotification,
} from '~/shared/utils';
import { getDefaultHeaders } from '~/shared/utils/apiRequest/helpers';
import { IRequestArguments, TParams } from '~/types/api';
import {
  ITransactionPostRequest,
  ITransactionPostResponse,
  ITransactionsState,
  TFetchTransactionColumnsResponse,
  TImportTransactions,
} from '~/types/transactions';

import {
  TRANSACTIONS_PARAMS_MAPPER,
  REQUESTED_FIELDS,
} from './transactions.api.constants';
import {
  FETCH_TRANSACTIONS,
  TRANSACTIONS_ENDPOINT,
  getImportTransactionEndpoint,
  getPostTransactionEndpoint,
  getRollbackTransactionURL,
  getTransactionColumnsEndpoint,
  getUpdateTransactionEndpoint,
} from './transactions.api.endpoints';
import {
  prepareFiltersToRequestTransactions,
  transactionsMappers,
  getDataForPostManualTransaction,
} from './transactions.api.helpers';

const tagTypes = [
  'Transactions',
  'Transaction',
  'TransactionsForExport',
] as const;

export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: baseQueryHandler,
  tagTypes,
  endpoints: function tradesApiEndpoints(builder) {
    return {
      getTransactionColumns: builder.query<
        TFetchTransactionColumnsResponse,
        void
      >({
        query: () => ({
          url: getTransactionColumnsEndpoint(),
        }),
      }),
      getTransactions: builder.query<ITransactionsState, TParams>({
        queryFn: async function getTransactions(
          params,
          _,
          __,
          fetchWithBaseQuery,
        ) {
          const preparedParams = prepareFiltersToRequestTransactions(params);

          const afterPreparing = paramsTransformer({
            params: preparedParams,
            mapper: TRANSACTIONS_PARAMS_MAPPER,
          });
          const { data, error } = await fetchWithBaseQuery({
            params: { ...afterPreparing, fields: REQUESTED_FIELDS },
            url: FETCH_TRANSACTIONS,
          });
          if (error || !data) {
            return {
              data: { transactions: [], pagination: { total: 0 } },
            };
          }
          return {
            data: {
              transactions: transactionsMappers(data.data),
              pagination: data.pagination,
            },
          };
        },
        providesTags: ['Transactions'],
      }),
      postTransactions: builder.mutation<
        ITransactionPostResponse,
        IRequestArguments<ITransactionPostRequest>
      >({
        queryFn: async function fetchPostTradesHandler(
          { params, onSuccess, onError },
          _api,
          _extraOptions,
          fetchWithBaseQuery,
        ) {
          if (params) {
            let result;

            const { accountId } = params;
            const shouldSendThroughWorkflowApi =
              params.extraData && params.extraData['4EyesCheck'];
            const postTransactionEndpoint = getPostTransactionEndpoint(
              accountId as string,
              shouldSendThroughWorkflowApi,
            );
            const preparedData = getDataForPostManualTransaction(params);
            if (window.runUIhistoryContainer && shouldSendThroughWorkflowApi) {
              const headers = getDefaultHeaders(
                getSessionIdFromCookie(SESSION_ID_RUN_UI_WORKFLOW_KEY),
                getAccessTokenFromCookies(),
              );
              result = await fetchWithBaseQuery({
                url: postTransactionEndpoint,
                method: 'POST',
                data: preparedData,
                headers,
              });
            } else {
              result = await fetchWithBaseQuery({
                url: postTransactionEndpoint,
                method: 'POST',
                data: preparedData,
              });
            }

            const { data, error } = result;

            if (error || !data) {
              if (typeof onError === 'function' && error) {
                onError(error);
              }

              return { error: String(error) };
            }

            onSuccess?.();

            return { data };
          }
          return { data: null };
        },
        invalidatesTags: function invalidateTagsAfterPostTrades(res, error) {
          if (error || !res) {
            return [];
          }
          const { uuid } = res;
          return ['Transactions', { type: 'Transaction', id: uuid }];
        },
      }),
      importTransaction: builder.mutation<
        ITransactionPostResponse,
        TImportTransactions
      >({
        queryFn: async function importTransactionHandler(
          { transactions, bulkManualTransaction },
          _api,
          _extraOptions,
          fetchWithBaseQuery,
        ) {
          if (transactions?.length) {
            const postTransactionEndpoint = getImportTransactionEndpoint(
              bulkManualTransaction,
            );

            const preparedData = transactions.map(
              getDataForPostManualTransaction,
            );
            const isInsideRun =
              window.runUIhistoryContainer && bulkManualTransaction;

            let response;

            if (isInsideRun) {
              const headers = getDefaultHeaders(
                getSessionIdFromCookie(SESSION_ID_RUN_UI_WORKFLOW_KEY),
                getAccessTokenFromCookies(),
              );

              response = await fetchWithBaseQuery({
                url: postTransactionEndpoint,
                method: 'POST',
                data: preparedData,
                headers,
              });
            } else {
              response = await fetchWithBaseQuery({
                url: postTransactionEndpoint,
                method: 'POST',
                data: preparedData,
              });
            }

            const { data, error } = response;

            if (error || !data) {
              return { error: error || 'Unknown error message' };
            }

            if (bulkManualTransaction) {
              sendNotification(
                'All transactions uploaded and sent to 4 eyes check',
                'success',
              );
            } else {
              sendNotification(
                'All transactions uploaded successfully',
                'success',
              );
            }

            return { data };
          }

          return { data: null };
        },
      }),
      updateTransaction: builder.mutation({
        query: ({ uuid, ...data }) => ({
          url: getUpdateTransactionEndpoint(uuid),
          method: 'POST',
          data,
        }),
      }),
      rollbackTransaction: builder.mutation<unknown, number>({
        query: (id) => ({
          url: getRollbackTransactionURL(id),
          method: 'POST',
        }),
      }),
      postLockedAsset: builder.mutation({
        query: (data) => ({
          url: TRANSACTIONS_ENDPOINT,
          method: 'POST',
          data: [data],
        }),
      }),
    };
  },
});

export const {
  useGetTransactionColumnsQuery,
  useImportTransactionMutation,
  useLazyGetTransactionsQuery,
  usePostLockedAssetMutation,
  usePostTransactionsMutation,
  useRollbackTransactionMutation,
  useUpdateTransactionMutation,
} = transactionsApi;
