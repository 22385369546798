import { mergeWith } from 'lodash';
import { FC, useMemo } from 'react';
import { defaultTheme, createTheme, ThemeProvider } from 'react-ui-kit-exante';

import { TChildren } from '~/types/TChildren';

import { theme } from './theme';

export const ComposedThemeProvider: FC<TChildren> = ({ children }) => {
  const brandingTheme = window.WEB_BO_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(
      mergeWith(
        {},
        defaultTheme,
        theme,
        brandingTheme,
        (objValue, srcValue) => {
          if (typeof objValue === 'string' && srcValue === '') {
            return objValue;
          }

          return undefined;
        },
      ),
    );
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
