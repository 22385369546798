import { convertToFormattedString } from '../../utils/convertToFormattedString';

import TooltipWrapperStyles from './TooltipWrapper.module.css';

interface TooltipWrapperProps {
  value: unknown;
  tryToParse?: boolean;
}

export const TooltipWrapper = ({ value, tryToParse }: TooltipWrapperProps) => {
  const preformattedValue = convertToFormattedString(value, tryToParse);

  return (
    <div className={TooltipWrapperStyles.Wrapper}>{preformattedValue}</div>
  );
};
