import type { ActionTypes } from '../constants';
import { isEqualStates } from '../helpers';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

export function editToken(
  state: IStatePasswordManager,
  payload: TPasswordManagerPayload[ActionTypes.EDIT_TOKEN],
) {
  if (!payload.type) {
    return state;
  }

  const newState = {
    ...state,
    data: {
      ...state.data,
      [payload.type]: state.data[payload.type].map((token) =>
        token.name !== payload.name
          ? token
          : {
              ...token,
              ...payload,
            },
      ),
    },
  };

  return {
    ...newState,
    disableSaveButton: isEqualStates(newState),
  };
}
