import { SerializedError } from '@reduxjs/toolkit';
import { cloneDeep, get, set } from 'lodash';
import { MutableRefObject } from 'react';

import { displayErrorNotification, is403GetError } from '~/shared/utils';
import { toDate } from '~/shared/utils/dates';
import { EMPTY_SERVICE_RESPONSE } from '~/shared/utils/services';
import { IServiceResponse } from '~/types/api';
import { TFormHandlerNew } from '~/types/form';
import { TDispatch } from '~/types/store';
import {
  ICoreFormUser,
  IFormUserClient,
  TPossibleAccessRights,
  ListAccessTypes,
} from '~/types/users';

import { TFormData } from '../types';

interface ICreateHandlersMapArguments {
  dispatch: TDispatch;
  user?: ICoreFormUser | null;
  previousUserClients: MutableRefObject<IFormUserClient[] | undefined>;
  userPermissions: Record<ListAccessTypes, TPossibleAccessRights>;
  updateUser: (params: {
    id: number;
    data: ICoreFormUser;
  }) => Promise<
    | { data: IServiceResponse<ICoreFormUser> }
    | { error: string | SerializedError }
  >;
  updateUserClients: (params: {
    userClients: IFormUserClient[];
    previousUserClients: MutableRefObject<IFormUserClient[]>;
  }) => Promise<
    | { data: IServiceResponse<IFormUserClient[]> }
    | { error: string | SerializedError }
  >;
  updateUserGroupSettings: (params: {
    userName: string;
    permissionsSetId: number | null;
  }) => Promise<
    | {
        data: IServiceResponse<{ permissionsSetId: number }>;
      }
    | { error: string | SerializedError }
  >;
}

export const createHandlersMap = ({
  previousUserClients,
  updateUser,
  updateUserClients,
  updateUserGroupSettings,
  user,
  userPermissions,
}: ICreateHandlersMapArguments): TFormHandlerNew<TFormData> | null => {
  if (!user) {
    return null;
  }

  return {
    user: (data) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      try {
        const { notifications, originatorIds, ...restDataInfo } = data.info;
        return updateUser({
          id: data.id,
          data: {
            ...data,
            info: {
              ...restDataInfo,
              notifications: JSON.parse(notifications),
              ...(userPermissions['User info: originator ids'].write && {
                originatorIds,
              }),
            },
          },
        });
      } catch (error) {
        if (!is403GetError(error)) {
          displayErrorNotification(error);
        }

        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }
    },
    userClients: async (data) => {
      if (!data || !isIFormUserClient(previousUserClients)) {
        return EMPTY_SERVICE_RESPONSE;
      }

      const response = await updateUserClients({
        userClients: data,
        previousUserClients,
      });

      if ('error' in response) {
        return EMPTY_SERVICE_RESPONSE;
      }

      return response.data;
    },
    groupSettings: async (data, allTabsData) => {
      if (data && allTabsData.user?.username) {
        const response = await updateUserGroupSettings({
          userName: allTabsData.user.username,
          permissionsSetId: data.permissionsSetId,
        });

        if ('error' in response) {
          return EMPTY_SERVICE_RESPONSE;
        }

        return response.data;
      }

      return EMPTY_SERVICE_RESPONSE;
    },
  };
};

function isIFormUserClient(
  value: ICreateHandlersMapArguments['previousUserClients'],
): value is MutableRefObject<IFormUserClient[]> {
  return Boolean(value.current);
}

export const getDefaultValues = (formData: TFormData) => {
  const clonedFormData = cloneDeep(formData);
  const birthDate = get(formData, 'user.info.birthDate');

  if (birthDate) {
    set(clonedFormData, 'user.info.birthDate', toDate(birthDate));
  }

  return clonedFormData;
};
