import { PROXY_CORE_PATH } from '~/shared/utils';
import { LimitSetResponse } from '~/types/limits';

import { limitsApi } from './limitsApi';

interface EditGroupLimitsParams {
  name: string;
  id: number;
}

export const { useEditGroupLimitsMutation } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    editGroupLimits: builder.mutation<LimitSetResponse, EditGroupLimitsParams>({
      query: ({ name, id }) => ({
        url: `${PROXY_CORE_PATH}v2.0/limits_set/${id}`,
        method: 'POST',
        data: {
          name,
        },
      }),
    }),
  }),
});
