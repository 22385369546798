import { mixed, number, object, string } from 'yup';

import {
  emptyStringToNull,
  validationUserFormArray,
} from '~/shared/utils/validation';

export const getValidationSchema = () =>
  object({
    risk: object({
      marginThresholdOverride: number()
        .transform(emptyStringToNull)
        .nullable()
        .typeError('Should be a number'),
    }),
    account: object({
      originatorIds: object({
        default: string().nullable(),
        overrides: mixed().test({
          name: 'originator-ids-overrides-validation',
          test: validationUserFormArray,
        }),
      }),
    }),
  });
