import { DEFAULT_LIMITS_TREE } from '../constants';
import { FETCH_LIMITS_TREE } from '../endpoints';
import { convertLimitsStructureFromFlatToTree } from '../helpers';
import { ConvertedLimitsFromFlatToStructure } from '../types';

import { limitsApi } from './limitsApi';

export const {
  useGetLimitsTreeQuery,
  useLazyGetLimitsTreeQuery,
  endpoints: {
    getLimitsTree: { matchFulfilled: getLimitsTreeMatchFulfilled },
  },
} = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    getLimitsTree: builder.query<ConvertedLimitsFromFlatToStructure, void>({
      queryFn: async (_, __, ___, fetchWithBaseQuery) => {
        const { data, error } = await fetchWithBaseQuery({
          url: FETCH_LIMITS_TREE,
        });

        if (error || !data) {
          return {
            data: DEFAULT_LIMITS_TREE,
          };
        }

        return {
          data: convertLimitsStructureFromFlatToTree(data.data),
        };
      },
      providesTags: ['LimitsTree'],
    }),
  }),
});
