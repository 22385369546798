import { clone } from 'lodash';
import { RefObject, useCallback, useRef } from 'react';

import { TMatchIdAndPositionInTable } from '../types';

interface IUseMatchIdAndPositionInTable {
  updateMatchIdAndPositionInSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void;
  matchIdAndPositionInNonSearchTable: RefObject<TMatchIdAndPositionInTable>;
  matchIdAndPositionInSearchTable: RefObject<TMatchIdAndPositionInTable>;
  updateMatchIdAndPositionInNonSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void;
}

export function useMatchIdAndPositionInTable(): IUseMatchIdAndPositionInTable {
  const matchIdAndPositionInSearchTable = useRef<TMatchIdAndPositionInTable>(
    {},
  );
  const matchIdAndPositionInNonSearchTable = useRef<TMatchIdAndPositionInTable>(
    {},
  );
  const matchIdAndPositionInSearchTableWithoutInstruments =
    useRef<TMatchIdAndPositionInTable | null>(null);

  const updateMatchIdAndPositionInSearchTable = useCallback(
    (newData: TMatchIdAndPositionInTable) => {
      matchIdAndPositionInSearchTable.current = {
        ...matchIdAndPositionInSearchTable.current,
        ...newData,
      };

      if (!matchIdAndPositionInSearchTableWithoutInstruments.current) {
        matchIdAndPositionInSearchTableWithoutInstruments.current =
          clone(newData);
      }
    },
    [],
  );

  const updateMatchIdAndPositionInNonSearchTable = useCallback(
    (newData: TMatchIdAndPositionInTable) => {
      matchIdAndPositionInNonSearchTable.current = newData;
    },
    [],
  );

  return {
    updateMatchIdAndPositionInSearchTable,
    matchIdAndPositionInNonSearchTable,
    matchIdAndPositionInSearchTable,
    updateMatchIdAndPositionInNonSearchTable,
  };
}
