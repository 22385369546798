import { initResources } from './resources';
import { initApp } from './shared/utils/initApp';

initResources();

window.WEB_BO_UI = {
  ...(window.WEB_BO_UI || {}),
  render: (node: Element) => initApp(node),
};

// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
