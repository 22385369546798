import { apiRequest } from '~/shared/utils';
import { ILogHandleTimeRequest } from '~/types/influxProxy';

import { FETCH_LOG_HANDLE_TIME } from './endpoints';

export class InfluxProxyRepository {
  public static async fetchLogHandleTime(data: ILogHandleTimeRequest) {
    return apiRequest({
      url: FETCH_LOG_HANDLE_TIME,
      method: 'POST',
      data,
    });
  }
}
