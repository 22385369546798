import { UseFormReturn } from 'react-hook-form';

import { ITransactionForm } from '~/types/transactions';

import {
  REQUIRED_VALUE_FOR_COUNTERPARTY_AND_TRANSFER,
  REQUIRED_VALUE_FOR_EXECUTION_COUNTERPARTY,
} from '../constants';

import { useOperationTypesAllowedForAutoConversion } from './useOperationTypesAllowedForAutoConversion';

export function useDisabled(
  watch: UseFormReturn<ITransactionForm>['watch'],
): Record<string, boolean> {
  const { operationTypesAllowedForAutoConversion } =
    useOperationTypesAllowedForAutoConversion();

  const autoConversion = !operationTypesAllowedForAutoConversion.includes(
    watch('operationType'),
  );
  const commission = !watch('takeCommission');
  const dependentFieldsFromOperationType =
    watch('operationType') !== REQUIRED_VALUE_FOR_COUNTERPARTY_AND_TRANSFER;
  const executionCounterparty =
    watch('operationType') !== REQUIRED_VALUE_FOR_EXECUTION_COUNTERPARTY;

  return {
    autoConversion,
    commission,
    dependentFieldsFromOperationType,
    executionCounterparty,
  };
}
