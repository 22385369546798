import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm, FieldErrors } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { ITableViewRules } from '~/types/commissions';
import { RowType } from '~/types/common';

import {
  RULES_FORM_VALIDATION_SCHEMA,
  RULES_FORM_UNIQ_NAME_TEST_NAME,
} from '../constants';
import { findLastNonUniqueStringIndex } from '../helpers';
import { IRulesForm } from '../types';

export function useRulesForm(
  rules: IRulesForm['rules'],
  row: IRulesForm['row'],
  column: IRulesForm['column'],
) {
  const dispatch = useDispatch();

  const isNodeCell = row.original.rowType === RowType.Node;

  const formInstance = useForm({
    defaultValues: { rules },
    resolver: yupResolver(RULES_FORM_VALIDATION_SCHEMA),
  });
  const { getValues, setError } = formInstance;

  const onSubmitHandler = useCallback(
    async (data: Pick<IRulesForm, 'rules'>) => {
      const actionForUpdateValue = isNodeCell
        ? updateNodeValue
        : updateInstrumentValue;

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: data.rules.map(({ rate, rule, name }) => ({
            rate,
            rule,
            name,
          })),
          column: column.id,
        }),
      );
    },
    [column.id, dispatch, isNodeCell, row.original.path],
  );

  const onErrorHandler = useCallback(
    (errors: FieldErrors<{ rules: ITableViewRules[] }>) => {
      if (errors?.rules?.type === RULES_FORM_UNIQ_NAME_TEST_NAME) {
        const nonUniqNameIndex = findLastNonUniqueStringIndex(
          getValues('rules').map(({ name }) => name),
        );

        setError(`rules.${nonUniqNameIndex}.name`, {
          type: 'manual',
          message: 'Name must be unique',
        });
      }
    },
    [getValues, setError],
  );

  const submitHandle = formInstance.handleSubmit(
    onSubmitHandler,
    onErrorHandler,
  );

  return { formInstance, submitHandle };
}
