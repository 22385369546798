import { isEmpty } from 'lodash';

export const getRowPropsHelperTransactions = (
  errorIndexes: Record<number, boolean>,
  hasErrors: boolean,
  index: number,
  uploadedSuccessfully: boolean,
) => {
  return uploadedSuccessfully || hasErrors
    ? {
        style: {
          background: errorIndexes[index]
            ? 'rgb(159, 64, 64)'
            : 'rgb(64, 159, 107)',
          color: isEmpty(errorIndexes) ? undefined : 'white',
        },
      }
    : {};
};
