import { pickBy } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { SERVICE_NAME } from '~/constants';
import { getBookmarkColumns } from '~/hooks/useBookmark/helpers';
import { bookmarksService } from '~/resources';
import {
  TCreateBookmarkPayload,
  TUpdateBookmarkPayload,
} from '~/resources/bookmarks';
import { clearTableFilters } from '~/shared/components/WithBookmarks/helpers';
import { customEvents } from '~/shared/utils';
import { bookmarkApi } from '~/store/bookmark';

import { IBookmarkProps, IBookmarkResponseProps } from './types';
import { useBookmarkData } from './useBookmarkData';

export const useBookmark = ({
  pageName,
  setSearchParams,
  tableId,
}: IBookmarkProps): IBookmarkResponseProps => {
  const dispatch = useDispatch();
  const navigator = window.navigator;
  const currentPath = window.location.href;
  const { pathname } = useLocation();
  const [, pathName] = pathname.slice(1).split('/');
  const { search } = useLocation();

  const { isLoading, selectedBookmark } = useBookmarkData({
    pageName,
    tableId,
  });
  const { id } = selectedBookmark;

  const addBookmarkEvents = useCallback(() => {
    const bookmarkEvents = [
      'onCreateBookmark',
      'onDeleteBookmark',
      'onUpdateBookmark',
    ];

    bookmarkEvents.forEach((eventName) => {
      customEvents.add(eventName, {
        detail: {
          serviceName: SERVICE_NAME,
        },
      });
    });
  }, []);

  useEffect(() => {
    addBookmarkEvents();
  }, [addBookmarkEvents]);

  const handleSaveBookmark = useCallback(
    async (name: string, filters: Record<string, unknown>) => {
      const createPayload: TCreateBookmarkPayload = {
        name,
        table: tableId,
        filters: pickBy(filters, (value) => value !== ''),
        columns: getBookmarkColumns(tableId),
        bookmarkTab: pathName,
      };

      if (!id) {
        const response = await bookmarksService.createBookmark(createPayload);
        dispatch(bookmarkApi.util.invalidateTags(['Bookmark']));
        if (response !== null) {
          customEvents.dispatch('onCreateBookmark');
        }
      } else {
        const updatePayload: TUpdateBookmarkPayload = createPayload;

        const updateResponse = await bookmarksService.updateBookmark(
          id,
          updatePayload,
        );
        dispatch(bookmarkApi.util.invalidateTags(['Bookmark']));
        if (updateResponse !== null) {
          customEvents.dispatch('onUpdateBookmark');
        }
      }
    },
    [dispatch, id, pathName, tableId],
  );

  const handleSaveAsNewBookmark = useCallback(
    async (name: string, filters: Record<string, unknown>) => {
      const createPayload: TCreateBookmarkPayload = {
        name,
        table: tableId,
        filters: pickBy(filters, (value) => value !== ''),
        columns: getBookmarkColumns(tableId),
        bookmarkTab: pathName,
      };

      const response = await bookmarksService.createBookmark(createPayload);
      dispatch(bookmarkApi.util.invalidateTags(['Bookmark']));
      if (response !== null) {
        customEvents.dispatch('onCreateBookmark');
      }
    },
    [dispatch, pathName, tableId],
  );

  const handleShareBookmark = useCallback(() => {
    navigator.clipboard.writeText(currentPath);
    Notification.success({
      title: 'Bookmark url was copied',
    });
  }, [currentPath, navigator]);

  const handleDeleteBookmark = useCallback(async () => {
    if (!id) {
      return;
    }

    const deleted = await bookmarksService.deleteBookmark(id);
    const searchParams = new URLSearchParams(search);

    searchParams.delete('bookmark');
    setSearchParams(searchParams);
    clearTableFilters(setSearchParams);
    if (deleted) {
      customEvents.dispatch('onDeleteBookmark');
    }
  }, [id, search, setSearchParams]);

  return {
    isLoading,
    selectedBookmark,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
  };
};
