import { styled } from 'react-ui-kit-exante';

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme?.color.bg.primary,
  borderBottom: `1px solid ${theme?.color.line.primary}`,
  padding: '24px',
  color: theme?.color.typo.primary,
}));

export const StyledTitle = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '24px',
  fontWeight: 400,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  color: theme?.color.typo.primary,
}));

export const StyledButtons = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
}));

export const StyledStatus = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '330px',
}));

export const StyledSaveButtonContainer = styled('div')(() => ({
  width: '70px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
