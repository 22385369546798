import cn from 'classnames';
import { Panel } from 'react-ui-kit-exante';

import { useGetRoutingSettingsQuery } from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { FormMultiSelectContainer } from '~/containers/form';

import { useHasPermissionForWrite } from './hooks';
import { IRoutingTagsFormProps } from './types';

export const RoutingTagsForm = ({
  accountId,
  name = 'routingTags',
}: Partial<IRoutingTagsFormProps>) => {
  const { error: routingSettingsError } = useGetRoutingSettingsQuery(
    {
      accountId: String(accountId),
    },
    { skip: !accountId },
  );

  const hasPermissionFor = useHasPermissionForWrite({
    accountId,
    name,
  });

  if (!hasPermissionFor.read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled: routingSettingsError || !hasPermissionFor.write,
      })}
      title="Routing Tags"
    >
      <FormMultiSelectContainer
        freeSolo
        label="Add tag"
        name={name}
        options={EMPTY_ARRAY}
        popupIcon={null}
      />
    </Panel>
  );
};
