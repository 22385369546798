export function createEndDate(date: string | Date, utc = false) {
  const end = new Date(date);

  if (utc) {
    end.setUTCHours(23, 59, 59, 999);
  } else {
    end.setHours(23, 59, 59, 999);
  }

  return end;
}
