/**
 * Capitalizes each word in a string, which may be separated by slashes.
 *
 * @param {string} variant - The string containing words that may be separated by slashes.
 * @returns {string} - A new string where each word starts with an uppercase letter.
 *
 * @example
 * // Example usage:
 * capitalize("FUNDING/WITHDRAWAL"); // Returns "Funding/Withdrawal"
 * capitalize("DEBIT CARD");         // Returns "Debit card"
 */
export const capitalize = (variant: string): string =>
  variant
    .split('/')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('/');
