import { IOption } from '~/types/form';

export const SEX_OPTIONS: IOption[] = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export const USER_TYPE_OPTIONS: IOption[] = [
  { value: 'trader', label: 'Authorised Trader' },
  { value: 'attorney', label: 'Power of Attorney' },
  { value: 'internal', label: 'Employee' },
];
