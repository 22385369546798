import { get } from 'lodash';
import { memo, useEffect, useState } from 'react';

import { RefreshIcon } from '~/images/icons';

import { useCopyPasteValues } from '../../context/CopyPasteValuesContext';
import { useRiskArrayContext } from '../../context/RiskArrayTreeContext';

import {
  StyledContainer,
  StyledDisabled,
  StyledInput,
  StyledReset,
} from './IntegerCell.styled';

interface IIntegerCellProps {
  id: string;
  name: string;
  path: string;
  fullPath: string;
}

export const IntegerCell = memo(
  ({ id, name, path, fullPath }: IIntegerCellProps) => {
    const {
      getOriginalCache,
      addRequestToUpdate,
      removeRequestToUpdate,
      getValue,
    } = useRiskArrayContext();
    const { addQueryToUpdate } = useCopyPasteValues();
    const originalCache = getOriginalCache();
    const defaultValue = get(originalCache, fullPath);
    const [localValue, setLocalValue] = useState(() => defaultValue);
    const isAutoCalculated = getValue({ name: 'isAutoCalculated', path });

    const handleResetToDefault = () => {
      setLocalValue(defaultValue);
    };

    const handleChange = ({
      target: { value = '' },
    }: {
      target: { value?: unknown };
    }) => {
      setLocalValue(value);
      const originalValue = get(originalCache, fullPath);
      if (originalValue !== value) {
        addRequestToUpdate({
          id,
          name,
          path,
          value,
        });
      } else {
        removeRequestToUpdate({ name, id, path });
      }
    };

    useEffect(() => {
      addQueryToUpdate({ id, name, handleChange });
    }, []);

    return (
      <StyledContainer>
        {isAutoCalculated ? (
          <StyledDisabled>{localValue}</StyledDisabled>
        ) : (
          <StyledInput
            type="number"
            onChange={handleChange}
            value={localValue}
            isWarning={get(originalCache, fullPath) !== localValue}
          />
        )}

        <StyledReset
          onClick={handleResetToDefault}
          isWarning={get(originalCache, fullPath) !== localValue}
        >
          <RefreshIcon />
        </StyledReset>
      </StyledContainer>
    );
  },
);

IntegerCell.displayName = 'IntegerCell';
