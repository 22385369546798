import { cloneDeep } from 'lodash';

import type { ActionTypes } from '../constants';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

export function updateInitialAuth(
  state: IStatePasswordManager,
  payload: TPasswordManagerPayload[ActionTypes.UPDATE_INITIAL_AUTH],
) {
  const authFlow = {
    firstStep: new Set([state.dictIdName[payload.firstStep]]),
    secondSteps: new Set(payload.secondSteps.map((id) => state.dictIdName[id])),
  };

  const newData = {
    ...state.initial,
    authFlow,
  };

  return {
    ...state,
    data: cloneDeep(newData),
    initial: cloneDeep(newData),
  };
}
