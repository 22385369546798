import { useCallback, useState, ChangeEvent, useMemo } from 'react';
import { Input, Modal } from 'react-ui-kit-exante';

interface AddGroupModalProps {
  existGroupNames: Set<string>;
  onSave: (groupName: string) => void;
  onClose: () => void;
}

export const AddGroupModal = ({
  existGroupNames,
  onClose,
  onSave,
}: AddGroupModalProps) => {
  const [newGroupName, setNewGroupName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChangeGroupName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(value);
    setErrorMessage('');
  };

  const handleSubmit = useCallback(async () => {
    if (existGroupNames.has(newGroupName)) {
      setErrorMessage('Group name already exists');
    } else {
      await onSave(newGroupName);
    }
  }, [existGroupNames, newGroupName, onSave]);

  const confirmButton = useMemo(
    () => ({
      handleConfirm: handleSubmit,
    }),
    [handleSubmit],
  );

  return (
    <Modal
      confirmButton={confirmButton}
      isOpened
      keepMounted={false}
      onClose={onClose}
      title="Enter the name of a new group"
    >
      <Input
        error={!!errorMessage}
        fullWidth
        message={errorMessage}
        name="name"
        onChange={handleChangeGroupName}
        placeholder="Group name"
        value={newGroupName}
      />
    </Modal>
  );
};
