import { useContext } from 'react';

import { TSetRefreshFn } from '~/types/refetch';

import { TabManagement as TabManagementContainer } from '../../../containers/TabManagement';
import { clientPageContext } from '../context/ClientPageContext';

import { TABS } from './constants';

interface ITabManagementProps {
  clientId: string;
  clientName: string;
  setRefetch: TSetRefreshFn;
}

export const TabManagement = ({
  clientId,
  clientName,
  setRefetch,
}: ITabManagementProps) => {
  const { dirtyTabs } = useContext(clientPageContext);

  return (
    <TabManagementContainer
      componentProps={{ clientId, clientName, setRefetch }}
      dirtyTabs={dirtyTabs}
      tabs={TABS}
    />
  );
};
