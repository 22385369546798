import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useAddGroupLimitsMutation,
  useDeleteGroupLimitsMutation,
  useEditGroupLimitsMutation,
  useLazyGetLimitGroupsQuery,
} from '~/api';
import { transformLimitGroupsToOptions } from '~/api/limits/helpers';
import { selectGroup, setDefaultGroupId } from '~/store/treeStructure';
import { TabGroup } from '~/types/treeStructure';

export function useTabGroup(): TabGroup {
  const [addGroupMutation] = useAddGroupLimitsMutation();
  const [editGroupMutation] = useEditGroupLimitsMutation();
  const [deleteGroupMutation] = useDeleteGroupLimitsMutation();
  const [getLimitGroupsOptions] = useLazyGetLimitGroupsQuery();

  const groupId = useSelector(selectGroup);

  const dispatch = useDispatch();

  const addGroup = useCallback(
    async (newGroupName: string) => {
      const result = await addGroupMutation(newGroupName);

      if ('data' in result) {
        return result.data.id;
      }

      return null;
    },
    [addGroupMutation],
  );

  const editGroup = useCallback(
    async (name: string, id: number) => {
      const result = await editGroupMutation({ name, id });

      if ('data' in result) {
        return result.data.id;
      }

      return null;
    },
    [editGroupMutation],
  );

  const deleteGroup = useCallback(async () => {
    if (!groupId) {
      return 'Group id is not defined';
    }

    const result = await deleteGroupMutation(groupId);

    if ('error' in result) {
      return typeof result.error === 'string'
        ? result.error
        : String(result.error.message);
    }

    return '';
  }, [groupId, deleteGroupMutation]);

  const getOptions = useCallback(async () => {
    const result = await getLimitGroupsOptions();

    if ('data' in result) {
      const {
        data: [{ value: defaultGroupId }, ...optionsWithoutFirstElement],
      } = transformLimitGroupsToOptions({
        data: result.data,
      });

      dispatch(setDefaultGroupId(defaultGroupId));

      return optionsWithoutFirstElement;
    }

    return [];
  }, [dispatch, getLimitGroupsOptions]);

  return useMemo(
    () => ({
      addGroup,
      deleteGroup,
      editGroup,
      getOptions,
    }),
    [deleteGroup, addGroup, editGroup, getOptions],
  );
}
