export function flattenObject<T, K>(object: T): K {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      return { ...acc, ...value };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {} as K);
}
