import { Button } from 'react-ui-kit-exante';

export function getUploadActionsTrades(
  handleUploadTrades: () => void,
  hasErrorResponse: boolean,
  allRowsUploadSuccessfully: boolean,
  disabled: boolean,
  uploadText: string,
) {
  return allRowsUploadSuccessfully || hasErrorResponse
    ? undefined
    : [
        {
          key: 'upload',
          children: (
            <Button size="small" disabled={disabled}>
              {uploadText}
            </Button>
          ),
          onClick: handleUploadTrades,
        },
      ];
}
