import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { InputDatePicker } from 'react-ui-kit-exante';

import { toDate } from '~/shared/utils/dates';

interface IFormDatePickerContainerProps {
  disabled?: boolean;
  label?: string;
  name: string;
  minDate?: Date;
  maxDate?: Date;
}

export const FormDatePickerContainer = ({
  disabled = false,
  label = '',
  name,
  ...datePickerProps
}: IFormDatePickerContainerProps) => {
  const formContext = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error, isDirty },
  } = useController({
    control: formContext.control,
    name,
  });

  const controlProps = useMemo(() => {
    return {
      error: !!error,
      message: error?.message,
      warning: isDirty,
    };
  }, [error, isDirty]);

  return (
    <InputDatePicker
      disabled={disabled}
      inputProps={{
        size: 'medium',
        label,
      }}
      name={name}
      onChange={onChange}
      selected={toDate(value)}
      {...controlProps}
      {...datePickerProps}
    />
  );
};
