import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Input } from 'react-ui-kit-exante';
// TODO replace to good import
import { IUIInputProps } from 'react-ui-kit-exante/build/Components/Inputs/Input/types';

export type TInputMaskProps = NumberFormatProps<IUIInputProps>;

export const InputMask = ({ customInput, ...rest }: TInputMaskProps) => {
  return <NumberFormat customInput={Input} {...rest} />;
};
