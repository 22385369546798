import { IOption } from '~/types/form';
import { INonTradingCommissionGroupsResponse } from '~/types/nonTradingCommissions';

import { transformDataToSelectOptions } from '../form';

export const transformCommissionGroupsToOptions = (
  commissionGroups: INonTradingCommissionGroupsResponse[] | undefined,
): IOption[] => {
  return transformDataToSelectOptions(commissionGroups || [], {
    labelKey: 'name',
    valueKey: 'id',
    sort: true,
  })
    .filter((item) => item.label && item.value)
    .map((item) => ({
      label: item.label,
      value: String(item.value),
    }));
};
