import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function downloadedPathsAdd(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.DOWNLOADED_PATHS_ADD],
) {
  const clonedDownloadedPaths = new Set(state.downloadedPaths);
  clonedDownloadedPaths.add(payload);

  return {
    ...state,
    downloadedPaths: clonedDownloadedPaths,
  };
}
