import { Loader } from 'react-ui-kit-exante';

import { formatNumberByLocale } from '~/shared/utils';

import {
  StyledLoader,
  StyledContainer,
  StyledItem,
  StyledValue,
  StyledTitle,
} from './AccountsStatistic.styled';

interface AccountsStatisticProps {
  items: (string | string[])[][] | null;
  loading: boolean;
  error?: boolean;
}

export const AccountsStatistic = ({
  items,
  loading,
  error,
}: AccountsStatisticProps) => {
  if (loading) {
    return (
      <StyledLoader>
        <Loader size="l" />
      </StyledLoader>
    );
  }

  if (!items || error) {
    return null;
  }

  return (
    <StyledContainer>
      {items.map(([title, value]) => {
        return (
          <StyledItem key={String(title)}>
            <StyledValue>{formatNumberByLocale(Number(value))}</StyledValue>
            <StyledTitle>{title}</StyledTitle>
          </StyledItem>
        );
      })}
    </StyledContainer>
  );
};
