import { createEndDate } from './createEndDate';
import { createStartDate } from './createStartDate';
import { createStringDateWithoutTimezone } from './createStringDateWithoutTimezone';

export function setStartEndForDateRangeString(dates?: [string?, string?]) {
  if (!dates) {
    return [null, null];
  }

  const [minDate, maxDate] = dates;

  return [
    minDate ? createStringDateWithoutTimezone(createStartDate(minDate)) : null,
    maxDate ? createStringDateWithoutTimezone(createEndDate(maxDate)) : null,
  ];
}
