import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep } from 'lodash';
import { useCallback, useRef, useEffect, useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Notification, Panel, useModal } from 'react-ui-kit-exante';

import {
  useDeleteNonTradingCommissionGroupMutation,
  nonTradingCommissionsApi,
} from '~/api';
import { useBack } from '~/hooks';
import { NON_TRADING_COMMISSIONS } from '~/routes';
import { PanelHeaderControls } from '~/shared/components';

import { EMPTY_FORM_ITEM } from '../../constants';
import { useSave } from '../../hooks';
import { IFormNonTradingCommission } from '../../types';
import { formSchema } from '../../validationSchema';
import { FormItem } from '../FormItem';

import { StyledList } from './Form.styled';

interface IFormProps {
  formData: Array<IFormNonTradingCommission>;
  title: string;
  isCommissionsByGroupIdFetching: boolean;
  isCommissionsFetching: boolean;
}

export const Form = ({
  formData,
  title,
  isCommissionsByGroupIdFetching,
  isCommissionsFetching,
}: IFormProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('id');

  const [isInvalidating, setIsInvalidating] = useState(false);
  const confirmModal = useModal();

  const [commissionsForDelete, setCommissionsForDelete] = useState<Set<number>>(
    new Set(),
  );

  const [deleteNonTradingCommissionGroup] =
    useDeleteNonTradingCommissionGroupMutation();

  const useFormMethods = useForm({
    defaultValues: {
      commissions: formData,
    },
    resolver: yupResolver(formSchema),
  });

  const { fields, append } = useFieldArray({
    control: useFormMethods.control,
    name: 'commissions',
  });
  const initialFields = useRef(
    cloneDeep(fields).map(({ id, ...field }) => field),
  );

  const watched = useFormMethods.watch('commissions');

  const redirectToNonTradingCommissionsPage = useBack({
    defaultPath: NON_TRADING_COMMISSIONS,
  });

  const invalidateAndRefetch = useCallback(() => {
    setIsInvalidating(true);

    dispatch(
      nonTradingCommissionsApi.util.invalidateTags([
        'NonTradingCommissionByGroup',
        'NonTradingCommissionGroups',
        'NonTradingCommissions',
      ]),
    );
  }, [dispatch]);

  const saveCommissions = useSave(
    initialFields,
    commissionsForDelete,
    invalidateAndRefetch,
  );
  const onSubmitHandler = useFormMethods.handleSubmit(saveCommissions);

  const addFormItem = useCallback(() => {
    append(EMPTY_FORM_ITEM);
  }, [append]);

  const deleteGroup = useCallback(async () => {
    try {
      if (!groupId) {
        Notification.error({
          title: 'Group ID is required',
        });

        return;
      }

      const response = await deleteNonTradingCommissionGroup(groupId);

      if (!('error' in response)) {
        dispatch(
          nonTradingCommissionsApi.util.invalidateTags([
            'NonTradingCommissionByGroup',
            'NonTradingCommissionGroups',
            'NonTradingCommissions',
          ]),
        );

        Notification.success({
          title: 'Group has been deleted',
        });

        navigate(NON_TRADING_COMMISSIONS);
      } else {
        Notification.error({
          title: 'Error deleting group',
        });
      }
    } catch (error) {
      Notification.error({
        title: 'Error deleting group',
      });
    }
  }, [dispatch, navigate, deleteNonTradingCommissionGroup, groupId]);

  const deleteCommission = useCallback((index: number) => {
    setCommissionsForDelete((prev) => new Set(prev.add(index)));
  }, []);

  const restoreCommission = useCallback((index: number) => {
    setCommissionsForDelete((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });
  }, []);

  useEffect(() => {
    if (
      isInvalidating &&
      !isCommissionsByGroupIdFetching &&
      !isCommissionsFetching
    ) {
      setIsInvalidating(false);

      setCommissionsForDelete(new Set());

      useFormMethods.reset({
        commissions: formData,
      });

      initialFields.current = cloneDeep(fields).map(
        ({ id, ...field }) => field,
      );
    }
  }, [
    fields,
    formData,
    useFormMethods,
    isInvalidating,
    isCommissionsByGroupIdFetching,
    isCommissionsFetching,
  ]);

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={onSubmitHandler}>
        <Panel
          action={
            <PanelHeaderControls
              closeIconColor="secondary"
              deleteIconColor="secondary"
              disableSaveButton={
                !useFormMethods.formState.isDirty && !commissionsForDelete.size
              }
              labelAdd="Add commission"
              onAdd={addFormItem}
              onClose={redirectToNonTradingCommissionsPage}
              onDelete={confirmModal.onOpen}
            />
          }
          disableBodyPaddings
          title={title}
        >
          <StyledList>
            {fields.map((field, index) => (
              <FormItem
                commissionsCount={initialFields.current.length}
                commissionsForDelete={commissionsForDelete}
                deleteCommission={deleteCommission}
                field={field}
                index={index}
                key={field.id}
                restoreCommission={restoreCommission}
                setValue={useFormMethods.setValue}
                watched={watched}
              />
            ))}
          </StyledList>
        </Panel>
      </form>

      <Modal
        onClose={confirmModal.onClose}
        isOpened={confirmModal.isOpened}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: deleteGroup,
        }}
        keepMounted={false}
      >
        Do you want to delete this group?
      </Modal>
    </FormProvider>
  );
};
