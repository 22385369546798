import { entries } from 'lodash';

import { getValueFromOption } from './getValueFromOption';

export function flattenOptionValues<T>(formData: T): {
  [K in keyof T]: T[K] extends string | number | boolean
    ? T[K]
    : ReturnType<typeof getValueFromOption>;
} {
  return entries(formData || {}).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: getValueFromOption(value),
    };
  }, <T>{});
}
