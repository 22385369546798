export enum SpecialNodes {
  CURRENCY = 'CURRENCY',
  OPTION = 'OPTION',
}

export const EMPTY_INSTRUMENTS_DATA = {
  data: { instruments: [], isFinish: true },
};

export const LIMITS_MESSAGES = {
  NON_OVERRIDE_LIMIT: 'You can not change non-override limit',
};
