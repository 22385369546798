import { Panel } from 'react-ui-kit-exante';

import { FormMultiSelectWithAllOptions } from '~/containers/form';
import { TTypes } from '~/types/types';

import CommonPermissionsStyles from './CommonPermissions.module.css';

interface ICommonPermissionsProps {
  accountTypesOptions: string[];
  legalEntities: TTypes;
  brandingList: string[];
}

export const CommonPermissions = ({
  accountTypesOptions,
  legalEntities,
  brandingList,
}: ICommonPermissionsProps) => {
  return (
    <Panel title="General permissions">
      <div className={CommonPermissionsStyles.ControlsWrapper}>
        <FormMultiSelectWithAllOptions
          label="Legal entity"
          name="generalPermissions.legalEntities"
          options={legalEntities as string[]}
          disableClearable
        />

        <FormMultiSelectWithAllOptions
          label="Account Types"
          name="generalPermissions.accountTypes"
          options={accountTypesOptions}
        />
        <FormMultiSelectWithAllOptions
          label="Branding Permission"
          name="generalPermissions.brandingPermission"
          options={brandingList}
        />
      </div>
    </Panel>
  );
};
