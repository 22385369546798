export function createStartDate(date: string | Date, utc = false) {
  const start = new Date(date);

  if (utc) {
    start.setUTCHours(0, 0, 0, 0);
  } else {
    start.setHours(0, 0, 0, 0);
  }

  return start;
}
