import { IRegularPaymentsSettings } from '~/types/accounts';
import { RowType } from '~/types/common';

type TValue = string | number | null;

export interface IOvernight {
  benchmarkNegative: TValue;
  benchmarkPositive: TValue;
  currency: null | string;
  markupNegative: TValue;
  markupPositive: TValue;
  override?: boolean;
}

export interface IOvernightResponseTree {
  displayName: string;
  icon?: string;
  id: string;
  path: string;
}

export interface IOvernightInstrumentResponse extends IOvernightResponseTree {
  overnight: IOvernight;
}

export interface IOvernightTree
  extends Record<string, unknown>,
    IOvernightResponseTree,
    IOvernight {
  rowType: RowType;
  subRows?: IOvernightTree[];
  nodeIsCalculated?: boolean;
}

export enum FilterLayers {
  Default = 'Default',
  Groups = 'Groups',
  Accounts = 'Accounts',
}

export type TOvernightsQueryParams =
  | {
      layer: FilterLayers;
      entity?: number | string;
    }
  | false;

export interface IChangedOvernights {
  nodes: Record<string, IOvernightTree>;
  instruments: Record<string, IOvernightTree>;
}

export interface IStateOvernights {
  changedOvernights: IChangedOvernights;
  dict: {
    regularPaymentSettings: IRegularPaymentsSettings;
  };
  filters: {
    select: {
      layer: FilterLayers;
      account: string | null;
      group: number | undefined;
    };
    queryParams: TOvernightsQueryParams;
    relatedGroup: number | null;
  };
  table: {
    defaultTree: IOvernightTree[];
    defaultPositionByIdInTree: Record<string, string>;
    downloadedPaths: string[];
    expandedRows: Record<string, boolean> | boolean;
    positionByIdInTree: Record<string, string>;
    tree: IOvernightTree[];
  };
  search: {
    isActive: boolean;
    tree: IOvernightTree[];
  };
}

export interface IGetResolveOvernightsTreeParams {
  layer: FilterLayers;
  entity?: number | string;
}
