/**
 * Replaces variable values in a route with a specified constant value.
 *
 * @param {string} route - The original route containing variable values.
 * @param {string} replacement - The constant value to replace the variables with.
 * @returns {string} - The new route with replaced variable values.
 *
 * @example
 * const originalRoute = "/bo/accounts/:accountId/test";
 * const constantValue = "123";
 * const updatedRoute = replaceRouteVariable(originalRoute, constantValue);
 * console.log(updatedRoute); // Outputs: "/bo/accounts/123/test"
 */
export function replaceRouteVariable(
  route: string,
  replacement: string | number,
): string {
  // Define the regular expression to find the segments with variables.
  // The regex consists of:
  // 1. A slash (/) indicating the beginning of a segment.
  // 2. A capturing group ([^/:]+\/?) that matches one or more characters not including a slash (/) or colon (:),
  //    optionally followed by a slash, representing the segment prefix before the variable.
  // 3. A colon (:) indicating the start of the variable name.
  // 4. Another capturing group ([^/]+|$) that matches the variable name itself, which is any character until the next slash or the end of the string.
  const regex = /\/([^/:]+\/?):([^/]+|$)/g;

  // Replace each found variable segment with the segment prefix and the replacement value.
  // The replacement string `/$1${replacement}` constructs the new segment by using:
  // - The matched segment prefix from the first capturing group ($1),
  // - The specified replacement value for the variable.
  return route.replace(regex, `/$1${replacement}`);
}
