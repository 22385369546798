import { useCallback } from 'react';
import { useFormContext, useController } from 'react-hook-form';
// TODO replace to FormInputMaskContainer or InputMask
import InputMask, { IBeforeMaskedValueChange } from 'react-input-mask';
import { Input } from 'react-ui-kit-exante';

import { EMPTY_STRING } from '../constants';
import { generateIdPostfix } from '../helpers';

const generatedId = generateIdPostfix();
const mask = `*** ${generatedId.replaceAll('9', '\\9')}`;

export const ClientIdInputContainer = () => {
  const formContext = useFormContext();

  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name: 'id',
  });

  formContext.register('id');

  const beforeMaskedValueChange: IBeforeMaskedValueChange = useCallback(
    (newState: { value: string; selection: unknown }) => ({
      value: newState.value.toUpperCase(),
      selection: newState.selection,
    }),
    [],
  );

  return (
    // Must be fixed in react-ui-kit
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      maskChar={EMPTY_STRING}
      beforeMaskedValueChange={beforeMaskedValueChange}
      onBlur={onBlur}
    >
      {() => (
        <Input
          label="Client Id"
          error={Boolean(error)}
          message={error?.message}
          sx={{
            width: '100%',
          }}
        />
      )}
    </InputMask>
  );
};
