import { TreeStructure, FilterLayers } from '~/types/treeStructure';

export const INITIAL_STATE: TreeStructure = {
  layer: FilterLayers.Default,
  group: {
    groupId: null,
    defaultGroupId: null,
  },
  account: {
    accountId: null,
    groupId: {
      initial: null,
      userSelected: null,
    },
  },
  isLoading: {
    instruments: false,
  },
};
