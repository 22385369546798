import type { ActionTypes } from '../constants';
import { isEqualStates } from '../helpers';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

export function addToken(
  state: IStatePasswordManager,
  payload: TPasswordManagerPayload[ActionTypes.ADD_TOKEN],
) {
  if (!payload.type) {
    return state;
  }

  const newState = {
    ...state,
    data: {
      ...state.data,
      [payload.type]: [...state.data[payload.type], payload],
    },
  };

  return {
    ...newState,
    disableSaveButton: isEqualStates(newState),
  };
}
