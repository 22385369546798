import { get } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useLazyGetGroupSettingsQuery } from '~/api';

import { ActionTypes, SymbolPermissionsContext } from '../constants';
import { TFilterChangeHandle } from '../types';

export const useAccountGroup = (filterChangeHandle: TFilterChangeHandle) => {
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useContext(SymbolPermissionsContext);
  const [fetchGroupSettings] = useLazyGetGroupSettingsQuery();

  const onAccountSelect = useCallback(
    async (selectedAccountId: string) => {
      try {
        setLoading(true);
        if (selectedAccountId) {
          const response = await fetchGroupSettings({
            accountId: selectedAccountId,
          });
          if ('error' in response) {
            Notification.error({
              title: String(get(response.error, 'message')),
            });
          } else {
            dispatch({
              type: ActionTypes.FILTERS_RELATED_GROUP_SET,
              payload: Number(response.data?.permissionsSetId),
            });

            filterChangeHandle();
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, fetchGroupSettings, filterChangeHandle],
  );

  return {
    loadingAccountSet: loading,
    onAccountSelect,
  };
};
