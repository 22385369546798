import { MutableRefObject } from 'react';

import {
  createServiceResponse,
  displayErrorNotification,
  getArrayDifference,
  is403GetError,
} from '~/shared/utils';
import { IServiceResponse } from '~/types/api';

import { commissionsAccountMapper } from './regularCommissions.mappers';
import { RegularCommissionsRepository } from './regularCommissions.repository';
import {
  TAccountRegularCommissions,
  TAccountRegularCommissionsTableRow,
  TRegularCommissions,
} from './types';

export class RegularCommissionsService {
  public async resolveRegularCommissions(): Promise<
    TRegularCommissions[] | []
  > {
    try {
      const { data } =
        await RegularCommissionsRepository.fetchRegularCommissions();

      return data || [];
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return [];
    }
  }

  public async resolveRegularCommissionsByAccountId(
    id: string,
  ): Promise<TAccountRegularCommissions> {
    try {
      const { data } =
        await RegularCommissionsRepository.fetchAccountRegularCommissions(id);

      return data;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return [];
    }
  }

  public async resolveAccountRegularCommissions(
    accountId: string,
  ): Promise<TAccountRegularCommissionsTableRow[]> {
    try {
      // todo move to regularCommissionsService
      const accountRegularCommissionsData =
        await this.resolveRegularCommissionsByAccountId(accountId);
      const regularCommissionsData = await this.resolveRegularCommissions();

      return commissionsAccountMapper(
        accountRegularCommissionsData,
        regularCommissionsData,
      );
    } catch (error) {
      return [];
    }
  }

  public async updateRegularCommissions(
    accountId: string,
    previousRegularCommissions: MutableRefObject<
      TAccountRegularCommissionsTableRow[]
    >,
    regularCommissions: TAccountRegularCommissionsTableRow[],
  ): Promise<IServiceResponse<TAccountRegularCommissionsTableRow[]>> {
    try {
      const messages: { success: string[]; error: string[] } = {
        success: [],
        error: [],
      };

      const { deletedElements, editedElements, createdElements } =
        getArrayDifference(
          previousRegularCommissions.current,
          regularCommissions,
          'commissionId',
        );

      const deletePromises = Promise.allSettled(
        deletedElements.map((commission) =>
          RegularCommissionsRepository.deleteAccountRegularCommissions(
            commission,
          ),
        ),
      ).then((results) =>
        results.forEach((result, index) => {
          const currentCommissionId = deletedElements[index].commissionId;

          if (result.status === 'fulfilled') {
            messages.success.push(
              `Commission with id ${currentCommissionId} successfully deleted.`,
            );
          }

          if (result.status === 'rejected') {
            messages.error.push(
              `Commission with id ${currentCommissionId} deleting failed ${result.reason.messages}`,
            );
          }
        }),
      );

      const createdAndEditedElements = editedElements.concat(createdElements);
      const editCreatePromises =
        RegularCommissionsRepository.updateAccountRegularCommissions(
          createdAndEditedElements,
        ).then(() => {
          createdAndEditedElements.forEach((item) => {
            const currentCommissionId = item.commissionId;

            messages.success.push(
              `Commission with id ${currentCommissionId} successfully updated.`,
            );
          });
        });

      await Promise.all([deletePromises, editCreatePromises]);

      // it is ok because it is react ref
      // eslint-disable-next-line no-param-reassign
      previousRegularCommissions.current = regularCommissions;

      return createServiceResponse({ data: regularCommissions, messages });
    } catch (error) {
      return createServiceResponse({
        messages: { error: 'Regular commission updating failed.' },
      });
    }
  }
}
