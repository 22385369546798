import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { IOption } from '~/types/form';
import { IMirroringRule } from '~/types/mirroringRules';

export const DISPLAYED_COLUMN_KEYS = [
  'name',
  'sourceAccounts',
  'operationTypes',
  'categories',
  'targetAccount',
  'transferTag',
  'startDateTime',
  'endDateTime',
  'status',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  accountTypesOptions: IOption[];
  accountPurposesOptions: IOption[];
}

export function getColumns({
  onFilter,
  onRemove,
  accountTypesOptions,
  accountPurposesOptions,
}: IGetColumns): IColumn<IMirroringRule>[] {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      disableFilters: true,
      onRemove,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableFilters: true,
      onRemove,
    },
    {
      Header: 'Source Accounts',
      accessor: 'sourceAccounts',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Source Legal Entities',
      accessor: 'sourceLegalEntities',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Operation Types',
      accessor: 'operationTypes',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Categories',
      accessor: 'categories',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Transfer Multiplier',
      accessor: 'transferMultiplier',
      disableFilters: true,
      onRemove,
    },
    {
      Header: 'Target Account',
      accessor: 'targetAccount',
      onFilter,
      onRemove,
    },
    {
      Header: 'Transfer Tag',
      accessor: 'transferTag',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Start Date',
      accessor: 'startDateTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
      onRemove,
    },
    {
      Header: 'End Date',
      accessor: 'endDateTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      onRemove,
    },
    {
      Header: 'Custodians',
      accessor: 'custodians',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
    {
      Header: 'Account types',
      accessor: 'accountTypes',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: accountTypesOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Account purposes',
      accessor: 'accountPurposes',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: accountPurposesOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Black list accounts',
      accessor: 'blackListAccounts',
      disableFilters: true,
      disableSortBy: true,
      onRemove,
    },
  ];
}
