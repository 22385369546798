import { memo } from 'react';

import { typeIconNames } from '~/constants';

import { InstrumentIcon } from '../../../../../shared/components/InstrumentIcon';

import AssetIconStyles from './AssetIcon.module.css';
import { FullIcon } from './FullIcon/FullIcon';
import { IAssetIconProps } from './types';

const AssetIconComponent = (props: IAssetIconProps): JSX.Element => {
  const {
    asset,
    iconName = typeIconNames.OTHER,
    isFullIcon = true,
    isExpired = false,
  } = props;
  const preparedIconName = isExpired ? typeIconNames.OTHER : iconName;

  return isFullIcon ? (
    <div className={AssetIconStyles.AssetIconWrapper}>
      <FullIcon asset={asset} iconName={preparedIconName} />
    </div>
  ) : (
    <InstrumentIcon iconName={preparedIconName} />
  );
};

export const AssetIcon = memo(AssetIconComponent);
