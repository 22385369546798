export const rowTypes = {
  node: 'node',
  leaf: 'leaf',
  root: 'root',
} as const;

export const SHOW_WARNING_TIME = 2500;

export const NOT_ALLOWED_TO_CHANGE_MSG =
  "isAutoCalculated can't be true when symbol of type OPTION";

export const tooltipStyles = {
  backgroundColor: '#EC9F0B',
  padding: '8px 16px',
  color: '#fff',
};

export const DEFAULT_LIMIT = 20;

export const ROW_HEIGHT = 32;

export const paginationOptions = [
  { label: String(DEFAULT_LIMIT), value: DEFAULT_LIMIT },
  { label: '50', value: 50 },
];

export const DOWNLOAD_RISK_ARRAYS_ENDPOINT = `/api/symbols/risk-array/csv`;
