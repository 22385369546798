import { TInterestRatesMap } from '~/api/interestRates/types';

export enum ELayer {
  Default = 'default',
  Groups = 'groups',
  Accounts = 'accounts',
}

export type TInterestRateWithOverriddenMap = {
  [Key in keyof TInterestRatesMap]: TInterestRatesMap[Key] & {
    overridden?: boolean;
  };
};

export type TInterestRateWithOverridden =
  TInterestRateWithOverriddenMap[keyof TInterestRateWithOverriddenMap];

export type TBrandedAccountId<AccountId = string> = AccountId;

export interface ITab<Key extends string> {
  id: Key;
  label: Capitalize<Key>;
  index: number;
}

export interface IQueryState {
  refetch: () => Promise<unknown>;
  isLoading: boolean;
}

export type TSetRefetch = (query: IQueryState) => void;
