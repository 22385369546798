import { transformVariantsToSelectOptions } from '~/shared/utils';

export const types = {
  Liabilities: 'Liabilities',
  Equity: 'Equity',
  Assets: 'Assets',
};

export const accountTypes = {
  Liabilities: 'Liabilities',
  Partner: 'Partner',
  Payment: 'Payment',
  Equity: 'Equity',
  Assets: 'Assets',
};

// right now bo support only Liabilities, Assets, Equity options
export const BO_SUPPORTED_ACCOUNT_TYPES = Object.keys(types);

export const BO_SUPPORTED_ACCOUNT_TYPES_OPTIONS =
  transformVariantsToSelectOptions(BO_SUPPORTED_ACCOUNT_TYPES);
