import { useState, useLayoutEffect } from 'react';

import { applicationService } from '~/resources';

import styles from './VersionContainer.module.css';

export const VersionContainer = () => {
  const [boVersion, setBOVersion] = useState('');
  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      applicationService.getBOVersion().then(setBOVersion);
    }
  }, []);

  return boVersion ? <div className={styles.version}>{boVersion}</div> : null;
};
