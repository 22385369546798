import { FilterLayers, IStateCommissions } from '~/types/commissions';

export const DEFAULT_CHANGED_COMMISSIONS = {
  nodes: {},
  instruments: {},
};

export const INITIAL_STATE: IStateCommissions = {
  changedCommission: DEFAULT_CHANGED_COMMISSIONS,
  filters: {
    select: {
      layer: FilterLayers.Default,
      account: null,
      group: 'default',
    },
    queryParams: {
      layer: FilterLayers.Default,
    },
    relatedGroup: null,
  },
  initialCommission: {},
  search: {
    isActive: false,
    tree: [],
  },
  table: {
    defaultPositionByIdInTree: {},
    defaultTree: [],
    downloadedPaths: [],
    expandedRows: {},
    positionByIdInTree: {},
    tree: [],
  },
};
