/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useLazyGetOvernightsInstrumentsQuery } from '~/api';
import { resetTable } from '~/store/overnights';
import { TOvernightsQueryParams } from '~/types/overnights';

import { getAndInsertInstrumentsToNode } from '../helpers';

export const useReloadInstruments = () => {
  const dispatch = useDispatch();
  const [getInstruments] = useLazyGetOvernightsInstrumentsQuery();

  return useCallback(
    async (pathsForReload: string[], queryParams: TOvernightsQueryParams) => {
      dispatch(
        resetTable({
          shouldResetExpandedRows: false,
          shouldResetDownloadedPaths: false,
        }),
      );

      for (const path of pathsForReload) {
        await getAndInsertInstrumentsToNode({
          dispatch,
          getInstruments,
          path,
          queryParams,
          skip: 0,
        });
      }
    },
    [dispatch, getInstruments],
  );
};
