import { ReactNode } from 'react';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import { IOption } from '~/types/form';

import { AtpPermissions } from './AtpPermissions';
import { Permissions } from './Permissions';

interface IAccountPermissionsProps {
  header: ReactNode;
  accountStatusesOptions: IOption[];
  initialPermissions: string[];
}

export const AccountPermissions = ({
  header,
  accountStatusesOptions,
  initialPermissions,
}: IAccountPermissionsProps) => {
  return (
    <>
      {header}

      <PanelGroup panelsPerLine={1}>
        <AtpPermissions />

        <Panel title="Permissions">
          <Permissions
            accountStatusesOptions={accountStatusesOptions}
            initialPermissions={initialPermissions}
          />
        </Panel>
      </PanelGroup>
    </>
  );
};
