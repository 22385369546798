import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

export const legalEntitiesApi = createApi({
  reducerPath: 'legalEntitiesApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['LegalEntities'],
  endpoints: () => ({}),
});
