import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import type { TApplicationState } from '~/types/store';
import {
  FilterLayers,
  TreeStructureIsLoadingKeys,
} from '~/types/treeStructure';

import { INITIAL_STATE } from './constants';

interface SetLoadingPayload {
  key: TreeStructureIsLoadingKeys;
  value: boolean;
}

export const treeStructureSlice = createSlice({
  name: 'treeStructure',
  initialState: INITIAL_STATE,
  reducers: {
    setNewLayer: (state, { payload }) => {
      const { layer } = payload;

      state.layer = layer;
    },
    setGroupId: (state, { payload }) => {
      state.group.groupId = payload;
    },
    setDefaultGroupId: (state, { payload }) => {
      state.group.defaultGroupId = payload;
    },
    setAccount: (state, { payload }) => {
      const { accountId, groupId } = payload;

      state.account = cloneDeep(INITIAL_STATE.account);
      state.account.accountId = accountId;
      state.account.groupId.initial = groupId;
    },
    setUserSelectedGroupIdForAccount: (state, { payload }) => {
      state.account.groupId.userSelected =
        state.account.groupId.initial === payload ? null : payload;
    },
    resetGroupIds: (state, { payload }) => {
      state.account.groupId.initial = payload;
      state.account.groupId.userSelected = null;
    },
    setLoading: (state, { payload }: { payload: SetLoadingPayload }) => {
      const { key, value } = payload;

      state.isLoading[key] = value;
    },
  },
});

export const selectLayer = (state: TApplicationState) => {
  return state.treeStructure.layer;
};

export const selectGroup = (state: TApplicationState) => {
  return state.treeStructure.group.groupId;
};

export const selectDefaultGroupId = (state: TApplicationState) => {
  return state.treeStructure.group.defaultGroupId;
};

export const selectAccount = (state: TApplicationState) => {
  return state.treeStructure.account.accountId;
};

export const selectEntity = (state: TApplicationState) => {
  if (state.treeStructure.layer === FilterLayers.Groups) {
    return (
      state.treeStructure.group.groupId ||
      state.treeStructure.group.defaultGroupId
    );
  }

  if (state.treeStructure.layer === FilterLayers.Accounts) {
    return state.treeStructure.account.accountId;
  }

  return undefined;
};

export const selectGroupIdConnectedWithAccount = (state: TApplicationState) => {
  return (
    state.treeStructure.account.groupId.userSelected ||
    state.treeStructure.account.groupId.initial
  );
};

export const selectUserSelectedGroup = (state: TApplicationState) =>
  state.treeStructure.account.groupId.userSelected;

export const selectIsLoading = (state: TApplicationState) =>
  !!Object.values(state.treeStructure.isLoading).filter(Boolean).length;

export const {
  resetGroupIds,
  setAccount,
  setDefaultGroupId,
  setGroupId,
  setNewLayer,
  setUserSelectedGroupIdForAccount,
  setLoading,
} = treeStructureSlice.actions;
