import { EN_DASH } from '~/constants';

function getNumberFromVariable(unknown: unknown, way: 'min' | 'max') {
  if (typeof unknown === 'number') {
    return unknown;
  }

  if (!Number.isNaN(Number(unknown))) {
    return Number(unknown);
  }

  if (typeof unknown === 'string' && way === 'min') {
    return Number(unknown.split(EN_DASH)[0]);
  }

  if (typeof unknown === 'string' && way === 'max') {
    return Number(unknown.split(EN_DASH)[1]);
  }

  throw new Error('Unknown type of variable');
}

export function findMinMaxInArrayOfObjects(
  arr: Array<Record<string, unknown>>,
  keys: string[],
) {
  const min: Record<string, number> = {};
  const max: Record<string, number> = {};

  keys.forEach((key) => {
    min[key] = getNumberFromVariable(arr[0][key], 'min');
    max[key] = getNumberFromVariable(arr[0][key], 'max');
  });

  for (let i = 1; i < arr.length; i += 1) {
    keys.forEach((key) => {
      const minValue = getNumberFromVariable(arr[i][key], 'min');
      const maxValue = getNumberFromVariable(arr[i][key], 'max');
      min[key] = minValue < min[key] ? minValue : min[key];
      max[key] = maxValue > max[key] ? maxValue : max[key];
    });
  }

  return [min, max];
}
