import { map } from 'lodash';

import { getTableId } from '~/shared/utils';
import { TTradeColumns } from '~/types/trades';

export const TABLE_ID = getTableId('Trades');

export const DEFAULT_TABLE_ENTITY = 'trades-list';

export const DISPLAYED_COLUMNS = [
  'userId',
  'tradeTime',
  'symbolId',
  'symbolType',
  'accountId',
  'side',
  'price',
  'quantity',
  'commission',
  'currency',
  'executionCounterparty',
  'valueDate',
  'clientComment',
  'clientType',
  'tradeType',
  'manual',
];

export const TRADE_COLUMNS_ALL: TTradeColumns[] = map(
  [
    'orderId',
    'orderPos',
    'accountId',
    'symbolId',
    'symbolType',
    'isin',
    'quantity',
    'price',
    'currency',
    'gwTime',
    'counterpartyAccountId',
    'exchangeOrderId',
    'side',
    'commission',
    'commissionCurrency',
    'isForcedCloseOut',
    'userId',
    'counterpartyClientId',
    'executionCounterparty',
    'valueDate',
    'clientComment',
    'internalComment',
    'contractMultiplier',
    'settlementCounterparty',
    'clientType',
    'isManual',
    'settlementBrokerAccountId',
    'settlementBrokerClientId',
    'who',
    'injectedBy',
    'tradeType',
    'pnl',
    'convertedPnl',
    'closedQuantity',
    'tradedVolume',
    'counterpartyTime',
    'tradeTime',
    'tradeDate',
    'feePolicy',
    'legalEntity',
    'symbolTypeOverride',
    'source',
    'legs',
    'convertedSum',
    'providerName',
    'gatewayName',
    'accountName',
    'executionVenue',
    'execBroker',
    'lastMarket',
    'remainedQuantity',
    'remainedQuantityTotal',
    'filledQuantity',
    'filledQuantityTotal',
    'chainId',
    'executionSchemeId',
    'logicType',
    'markup',
    'uti',
  ],
  (id) => ({ id }),
);

export const TRADE_COLUMNS_PROPS: Record<string, Record<string, unknown>> = {
  orderId: {
    Header: 'Order id',
    accessor: 'orderId',
    onFilter: true,
    onRemove: true,
  },
  orderPos: {
    Header: 'Order position',
    accessor: 'orderPos',
    disableFilters: true,
  },
  accountId: {
    Header: 'Account',
    accessor: 'accountId',
    onFilter: true,
    onRemove: true,
  },
  symbolId: {
    Header: 'Symbol',
    accessor: 'symbolId',
    onFilter: true,
    onRemove: true,
  },
  symbolType: {
    Header: 'Symbol type',
    accessor: 'symbolType',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  quantity: {
    Header: 'Quantity',
    accessor: 'quantity',
    disableFilters: true,
    formatting: 'number',
  },
  price: {
    Header: 'Price',
    accessor: 'price',
    disableFilters: true,
    formatting: 'number',
  },
  currency: {
    Header: 'Currency',
    accessor: 'currency',
    disableFilters: true,
    disableSortBy: true,
  },
  gwTime: {
    Header: 'Gw time',
    accessor: 'gwTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  counterpartyAccountId: {
    Header: 'Counterparty account',
    accessor: 'counterpartyAccountId',
    disableFilters: true,
  },
  exchangeOrderId: {
    Header: 'Exchange order id',
    accessor: 'exchangeOrderId',
    onFilter: true,
    onRemove: true,
  },
  side: {
    Header: 'Side',
    accessor: 'side',
    disableFilters: true,
  },
  commission: {
    Header: 'Commission',
    accessor: 'commission',
    disableFilters: true,
    formatting: 'number',
  },
  commissionCurrency: {
    Header: 'Commission currency',
    accessor: 'commissionCurrency',
    disableFilters: true,
  },
  isForcedCloseOut: {
    Header: 'Forced close out',
    accessor: 'isForcedCloseOut',
    disableFilters: true,
    disableSortBy: true,
  },
  userId: {
    Header: 'User',
    accessor: 'userId',
    onFilter: true,
    onRemove: true,
  },
  counterpartyClientId: {
    Header: 'Counterparty client',
    accessor: 'counterpartyClientId',
    disableFilters: true,
  },
  executionCounterparty: {
    Header: 'Execution counterparty',
    accessor: 'executionCounterparty',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  valueDate: {
    Header: 'Value date',
    accessor: 'valueDate',
    disableFilters: true,
    formatting: 'dateUTC',
  },
  clientComment: {
    Header: 'Client comment',
    accessor: 'clientComment',
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  internalComment: {
    Header: 'Internal comment',
    accessor: 'internalComment',
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  contractMultiplier: {
    Header: 'Contract multiplier',
    accessor: 'contractMultiplier',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  settlementCounterparty: {
    Header: 'Settlement counterparty',
    accessor: 'settlementCounterparty',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  clientType: {
    Header: 'Client type',
    accessor: 'clientType',
    disableFilters: true,
  },
  isManual: {
    Header: 'Manual',
    accessor: 'isManual',
    disableFilters: true,
  },
  who: {
    Header: 'Who',
    accessor: 'who',
    disableFilters: true,
  },
  tradeType: {
    Header: 'Trade type',
    accessor: 'tradeType',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  pnl: {
    Header: 'PNL',
    accessor: 'pnl',
    disableFilters: true,
    formatting: 'number',
  },
  closedQuantity: {
    Header: 'Closed quantity',
    accessor: 'closedQuantity',
    disableFilters: true,
    formatting: 'number',
  },
  tradeVolume: {
    Header: 'Traded volume',
    accessor: 'tradedVolume',
    disableFilters: true,
    formatting: 'number',
  },
  counterpartyTime: {
    Header: 'Counterparty time',
    accessor: 'counterpartyTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  tradeTime: {
    Header: 'Trade time',
    accessor: 'tradeTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  legalEntity: {
    Header: 'Legal entity',
    accessor: 'legalEntity',
    disableSortBy: true,
    type: 'multiSelect',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
  },
  source: {
    Header: 'Source',
    accessor: 'source',
    onFilter: true,
    onRemove: true,
  },
  execBroker: {
    Header: 'Exec broker',
    accessor: 'execBroker',
    disableFilters: true,
    disableSortBy: true,
  },
};
