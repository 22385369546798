const BASE_PROXY_API = '/api/proxy/';

export const PROXY_CORE_PATH = `${BASE_PROXY_API}core/`;
export const FETCH_PROXY_AUTH_DB = `${BASE_PROXY_API}auth-db/`;
export const FETCH_PROXY_BRANDING = `${BASE_PROXY_API}branding-service/`;

export const knownStatuses = [401, 403, 500];
export const errorMessages = {
  401: `No authorization found. Please re-enter your username and password or clear your browser cache and try again.`,
  403: `You don’t have permission to view/edit this data. Please contact our support team.`,
  500: `Something went wrong. Please try again later.`,
};
