import { createSlice } from '@reduxjs/toolkit';

import { getSymbolIdInfoMatchFulfilled } from '~/api';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import type { TApplicationState } from '~/types/store';
import type { IBrokerClientAccount } from '~/types/symbols';

import { INITIAL_STATE } from './constants';

export const symbolsSlice = createSlice({
  name: 'symbols',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      getSymbolIdInfoMatchFulfilled,
      (state, { payload: { brokerClientAccounts } }) => {
        state.symbolIdInfo = brokerClientAccounts;

        state.counterparties = brokerClientAccounts.reduce<
          Record<string, IBrokerClientAccount>
        >((acc, item) => {
          const { account, counterparty } = item;

          acc[`${counterparty}:${account}`] = item;

          return acc;
        }, {});

        state.settlementCounterparties = brokerClientAccounts.reduce<
          Record<string, IBrokerClientAccount[]>
        >((acc, item) => {
          if (acc[item.settlementCounterparty]) {
            acc[item.settlementCounterparty].push(item);
          } else {
            acc[item.settlementCounterparty] = [item];
          }

          return acc;
        }, {});
      },
    );
  },
});

export const selectSymbolIdSettlementCounterparties = (
  state: TApplicationState,
) => state.symbols.settlementCounterparties;

export const selectSymbolIdCounterparties = (state: TApplicationState) =>
  state.symbols.counterparties;

export const selectSymbolIdSettlementCounterpartyOptions = (
  state: TApplicationState,
) => {
  const uniqSettlementCounterparties = Object.keys(
    state.symbols.settlementCounterparties,
  ).sort();

  uniqSettlementCounterparties.unshift('EXANTE');

  return transformVariantsToSelectOptions(uniqSettlementCounterparties);
};

export const selectSymbolIdCounterpartyOptions = (state: TApplicationState) => {
  const counterparties = Object.keys(state.symbols.counterparties).sort();

  counterparties.unshift('EXANTE');

  return transformVariantsToSelectOptions(counterparties);
};
