/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { useCallback, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IColumn, IRowExpand, Notification } from 'react-ui-kit-exante';

import { useLazyGetOvernightsInstrumentsQuery } from '~/api';
import { usePrevious } from '~/hooks';
import {
  selectFiltersQueryParams,
  updateInstrumentValue,
  updateNodeValue,
} from '~/store/overnights';
import { FilterLayers, IOvernightTree } from '~/types/overnights';

import { getAndInsertInstrumentsToNode } from '../../../../helpers';

import { StyledInput, StyledButton } from './ValueCellStyled';

interface IValueCell {
  column: IColumn<IOvernightTree>;
  row: IRowExpand<IOvernightTree>;
  value: null | string | number;
}

export const ValueCell = ({ column, value, row }: IValueCell) => {
  const isNodeCell = row.original.rowType === 'node';
  const isCanBeEdit = row.depth !== 0;

  const dispatch = useDispatch();
  const queryParams = useSelector(selectFiltersQueryParams);
  const [getInstruments] = useLazyGetOvernightsInstrumentsQuery();

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>(() => {
    if (value === null) {
      return isNodeCell ? '-' : 0;
    }

    return value;
  });
  const prevInputValue = usePrevious(inputValue);

  const layer = queryParams ? queryParams.layer : false;

  const onClickHandler = useCallback(() => {
    if (!isCanBeEdit) {
      Notification.warning({
        title: 'You can not change root node',
      });

      return;
    }

    if (layer !== FilterLayers.Default && !row.original.override) {
      Notification.warning({
        title: 'You can not change non-override overnight',
      });

      return;
    }

    if (column.id === 'currency') {
      Notification.warning({
        title: 'Сurrency is not available for change',
      });

      return;
    }

    if (
      layer === FilterLayers.Groups &&
      (column.id === 'benchmarkNegative' || column.id === 'benchmarkPositive')
    ) {
      Notification.warning({
        title: 'You can not change benchmarks for groups',
      });

      return;
    }

    setIsEdit(true);
  }, [isCanBeEdit, column, layer, row]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  const onBlurHandler = useCallback(async () => {
    // if user doesn't change value: click on cell and click outside
    if (String(prevInputValue) === String(inputValue)) {
      setIsEdit(false);

      return;
    }

    const actionForUpdateValue = isNodeCell
      ? updateNodeValue
      : updateInstrumentValue;
    const subRowsIsNode = row.subRows[0]?.original.rowType === 'node';

    if (subRowsIsNode) {
      for (const subRow of row.subRows) {
        if (!subRow.subRows.length) {
          await getAndInsertInstrumentsToNode({
            dispatch,
            getInstruments,
            path: subRow.original.path,
            queryParams,
            row: subRow,
            skip: 0,
          });
        }

        dispatch(
          actionForUpdateValue({
            path: subRow.original.path,
            value: Number(inputValue),
            column: column.id,
          }),
        );
      }
    }

    if (isNodeCell && !row.subRows.length) {
      await getAndInsertInstrumentsToNode({
        dispatch,
        getInstruments,
        path: row.original.path,
        queryParams,
        row,
        skip: 0,
      });
    }

    dispatch(
      actionForUpdateValue({
        path: row.original.path,
        value: Number(inputValue),
        column: column.id,
      }),
    );

    setIsEdit(false);
  }, [
    column,
    dispatch,
    getInstruments,
    inputValue,
    isNodeCell,
    prevInputValue,
    queryParams,
    row,
    setIsEdit,
  ]);

  const buttonLabel = useMemo(() => {
    if (isNodeCell && value === null) {
      return '-';
    }
    return String(value) + (column.id.startsWith('markup') ? '%' : '');
  }, [isNodeCell, value, column.id]);

  if (isEdit) {
    return (
      <StyledInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="number"
        value={inputValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );
  }

  return <StyledButton onClick={onClickHandler}>{buttonLabel}</StyledButton>;
};
