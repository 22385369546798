import { IIndicatorLine } from 'react-ui-kit-exante';

import { marginColorMap } from '~/constants';
import { TMarginMode } from '~/types/margin';

interface IGetProportionArguments {
  positionMargin: number;
  totalOrderMargin: number;
  totalBlockedMargin: number;
}

export function getProportion(
  props: IGetProportionArguments,
): IGetProportionArguments {
  const { positionMargin, totalOrderMargin, totalBlockedMargin } = props;
  const sum = positionMargin + totalOrderMargin + totalBlockedMargin;

  if (sum <= 100) {
    return props;
  }

  const proportion = 100 / sum;

  return {
    positionMargin: Math.round(positionMargin * proportion),
    totalOrderMargin: Math.round(totalOrderMargin * proportion),
    totalBlockedMargin: Math.round(totalBlockedMargin * proportion),
  };
}

export function getLines(
  proportions: IGetProportionArguments,
  mode: TMarginMode,
): IIndicatorLine[] {
  return Object.keys(proportions).map((key) => ({
    color: marginColorMap[mode][key],
    value: proportions[key as keyof IGetProportionArguments],
  }));
}
