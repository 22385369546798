import * as Yup from 'yup';

export const DEFAULT_VALUES = {
  underlying1: '',
  deltaRatio1: '',
  underlying2: '',
  deltaRatio2: '',
  spreadCredit: '',
  sameLegSide: false,
  tierFrontFront: false,
  tierFrontBack: false,
  tierBackBack: false,
  priority: 0,
};

export const VALIDATION_SCHEMA = Yup.object({
  underlying1: Yup.string().required('Underlying₁ is required'),
  deltaRatio1: Yup.number().typeError('Δ₁ should be number'),
  underlying2: Yup.string().required('Underlying₂ is required'),
  deltaRatio2: Yup.number().typeError('Δ₂ should be number'),
  spreadCredit: Yup.number().typeError('Spread credit be number'),
  sameLegSide: Yup.boolean(),
  tierFrontFront: Yup.boolean(),
  tierFrontBack: Yup.boolean(),
  tierBackBack: Yup.boolean(),
  priority: Yup.number().typeError('Priority should be number'),
});
