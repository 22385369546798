import { MutableRefObject, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useLazyGetLimitsInstrumentsQuery,
  useLazyGetLimitsTreeQuery,
} from '~/api';
import { MAX_REQUEST_LIMIT } from '~/constants';
import { usePrevious } from '~/hooks';
import {
  insertInstruments,
  resetTableForRefreshInSearchMode,
  resetTableForRefreshInStandardMode,
  selectDownloadedPaths,
  selectIsSearchMode,
  selectIsRefreshMode,
  setRefreshMode,
  resetRefreshMode,
} from '~/store/limits';
import { selectEntity, selectLayer } from '~/store/treeStructure';

interface UseRefreshDataParams {
  searchValue: MutableRefObject<string>;
  onSearch: (value: string, callback?: unknown) => Promise<void>;
}

export const useRefreshData = ({
  searchValue,
  onSearch,
}: UseRefreshDataParams) => {
  const [getLimitsTree] = useLazyGetLimitsTreeQuery();
  const [getInstruments] = useLazyGetLimitsInstrumentsQuery();

  const dispatch = useDispatch();
  const downloadedPaths = useSelector(selectDownloadedPaths);
  const isSearchMode = useSelector(selectIsSearchMode);
  const layer = useSelector(selectLayer);
  const entity = useSelector(selectEntity);
  const isRefreshMode = useSelector(selectIsRefreshMode);

  const prevIsRefreshMode = usePrevious(isRefreshMode);

  const refresh = useCallback(async () => {
    if (isSearchMode) {
      dispatch(resetTableForRefreshInSearchMode());

      onSearch(searchValue.current, resetRefreshMode);

      return;
    }

    dispatch(resetTableForRefreshInStandardMode());

    await getLimitsTree();

    // eslint-disable-next-line no-restricted-syntax
    for (const path of downloadedPaths) {
      // eslint-disable-next-line no-await-in-loop
      const { data: result } = await getInstruments({
        path,
        layer,
        entity,
      });

      if (!result) {
        return;
      }

      let instruments = result.instruments;

      if (result.pagination.total > MAX_REQUEST_LIMIT) {
        const requests = [];

        const numberOfRequests = Math.ceil(
          (result.pagination.total - MAX_REQUEST_LIMIT) / MAX_REQUEST_LIMIT,
        );

        for (let i = 1; i <= numberOfRequests; i += 1) {
          requests.push(
            getInstruments({
              path,
              layer,
              skip: MAX_REQUEST_LIMIT * i,
            }),
          );
        }

        // eslint-disable-next-line no-await-in-loop
        const results = await Promise.all(requests);

        results.forEach(({ data: res }) => {
          if (res) {
            instruments = instruments.concat(res.instruments);
          }
        });
      }

      dispatch(insertInstruments({ path, instruments }));
    }

    dispatch(resetRefreshMode());
  }, [
    dispatch,
    downloadedPaths,
    getInstruments,
    getLimitsTree,
    isSearchMode,
    layer,
    onSearch,
    searchValue,
  ]);

  const onRefresh = useCallback(() => {
    dispatch(setRefreshMode());
  }, [dispatch]);

  useEffect(() => {
    if (isRefreshMode && !prevIsRefreshMode) {
      refresh();
    }
  }, [refresh, prevIsRefreshMode, isRefreshMode]);

  return { onRefresh, isRefreshLoading: isRefreshMode };
};
