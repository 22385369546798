import * as Yup from 'yup';

export const GLOBAL_CASH_VALIDATION_SCHEMA = Yup.object({
  negativeGlobalCashLimit: Yup.number()
    .transform((value, originalValue) =>
      originalValue?.trim() === '' ? null : value,
    )
    .nullable()
    .typeError('Should be a number'),
  positiveGlobalCashLimit: Yup.number()
    .transform((value, originalValue) =>
      originalValue?.trim() === '' ? null : value,
    )
    .nullable()
    .typeError('Should be a number'),
});
