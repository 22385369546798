import { clone, cloneDeep, groupBy, set, unset } from 'lodash';
import { useCallback, useContext, useRef, useState } from 'react';

import { MIN_SEARCH_LENGTH, REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { symbolPermissionsService } from '~/resources';
import { getAllPathsSet } from '~/shared/utils';

import { ActionTypes, SymbolPermissionsContext } from '../../constants';
import { ICommonPropertiesSymbolPermissions } from '../../types';

import { matchingIdAndPositionInTable } from './helpers';

export function useSearchInstruments({
  matchIdAndPositionInNonSearchTable,
  preCall,
  setTableData,
  symbolsTree,
  updateMatchIdAndPositionInSearchTable,
  updateSearchIsActive,
  updateTableSizes,
}: Pick<
  ICommonPropertiesSymbolPermissions,
  | 'matchIdAndPositionInNonSearchTable'
  | 'preCall'
  | 'setTableData'
  | 'symbolsTree'
  | 'updateMatchIdAndPositionInSearchTable'
  | 'updateSearchIsActive'
  | 'updateTableSizes'
>) {
  const abortController = useRef(new AbortController());
  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const {
    filters: { queryParams, withExpired },
  } = state;
  const [searchIsLoading, setSearchIsLoading] = useState(false);

  const resetTable = useCallback(() => {
    updateSearchIsActive(false);
    dispatch({
      type: ActionTypes.EXPANDED_ROWS_RESET,
      payload: null,
    });
    updateTableSizes();
    setTableData(symbolsTree.current);
  }, [
    dispatch,
    setTableData,
    symbolsTree,
    updateSearchIsActive,
    updateTableSizes,
  ]);

  const debounceSearchInstruments = useCallback(
    async (searchValue: string) => {
      abortController.current.abort();
      abortController.current = new AbortController();

      const search = searchValue.trim();

      dispatch({ type: ActionTypes.EXPANDED_ROWS_RESET, payload: null });

      if (search.length < MIN_SEARCH_LENGTH) {
        resetTable();

        return;
      }

      setSearchIsLoading(true);

      if (!queryParams) {
        return;
      }

      preCall();

      const instrumentsFromServer =
        await symbolPermissionsService.searchInstruments({
          search,
          preparedParams: {
            ...queryParams,
            withExpired,
          },
          options: {
            signal: abortController.current.signal,
          },
        });

      if (
        !instrumentsFromServer ||
        !matchIdAndPositionInNonSearchTable.current ||
        !symbolsTree.current
      ) {
        setSearchIsLoading(false);
        return;
      }

      const groupedInstrumentsByPath = groupBy(instrumentsFromServer, (item) =>
        item.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
      );

      const pathsWithInstruments: string[] = [];

      const tree = cloneDeep(symbolsTree.current);

      const defaultPositionByIdInTree = clone(
        matchIdAndPositionInNonSearchTable.current,
      );

      Object.entries(groupedInstrumentsByPath).forEach(
        ([path, instruments]) => {
          const position = defaultPositionByIdInTree[path];
          set(tree, `${position}.subRows`, instruments);
          pathsWithInstruments.push(path);
          delete defaultPositionByIdInTree[path];
        },
      );

      const allPathsSet = getAllPathsSet(
        pathsWithInstruments,
        REGEX_LAST_SEGMENT_IN_PATH,
      );

      Object.entries(defaultPositionByIdInTree).forEach(([path, position]) => {
        if (!allPathsSet.has(path)) {
          unset(tree, position);
        }
      });

      updateMatchIdAndPositionInSearchTable(matchingIdAndPositionInTable(tree));
      updateSearchIsActive(true);

      setTableData(tree);

      setSearchIsLoading(false);
    },
    [
      matchIdAndPositionInNonSearchTable,
      preCall,
      queryParams,
      resetTable,
      setSearchIsLoading,
      setTableData,
      symbolsTree,
      updateMatchIdAndPositionInSearchTable,
      updateSearchIsActive,
      withExpired,
    ],
  );

  return { debounceSearchInstruments, searchIsLoading, resetTable };
}
