import { useCallback, useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { IconButton, Modal, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE } from '~/constants';
import { IFormArrayItemStructure } from '~/types/form';

import { FormInputContainer } from '../FormInputContainer';
import { FormMultiSelectContainer } from '../FormMultiSelectContainer';
import { FormSelectAsyncContainer } from '../FormSelectAsyncContainer';
import { FormSelectContainer } from '../FormSelectContainer';

import {
  StyledFormArrayHeader,
  StyledFormArrayRow,
} from './FormArrayContainer.styled';
import { FormArrayDeleteButton } from './components';
import { getEmptyRow } from './helpers/getEmptyRow';

interface IFormArrayContainerProps {
  name: string;
  structure: Array<IFormArrayItemStructure>;
  textForAddButton?: string;
  title?: string;
}

export const FormArrayContainer = ({
  name,
  structure,
  textForAddButton,
  title,
}: IFormArrayContainerProps) => {
  const confirmModal = useModal();
  const indexForDelete = useRef<number | null>(null);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const EMPTY_ROW = getEmptyRow(structure);

  const openModal = (index: number) => {
    confirmModal.onOpen();
    indexForDelete.current = index;
  };

  const closeModal = () => {
    confirmModal.onClose();
    indexForDelete.current = null;
  };

  const addKeyValue = useCallback(() => {
    append(EMPTY_ROW);
  }, [append, EMPTY_ROW]);

  const removeKeyValue = useCallback(() => {
    if (typeof indexForDelete.current === 'number') {
      remove(indexForDelete.current);
    }

    indexForDelete.current = null;

    confirmModal.onClose();
  }, [remove, confirmModal]);

  return (
    <div>
      <StyledFormArrayHeader>
        <h3>{title}</h3>

        <IconButton
          iconColor="action"
          iconName="AddIcon"
          label={textForAddButton}
          onClick={addKeyValue}
        />
      </StyledFormArrayHeader>

      {fields?.map((field, rowIndex) => {
        return (
          <StyledFormArrayRow key={field.id}>
            {structure.map(
              // TODO: need to delete columnIndex from logic
              (
                { name: fieldName, type, fetchData, label, options, ...props },
                columnIndex,
              ) => {
                switch (type) {
                  case 'selectasync':
                    if (fetchData) {
                      return (
                        <FormSelectAsyncContainer
                          fetchData={fetchData}
                          key={`${field.id}.${label}`}
                          name={`${name}.${rowIndex}.${
                            fieldName || columnIndex
                          }`}
                          {...props}
                        />
                      );
                    }
                    return null;
                  case 'select':
                    return (
                      <FormSelectContainer
                        key={`${field.id}.${label}`}
                        label={label}
                        name={`${name}.${rowIndex}.${fieldName || columnIndex}`}
                        options={options || []}
                        {...props}
                      />
                    );
                  case 'multiselect':
                    return (
                      <FormMultiSelectContainer
                        key={`${field.id}.${label}`}
                        name={`${name}.${rowIndex}.${fieldName || columnIndex}`}
                        options={options || []}
                        {...props}
                      />
                    );
                  case 'number':
                  case 'tel':
                  case 'text':
                    return (
                      <FormInputContainer
                        key={`${field.id}.${label}`}
                        label={label}
                        name={`${name}.${rowIndex}.${fieldName || columnIndex}`}
                        type={type}
                        {...props}
                      />
                    );
                  default:
                    return null;
                }
              },
            )}

            <FormArrayDeleteButton index={rowIndex} openModal={openModal} />
          </StyledFormArrayRow>
        );
      })}

      <Modal
        isOpened={confirmModal.isOpened}
        onClose={closeModal}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: removeKeyValue,
        }}
        keepMounted={false}
      >
        <div>{CONFIRM_DELETE_MESSAGE}</div>
      </Modal>
    </div>
  );
};
