import {
  ILegalEntityDetail,
  ILegalEntityFormData,
} from '~/types/legalEntities';

import { TYPES_LEGAL_ENTITY_ENDPOINT } from '../endpoints';

import { legalEntitiesApi } from './legalEntitiesApi';

interface UpdateLegalEntityParams {
  legalEntityName: string;
  data: ILegalEntityFormData;
}

export const { useUpdateLegalEntityMutation } =
  legalEntitiesApi.injectEndpoints({
    endpoints: (builder) => ({
      updateLegalEntity: builder.mutation<
        ILegalEntityDetail,
        UpdateLegalEntityParams
      >({
        query: ({ legalEntityName, data }) => ({
          url: `${TYPES_LEGAL_ENTITY_ENDPOINT}/${legalEntityName}`,
          method: 'POST',
          data,
        }),
      }),
    }),
  });
