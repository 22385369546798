import { isBefore } from 'date-fns';

import { today } from './variables';

export function isExpired(expirationDate?: string | Date | null) {
  if (!expirationDate) {
    return false;
  }

  return isBefore(new Date(expirationDate), today);
}
