import { CenteredLoader } from 'react-ui-kit-exante';

import { StyledWrapper } from './StaticCenteredLoader.styled';

interface IStaticCenteredLoaderProps {
  height?: string;
  withBackground?: boolean;
  backgroundColor?: string;
}

export const StaticCenteredLoader = ({
  height,
  backgroundColor,
  withBackground = false,
}: IStaticCenteredLoaderProps) => {
  return (
    <StyledWrapper
      style={{ height: height || 'auto', backgroundColor }}
      withBackground={withBackground}
    >
      <CenteredLoader isInner />
    </StyledWrapper>
  );
};
