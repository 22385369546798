import { IColumn } from 'react-ui-kit-exante';

import { TAccountReferrals } from '~/types/accounts';

export const columns: IColumn<TAccountReferrals>[] = [
  {
    Header: 'Referrals account',
    accessor: 'accountId',
    disableFilters: true,
  },
];
