import { pickBy, get, has, isEmpty, isUndefined, isEqual } from 'lodash';
import { useCallback } from 'react';

import {
  useGetUserAccessRightsQuery,
  useUpdateGeneralPermissionsMutation,
  useUpdateUserAccessRightsMutation,
} from '~/api';
import { useUpdateAtpPermissionsMutation } from '~/api/accountPermissions';
import { servicesService } from '~/resources';
import { UserServices } from '~/resources/services/types';
import {
  ICoreFormUser,
  IGeneralPermissions,
  TFormAccessRights,
} from '~/types/users';

import { TServiceStateMap } from '../../types';
import { useAccessRightsData } from '../../useAccessRightsData';

const isDifferentPermissions =
  (
    initialValue: Record<string, unknown> | undefined,
    newValue: { read?: boolean; write?: boolean },
  ) =>
  (op: 'read' | 'write') => {
    if (!has(newValue, 'read')) {
      return false;
    }
    const isNewPermission = isUndefined(initialValue?.[op]);

    if (
      (!isNewPermission && initialValue?.[op] !== newValue?.[op]) ||
      (newValue?.[op] && !initialValue?.[op])
    ) {
      return true;
    }

    return false;
  };

export function useCreateHandlersMap(
  user: ICoreFormUser,
  userServices: UserServices,
) {
  const [updateUserAccessRights] = useUpdateUserAccessRightsMutation();
  const [updateAtpPermissions] = useUpdateAtpPermissionsMutation();
  const [updateGeneralPermissions] = useUpdateGeneralPermissionsMutation();

  const { data: initialAccessRight } = useGetUserAccessRightsQuery(
    user.username,
    { skip: !user.username },
  );

  const {
    data: { services },
  } = useAccessRightsData(user.id, user.username);

  const createHandlersMap = useCallback(() => {
    if (!user) {
      return null;
    }

    return {
      accessRights: (accessRights: TFormAccessRights) => {
        const changedUserAccessRights = pickBy(accessRights, (value, key) => {
          // we cannot use isEqual here because 'read' or 'write' fields can be undefined in initialAccessRight
          const initialValue = get(
            initialAccessRight?.userAccessRightsResponse,
            key,
          );
          const newValue = value.values;
          const ops = ['read', 'write'] as const;
          const hasChanged = isDifferentPermissions(initialValue, newValue);

          return ops.some(hasChanged);
        });

        return updateUserAccessRights({
          accessRights: isEmpty(initialAccessRight?.userAccessRightsResponse)
            ? accessRights
            : changedUserAccessRights,
          userName: user.username,
        });
      },
      generalPermissions: (generalPermissions: IGeneralPermissions) => {
        return updateGeneralPermissions({
          userId: user.id,
          generalPermissions,
        });
      },
      services: (changedServices: TServiceStateMap) => {
        const hasChangedAtpPermissions = !isEqual(
          services?.atp.permissions,
          changedServices.atp.permissions,
        );

        return servicesService.updateServicesPermissions(
          user.username,
          changedServices,
          userServices,
          user,
          hasChangedAtpPermissions ? updateAtpPermissions : undefined,
        );
      },
    };
  }, [
    initialAccessRight?.userAccessRightsResponse,
    services,
    updateAtpPermissions,
    updateGeneralPermissions,
    updateUserAccessRights,
    user,
    userServices,
  ]);

  return createHandlersMap;
}
