import { Tooltip, ButtonDatePicker } from 'react-ui-kit-exante';

import { WarningLite } from '~/images/icons';
import { today } from '~/shared/utils/dates';

import HistoricalModeStyles from './HistoricalMode.module.css';
import { WARNING_TOOLTIP_TEXT } from './constants';

interface IHistoricalModeProps {
  closeSessionTime?: string | null;
  date: Date | null;
  setDate(date: Date | null): void;
  resetDate(): void;
}

export const HistoricalMode = ({
  closeSessionTime,
  date,
  setDate,
  resetDate,
}: IHistoricalModeProps) => {
  return (
    <div className={HistoricalModeStyles.Wrapper}>
      {closeSessionTime && (
        <Tooltip title={WARNING_TOOLTIP_TEXT}>
          <span className={HistoricalModeStyles.WarningIcon}>
            <WarningLite />
          </span>
        </Tooltip>
      )}
      <ButtonDatePicker
        maxDate={today}
        showTodayButton
        onChange={setDate}
        selected={date || null}
        todayButtonText="Real time"
        onToday={() => {
          setDate(today);
          resetDate();
        }}
      />
    </div>
  );
};
