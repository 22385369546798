import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { useCreateUserMutation } from '~/api';
import { USER_TYPE_OPTIONS } from '~/constants/options';
import {
  FormInputContainer,
  FormSelectContainer,
  FormMultiSelectWithAllOptions,
} from '~/containers/form';
import { useBrandingList } from '~/hooks';
import { USERS_PATH } from '~/routes';
import { CloseButton, FullHeightPanel, SaveButton } from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';

import { StyledFields, StyledHeader } from './NewUserStyled';
import { defaultValues } from './constants';
import { newUserSchema } from './schema';

export const NewUserPage = () => {
  const { brandingList, haveAllBranding, brandingListWithoutAll } =
    useBrandingList();
  const navigate = useNavigate();
  const [createUser, { isLoading: loading }] = useCreateUserMutation();

  const brandingDefault = brandingList[0] && brandingList[0].value;
  const brandingPermissionOptions = brandingList.map((item) => item.value);

  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(newUserSchema(haveAllBranding)),
  });

  const { handleSubmit } = formInstance;

  const onSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async (values) => {
      const createdUser = await createUser({ data: values });

      if (!('error' in createdUser)) {
        const createdUserId = createdUser.data.info.id;
        navigate(`${USERS_PATH}/${createdUserId}`);
      }
    },
    [createUser, navigate],
  );

  const onClose = useCallback(() => {
    navigate(USERS_PATH);
  }, [navigate]);

  useEffect(() => {
    if (!haveAllBranding) {
      formInstance.setValue('branding', brandingDefault);
      formInstance.setValue('brandingPermission', brandingPermissionOptions);
    } else {
      formInstance.setValue('brandingPermission', brandingDefault);
    }
  }, [
    brandingDefault,
    brandingPermissionOptions,
    formInstance,
    haveAllBranding,
  ]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader>
          <EntryScreenActions
            closeButton={<CloseButton onClick={onClose} />}
            saveButton={loading ? <Loader /> : <SaveButton />}
            title="Create new user"
          />
        </StyledHeader>

        <FullHeightPanel>
          <StyledFields>
            <FormInputContainer
              key="username"
              label="Username"
              name="username"
            />
            <FormInputContainer
              key="password"
              label="Password"
              name="password"
              type="password"
            />
            <FormInputContainer
              key="passwordConfirmation"
              label="Re-password"
              name="passwordConfirmation"
              type="password"
            />
            <FormSelectContainer
              key="userType"
              label="User type"
              name="userType"
              options={USER_TYPE_OPTIONS}
            />
            <FormSelectContainer
              key="branding"
              label="Branding"
              name="branding"
              options={brandingListWithoutAll}
            />
            <FormMultiSelectWithAllOptions
              key="brandingPermission"
              label="Branding permission"
              name="brandingPermission"
              options={brandingPermissionOptions}
              withAll={false}
            />
          </StyledFields>
        </FullHeightPanel>
      </form>
    </FormProvider>
  );
};
