import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react';

import { EMPTY_ARRAY, EMPTY_FUNCTION } from '~/constants';
import { ITab } from '~/types/tabs';

import { TDirtyTabs } from '../useDirtyTabs/types';

const TabsContext = createContext<[number, Dispatch<SetStateAction<number>>]>([
  0,
  EMPTY_FUNCTION,
]);

export const TabsContextProvider = ({ children }: { children: ReactNode }) => {
  const state = useState(0);

  return <TabsContext.Provider value={state}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => {
  const contextValue = useContext(TabsContext);

  return contextValue;
};

interface IUseTabsProps<T extends string, ComponentProps> {
  modal: {
    isOpened: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
  dirtyTabs?: TDirtyTabs<T>;
  tabs?: ITab<T, ComponentProps>[];
}

export function useTabs<T extends string, ComponentProps>({
  modal,
  dirtyTabs,
  tabs,
}: IUseTabsProps<T, ComponentProps>) {
  const [activeTab, setActiveTab] = useTabsContext();
  const [invalidTabs, setInvalidTabs] = useState<number[]>(EMPTY_ARRAY);
  const tabsContent = useMemo(
    () =>
      tabs
        ? tabs.map(({ id, Component }, index) => ({
            index,
            id,
            Component,
          }))
        : [],
    [tabs],
  );
  const newValueRef = useRef(0);

  const handleTabChange = useCallback(
    (event: SyntheticEvent | null, newValue: number) => {
      const currentTab = tabs?.[activeTab];

      if (!currentTab) {
        return;
      }

      newValueRef.current = newValue;
      const isCurrentTabDirty = Boolean(dirtyTabs?.[currentTab.id as T]);

      if (currentTab.id && !isCurrentTabDirty) {
        setActiveTab(newValue);
      } else {
        modal.onOpen();
      }
    },
    [modal, setActiveTab, activeTab, dirtyTabs, tabs],
  );

  const confirmNewTab = useCallback(() => {
    setActiveTab(newValueRef.current);
    modal.onClose();
  }, [modal, setActiveTab]);

  const handleSetInvalidTab = useCallback(
    (index: number) => setInvalidTabs((prev) => [...prev, index]),
    [],
  );
  const handleRemoveInvalidTab = useCallback(
    (index: number) =>
      setInvalidTabs((prev) => prev.filter((idx) => idx !== index)),
    [],
  );
  const resetInvalidTabs = useCallback(() => setInvalidTabs(EMPTY_ARRAY), []);

  return {
    activeTab,
    confirmNewTab,
    currentTab: tabs?.[activeTab],
    handleRemoveInvalidTab,
    handleSetInvalidTab,
    handleTabChange,
    invalidTabs,
    resetInvalidTabs,
    tabsContent,
  };
}
