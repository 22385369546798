import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, IColumn } from 'react-ui-kit-exante';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

interface IOverrideCellProps {
  column: IColumn<ICommissionTree>;
  row: IColumn<ICommissionTree>;
  value: boolean;
}

export const OverrideCell = ({ column, value, row }: IOverrideCellProps) => {
  const { layer } = column.getProps();
  const dispatch = useDispatch();

  const isNodeCell = row.original.rowType === RowType.Node;

  const disabled = layer === FilterLayers.Default;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const actionForUpdateValue = isNodeCell
        ? updateNodeValue
        : updateInstrumentValue;

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: event.target.checked,
          column: column.id,
        }),
      );

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: false,
          column: 'inherited',
        }),
      );
    },
    [column, dispatch, isNodeCell, row],
  );

  return (
    <Checkbox disabled={disabled} checked={value} onChange={onChangeHandler} />
  );
};
