import { ReactNode } from 'react';

import DescriptionListStyles from './DescriptionList.module.css';

interface IDescriptionListProps {
  list: Array<[string, string | ReactNode]>;
}

export const DescriptionList = ({ list }: IDescriptionListProps) => {
  return (
    <>
      {list.map(([title, description]) => (
        <dl key={title + description} className={DescriptionListStyles.Item}>
          <dt>{title}</dt>
          <dd>{description}</dd>
        </dl>
      ))}
    </>
  );
};
