import { Notification, NotificationVariant } from 'react-ui-kit-exante';

export function sendNotification(
  message: string,
  type: NotificationVariant,
): void {
  if (typeof Notification !== undefined) {
    Notification[type]({
      title: message,
    });
  }
}
