import { createApi } from '@reduxjs/toolkit/query/react';

import { regularCommissionsService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';
import type { TApplicationState } from '~/types/store';

export const regularCommissionsApi = createApi({
  reducerPath: 'regularCommissionsQuery',
  baseQuery: async (args) =>
    transformToBaseQueryResponse(
      await regularCommissionsService.resolveAccountRegularCommissions(args),
    ),
  endpoints: (build) => ({
    getRegularCommissions: build.query({
      query: (accountId: string) => accountId,
    }),
  }),
});

export const { useGetRegularCommissionsQuery } = regularCommissionsApi;

export const selectRegularCommissions = (
  state: TApplicationState,
  id: string,
) => {
  const key = `getRegularCommissions("${id}")`;

  return state.regularCommissionsQuery.queries[key];
};
