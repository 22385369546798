interface RollbackIconProps {
  size?: number;
}

export const RollbackIcon = ({ size = 16 }: RollbackIconProps) => {
  return (
    <svg width={size} height={size} fill="none">
      <path
        d="M5 8.5L2 5.5L5 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 12.5H10.5C11.4283 12.5 12.3185 12.1313 12.9749 11.4749C13.6313 10.8185 14 9.92826 14 9C14 8.07174 13.6313 7.1815 12.9749 6.52513C12.3185 5.86875 11.4283 5.5 10.5 5.5H2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
