import { LimitSetResponse } from '~/types/limits';

import { FETCH_LIMITS_SETS } from '../endpoints';

import { limitsApi } from './limitsApi';

export const {
  useGetLimitGroupsQuery,
  useLazyGetLimitGroupsQuery,
  endpoints: {
    getLimitGroups: { matchFulfilled: getLimitsGroupsMatchFulfilled },
  },
} = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    getLimitGroups: builder.query<LimitSetResponse[], void>({
      query: () => ({
        url: FETCH_LIMITS_SETS,
      }),
      providesTags: ['LimitSets'],
    }),
  }),
});
