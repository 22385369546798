import { Dispatch, SetStateAction, useCallback } from 'react';

import { TabContentHeader } from '~/shared/components';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import { IUseQueryReturn } from '~/types/rtkQuery';
import { ICoreFormUser } from '~/types/users';

import { useActions } from '../hooks';
import { TPasswordFormDefaultValues } from '../types';

import { TokenListComponent } from './TokenListComponent';

interface ITokenListContainerProps {
  setDisplayAddTokenForm: Dispatch<SetStateAction<boolean>>;
  setPasswordFormDefaultValues: Dispatch<
    SetStateAction<TPasswordFormDefaultValues>
  >;
  userQuery: IUseQueryReturn<ICoreFormUser | null | undefined>;
}

export const TokenListContainer = ({
  setDisplayAddTokenForm,
  setPasswordFormDefaultValues,
  userQuery,
}: ITokenListContainerProps) => {
  const { id: userId } = userQuery.data ?? {};

  const handleEditClick = useCallback(
    (name: string) => {
      setPasswordFormDefaultValues({ name });
      setDisplayAddTokenForm(true);
    },
    [setDisplayAddTokenForm, setPasswordFormDefaultValues],
  );

  const actions = useActions(setDisplayAddTokenForm, userId!);

  if (!userId) {
    return null;
  }

  return (
    <section>
      <WrapperLoader isLoading={false}>
        <TabContentHeader actions={actions} title="Password Management" />

        <TokenListComponent handleEditClick={handleEditClick} />
      </WrapperLoader>
    </section>
  );
};
