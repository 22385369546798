import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useLazyGetGroupSettingsQuery } from '~/api';
import {
  selectAccount,
  selectGroupIdConnectedWithAccount,
  setAccount,
  setUserSelectedGroupIdForAccount,
} from '~/store/treeStructure';
import { IOption } from '~/types/form';
import { TabAccount } from '~/types/treeStructure';

import {
  AutocompleteAsyncStyled,
  SelectStyled,
  WrapperStyled,
} from './AccountsFilter.styled';

interface AccountsFilterProps {
  onFilterChange: () => void;
  tabAccount: TabAccount;
}

export const AccountsFilter = ({
  tabAccount,
  onFilterChange,
}: AccountsFilterProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const urlAccountId = searchParams.get('accountId') || '';

  const { getGroupOptions, getAccounts, getAccountInfo } = tabAccount;

  const [getGroupId] = useLazyGetGroupSettingsQuery();

  const [groupOptions, setGroupOptions] = useState<IOption[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateAccountId = useSelector(selectAccount);
  const groupId = useSelector(selectGroupIdConnectedWithAccount);

  const accountHandleChange = useCallback(
    async (_: unknown, { value: accountId }: { value: string }) => {
      const { data } = await getGroupId({
        accountId,
      });

      dispatch(
        setAccount({
          accountId,
          groupId: data?.limitsSetId,
        }),
      );

      navigate(`?layer=accounts&accountId=${accountId}`);

      onFilterChange();
    },
    [navigate, onFilterChange, getGroupId, dispatch],
  );

  const groupHandleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setUserSelectedGroupIdForAccount(event.target.value));
    },
    [dispatch],
  );

  useEffect(() => {
    Promise.all([getGroupOptions(), getAccountInfo(urlAccountId)]).then(
      ([resultGroupOptions, accountInfo]) => {
        setGroupOptions(resultGroupOptions);

        dispatch(setAccount(accountInfo));

        navigate(`?layer=accounts&accountId=${accountInfo.accountId}`);

        onFilterChange();
      },
    );
  }, []);

  return stateAccountId && groupOptions.length ? (
    <WrapperStyled>
      <AutocompleteAsyncStyled
        fetchData={getAccounts}
        options={[]}
        value={String(stateAccountId)}
        onChange={accountHandleChange}
        placeholder="Account"
      />
      <SelectStyled
        options={groupOptions}
        value={String(groupId)}
        onChange={groupHandleChange}
        label="Group"
      />
    </WrapperStyled>
  ) : null;
};
