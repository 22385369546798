import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Panel } from 'react-ui-kit-exante';

import {
  useGetSymbolPermissionsGroupsQuery,
  useGetUserOverridesQuery,
} from '~/api';
import { transformSymbolPermissionsGroupsToOptions } from '~/api/symbolPermissions/helpers';
import { usePickUserPermissions } from '~/hooks';
import { SYMBOL_PERMISSIONS_PATH } from '~/routes';
import { NoDataWrapper } from '~/shared/components';
import { FilterLayers } from '~/types/symbolPermissions';

import { FormSelectContainer } from '../../form';
import { IDataAvailabilityState } from '../types';

import GroupSettingsStyles from './styles.module.css';

interface IGroupSettingsProps {
  dataAvailabilityState: IDataAvailabilityState;
  userName: string;
}

export const GroupSettings = ({
  dataAvailabilityState,
  userName,
}: IGroupSettingsProps) => {
  const userPermissions = usePickUserPermissions([
    'User info',
    'User symbol permissions',
  ]);
  const hasUserInfoWriteAccess =
    userPermissions['User info'].write &&
    userPermissions['User symbol permissions'].write;

  const { data: overrides } = useGetUserOverridesQuery({
    id: userName,
  });

  const { data: permissionsOptions } = useGetSymbolPermissionsGroupsQuery(
    undefined,
    {
      selectFromResult: transformSymbolPermissionsGroupsToOptions,
    },
  );

  if (!userPermissions['User symbol permissions'].read) {
    return null;
  }

  return (
    <Panel
      className={cn(GroupSettingsStyles.GroupSettings, {
        Disabled: !hasUserInfoWriteAccess,
      })}
      title="Group settings"
    >
      <NoDataWrapper hasData={dataAvailabilityState.hasGroupSettingsData}>
        <FormSelectContainer
          label="Permissions"
          name="groupSettings.permissionsSetId"
          options={permissionsOptions}
        />
        <p className={GroupSettingsStyles.HelperText}>
          {overrides?.permissions
            ? 'Overrides on User level exist.'
            : 'No overrides on User level.'}{' '}
          <NavLink
            className={GroupSettingsStyles.TextLink}
            target="_blank"
            to={`${SYMBOL_PERMISSIONS_PATH}?layer=${FilterLayers.Users}&user=${userName}`}
          >
            Configure
          </NavLink>
        </p>
      </NoDataWrapper>
    </Panel>
  );
};
