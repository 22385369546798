import { isEqual } from 'lodash';

export function migrateWbu1180() {
  try {
    const key = 'web-bo-commissions-table-view-params';

    const oldDefaultOrder = [
      'name',
      'override',
      'inherited',
      'currency',
      'exchange',
      'internal',
      'rebate',
      'total',
    ];

    const ls = JSON.parse(localStorage.getItem(key) || '{}');

    if (isEqual(ls.defaultColumnOrder, oldDefaultOrder)) {
      localStorage.removeItem(key);
    }
  } catch {
    console.error('Failed to migrate wbu1180');
  }
}
