import { createHashMapFromArray, isKey } from './helpers';

export function findCreatedElements<Data extends Record<keyof Data, unknown>>(
  previous: Data[],
  next: Data[],
  comparedField: keyof Data,
) {
  const previousHashMap = createHashMapFromArray(previous, comparedField);

  return next.filter((initialItem) => {
    const currentValue = initialItem[comparedField];

    if (isKey(currentValue)) {
      return !previousHashMap[currentValue];
    }

    // if your uniq key in new item doesn't generate
    // Case: we have cards, id generates on back-end.
    // Front have not unique keys for new cards.
    // So, in order not to store a temporary ID, just check for undefined
    return currentValue === undefined;
  });
}
