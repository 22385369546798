import { ChangeEvent, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from 'react-ui-kit-exante';

import { CurrencySelectorContainer } from '~/containers/CurrencySelectorContainer';

import HeaderStyles from './Header.module.css';
import { NAV_LINKS } from './constants';

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      navigate(event.target.value);
    },
    [navigate],
  );

  return (
    <header className={HeaderStyles.Header}>
      <span className={HeaderStyles.Title}>Web BO</span>
      <div className={HeaderStyles.CurrencySelectorContainer}>
        <CurrencySelectorContainer />
      </div>

      <Select
        onChange={onChangeHandler}
        options={NAV_LINKS}
        size="small"
        value={pathname}
      />
    </header>
  );
};
