import { Children, ReactNode } from 'react';

import AccountPositionsStyles from './AccountPositions.module.css';

interface IAccountPositionsProps {
  columnsSelect: ReactNode;
  tables: ReactNode;
}

export const AccountPositions = ({
  columnsSelect,
  tables,
}: IAccountPositionsProps) => {
  return (
    <div className={AccountPositionsStyles.Wrapper}>
      <div className={AccountPositionsStyles.ColumnWrapper}>
        {columnsSelect}
      </div>

      <div>{Children.map(tables, (item) => item)}</div>
    </div>
  );
};
