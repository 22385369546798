import { useGetCoreAccountQuery } from '~/api';
import { useAccountSummary } from '~/pages/AccountPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { DetailedAccountInfoContainer } from './DetailedAccountInfoContainer';

interface ISummaryWithAccountInfoProps {
  accountId: string;
  archived?: boolean;
}

export const SummaryWithAccountInfo = ({
  accountId,
  archived,
}: ISummaryWithAccountInfoProps) => {
  const { data, isLoading, date, setDate, resetDate, currency } =
    useAccountSummary(accountId, archived);
  const { data: account } = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );

  // todo loading should be on navigation but shouldn't be on interval
  if (isLoading) {
    return <StaticCenteredLoader height="125px" />;
  }

  if (!data) {
    return null;
  }

  return (
    <DetailedAccountInfoContainer
      accountSummary={data}
      date={date}
      setDate={setDate}
      resetDate={resetDate}
      currency={currency}
      internalComment={account?.internalComment}
    />
  );
};
