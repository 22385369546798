import { IServiceResponse } from '~/types/api';

const defaultArguments: IServiceResponse<never> = {
  data: undefined,
  error: null,
  messages: undefined,
};

export function createServiceResponse<T>(
  args: IServiceResponse<T> = defaultArguments,
): IServiceResponse<T> {
  const { data, error, messages } = args;

  return { data, error, messages };
}
