import { useGetAccountPermissionDataQuery } from '~/api/accountPermissions';
import { IUsePageDataReturn } from '~/types/rtkQuery';

import { IAccountPermissionsQueries, IDataAvailabilityState } from './types';

export const useAccountPermissionsData = (
  userName: string,
): IUsePageDataReturn<IAccountPermissionsQueries, IDataAvailabilityState> => {
  const accountPermissionsQuery = useGetAccountPermissionDataQuery(
    { userId: userName },
    { refetchOnMountOrArgChange: true },
  );

  const isAccountPermissionsLoading =
    accountPermissionsQuery.isLoading || accountPermissionsQuery.isFetching;
  const isDataLoading = isAccountPermissionsLoading;

  const hasAccountPermissionsData = Boolean(accountPermissionsQuery.data);

  return {
    queries: { accountPermissionsQuery },
    isDataLoading,
    dataAvailabilityState: {
      hasAccountPermissionsData,
    },
  };
};
