import { useCallback, useMemo } from 'react';
import { Indicator } from 'react-ui-kit-exante';

import { IMarginData } from '~/types/margin';

import { getLines, getProportion } from './helpers';

interface IMarginIndicatorContainerProps {
  netAssetValue: number;
  totalOrderMargin: number;
  totalBlockedMargin: number;
  marginData: IMarginData;
}

export const MarginIndicatorContainer = ({
  netAssetValue,
  totalOrderMargin,
  totalBlockedMargin,
  marginData,
}: IMarginIndicatorContainerProps) => {
  const { positionMargin, mode, moneyUsedFromMarginPercent } = marginData;

  const getPercentFromNav = useCallback(
    (value: number) => Math.floor((value / Math.abs(netAssetValue)) * 100),
    [netAssetValue],
  );
  const proportions = useMemo(
    () =>
      getProportion({
        positionMargin:
          netAssetValue > 0
            ? getPercentFromNav(positionMargin)
            : moneyUsedFromMarginPercent,
        totalOrderMargin: getPercentFromNav(totalOrderMargin),
        totalBlockedMargin: getPercentFromNav(totalBlockedMargin),
      }),
    [
      getPercentFromNav,
      moneyUsedFromMarginPercent,
      netAssetValue,
      positionMargin,
      totalBlockedMargin,
      totalOrderMargin,
    ],
  );
  const lines = useMemo(() => getLines(proportions, mode), [mode, proportions]);

  return <Indicator lines={lines} />;
};
