import { styled } from 'react-ui-kit-exante';

import { EN_DASH } from '~/constants';
import { FormInputContainer } from '~/containers/form';
import { MAX_WIDTH } from '~/shared/components/TreeStructure/constants';

export const StyledForm = styled('form')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  padding: '0 24px',
}));

export const StyledWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '16px',
  maxWidth: MAX_WIDTH,
}));

export const StyledFormInput = styled(FormInputContainer)(
  ({
    isDirty,
    isEmptyDefaultValue,
  }: {
    isDirty: boolean;
    isEmptyDefaultValue: boolean;
  }) => {
    if (isDirty) {
      return {};
    }

    return {
      '& div:not(.Mui-focused)': {
        borderColor: 'transparent',

        '&:before': {
          content: isEmptyDefaultValue ? `"${EN_DASH}"` : '""',
          position: 'absolute',
          bottom: '6px',
          left: '12px',
          pointerEvents: 'none',
        },
      },
    };
  },
);
