import { useCallback } from 'react';

import { EditIcon } from '~/images/icons';

interface ITokenEditIconProps {
  handleEditClick: (name: string) => void;
  tokenName: string;
}

export const TokenEditIcon = ({
  handleEditClick,
  tokenName,
}: ITokenEditIconProps) => {
  const onClickHandler = useCallback(() => {
    handleEditClick(tokenName);
  }, [handleEditClick, tokenName]);

  return (
    <span onClick={onClickHandler} role="button" tabIndex={0}>
      <EditIcon />
    </span>
  );
};
