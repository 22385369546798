import { ReactNode } from 'react';

import DottedItemStyles from './DottetItem.module.css';

interface IDottedItemProps {
  label: ReactNode;
  value: ReactNode;
  legendColor?: string;
}

export const DottedItem = ({ label, value, legendColor }: IDottedItemProps) => {
  return (
    <dl className={DottedItemStyles.Wrapper}>
      {legendColor && (
        <span
          className={DottedItemStyles.Legend}
          style={{ backgroundColor: legendColor }}
        />
      )}

      <dt className={DottedItemStyles.Label}>{label}</dt>
      <hr className={DottedItemStyles.DottedLine} />
      <dd className={DottedItemStyles.Value}>{value}</dd>
    </dl>
  );
};
