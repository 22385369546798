import { styled } from 'react-ui-kit-exante';

export const StyledHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  background: theme?.color.bg.primary,
  padding: `16px 24px`,
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme?.color.typo.primary,
  margin: 0,
  font: `32px ${theme?.font.header}`,
}));

export const StyledControls = styled('div')(({ theme }) => ({
  background: theme?.color.bg.primary,
  display: 'flex',
  justify: 'space-between',
  padding: `16px 24px`,
}));

export const StyledTableWrapper = styled('div')(({ theme }) => ({
  padding: '0 24px',
  background: theme?.color.bg.primary,
}));
