import { FilterLayers } from '~/types/symbolPermissions';

import { ActionTypes } from '../constants';
import { getQueryParams } from '../helpers';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function filtersReset(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.FILTERS_RESET],
) {
  const {
    layer = FilterLayers.Default,
    account = null,
    user = null,
    group = null,
    relatedGroup = null,
  } = payload;
  const select = { layer, account, user, group };
  const queryParams = getQueryParams({ ...select, relatedGroup });

  return {
    ...state,
    filters: {
      ...state.filters,
      relatedGroup,
      select,
      queryParams,
    },
  };
}
