import { useController, useFormContext } from 'react-hook-form';
import { Checkbox } from 'react-ui-kit-exante';

interface IFormCheckboxContainerProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disableWarning?: boolean;
  onChangeHandler?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    defaultCallback: () => unknown,
  ) => unknown;
}

export const FormCheckboxContainer = ({
  className = '',
  disabled = false,
  label,
  name,
  labelPlacement,
  disableWarning,
  onChangeHandler,
}: IFormCheckboxContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name,
  });

  return (
    <Checkbox
      className={className}
      disabled={disabled}
      checked={value ?? false}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={(...props) => {
        if (typeof onChangeHandler === 'function') {
          return onChangeHandler(...props, () => onChange(...props));
        }
        return onChange(...props);
      }}
      labelPlacement={labelPlacement}
      warning={!disableWarning && isDirty}
    />
  );
};
