import { isEqual } from 'lodash';

import { IStatePasswordManager } from '../types';

export function findDifferenceAuthFlow(
  initialAuthFlow: IStatePasswordManager['initial']['authFlow'],
  dataAuthFlow: IStatePasswordManager['data']['authFlow'],
) {
  if ([...initialAuthFlow.firstStep][0] !== [...dataAuthFlow.firstStep][0]) {
    return true;
  }

  if (
    !isEqual([...initialAuthFlow.secondSteps], [...dataAuthFlow.secondSteps])
  ) {
    return true;
  }

  return false;
}
