import { Checkbox, IColumn } from 'react-ui-kit-exante';

import { FilterLayers, ISymbolTreeStructure } from '~/types/symbolPermissions';

interface IViewCellProps {
  column: IColumn<ISymbolTreeStructure>;
  value: boolean;
}

export const TradeCell = ({ column, value }: IViewCellProps) => {
  const { layer } = column.getProps();

  return (
    <Checkbox checked={value} disabled={layer === FilterLayers.Effective} />
  );
};
