import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Panel, PanelGroup, CenteredLoader } from 'react-ui-kit-exante';

import { PanelHeaderControls } from '~/shared/components';
import { IOption } from '~/types/form';

import TradeAddPageStyles from './TradeAddPage.module.css';
import { TradeSwitch, TradeNonSwitch } from './components';
import {
  DEFAULT_VALUES,
  WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION,
  WATCHED_SWITCHED_FIELDS_FOR_COMMISSION,
} from './constants';
import {
  useFetchData,
  useOptions,
  usePresetDefaultValues,
  useSubmit,
  useSwitchTrade,
} from './hooks';
import { getTradeAddFormValidationSchema } from './validationSchema';

export const TradeAddPage = () => {
  const [isSwitchTrade, setIsSwitchTrade] = useState(false);
  const location = useLocation();
  const fetchData = useFetchData();

  const accountId = location?.state?.accountId;

  const useFormMethods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(getTradeAddFormValidationSchema(isSwitchTrade)),
  });
  const { getValues, reset, setValue, resetField, watch } = useFormMethods;

  const disabledAccruedInterest = !watch('nonSwitched.processAccruedInterest');
  const disabledSymbolIdOverride = !watch('nonSwitched.executionSchemeId');

  const watchCommissionSwitchedFields = watch(
    WATCHED_SWITCHED_FIELDS_FOR_COMMISSION,
  );
  const watchCommissionNonSwitchedFields = watch(
    WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION,
  );

  const { options, isExecutionSchemesLoading } = useOptions();

  const watchedNonSwitchedAccountId = watch('nonSwitched.accountId');
  const watchedSwitchedAccountId = watch('switched.accountId');

  const checkRequiredForCommissionSwitchedFields = useCallback(() => {
    const requiredForSwitchedCommissionFields =
      watchCommissionSwitchedFields.slice(
        0,
        watchCommissionSwitchedFields.length - 1,
      );
    const switchedTakeCommission =
      watchCommissionSwitchedFields[watchCommissionSwitchedFields.length - 1];
    if (requiredForSwitchedCommissionFields.some((t) => !t)) {
      if (switchedTakeCommission) {
        resetField('switched.takeCommission');
      }
    }
  }, [resetField, watchCommissionSwitchedFields]);

  const checkRequiredForCommissionNonSwitchedFields = useCallback(() => {
    const requiredForNonSwitchedCommissionFields =
      watchCommissionNonSwitchedFields.slice(
        0,
        watchCommissionNonSwitchedFields.length - 1,
      );
    const nonSwitchedTakeCommission =
      watchCommissionNonSwitchedFields[
        watchCommissionNonSwitchedFields.length - 1
      ];
    if (requiredForNonSwitchedCommissionFields.some((t) => !t)) {
      if (nonSwitchedTakeCommission) {
        resetField('nonSwitched.takeCommission');
      }
    }
  }, [resetField, watchCommissionNonSwitchedFields]);

  const updateInternalComment = useCallback(
    (
      internalCommentFieldKey:
        | 'nonSwitched.internalComment'
        | 'switched.internalComment',
      targetAccountId: string | IOption,
    ) => {
      if (targetAccountId) {
        if (typeof targetAccountId !== 'string') {
          setValue(
            internalCommentFieldKey,
            `Switch on ${targetAccountId.label}`,
          );
        } else {
          setValue(internalCommentFieldKey, `Switch on ${targetAccountId}`);
        }
      } else {
        setValue(internalCommentFieldKey, '');
      }
    },
    [setValue],
  );

  const syncInternalCommentsWithAccountId = useCallback(() => {
    const nonSwitchedInternalComment = 'nonSwitched.internalComment';
    const switchedInternalComment = 'switched.internalComment';

    updateInternalComment(nonSwitchedInternalComment, watchedSwitchedAccountId);
    updateInternalComment(switchedInternalComment, watchedNonSwitchedAccountId);
  }, [
    updateInternalComment,
    watchedNonSwitchedAccountId,
    watchedSwitchedAccountId,
  ]);

  const { disableSaveButton, onSubmitHandler, redirectToTradePage } = useSubmit(
    {
      accountId,
      getValues,
      isSwitchTrade,
      reset,
    },
  );
  const submitHandle = useFormMethods.handleSubmit(onSubmitHandler);

  usePresetDefaultValues({
    accountId,
    setValue,
  });

  const { onChangeSwitchTrade } = useSwitchTrade({
    setIsSwitchTrade,
    setValue,
    watch,
  });

  useEffect(() => {
    checkRequiredForCommissionSwitchedFields();
  }, [checkRequiredForCommissionSwitchedFields]);

  useEffect(() => {
    checkRequiredForCommissionNonSwitchedFields();
  }, [checkRequiredForCommissionNonSwitchedFields]);

  useEffect(() => {
    syncInternalCommentsWithAccountId();
  }, [syncInternalCommentsWithAccountId]);

  if (isExecutionSchemesLoading) {
    return <CenteredLoader />;
  }

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={submitHandle}>
        <Panel
          action={
            <PanelHeaderControls
              onClose={redirectToTradePage}
              disableSaveButton={disableSaveButton}
            />
          }
          disableBodyPaddings
          title="Add manual trade"
        >
          <PanelGroup
            className={cn(
              TradeAddPageStyles.Wrapper,
              TradeAddPageStyles[
                `WrapperSwitchModeIs${isSwitchTrade ? 'True' : 'False'}`
              ],
            )}
          >
            <TradeNonSwitch
              disabledAccruedInterest={disabledAccruedInterest}
              disabledSymbolIdOverride={disabledSymbolIdOverride}
              fetchData={fetchData}
              onChangeSwitchTrade={onChangeSwitchTrade}
              options={options}
              watchCommissionNonSwitchedFields={
                watchCommissionNonSwitchedFields
              }
            />

            {isSwitchTrade && (
              <TradeSwitch
                fetchData={fetchData}
                options={options}
                watchCommissionSwitchedFields={watchCommissionSwitchedFields}
              />
            )}
          </PanelGroup>
        </Panel>
      </form>
    </FormProvider>
  );
};
