import { formatNumber } from 'react-ui-kit-exante';

interface IProps {
  value: string | number;
  title: string;
}

const FRACTION_DIGITS = 4;

export const InterestRateCell = ({ value, title }: IProps) => {
  const formatted = formatNumber(Number(value), {
    minimumFractionDigits: FRACTION_DIGITS,
    maximumFractionDigits: FRACTION_DIGITS,
  });

  return <span title={title}>{formatted}</span>;
};
