export const DEFAULT_LOCK_VALUES = {
  accountId: '',
  amount: 0,
  asset: '',
  blockedQtty: 0,
  comment: '',
  operationType: '',
};

export const DEFAULT_UNLOCK_VALUES = {
  asset: '',
  uuid: '',
};

export enum Mode {
  Lock = 'Lock',
  Unlock = 'Unlock',
}
