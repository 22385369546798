import { useCallback, useEffect, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { clientsService } from '~/resources';
import { ICoreClient } from '~/types/clients';

export function useClient(id: string | undefined) {
  const [client, setClient] = useState<ICoreClient | null>(null);

  const getClient = useCallback(async () => {
    if (id) {
      const response = await clientsService.resolveClient(id);

      setClient(response);

      return response;
    }

    return null;
  }, [id]);

  const { fetchData, isLoading } = useData<ICoreClient | null>({
    onFetch: getClient,
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { client, isLoading };
}
