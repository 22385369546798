import { FilterLayers, TreeStructure } from '~/types/treeStructure';

import { RequestLayerInfo } from '../types';

export const prepareLayerInfo = (
  treeFilters: TreeStructure,
): RequestLayerInfo => {
  if (treeFilters.layer === FilterLayers.Accounts) {
    return {
      layer: treeFilters.layer,
      entity: treeFilters.account.accountId,
    };
  }

  if (treeFilters.layer === FilterLayers.Groups) {
    return {
      layer: treeFilters.layer,
      entity: treeFilters.group.groupId,
    };
  }

  return {
    layer: treeFilters.layer,
  };
};
