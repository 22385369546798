import { orderBy } from 'lodash';

import { IAccessRightsMap, TUserAccessRightsForm } from '~/types/users';

export function getFormValues(
  userAccessRights: IAccessRightsMap,
  allAccessRights: IAccessRightsMap,
): TUserAccessRightsForm[] {
  const userAccessRightsMap: IAccessRightsMap = {
    ...allAccessRights,
    ...userAccessRights,
  };
  const accessRightsList = Object.entries(userAccessRightsMap).map(
    ([operation]) => ({
      operation,
      // default values
      values: {
        read: userAccessRights[operation]?.read || false,
        write: userAccessRights[operation]?.write || false,
      },
      actions: {
        read: userAccessRights[operation]?.read || false,
        write: userAccessRights[operation]?.write || false,
        // disabled fields aren't used in form directly, it's to be able to disable necessary checkbox at Cell
        disabledFields: {
          read: allAccessRights[operation]?.read === undefined,
          write: allAccessRights[operation]?.write === undefined,
        },
      },
    }),
  );

  return orderBy(accessRightsList, ['operation'], ['desc']);
}
