import { prepareLayerInfo } from '~/api/limits/helpers';
import { EMPTY_INSTRUMENTS_DATA } from '~/constants/limits';
import { RowType } from '~/types/common';
import { ILimitInstrumentResponse } from '~/types/limits';
import { TreeStructure } from '~/types/treeStructure';

import { getFetchLimitsInstruments } from '../endpoints';
import {
  ISearchLimitsInstrumentsParams,
  ISearchLimitsInstrumentsReturn,
} from '../types';

import { limitsApi } from './limitsApi';

interface Store {
  treeStructure: TreeStructure;
}

export const {
  useLazySearchLimitsInstrumentsQuery,
  endpoints: {
    searchLimitsInstruments: {
      matchFulfilled: searchLimitsInstrumentsMatchFulfilled,
    },
  },
} = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    searchLimitsInstruments: builder.query<
      ISearchLimitsInstrumentsReturn,
      ISearchLimitsInstrumentsParams
    >({
      queryFn: async (params, api, __, fetchWithBaseQuery) => {
        const { search } = params;

        const state = api.getState() as Store;
        const treeFilters = state.treeStructure;

        const newParams = { search, limit: 1000, skip: 0 };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchLimitsInstruments(prepareLayerInfo(treeFilters)),
          params: newParams,
        });

        if (error || !data) {
          return EMPTY_INSTRUMENTS_DATA;
        }

        const { data: instruments } = data;

        return {
          data: {
            instruments: instruments.map(
              ({ limit: item, ...instrument }: ILimitInstrumentResponse) => ({
                ...instrument,
                ...item,
                rowType: RowType.Instrument,
              }),
            ),
            isFinish: true,
          },
        };
      },
      providesTags: ['LimitsSearchInstruments'],
    }),
  }),
});
