import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TSaveButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'>;

export const RemoveButton = (props: TSaveButtonProps) => {
  return (
    <IconButton
      iconName="DeleteIcon"
      iconColor="radical"
      label="Remove"
      type="submit"
      iconSize={24}
      {...props}
    />
  );
};
