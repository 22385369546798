import { useMemo } from 'react';

import { IUserTabComponentProps } from '~/pages/UserPage/TabManagement/types';
import { StaticCenteredLoader } from '~/shared/components';

import { ActivityContainer } from '../ActivityContainer';

import { useAccountsByUser } from './useAccountsByUser';

export const UserActivityContainer = ({
  userName,
  setRefetch,
}: IUserTabComponentProps) => {
  const { accountListLoading, accountsList } = useAccountsByUser(userName);

  const filters = useMemo(() => ({ accountId: accountsList }), [accountsList]);

  const auditLogsFilters = useMemo(() => {
    return accountsList.length ? filters : { user: [userName] };
  }, [accountsList.length, filters, userName]);

  if (accountListLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <ActivityContainer
      entity="user-activity-entry-screen"
      auditLogsFilters={auditLogsFilters}
      filters={filters}
      setRefetch={setRefetch}
    />
  );
};
