import { useEffect } from 'react';

import { useLogHandleTime, useAccountSettingsData } from '~/hooks';
import { IAccountTabComponentProps } from '~/pages/AccountPage/TabManagement/types';
import { useRefreshActiveTab } from '~/pages/AccountPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { AccountSettingsForm } from './AccountSettingsForm';

export const AccountSettingsContainer = ({
  accountId,
}: IAccountTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'account-settings-entry-screen',
  );
  const { setRefreshFn } = useRefreshActiveTab();

  setStartHandleTime();

  const {
    queries: {
      accountQuery,
      regularPaymentsQuery,
      extReportsQuery,
      internalQuery,
      cashConversionQuery,
      commissionQuery,
      routingQuery,
      regularCommissionsQuery,
      rebatesQuery,
      marginQuery,
      riskQuery,
      accountGroupSettingsQuery,
    },
    isDataLoading,
    isError,
    refetchAll,
    refetchQuery,
  } = useAccountSettingsData(accountId);

  useEffect(() => {
    if (!isDataLoading) {
      logHandleTime();
    }
  }, [isDataLoading, logHandleTime]);

  useEffect(() => {
    setRefreshFn('settings', {
      refetch: refetchAll,
      isLoading: isDataLoading,
    });
  }, [isDataLoading, refetchAll, setRefreshFn]);

  const handleRefresh = (refetchList: Set<string>) =>
    Promise.all([...refetchList].map(refetchQuery));

  if (isDataLoading && !isError) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <AccountSettingsForm
      account={accountQuery.data}
      cashConversion={cashConversionQuery.data}
      commission={commissionQuery.data}
      extReports={extReportsQuery.data}
      groupSettings={accountGroupSettingsQuery.data}
      internal={internalQuery.data}
      margin={marginQuery.data}
      rebates={rebatesQuery.data}
      refresh={handleRefresh}
      regularCommissions={regularCommissionsQuery.data}
      regularPayments={regularPaymentsQuery.data}
      risk={riskQuery.data}
      routing={routingQuery.data}
    />
  );
};
