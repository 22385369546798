import { ICreateUserPayload } from '~/types/users';

export const defaultValues: ICreateUserPayload & {
  passwordConfirmation: string;
} = {
  username: '',
  password: '',
  passwordConfirmation: '',
  userType: 'trader',
  branding: '',
  brandingPermission: [],
};
