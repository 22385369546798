import { isEqual } from 'lodash';
import { useEffect, useMemo, useCallback } from 'react';
import { Select, Loader, ISelectOption } from 'react-ui-kit-exante';

import { TInterestRatesMap } from '~/api/interestRates/types';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { ICurrency } from '~/types/currencies';
import { TGroupId } from '~/types/interestRates/interestRates';

import { InterestPageLayer } from '../../constants';
import { prepareDataForTable } from '../../helpers';
import { useRatesByGroup, useGroups } from '../../hooks';
import { TSetRefetch } from '../../types';

import GroupsSelectorStyles from './GroupsSelector.module.css';

interface IGroupRatesProps {
  group: TGroupId | null;
  setGroup: (groupId: TGroupId) => void;
  currencies: ICurrency[];
  defaultRates: TInterestRatesMap;
  loading?: boolean;
  setRefetchCurrentTabQuery: TSetRefetch;
  layer: InterestPageLayer;
}

export const GroupsSelector = ({
  group,
  setGroup,
  currencies,
  defaultRates,
  loading,
  setRefetchCurrentTabQuery,
  layer,
}: IGroupRatesProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'interests-rates-group-rates-list',
  );

  const {
    ratesByGroup,
    loading: loadingRatesByGroup,
    refetch,
  } = useRatesByGroup({
    group,
    defaultRates,
    preCall: setStartHandleTime,
  });

  const { groups, refetch: refetchGroups, loadingGroupList } = useGroups();

  useEffect(() => {
    setRefetchCurrentTabQuery({
      refetch: async () => Promise.all([refetch(), refetchGroups()]),
      isLoading: loadingRatesByGroup,
    });
  }, [setRefetchCurrentTabQuery, refetch, loadingRatesByGroup, refetchGroups]);

  const preparedGroupTableData = useMemo(
    () => prepareDataForTable(currencies, ratesByGroup),
    [currencies, ratesByGroup],
  );

  const prevPreparedGroupTableData = usePrevious(preparedGroupTableData);

  const preparedGroupsForSelect = useMemo(() => {
    const defaultGroup = { value: 'default', label: 'Default' };
    const options: ISelectOption[] =
      layer === InterestPageLayer.Accounts ? [defaultGroup] : [];
    if (groups) {
      groups.forEach(({ id: value, name: label }) => {
        options.push({ value, label });
      });
    }

    return options;
  }, [layer, groups]);

  useEffect(() => {
    if (
      group &&
      preparedGroupTableData &&
      !isEqual(preparedGroupTableData, prevPreparedGroupTableData)
    ) {
      logHandleTime();
    }
  }, [
    group,
    preparedGroupTableData,
    prevPreparedGroupTableData,
    logHandleTime,
  ]);

  return (
    <div className={GroupsSelectorStyles.Wrapper}>
      {loading || loadingGroupList ? (
        <Loader />
      ) : (
        <Select
          label="Group"
          fullWidth
          value={!group ? 'default' : group}
          options={preparedGroupsForSelect}
          onChange={(e) => {
            setGroup(Number(e.target.value));
          }}
        />
      )}
    </div>
  );
};
