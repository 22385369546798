import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetNonTradingCommissionsQuery } from '~/api';
import { selectGroupsById } from '~/store/nonTradingCommissions/slice';

import { removeDotsForTableColumns } from '../helpers';
import { TTableData } from '../types';

export function useTableData(): TTableData[] {
  const { data: commissions } = useGetNonTradingCommissionsQuery();

  const groupsById = useSelector(selectGroupsById);

  const tableData = useMemo(() => {
    if (Object.keys(groupsById).length === 0 || !commissions) {
      return [];
    }

    const nameInTableDataToIndex: Record<string, number> = {};

    return (
      commissions
        .reduce<TTableData[]>(
          (acc, { name, groupid, value, interval, type }) => {
            // commission can be set for a group that doesn't exist
            // example: group with commission was deleted
            if (!groupsById[groupid]?.name) {
              return acc;
            }

            const accIndex = nameInTableDataToIndex[name];
            const columnName = groupsById[groupid].name;
            const currency = groupsById[groupid].currency;

            if (accIndex !== undefined) {
              acc[accIndex][removeDotsForTableColumns(columnName)] = {
                ...value,
                currency,
                type,
                intervalType: interval?.type,
              };
            } else {
              nameInTableDataToIndex[name] = acc.length;

              acc.push({
                commissionName: name,
                [removeDotsForTableColumns(columnName)]: {
                  ...value,
                  currency,
                  type,
                  intervalType: interval?.type,
                },
              });
            }

            return acc;
          },
          [],
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .sort((a, b) => a.commissionName?.localeCompare(b.commissionName))
    );
  }, [commissions, groupsById]);

  if (!tableData) {
    return [];
  }

  return tableData;
}
