import { useCallback, useLayoutEffect } from 'react';

interface IParams {
  pageCount: number;
  setPage: (pageIndex: number) => void;
  tableId: string;
}

export const useSyncTableViewParams = ({
  pageCount,
  setPage,
  tableId,
}: IParams) => {
  const lsKey = `${tableId}-view-params`;
  // TODO: Validating of page index should be part of react-ui-kit. But now this is not possible because we calculate pageCount on the client side and
  // useTableData doesn't even know about it.
  const syncLsData = useCallback(() => {
    const savedData = localStorage.getItem(lsKey);
    const firstValidPageIndex = 0;

    if (!savedData) {
      return;
    }

    try {
      const parsed = JSON.parse(savedData) || {};

      const lastValidPageIndex =
        pageCount - 1 >= firstValidPageIndex
          ? pageCount - 1
          : firstValidPageIndex;

      if (lastValidPageIndex < parsed.page && pageCount !== 0) {
        setPage(lastValidPageIndex);
        parsed.page = lastValidPageIndex;

        const newData = JSON.stringify(parsed);
        localStorage.setItem(lsKey, newData);
      }
    } catch (e) {
      console.error(e);
    }
  }, [lsKey, setPage, pageCount]);

  useLayoutEffect(() => {
    syncLsData();
  }, [syncLsData]);
};
