import { mapValues } from 'lodash';

import { getPostLimitSetEndpointById } from '~/api/limits/endpoints';
import { selectCurrentLimitSet } from '~/store/limits/selectors/selectCurrentLimitSet';
import type { TApplicationState } from '~/types/store';

import { limitsApi } from './limitsApi';

export const { useUpdateLimitSetMutation } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    updateLimitSet: builder.mutation({
      queryFn: async (params, api, __, fetchWithBaseQuery) => {
        const state = api.getState() as TApplicationState;

        const formValues = mapValues(
          state.limits.globalCash.formValues,
          (value) => {
            return !value ? null : value;
          },
        );

        const { id, ...restSet } = selectCurrentLimitSet(state) || {};

        if (id) {
          const { data, error } = await fetchWithBaseQuery({
            url: getPostLimitSetEndpointById(id),
            method: 'POST',
            data: {
              ...restSet,
              ...formValues,
            },
          });

          if (error || !data) {
            return {
              error: error || 'Unknown back-end error for updating limit set',
            };
          }

          return { data };
        }

        return {
          error: 'Unknown front-end error for updating limit set',
        };
      },
    }),
  }),
});
