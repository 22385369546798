import { FC } from 'react';

import { WarnIcon } from '../../Icons';

import {
  ListItemTextStyled,
  ListItemButtonStyled,
} from './SelectedListItem.styled';
import { ISelectedListItemProps } from './types';

export const SelectedListItem: FC<ISelectedListItemProps> = ({
  isSelected,
  isWarned,
  onClick,
  text,
}) => {
  return (
    <ListItemButtonStyled
      disableGutters
      disableRipple
      selected={isSelected}
      onClick={onClick}
    >
      <ListItemTextStyled
        selected={isSelected}
        warned={isWarned}
        disableTypography
        primary={text}
        secondary={isWarned && <WarnIcon />}
      />
    </ListItemButtonStyled>
  );
};
