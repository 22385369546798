import { padStart } from 'lodash';

export const generateIdPostfix = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff = now.getTime() - start.getTime();
  const day = 1000 * 60 * 60 * 24;
  const currentDay = padStart(String(Math.floor(diff / day)), 3, '0');
  const lastCharInYear = now.getFullYear().toString().at(-1);

  return `${lastCharInYear}${currentDay}`;
};
