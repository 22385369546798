import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  useUpdateAtpPermissionsMutation,
  useUpdatePermissionsMutation,
} from '~/api/accountPermissions';
import { usePickUserPermissions } from '~/hooks';
import { useAccountStatuses } from '~/hooks/useAccountStatuses';
import { userPageContext } from '~/pages/UserPage/context/userPageContext';
import { FixedLoader, SaveButton, TabContentHeader } from '~/shared/components';
import { createFormSubmitHandler } from '~/shared/utils';
import { IAccountPermissionData } from '~/types/accountPermissions';
import { TFormHandlerNew } from '~/types/form';

import { AccountPermissions } from '../AccountPermissions';
import { IFormData } from '../types';
import { getValidationSchema } from '../vaildationSchema';

import { createHandlersMap, getDefaultValues } from './helpers';
import { usePreviousPermissions } from './usePreviousPermissions';

interface IPermissionsFormContainerProps {
  accountPermissions: IAccountPermissionData;
  userName: string;
}

export const PermissionsFormContainer = ({
  accountPermissions: {
    accountPermissions,
    defaultAccountAccess,
    atpPermissions,
  },
  userName,
}: IPermissionsFormContainerProps) => {
  const { setDirtyTabs } = useContext(userPageContext);
  const { accountStatusesOptions } = useAccountStatuses();
  const defaultValues = useMemo(
    () =>
      getDefaultValues(accountPermissions, defaultAccountAccess || 'blocked'),
    [accountPermissions, defaultAccountAccess],
  );
  const { previousPermissions, setPreviousPermissions } =
    usePreviousPermissions(defaultValues.permissions);
  const initialPermissions = previousPermissions.map((item) => item.accountId);

  const userPermissions = usePickUserPermissions(['User account status']);

  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = formInstance;

  const [updatePermissions] = useUpdatePermissionsMutation();
  const [updateAtpPermissions] = useUpdateAtpPermissionsMutation();

  const handlersMap = useMemo<TFormHandlerNew<IFormData> | null>(
    () =>
      createHandlersMap({
        userName,
        previousPermissions,
        setPreviousPermissions,
        atpPermissions,
        updatePermissions,
        updateAtpPermissions,
      }),
    [
      atpPermissions,
      previousPermissions,
      setPreviousPermissions,
      userName,
      updatePermissions,
      updateAtpPermissions,
    ],
  );

  const handleFormSubmit = useMemo(
    () =>
      createFormSubmitHandler({
        dirtyFields,
        handlersMap,
        reset,
        getValues,
      }),
    [dirtyFields, getValues, handlersMap, reset],
  );

  const actions = userPermissions['User account status'].write ? (
    <SaveButton type="submit" disabled={isSubmitting || !isDirty} />
  ) : undefined;

  useEffect(() => {
    setDirtyTabs('accountPermissions', isDirty);
  }, [dirtyFields, isDirty, setDirtyTabs]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isSubmitting && <FixedLoader />}

        <AccountPermissions
          header={
            <TabContentHeader title="Account permissions" actions={actions} />
          }
          accountStatusesOptions={accountStatusesOptions}
          initialPermissions={initialPermissions}
        />
      </form>
    </FormProvider>
  );
};
