import { IColumn } from 'react-ui-kit-exante';

import { TAccountRegularCommissionsTable } from '~/resources/regularCommissions/types';

export const columns: IColumn<TAccountRegularCommissionsTable>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    disableFilters: true,
    maxWidth: 100,
  },
  {
    Header: 'Amount',
    accessor: 'rate',
    disableFilters: true,
    maxWidth: 80,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    disableFilters: true,
    maxWidth: 100,
  },
  {
    Header: 'Frequency',
    accessor: 'frequency',
    disableFilters: true,
    maxWidth: 100,
  },
  {
    Header: 'Active',
    accessor: 'active',
    disableFilters: true,
    maxWidth: 80,
    type: 'checkbox',
    editParams: {
      editable: true,
      inputType: 'checkbox',
    },
  },
];
