import { get } from 'lodash';

/**
 * Checks if the provided error corresponds to a 403 (Forbidden) error for a GET request.
 *
 * This function extracts the status and method information from the provided error object
 * using lodash's `get` function. It then checks if the status is 403 and the method is 'get'.
 *
 * @param {unknown} error - The error object to check.
 * @returns {boolean} - True if the error corresponds to a 403 error for a GET request, false otherwise.
 */
export function is403GetError(error: unknown) {
  const status = get(error, 'response.status');
  const method = get(error, 'response.config.method');

  return status === 403 && method === 'get';
}
