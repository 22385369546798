import { TSymbolIdInfoParams, IBrokerClientAccount } from '~/types/symbols';

import { SYMBOL_ID_INFO_ENDPOINT } from '../endpoints';

import { symbolsApi } from './symbolsAPI';

export const {
  useLazyGetSymbolIdInfoQuery,
  endpoints: {
    getSymbolIdInfo: { matchFulfilled: getSymbolIdInfoMatchFulfilled },
  },
} = symbolsApi.injectEndpoints({
  endpoints: (builder) => ({
    getSymbolIdInfo: builder.query<
      { brokerClientAccounts: IBrokerClientAccount[] },
      TSymbolIdInfoParams
    >({
      query: (params) => ({
        url: SYMBOL_ID_INFO_ENDPOINT,
        params,
      }),
      providesTags: ['SymbolIdInfo'],
    }),
  }),
});
