import { FilterValue } from 'react-ui-kit-exante';

import { CONTEXT_SEARCH_FIELD_NAME } from './constants';

interface IGetAdditionalFilters {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export const getDefaultFilters = () => ({ username: '' });

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
}: IGetAdditionalFilters) => [
  {
    Header: 'Context Search',
    accessor: CONTEXT_SEARCH_FIELD_NAME,
    onFilter,
    onRemove,
  },
];
