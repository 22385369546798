import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TCellData } from 'react-ui-kit-exante';

import { IVirtualized } from '~/hooks';
import { prepareValue } from '~/pages/RiskArrays/helpers';
import { getTableId } from '~/shared/utils';
import {
  selectExpandedRows,
  selectFiltersQueryParams,
  selectOvernightsTree,
  updateInstrumentOverride,
  updateNodeOverride,
} from '~/store/overnights';
import { IOvernightTree } from '~/types/overnights';

import { canExpandDefinition } from '../../helpers';
import { useColumns, useHandleCellClick } from '../../hooks';

import { TableContainer } from './styled';

interface ITableComponentProps {
  virtualized: IVirtualized;
}

export const TableComponent = ({ virtualized }: ITableComponentProps) => {
  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const expandedRows = useSelector(selectExpandedRows);
  const tree = useSelector(selectOvernightsTree);
  const dispatch = useDispatch();

  const columns = useColumns(filtersQueryParams);
  const { handleCellClick } = useHandleCellClick();

  const expanded = {
    canExpandDefinition,
    customExpandControl: true,
    listOfInitialExpandedRowKeys: expandedRows,
  };

  const handleCellUpdate = useCallback(
    (values: TCellData<IOvernightTree>[]) => {
      values.forEach((value) => {
        const {
          id,
          row: {
            original: { path, rowType },
          },
          value: newValue,
        } = value;

        const actionFn =
          rowType === 'node' ? updateNodeOverride : updateInstrumentOverride;

        dispatch(
          actionFn({
            path,
            value: prepareValue(newValue),
            column: id,
          }),
        );
      });
    },
    [dispatch],
  );

  return (
    <TableContainer
      columns={columns}
      data={tree}
      defaultSortBy={[]}
      expanded={expanded}
      handleCellClick={handleCellClick}
      isFlexLayout
      saveViewParamsAfterLeave
      tableId={getTableId('Overnights')}
      virtualized={virtualized}
      onTableCellUpdate={handleCellUpdate}
      isPinnedHeader
    />
  );
};
