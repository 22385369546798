import { ReactNode } from 'react';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import ServicesPermissionsStyles from './ServicesPermissions.module.css';

interface IServicesPermissionsProps {
  group: ReactNode;
}

export const ServicesPermissions = ({ group }: IServicesPermissionsProps) => {
  return (
    <Panel title="Services">
      <PanelGroup
        className={ServicesPermissionsStyles.GroupWrapper}
        panelsPerLine={2}
      >
        {group}
      </PanelGroup>
    </Panel>
  );
};
