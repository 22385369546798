import { ReactNode, useMemo } from 'react';

import { useDirtyTabs } from '~/hooks';

import { DEFAULT_DIRTY_TABS_STATE } from './constants';
import { MirroringRulePageContextProvider } from './mirroringRulePageContext';
import { IMirroringRulePageContext, TTabsIds } from './types';

export const MirroringRulePageContextManager = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { dirtyTabs, setDirtyTabs } = useDirtyTabs<TTabsIds>(
    DEFAULT_DIRTY_TABS_STATE,
  );

  const contextValue = useMemo<IMirroringRulePageContext>(
    () => ({
      dirtyTabs,
      setDirtyTabs,
    }),
    [dirtyTabs, setDirtyTabs],
  );

  return (
    <MirroringRulePageContextProvider value={contextValue}>
      {children}
    </MirroringRulePageContextProvider>
  );
};
