import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';
import { ICurrentUserAccessRights } from '~/types/users';

import {
  ALL_ACCESS_RIGHTS_ENDPOINT,
  CURRENT_USER_ACCESS_RIGHTS_ENDPOINT,
  getUserAccessRightsEndpoint,
} from './endpoints';
import { userAccessRightsMapper, prepareAccessRightsForSend } from './helpers';
import { IAccessRights } from './types';

export const accessRightsApi = createApi({
  reducerPath: 'accessRightsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['AccessRightsCurrentUser', 'AccessRightsUser'],
  endpoints: (builder) => ({
    getCurrentUserAccessRights: builder.query<ICurrentUserAccessRights, void>({
      query: () => ({
        url: CURRENT_USER_ACCESS_RIGHTS_ENDPOINT,
      }),
      transformErrorResponse: () => {
        return {
          error: `Unexpected error while getting access rights for user`,
        };
      },
      providesTags: ['AccessRightsCurrentUser'],
    }),
    getUserAccessRights: builder.query<IAccessRights, string>({
      async queryFn(userName, _, __, fetchWithBaseQuery) {
        const allAccessRightsResponse = await fetchWithBaseQuery({
          url: ALL_ACCESS_RIGHTS_ENDPOINT,
        });

        const userAccessRightsResponse = await fetchWithBaseQuery({
          url: getUserAccessRightsEndpoint(userName),
        });

        if (allAccessRightsResponse.error || userAccessRightsResponse.error) {
          let resultError = ``;

          if (allAccessRightsResponse.error) {
            resultError += `Get all access rights: ${allAccessRightsResponse.error}\n`;
          }

          if (userAccessRightsResponse.error) {
            resultError += `Get user access rights: ${userAccessRightsResponse.error}`;
          }

          return {
            error: resultError,
          };
        }

        return {
          data: {
            allAccessRightsResponse: userAccessRightsMapper(
              allAccessRightsResponse.data,
            ),
            userAccessRightsResponse: userAccessRightsMapper(
              userAccessRightsResponse.data,
            ),
          },
        };
      },
      providesTags: ['AccessRightsUser'],
    }),
    updateUserAccessRights: builder.mutation({
      async queryFn({ userName, accessRights }, _, __, fetchWithBaseQuery) {
        const preparedAccessRights = prepareAccessRightsForSend(accessRights);

        const { error } = await fetchWithBaseQuery({
          url: getUserAccessRightsEndpoint(userName),
          method: 'PUT',
          data: preparedAccessRights,
        });

        if (error) {
          return {
            error,
          };
        }

        return {
          data: {
            data: accessRights,
            messages: { success: 'Access rights are successfully updated.' },
          },
        };
      },
      invalidatesTags: ['AccessRightsUser'],
    }),
  }),
});

export const {
  useGetCurrentUserAccessRightsQuery,
  useGetUserAccessRightsQuery,
  useUpdateUserAccessRightsMutation,
} = accessRightsApi;
