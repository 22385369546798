import { useGetAccountStatisticQuery } from '~/api';
import { prepareClientsParamsToRequest } from '~/pages/ClientsPage/helpers';

import { prepareData } from '../helpers';

export const useAccountsStatistic = (filters: Record<string, unknown>) => {
  const { data, isLoading, isFetching, error } = useGetAccountStatisticQuery(
    prepareClientsParamsToRequest(filters),
  );

  if (!data) {
    return {
      isLoading,
      isFetching,
      error,
      data: null,
    };
  }

  const preparedData = prepareData(data);
  const {
    allAccountsCount,
    allClientsCount,
    sumNetAssetValue,
    sumRequiredMargin,
    assets,
    crossEntity,
    liabilities,
    equity,
  } = preparedData;

  return {
    isFetching,
    isLoading,
    error,
    data: [
      ['Accounts', allAccountsCount],
      ['Clients', allClientsCount],
      ['Total Required Margin, EUR', sumRequiredMargin],
      ['Total Net Asset Value, EUR', sumNetAssetValue],
      ['Assets, EUR', assets],
      ['Cross Entity, EUR', crossEntity],
      ['Liabilities, EUR', liabilities],
      ['Equity, EUR', equity],
    ],
  };
};
