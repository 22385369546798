import { PaginationItem } from '@mui/material';
import debounce from 'debounce';
import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react';

import { useTranslator } from '../../../contexts/TranslationContext';
import { Input } from '../../Inputs/Input/Input';
import { TableFooterSkeleton } from '../TableSkeleton/TableFooterSkeleton';
import { calculateCountOfPages } from '../utils';

import {
  PaginationControlStyled,
  PaginationRootStyled,
  PaginationSelect,
  PaginationStyled,
  TextStyled,
} from './TablePagination.styled';
import {
  debounceTimeout,
  PAGE_SIZES,
  RADIX,
  selectCls,
  textFieldCls,
} from './const';
import { TablePaginationProps } from './types';

export const TablePaginationComponent: FC<TablePaginationProps> = ({
  total,
  pageIndex,
  pageCount: pages,
  pageSize,
  pageSizes,
  setPage,
  setPageSize,
  serverHandleChangeRowsPerPage,
  serverHandlePaginationChange,
  isLoading,
}) => {
  const { t } = useTranslator();

  const [error, setError] = useState(false);
  const pageCount = useMemo(
    () => pages ?? calculateCountOfPages(total, pageSize),
    [pageSize, pages, total],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPageSize(parseInt(event.target.value as string, RADIX));
    },
    [setPageSize],
  );

  const handlePaginationChange = useCallback(
    (event: ChangeEvent<unknown>, value: number) => setPage(value - 1),
    [setPage],
  );

  const handlePageChange = useMemo(
    () =>
      debounce((event: React.ChangeEvent<{ value: string }>) => {
        const pageNumber = Number(event.target.value) - 1;
        const isError =
          !Number.isInteger(pageNumber) ||
          pageNumber >= pageCount ||
          pageNumber < 0;
        setError(isError);
        if (isError) {
          return;
        }
        setPage(pageNumber);
      }, debounceTimeout),
    [pageCount, setPage],
  );

  const options = useMemo(
    () =>
      (pageSizes ?? PAGE_SIZES).map((newPageSize: number) => ({
        value: newPageSize,
        label: String(newPageSize),
      })),
    [pageSizes],
  );

  if (isLoading) {
    return <TableFooterSkeleton />;
  }

  const getValidatedPageIndex = () => {
    const isValidPageIndex = pageIndex <= pageCount - 1;
    const lastPageIndex = pageCount;

    return isValidPageIndex ? pageIndex + 1 : lastPageIndex;
  };

  return (
    <PaginationRootStyled>
      <PaginationControlStyled>
        <TextStyled>{t('ui__table__pagination__pagesize')}</TextStyled>
        <PaginationSelect
          sx={selectCls}
          value={pageSize}
          onChange={handleChangeRowsPerPage || serverHandleChangeRowsPerPage}
          inputProps={{
            'data-test-id': 'table__pagination--select',
          }}
          options={options}
          size="small"
        />
      </PaginationControlStyled>
      <PaginationStyled
        count={pageCount}
        shape="rounded"
        onChange={handlePaginationChange || serverHandlePaginationChange}
        page={getValidatedPageIndex()}
        renderItem={(item) => (
          <PaginationItem
            data-test-id={`table__pagination--page-${
              item?.type === 'page' ? item?.page : item?.type
            }`}
            {...item}
          />
        )}
      />
      <PaginationControlStyled>
        <TextStyled>{t('ui__table__pagination__gotopage')}</TextStyled>
        <Input
          inputProps={{
            'data-test-id': 'table__pagination--input',
          }}
          sx={textFieldCls}
          onChange={handlePageChange}
          placeholder="#"
          error={error}
          size="small"
        />
      </PaginationControlStyled>
    </PaginationRootStyled>
  );
};

export const TablePagination = memo(TablePaginationComponent);
