import { useEffect, useMemo, useRef, useState } from 'react';
import { IColumn, Table, RowActions } from 'react-ui-kit-exante';

import { formatCellToCurrency } from '~/shared/utils/formatters/formatCellToCurrency';
import { getFooterPropsWithTotal } from '~/shared/utils/table';
import { IPosition } from '~/types/accounts';

import { getExistingColumns } from '../../columns';
import { ACCOUNT_ASSET_TABLE_ID, TABLE_ROW_HEIGHT } from '../../constants';

import { StyledWrapper } from './AssetTable.styled';
import { getAdditionalColumns } from './helpers';

interface IAssetTableProps {
  columns: IColumn<IPosition>[];
  currency: string;
  displayedColumnKeys: string[];
  height: number;
  positions: IPosition[];
  requiredColumnKeys?: string[];
  rowActions?: RowActions<IPosition>;
  tableId: string;
  withLock: boolean;
}

export const AssetTable = ({
  columns,
  currency,
  displayedColumnKeys,
  height,
  positions,
  requiredColumnKeys,
  rowActions,
  tableId,
  withLock,
}: IAssetTableProps) => {
  const [cashAdditionalColumns, nonCashAdditionalColumns] = useMemo(
    () => getAdditionalColumns(currency, withLock),
    [currency, withLock],
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [tableWidth, setTableWidth] = useState(0);

  const virtualized = useMemo(() => {
    return {
      height,
      itemSize: TABLE_ROW_HEIGHT,
      width: tableWidth,
    };
  }, [height, tableWidth]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      const tableContentElement = containerElement.querySelector(
        "[data-test-id='table__content']",
      );

      if (tableContentElement) {
        setTableWidth(tableContentElement.clientWidth);
      }
    }
  }, []);

  const lockedColumns = useMemo(
    () =>
      tableId === `${ACCOUNT_ASSET_TABLE_ID}-cash`
        ? [
            ...getExistingColumns(positions, columns, requiredColumnKeys),
            ...cashAdditionalColumns,
          ]
        : [...columns, ...nonCashAdditionalColumns],
    [
      cashAdditionalColumns,
      columns,
      nonCashAdditionalColumns,
      positions,
      requiredColumnKeys,
      tableId,
    ],
  );

  const nonLockedColumns = useMemo(
    () => [
      ...getExistingColumns(positions, columns, requiredColumnKeys),
      ...[
        {
          Header: 'Blocked qtty',
          accessor: 'blockedQtty',
          Cell: formatCellToCurrency<IPosition>('blockedQtty'),
          formatting: 'number',
        },
      ],
    ],
    [columns, positions, requiredColumnKeys],
  );

  const lockedDisplayedColumnKeys = useMemo(
    () =>
      tableId === `${ACCOUNT_ASSET_TABLE_ID}-cash`
        ? [
            ...displayedColumnKeys,
            'lockedValue',
            'convertedLockedValue',
            'actions',
          ]
        : [...displayedColumnKeys, 'lockedQuantity', 'actions'],
    [displayedColumnKeys, tableId],
  );

  const nonLockedDisplayedColumnKeys = useMemo(
    () => [...displayedColumnKeys, 'blockedQtty'],
    [displayedColumnKeys],
  );

  return (
    <StyledWrapper ref={containerRef}>
      <Table
        columns={withLock ? lockedColumns : nonLockedColumns}
        data={positions}
        displayedColumnKeys={
          withLock ? lockedDisplayedColumnKeys : nonLockedDisplayedColumnKeys
        }
        getFooterProps={getFooterPropsWithTotal}
        isFlexLayout
        rowActions={withLock ? rowActions : undefined}
        saveViewParamsAfterLeave
        tableId={tableId}
        virtualized={virtualized}
      />
    </StyledWrapper>
  );
};
