import { useCallback, useState } from 'react';

import { IUseDirtyTabsReturn, TDirtyTabs, TSetDirtyTabs } from './types';

export function useDirtyTabs<T extends string>(
  defaultState: TDirtyTabs<T>,
): IUseDirtyTabsReturn<T> {
  const [dirtyTabs, setDirtyTabs] = useState(defaultState);

  const handleDirtyTabsChange = useCallback<TSetDirtyTabs<T>>(
    (tabName: T, isDirty: boolean) =>
      setDirtyTabs((prev) => ({ ...prev, [tabName]: isDirty })),
    [],
  );

  return { dirtyTabs, setDirtyTabs: handleDirtyTabsChange };
}
