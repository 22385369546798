import { TCommissionsQueryParams } from '~/types/commissions';

import { EMPTY_COMMISSIONS_TREE } from '../constants';
import { getFetchCommissionsTreeEndpoint } from '../endpoints';
import { convertCommissionsStructureFromFlatToTree } from '../helpers';
import { IConvertCommissionsStructureFromFlatToTreeReturn } from '../types';

import { commissionsApi } from './commissionsApi';

export const {
  useLazyGetCommissionsTreeQuery,
  endpoints: {
    getCommissionsTree: { matchFulfilled: getCommissionsTreeMatchFulfilled },
  },
} = commissionsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCommissionsTree: builder.query<
      IConvertCommissionsStructureFromFlatToTreeReturn,
      TCommissionsQueryParams | null
    >({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        if (!params) {
          return EMPTY_COMMISSIONS_TREE;
        }

        const { layer, entity } = params;

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsTreeEndpoint(layer, entity),
        });

        if (error || !data) {
          return {
            error: error || 'Error while fetching tree',
          };
        }

        return {
          data: convertCommissionsStructureFromFlatToTree(data.data),
        };
      },
      providesTags: ['CommissionsTree'],
    }),
  }),
});
