import { CenteredLoader } from 'react-ui-kit-exante';

import FixedLoaderStyles from './FixedLoader.module.css';

export const FixedLoader = () => {
  return (
    <div className={FixedLoaderStyles.Wrapper}>
      <CenteredLoader size="l" />
    </div>
  );
};
