import { mirroringRuleApi } from '~/store/mirroringRule';
import { IUsePageDataReturn, IUseQueryReturn } from '~/types/rtkQuery';

import {
  IDataAvailabilityState,
  IMirroringRuleInfoQueries,
  TFormData,
} from './types';

export const useMirroringRuleData = (
  mirroringRuleId: number,
): IUsePageDataReturn<IMirroringRuleInfoQueries, IDataAvailabilityState> => {
  const mirroringRuleQuery =
    mirroringRuleApi.endpoints.getMirroringRule.useQueryState<
      IUseQueryReturn<TFormData['mirroringRule']>
    >(mirroringRuleId);

  /**
   * isFetching is needed because we should rebuild form
   * when navigate between mirroring rules by navigation arrows
   * */
  const isDataLoading =
    mirroringRuleQuery.isLoading || mirroringRuleQuery.isFetching;

  const hasMirroringRuleInfoData = Boolean(mirroringRuleQuery.data);

  return {
    queries: {
      mirroringRuleQuery,
    },
    isDataLoading,
    dataAvailabilityState: {
      hasMirroringRuleInfoData,
    },
  };
};
