import { ReactNode } from 'react';

import { ParthenonIcon } from '../../../../images/icons';

import NameCellStyles from './NameCell.module.css';

interface ICellRootProps {
  children: ReactNode;
  displayParthenon: boolean;
  icon?: string;
  isColored: boolean;
}

const NODE_FOR_MANUAL_BORDER = 'CFD';

export const NameCellComponent = ({
  children,
  displayParthenon,
  icon = '',
  isColored,
  ...dataAttributes
}: ICellRootProps) => {
  return (
    <div className={NameCellStyles.CellRoot} {...dataAttributes}>
      {isColored ? (
        <span
          className={NameCellStyles.Icon}
          data-border={children?.toString() === NODE_FOR_MANUAL_BORDER}
          style={{ backgroundImage: `url(${icon})` }}
        />
      ) : null}

      {displayParthenon ? <ParthenonIcon width={14} height={14} /> : null}

      <span className={NameCellStyles.Name}>{children}</span>
    </div>
  );
};
