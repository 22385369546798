import { useState, useEffect, useReducer } from 'react';

import { useGetUserAuthFlowQuery } from '~/api';
import { EMPTY_OBJECT } from '~/constants';
import { useGeneralSettingsData } from '~/containers/UserGeneralSettingsContainer/useGeneralSettingsData';
import { useLogHandleTime } from '~/hooks';
import { useUserPageContextManager } from '~/pages/UserPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { TokenFormContainer } from './TokenForm';
import { TokenListContainer } from './TokenList';
import {
  ActionTypes,
  INITIAL_STATE,
  PasswordManagementContext,
} from './constants';
import { reducer } from './reducer';

interface IUserPasswordManagementContainerProps {
  userId: number;
}

export const UserPasswordManagementPage = ({
  userId,
}: IUserPasswordManagementContainerProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'user-password-management-entry-screen',
  );
  setStartHandleTime();

  const {
    queries: { userQuery },
    isDataLoading: isUserLoading,
  } = useGeneralSettingsData(userId);
  const {
    data: authFlow,
    isLoading: isAuthFlowLoading,
    refetch: getAuthFlow,
  } = useGetUserAuthFlowQuery({ userId });

  const passwordStateManager = useReducer(reducer, INITIAL_STATE);

  const [displayAddTokenForm, setDisplayAddTokenForm] = useState(false);
  const { setRefreshFn } = useUserPageContextManager();

  const [passwordFormDefaultValues, setPasswordFormDefaultValues] =
    useState(EMPTY_OBJECT);

  useEffect(() => {
    if (userQuery.data && authFlow) {
      const [, dispatch] = passwordStateManager;
      dispatch({
        type: ActionTypes.SET_INITIAL,
        payload: {
          tokens: userQuery.data?.tokens,
          authFlow,
        },
      });
    }
  }, [authFlow, userQuery]);

  useEffect(() => {
    setRefreshFn('passwordManagement', {
      refetch: async () => {
        userQuery.refetch();
        getAuthFlow();
      },
      isLoading: userQuery.isFetching || isAuthFlowLoading,
    });
  }, [getAuthFlow, isAuthFlowLoading, userQuery]);

  useEffect(() => {
    if (userQuery.data && !isUserLoading) {
      logHandleTime();
    }
  }, [userQuery, isUserLoading, logHandleTime]);

  if (isUserLoading || !userQuery.data || isAuthFlowLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <PasswordManagementContext.Provider value={passwordStateManager}>
      {displayAddTokenForm ? (
        <TokenFormContainer
          passwordFormDefaultValues={passwordFormDefaultValues}
          setDisplayAddTokenForm={setDisplayAddTokenForm}
          setPasswordFormDefaultValues={setPasswordFormDefaultValues}
        />
      ) : (
        <TokenListContainer
          setDisplayAddTokenForm={setDisplayAddTokenForm}
          setPasswordFormDefaultValues={setPasswordFormDefaultValues}
          userQuery={userQuery}
        />
      )}
    </PasswordManagementContext.Provider>
  );
};
