export function getInjectedValues(value: 'ALL' | string[]) {
  if (value === 'ALL') {
    return ['ALL'];
  }

  return value;
}

export function getNewValues(values: string[]) {
  if (values[values.length - 1] === 'ALL') {
    return 'ALL';
  }

  if (values.includes('ALL')) {
    return values.filter((value) => value !== 'ALL');
  }

  return values;
}
