import { debounce, isEqual, Dictionary, get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLazyGetTradeCommissionQuery } from '~/api';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '~/constants';
import { getValueFromOption } from '~/shared/utils';

type TWatchCommissionFields = Dictionary<
  string | boolean | { value: string; label: string }
>;

export function useCommission(watchCommissionFields: TWatchCommissionFields) {
  const abortController = useRef(new AbortController());
  const [commission, setCommission] = useState<string | null>(null);
  const prevWatchCommissionFields = useRef<null | TWatchCommissionFields>(null);
  const [fetchTradeCommission] = useLazyGetTradeCommissionQuery();
  const prefix = useMemo(() => {
    return 'switched.takeCommission' in watchCommissionFields
      ? 'switched'
      : `nonSwitched`;
  }, [watchCommissionFields]);

  const getCommission = useCallback(
    async (params: TWatchCommissionFields) => {
      abortController.current.abort();
      abortController.current = new AbortController();

      const { data: result } = await fetchTradeCommission({
        params: {
          price: Number(get(params, `${prefix}.price`)),
          quantity: Number(get(params, `${prefix}.quantity`)),
          symbolId: getValueFromOption(get(params, `${prefix}.symbolId`)),
          accountId: getValueFromOption(get(params, `${prefix}.accountId`)),
        },
        options: {
          signal: abortController.current.signal,
        },
      });

      if (result) {
        setCommission(result);
      }
    },
    [fetchTradeCommission, prefix],
  );

  const preparedGetCommission = useCallback(() => {
    return getCommission(watchCommissionFields);
  }, [getCommission, watchCommissionFields]);

  const { fetchData: fetchCommission, isLoading: isCommissionLoading } =
    useData({
      loading: false,
      onFetch: preparedGetCommission,
    });

  const useEffectCallback = useCallback(() => {
    if (isEqual(watchCommissionFields, prevWatchCommissionFields.current)) {
      return;
    }

    prevWatchCommissionFields.current = watchCommissionFields;

    const takeCommission = get(
      watchCommissionFields,
      `${prefix}.takeCommission`,
    );

    if (!takeCommission) {
      setCommission(null);
      return;
    }

    const hasEmptyField = Object.values(watchCommissionFields).some(
      (item) => !(typeof item === 'string' ? Boolean(item.trim()) : item),
    );

    if (hasEmptyField) {
      setCommission(null);
      return;
    }

    fetchCommission();
  }, [fetchCommission, prefix, watchCommissionFields]);

  const debouncedCallback = useMemo(
    () => debounce(useEffectCallback, DEFAULT_DEBOUNCE_TIMEOUT),
    [useEffectCallback],
  );

  useEffect(() => {
    debouncedCallback();

    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback, fetchCommission, watchCommissionFields]);

  return { commission, isCommissionLoading };
}
