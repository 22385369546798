import { get } from 'lodash';
import * as Yup from 'yup';

import { EMPTY_ARRAY } from '~/constants';

import { findDuplicateIndexes } from './findDuplicateIndexes';
import { findEmptyFieldIndexes } from './findEmptyFieldIndexes';

const INDEX_FOR_DUPLICATE = 0;

interface IValidationFormArrayMeta {
  createError: (
    params?: Yup.CreateErrorOptions | undefined,
  ) => Yup.ValidationError;
  path: string;
}

export function validationUserFormArray(
  initialValues: Array<Array<unknown> | Record<number, unknown>> | undefined,
  { createError, path }: IValidationFormArrayMeta,
) {
  const values = (initialValues ?? EMPTY_ARRAY).map((value) =>
    Array.isArray(value) ? value : Object.values(value),
  );

  const emptyFieldIndexes = findEmptyFieldIndexes(values);

  if (emptyFieldIndexes) {
    const [EMPTY_ROW_INDEX, EMPTY_COLUMN_INDEX] = emptyFieldIndexes;

    return new Yup.ValidationError([
      createError({
        message: `Field can not be empty`,
        path: `${path}.${EMPTY_ROW_INDEX}.${EMPTY_COLUMN_INDEX}`,
      }),
    ]);
  }

  const names = values.map((item: Array<unknown>) => {
    if (typeof item[INDEX_FOR_DUPLICATE] === 'string') {
      return item[INDEX_FOR_DUPLICATE];
    }

    return get(item, [INDEX_FOR_DUPLICATE, 'value']);
  });
  const duplicateIndexes = findDuplicateIndexes(names);

  if (duplicateIndexes) {
    const [FIRST_DUPLICATE_INDEX, SECOND_DUPLICATE_INDEX] = duplicateIndexes;

    return new Yup.ValidationError([
      createError({
        message: `Field should be unique`,
        path: `${path}.${FIRST_DUPLICATE_INDEX}.${INDEX_FOR_DUPLICATE}`,
      }),
      createError({
        message: `Field should be unique`,
        path: `${path}.${SECOND_DUPLICATE_INDEX}.${INDEX_FOR_DUPLICATE}`,
      }),
    ]);
  }

  return true;
}
