import { clone } from 'lodash';

import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function permissionsByPathsSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.PERMISSIONS_BY_PATHS_SET],
) {
  return {
    ...state,
    permissionsByPath: clone(payload),
  };
}
