import { FilterValue, ICellValue } from 'react-ui-kit-exante';

import { AutoCompleteSearch } from 'types/common';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';
import { formatPercentage } from '~/shared/utils';
import { IPosition } from '~/types/accounts';

interface IGetAdditionalFilters {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
}: IGetAdditionalFilters) => [
  {
    Header: 'Exclude accounts',
    accessor: 'excludeAccount',
    onFilter,
    onRemove,
  },
  {
    Header: 'Exclude asset accounts',
    accessor: 'excludeAssetAccounts',
    type: 'checkbox',
    onFilter,
    onRemove,
  },
];

export const DEFAULT_SORTING = [
  {
    id: 'accountId',
    desc: false,
  },
];

export const DEFAULT_SORTING_AGGREGATED_DATA = [
  {
    id: 'symbolId',
    desc: false,
  },
];

export const DEFAULT_SORTING_CURRENCY_RISK = [
  {
    id: 'currency',
    desc: false,
  },
];

export const DISPLAYED_COLUMNS_KEYS = [
  'id',
  'convertedDailyPnl',
  'timestamp',
  'price',
  'sharePercent',
  'priceUnit',
  'description',
  'typeOverride',
  'convertedValue',
  'dailyPnl',
  'blockedQtty',
  'averagePrice',
  'sedol',
  'convertedPnl',
  'accountType',
  'accountId',
  'accountPurpose',
  'archived',
  'quoteTimestamp',
  'value',
  'realizedPnl',
  'pnl',
  'currency',
  'quantity',
  'isin',
  'symbolId',
  'expirationTime',
  'ticker',
  'exchange',
  'figi',
  'shortName',
  'leverageRate',
  'pnlPercentage',
  'contractMultiplier',
  'dailyPnlPercentage',
];

export const DISPLAYED_COLUMNS_KEYS_WITH_LE = [
  ...DISPLAYED_COLUMNS_KEYS,
  'legalEntity',
];

interface ColumnsProps {
  currency: string;
  onRemove(col: string): void;
  onFilter(col: string, value: FilterValue): void;
  accountTypes?: { label: string; value: string }[];
  legalEntityOptions: { label: string; value: string }[];
  accountPurposesOptions?: string[];
  fetchAccounts?: AutoCompleteSearch;
  assetTypes: { label: string; value: string }[];
}

export function getColumns({
  currency,
  onRemove,
  onFilter,
  legalEntityOptions,
  accountTypes,
  accountPurposesOptions,
  assetTypes,
}: ColumnsProps) {
  return [
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      type: 'select',
      onRemove,
      onFilter,
      filterOptions: assetTypes,
    },
    {
      Header: 'Asset',
      accessor: 'symbolId',
      inputFilterTooltip: {
        type: 'custom',
        options: {
          Title: () => 'For precise searches please use quotation marks',
        },
      },
      onRemove,
      onFilter,
    },
    {
      Header: 'Account',
      accessor: 'accountId',
      onRemove,
      onFilter,
    },
    {
      Header: 'Account type',
      accessor: 'accountType',
      type: 'multiSelect',
      filterOptions: accountTypes,
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Account purpose',
      accessor: 'accountPurpose',
      disableSortBy: true,
      filterOptions: accountPurposesOptions,
      type: 'multiSelect',
      onRemove,
      onFilter,
    },
    {
      Header: 'Legal Entity',
      accessor: 'legalEntity',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: legalEntityOptions,
      onRemove,
      onFilter,
    },
    {
      Header: 'Leverage Rate',
      accessor: 'leverageRate',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Report date',
      accessor: 'reportDate',
      type: 'dateInput',
      formatting: 'dateUTC',
      disableFilters: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      type: 'numberRange',
      onRemove,
      onFilter,
      Cell: ({ row: { values } }: ICellValue<IPosition>) => {
        const quantity = values.quantity;
        return quantity ? quantity.toLocaleString() : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Average price',
      accessor: 'averagePrice',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'PNL',
      accessor: 'pnl',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Share percent',
      accessor: 'sharePercent',
      disableSortBy: true,
      Cell: ({ row: { values } }: ICellValue<IPosition>) =>
        formatPercentage(values.sharePercent),
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: `Value, ${currency}`,
      accessor: 'convertedValue',
      formatting: 'number',
      disableSortBy: true,
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: `P&L, ${currency}`,
      accessor: 'convertedPnl',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'SEDOL',
      accessor: 'sedol',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Expiration',
      accessor: 'expirationTime',
      disableSortBy: true,
      formatting: 'dateTimeUTC',
      type: 'dateTimeRange',
      onRemove,
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
    },
    {
      Header: 'Expiration Date',
      accessor: 'expirationDate',
      formatting: 'dateUTC',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: `Accrued interest ${currency}`,
      accessor: 'convertedAccruedInterest',
      disableSortBy: true,
      formatting: 'number',
    },
    {
      Header: 'Accrued interest',
      accessor: 'accruedInterest',
      disableSortBy: true,
      formatting: 'number',
    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      formatting: 'dateTimeUTC',
      type: 'dateTimeRange',
      onRemove,
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
    },
    {
      Header: `Daily P&L, ${currency}`,
      accessor: 'convertedDailyPnl',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Daily P&L',
      accessor: 'dailyPnl',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Daily P&L %',
      accessor: 'dailyPnlPercentage',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'P&L %',
      accessor: 'pnlPercentage',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Realized P&L',
      accessor: 'realizedPnl',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    { Header: 'id', accessor: 'id', disableSortBy: true, onRemove, onFilter },
    {
      Header: 'Quote timestamp',
      accessor: 'quoteTimestamp',
      formatting: 'dateTimeUTC',
      type: 'dateTimeRange',
      onRemove,
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
    },
    {
      Header: 'Figi',
      accessor: 'figi',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Price unit',
      accessor: 'priceUnit',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Ticker',
      accessor: 'ticker',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Contract multiplier',
      accessor: 'contractMultiplier',
      disableSortBy: true,
      formatting: 'number',
      type: 'numberRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Exchange',
      accessor: 'exchange',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Type override',
      accessor: 'typeOverride',
      disableSortBy: true,
      type: 'select',
      onRemove,
      onFilter,
      filterOptions: assetTypes,
    },
    {
      Header: 'Short name',
      accessor: 'shortName',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
  ];
}
