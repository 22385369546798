export const ACCOUNTS_PATH = '/bo/accounts';
export const ACCOUNT_ADD_PATH = `${ACCOUNTS_PATH}/add/:clientId`;
export const ACCOUNT_PATH = `${ACCOUNTS_PATH}/:id`;

export const AUDIT_LOG_PATH = '/bo/audit-log';

export const CLIENTS_PATH = '/bo/clients';
export const CLIENT_ADD_PATH = `${CLIENTS_PATH}/add`;
export const CLIENT_PATH = '/bo/clients/:id';

export const COMMISSIONS_PATH = '/bo/commissions';
export const COMMISSION_RULES_PATH = '/bo/commissions/rules';

export const EXPIRED_INSTRUMENTS = '/bo/expired-instruments';

export const GLOBAL_SUMMARY_BY_ACCOUNTS_PATH = '/bo/global-summary-by-accounts';
export const GLOBAL_SUMMARY_BY_POSITION_SIDE_PATH =
  '/bo/global-summary-by-position-side';
export const GLOBAL_SUMMARY_CURRENCY_RISK = '/bo/global-summary-currency-risk';
export const GLOBAL_SUMMARY_PATH = '/bo/global-summary';

export const INTERESTS_PATH = '/bo/interests';

export const INTER_COMMODITY_PATH = '/bo/inter-commodity';
export const INTER_COMMODITY_ADD_PATH = `${INTER_COMMODITY_PATH}/add`;
export const INTER_COMMODITY_UPDATE_PATH = `${INTER_COMMODITY_PATH}/update/:id`;
export const INTER_COMMODITY_UPDATE_REDIRECT = `${INTER_COMMODITY_PATH}/update`;

export const LEGAL_ENTITIES_PATH = '/bo/legal-entities';
export const LEGAL_ENTITY_ADD_PATH = `${LEGAL_ENTITIES_PATH}/add`;
export const LEGAL_ENTITY_UPDATE_PATH = `${LEGAL_ENTITIES_PATH}/update/:name`;
export const LEGAL_ENTITY_UPDATE_REDIRECT = `${LEGAL_ENTITIES_PATH}/update`;

export const LIMITS_PATH = '/bo/limits';

export const MIRRORING_RULES_PATH = '/bo/mirroring-rules';
export const MIRRORING_RULE_ADD_PATH = `${MIRRORING_RULES_PATH}/add`;
export const MIRRORING_RULE_PATH = '/bo/mirroring-rules/:id';

export const NON_TRADING_COMMISSIONS = '/bo/non-trading-commissions';
export const NON_TRADING_COMMISSIONS_ENTRY_SCREEN =
  '/bo/non-trading-commissions/:groupName';

export const OVERNIGHTS_PATH = '/bo/overnights';

export const RISK_ARRAYS_PATH = '/bo/risk-arrays';

export const SWAGGERS_PATH = '/bo/swaggers';

export const SYMBOL_PERMISSIONS_PATH = '/bo/symbol-permissions';

export const TRADES_PATH = '/bo/trades';
export const TRADE_ADD_PATH = `${TRADES_PATH}/add`;
export const TRADE_IMPORT_PATH = `${TRADES_PATH}/import`;

export const TRANSACTIONS_PATH = '/bo/transactions';
export const TRANSACTION_ADD_PATH = `${TRANSACTIONS_PATH}/add`;
export const TRANSACTION_IMPORT_PATH = `${TRANSACTIONS_PATH}/import`;

export const TRANSFER_PATH = '/bo/transfer';
export const TRANSFER_ADD_PATH = `${TRANSFER_PATH}/add`;

export const UNDERLYING_SETTINGS = '/bo/underlying-settings';

export const USERS_INTERNAL_PATH = '/bo/users-internal';

export const USERS_PATH = '/bo/users';
export const USER_ADD_PATH = `${USERS_PATH}/add`;
export const USER_INTERNAL_PATH = `${USERS_INTERNAL_PATH}/:id`;
export const USER_PATH = '/bo/users/:id';
