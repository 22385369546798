import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { useLazyGetLimitsInstrumentsQuery } from '~/api';
import { MAX_REQUEST_LIMIT } from '~/constants';
import { expandedRowsSet, insertInstruments } from '~/store/limits';
import { selectEntity, selectLayer, setLoading } from '~/store/treeStructure';
import { UseHandleCellClick } from '~/types/treeStructure';

export const useHandleCellClick = () => {
  const dispatch = useDispatch();
  const layer = useSelector(selectLayer);
  const entity = useSelector(selectEntity);

  const [getInstruments] = useLazyGetLimitsInstrumentsQuery();

  return useCallback(
    async ({ column, row, tableState: { expanded } }: UseHandleCellClick) => {
      const hasSubRows = !!row.subRows.length;

      if (column.id === 'name' && row.original.rowType === 'node') {
        if (hasSubRows) {
          row.toggleRowExpanded();

          dispatch(expandedRowsSet([expanded, row.id, true]));
        } else {
          dispatch(setLoading({ key: 'instruments', value: true }));

          const { data: responseWithInstruments } = await getInstruments({
            path: row.original.path,
            layer,
            entity,
          });

          if (!responseWithInstruments) {
            Notification.error({
              title: 'Error getting instruments',
            });

            dispatch(setLoading({ key: 'instruments', value: false }));

            return;
          }

          let instruments = responseWithInstruments.instruments;

          if (responseWithInstruments?.pagination?.total > MAX_REQUEST_LIMIT) {
            // Создаём массив промисов
            const requests = [];

            // Вычисляем количество необходимых запросов
            const numberOfRequests = Math.ceil(
              (responseWithInstruments.pagination.total - MAX_REQUEST_LIMIT) /
                MAX_REQUEST_LIMIT,
            );

            for (let i = 1; i <= numberOfRequests; i += 1) {
              requests.push(
                getInstruments({
                  path: row.original.path,
                  layer,
                  skip: MAX_REQUEST_LIMIT * i,
                  entity,
                }),
              );
            }

            const results = await Promise.all(requests);

            results.forEach(({ data: responseWithNewInstruments }) => {
              if (responseWithNewInstruments) {
                instruments = instruments.concat(
                  responseWithNewInstruments.instruments,
                );
              }
            });
          }

          dispatch(insertInstruments({ path: row.original.path, instruments }));
          dispatch(expandedRowsSet([expanded, row.id, true]));
          dispatch(setLoading({ key: 'instruments', value: false }));

          row.toggleRowExpanded();
        }
      }
    },
    [getInstruments, layer, dispatch, entity],
  );
};
