import { styled } from 'react-ui-kit-exante';

export const StyledFormArrayHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  h3: {
    fontWeight: 400,
    fontSize: '18px',
  },
  span: {
    color: theme?.color.typo?.action,
  },
  svg: {
    color: theme?.color.typo?.action,
  },
}));

export const StyledFormArrayRow = styled('div')(() => ({
  display: 'flex',
  gap: '16px',

  '& + &': {
    marginTop: '24px',
  },
}));
