import { isUndefined, isEmpty, isEqual, get, has } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Notification, Skeleton } from 'react-ui-kit-exante';

import {
  useLazySearchInstrumentsQuery,
  useAddOvernightsGroupMutation,
  useDeleteOvernightsGroupMutation,
  useSaveAccountOvernightsMutation,
  useSaveDefaultOvernightsMutation,
  useSaveGroupOvernightsMutation,
  useGetOvernightsTreeQuery,
  useGetOvernightsGroupQuery,
  useUpdateRegularPaymentsSettingsMutation,
} from '~/api';
import { usePrevious, useTableVirtualized } from '~/hooks';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { Switch } from '~/shared/components/Switch';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import {
  filtersReset,
  filtersSetGroup,
  filtersSetLayer,
  resetTable,
  selectChangedOvernights,
  selectDictRegularPaymentSettings,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
  selectFiltersQueryParams,
  selectFiltersRelatedGroup,
  selectOvernightsTree,
  selectSearchIsActive,
} from '~/store/overnights/overnightsSlice';
import { FilterLayers } from '~/types/overnights';

import { TableComponent } from './components';
import { Filters } from './components/Filters';
import { getNodesForReload } from './helpers';
import { useOvernightsTree, useReloadInstruments } from './hooks';

const OVERNIGHTS_ROW_LIMIT = 30000;

export const Overnights = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const tree = useSelector(selectOvernightsTree);

  const { fetchOvernightsTree, isLoading: isLoadingTree } = useOvernightsTree();
  const [searchInstruments, searchState] = useLazySearchInstrumentsQuery();
  const [deleteOvernightsGroup, { isLoading: loadingDeleteGroup }] =
    useDeleteOvernightsGroupMutation();
  const [addOvernightsGroup, { isLoading: loadingAddGroup }] =
    useAddOvernightsGroupMutation();

  const searchIsActive = useSelector(selectSearchIsActive);

  const isLoadingTable =
    isLoadingTree || searchState.isLoading || searchState.isFetching;
  const dispatch = useDispatch();

  const prevFiltersQueryParams = usePrevious(filtersQueryParams);

  const filterGroup = useSelector(selectFiltersGroup);

  const changedOvernights = useSelector(selectChangedOvernights);
  const filtersLayer = useSelector(selectFiltersLayer);

  const filterRelatedGroup = useSelector(selectFiltersRelatedGroup);
  const filterAccount = useSelector(selectFiltersAccount);
  const { isLoading: loadingGroups, isFetching: fetchingGroups } =
    useGetOvernightsGroupQuery();

  const regularPaymentSettings = useSelector(selectDictRegularPaymentSettings);
  const { refetch: refetchOvernightsTree } =
    useGetOvernightsTreeQuery(filtersQueryParams);
  const reloadInstruments = useReloadInstruments();

  const [saveDefaultOvernights] = useSaveDefaultOvernightsMutation();
  const [saveGroupOvernights] = useSaveGroupOvernightsMutation();
  const [saveAccountOvernights] = useSaveAccountOvernightsMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { virtualized, updateTableSizes } = useTableVirtualized(
    tableRef.current,
  );
  const [updateRegularPaymentsSettings] =
    useUpdateRegularPaymentsSettingsMutation();

  const prevTree = usePrevious(tree);

  useEffect(() => {
    if (
      prevTree.length !== tree.length ||
      (tree.length !== 0 && virtualized.height === 0)
    ) {
      updateTableSizes();
    }
  }, [prevTree, tree, updateTableSizes, virtualized.height]);

  useEffect(() => {
    fetchOvernightsTree();
  }, [fetchOvernightsTree]);

  const onChangeLayerHandler = useCallback(
    (value: string, _tabIndex: number | null, isMount = false) => {
      dispatch(filtersReset({}));
      dispatch(filtersSetLayer(value));
      dispatch(resetTable({}));

      setSearchParams((params) => {
        if (!isMount) {
          params.delete('account');
        }

        if (!value) {
          params.delete('layer');
        } else {
          params.set('layer', value);
        }

        return params;
      });

      fetchOvernightsTree();

      /**
       * Need to wait for elements to appear in dom
       */
      setTimeout(() => {
        updateTableSizes();
      }, 0);
    },
    [dispatch, fetchOvernightsTree, updateTableSizes, setSearchParams],
  );

  useEffect(() => {
    const initialLayer = searchParams.get('layer');

    if (initialLayer) {
      onChangeLayerHandler(initialLayer, null, true);
    }
  }, []);

  const LAYERS_OPTIONS = Object.values(FilterLayers);

  const accountsGroupWasChanged =
    filterGroup !== filterRelatedGroup &&
    filtersLayer === FilterLayers.Accounts;

  const saveNewGroupForAccount = useCallback(async () => {
    if (
      !accountsGroupWasChanged ||
      !filterAccount ||
      isUndefined(filterGroup)
    ) {
      return;
    }

    const response = await updateRegularPaymentsSettings({
      accountId: filterAccount,
      regularPaymentsSettings: {
        ...regularPaymentSettings,
        overnightsSetId: String(filterGroup) === 'default' ? null : filterGroup,
      },
    });

    if ('error' in response) {
      Notification.error({
        title: 'Overnights set for account saving failed',
      });
    } else {
      Notification.success({
        title: 'Overnights set for account successfully changed',
      });
    }
  }, [
    accountsGroupWasChanged,
    filterAccount,
    filterGroup,
    regularPaymentSettings,
    updateRegularPaymentsSettings,
  ]);

  const saveChangedOvernights = useCallback(async () => {
    if (
      isEmpty(changedOvernights.nodes) &&
      isEmpty(changedOvernights.instruments)
    ) {
      return;
    }

    const isDefault = filtersLayer === FilterLayers.Default;
    const isAccount = filtersLayer === FilterLayers.Accounts && filterAccount;
    const isGroup = filtersLayer === FilterLayers.Groups && filterGroup;

    let result: Record<string, unknown> = { data: false, error: false };
    let nodesForReload: string[] = [];

    if (isDefault) {
      result = await saveDefaultOvernights(changedOvernights);
    }

    if (isGroup) {
      result = await saveGroupOvernights({
        changedOvernights,
        group: filterGroup,
      });
    }

    if (isAccount) {
      result = await saveAccountOvernights({
        changedOvernights,
        account: filterAccount,
      });
    }

    if (isAccount || isGroup) {
      nodesForReload = getNodesForReload(changedOvernights);

      if (nodesForReload.length) {
        await reloadInstruments(nodesForReload, filtersQueryParams);
      }
    }

    if ('data' in result) {
      Notification.success({
        title: 'Overnights saved successfully',
      });
    }
  }, [
    changedOvernights,
    filterAccount,
    filterGroup,
    filtersLayer,
    filtersQueryParams,
    reloadInstruments,
    saveAccountOvernights,
    saveDefaultOvernights,
    saveGroupOvernights,
  ]);

  const handleSaveData = useCallback(async () => {
    await saveNewGroupForAccount();
    await saveChangedOvernights();
  }, [saveChangedOvernights, saveNewGroupForAccount]);

  const handleOnRefresh = async () => {
    await refetchOvernightsTree();
    dispatch(resetTable({}));
  };

  const getIsActiveSearch = () => {
    if (
      get(prevFiltersQueryParams, 'layer') !== get(filtersQueryParams, 'layer')
    ) {
      return false;
    }

    return (
      searchIsActive && !isEqual(filtersQueryParams, prevFiltersQueryParams)
    );
  };

  const handleResetTable = () => dispatch(resetTable({}));

  const handleSearchSubmit = (value: string) => {
    searchInstruments({
      search: value,
      limit: OVERNIGHTS_ROW_LIMIT,
      skip: 0,
      queryParams: filtersQueryParams,
    });
  };

  const handleAddGroup = async (groupName: string) => {
    const res = await addOvernightsGroup({
      name: groupName,
    });

    if (has(res, 'data')) {
      Notification.success({
        title: 'Group was successfully added',
      });

      const newGroupId = get(res, 'data.id');
      dispatch(
        filtersSetGroup({
          value: newGroupId,
          shouldResetDownloadedPaths: true,
        }),
      );
    }
  };

  const handleDeleteGroup = async () => {
    if (filterGroup) {
      const res = await deleteOvernightsGroup({ id: filterGroup });

      if (!has(res, 'error')) {
        Notification.success({
          title: 'Group was successfully deleted',
        });
        dispatch(
          filtersSetGroup({
            value: null,
            shouldResetDownloadedPaths: true,
          }),
        );

        dispatch(resetTable({}));
      }
    }
  };

  const groupsPending = loadingGroups || fetchingGroups;

  return (
    <LayersLayout
      title="Overnights"
      tabs={LAYERS_OPTIONS}
      initialTab={searchParams.get('layer') || null}
      pageControls={
        filtersLayer !== 'Default' ? (
          <Switch condition={[groupsPending, !groupsPending]}>
            <Skeleton width={280} height={76} />
            <Filters
              fetchOvernightsTree={fetchOvernightsTree}
              disabled={loadingAddGroup || loadingDeleteGroup}
            />
          </Switch>
        ) : null
      }
      searchParams={{
        getIsActiveSearch,
        onReset: handleResetTable,
        onSearch: handleSearchSubmit,
      }}
      table={
        <WrapperLoader isLoading={isLoadingTable} ref={tableRef}>
          <TableComponent virtualized={virtualized} />
        </WrapperLoader>
      }
      onChangeLayer={onChangeLayerHandler}
      onDeleteGroup={handleDeleteGroup}
      onSave={handleSaveData}
      onAddGroup={handleAddGroup}
      onRefresh={handleOnRefresh}
      loading={isLoadingTable}
    />
  );
};
