import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ApplicationContainer } from '~/containers/ApplicationContainer';
import { migrate } from '~/shared/utils/migration';
import { createStore } from '~/store';
import { ComposedThemeProvider } from '~/theme/ComposedThemeProvider';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);
  const store = createStore([]);

  migrate();

  window.WEB_BO_UI = {
    ...(window.WEB_BO_UI || {}),
    root,
  };

  root.render(
    <Provider store={store}>
      <ComposedThemeProvider>
        <ApplicationContainer />
      </ComposedThemeProvider>
    </Provider>,
  );
};
