import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Table } from 'react-ui-kit-exante';

import { getTableId } from '~/shared/utils';
import { IUserAccessRights } from '~/types/users';

import { IFormData } from '../types';

import { BackofficePermissions, UserAccessRightsControls } from './components';
import { ACCESS_RIGHTS_TABLE } from './constants';
import { useToggleAccessRights } from './useToggleAccessRight';

interface IBackofficePermissionsContainerProps {
  userAccessRightsForm: IUserAccessRights[];
}

export const BackofficePermissionsContainer = ({
  userAccessRightsForm,
}: IBackofficePermissionsContainerProps) => {
  const { getValues, setValue, watch } = useFormContext<IFormData>();

  useToggleAccessRights(watch, setValue);

  const setAllValuesTo = useCallback(
    (defaultValue: boolean) => {
      const accessRights = getValues('accessRights');

      Object.keys(accessRights).forEach((operation) => {
        if (!accessRights[operation].actions.disabledFields?.read) {
          setValue(`accessRights.${operation}.values.read`, defaultValue, {
            shouldDirty: true,
          });
        }
        if (!accessRights[operation].actions.disabledFields?.write) {
          setValue(`accessRights.${operation}.values.write`, defaultValue, {
            shouldDirty: true,
          });
        }
      });
    },
    [getValues, setValue],
  );

  const selectAllValues = useCallback(() => {
    setAllValuesTo(true);
  }, [setAllValuesTo]);
  const clearAllValues = useCallback(() => {
    setAllValuesTo(false);
  }, [setAllValuesTo]);

  return (
    <BackofficePermissions
      actions={
        <UserAccessRightsControls
          onSetAllValues={selectAllValues}
          onClearAllValues={clearAllValues}
        />
      }
      table={
        <Table
          showScrollbar
          columns={ACCESS_RIGHTS_TABLE}
          tableId={getTableId('AccessRights')}
          data={userAccessRightsForm}
          disableSortBy
          isFlexLayout
          saveViewParamsAfterLeave
        />
      }
    />
  );
};
