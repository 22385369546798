import { IClient, TFlattenCoreClient } from '~/types/clients';

export const expectedFields: (keyof IClient)[] = [
  'withdrawalRestriction',
  'role',
  'owner',
  'name',
  'legalEntity',
  'lastModificationDate',
  'incorporationType',
  'id',
  'tradeCommissionChargeAccountId',
  'exanteType',
  'documentsPending',
  'corporateCategory',
  'closed',
  'clientType',
  'archived',
  'clientInfo',
  'netAssetValue',
  'branding',
];

export const UPDATE_CLIENT_EXPECTED_FIELDS: (keyof TFlattenCoreClient)[] = [
  'id',
  'clientType',
  'closed',
  'corporateCategory',
  'documentsPending',
  'tradeCommissionChargeAccountId',
  'exanteType',
  'incorporationType',
  'legalEntity',
  'name',
  'owner',
  'role',
  'withdrawalRestriction',
  'archived',
];

export const UPDATE_CLIENT_INFO_EXPECTED_FIELDS: (keyof TFlattenCoreClient)[] =
  ['email', 'internalInfo', 'primaryPhone', 'secondaryPhone'];
