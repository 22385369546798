import { useContext } from 'react';

import { Accordion, IAccordionProps } from '../Accordion';
import { accordionContext } from '../context';

type TAccordionWithContextProps = Omit<IAccordionProps, 'isActive'>;

export const AccordionWithContext = ({
  id,
  children,
  ...rest
}: TAccordionWithContextProps) => {
  const { [id]: isActive } = useContext(accordionContext);

  return (
    <Accordion {...rest} id={id} isActive={isActive}>
      {children}
    </Accordion>
  );
};
