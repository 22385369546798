import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  AutocompleteAsync,
  TAutocompleteAsyncFetchData,
} from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { IOption } from '~/types/form';

type TValue = number | string | IOption;

type TFormSelectAsyncContainerProps = {
  autoSelect?: boolean;
  disableCloseOnSelect?: boolean;
  disableWarning?: boolean;
  disabled?: boolean;
  fetchData: TAutocompleteAsyncFetchData;
  freeSolo?: boolean;
  fullWidth?: boolean;
  label?: string;
  name: string;
  onChange?: (values: TValue[]) => void;
};

const AUTOCOMPLETE_CHANGE_REASON = {
  clear: 'clear',
  removeOption: 'removeOption',
};

export const FormMultiSelectAsyncContainer = ({
  autoSelect = false,
  disableCloseOnSelect = true,
  disableWarning,
  disabled = false,
  fetchData,
  freeSolo = false,
  fullWidth = true,
  label = '',
  name,
  onChange: onChangeHandler,
}: TFormSelectAsyncContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name,
  });

  const transformToValues = useCallback((newValues: TValue[]) => {
    const result = newValues?.map((newValue) => {
      if (typeof newValue === 'string' || typeof newValue === 'number') {
        return newValue;
      }

      return newValue?.value;
    });

    return [...new Set(result)];
  }, []);

  const multiSelectChangeHandler = useCallback(
    (event: SyntheticEvent, newValues: TValue[], reason: string) => {
      let currentValues = [...(value || []), ...newValues];

      if (
        reason === AUTOCOMPLETE_CHANGE_REASON.clear ||
        reason === AUTOCOMPLETE_CHANGE_REASON.removeOption
      ) {
        currentValues = newValues;
      }

      onChange(transformToValues(currentValues));

      if (onChangeHandler) {
        onChangeHandler(transformToValues(currentValues));
      }

      event.preventDefault();
    },
    [onChange, onChangeHandler, transformToValues, value],
  );

  const controlProps = useMemo(
    () => ({ error: Boolean(error), warning: isDirty }),
    [error, isDirty],
  );

  return (
    <AutocompleteAsync
      autoSelect={autoSelect}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      error={!!error}
      fetchData={fetchData}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      isMultiple
      message={error?.message}
      onChange={multiSelectChangeHandler}
      options={EMPTY_ARRAY}
      placeholder={label}
      value={value}
      warning={!disableWarning && isDirty}
      controlProps={controlProps}
    />
  );
};
