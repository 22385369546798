import { ReactNode } from 'react';
import { Panel } from 'react-ui-kit-exante';

import { NoDataWrapper } from '~/shared/components';

import { IDataAvailabilityState } from '../types';

interface IMirroringRuleFormTemplateProps {
  dataAvailabilityState: IDataAvailabilityState;
  info: ReactNode;
  header: ReactNode;
}

export const MirroringRuleFormTemplate = ({
  dataAvailabilityState,
  info,
  header,
}: IMirroringRuleFormTemplateProps) => {
  return (
    <>
      {header}

      <Panel>
        <NoDataWrapper hasData={dataAvailabilityState.hasMirroringRuleInfoData}>
          {info}
        </NoDataWrapper>
      </Panel>
    </>
  );
};
