import {
  Fragment,
  MouseEvent,
  TouchEvent,
  createElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ClickAwayListener, IColumn, IRowExpand } from 'react-ui-kit-exante';

import { useCurrency } from '~/hooks';
import { ITableViewRules, ICommissionTree } from '~/types/commissions';

import { checkPossibilityChangeData } from '../../../../helpers';
import { RulesForm } from '../RulesForm';

import { StyledText, StyledTooltip } from './RuleCell.styled';
import { convertRulesToString } from './helpers';

interface IValueCell {
  column: IColumn<ICommissionTree>;
  row: IRowExpand<ICommissionTree>;
  value: ITableViewRules[];
}

export const RuleCell = ({ row, value: rules, column }: IValueCell) => {
  const currency = useCurrency();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const handleTooltipClose = useCallback(() => {
    setTooltipIsOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    const canOpenTooltip = checkPossibilityChangeData(column, row);

    if (canOpenTooltip) {
      setTooltipIsOpen(true);
    }
  }, [column, row]);

  const handleAwayTooltipClose = useCallback(
    (event: unknown) => {
      const target = (
        event as MouseEvent<HTMLElement> | TouchEvent<HTMLElement>
      ).target as HTMLElement;

      if (
        tooltipIsOpen &&
        // check on click by dropdown in select
        !target.closest('.MuiPopover-root') &&
        // check on click by select
        target.tagName !== 'BODY'
      ) {
        setTooltipIsOpen(false);
      }
    },
    [tooltipIsOpen],
  );

  const stringifiedValue = useMemo(
    () => convertRulesToString(rules, currency),
    [currency, rules],
  );

  return (
    <>
      <StyledText title={stringifiedValue} onClick={handleTooltipOpen}>
        {stringifiedValue}
      </StyledText>

      {tooltipIsOpen ? (
        <ClickAwayListener onClickAway={handleAwayTooltipClose}>
          {/* this div is required for ClickAwayListener */}
          <div>
            <StyledTooltip
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={tooltipIsOpen}
              title={
                <RulesForm
                  column={column}
                  handleTooltipClose={handleTooltipClose}
                  row={row}
                  rules={rules}
                />
              }
            >
              {/* I can not wrap StyledText by StyledTooltip because text-overflow: ellipsis will not work  */}
              {/* But Tooltip requires children, so createElement is just a stub  */}
              {createElement(Fragment, null)}
            </StyledTooltip>
          </div>
        </ClickAwayListener>
      ) : null}
    </>
  );
};
