import { getLegalEntityEndpoint } from '../endpoints';
import { transformLegalEntityToFormData } from '../helpers';

import { legalEntitiesApi } from './legalEntitiesApi';

export const { useLazyGetLegalEntityQuery } = legalEntitiesApi.injectEndpoints({
  endpoints: (builder) => ({
    getLegalEntity: builder.query({
      query: (legalEntityName: string) => ({
        url: getLegalEntityEndpoint(legalEntityName),
      }),
      transformResponse: transformLegalEntityToFormData,
    }),
  }),
});
