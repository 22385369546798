import { styled, Table, Tooltip } from 'react-ui-kit-exante';

export const StyledTooltip = styled(Tooltip)(() => ({
  // reset z-index to avoid conflicts with dropdowns in select
  zIndex: 1300,
}));

export const TableContainer = styled(Table)(() => ({
  marginBottom: '24px',
}));
