import { concat } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { Loader, Panel } from 'react-ui-kit-exante';

import { useGetMarginStructureQuery } from '~/api';
import { DEFAULT_POOLING_INTERVAL_DATA, EMPTY_ARRAY } from '~/constants';
import { useAccordion, useCurrency, useLogHandleTime } from '~/hooks';
import { IAccountTabComponentProps } from '~/pages/AccountPage/TabManagement/types';
import { accountPageContext } from '~/pages/AccountPage/context';
import { useRefreshActiveTab } from '~/pages/AccountPage/hooks';
import { AccordionWithContext, AccordionProvider } from '~/shared/components';
import { formatDateToYYYYMMDD } from '~/shared/utils';

import { BlockedFunds } from './BlockedFunds';
import { Margin } from './Margin';
import { OrderMargin } from './OrderMargin';
import { TMarginTableData } from './types';

const DEFAULT_DATA = {
  margin: EMPTY_ARRAY,
  scanningRisk: EMPTY_ARRAY,
  interspreadMargin: EMPTY_ARRAY,
  optionMinimum: EMPTY_ARRAY,
  blockedFunds: EMPTY_ARRAY,
  orderMargin: EMPTY_ARRAY,
};

export const MarginStructureContainer = ({
  accountId,
}: IAccountTabComponentProps) => {
  const currency = useCurrency();
  const {
    accountSummary: {
      filters: { date },
    },
  } = useContext(accountPageContext);
  const { isRefreshing, setRefreshFn } = useRefreshActiveTab();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'account-margin-entry-screen',
  );

  setStartHandleTime();

  const {
    data = DEFAULT_DATA,
    isLoading,
    isFetching,
    refetch: refetchMarginStructure,
  } = useGetMarginStructureQuery(
    {
      accountId,
      currency,
      date: date ? formatDateToYYYYMMDD(date) : null,
    },
    {
      pollingInterval: DEFAULT_POOLING_INTERVAL_DATA,
    },
  );

  useEffect(() => {
    setRefreshFn('margin', {
      refetch: refetchMarginStructure,
      isLoading: isFetching,
    });
  }, [refetchMarginStructure, isFetching]);

  const {
    margin,
    scanningRisk,
    interspreadMargin,
    optionMinimum,
    blockedFunds,
    orderMargin,
  } = data;

  const mergedMarginData = useMemo(
    () =>
      concat<TMarginTableData>(
        margin,
        scanningRisk,
        interspreadMargin,
        optionMinimum,
      ),
    [interspreadMargin, margin, optionMinimum, scanningRisk],
  );
  const hasMarginData = mergedMarginData.length > 0;
  const hasBlockedFundsData = blockedFunds.length > 0;
  const hasOrderMarginData = orderMargin.length > 0;

  const accordionItems = useMemo(
    () =>
      Object.keys(data).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
    [data],
  );
  const { accordion, handleAccordionChange } = useAccordion({
    items: accordionItems,
    lsKey: 'marginStructure-accordion',
  });

  useEffect(() => {
    if (hasMarginData || hasBlockedFundsData || hasOrderMarginData) {
      logHandleTime();
    }
  }, [hasBlockedFundsData, hasMarginData, hasOrderMarginData, logHandleTime]);

  if (
    !isLoading &&
    !hasMarginData &&
    !hasBlockedFundsData &&
    !hasOrderMarginData
  ) {
    return <Panel>No data</Panel>;
  }

  if (isRefreshing) {
    return (
      <Panel>
        <Loader />
      </Panel>
    );
  }

  return (
    <AccordionProvider value={accordion}>
      {(isLoading || hasMarginData) && (
        <AccordionWithContext
          id="margin"
          title="Margin"
          onChange={handleAccordionChange}
        >
          <Margin data={mergedMarginData} isLoading={isLoading} />
        </AccordionWithContext>
      )}
      {(isLoading || hasBlockedFundsData) && (
        <AccordionWithContext
          id="blockedFunds"
          title="Funds on hold"
          onChange={handleAccordionChange}
        >
          <BlockedFunds data={blockedFunds} isLoading={isLoading} />
        </AccordionWithContext>
      )}
      {(isLoading || hasOrderMarginData) && (
        <AccordionWithContext
          id="orderMargin"
          title="Order margin"
          onChange={handleAccordionChange}
        >
          <OrderMargin data={orderMargin} isLoading={isLoading} />
        </AccordionWithContext>
      )}
    </AccordionProvider>
  );
};
