import { get } from 'lodash';

import { EMPTY_OBJECT } from '~/constants';

import {
  TRiskArrayBySymbolID,
  TRiskArrayFullNode,
  TRiskArrayNode,
  TRiskArrayTreeNode,
  TStrike,
} from './types';

const hasStrikes = (
  response: Partial<TRiskArrayTreeNode>,
): response is { strikes: TStrike[] } => {
  const strikesList = get(response, 'strikes', null);

  return Array.isArray(strikesList) && Boolean(strikesList.length);
};

type TCreateFullRiskArrayNodeFromStrike = (params: {
  strikes: TStrike[];
  fullData: TRiskArrayBySymbolID;
  initialMargin: number;
  path: string;
  underlying: string;
  optionRight: string;
}) => Record<string, unknown>[];

const createFullRiskArrayNodeFromStrike: TCreateFullRiskArrayNodeFromStrike = ({
  strikes,
  fullData,
  initialMargin,
  path,
  underlying,
  optionRight,
}) => {
  strikes.sort(
    ({ strikePrice: a }, { strikePrice: b }) => Number(a) - Number(b),
  );

  return strikes.map((strike) => {
    const fullRiskArrayNode: Record<string, unknown> = { ...strike };
    const fullStrikeData = fullData[strike.symbolId];

    const displayName = `${optionRight}, ${strike.strikePrice} (${strike.symbolId})`;
    const name = `${optionRight}, ${strike.strikePrice} (${strike.symbolId})`;

    if (!fullStrikeData) {
      return {
        displayName,
        name,
        id: strike.symbolId,
        underlying,
        initialMargin,
        type: 'leaf',
      };
    }

    const { isAutoCalculated, usePortfolioMargin, delta } = fullStrikeData;

    fullRiskArrayNode.isAutoCalculated = isAutoCalculated;
    fullRiskArrayNode.usePortfolioMargin = usePortfolioMargin;
    fullRiskArrayNode.delta = delta;
    fullRiskArrayNode.path = path;

    if (Array.isArray(fullStrikeData?.riskarray)) {
      fullStrikeData.riskarray.forEach((riskArrayItem, index) => {
        fullRiskArrayNode[`risk-array-${index}`] = riskArrayItem;
      });
    }

    fullRiskArrayNode.displayName = displayName;
    fullRiskArrayNode.name = name;
    fullRiskArrayNode.id = strike.symbolId;
    fullRiskArrayNode.underlying = underlying;
    fullRiskArrayNode.initialMargin = initialMargin;
    fullRiskArrayNode.type = 'leaf';

    return fullRiskArrayNode;
  });
};

export const createFullRiskArrayNode = (
  arr: TRiskArrayTreeNode[],
  fullData: TRiskArrayBySymbolID,
): TRiskArrayFullNode[] => {
  const result = arr.map((item) => {
    if (hasStrikes(item)) {
      return {
        ...item,
        strikes: createFullRiskArrayNodeFromStrike({
          strikes: item.strikes,
          fullData,
          initialMargin: item.initialMargin,
          path: item.path,
          underlying: item.underlying,
          optionRight: item.optionRight ?? '',
        }),
      };
    }

    const fullRiskArrayNode: Record<string, unknown> = { ...item };
    const data = {
      ...(item.id ? fullData[item.id] : EMPTY_OBJECT),
    } as TRiskArrayNode;

    const { isAutoCalculated, usePortfolioMargin, delta } = data;

    fullRiskArrayNode.isAutoCalculated = isAutoCalculated;
    fullRiskArrayNode.usePortfolioMargin = usePortfolioMargin;
    fullRiskArrayNode.delta = delta;

    if (data && data.riskarray && Array.isArray(data.riskarray)) {
      data.riskarray.forEach((riskArrayItem, index) => {
        fullRiskArrayNode[`risk-array-${index}`] = riskArrayItem;
      });
    }

    return fullRiskArrayNode;
  });

  return result as TRiskArrayFullNode[];
};
