import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { symbolPermissionsService } from '~/resources';
import { ISymbolTreeStructure } from '~/types/symbolPermissions';

import { ActionTypes, SymbolPermissionsContext } from '../constants';
import {
  IStateSymbolPermissionsQueryParams,
  TMatchIdAndPositionInTable,
  TSetTableData,
} from '../types';

export function useSymbolsTree(
  updateMatchIdAndPositionInSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void,
  setTableData: TSetTableData,
  updateMatchIdAndPositionInNonSearchTable: (
    newData: TMatchIdAndPositionInTable,
  ) => void,
  preCall: () => void,
) {
  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const {
    filters: { queryParams, withExpired },
    permissions: { changed: changedPermissions },
    permissionsByPath,
  } = state;
  const previousQueryParams = useRef<IStateSymbolPermissionsQueryParams | null>(
    null,
  );

  const symbolsTree = useRef<ISymbolTreeStructure[]>([]);
  const getSymbolsTree = useCallback(async () => {
    if (!queryParams) {
      return;
    }

    preCall();

    previousQueryParams.current = queryParams;

    const data = await symbolPermissionsService.resolveSymbolsTree(
      {
        ...queryParams,
        withExpired,
      },
      permissionsByPath,
      changedPermissions,
    );

    if (data) {
      dispatch({
        type: ActionTypes[
          Object.keys(permissionsByPath).length
            ? 'PERMISSIONS_BY_PATHS_UPDATE'
            : 'PERMISSIONS_BY_PATHS_SET'
        ],
        payload: data.newPermissionsByPath,
      });

      updateMatchIdAndPositionInNonSearchTable(
        data.matchIdAndPositionInNonSearchTable,
      );
      updateMatchIdAndPositionInSearchTable(
        data.matchIdAndPositionInNonSearchTable,
      );

      symbolsTree.current = cloneDeep(data.tree);
      setTableData(data.tree);
    }
  }, [
    changedPermissions,
    dispatch,
    permissionsByPath,
    preCall,
    queryParams,
    setTableData,
    updateMatchIdAndPositionInNonSearchTable,
    updateMatchIdAndPositionInSearchTable,
    withExpired,
  ]);

  const useDataOptions = useMemo(
    () => ({
      onFetch: async () => {
        if (!queryParams || isEqual(queryParams, previousQueryParams.current)) {
          return;
        }

        await getSymbolsTree();
      },
    }),
    [queryParams, getSymbolsTree],
  );

  const { fetchData: fetchSymbolsTree, isLoading } = useData(useDataOptions);

  useEffect(() => {
    fetchSymbolsTree();
  }, [fetchSymbolsTree]);

  return { isLoading, symbolsTree, refetch: getSymbolsTree };
}
