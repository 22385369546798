import { Panel } from 'react-ui-kit-exante';

import { FormSelectContainer } from '~/containers/form';
import { usePickUserPermissions } from '~/hooks';
import { useAccountStatuses } from '~/hooks/useAccountStatuses';

import AtpPermissionsStyles from './AtpPermissions.module.css';

export const AtpPermissions = () => {
  const { accountStatusesOptions } = useAccountStatuses();
  const userPermissions = usePickUserPermissions([
    'User account status',
    'User default account access',
  ]);

  const hasWriteAccess =
    userPermissions['User default account access'].write &&
    userPermissions['User account status'].write;

  if (!userPermissions['User default account access'].read) {
    return null;
  }

  return (
    <Panel title="Default account access">
      <FormSelectContainer
        className={AtpPermissionsStyles.Select}
        disabled={!hasWriteAccess}
        fullWidth={false}
        label="Default account access"
        name="defaultAccountAccess"
        options={accountStatusesOptions}
      />
    </Panel>
  );
};
