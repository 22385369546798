import { ChangeEvent, useCallback } from 'react';
import { RadioGroup } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { useAutocomplete } from '~/hooks';
import {
  MirroringRuleOptions,
  radioOptions,
  transferMultiplierOptions,
} from '~/pages/MirroringRulePage/constants';
import { IOption } from '~/types/form';

import {
  FormDateTimePickerContainer,
  FormInputContainer,
  FormMultiSelectAsyncContainer,
  FormMultiSelectContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
} from '../../form';

import InfoStyles from './Info.module.css';

interface IInfoProps {
  options: Record<string, Array<IOption>>;
  activeOption: MirroringRuleOptions;
  handleActiveOptionChange: (value: MirroringRuleOptions) => void;
}

export const Info = ({
  options,
  activeOption,
  handleActiveOptionChange,
}: IInfoProps) => {
  const {
    legalEntitiesOptions,
    legalEntitiesEmptyOptions,
    operationTypeOptions,
    categoriesTypeOptions,
    accountPurposesOptions,
    accountTypesOptions,
  } = options;

  const handleRadioGroupChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      handleActiveOptionChange(value as MirroringRuleOptions);
    },
    [handleActiveOptionChange],
  );

  const getAccountsAutocompleteFn = useAutocomplete('accounts');

  return (
    <div className={InfoStyles.Wrapper}>
      <FormInputContainer readonly label="ID" name="mirroringRule.id" />
      <FormInputContainer label="Name" name="mirroringRule.name" />
      <FormSelectAsyncContainer
        label="Target account"
        name="mirroringRule.targetAccount"
        fetchData={getAccountsAutocompleteFn()}
      />
      <FormMultiSelectContainer
        label="Account types"
        name="mirroringRule.accountTypes"
        options={accountTypesOptions}
      />
      <FormMultiSelectContainer
        label="Account purposes"
        name="mirroringRule.accountPurposes"
        options={accountPurposesOptions}
      />
      <FormMultiSelectContainer
        label="Source legal entities"
        name="mirroringRule.sourceLegalEntities"
        options={legalEntitiesOptions}
      />
      <FormMultiSelectAsyncContainer
        label="Source accounts"
        name="mirroringRule.sourceAccounts"
        fetchData={getAccountsAutocompleteFn()}
        freeSolo
      />
      <FormMultiSelectAsyncContainer
        label="Black list accounts"
        name="mirroringRule.blackListAccounts"
        fetchData={getAccountsAutocompleteFn()}
        freeSolo
      />
      <FormMultiSelectContainer
        freeSolo
        label="Excluded Accounts"
        name="mirroringRule.excludedAccounts"
        options={EMPTY_ARRAY}
        disabled
      />
      <FormMultiSelectContainer
        freeSolo
        label="Custodians"
        name="mirroringRule.custodians"
        options={EMPTY_ARRAY}
      />
      <RadioGroup
        handleChange={handleRadioGroupChange}
        radioOptions={radioOptions}
        value={activeOption}
      />
      <FormMultiSelectContainer
        label="Categories"
        name="mirroringRule.categories"
        options={categoriesTypeOptions}
        disabled={activeOption !== MirroringRuleOptions.Categories}
      />
      <FormMultiSelectContainer
        label="Transactions"
        name="mirroringRule.operationTypes"
        options={operationTypeOptions}
        disabled={activeOption !== MirroringRuleOptions.Transactions}
      />
      <FormSelectContainer
        label="Internal transfer from LE"
        name="mirroringRule.tags.transfer.from.legalEntity"
        options={legalEntitiesEmptyOptions}
      />
      <FormInputContainer
        label="Internal transfer from environment"
        name="mirroringRule.tags.transfer.from.environment"
      />
      <FormSelectContainer
        label="Internal transfer to LE"
        name="mirroringRule.tags.transfer.to.legalEntity"
        options={legalEntitiesEmptyOptions}
      />
      <FormInputContainer
        label="Internal transfer to environment"
        name="mirroringRule.tags.transfer.to.environment"
      />
      <FormSelectAsyncContainer
        label="Rebate tag account"
        name="mirroringRule.tags.rebate.from.account"
        fetchData={getAccountsAutocompleteFn()}
      />
      <FormSelectContainer
        label="Rebate tag LE"
        name="mirroringRule.tags.rebate.from.legalEntity"
        options={legalEntitiesEmptyOptions}
      />
      <FormSelectContainer
        label="Transfer multiplier"
        name="mirroringRule.transferMultiplier"
        options={transferMultiplierOptions}
      />
      <FormDateTimePickerContainer
        label="Start date"
        name="mirroringRule.startDateTime"
      />
      <FormDateTimePickerContainer
        label="End date"
        name="mirroringRule.endDateTime"
      />
    </div>
  );
};
