import { ISortBy } from 'react-ui-kit-exante';

import {
  prepareNumberArrayForRequest,
  prepareSortingForApi,
} from '~/shared/utils';
import { parseTimeParams } from '~/shared/utils/dates';
import { TMapper } from '~/types/api';
import { TNumberRangeValues } from '~/types/filters';

const START_END_DATE_TIMESTAMP = ['startTimestamp', 'endTimestamp'];
const START_END_DATE_QUOTE_TIMESTAMP = [
  'startQuoteTimestamp',
  'endQuoteTimestamp',
];
const START_END_DATE_EXPIRATION_TIME = [
  'startExpirationTime',
  'endExpirationTime',
];

export const GLOBAL_SUMMARY_PARAMS_MAPPER: TMapper = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  timestamp: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_TIMESTAMP }),
  },
  quoteTimestamp: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_QUOTE_TIMESTAMP }),
  },
  expirationTime: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_EXPIRATION_TIME }),
  },
  quantity: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'quantity'),
  },
  leverageRate: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'leverageRate'),
  },
  price: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'price'),
  },
  averagePrice: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'averagePrice'),
  },
  value: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'value'),
  },
  pnl: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'pnl'),
  },
  sharePercent: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'sharePercent'),
  },
  convertedValue: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'convertedValue'),
  },
  convertedPnl: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'convertedPnl'),
  },
  convertedDailyPnl: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'convertedDailyPnl'),
  },
  dailyPnl: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'dailyPnl'),
  },
  dailyPnlPercentage: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'dailyPnlPercentage'),
  },
  pnlPercentage: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'pnlPercentage'),
  },
  realizedPnl: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'realizedPnl'),
  },
  priceUnit: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'priceUnit'),
  },
  contractMultiplier: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'contractMultiplier'),
  },
};

export const EXPORT_GLOBAL_SUMMARY_PARAMS_MAPPER: TMapper = {
  sorting: {
    parser: ({
      order,
      order_by,
    }: {
      order: 'desc';
      order_by: 'timestamp';
    }) => ({
      order,
      orderBy: order_by,
    }),
  },
  timestamp: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_TIMESTAMP }),
  },
  quoteTimestamp: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_QUOTE_TIMESTAMP }),
  },
  expirationDate: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_EXPIRATION_TIME }),
  },
  expirationTime: {
    parser: (values: (string | null)[]) =>
      parseTimeParams({ values, nameParams: START_END_DATE_EXPIRATION_TIME }),
  },
};
