import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InterestPageLayer } from '../constants';

const isInterestPageLayer = (val: string): val is InterestPageLayer => {
  return Object.values(InterestPageLayer).some((v) => v === val);
};

export const useViewOptions = () => {
  const init = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<null | InterestPageLayer>(null);
  const [account, setAccount] = useState('');

  useEffect(() => {
    if (!init.current) {
      const initialAccount = searchParams.get('account');
      const initialLayer = searchParams.get('layer');

      if (initialLayer && isInterestPageLayer(initialLayer)) {
        setActiveTab(initialLayer);
      } else {
        setActiveTab(InterestPageLayer.Default);
      }

      if (initialAccount) {
        setAccount(initialAccount);
      }

      init.current = false;
    }
  }, [searchParams]);

  const handleSetAccount = useCallback(
    (accountId: string) => {
      setSearchParams((params) => {
        if (!accountId) {
          params.delete('account');
        } else {
          params.set('account', accountId);
        }

        return params;
      });

      setAccount(accountId);
    },
    [setSearchParams],
  );

  const handleSetActiveTab = useCallback(
    (layer: InterestPageLayer) => {
      setSearchParams((params) => {
        params.set('layer', layer);

        return params;
      });

      setActiveTab(layer);
    },
    [setSearchParams],
  );

  return {
    activeTab,
    setActiveTab: handleSetActiveTab,
    account,
    setAccount: handleSetAccount,
  };
};
