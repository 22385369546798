import { ISortBy } from 'react-ui-kit-exante';

import { DEFAULT_PAGINATION_RESPONSE, FROM_TO_DATE_FIELDS } from '~/constants';
import { createEmptyObject, prepareSortingForApi } from '~/shared/utils';
import { prepareDateRangeStringForAPI } from '~/shared/utils/dates';
import { formatDateRange } from '~/shared/utils/formatDateRange';
import { ITrade } from '~/types/trades';

export const expectedFields: (keyof ITrade)[] = [
  'accountId',
  'clientComment',
  'clientType',
  'closedQuantity',
  'commission',
  'commissionCurrency',
  'contractMultiplier',
  'counterpartyAccountId',
  'counterpartyClientId',
  'counterpartyTime',
  'currency',
  'exchangeOrderId',
  'executionCounterparty',
  'feePolicy',
  'gwTime',
  'internalComment',
  'isForcedCloseOut',
  'isManual',
  'legalEntity',
  'orderId',
  'orderPos',
  'pnl',
  'price',
  'quantity',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
  'settlementCounterparty',
  'side',
  'source',
  'symbolId',
  'symbolType',
  'tradeTime',
  'tradeType',
  'tradedVolume',
  'userId',
  'valueDate',
  'who',
];

export const REQUESTED_FIELDS = [
  'userId',
  'gwTime',
  'tradeTime',
  'counterpartyTime',
  'counterpartyAccountId',
  'counterpartyClientId',
  'symbolId',
  'symbolType',
  'accountId',
  'side',
  'price',
  'quantity',
  'commission',
  'commissionCurrency',
  'isForcedCloseOut',
  'orderId',
  'orderPos',
  'exchangeOrderId',
  'currency',
  'contractMultiplier',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
  'settlementCounterparty',
  'executionCounterparty',
  'valueDate',
  'clientComment',
  'internalComment',
  'clientType',
  'tradeType',
  'pnl',
  'isManual',
  'who',
  'closedQuantity',
  'tradedVolume',
  'legalEntity',
  'feePolicy',
  'source',
  'execBroker',
  'chainId',
];

export const TRADES_PARAMS_MAPPER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  fromTo: {
    parser: (values: (string | null)[]) => {
      const formattedDateRange = formatDateRange(values);

      return prepareDateRangeStringForAPI(
        FROM_TO_DATE_FIELDS,
        formattedDateRange,
      );
    },
  },
  symbolId: {
    parser: (values: string[]) => {
      return { symbolId: values };
    },
  },
  userId: {
    parser: (values: string[]) => {
      return { userId: values };
    },
  },
  page: {
    parser: createEmptyObject,
  },
};

export const EXPORT_TRADES_PARAMS_TRANSFORMER = {
  sorting: {
    parser: ({
      order,
      order_by,
    }: {
      order: 'desc';
      order_by: 'timestamp';
    }) => ({
      order,
      orderBy: order_by,
    }),
  },
  fromTo: {
    parser: (values: (string | null)[]) =>
      prepareDateRangeStringForAPI(FROM_TO_DATE_FIELDS, values),
  },
  symbolId: {
    parser: (values: string[]) => {
      return { symbolId: values };
    },
  },
  userId: {
    parser: (values: string[]) => {
      return { userId: values };
    },
  },
  page: {
    parser: createEmptyObject,
  },
};

export const ignoredBrokerFields = [
  'brokerAccountId',
  'brokerClientId',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
];

export const defaultTradesResponse = {
  trades: [],
  pagination: DEFAULT_PAGINATION_RESPONSE,
};
