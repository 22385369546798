export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
export const DEFAULT_EMPTY_PLACEHOLDER = '—';
export const DEFAULT_POOLING_INTERVAL_DATA = 60000; // 1 min
export const DEFAULT_TABLE_COLUMN_WIDTH = 150;
export const EMPTY_ARRAY: never[] = [];
export const EMPTY_FUNCTION = () => undefined;
export const EMPTY_OBJECT = {};
export const EN_DASH = '–';
export const FETCH_DATA_SIZE = 100;
export const MAX_REQUEST_LIMIT = 1000;
export const MIN_SEARCH_LENGTH = 3;
export const NON_BREAKING_SPACE = '\u00A0';
export const NO_DATA_HEIGHT = 'auto';
export const PATH_DELIMITER = '::';
export const REGEX_DOUBLE_COLON = /::(?=[^::]*$)/;
export const REGEX_LAST_SEGMENT_IN_PATH = /::[^:]*$/;
export const REGEX_SPLIT_COMMA_AND_PIPE = /\||,\s+/;
export const SERVICE_NAME = 'bo';

export const MAIN_USER_TOKEN_NAME = 'Main password';
