import { useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Modal, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE } from '~/constants';

import { UsersPermissionHeader, UsersPermissionList } from '../components';
import { CONFIRM_DELETE_TITLE, FORM_ID } from '../constants';
import { getDefaultUserPermission } from '../helpers';
import { IUserFormData } from '../types';

interface IUsersPermissionFormProps {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  isDirty: boolean;
  accountId: string;
}

export const UsersPermissionForm = ({
  onSubmit,
  isDirty,
  accountId,
}: IUsersPermissionFormProps) => {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<IUserFormData>();
  const removingRowIndex = useRef<null | number>(null);
  const confirmDeleteModal = useModal();

  const { fields, append, remove } = useFieldArray<
    IUserFormData,
    'users',
    string
  >({
    control,
    name: 'users',
    keyName: 'userId',
  });

  const handleAdd = () => {
    const defaultUserPermission = getDefaultUserPermission(accountId);

    append(defaultUserPermission);
  };

  const handleRemove = (index: number, isNew = false) => {
    if (isNew) {
      remove(index);
    } else {
      removingRowIndex.current = index;
      confirmDeleteModal.onOpen();
    }
  };

  const handleConfirmDelete = () => {
    if (removingRowIndex.current !== null) {
      remove(removingRowIndex.current);
      confirmDeleteModal.onClose();
    }
  };

  return (
    <>
      <UsersPermissionHeader
        onAdd={handleAdd}
        disabledSave={!isDirty}
        loading={isSubmitting}
      />
      <div style={{ padding: '24px 0' }}>
        <form onSubmit={onSubmit} id={FORM_ID}>
          <UsersPermissionList users={fields} onRemove={handleRemove} />
        </form>
        <Modal
          isOpened={confirmDeleteModal.isOpened}
          onClose={confirmDeleteModal.onClose}
          title={CONFIRM_DELETE_TITLE}
          confirmButton={{
            handleConfirm: handleConfirmDelete,
          }}
          keepMounted={false}
        >
          <div>{CONFIRM_DELETE_MESSAGE}</div>
        </Modal>
      </div>
    </>
  );
};
