import { createContext } from 'react';

import { EMPTY_FUNCTION } from '~/constants';
import { DEFAULT_REFRESH_QUERY_STATE } from '~/constants/refreshQuery';

import { IAccountPageContext } from './types';

export const DEFAULT_DIRTY_TABS_STATE = {
  settings: false,
  cards: false,
};

const defaultValues: IAccountPageContext = {
  accountSummary: {
    filters: {
      date: null,
      setDate: EMPTY_FUNCTION,
      resetDate: EMPTY_FUNCTION,
    },
  },
  dirtyTabs: DEFAULT_DIRTY_TABS_STATE,
  setDirtyTabs: EMPTY_FUNCTION,
  refreshActiveTabQuery: DEFAULT_REFRESH_QUERY_STATE,
  setRefreshFn: EMPTY_FUNCTION,
  activeTabName: '',
};

export const accountPageContext = createContext(defaultValues);
export const { Provider: AccountPageContextProvider } = accountPageContext;
