import { format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Bookmarks,
  ButtonDatePicker,
  CircleArrowForwardIcon,
  Loader,
  Switch,
  Table,
} from 'react-ui-kit-exante';

import {
  useGetAccountPurposeTypesQuery,
  useGetLegalEntityTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { useSyncTableViewParams, useLogHandleTime, useTotalNav } from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { today } from '~/shared/utils/dates';

import {
  DEFAULT_SORTING,
  DISPLAYED_COLUMNS_KEYS_WITH_LE,
  getColumns,
} from './columns';
import { GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID } from './constants';
import { prepareTableTitle } from './helpers';
import { useGlobalSummary } from './hooks';
import { useRefetchGlobalSummary } from './hooks/usePollingTaskStatus';
import {
  LastUpdateText,
  UpdateTableButton,
  UpdateTableLoadingContainer,
  UpdateTableText,
  UpdateTableTextContainer,
} from './styled';

export const GlobalSummaryByAccountsPageComponent = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}: IBookmarkResponseProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'global-summary-list-by-accounts',
  );
  const {
    accountTypes,
    additionalActions: hookAdditionalActions,
    assetTypes,
    currency,
    data,
    date,
    fetchAccounts,
    filteringProps,
    isLoading,
    reconciliationMode,
    refetch,
    removeFilter,
    setDate,
    setFilter,
    setReconciliationMode,
    setSorting,
    ...serverPaginationProps
  } = useGlobalSummary({
    logHandleTime,
    setStartHandleTime,
    tableId: GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID,
  });
  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();

  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );

  const [showTableLoader, setShowTableLoader] = useState(true);

  // TODO use useData
  const handleRefetch = useCallback(async () => {
    try {
      setShowTableLoader(false);
      await refetch();
    } finally {
      setShowTableLoader(true);
    }
  }, [refetch]);

  const {
    start: startPolling,
    lastUpdate,
    loading: refetchLoading,
  } = useRefetchGlobalSummary({
    reconciliationMode,
    refetch: handleRefetch,
    date: date || today,
  });

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        currency,
        legalEntityOptions,
        accountTypes,
        accountPurposesOptions: accountPurposeTypes?.values || [],
        fetchAccounts,
        assetTypes,
      }),
    [
      setFilter,
      removeFilter,
      currency,
      legalEntityOptions,
      accountTypes,
      accountPurposeTypes,
      assetTypes,
      fetchAccounts,
    ],
  );

  const title = prepareTableTitle(date, 'Global Summary by accounts');

  useSyncTableViewParams({
    pageCount: serverPaginationProps.pageCount,
    setPage: serverPaginationProps.setPage,
    tableId: GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID,
  });

  const totalNav = useTotalNav({
    currency,
    label: "Selected filters' Net Asset Value",
    nav: data?.totalNav,
  });

  const handleFiltersSave = useCallback(
    (recMode: boolean, newDate: Date | null) => {
      setReconciliationMode(recMode);
      serverPaginationProps.setPage(0);
      setDate(newDate);
    },
    [serverPaginationProps, setDate, setReconciliationMode],
  );

  const handleReconChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setReconciliationMode(checked);
    },
    [setReconciliationMode],
  );

  const additionalActions = useMemo(
    () => [
      {
        key: 'refresh',
        component: refetchLoading ? (
          <UpdateTableLoadingContainer>
            <Loader />
            <span>Update</span>
          </UpdateTableLoadingContainer>
        ) : (
          <UpdateTableButton onClick={startPolling} type="button">
            <CircleArrowForwardIcon />

            <UpdateTableTextContainer>
              <UpdateTableText>Update Table</UpdateTableText>
              <LastUpdateText>
                Last table update:{' '}
                {lastUpdate
                  ? format(new Date(lastUpdate), 'dd.MM.yyyy HH:mm')
                  : '-'}
              </LastUpdateText>
            </UpdateTableTextContainer>
          </UpdateTableButton>
        ),
      },
      {
        key: 'historicalMode',
        component: (
          <ButtonDatePicker
            maxDate={today}
            showTodayButton
            onChange={setDate}
            selected={date || null}
            todayButtonText="Real time"
            onToday={() => {
              setDate(today);
            }}
          />
        ),
      },
      {
        key: 'reconciliationMode',
        component: (
          <Switch
            id="reconciliation"
            onChange={handleReconChange}
            label="Reconciliation mode"
            checked={reconciliationMode}
            disabled={!date}
            style={{ margin: '0 18px 0 12px' }}
          />
        ),
      },
      ...hookAdditionalActions.filter((action) => action.key === 'exportCSV'),
    ],
    [
      date,
      handleReconChange,
      hookAdditionalActions,
      lastUpdate,
      reconciliationMode,
      refetchLoading,
      setDate,
      startPolling,
    ],
  );

  useEffect(() => {
    handleFiltersSave(reconciliationMode, date);
  }, [date, reconciliationMode]);

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filteringProps.filters)}
        onSaveAsNew={(name) =>
          handleSaveAsNewBookmark(name, filteringProps.filters)
        }
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filteringProps.filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      commonValue={totalNav}
      data={data?.data || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS_WITH_LE}
      filteringProps={filteringProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={showTableLoader && isLoading}
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID}
      title={title}
      isPinnedHeader
    />
  );
};

export const GlobalSummaryByAccountsPage = () => {
  return (
    <WithBookmarks
      component={GlobalSummaryByAccountsPageComponent}
      pageName="GlobalSummaryByAccounts"
      tableId={GLOBAL_SUMMARY_BY_ACCOUNTS_TABLE_ID}
    />
  );
};
