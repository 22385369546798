import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';
import {
  INonTradingCommissionGroupsResponse,
  INonTradingCommissionResponse,
} from '~/types/nonTradingCommissions';

import {
  nonTradingCommissionGroupsEndpoint,
  nonTradingCommissionsEndpoint,
} from './endpoints';

export const nonTradingCommissionsApi = createApi({
  reducerPath: 'nonTradingCommissionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'NonTradingCommissionByGroup',
    'NonTradingCommissionGroups',
    'NonTradingCommissions',
  ],
  endpoints: (builder) => ({
    getNonTradingCommissionGroups: builder.query<
      INonTradingCommissionGroupsResponse[],
      void
    >({
      query: () => ({
        url: nonTradingCommissionGroupsEndpoint,
      }),
      providesTags: ['NonTradingCommissionGroups'],
    }),
    getNonTradingCommissionGroupsById: builder.query<
      INonTradingCommissionGroupsResponse[],
      string | null
    >({
      query: (groupId) => ({
        url: `${nonTradingCommissionGroupsEndpoint}?id=${groupId}`,
      }),
    }),
    getNonTradingCommissions: builder.query<
      INonTradingCommissionResponse[],
      void
    >({
      query: () => ({
        url: nonTradingCommissionsEndpoint,
      }),
      providesTags: ['NonTradingCommissions'],
    }),
    getNonTradingCommissionsByGroup: builder.query<
      INonTradingCommissionResponse[],
      string | null
    >({
      query: (groupId) => ({
        url: `${nonTradingCommissionsEndpoint}?groupid=${groupId}`,
      }),
      providesTags: ['NonTradingCommissionByGroup'],
    }),
    createNonTradingCommission: builder.mutation({
      query: (commissions) => ({
        url: nonTradingCommissionsEndpoint,
        method: 'POST',
        data: commissions,
      }),
    }),
    updateNonTradingCommission: builder.mutation({
      query: ({ commission, id }) => ({
        url: `${nonTradingCommissionsEndpoint}/${id}`,
        method: 'POST',
        data: commission,
      }),
    }),
    deleteNonTradingCommission: builder.mutation({
      query: (id: number) => ({
        url: `${nonTradingCommissionsEndpoint}/${id}`,
        method: 'DELETE',
      }),
    }),
    createNonTradingCommissionGroup: builder.mutation({
      query: (data) => ({
        url: nonTradingCommissionGroupsEndpoint,
        method: 'POST',
        data,
      }),
    }),
    deleteNonTradingCommissionGroup: builder.mutation({
      query: (id: string) => ({
        url: `${nonTradingCommissionGroupsEndpoint}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateNonTradingCommissionGroupMutation,
  useCreateNonTradingCommissionMutation,
  useDeleteNonTradingCommissionGroupMutation,
  useDeleteNonTradingCommissionMutation,
  useGetNonTradingCommissionGroupsByIdQuery,
  useGetNonTradingCommissionGroupsQuery,
  useGetNonTradingCommissionsByGroupQuery,
  useGetNonTradingCommissionsQuery,
  useUpdateNonTradingCommissionMutation,
} = nonTradingCommissionsApi;
