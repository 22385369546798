import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Skeleton, Notification } from 'react-ui-kit-exante';

import { ExpiredInstrumentsUpdateFields } from '~/api';
import {
  expiredInstrumentActions,
  useGetAllowedSymbolsQuery,
} from '~/api/expiredInstruments/expiredInstruments.api';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import {
  selectRequestsToUpdate,
  updateFields,
} from '../../expiredInstrumentsSlice';
import { getChangeVisibility, getRequestById } from '../../helpers';
import { TableData } from '../../types';

type ConvertToCellProps = {
  id: ExpiredInstrumentsUpdateFields;
  originalValues: TableData;
  rowId: string;
};

export const ConvertToCell = ({
  id,
  originalValues,
  rowId,
}: ConvertToCellProps) => {
  const dispatch = useDispatch();
  const requests = useSelector(selectRequestsToUpdate);
  const values = getRequestById(requests, originalValues);
  const value = values?.[id] || null;
  const setVisibility = getChangeVisibility(rowId);

  const requestParams = originalValues.optionGroupId
    ? { optionGroupId: originalValues.optionGroupId }
    : { symbolId: originalValues.symbolId };

  const { data, isLoading, error, isFetching } = useGetAllowedSymbolsQuery(
    {
      params: requestParams,
    },
    {
      skip:
        get(requests, [
          originalValues.optionGroupId || originalValues.symbolId,
          'action',
        ]) !== expiredInstrumentActions.convertInto,
    },
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateFields({
        id: originalValues.optionGroupId || originalValues.symbolId,
        key: id,
        value: e.target.value,
        type: originalValues.optionGroupId ? 'optionGroup' : 'symbol',
      }),
    );

    if (originalValues.optionGroupId) {
      setVisibility('underlyingPrice', true);
    }
  };

  useEffect(() => {
    if (error) {
      Notification.error({
        title: `Error while getting allowed symbols: ${error}`,
      });
    }
  }, [error]);

  if (isLoading || isFetching) {
    return (
      <div data-input-id={id} style={{ display: 'none' }}>
        <Skeleton height={30} width="100%" />
      </div>
    );
  }

  return (
    <div data-input-id={id} style={{ display: 'none' }}>
      <Select
        size="small"
        sx={{
          width: 200,
        }}
        disabled={Boolean(error) || !data?.length}
        value={String(value)}
        options={transformVariantsToSelectOptions(data) || []}
        onChange={handleChange}
      />
    </div>
  );
};
