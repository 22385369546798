import { useReducer } from 'react';

import { SymbolPermissionsContainer } from './SymbolPermissionsContainer';
import { INITIAL_STATE, SymbolPermissionsContext } from './constants';
import { reducer } from './reducer';

export const SymbolPermissionsPage = () => {
  const symbolPermissionsStateManager = useReducer(reducer, INITIAL_STATE);

  return (
    <SymbolPermissionsContext.Provider value={symbolPermissionsStateManager}>
      <SymbolPermissionsContainer />
    </SymbolPermissionsContext.Provider>
  );
};
