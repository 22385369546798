import { useFormContext, useController } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Input } from 'react-ui-kit-exante';

import { ACCOUNT_ID_MAX_LENGTH } from '../constants';

interface IAccountIdInputProps {
  clientId: string;
  className?: string;
}

export const AccountIdInput = ({
  clientId,
  className,
}: IAccountIdInputProps) => {
  const mask = `${clientId.replaceAll('9', '\\9')}.${'*'.repeat(
    ACCOUNT_ID_MAX_LENGTH,
  )}`;

  const formContext = useFormContext();

  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name: 'id',
  });

  formContext.register('id');

  return (
    // Must be fixed in react-ui-kit
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      maskChar=""
      onBlur={onBlur}
    >
      {() => (
        <Input
          label="Account Id"
          error={Boolean(error)}
          message={error?.message}
          sx={{
            width: '100%',
          }}
          className={className}
        />
      )}
    </InputMask>
  );
};
