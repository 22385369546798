import { boolean, mixed, object, string } from 'yup';

import {
  validationJSON,
  validationUserFormArray,
} from '~/shared/utils/validation';

import { createArraysValidation } from './validateUsers';

export const getValidationSchema = (isUserTypeRequired: boolean) =>
  object({
    user: object({
      info: object({
        firstName: string().nullable(),
        lastName: string().nullable(),
        middleName: string().nullable(),
        email: string().email('Should be valid e-mail').nullable(),
        sex: string().nullable(),
        lei: string().nullable(),
        birthDate: string().nullable(),
        companyClassification: string().nullable(),
        userType: isUserTypeRequired
          ? string().required('User type is required')
          : string(),
        primaryPhone: string().nullable(),
        archived: boolean(),
        preferableLanguage: string().nullable(),
        residence: string().nullable(),
        preferableCurrency: string().nullable(),
        jurisdiction: string().nullable(),
        passport: string().nullable(),
        branding: string().nullable(),
        extraData: object({
          internalInfo: string().nullable(),
          residence: string().nullable(),
        }).nullable(),
        originatorIds: object({
          default: string().nullable(),
          overrides: mixed().test({
            name: 'originator-ids-overrides-validation',
            test: validationUserFormArray,
          }),
        }),
        notifications: mixed().test({
          name: 'notification-validation',
          test: validationJSON,
        }),
      }),
    }),
    userClients: mixed().test({
      name: 'user-clients-validation',
      test: createArraysValidation('clientId'),
    }),
  });
