import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { transformVariantsToSelectOptions } from '~/shared/utils';
import { AutoCompleteSearch } from '~/types/common';
import { ITransaction, TTransactionColumns } from '~/types/transactions';

import { TRANSACTION_COLUMNS_PROPS } from './constants';

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: {
    legalEntity: string[];
    categories: string[];
    operations: string[];
  };
  fetchAccounts: AutoCompleteSearch;
  fetchAssets: AutoCompleteSearch;
  transactionColumns: TTransactionColumns[];
}

export const getColumns = ({
  onFilter,
  onRemove,
  types: { categories, legalEntity, operations },
  transactionColumns,
}: IGetColumns): IColumn<ITransaction>[] => {
  const handleFilterOptions: Record<string, unknown> = {
    category: transformVariantsToSelectOptions(categories),
    legalEntity: transformVariantsToSelectOptions(legalEntity),
    operationType: transformVariantsToSelectOptions(operations),
  };

  return transactionColumns.map((item) => {
    if (!TRANSACTION_COLUMNS_PROPS[item.id]) {
      const capitalized = item.id.charAt(0).toUpperCase() + item.id.slice(1);
      const header = capitalized.replace(/([A-Z])/g, ' $1').trim();

      return {
        Header: header,
        accessor: item.id,
        disableFilters: true,
        disableSortBy: true,
      };
    }

    return {
      ...TRANSACTION_COLUMNS_PROPS[item.id],
      ...(TRANSACTION_COLUMNS_PROPS[item.id].filterOptions &&
      handleFilterOptions[item.id]
        ? {
            filterOptions: handleFilterOptions[item.id],
          }
        : {}),
      ...(TRANSACTION_COLUMNS_PROPS[item.id].onFilter ? { onFilter } : {}),
      ...(TRANSACTION_COLUMNS_PROPS[item.id].onRemove ? { onRemove } : {}),
    };
  });
};
