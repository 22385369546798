import { get, set } from 'lodash';

import { REGEX_DOUBLE_COLON } from '~/constants';
import { PATH_DELIMITER } from '~/constants/common';
import { RowType } from '~/types/common';
import {
  IChangedOvernights,
  IOvernightResponseTree,
  IOvernightTree,
} from '~/types/overnights';

export interface IConvertOvernightsStructureFromFlatToTreeReturn {
  tree: IOvernightTree[];
  positionByIdInTree: Record<string, string>;
}

export function convertOvernightsStructureFromFlatToTree(
  items: IOvernightResponseTree[],
) {
  const tree: IOvernightTree[] = [];
  const positionByIdInTree: Record<string, string> = {};

  const OVERNIGHTS = {
    benchmarkNegative: null,
    benchmarkPositive: null,
    markupNegative: null,
    markupPositive: null,
    currency: null,
  };

  items.forEach((item) => {
    const [parent, current] = item.path.split(REGEX_DOUBLE_COLON);

    if (!current) {
      tree.push({
        ...item,
        ...OVERNIGHTS,
        rowType: RowType.Node,
        subRows: [],
        nodeIsCalculated: false,
      });
      positionByIdInTree[item.path] = String(tree.length - 1);
    } else {
      const subRows: IOvernightTree[] = get(
        tree,
        `${positionByIdInTree[parent]}.subRows`,
        [],
      );
      subRows.push({
        ...item,
        ...OVERNIGHTS,
        rowType: RowType.Node,
        subRows: [],
        nodeIsCalculated: false,
      });
      positionByIdInTree[item.path] = `${positionByIdInTree[parent]}.subRows.${
        subRows.length - 1
      }`;
      set(tree, `${positionByIdInTree[parent]}.subRows`, subRows);
    }
  });

  return { tree, positionByIdInTree };
}

type TPathParams = {
  type: string;
  exchange: string;
  ticker?: string;
  symbolId?: string;
};

export function preparePath(path: string, mode: RowType): TPathParams {
  const pathElements: string[] = path.split(PATH_DELIMITER);

  const hasTickerNode = pathElements.length === 3 && mode === RowType.Node;
  const hasTickerInstrument =
    pathElements.length === 4 && mode === RowType.Instrument;

  const [type, exchange] = pathElements;

  const params = {
    type,
    exchange,
  };

  if (hasTickerNode) {
    const [, , ticker] = pathElements;

    return {
      ...params,
      ticker,
    };
  }

  if (hasTickerInstrument) {
    const [, , ticker, symbolId] = pathElements;

    return {
      ...params,
      ticker,
      symbolId,
    };
  }

  if (mode === RowType.Instrument) {
    const [, , symbolId] = pathElements;

    return {
      ...params,
      symbolId,
    };
  }

  return params;
}

// TODO connect this function with formatPercantage from utils
export function formatPercentage(number: number): number {
  const multipliedNumber = number / 100;
  const length = (String(number).split('.')[1]?.length || 0) + 2;

  return Number(multipliedNumber.toFixed(length < 0 ? 0 : length));
}

export function preparePostDataForAccountsOrGroups(
  changedOvernights: IChangedOvernights,
) {
  const { nodes: changedNodes, instruments: changedInstruments } =
    changedOvernights;

  return Object.values({
    ...changedNodes,
    ...changedInstruments,
  }).map(({ path, markupNegative, markupPositive, rowType, override }) => {
    const pathParams = preparePath(path, rowType);

    return {
      active: override,
      negative: formatPercentage(Number(markupNegative)),
      positive: formatPercentage(Number(markupPositive)),
      ...pathParams,
    };
  });
}
