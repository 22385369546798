import { Checkbox, IColumn } from 'react-ui-kit-exante';

import { FilterLayers, ISymbolTreeStructure } from '~/types/symbolPermissions';

interface IOverrideCellProps {
  column: IColumn<ISymbolTreeStructure>;
  value: boolean;
}

export const OverrideCell = ({ column, value }: IOverrideCellProps) => {
  const { layer } = column.getProps();
  const disabled = [FilterLayers.Default, FilterLayers.Effective].includes(
    layer,
  );

  return <Checkbox checked={value} disabled={disabled} />;
};
