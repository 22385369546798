import { isUndefined } from 'lodash';

import { IRowData } from '~/types/symbolPermissions';

export function getNewData(
  initialValue: IRowData,
  columnId: 'public' | 'view' | 'short' | 'trade' | 'override',
  path: string,
  values: Record<string, boolean>,
  shouldToFalse: boolean,
): IRowData {
  const view = isUndefined(values.view) ? values.view : false;
  const trade = isUndefined(values.trade) ? values.trade : false;
  const short = isUndefined(values.short) ? values.short : false;

  if (columnId === 'public' && shouldToFalse) {
    return {
      ...initialValue,
      view,
      trade,
    };
  }

  if (columnId === 'override' && (path || '').startsWith('FOREX')) {
    return {
      ...initialValue,
      view,
      trade,
    };
  }

  if (columnId === 'override') {
    return {
      ...initialValue,
      view,
      trade,
      short,
    };
  }

  return initialValue;
}
