import { PARAMS_FOR_REQUEST, PARAMS_FOR_EQUAL } from '~/api/auditLog/constants';
import {
  createStringDateUTCWithoutTimezone,
  threeDaysAgo,
  today,
  convertDateToISO,
} from '~/shared/utils/dates';
import { IFetchParams, TFetchAuditLogsParams } from '~/types/auditLogs';
import { TActivityEntities } from '~/types/common';

export function getDefaultFilters() {
  return {
    time: [
      createStringDateUTCWithoutTimezone(threeDaysAgo),
      createStringDateUTCWithoutTimezone(today),
    ],
  };
}

export function prepareParams(
  params: IFetchParams,
  entity: TActivityEntities,
): TFetchAuditLogsParams {
  const { page, limit, sorting, ...rest } = params;
  const query = [];

  // for user which has clients
  if (
    entity === 'user-activity-entry-screen' &&
    Array.isArray(rest.accountId)
  ) {
    query.push(rest.accountId.map((id) => `"account"="${id}"`).join(' or '));
    delete rest.accountId;
    // for user which no has clients
  } else if (
    entity === 'user-activity-entry-screen' &&
    Array.isArray(rest.user)
  ) {
    query.push(rest.user.map((id) => `"user"="${id}"`).join(' or '));
    delete rest.user;
  } else if (entity === 'account-activity-entry-screen') {
    rest.account = rest.accountId;

    delete rest.clientId;
    delete rest.accountId;
  } else if (rest.clientId) {
    rest.account = rest.clientId;

    delete rest.clientId;
    delete rest.accountId;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in rest) {
    if (!PARAMS_FOR_REQUEST.has(key)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (key === 'time' && Array.isArray(rest.time) && rest.time.length) {
      query.push(`"time">='${convertDateToISO(rest.time[0])}'`);
      query.push(`"time"<='${convertDateToISO(rest.time[1])}'`);
    } else if (PARAMS_FOR_EQUAL[entity].has(key)) {
      query.push(`"${key}"="${rest[key]}"`);
    } else {
      query.push(`"${key}"~"${rest[key]}"`);
    }
  }

  return {
    limit,
    offset: page * limit,
    query: query.join(' and '),
    order: Array.isArray(sorting) && sorting[0].desc ? 'desc' : 'asc',
    orderBy: Array.isArray(sorting) ? sorting[0].id : '',
  };
}
