import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  IColumn,
  IRowExpand,
  Notification,
} from 'react-ui-kit-exante';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

interface ICheckboxCellProps {
  column: IColumn<ICommissionTree>;
  row: IRowExpand<ICommissionTree>;
  value: boolean;
}

export const CheckboxCell = ({ row, value, column }: ICheckboxCellProps) => {
  const { layer } = column.getProps();
  const dispatch = useDispatch();

  const isNodeCell = row.original.rowType === RowType.Node;
  const isMinFeeExchangeOnDefaultLayer =
    layer === FilterLayers.Default && column.id === 'minFeeExchange';
  const isNonOverridableOnNonDefaultLayer =
    layer !== FilterLayers.Default && !row.original.override;

  const disabled =
    isMinFeeExchangeOnDefaultLayer || isNonOverridableOnNonDefaultLayer;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const actionForUpdateValue = isNodeCell
        ? updateNodeValue
        : updateInstrumentValue;

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: event.target.checked,
          column: column.id,
        }),
      );
    },
    [column, dispatch, isNodeCell, row],
  );

  const onClickHandler = useCallback(() => {
    if (isMinFeeExchangeOnDefaultLayer) {
      Notification.warning({
        title: 'You can not change Minimum Fee Exchange on Default layer',
      });
    }

    if (isNonOverridableOnNonDefaultLayer) {
      Notification.warning({
        title: `You can not change non-overridable commission`,
      });
    }
  }, [isMinFeeExchangeOnDefaultLayer, isNonOverridableOnNonDefaultLayer]);

  return (
    <Checkbox
      disabled={disabled}
      checked={value}
      onChange={onChangeHandler}
      onClick={onClickHandler}
    />
  );
};
