import { get } from 'lodash';

import { TRiskArrayFullNode } from '~/api';
import { PATH_DELIMITER } from '~/constants';

import { rowTypes } from '../constants';
import { ITreeNode } from '../types';

import { toExpandedTreePath } from './toExpandedTreePath';

export function transformSearchResultToTree(
  searchResult: TRiskArrayFullNode[],
  iconsMap: Record<string, string>,
) {
  const hash: ITreeNode[] = [];
  const ids = new Map<string, string>();
  searchResult.forEach((item) => {
    const { path } = item;
    const parents = path.split(PATH_DELIMITER);
    const root = String(parents.shift());
    const leaf = String(parents.pop());
    let newRootIndex = '';
    if (!ids.has(String(root))) {
      newRootIndex = String(hash.length);
      hash.push({
        id: root,
        type: rowTypes.root,
        name: root,
        subRows: [],
        icon: iconsMap[root],
        path: root,
        index: newRootIndex,
      });
      ids.set(root, String(hash.length - 1));
    }
    let rootPath = ids.get(root);
    let rootPathName = root;
    while (parents.length > 0) {
      const curr = parents.shift();
      const parentNode = get(hash, toExpandedTreePath(rootPath));
      const pathToNodeName = `${parentNode.path || rootPathName}.${curr}`;
      if (ids.has(pathToNodeName)) {
        rootPath = ids.get(pathToNodeName);
        rootPathName = pathToNodeName;
      } else {
        const newPath = `${rootPath}.${parentNode.subRows.length || 0}`;
        parentNode.subRows.push({
          id: curr,
          name: curr,
          subRows: [],
          type: rowTypes.node,
          path: pathToNodeName.replaceAll('.', PATH_DELIMITER),
          index: newPath,
        });
        ids.set(pathToNodeName, newPath);
        rootPath = newPath;
      }
    }
    const last = get(hash, toExpandedTreePath(rootPath));
    const newIndex = last.subRows.length;
    const hasStrikes = Array.isArray(item.strikes) && item.strikes.length > 0;
    const strikes = hasStrikes
      ? item.strikes?.map((strikeItem, strikeIndex) => ({
          ...strikeItem,
          index: `${rootPath}.${newIndex}.${strikeIndex}`,
        }))
      : [];
    last.subRows.push({
      ...item,
      id: leaf,
      type: rowTypes.leaf,
      path,
      index: `${rootPath}.${newIndex}`,
      name: hasStrikes ? `${item.displayName} (${leaf})` : leaf,
      ...(hasStrikes ? { subRows: strikes } : {}),
    });
  });
  return hash;
}
