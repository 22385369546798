import { useMemo } from 'react';

import { TInterestRatesMap } from '~/api/interestRates/types';
import { ICurrency } from '~/types/currencies';
import { IInterestRate } from '~/types/interestRates';

import { getColumnSetByLayerName } from '../../columns';
import { InterestRatesTable } from '../../components';
import { prepareDataForTable } from '../../helpers';
import { useClientFilters } from '../../hooks';
import { ELayer } from '../../types';

interface IDefaultRatesLayerProps {
  currencies: ICurrency[];
  defaultRates: TInterestRatesMap;
  loadingDefault: boolean;
  updateDefaultRates: (
    previous: IInterestRate,
    updated: IInterestRate,
  ) => Promise<void>;
  onRefresh: () => Promise<void>;
}

export const DefaultRatesLayer = ({
  currencies,
  defaultRates,
  loadingDefault,
  updateDefaultRates,
  onRefresh,
}: IDefaultRatesLayerProps) => {
  const { filters, onRemove, removeAllFilters } = useClientFilters();
  const preparedDefaultTableData = useMemo(
    () => prepareDataForTable(currencies, defaultRates),
    [currencies, defaultRates],
  );

  const columns = getColumnSetByLayerName(ELayer.Default, { onRemove });

  return (
    <InterestRatesTable
      loading={loadingDefault}
      onUpdate={updateDefaultRates}
      data={preparedDefaultTableData}
      columns={columns}
      filters={filters}
      removeAllFilters={removeAllFilters}
      onRefresh={onRefresh}
    />
  );
};
