interface IDownloadFromPublic {
  url: string;
  filename?: string;
}

export function downloadFromUrl({
  url,
  filename = 'download',
}: IDownloadFromPublic): void {
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.click();
  link.remove();
}
