import { get } from 'lodash';

import { TRiskArrayTree } from '~/api';
import { PATH_DELIMITER } from '~/constants';

import { ITreeNode } from '../types';

import { toExpandedTreePath } from './toExpandedTreePath';

const addNewSubNodeToTree = (
  node: TRiskArrayTree,
  output: unknown[],
  indexes: Record<string, string>,
) => {
  const fullPath = node.path.split(PATH_DELIMITER);

  fullPath.pop();

  const pathToParent = fullPath.join('.');
  const parentIndex = indexes[pathToParent];
  const parentElement = get(output, toExpandedTreePath(parentIndex));

  // sometimes core backend may have defective data, even on production https://jira.exante.eu/browse/WBU-1088
  if (parentElement && parentElement?.subRows) {
    parentElement.subRows.push({
      ...node,
      subRows: [],
      type: 'node',
      index: `${parentIndex}.${parentElement.subRows.length}`,
    });
    const newElementIndex = parentElement.subRows.length - 1;
    const newElementPath = node.path.replaceAll(PATH_DELIMITER, '.');

    indexes[newElementPath] = `${parentIndex}.${newElementIndex}`;
  }
};

const addNewRootNodeToTree = (
  node: TRiskArrayTree,
  output: unknown[],
  indexes: Record<string, string>,
) => {
  output.push({
    ...node,
    subRows: [],
    type: 'root',
    index: output.length,
  });

  const index = output.length - 1;
  indexes[node.name] = String(index);
};

const transformToTree = (arr: TRiskArrayTree[]) => {
  const indexes: Record<string, string> = {};
  const output: ITreeNode[] = [];

  arr.forEach((node) => {
    const paths = node.path.split(PATH_DELIMITER);
    const isRootNode = paths.length === 1;

    if (isRootNode) {
      addNewRootNodeToTree(node, output, indexes);
    } else {
      addNewSubNodeToTree(node, output, indexes);
    }
  });

  return output;
};

const sortByPathLengthAndAlphabetically = (riskArrayData: TRiskArrayTree[]) => {
  const sorted = [...riskArrayData].sort((first, second) => {
    const firstPath = first.path.split(PATH_DELIMITER);
    const secondPath = second.path.split(PATH_DELIMITER);

    const sortOrderByPathLength = firstPath.length - secondPath.length;
    const shouldSortByPathLength = sortOrderByPathLength !== 0;

    if (shouldSortByPathLength) {
      return sortOrderByPathLength;
    }
    if (first.path < second.path) {
      return -1;
    }
    if (first.path > second.path) {
      return 1;
    }
    return 0;
  });

  return sorted;
};

export const transformRiskArrayToTree = (riskArrayData: TRiskArrayTree[]) => {
  const sorted = sortByPathLengthAndAlphabetically(riskArrayData);
  const tree = transformToTree(sorted);

  return tree;
};
