import { useCurrency } from '~/hooks';
import { CommissionTotal } from '~/types/commissions';

interface ITotalCell {
  value: CommissionTotal;
}

export const TotalCell = ({ value: { percent, fixed } }: ITotalCell) => {
  const currency = useCurrency();

  switch (true) {
    case percent !== undefined && fixed !== undefined:
      return (
        <div>
          {percent}%, {fixed} {currency}
        </div>
      );

    case percent !== undefined:
      return <div>{percent}%</div>;

    case fixed !== undefined:
      return (
        <div>
          {fixed} {currency}
        </div>
      );

    default:
      return <div>-</div>;
  }
};
