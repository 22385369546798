import { get } from 'lodash';
import { useEffect } from 'react';
import { UseFormWatch, UseFormSetValue } from 'react-hook-form';

import { IFormData } from '../types';

export const useToggleAccessRights = (
  watch: UseFormWatch<IFormData>,
  setValue: UseFormSetValue<IFormData>,
) => {
  useEffect(() => {
    const subscription = watch((values, { name: tName, type }) => {
      if (type !== 'change') {
        return;
      }

      const name = tName as NonNullable<typeof tName>;
      // If you change field name, don't forget change indexes of operation and operationType
      const operation = name.split('.')[1];
      const operationType = name.split('.')[3];

      const isCheckboxDisabled = (
        op: string,
        opType: 'read' | 'write',
      ): boolean | undefined => {
        const accessRights = (values.accessRights || {})[op];
        const disabledFields = accessRights?.actions?.disabledFields || {};
        return disabledFields[opType];
      };
      const switchViewAndWrite = (fieldName: NonNullable<typeof name>) => {
        const [opType, ...parts] = fieldName.split('.').reverse();
        const newType = opType === 'write' ? 'read' : 'write';
        return [newType, ...parts].reverse().join('.') as NonNullable<
          typeof tName
        >;
      };

      const setReadToTrueIfWriteIsTrue = () => {
        const value = get(values, name, false);

        if (value === true && operationType === 'write') {
          const fieldNameToUpdate = switchViewAndWrite(name);

          if (!isCheckboxDisabled(operation, 'read')) {
            // Somewhere errors in types. Actually it is terrible data structure from previous developer
            // it should be refactored but don't have time
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValue(fieldNameToUpdate, true, { shouldDirty: true });
          }
        }
      };
      const setWriteToFalseIfReadIsFalse = () => {
        const value = get(values, name, false);

        if (value === false && operationType === 'read') {
          const fieldNameToUpdate = switchViewAndWrite(name);

          // Somewhere errors in types. Actually it is terrible data structure from previous developer
          // it should be refactored but don't have time
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(fieldNameToUpdate, false, { shouldDirty: true });
        }
      };

      setReadToTrueIfWriteIsTrue();
      setWriteToFalseIfReadIsFalse();
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);
};
