import { Modal, TabPanel, useModal } from 'react-ui-kit-exante';

import { TDirtyTabs, useTabs } from '~/hooks';
import { Tabs } from '~/shared/components/Tabs';
import { ITab } from '~/types/tabs';

import { StyledTabs } from './TabManagement.styled';

interface ITabManagementProps<
  TabsIds extends string,
  ComProps extends Record<string, unknown>,
> {
  componentProps: ComProps;
  dirtyTabs: TDirtyTabs<TabsIds>;
  tabs: ITab<TabsIds, ComProps>[];
}

export const TabManagement = <
  TabsIds extends string,
  ComProps extends Record<string, unknown>,
>({
  componentProps,
  dirtyTabs,
  tabs,
}: ITabManagementProps<TabsIds, ComProps>) => {
  const modal = useModal();
  const { activeTab, confirmNewTab, handleTabChange, tabsContent, currentTab } =
    useTabs({
      dirtyTabs,
      modal,
      tabs,
    });

  return (
    <>
      <StyledTabs>
        <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
      </StyledTabs>

      {tabsContent.map(({ index, Component }) => (
        <TabPanel key={index} value={activeTab} index={index}>
          <Component {...componentProps} />
        </TabPanel>
      ))}

      <Modal
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        confirmButton={{
          handleConfirm: confirmNewTab,
          confirmButtonName: 'Skip Changes',
        }}
        isOpened={modal.isOpened}
        onClose={modal.onClose}
        title="Do you want to switch to another tab?"
      >
        <div>
          You have changes in the {currentTab?.title} tab, the changes will not
          be saved if you leave the tab.
        </div>
      </Modal>
    </>
  );
};
