import * as Yup from 'yup';

export const getValidationSchema = (groupNames: Set<string>) => {
  return Yup.object({
    name: Yup.mixed().test({
      name: 'non-trading-commissions-name-validation',
      test(value, { path, createError }) {
        if (!value.trim()) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (groupNames.has(value.trim().toLowerCase())) {
          return createError({
            message: 'Name with this name already exists',
            path,
          });
        }

        return true;
      },
    }),
    currency: Yup.string().required('Currency is required'),
  });
};
