import { TActivityEntities } from '~/types/common';

export const PARAMS_FOR_REQUEST = new Set([
  'account',
  'change',
  'description',
  'symbol',
  'time',
  'type',
  'user',
  'who',
]);

export const PARAMS_FOR_EQUAL: Record<TActivityEntities, Set<string>> = {
  'audit-log-list': new Set(['account', 'symbol', 'user', 'who']),
  'account-activity-entry-screen': new Set([
    'account',
    'symbol',
    'user',
    'who',
  ]),
  'client-activity-entry-screen': new Set(['symbol', 'user', 'who']),
  'user-activity-entry-screen': new Set(['symbol', 'user', 'who']),
};
