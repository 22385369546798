import { uniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'react-ui-kit-exante';

import { getInjectedValues, getNewValues } from './helpers';

export interface IFormMultiSelectContainerProps {
  fullWidth?: boolean;
  label?: string;
  name: string;
  options: string[];
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  withAll?: boolean;
}

export const FormMultiSelectWithAllOptions = ({
  fullWidth = true,
  label = '',
  name,
  options,
  disableCloseOnSelect = true,
  disableClearable = false,
  withAll = true,
}: IFormMultiSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error, isDirty },
  } = useController({
    control,
    name,
  });

  const injectedOptions = useMemo(
    () => uniq(withAll ? ['ALL', ...options] : options),
    [options, withAll],
  );
  const injectedValue = useMemo(() => getInjectedValues(value), [value]);

  const multiSelectChangeHandler = useCallback(
    (_: unknown, newValues: string[]) => onChange(getNewValues(newValues)),
    [onChange],
  );
  const controlProps = useMemo(
    () => ({
      error: Boolean(error),
      message: error?.message,
      warning: isDirty,
    }),
    [error, isDirty],
  );

  return (
    <Autocomplete
      fullWidth={fullWidth}
      isMultiple
      disableClearable={disableClearable}
      onChange={multiSelectChangeHandler}
      options={injectedOptions}
      placeholder={label}
      value={injectedValue}
      disableCloseOnSelect={disableCloseOnSelect}
      controlProps={controlProps}
    />
  );
};
