import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function changedDefaultNodesUpdate(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.CHANGED_DEFAULT_NODES_UPDATE],
) {
  return {
    ...state,
    changedDefaultNodes: {
      ...state.changedDefaultNodes,
      [payload.id]: {
        ...state.changedDefaultNodes[payload.id],
        [payload.type]: payload.value,
      },
    },
  };
}
