import { RowType } from '~/types/common';

import { ICommissionRules, ITableViewRules, CommissionTotal } from './rules';

interface ICommissionBase {
  currency: string | null;
  inherited: boolean;
  minFeeExchange: boolean | null;
  minFeeOdd: null;
  minFeeRebateRate: string;
  minimumFee: null;
  override: null;
}

interface ICommissionResponse extends ICommissionBase {
  rules: ICommissionRules;
}

interface ICommissionTreeBase {
  displayName: string;
  icon: string | null;
  id: string;
  path: string;
  typeExchangeTicker: string;
}

export interface ICommissionTreeResponse extends ICommissionTreeBase {
  commission: ICommissionResponse;
}

export interface ICommissionTree extends ICommissionTreeBase, ICommissionBase {
  rowType: RowType;
  subRows?: ICommissionTree[];
  exchange: ITableViewRules[];
  internal: ITableViewRules[];
  rebate: ITableViewRules[];
  total: CommissionTotal;
}

export enum FilterLayers {
  Default = 'Default',
  Groups = 'Groups',
  Accounts = 'Accounts',
}

export const tabs = ['Default', 'Groups', 'Accounts'];

export type TCommissionsQueryParams =
  | {
      layer: FilterLayers;
      entity?: number | string;
    }
  | false;

export interface IChangedCommission {
  nodes: Record<string, ICommissionTree>;
  instruments: Record<string, ICommissionTree>;
}

export interface IStateCommissions {
  changedCommission: IChangedCommission;
  filters: {
    select: {
      layer: FilterLayers;
      account: string | null;
      group: number | string | undefined | null;
    };
    queryParams: TCommissionsQueryParams;
    relatedGroup: number | null;
  };
  initialCommission: Record<string, ICommissionTreeResponse>;
  table: {
    defaultTree: ICommissionTree[];
    defaultPositionByIdInTree: Record<string, string>;
    downloadedPaths: string[];
    expandedRows: Record<string, boolean> | boolean;
    positionByIdInTree: Record<string, string>;
    tree: ICommissionTree[];
  };
  search: {
    isActive: boolean;
    tree: ICommissionTree[];
  };
}

export interface IGetResolveCommissionsTreeParams {
  layer: FilterLayers;
  entity?: number | string;
}
