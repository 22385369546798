import { isFunction, isString } from 'lodash';
import { useRef } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { Notification } from 'react-ui-kit-exante';

import { useUpdateAccountMutation } from '~/api';

import { accountUsersService } from '../../../resources';
import { TUsersPermissionResponse } from '../../../resources/usersPermission/types';
import {
  createNewState,
  getExcludedKeys,
  isOption,
  isUpdateUserPermissionsResponse,
  prepareDataToUpdateUsersPermission,
  separateUsers,
} from '../helpers';
import { IFormData } from '../types';

interface IUseUsersPermissionProps {
  accountId: string;
  initialUsersPermission: (Record<string, unknown> | undefined)[];
}

interface IUpdateUsersPermission {
  (props: {
    newData: IFormData;
    allData: IFormData;
    updatedFields: Partial<FieldNamesMarkedBoolean<IFormData>>;
    onSuccess?: (newState: IFormData) => unknown;
  }): Promise<unknown>;
}

interface IRemoveUsersPermission {
  (ids: string | string[]): Promise<boolean>;
}

export const useUsersPermission = ({
  accountId,
  initialUsersPermission = [],
}: IUseUsersPermissionProps) => {
  const [updateAccount] = useUpdateAccountMutation();
  const addedUsersPermission = useRef<TUsersPermissionResponse[] | null>(null);

  const removeUsersPermissionRequest: IRemoveUsersPermission = async (ids) => {
    if (isString(ids)) {
      return accountUsersService.removeUserAccount(ids);
    }

    const queue = ids.map((id) => accountUsersService.removeUserAccount(id));
    const responses = await Promise.all(queue);

    return responses.every((res) => res);
  };

  const updateUsersPermissionRequest = async ({
    newData,
    updatedFields,
  }: {
    newData: IFormData;
    updatedFields: Partial<FieldNamesMarkedBoolean<IFormData>>;
  }) => {
    const preparedNewData = prepareDataToUpdateUsersPermission({
      newData,
      updatedFields,
    });
    const { editedUsersPermission, newUsersPermission } =
      separateUsers(preparedNewData);

    const response = await accountUsersService.updateUsersPermission({
      editedUsersPermission,
      newUsersPermission,
    });

    if (response) {
      const { newItems } = response;
      addedUsersPermission.current = newItems;

      return response;
    }

    return null;
  };

  const updateAccountStatusRequest = async (status: string) => {
    const response = await updateAccount({
      accountId,
      status,
    });

    return !('error' in response);
  };

  const updateUsersPermission: IUpdateUsersPermission = async ({
    newData,
    allData,
    updatedFields,
    onSuccess,
  }) => {
    const queue = [];
    const shouldUpdateUserPermissions = Boolean(updatedFields.users?.length);
    const shouldUpdateAccountStatus = updatedFields.status;
    const removeKeys = getExcludedKeys(initialUsersPermission, newData.users);
    const shouldRemoveUserPermission = Boolean(removeKeys.length);

    if (shouldUpdateUserPermissions) {
      const requestToUpdateUsers = updateUsersPermissionRequest({
        newData,
        updatedFields,
      });

      queue.push(requestToUpdateUsers);
    }

    if (shouldUpdateAccountStatus) {
      const requestToUpdateAccount = updateAccountStatusRequest(newData.status);
      queue.push(requestToUpdateAccount);
    }

    if (shouldRemoveUserPermission) {
      const requestToRemoveUsersPermission =
        removeUsersPermissionRequest(removeKeys);
      queue.push(requestToRemoveUsersPermission);
    }

    const responses = await Promise.all(queue);
    let newStateUsers = newData.users;

    if (responses.every((res) => res)) {
      const [updatedAccountsResponse] = responses;

      if (
        shouldUpdateUserPermissions &&
        isUpdateUserPermissionsResponse(updatedAccountsResponse)
      ) {
        const { newItems: addedUsers } = updatedAccountsResponse;
        const addedUsersMap = new Map();

        (addedUsers || []).forEach((user) =>
          addedUsersMap.set(user.userId, user),
        );

        newStateUsers = newData.users.map((user) => {
          const userId = isOption(user.userId)
            ? user.userId.value
            : user.userId;

          return {
            ...user,
            userId,
            id: addedUsersMap.get(userId)?.id || user.id,
          };
        });
      }

      const newState = createNewState(
        addedUsersPermission.current || [],
        allData,
        {
          ...newData,
          users: newStateUsers,
        },
      );

      Notification.success({
        title: 'Users permission successfully updated',
      });

      if (isFunction(onSuccess)) {
        onSuccess(newState);
        addedUsersPermission.current = [];
      }
    }

    return null;
  };

  return {
    updateUsersPermission,
  };
};
