import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'react-ui-kit-exante';

import { ExpiredInstrumentAction, expiredInstrumentActions } from '~/api';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import {
  selectRequestsToUpdate,
  updateFields,
} from '../../expiredInstrumentsSlice';
import { getChangeVisibility, isExpiredInstrumentAction } from '../../helpers';
import { TableData } from '../../types';

type SelectActionProps = {
  originalValues: TableData;
  rowId: string;
};

export const SelectAction = ({ originalValues, rowId }: SelectActionProps) => {
  const dispatch = useDispatch();
  const requestToUpdate = useSelector(selectRequestsToUpdate);

  const [action, setAction] = useState<ExpiredInstrumentAction>(
    get(
      requestToUpdate,
      [originalValues.optionGroupId || originalValues.symbolId, 'action'],
      expiredInstrumentActions.doNothing,
    ),
  );

  const updateFieldsVisibility = useCallback(
    (value: ExpiredInstrumentAction) => {
      const setVisibility = getChangeVisibility(rowId);

      if (value === expiredInstrumentActions.convertInto) {
        setVisibility('convertInto', true);
        setVisibility('closePrice', false);
      }
      if (value === expiredInstrumentActions.moveInCash) {
        setVisibility('closePrice', true);
        setVisibility('convertInto', false);
        setVisibility('underlyingPrice', false);
      }
      if (value === expiredInstrumentActions.doNothing) {
        ['closePrice', 'convertInto', 'underlyingPrice'].forEach((t) =>
          setVisibility(t, false),
        );
      }
    },
    [rowId],
  );

  const handleChange = ({
    target: { value = '' },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (isExpiredInstrumentAction(value)) {
      dispatch(
        updateFields({
          id: originalValues.optionGroupId || originalValues.symbolId,
          key: 'action',
          value,
          type: originalValues.optionGroupId ? 'optionGroup' : 'symbol',
        }),
      );

      setAction(value);
    }
  };

  useEffect(() => {
    updateFieldsVisibility(action);
  }, [action, updateFieldsVisibility]);

  const options =
    transformVariantsToSelectOptions(Object.values(expiredInstrumentActions)) ||
    [];

  return (
    <Select
      size="small"
      sx={{
        width: 200,
      }}
      value={action}
      options={options}
      onChange={handleChange}
    />
  );
};
