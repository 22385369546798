import InternalCommentStyles from './InternalComment.module.css';

interface IInternalCommentProps {
  comment: string;
}

export const InternalComment = ({ comment }: IInternalCommentProps) => {
  const { text, marked } = prepareComment(comment);

  return (
    <span className={InternalCommentStyles.Wrapper}>
      <mark className={InternalCommentStyles.Marked}>{marked}</mark>
      {text && <span>&nbsp;{text}</span>}
    </span>
  );
};

const COLORING_WORDS_NUMBER = 2;

function prepareComment(comment: string) {
  const dividedComment = comment.split(' ');
  const marked = dividedComment.slice(0, COLORING_WORDS_NUMBER).join(' ');

  if (dividedComment.length > COLORING_WORDS_NUMBER) {
    const text = dividedComment
      .slice(COLORING_WORDS_NUMBER, dividedComment.length)
      .join(' ');

    return { marked, text };
  }

  return { marked };
}
