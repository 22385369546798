import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCurrencies } from '~/store/currencies';

export function useStoreRequests() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);
}
