import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

import { endpoints } from './endpoints';
import {
  THistoryLastUpdateForDayResponse,
  TRequestHistoryLastUpdateForDayParams,
  TRequestTaskIdParams,
  TRequestTaskStatusParams,
  TTaskIdResponse,
  TTaskStatusResponse,
} from './types';

export const globalSummaryTasksApi = createApi({
  reducerPath: 'globalSummaryTasksApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getTaskId: builder.query<TTaskIdResponse, TRequestTaskIdParams>({
      query: ({ date, ...params }) => ({
        url: `${endpoints.globalSummaryHistory}/${date}`,
        method: 'PUT',
        params,
      }),
    }),
    getHistoryLastUpdateForDay: builder.query<
      THistoryLastUpdateForDayResponse,
      TRequestHistoryLastUpdateForDayParams
    >({
      query: (params) => ({
        params,
        url: endpoints.globalSummaryHistoryLastUpdateForDay,
      }),
    }),
    getTaskStatus: builder.query<TTaskStatusResponse, TRequestTaskStatusParams>(
      {
        query: ({ taskId }) => ({
          url: `${endpoints.tasks}/${taskId}`,
          ignoreForbiddenError: true,
        }),
      },
    ),
  }),
});

export const {
  useGetTaskStatusQuery,
  useLazyGetTaskIdQuery,
  useGetHistoryLastUpdateForDayQuery,
} = globalSummaryTasksApi;
