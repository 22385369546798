import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetExecutionSchemesOptionsQuery } from '~/api';
import { useGetTradeTypesQuery } from '~/api/types/types.api';
import {
  selectSymbolIdSettlementCounterpartyOptions,
  selectSymbolIdCounterpartyOptions,
} from '~/store/symbols';

import { SIDE_OPTIONS } from '../constants';

export function useOptions() {
  const {
    data: executionSchemesOptions,
    isLoading,
    isFetching,
  } = useGetExecutionSchemesOptionsQuery();

  const settlementCounterparty = useSelector(
    selectSymbolIdSettlementCounterpartyOptions,
  );

  const counterparty = useSelector(selectSymbolIdCounterpartyOptions);

  const { data: tradeTypes } = useGetTradeTypesQuery();

  const options = useMemo(
    () => ({
      counterparty,
      executionSchemeId: executionSchemesOptions || [],
      settlementCounterparty,
      side: SIDE_OPTIONS,
      tradeType: tradeTypes
        ? tradeTypes?.map((type) => ({ label: type, value: type }))
        : [],
    }),
    [counterparty, settlementCounterparty, executionSchemesOptions, tradeTypes],
  );

  return { options, isExecutionSchemesLoading: isLoading || isFetching };
}
