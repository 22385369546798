import { isEqual } from 'lodash';
import { MutableRefObject } from 'react';
import { string } from 'yup';

import { ICard } from '~/types/cardsManagement';

export function validateSanPanFields(
  length: number,
  message: string,
  fieldName: string,
  previousCards: MutableRefObject<ICard[]>,
) {
  return string()
    .test(`${fieldName} length validation`, message, (value, { parent }) => {
      const previousParent = previousCards.current.find(
        (item) => item.id === parent.id,
      );

      /**
       * if parent.id it means it is existing card
       * Then if field is empty we should show error
       */
      if (parent.id && !isEqual(parent, previousParent)) {
        if (!value || value.trim().length === 0) {
          return false;
        }
      }

      /**
       * if it is new card we can pass empty field
       */
      if (!value) {
        return true;
      }

      const trimmedValue = value.trim();

      /**
       * But if we write some value we should validate
       * It can be empty or exactly length
       */
      return trimmedValue.length === 0 || trimmedValue.length === length;
    })
    .nullable();
}

export function validateIssueId(previousCards: MutableRefObject<ICard[]>) {
  return string()
    .test('issueId validation', 'Required field!', (value, { parent }) => {
      const previousParent = previousCards.current.find(
        (item) => item.id === parent.id,
      );

      /**
       * if parent.id it means it is existing card
       * Then if field is empty we should show error
       */
      if (parent.id && !isEqual(parent, previousParent)) {
        if (!value || value.trim().length === 0) {
          return false;
        }
      }

      /**
       * if it is new card we can pass empty field
       */
      return true;
    })
    .nullable();
}
