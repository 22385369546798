import { usePickUserPermissions } from '~/hooks';
import { TPossibleAccessRights } from '~/types/users';

import { IOriginatorIdsProps } from '../types';

export function useHasPermissionForWrite({
  accountId,
  path,
}: IOriginatorIdsProps): TPossibleAccessRights {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: routing settings',
    'User info',
    'User info: originator ids',
  ]);

  if (accountId) {
    return {
      write:
        userPermissions['Account info'].write &&
        userPermissions['Account info: routing settings'].write,
      read: userPermissions['Account info: routing settings'].read,
    };
  }

  if (path.startsWith('user')) {
    return {
      write:
        userPermissions['User info'].write &&
        userPermissions['User info: originator ids'].write,
      read: userPermissions['User info: originator ids'].read,
    };
  }

  return {
    write: false,
    read: false,
  };
}
