import { styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-start',

  '& > div': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '280px',
  },

  '@media (min-width: 1200px)': {
    flexWrap: 'nowrap',
  },
}));
