import { useState } from 'react';

import { IAccountPermissionByUsername } from '~/types/accountPermissions';

import { IPrevPermissionsData } from './types';

export function usePreviousPermissions(
  defaultPermissions: IAccountPermissionByUsername[],
): IPrevPermissionsData {
  // using useState here because we need rerender form after change state
  const [previousPermissions, setPreviousPermissions] =
    useState<IAccountPermissionByUsername[]>(defaultPermissions);

  return { previousPermissions, setPreviousPermissions };
}
