import { FC, useMemo, useState } from 'react';

import { DefaultThemeProvider } from '../../../theme';
import { SearchInput } from '../../Inputs/SearchInput/SearchInput';
import { SelectedList } from '../SelectedList';
import SkeletonUISelectedList from '../SkeletonUISelectedList';

import { WrapperSkeletonUI } from './SelectedListWithSearch.styled';
import { IUIISelectedListWithSearchProps } from './types';

export const SelectedListWithSearch: FC<IUIISelectedListWithSearchProps> = ({
  selected,
  options,
  onListItemClick,
  inputProps,
  selectedListProps,
  isLoading,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      return option.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  }, [options, searchValue]);

  return (
    <DefaultThemeProvider>
      <SearchInput onChange={setSearchValue} inputProps={inputProps} />
      {isLoading ? (
        <WrapperSkeletonUI data-test-id="selected-list-with-search-skeleton">
          <SkeletonUISelectedList />
        </WrapperSkeletonUI>
      ) : (
        <SelectedList
          selected={selected}
          options={filteredOptions}
          searchValue={searchValue}
          onListItemClick={onListItemClick}
          sx={{ mt: '12px' }}
          {...selectedListProps}
        />
      )}
    </DefaultThemeProvider>
  );
};
