import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLazyGetOvernightsInstrumentsQuery } from '~/api';
import { expandedRowsSet, selectFiltersQueryParams } from '~/store/overnights';

import { getAndInsertInstrumentsToNode } from '../../helpers';
import { TOvernightCellState } from '../../types';

import { closeRowAnsSubRows } from './helpers';

export function useHandleCellClick() {
  const [getInstruments] = useLazyGetOvernightsInstrumentsQuery();
  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const dispatch = useDispatch();

  const handleCellClick = useCallback(
    ({ column, row, tableState: { expanded } }: TOvernightCellState) => {
      if (column.id === 'name' && row.original.rowType === 'node') {
        if (row.isExpanded) {
          closeRowAnsSubRows(row, dispatch);

          return;
        }

        if (row.subRows.length) {
          row.toggleRowExpanded();

          dispatch(expandedRowsSet([expanded, row.id, true]));

          return;
        }

        if (!row.subRows.length) {
          getAndInsertInstrumentsToNode<typeof getInstruments>({
            dispatch,
            getInstruments,
            path: row.original.path,
            queryParams: filtersQueryParams,
            row,
            skip: 0,
          });
        }
      }
    },
    [dispatch, getInstruments, filtersQueryParams],
  );

  return { handleCellClick };
}
