import { createContext } from 'react';

import { EMPTY_FUNCTION } from '~/constants';
import { DEFAULT_REFRESH_QUERY_STATE } from '~/constants/refreshQuery';
import { TDirtyTabs } from '~/hooks';

import { IUserPageContext, TTabsIds } from './types';

const DEFAULT_DIRTY_TABS_STATE: TDirtyTabs<TTabsIds> = {
  general: false,
  accessRights: false,
  accountPermissions: false,
  activity: false,
};

export const defaultValues: IUserPageContext = {
  dirtyTabs: DEFAULT_DIRTY_TABS_STATE,
  setDirtyTabs: EMPTY_FUNCTION,
  refreshActiveTabQuery: DEFAULT_REFRESH_QUERY_STATE,
  setRefreshFn: EMPTY_FUNCTION,
  activeTabName: '',
};

export const userPageContext = createContext(defaultValues);
export const { Provider: UserPageContextProvider } = userPageContext;
