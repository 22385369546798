import {
  forwardRef,
  useCallback,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import { SearchInput, Tooltip } from 'react-ui-kit-exante';

import { MIN_SEARCH_LENGTH } from '~/constants';
import { useTimer } from '~/hooks';
import { DownloadButton, RefreshButton, SaveButton } from '~/shared/components';

import { SHOW_WARNING_TIME } from '../../constants';
import { useRiskArrayContext } from '../../context/RiskArrayTreeContext';
import { createLinkToRiskArrayDownloading } from '../../helpers';

import RiskArrayHeaderStyles from './RiskArrayHeader.module.css';

const ENTER_KEY_CODE = 13;

type TRef = HTMLDivElement;

export const RiskArrayHeader = forwardRef<TRef>((_, ref) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showMinSearchLengthWarning, setShowMinSearchLengthWarning] =
    useState(false);
  const { setSearch, withExpired, saveTree, refetch, getIsDirty, search } =
    useRiskArrayContext();

  const { startWithDelay } = useTimer();

  const handleKeyDownOnSearch = useCallback(
    ({ keyCode, target }: KeyboardEvent<HTMLInputElement>) => {
      const { value } = target as HTMLInputElement;
      if (keyCode === ENTER_KEY_CODE) {
        if (value.trim().length >= MIN_SEARCH_LENGTH) {
          setSearch(value);
        } else if (value.trim() === '') {
          setSearch('');
        } else {
          setShowMinSearchLengthWarning(true);
          startWithDelay(() => {
            setShowMinSearchLengthWarning(false);
          }, SHOW_WARNING_TIME);
        }
      }
    },
    [setSearch, startWithDelay],
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (
        event.target.value === '' &&
        (event.nativeEvent as InputEvent).inputType !== 'deleteContentBackward'
      ) {
        setSearch('');
      }
    },
    [setSearch],
  );

  const handleSave = () => {
    const isDirty = getIsDirty();

    if (isDirty) {
      saveTree();
    } else {
      setShowWarning(true);
      startWithDelay(() => {
        setShowWarning(false);
      }, SHOW_WARNING_TIME);
    }
  };

  return (
    <div className={RiskArrayHeaderStyles.Container}>
      <div className={RiskArrayHeaderStyles.Header}>
        <h1 className={RiskArrayHeaderStyles.Title}>Risk Arrays</h1>
        <div className={RiskArrayHeaderStyles.Buttons}>
          <Tooltip
            title="Changes not found"
            sx={{
              backgroundColor: '#fff',
              padding: '8px 16px',
            }}
            placement="top"
            open={showWarning}
          >
            <SaveButton onClick={handleSave} />
          </Tooltip>
          <RefreshButton
            onRefresh={() => refetch(true, { withExpired })}
            disabled={Boolean(search)}
          />
          <DownloadButton
            link={createLinkToRiskArrayDownloading(search)}
            fileName="risk-arrays.csv"
          />
        </div>
      </div>

      <div className={RiskArrayHeaderStyles.SearchContainer} ref={ref}>
        <Tooltip
          title={`The request must contain at least ${MIN_SEARCH_LENGTH} characters`}
          open={showMinSearchLengthWarning}
          placement="top"
          style={{
            marginLeft: 160,
            position: 'absolute',
            bottom: 45,
          }}
        >
          <span />
        </Tooltip>
        <SearchInput
          size="medium"
          inputProps={{
            onKeyDown: handleKeyDownOnSearch,
            onChange: handleOnChange,
          }}
        />
      </div>
    </div>
  );
});

RiskArrayHeader.displayName = 'RiskArrayHeader';
