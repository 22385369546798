import { ReactNode } from 'react';
import { IconButton, Panel, PanelGroup } from 'react-ui-kit-exante';

interface ICardManagementTemplateProps {
  onAdd(): void;
  search: ReactNode;
  cardForm: ReactNode;
  header: ReactNode;
}

export const CardManagementTemplate = ({
  header,
  onAdd,
  search,
  cardForm,
}: ICardManagementTemplateProps) => {
  return (
    <>
      {header}

      <PanelGroup panelsPerLine={2}>
        <Panel
          title="Cards list"
          action={<IconButton iconName="AddIcon" onClick={onAdd} />}
        >
          {search}
        </Panel>

        {cardForm}
      </PanelGroup>
    </>
  );
};
