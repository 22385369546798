import { Button } from '@mui/material';
import { CalendarContainer } from 'react-datepicker';

import { styled } from '../../../../../theme';

import {
  IStyledCalendarContainerProps,
  TCalendarTimePickerWrapperProps,
} from './types';

export const StyledCalendarContainer = styled(
  CalendarContainer,
)<IStyledCalendarContainerProps>(({ theme, className }) => {
  const colors = theme?.color?.datepicker;

  return {
    position: 'relative',
    backgroundColor: colors?.background,
    fontFamily: theme?.font?.main,
    borderRadius: '4px',

    [`.${className}`]: {
      '&__month-container': {
        float: 'left',
        padding: '24px',
      },

      '&__month': {
        textAlign: 'center',
      },

      '&__day, &__day-name': {
        display: 'inline-block',
        width: '40px',
        height: '40px',
        padding: '8px',
        fontWeight: 400,
        fontSize: theme?.size?.text?.md,
        lineHeight: '23px',
        textAlign: 'center',
        textTransform: 'capitalize',
        color: colors?.colorDay,

        '&--outside-month': {
          opacity: 0.4,
          color: theme?.color?.typo?.ghost,
        },

        '&--disabled, &--excluded': {
          opacity: 0.4,
          pointerEvents: 'none',
        },
      },

      '&__day-name': {
        fontWeight: 600,
        color: colors?.colorWeek,
      },

      '&__day, &__month-text, &__quarter-text, &__year-text': {
        border: '2px solid transparent',
        borderRadius: '4px',

        cursor: 'pointer',

        '&--today': {
          fontWeight: 600,
          color: theme?.color?.typo?.primary,
          backgroundColor: colors?.daySelected,
        },

        '&--selected:not(&--outside-month), &--keyboard-selected:not(&--outside-month)':
          {
            color: theme?.color?.typo?.primary,
            fontWeight: 600,
            backgroundColor: colors?.daySelected,
            border: `2px solid ${colors?.dayBorder}`,
            borderRadius: '4px',
            opacity: 1,
          },

        '&--in-selecting-range:not(&--outside-month), &--in-range:not(&--disabled):not(&--excluded):not(&--outside-month)':
          {
            backgroundColor: colors?.daySelected,
            borderRadius: 0,

            // reset to remove keyboard selection when inside selected range
            color: theme?.color?.typo?.primary,
            borderColor: 'transparent',
            fontWeight: 600,
            opacity: 1,
          },

        '&--selecting-range-start:not(&--outside-month), &--range-start:not(&--outside-month):not(&--disabled):not(&--excluded)':
          {
            border: `2px solid ${colors?.dayBorder}`,
            borderRadius: '4px 0 0 4px',
          },

        '&--selecting-range-end:not(&--outside-month), &--range-end:not(&--outside-month):not(&--disabled):not(&--excluded)':
          {
            border: `2px solid ${colors?.dayBorder}`,
            borderRadius: '0 4px 4px 0',
          },

        [`&--range-start.${className}__day--range-end:not(&--outside-month):not(&--disabled):not(&--excluded)`]:
          {
            border: `2px solid ${colors?.dayBorder}`,
            borderRadius: '4px',
          },

        '&:hover': {
          opacity: 1,
          color: colors?.colorDay,
          fontWeight: 600,
          backgroundColor: colors?.dayHover,
        },
      },
      '&__day-names, &__week': {
        whiteSpace: 'nowrap',
      },

      '&__week': {
        display: 'flex',
      },

      '&__input-time-container': {
        display: 'none',
      },
    },
  };
});

export const StyledPopperWrapper = styled('div')({
  '& .react-datepicker-popper': {
    zIndex: 2,
  },
});

export const CalendarBody = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const CalendarFooter = styled('div')({
  width: '100%',
  borderTop: '1px solid #EBEBEB',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const CalendarActions = styled('div')({
  padding: '12px',
  display: 'flex',
  gap: '8px',
  width: '100%',
});

export const CalendarTimePickerWrapper = styled('div')(
  (props: TCalendarTimePickerWrapperProps) => ({
    padding: '12px',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: props.fullWidth ? '100%' : 'min-content',
    gap: '24px',
  }),
);

export const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: theme?.size?.text?.md,
  fontFamily: theme?.font?.main,
  textTransform: 'none',
  padding: '0',
  minWidth: '48px',
  color: theme?.color?.typo?.action,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ClearActionButton = styled(ActionButton)(({ theme }) => ({
  color: theme?.color?.typo?.secondary,
}));
