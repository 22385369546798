import { TNumberRangeValues } from '~/types/filters';

const isWrongNumberValue = (value: number | string | null) => {
  switch (typeof value) {
    case 'number':
      return false;

    case 'string':
      return Number.isNaN(parseInt(value, 10));

    default:
      return true;
  }
};

export function prepareNumberArrayForRequest(
  values: TNumberRangeValues | string,
  fieldName: string,
) {
  if (!values) {
    return { [fieldName]: null };
  }

  if (typeof values === 'string') {
    return { [fieldName]: [values, Infinity] };
  }

  const [min, max] = values;

  if ((min === '' && max === '') || (min === null && max === null)) {
    return { [fieldName]: null };
  }

  if ((min || min === 0) && isWrongNumberValue(max)) {
    return { [fieldName]: [min, Infinity] };
  }

  if (isWrongNumberValue(min) && (max || max === 0)) {
    return { [fieldName]: [-Infinity, max] };
  }

  return { [fieldName]: values };
}
