import {
  FilterLayers,
  IGetResolveCommissionsTreeParams,
  IStateCommissions,
} from '~/types/commissions';

export function getQueryParams({
  layer,
  account,
  group,
  relatedGroup,
}: IStateCommissions['filters']['select'] & {
  relatedGroup: IStateCommissions['filters']['relatedGroup'];
}): IGetResolveCommissionsTreeParams | false {
  if (layer === FilterLayers.Default) {
    return { layer };
  }

  if (layer === FilterLayers.Accounts && account) {
    const groupFilterHasNotBeenChanged =
      (!relatedGroup && !group) || relatedGroup === group;

    if (groupFilterHasNotBeenChanged) {
      return {
        layer,
        entity: account,
      };
    }

    return {
      layer: FilterLayers.Groups,
      entity: group === null ? undefined : group,
    };
  }

  if (layer === FilterLayers.Groups && group) {
    return { layer, entity: group };
  }

  return false;
}
