import { useCallback, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Notification, Table } from 'react-ui-kit-exante';

import { useAddInterCommodityMutation, useGetInterCommodityQuery } from '~/api';
import { ChangeIcon } from '~/images/icons/ChangeIcon';
import {
  INTER_COMMODITY_ADD_PATH,
  INTER_COMMODITY_UPDATE_PATH,
} from '~/routes';
import { getTableId, replaceRouteVariable } from '~/shared/utils';
import { IInterCommodityRequestBody } from '~/types/interCommodity';

import { COLUMNS, DEFAULT_SORT_BY } from './constants';
import { TInterCommodityCellState } from './types';

export const InterCommodityPage = () => {
  const [sortData, setSortData] = useState<IInterCommodityRequestBody[] | null>(
    null,
  );
  const { data, isLoading, isFetching } = useGetInterCommodityQuery({});
  const [addInterCommodity, { isLoading: isLoadingPost }] =
    useAddInterCommodityMutation();

  const navigate = useNavigate();
  const goToAddInterCommodity = useCallback(() => {
    navigate(INTER_COMMODITY_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add inter-commodity"
            iconSize={24}
            onClick={goToAddInterCommodity}
          />
        ),
      },
    ],
    [goToAddInterCommodity],
  );

  const handleCellClick = useCallback(
    (dataCell: TInterCommodityCellState) => {
      const { column, row } = dataCell;
      if (column.id !== 'swapRows') {
        navigate(
          replaceRouteVariable(INTER_COMMODITY_UPDATE_PATH, row.values?.id),
          {
            state: {
              previousPath: window.location.href,
            },
          },
        );
      }
    },
    [navigate],
  );

  const onSaveHandler = useCallback(
    async (body: unknown[]) => {
      const result = await addInterCommodity(body);

      if (!('error' in result)) {
        Notification.success({
          title: 'Inter-commodity successfully updated',
        });
      }
    },
    [addInterCommodity],
  );

  const changePriority = (
    direction: string,
    row: { values: IInterCommodityRequestBody; id: number },
    rows: { values: IInterCommodityRequestBody; id: number }[],
  ) => {
    const isDirectionUp = direction === 'up';
    const isSortUp = isDirectionUp
      ? rows[0].values.priority < rows[1].values.priority
      : rows[0].values.priority > rows[1].values.priority;

    rows.forEach(
      (
        item: { values: IInterCommodityRequestBody; id: number },
        index: number,
      ) => {
        if (item.id === row.id) {
          const currentInterCommodity = [
            {
              deltaRatio1: row.values.deltaRatio1,
              deltaRatio2: row.values.deltaRatio2,
              priority: isSortUp
                ? row.values.priority - 1
                : row.values.priority + 1,
              sameLegSide: row.values.sameLegSide,
              spreadCredit: row.values.spreadCredit,
              tierBackBack: row.values.tierBackBack,
              tierFrontBack: row.values.tierFrontBack,
              tierFrontFront: row.values.tierFrontFront,
              underlying1: row.values.underlying1,
              underlying2: row.values.underlying2,
              id: row.values.id,
            },
          ];

          const {
            deltaRatio1,
            deltaRatio2,
            priority,
            sameLegSide,
            spreadCredit,
            tierBackBack,
            tierFrontBack,
            tierFrontFront,
            underlying1,
            underlying2,
            id,
          } = isDirectionUp ? rows[index - 1].values : rows[index + 1].values;

          const nearbyInterCommodity = {
            deltaRatio1,
            deltaRatio2,
            priority: isSortUp ? priority + 1 : priority - 1,
            sameLegSide,
            spreadCredit,
            tierBackBack,
            tierFrontBack,
            tierFrontFront,
            underlying1,
            underlying2,
            id,
          };

          if (
            isSortUp
              ? row.values.priority - 1 === priority
              : row.values.priority + 1 === priority
          ) {
            return onSaveHandler([
              ...currentInterCommodity,
              nearbyInterCommodity,
            ]);
          }

          return onSaveHandler(currentInterCommodity);
        }
        return null;
      },
    );
  };

  useEffect(() => {
    if (data) {
      setSortData(
        [...data].sort((a, b) => {
          return a.priority - b.priority;
        }),
      );
    }
  }, [data]);

  return sortData ? (
    <Table
      additionalActions={additionalActions}
      columns={COLUMNS}
      data={sortData}
      defaultSortBy={DEFAULT_SORT_BY}
      handleCellClick={handleCellClick}
      handleRowClick={() => {}}
      hasFilters
      isFlexLayout
      saveColumnOrder
      saveViewParamsAfterLeave
      showTableInfo
      tableId={getTableId('InterCommodity')}
      title="Inter-Commodity"
      copyPasteMode
      isLoading={isLoading || isFetching || isLoadingPost}
      swapRows={{
        headerOptions: {
          Header: <ChangeIcon />,
        },
        show: true,
        swapRowUp: changePriority,
        swapRowDown: changePriority,
      }}
      isPinnedHeader
    />
  ) : null;
};
