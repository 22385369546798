import { zipObject } from 'lodash';
import { Panel, TAutocompleteAsyncFetchData } from 'react-ui-kit-exante';

import {
  FormCheckboxContainer,
  FormDatePickerContainer,
  FormInputContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';
import { FormItem } from '~/shared/components';
import { IOption } from '~/types/form';

import TradeAddPageStyles from '../../TradeAddPage.module.css';
import { WATCHED_SWITCHED_FIELDS_FOR_COMMISSION } from '../../constants';
import { useCommission } from '../../hooks';
import { TakeCommissionCheckbox } from '../TakeCommissionCheckbox';

interface ITradeProps {
  fetchData: Record<string, TAutocompleteAsyncFetchData>;
  options: Record<string, Array<IOption>>;
  watchCommissionSwitchedFields: any;
}

export const TradeSwitch = ({
  fetchData,
  options,
  watchCommissionSwitchedFields,
}: ITradeProps) => {
  const {
    commission: commissionSwitched,
    isCommissionLoading: isCommissionLoadingSwitched,
  } = useCommission(
    zipObject(
      WATCHED_SWITCHED_FIELDS_FOR_COMMISSION,
      watchCommissionSwitchedFields,
    ),
  );

  return (
    <Panel className={TradeAddPageStyles.Column}>
      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          fetchData={fetchData.accountId}
          label="Account"
          name="switched.accountId"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          disabled
          fetchData={fetchData.user}
          name="switched.user"
        />

        <FormSelectContainer
          label="Trade Type"
          name="switched.tradeType"
          options={options.tradeType}
          readOnly
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectAsyncContainer
          disabled
          fetchData={fetchData.symbolId}
          label="Symbol"
          name="switched.symbolId"
        />

        <FormInputContainer
          label="Price"
          name="switched.price"
          readonly
          disableWarning
          sx={{
            '.MuiInputBase-input': {
              color: '#B8BBB7',
            },
          }}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer
          label="Quantity"
          name="switched.quantity"
          readonly
          disableWarning
          sx={{
            '.MuiInputBase-input': {
              color: '#B8BBB7',
            },
          }}
        />

        <FormToggleButtonGroupContainer
          disabled
          fullWidth
          name="switched.side"
          options={options.side}
        />
      </FormItem>

      {/* start of hidden block for space */}
      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormCheckboxContainer
          className={TradeAddPageStyles.Hidden}
          label=""
          name=""
        />
      </FormItem>

      <FormInputContainer
        className={TradeAddPageStyles.Hidden}
        label=""
        name=""
      />
      {/* end of hidden block for space */}

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectContainer
          label="Counterparty"
          name="switched.counterparty"
          options={options.counterparty}
          readOnly
        />

        <FormSelectContainer
          label="Settlement counterparty"
          name="switched.settlementCounterparty"
          options={options.settlementCounterparty}
          readOnly
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormDatePickerContainer
          disabled
          label="Value date"
          name="switched.valueDate"
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer
          label="Comment for client"
          name="switched.comment"
        />

        <FormInputContainer
          label="Internal comment"
          name="switched.internalComment"
        />
      </FormItem>

      {/* start of hidden block for space */}
      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectContainer
          className={TradeAddPageStyles.Hidden}
          label=""
          name=""
          options={[]}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormSelectContainer
          className={TradeAddPageStyles.Hidden}
          label=""
          name=""
          options={[]}
        />
      </FormItem>

      <FormItem className={TradeAddPageStyles.FormItem}>
        <FormInputContainer
          className={TradeAddPageStyles.Hidden}
          label=""
          name=""
        />
      </FormItem>
      {/* end of hidden block for space */}

      <div className={TradeAddPageStyles.Commission}>
        <TakeCommissionCheckbox
          prefix="switched"
          commission={commissionSwitched}
          isLoading={isCommissionLoadingSwitched}
          watchFields={watchCommissionSwitchedFields}
          listOfFields={WATCHED_SWITCHED_FIELDS_FOR_COMMISSION}
        />
      </div>
    </Panel>
  );
};
