import { createContext } from 'react';

import { EMPTY_FUNCTION } from '~/constants';

import { DEFAULT_DIRTY_TABS_STATE } from './constants';
import { IMirroringRulePageContext } from './types';

export const defaultValues: IMirroringRulePageContext = {
  dirtyTabs: DEFAULT_DIRTY_TABS_STATE,
  setDirtyTabs: EMPTY_FUNCTION,
};

export const mirroringRulePageContext = createContext(defaultValues);
export const { Provider: MirroringRulePageContextProvider } =
  mirroringRulePageContext;
