import { displayErrorNotification } from '~/shared/utils';
import { IRequestArguments } from '~/types/api';

import { SessionRepository } from './session.repository';

export class SessionService {
  public async registerSession({ onError, onSuccess }: IRequestArguments) {
    try {
      await SessionRepository.registerSession();

      onSuccess?.();
    } catch (error) {
      displayErrorNotification(error);

      onError?.(error);
    }
  }
}
