const POSTFIX = '.csv';

export function getInputFileText(
  displayTable: boolean,
  allRowsUploadedSuccessfully: boolean,
  hasErrors: boolean,
) {
  switch (true) {
    case allRowsUploadedSuccessfully:
      return `Upload more ${POSTFIX}`;

    case displayTable:
    case hasErrors:
      return `Change ${POSTFIX}`;

    default:
      return `Add ${POSTFIX}`;
  }
}
