export function prepareServiceMessageToArray(
  message: string | string[] | undefined,
): string[] {
  let result: string[] = [];

  if (!message) {
    return [];
  }

  if (Array.isArray(message)) {
    result = result.concat(message);
  } else {
    result.push(message);
  }

  return result;
}
