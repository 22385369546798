import { useCallback, useMemo } from 'react';

import { interestRatesApi } from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { transformDataToSelectOptions } from '~/shared/utils';
import { Group, TGroupId } from '~/types/interestRates/interestRates';

export const useGroups = () => {
  const {
    data: groups,
    isLoading,
    isFetching,
    refetch,
  } = interestRatesApi.useGetInterestsGroupsQuery();

  const groupsMap = useMemo(() => {
    const map: Map<Group['id'], Group['name']> = new Map();

    groups?.forEach(({ id, name }) => map.set(id, name));

    return map;
  }, [groups]);

  const groupsOptions = useMemo(() => {
    if (!groups) {
      return EMPTY_ARRAY;
    }

    return [
      { label: 'Default', value: null },
      ...transformDataToSelectOptions(groups, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ];
  }, [groups]);

  const getGroupNameById = useCallback(
    (id: TGroupId) => groupsMap.get(id),
    [groupsMap],
  );

  return {
    getGroupNameById,
    groups,
    groupsMap,
    groupsOptions,
    loadingGroupList: isLoading || isFetching,
    refetch,
  };
};
