import { getApiDomain } from '~/shared/utils';

import { DOWNLOAD_RISK_ARRAYS_ENDPOINT } from '../constants';

export const createLinkToRiskArrayDownloading = (searchQuery = '') => {
  const baseURL = `https://${getApiDomain('BO')}`;
  const url = new URL(DOWNLOAD_RISK_ARRAYS_ENDPOINT, baseURL);

  if (searchQuery) {
    url.searchParams.set('search', searchQuery);
  }

  return url.toString();
};
