import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TotalFooter } from '~/shared/components/TotalFooter';
import { formatCellToCurrency } from '~/shared/utils/formatters/formatCellToCurrency';
import { formatUnderScoreFields } from '~/shared/utils/formatters/formatUnderScoreFields';
import { calculateColumnValuesSum } from '~/shared/utils/table';
import { IOrderMargin } from '~/types/accounts/metrics';

export const getColumns = (currency: string): IColumn<IOrderMargin>[] => [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ row: { values } }: ICellValue<IOrderMargin>) =>
      formatUnderScoreFields(values.type),
    Footer: TotalFooter,
  },
  {
    Header: 'Underlying',
    accessor: 'underlying',
  },
  {
    Header: 'Asset',
    accessor: 'asset',
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    Cell: formatCellToCurrency<IOrderMargin>('margin'),
    formatting: 'number',
  },
  {
    Header: 'Currency',
    accessor: 'currency',
  },
  {
    Header: `Margin, ${currency}`,
    accessor: 'convertedMargin',
    Cell: formatCellToCurrency<IOrderMargin>('convertedMargin'),
    Footer: calculateColumnValuesSum<IOrderMargin>('convertedMargin'),
    formatting: 'number',
  },
];
