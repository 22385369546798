import { apiRequest } from '~/shared/utils';
import { ICurrencyResponse } from '~/types/currencies';

import { FETCH_CURRENCIES } from './constants';

export class CurrenciesRepository {
  public static fetchCurrencies() {
    return apiRequest<ICurrencyResponse>({
      url: FETCH_CURRENCIES,
    });
  }
}
