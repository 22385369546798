import { ReactNode } from 'react';

import { useCurrentUserHasAllBrandingPermission } from '~/hooks';

import { RefreshButton } from '../RefreshButton';

import {
  StyledContainer,
  StyledTitle,
  StyledButtonsWrapper,
  StyledCloseSaveWrapper,
} from './EntryScreenActions.styled';

interface IEntryScreenActionsProps {
  addTradeButton?: ReactNode;
  addTransactionButton?: ReactNode | null;
  addTransferButton?: ReactNode;
  closeButton: ReactNode;
  isRefreshLoading?: boolean;
  navigationArrows?: ReactNode;
  onRefresh?: () => void;
  saveButton?: ReactNode;
  title: string;
}

export const EntryScreenActions = ({
  addTradeButton,
  addTransactionButton,
  addTransferButton = null,
  closeButton,
  isRefreshLoading,
  navigationArrows,
  onRefresh,
  saveButton,
  title,
}: IEntryScreenActionsProps) => {
  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();

  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>

      <StyledButtonsWrapper>
        {navigationArrows}

        <StyledCloseSaveWrapper>
          {addTransferButton}
          {currentUserHasAllBrandingPermission ? addTradeButton : null}
          {currentUserHasAllBrandingPermission ? addTransactionButton : null}
          {onRefresh ? (
            <RefreshButton onRefresh={onRefresh} disabled={isRefreshLoading} />
          ) : null}
          {saveButton}
          {closeButton}
        </StyledCloseSaveWrapper>
      </StyledButtonsWrapper>
    </StyledContainer>
  );
};
