import { useController, useFormContext } from 'react-hook-form';

import { EMPTY_ARRAY } from '~/constants';
import { IOption } from '~/types/form';

import { ButtonSelect } from './components/ButtonSelect';

interface IFormSelectContainerProps {
  label?: string;
  name: string;
  options: Array<IOption>;
  withIcon?: boolean;
}

export const FormButtonSelectContainer = ({
  label = '',
  name,
  options,
  withIcon = false,
}: IFormSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  const selectValue = value || EMPTY_ARRAY;

  const handleChange = (key: string) => () => {
    if (selectValue.includes(key)) {
      const filteredValue = selectValue.filter(
        (option: string) => key !== option,
      );
      onChange(filteredValue);
    } else {
      onChange([...selectValue, key]);
    }
  };

  return (
    <ButtonSelect
      label={label}
      value={selectValue}
      options={options}
      onChange={handleChange}
      withIcon={withIcon}
    />
  );
};
