import { PROXY_CORE_PATH, isKeyOf } from '~/shared/utils';
import { FilterLayers } from '~/types/symbolPermissions';

const layers = {
  default: 'default',
  groups: 'group',
  users: 'user',
  accounts: 'account',
  effective: 'effective',
};

export const getFetchSymbolPermissionsEndpoint = (
  layer: FilterLayers,
  params: Record<string, unknown>,
) => {
  const key = layer.toLowerCase();
  let subPath = layers.default;
  if (isKeyOf(key, layers)) {
    subPath =
      layer === FilterLayers.Groups && params.groupId === null
        ? layers.default
        : layers[key];
  }

  return `/api/v2/symbols/permissions/${subPath}`;
};

export const getFetchSymbolPermissionsTreeEndpoint = (
  layer: FilterLayers,
  params: Record<string, unknown>,
) => {
  const key = layer.toLowerCase();
  let subPath = layers.default;
  if (isKeyOf(key, layers)) {
    subPath =
      layer === FilterLayers.Groups && params.groupId === null
        ? layers.default
        : layers[key];
  }

  return `/api/v2/symbols/permissions/${subPath}/tree`;
};

export const getPostPermissionsEndpoint = (
  layer: FilterLayers,
  entity: string | null,
) => {
  const { Accounts, Users, Groups } = FilterLayers;

  if (layer === Accounts) {
    return `${PROXY_CORE_PATH}v2.1/accounts/${entity}/permissions`;
  }

  if (layer === Groups) {
    return `${PROXY_CORE_PATH}v2.1/permissions/sets/${entity}/overrides`;
  }

  if (layer === Users) {
    return `${PROXY_CORE_PATH}v2.0/users/${entity}/permissions`;
  }

  return '/api/v2/symbols/permissions/default';
};
