import { useContext } from 'react';

import { TabManagement as TabManagementContainer } from '../../../containers/TabManagement';
import { mirroringRulePageContext } from '../context/mirroringRulePageContext';

import { TABS } from './constants';

interface ITabManagementProps {
  mirroringRuleId: number;
  mirroringRuleName: string;
}

export const TabManagement = ({
  mirroringRuleId,
  mirroringRuleName,
}: ITabManagementProps) => {
  const { dirtyTabs } = useContext(mirroringRulePageContext);

  return (
    <TabManagementContainer
      componentProps={{ mirroringRuleId, mirroringRuleName }}
      dirtyTabs={dirtyTabs}
      tabs={TABS}
    />
  );
};
