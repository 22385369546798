import { ReactNode } from 'react';

import { StyledHeaderWrapper } from './MirroringRulePageTemplate.styled';

interface IMirroringRulePageTemplateProps {
  header: ReactNode;
  tabManagement: ReactNode;
}

export const MirroringRulePageTemplate = ({
  header,
  tabManagement,
}: IMirroringRulePageTemplateProps) => {
  return (
    <>
      <StyledHeaderWrapper>{header}</StyledHeaderWrapper>

      <div>{tabManagement}</div>
    </>
  );
};
