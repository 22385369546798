import { ReactNode } from 'react';

export const DEFAULT_VALUES = {
  applyExecutionScheme: null,
  autoconversionByDefault: false,
  availableCurrencies: [],
  calcInterestsNegativeFlag: null,
  calcInterestsPositiveFlag: null,
  calcOvernightsFlag: null,
  company: '',
  convertCashInto: null,
  defaultAccountType: 'Liabilities',
  forceAutoconversionCurrencies: [],
  forceConvertCashInto: null,
  isDefault: false,
  lei: null,
  name: null,
  order: null,
  underEMIR: false,
  useExcessMarginFee: null,
  useFreemoneyPremium: null,
};

export const TOOLTIP_TEXT: Record<
  string,
  Array<[string, string | ReactNode]>
> = {
  GENERAL_INFO: [
    ['Name', "Legal Entity's Name"],
    [
      'Company',
      'The field is\u00A0used to\u00A0add to\u00A0the transaction body',
    ],
    [
      'LEI',
      "Legal Entity's unique identifier. Used in\u00A0UTI numeration for trades",
    ],
    ['Order', "Legal Entity's order number"],
    ['Is default', 'Pointer on\u00A0default\u00A0LE'],
    [
      'Default account type',
      <>
        Default value for <i>Account type</i> filed on&nbsp;this&nbsp;LE account
        creating. Unless otherwise specified
      </>,
    ],
  ],
  AUTOCONVERSION_SETTINGS: [
    [
      'Autoconversion by default',
      <>
        Describes special behaviour in&nbsp;2&nbsp;main cases:
        <ul>
          <li>
            The window of&nbsp;manual transaction injection, default setting
            of&nbsp;checkbox <i>Use autoconverions</i> for all transaction types
            that have setting <i>Is&nbsp;autoconverstion allowed</i>{' '}
            as&nbsp;true and for accounts with enabled automatic conversion.
          </li>
          <li>
            In&nbsp;Core, default value&nbsp;of <i>Use auto cash conversion</i>{' '}
            field for manual transaction w/o direct specifying
            of&nbsp;it&rsquo;s value in&nbsp;transaction params. Again, only for
            accounts with enabled automatic conversion and only for transaction
            types that have setting
            <i>Is&nbsp;autoconverstion allowed</i> as&nbsp;true
          </li>
        </ul>
      </>,
    ],
    [
      'Convert cash into',
      <>
        Default value for <i>Convert cash into</i> filed on&nbsp;this&nbsp;LE
        account creating. Unless otherwise specified. Itself being set
        up&nbsp;on&nbsp;account means enabling automatic conversion
      </>,
    ],
    [
      'Force autoconversion currencies',
      <>
        Transactions in&nbsp;the currencies specified in&nbsp;the{' '}
        <i>Force autoconversion currencies</i> list will be&nbsp;automatically
        converted into the <i>Force convert cash into</i> currency. But under
        such extra conditions:
        <ul>
          <li>
            account&apos;s setting <i>Disable forced conversion</i> should
            be&nbsp;set up&nbsp;in&nbsp;false;
          </li>
          <li>
            account shouldn&apos;t have automatic conversion enabled (through
            setting <i>Convert cash into</i>).
          </li>
        </ul>
      </>,
    ],
    [
      'Force convert cash into',
      <>
        Currency to&nbsp;which automatic conversion will be&nbsp;performed from
        the currencies in&nbsp;the <i>Force autoconversion currencies</i> list
      </>,
    ],
  ],
  EXECUTION_SETTINGS: [
    [
      'Apply execution scheme',
      'Setting switch on/off automatic execution scheme usage',
    ],
    [
      'Available currencies',
      <>
        Default value for <i>Available currencies</i> filed on&nbsp;this&nbsp;LE
        account creating. Unless otherwise specified. On&nbsp;account level this
        list restricts possible transfer currencies. Empty list means all
        currencies are prohibited. Null means use list from Core main config.
        For accounts with <i>Payment</i> type all currencies are available
      </>,
    ],
  ],
  REGULATORY_REPORTING: [
    [
      'Under EMIR',
      <>
        Setting switch on/off for trades UTI numeration (from <i>LEI</i> field)
      </>,
    ],
  ],
};
