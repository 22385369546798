import Big from 'big.js';

import { CommissionRulesAsArray, CommissionTotal } from '~/types/commissions';

/**
 * Calculates the total sum of 'percent' and 'fixed' commission rates
 *
 * @param {Object} commissionRules - The object containing commission types and their rules.
 * @returns {Object} An object with the total sum of 'percent' and 'fixed' rates.
 *
 * @example
 * const commissionRules = {
 *     "rebate": [
 *         {
 *             "rate": "7",
 *             "rule": "percent",
 *             "name": "rebate"
 *         },
 *         {
 *             "rate": "0.9",
 *             "rule": "fixed",
 *             "name": "rebate3"
 *         }
 *     ],
 *     "exchange": [
 *         {
 *             "rate": "0.6",
 *             "rule": "fixed",
 *             "name": "exchange"
 *         },
 *     ],
 *     "internal": [
 *         {
 *             "rate": "0.8",
 *             "rule": "fixed",
 *             "name": "internal"
 *         },
 *     ],
 * };
 * const output = getTotal(commissionRules);
 * console.log(output);
 * // Output object:
 * const output = {
 *    "percent": "7",
 *    "fixed": "2.3",
 * };
 */
export function getTotal(
  commissionRules: CommissionRulesAsArray,
): CommissionTotal {
  const result = {
    percent: new Big(0),
    fixed: new Big(0),
  };

  Object.values(commissionRules).forEach((commissionArray) => {
    commissionArray.forEach(({ rule, rate }) => {
      result[rule] = result[rule].plus(new Big(rate));
    });
  });

  return {
    percent: result.percent.toString(),
    fixed: result.fixed.toString(),
  };
}
