import { createContext, Dispatch } from 'react';

import { TTokenType } from '~/types/users';

import type {
  IStatePasswordManager,
  TActionsPasswordManagementState,
} from './types';

export const TOKEN_TYPES_OPTIONS: Map<
  'password' | 'sms' | 'email' | 'totp',
  { value: TTokenType; label: string }
> = new Map([
  ['password', { value: 'password', label: 'Password' }],
  ['sms', { value: 'sms', label: 'SMS' }],
  ['email', { value: 'email', label: 'E-mail' }],
  ['totp', { value: 'totp', label: 'One Time Password' }],
]);

export const DEFAULT_VALUES = {
  name: '',
  type: TOKEN_TYPES_OPTIONS.get('password')?.value,
  value: '',
  revalue: '',
};

export const INITIAL_STATE: IStatePasswordManager = {
  initial: {
    authFlow: {
      firstStep: new Set(),
      secondSteps: new Set(),
    },
    password: [],
    sms: [],
    email: [],
    totp: [],
  },
  data: {
    authFlow: {
      firstStep: new Set(),
      secondSteps: new Set(),
    },
    password: [],
    sms: [],
    email: [],
    totp: [],
  },
  dictIdName: {},
  services: [],
  disableSaveButton: true,
};

export enum ActionTypes {
  ADD_TOKEN = 'ADD_TOKEN',
  DELETE_TOKEN = 'DELETE_TOKEN',
  EDIT_TOKEN = 'EDIT_TOKEN',
  SET_INITIAL = 'SET_INITIAL',
  UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN',
  UPDATE_INITIAL_AUTH = 'UPDATE_INITIAL_AUTH',
  UPDATE_INITIAL_TOKENS = 'UPDATE_INITIAL_TOKENS',
  UPDATE_FINISH = 'UPDATE_FINISH',
}

export const PasswordManagementContext = createContext<
  [IStatePasswordManager, Dispatch<TActionsPasswordManagementState>]
>([INITIAL_STATE, () => {}]);
