import { useContext } from 'react';

import { useCurrency } from '~/hooks';
import { clientPageContext } from '~/pages/ClientPage/context/ClientPageContext';

import { DetailedAccountInfoContainer } from '../../DetailedAccountInfoContainer';
import { DetailedClientSummary } from '../DetailedClientSummary';

import { initialAccountSummary } from './constants';

interface INoAccountIDsWrapperProps {
  accountIDs: string[];
}

export const NoAccountIDsWrapper = ({
  accountIDs,
}: INoAccountIDsWrapperProps) => {
  const currency = useCurrency();
  const {
    clientAccountsSummary: {
      filters: { date, setDate, resetDate },
    },
  } = useContext(clientPageContext);

  if (accountIDs.length) {
    return <DetailedClientSummary accountIDs={accountIDs} />;
  }

  return (
    <DetailedAccountInfoContainer
      accountSummary={initialAccountSummary}
      date={date}
      setDate={setDate}
      resetDate={resetDate}
      currency={currency}
    />
  );
};
