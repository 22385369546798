import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

import { INTER_COMMODITY_ENDPOINT } from './constants';

export const interCommodityApi = createApi({
  reducerPath: 'interCommodityApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['InterCommodity', 'InterCommodityById'],
  endpoints: (builder) => ({
    getInterCommodity: builder.query({
      query: () => ({
        url: INTER_COMMODITY_ENDPOINT,
      }),
      providesTags: ['InterCommodity'],
    }),
    getInterCommodityById: builder.query({
      query: (id) => ({
        url: `${INTER_COMMODITY_ENDPOINT}/${id}`,
      }),
      providesTags: ['InterCommodityById'],
    }),
    addInterCommodity: builder.mutation({
      query: (data) => ({
        url: INTER_COMMODITY_ENDPOINT,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['InterCommodity'],
    }),
    updateInterCommodity: builder.mutation({
      query: ({ id, data }) => ({
        url: `${INTER_COMMODITY_ENDPOINT}/${id}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['InterCommodity'],
    }),
    deleteInterCommodity: builder.mutation({
      query: (id) => ({
        url: `${INTER_COMMODITY_ENDPOINT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InterCommodity'],
    }),
  }),
});

export const {
  useAddInterCommodityMutation,
  useDeleteInterCommodityMutation,
  useGetInterCommodityQuery,
  useLazyGetInterCommodityQuery,
  useLazyGetInterCommodityByIdQuery,
  useUpdateInterCommodityMutation,
} = interCommodityApi;
