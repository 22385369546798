import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useSubscribeOnEvent } from '~/hooks/useSubscribeOnEvent';
import { bookmarkMapper } from '~/resources/bookmarks/bookmarks.mappers';
import { useLazyGetBookmarkQuery } from '~/store/bookmark';
import { TBookmarks } from '~/types/bookmarks';

import { getDefaultBookmark } from './helpers';
import { IBookmarkLoadedProps, IBookmarkLoadedResponseProps } from './types';

export const useBookmarkData = ({
  tableId,
  pageName,
}: IBookmarkLoadedProps): IBookmarkLoadedResponseProps => {
  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [fetchBookmark] = useLazyGetBookmarkQuery();
  const [activeBookmark, setActiveBookmark] = useState<TBookmarks | null>(null);
  const [loading, setLoading] = useState(false);

  const clearSavedFilters = useCallback(() => {
    const lsKey = `${tableId}-view-params`;
    localStorage.removeItem(lsKey);
  }, [tableId]);

  const handleChangeBookmark = useCallback(
    (event: unknown) => {
      const detail = get(event, 'detail', {});
      const onBookmarkChange = get(detail, 'onBookmarkChange', () => {});
      const linkTo = get(detail, 'selectedBookmark.linkTo', '');

      const searchParams = new URLSearchParams(linkTo);
      const bookmark = searchParams.get('bookmark');

      if (!bookmark) {
        setLoading(true);
        setActiveBookmark(null);
      }

      clearSavedFilters();
      setSearchParams('');

      onBookmarkChange();

      setLoading(false);
    },
    [clearSavedFilters, setSearchParams],
  );

  useSubscribeOnEvent('onChangeBookmark', handleChangeBookmark);

  const getInitialBookmark = useCallback(async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(search);
      const bookmark = searchParams.get('bookmark');

      if (!bookmark) {
        return;
      }
      const { data: response } = await fetchBookmark(Number(bookmark));

      if (response) {
        setActiveBookmark(bookmarkMapper(response));
      }
    } finally {
      setLoading(false);
    }
  }, [fetchBookmark, search]);

  useEffect(() => {
    getInitialBookmark();
  }, [getInitialBookmark]);

  const getSelectedBookmark = useCallback(() => {
    if (activeBookmark) {
      return activeBookmark;
    }
    return getDefaultBookmark(pageName);
  }, [activeBookmark, pageName]);

  return {
    isLoading: loading,
    selectedBookmark: getSelectedBookmark(),
  };
};
