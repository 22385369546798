import { get, pick, set } from 'lodash';
import { Notification } from 'react-ui-kit-exante';

import { EMPTY_ARRAY, MAX_REQUEST_LIMIT } from '~/constants';
import { symbolPermissionsService } from '~/resources';
import { DEFAULT_SYMBOLS_PERMISSIONS_SET } from '~/resources/symbolPermissions/constants';
import { ISymbolTreeStructure } from '~/types/symbolPermissions';

import { ActionTypes } from '../../../constants';
import {
  ICommonPropertiesSymbolPermissions,
  ISymbolPermissions,
} from '../../../types';

export async function getAndInsertInstrumentsToNode({
  changedPermissions,
  dispatch,
  expanded,
  filters,
  matchIdAndPositionInNonSearchTable,
  permissionsByPath,
  refreshQueue,
  row,
  setTableData,
  skip,
  updateMatchIdAndPositionInNonSearchTable,
  withExpired,
}: Pick<
  ICommonPropertiesSymbolPermissions,
  | 'changedPermissions'
  | 'dispatch'
  | 'expanded'
  | 'filters'
  | 'matchIdAndPositionInNonSearchTable'
  | 'permissionsByPath'
  | 'setTableData'
  | 'skip'
  | 'updateMatchIdAndPositionInNonSearchTable'
  | 'withExpired'
> & {
  refreshQueue: Set<string>;
  row: Omit<Partial<ICommonPropertiesSymbolPermissions['row']>, 'original'> & {
    original: Partial<ICommonPropertiesSymbolPermissions['row']['original']> & {
      id: string;
    };
    id: string;
  };
}): Promise<ISymbolTreeStructure[] | void> {
  if (row.original.id) {
    const shouldExpandRowAfterFirstInstruments = skip === 0;

    const {
      select: { layer },
      queryParams,
    } = filters;

    if (!queryParams) {
      return;
    }

    refreshQueue.add(row.original.id);

    const result = await symbolPermissionsService.resolveInstruments(
      row.original.id,
      changedPermissions,
      permissionsByPath,
      MAX_REQUEST_LIMIT,
      skip || 0,
      {
        ...queryParams,
        withExpired,
      },
      layer,
    );

    if (result === null) {
      Notification.error({
        title: 'Error instruments download',
      });
      return;
    }

    const { data, pagination } = result;

    const instruments = data.map((instrument) => ({
      ...instrument,
      ...(changedPermissions[instrument.id] || {}),
    }));

    if (row.original.path) {
      dispatch({
        type: ActionTypes.DOWNLOADED_PATHS_ADD,
        payload: row.original.path,
      });

      dispatch({
        type: ActionTypes.PERMISSIONS_BY_PATHS_UPDATE,
        payload: instruments.reduce<Record<string, ISymbolPermissions>>(
          (acc, instrument) => {
            acc[instrument.path] = pick(
              instrument,
              Object.keys(DEFAULT_SYMBOLS_PERMISSIONS_SET),
            ) as ISymbolPermissions;
            return acc;
          },
          {},
        ),
      });
    }

    setTableData((prevState) => {
      if (
        row.original.id &&
        prevState &&
        matchIdAndPositionInNonSearchTable.current
      ) {
        const pathToSubrows = `${
          matchIdAndPositionInNonSearchTable.current[row.original.id]
        }.subRows`;

        const subRows = get(prevState, pathToSubrows, EMPTY_ARRAY);

        updateMatchIdAndPositionInNonSearchTable({
          ...matchIdAndPositionInNonSearchTable.current,
          ...instruments.reduce<Record<string, string>>(
            (acc, instrument, index) => {
              acc[instrument.id] = `${pathToSubrows}.${index}`;
              return acc;
            },
            {},
          ),
        });

        set(prevState, pathToSubrows, [...subRows, ...instruments]);

        return [...prevState];
      }
      return prevState;
    });

    if (shouldExpandRowAfterFirstInstruments && row.toggleRowExpanded) {
      row.toggleRowExpanded();
      dispatch({
        type: ActionTypes.EXPANDED_ROWS_SET,
        payload: {
          ...expanded,
          [row.id]: true,
        },
      });
    }

    if (pagination.total > skip + MAX_REQUEST_LIMIT) {
      await getAndInsertInstrumentsToNode({
        changedPermissions,
        dispatch,
        expanded,
        filters,
        matchIdAndPositionInNonSearchTable,
        permissionsByPath,
        refreshQueue,
        row,
        setTableData,
        skip: skip + MAX_REQUEST_LIMIT,
        updateMatchIdAndPositionInNonSearchTable,
        withExpired,
      });
    }
  }
}
