import { TBookmarkAPIFilters } from './types';

const excludedFilters = new Set(['bookmark']);

export const transformFilters = (
  filters: Record<string, unknown>,
): TBookmarkAPIFilters[] => {
  return Object.entries(filters).flatMap(([name, value]) => {
    if (excludedFilters.has(name)) {
      return [];
    }

    return {
      name,
      value,
    };
  });
};
