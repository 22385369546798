import * as React from 'react';

import { DefaultThemeProvider } from '../../theme';

import { IndicatorLine } from './Indicator.styled';
import { IndicatorPart } from './IndicatorPart';
import { IIndicatorLine } from './types';

interface IIndicatorProps {
  lines: IIndicatorLine[];
  backgroundColor?: string;
  disableAnimation?: boolean;
}

export const Indicator = ({
  lines,
  backgroundColor,
  disableAnimation,
}: IIndicatorProps) => {
  return (
    <DefaultThemeProvider>
      <IndicatorLine backgroundColor={backgroundColor}>
        {lines.map((line) => (
          <IndicatorPart
            key={line.value}
            {...line}
            disableAnimation={disableAnimation}
          />
        ))}
      </IndicatorLine>
    </DefaultThemeProvider>
  );
};
