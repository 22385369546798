import { IUserToken } from '~/types/users';

import { IStatePasswordManager } from '../types';

/**
 * Finds the differences between two objects containing arrays of objects.
 *
 * @param {Object} initial - The initial tokens.
 * @param {Object} data - The data tokens.
 * @returns {Object} - An object containing added, removed, and changed tokens for each type.
 */
export function findDifferencesTokens(
  initial: Omit<IStatePasswordManager['initial'], 'authFlow'>,
  data: Omit<IStatePasswordManager['data'], 'authFlow'>,
) {
  const added: IUserToken[] = [];
  const removed: IUserToken[] = [];
  const changed: IUserToken[] = [];

  const types = Object.keys(data) as (keyof Omit<
    IStatePasswordManager['initial'],
    'authFlow'
  >)[];

  types.forEach((type) => {
    data[type].forEach((dataToken) => {
      if (
        !initial[type].some((initialToken) => initialToken.id === dataToken.id)
      ) {
        added.push(dataToken);
      }
    });

    // Find removed objects
    initial[type].forEach((initialToken) => {
      if (!data[type].some((dataToken) => dataToken.id === initialToken.id)) {
        removed.push(initialToken);
      }
    });

    // Find changed objects
    data[type].forEach((dataToken) => {
      const initialItem = initial[type].find(
        (initialToken) => initialToken.id === dataToken.id,
      );

      // check on changed password
      if (dataToken.type === 'password' && dataToken.value && initialItem) {
        changed.push(dataToken);
        // check on changed another token
      } else if (initialItem && initialItem.name !== dataToken.name) {
        changed.push(dataToken);
      }
    });
  });

  return { added, removed, changed };
}
