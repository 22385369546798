export const COLUMNS = [
  {
    Header: 'id',
    accessor: 'id',
    width: 100,
    disableSortBy: true,
  },
  {
    Header: 'Underlying₁',
    accessor: 'underlying1',
    width: 140,
    disableSortBy: true,
  },
  {
    Header: 'Δ₁',
    accessor: 'deltaRatio1',
    width: 80,
    disableSortBy: true,
  },
  {
    Header: 'Underlying₂',
    accessor: 'underlying2',
    width: 140,
    disableSortBy: true,
  },
  {
    Header: 'Δ₂',
    accessor: 'deltaRatio2',
    width: 80,
    disableSortBy: true,
  },
  {
    Header: 'Spread credit',
    accessor: 'spreadCredit',
    width: 160,
    disableSortBy: true,
  },
  {
    Header: 'Same leg side',
    accessor: 'sameLegSide',
    width: 160,
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Tier FF',
    accessor: 'tierFrontFront',
    width: 100,
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Tier FB',
    accessor: 'tierFrontBack',
    width: 100,
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Tier BB',
    accessor: 'tierBackBack',
    width: 100,
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    width: 140,
    editParams: {
      editable: true,
    },
  },
];

export const DEFAULT_SORT_BY = [{ id: 'priority' }];

export const FIELDS_FOR_POST = [
  'deltaRatio1',
  'deltaRatio2',
  'priority',
  'sameLegSide',
  'spreadCredit',
  'tierBackBack',
  'tierFrontBack',
  'tierFrontFront',
  'underlying1',
  'underlying2',
];
