export const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 80,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Apply execution scheme',
    accessor: 'applyExecutionScheme',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 210,
  },
  {
    Header: 'Autoconversion by default',
    accessor: 'autoconversionByDefault',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 240,
  },
  {
    Header: 'Available currencies',
    accessor: 'availableCurrencies',
    disableSortBy: true,
    width: 210,
  },
  {
    Header: 'Calc interests negative flag',
    accessor: 'calcInterestsNegativeFlag',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 250,
  },
  {
    Header: 'Calc interests positive flag',
    accessor: 'calcInterestsPositiveFlag',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 240,
  },
  {
    Header: 'Calc overnights flag',
    accessor: 'calcOvernightsFlag',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 195,
  },
  {
    Header: 'Company',
    accessor: 'company',
  },
  {
    Header: 'Convert cash into',
    accessor: 'convertCashInto',
    disableSortBy: true,
    width: 175,
  },
  {
    Header: 'Default account type',
    accessor: 'defaultAccountType',
    width: 190,
  },
  {
    Header: 'Force autoconversion currencies',
    accessor: 'forceAutoconversionCurrencies',
    disableSortBy: true,
    width: 290,
  },
  {
    Header: 'Force convert cash into',
    accessor: 'forceConvertCashInto',
    disableSortBy: true,
    width: 215,
  },
  {
    Header: 'Is default?',
    accessor: 'is_default',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 120,
  },
  {
    Header: 'Lei',
    accessor: 'lei',
    width: 250,
  },
  {
    Header: 'Order',
    accessor: 'order',
    width: 100,
  },
  {
    Header: 'Under EMIR',
    accessor: 'underEMIR',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 120,
  },
  {
    Header: 'Use excess margin fee',
    accessor: 'useExcessMarginFee',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 200,
  },
  {
    Header: 'Use freemoney premium',
    accessor: 'useFreemoneyPremium',
    type: 'checkbox',
    disableFilters: true,
    disableSortBy: true,
    width: 200,
  },
];
