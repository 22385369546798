import { IColumn } from 'react-ui-kit-exante';

import { TUserAccessRightsRow } from '~/types/users';

import { FormCheckboxCell } from '../../../../form/FormCheckboxCell';

export const UserAccessRightsCell = ({
  operationType,
  props,
}: {
  operationType: 'read' | 'write';
  props: IColumn<TUserAccessRightsRow>;
}) => {
  const {
    values: { operation },
  } = props.row;
  const defaultValue = props.row.values[operationType];
  const isDisabled = props.row.original.actions.disabledFields[operationType];

  return (
    <FormCheckboxCell
      disabled={isDisabled}
      {...props.row}
      defaultValue={defaultValue}
      name={`accessRights.${operation}.values.${operationType}`}
    />
  );
};
