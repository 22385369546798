import { LimitsState } from '~/types/limits';

export const INITIAL_GLOBAL_CASH = {
  formValues: {
    negativeGlobalCashLimit: null,
    positiveGlobalCashLimit: null,
  },
  isValid: false,
  isDirty: false,
  dataForResetFormAfterSuccessSubmit: null,
};

export const INITIAL_STATE: LimitsState = {
  changedLimits: {
    nodes: {},
    instruments: {},
  },
  tree: {
    initial: [],
    standardMode: [],
    searchMode: [],
  },
  pathByPositionInTree: {
    initial: {},
    standardMode: {},
    searchMode: {},
  },
  expandedRows: {},
  downloadedPaths: [],
  refreshMode: {
    isEnabled: false,
    tree: [],
  },
  sets: {},
  globalCash: structuredClone(INITIAL_GLOBAL_CASH),
};
