import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { IOption, SelectedListWithSearch } from 'react-ui-kit-exante';

import {
  useGetCoreAccountQuery,
  useGetCurrentUserAccessRightsQuery,
} from '~/api';
import { ACCOUNT_FORM } from '~/pages/AccountPage/constants';
import { accountPageContext } from '~/pages/AccountPage/context';
import { SaveButton, TabContentHeader } from '~/shared/components';
import { ICard } from '~/types/cardsManagement';

import { CardFormContainer } from './CardFormContainer';
import { CardManagementTemplate } from './CardManagementTemplate';
import {
  createCardsSubmitHandler,
  createDefaultCard,
  getValidationSchema,
  prepareCardsOptions,
} from './helpers';
import { ICardForm } from './types';

interface ICardsManagementContainerProps {
  cards: ICard[];
  accountId: string;
}

export const CardsManagementContainer = ({
  cards,
  accountId,
}: ICardsManagementContainerProps) => {
  const { setDirtyTabs } = useContext(accountPageContext);
  const previousCards = useRef(cards);

  const { data: currentUserPermissions } = useGetCurrentUserAccessRightsQuery();

  const hasCardAccess =
    currentUserPermissions?.write['Account info: card settings'];

  const validationSchema = useMemo(
    () => getValidationSchema(previousCards),
    [],
  );
  const defaultFormValues = useMemo<ICardForm>(() => ({ cards }), [cards]);
  const formInstance = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const { formState, handleSubmit, getValues, reset, control } = formInstance;
  const { isSubmitting, isDirty, isSubmitSuccessful } = formState;

  const [selectedCardIndex, setSelectedCardIndex] = useState('');
  const { data: account } = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );
  const watchedFields = useWatch({ control, name: 'cards' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cards',
    keyName: '_id',
  });
  const numericIndex =
    selectedCardIndex === '' ? -1 : Number(selectedCardIndex);

  const cardsOptions = prepareCardsOptions(watchedFields);
  const defaultCard = useMemo(() => createDefaultCard(account?.id), [account]);

  const resetSelectedCardIndex = () => setSelectedCardIndex('');
  const handleCloseCard = () => {
    const values = getValues('cards');

    // if close existing card, reset fields of this card
    if (values[numericIndex].id && isDirty) {
      reset({
        cards: values.map((item, index) =>
          index === numericIndex ? cards[index] : item,
        ),
      });
    }

    resetSelectedCardIndex();
  };
  const handleChangeCard = (_: IOption, id: string) => setSelectedCardIndex(id);
  const handleAddCard = () => {
    append(defaultCard as ICard);
    setSelectedCardIndex(fields.length.toString());
  };

  const handleDeleteCard = () => {
    remove(numericIndex);
    resetSelectedCardIndex();
  };

  const handleCardsSubmit = createCardsSubmitHandler(
    previousCards,
    reset,
    accountId,
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [getValues, reset, isSubmitSuccessful]);

  useEffect(() => {
    setDirtyTabs('cards', isDirty);
  }, [isDirty, setDirtyTabs]);

  return (
    <section
      className={cn({
        Disabled: !hasCardAccess,
      })}
    >
      <FormProvider {...formInstance}>
        <form id={ACCOUNT_FORM} onSubmit={handleSubmit(handleCardsSubmit)}>
          <CardManagementTemplate
            header={
              <TabContentHeader
                title="Cards"
                actions={
                  <SaveButton
                    type="submit"
                    disabled={isSubmitting || !isDirty}
                  />
                }
              />
            }
            onAdd={handleAddCard}
            search={
              <SelectedListWithSearch
                options={cardsOptions}
                selected={selectedCardIndex}
                onListItemClick={handleChangeCard}
              />
            }
            cardForm={
              <CardFormContainer
                fields={fields}
                selectedCardIndex={selectedCardIndex}
                onClose={handleCloseCard}
                onDelete={handleDeleteCard}
              />
            }
          />
        </form>
      </FormProvider>
    </section>
  );
};
