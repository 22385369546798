import { isArray, isString } from 'lodash';

export const transformToArray = (
  value: string | string[] | unknown,
): string[] => {
  if (isString(value)) {
    return [value];
  }

  if (isArray(value)) {
    return value;
  }

  return [];
};
