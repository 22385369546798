import { get } from 'lodash';

import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { deepSet, findMinMaxInArrayOfObjects } from '~/shared/utils';
import { IOvernightTree, IStateOvernights } from '~/types/overnights';

export function updateNodeData(
  instruments: Record<string, unknown>[],
  state: IStateOvernights,
  path: string,
) {
  const [min, max] = findMinMaxInArrayOfObjects(instruments, [
    'benchmarkNegative',
    'benchmarkPositive',
    'markupNegative',
    'markupPositive',
  ]);

  Object.keys(min).forEach((key) => {
    deepSet(
      state.table.tree,
      `${state.table.positionByIdInTree[path]}.${key}`,
      min[key] === max[key] ? min[key] : `${min[key]}–${max[key]}`,
    );
  });

  deepSet(
    state.table.tree,
    `${state.table.positionByIdInTree[path]}.nodeIsCalculated`,
    true,
  );

  const topLevelPath = path.replace(REGEX_LAST_SEGMENT_IN_PATH, '');

  const parentRow: Partial<IOvernightTree> = get(
    state.table.tree,
    `${state.table.positionByIdInTree[topLevelPath]}`,
    {},
  );

  if (parentRow.subRows?.every(({ nodeIsCalculated }) => nodeIsCalculated)) {
    updateNodeData(parentRow.subRows, state, topLevelPath);
  }
}
