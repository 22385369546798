import cn from 'classnames';
import { ReactNode } from 'react';
import { Panel } from 'react-ui-kit-exante';
import { IPanelProps } from 'react-ui-kit-exante/build/Components/Panel/types';

import FullHeightPanelStyles from './FullHeightPanel.module.css';

interface IFullHeightPanelProps extends IPanelProps {
  children: ReactNode;
}

export const FullHeightPanel = ({
  children,
  className,
  ...rest
}: IFullHeightPanelProps) => {
  return (
    <Panel className={cn(FullHeightPanelStyles.Panel, className)} {...rest}>
      {children}
    </Panel>
  );
};
