import { cloneDeep } from 'lodash';

import type { ActionTypes } from '../constants';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

interface IReduceReturn {
  password: IStatePasswordManager['data']['password'];
  sms: IStatePasswordManager['data']['sms'];
  email: IStatePasswordManager['data']['email'];
  totp: IStatePasswordManager['data']['totp'];
  dictIdName: Record<number, string>;
}

export function setInitial(
  state: IStatePasswordManager,
  payload: TPasswordManagerPayload[ActionTypes.SET_INITIAL],
) {
  const { tokens, authFlow } = payload;

  const {
    password: newPassword,
    sms: newSms,
    email: newEmail,
    totp: newTotp,
    dictIdName,
  } = tokens.reduce<IReduceReturn>(
    (acc, token) => {
      if (acc[token.type]) {
        acc[token.type].push(token);
      }

      acc.dictIdName = {
        ...acc.dictIdName,
        [token.id]: token.name,
      };

      return acc;
    },
    { password: [], sms: [], email: [], totp: [], dictIdName: {} },
  );

  const newAuthFlow = {
    firstStep: new Set([dictIdName[authFlow.firstStep]]),
    secondSteps: new Set(authFlow.secondSteps.map((id) => dictIdName[id])),
  };

  return {
    ...state,
    initial: {
      ...state.initial,
      authFlow: cloneDeep(newAuthFlow),
      password: cloneDeep(newPassword),
      sms: cloneDeep(newSms),
      email: cloneDeep(newEmail),
      totp: cloneDeep(newTotp),
    },
    data: {
      ...state.data,
      authFlow: cloneDeep(newAuthFlow),
      password: cloneDeep(newPassword),
      sms: cloneDeep(newSms),
      email: cloneDeep(newEmail),
      totp: cloneDeep(newTotp),
    },
    dictIdName,
  };
}
