import { ActionTypes } from '../constants';
import { getQueryParams } from '../helpers';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function filtersRelatedGroupSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.FILTERS_RELATED_GROUP_SET],
) {
  const select = {
    ...state.filters.select,
    group: payload,
  };
  const relatedGroup = payload;
  const queryParams = getQueryParams({ ...select, relatedGroup });

  return {
    ...state,
    filters: {
      ...state.filters,
      relatedGroup,
      select,
      queryParams,
    },
  };
}
