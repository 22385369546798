import { Loader, IconButton, ThemeColorType } from 'react-ui-kit-exante';

interface IDeleteButtonProps {
  iconColor?: ThemeColorType;
  iconSize?: number;
  loading?: boolean;
  onClick?: () => void;
}

export const DeleteButton = ({
  iconColor = 'action',
  iconSize = 24,
  loading = false,
  onClick = () => {},
  ...rest
}: IDeleteButtonProps) => {
  return (
    <span {...rest}>
      {loading ? (
        <Loader size="m" />
      ) : (
        <IconButton
          iconSize={iconSize}
          iconColor={iconColor}
          iconName="DeleteIcon"
          label=""
          onClick={onClick}
        />
      )}
    </span>
  );
};
