import { array, lazy, mixed, object, string } from 'yup';

export const getValidationSchema = () =>
  object({
    accessRights: object(),
    generalPermissions: object({
      legalEntities: mixed().test(
        'legalEntities',
        'Minimum 1 variant is required!',
        (value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }

          return !!value;
        },
      ),
      accountTypes: lazy((value) =>
        typeof value === 'string' ? string() : array().of(string()),
      ),
    }),
  });
