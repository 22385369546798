import {
  useGetCoreUserQuery,
  useGetUserClientsQuery,
  useGetUserGroupSettingsQuery,
} from '~/api';
import { IUsePageDataReturn } from '~/types/rtkQuery';

import { IDataAvailabilityState, IGeneralSettingsQueries } from './types';

const NOT_FOUND_USER = '#not_fount_user';

export const useGeneralSettingsData = (
  userId: number,
): IUsePageDataReturn<IGeneralSettingsQueries, IDataAvailabilityState> => {
  const userQuery = useGetCoreUserQuery({ id: userId });

  const userName = userQuery?.data?.username || NOT_FOUND_USER;

  const userClientsQuery = useGetUserClientsQuery(
    { userName },
    { skip: userName === NOT_FOUND_USER },
  );
  const userGroupSettingsQuery = useGetUserGroupSettingsQuery(
    { userName },
    { skip: userName === NOT_FOUND_USER },
  );

  // isFetching is needed because we should rebuild form when navigate between users by navigation arrows
  const isUserLoading = userQuery.isLoading || userQuery.isFetching;
  const isUserClientsLoading =
    userClientsQuery.isLoading || userClientsQuery.isFetching;
  const isGroupSettingsLoading =
    userGroupSettingsQuery.isLoading || userGroupSettingsQuery.isFetching;

  const isDataLoading =
    isUserLoading || isUserClientsLoading || isGroupSettingsLoading;

  const hasGeneralSettingsData = Boolean(userQuery.data);
  const hasUserClientsData = Boolean(userClientsQuery.data);
  const hasGroupSettingsData = Boolean(userGroupSettingsQuery.data);

  return {
    queries: {
      userClientsQuery,
      userGroupSettingsQuery,
      userQuery,
    },
    isDataLoading,
    dataAvailabilityState: {
      hasGeneralSettingsData,
      hasGroupSettingsData,
      hasUserClientsData,
    },
  };
};
