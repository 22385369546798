import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'react-ui-kit-exante';

import { ExpiredInstrumentsUpdateFields } from '~/api';

import {
  selectRequestsToUpdate,
  updateFields,
} from '../../expiredInstrumentsSlice';
import { getRequestById } from '../../helpers';
import { TableData } from '../../types';

type InputCellProps = {
  id: ExpiredInstrumentsUpdateFields;
  originalValues: TableData;
  hide?: () => boolean;
};

export const InputCell = ({ id, originalValues, hide }: InputCellProps) => {
  const dispatch = useDispatch();
  const requests = useSelector(selectRequestsToUpdate);
  const values = getRequestById(requests, originalValues);
  const [value, setValue] = useState(values?.[id] || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    dispatch(
      updateFields({
        id: originalValues.optionGroupId || originalValues.symbolId,
        key: id,
        value: Number(value),
        type: originalValues.optionGroupId ? 'optionGroup' : 'symbol',
      }),
    );
  };

  if (hide && hide()) {
    return null;
  }

  return (
    <div data-input-id={id} style={{ display: 'none' }}>
      <Input
        value={String(value)}
        onChange={handleChange}
        size="small"
        onBlur={handleBlur}
      />
    </div>
  );
};
