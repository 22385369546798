import { RefreshIcon } from '~/images/icons';

import { RefreshButtonStyled } from './RefreshButton.styled';

interface RefreshButtonProps {
  onRefresh: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const RefreshButton = ({
  onRefresh,
  disabled,
  type = 'button',
}: RefreshButtonProps) => {
  return (
    <RefreshButtonStyled
      disabled={disabled}
      title="Refresh"
      onClick={onRefresh}
      type={type}
    >
      <RefreshIcon />
    </RefreshButtonStyled>
  );
};
