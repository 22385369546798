import { useGetOperationTypesQuery } from '~/api/types/types.api';

export function useOperationTypesAllowedForAutoConversion() {
  const {
    data: operationTypesAllowedForAutoConversion,
    isLoading,
    isFetching,
  } = useGetOperationTypesQuery({
    isAutoConversionAllowed: true,
    withSystemOnly: false,
  });

  if (!operationTypesAllowedForAutoConversion) {
    return {
      operationTypesAllowedForAutoConversion: [],
      isOperationTypesAllowedForAutoConversionLoading: true,
    };
  }

  return {
    operationTypesAllowedForAutoConversion:
      operationTypesAllowedForAutoConversion?.values || [],
    isOperationTypesAllowedForAutoConversionLoading: isLoading || isFetching,
  };
}
