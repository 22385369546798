import { getLabelAtpPermissions } from '../../../helpers';
import { IServiceState } from '../../../types';
import { AtpPermissions } from '../AtpPermissions';

interface IServicePermissionContainerProps {
  serviceName: string;
  serviceState: IServiceState;
}

export const ServicePermissionContainer = ({
  serviceName,
  serviceState,
}: IServicePermissionContainerProps) => {
  const labelAtpPermissions = getLabelAtpPermissions(serviceState.permissions);

  if (serviceName !== 'atp') {
    return null;
  }

  return <AtpPermissions labelAtpPermissions={labelAtpPermissions} />;
};
