import { Dispatch } from '@reduxjs/toolkit';
import { IRowExpand } from 'react-ui-kit-exante';

import { MAX_REQUEST_LIMIT } from '~/constants';
import { expandedRowsUpdate } from '~/store/commissions';
import { ICommissionTree, IStateCommissions } from '~/types/commissions';

interface IGetAndInsertInstrumentsToNodeParams<T> {
  dispatch: Dispatch;
  getInstruments: T;
  path: string;
  queryParams: IStateCommissions['filters']['queryParams'];
  row?: IRowExpand<ICommissionTree>;
  skip: number;
}

export async function getAndInsertInstrumentsToNode<T>({
  dispatch,
  getInstruments,
  path,
  queryParams,
  row,
  skip,
}: IGetAndInsertInstrumentsToNodeParams<T>) {
  if (!queryParams || typeof getInstruments !== 'function') {
    return;
  }

  const shouldExpandRowAfterFirstInstruments = skip === 0;

  await getInstruments({
    path,
    limit: MAX_REQUEST_LIMIT,
    skip,
    queryParams,
  });

  if (shouldExpandRowAfterFirstInstruments && row?.toggleRowExpanded) {
    row.toggleRowExpanded();

    dispatch(expandedRowsUpdate([row.id, true]));
  }
}
