import { styled } from 'react-ui-kit-exante';

export const StyledList = styled('ul')(() => ({
  padding: 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '24px',
}));

export const StyledItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end', // Изменил на 'flex-end'
  padding: '16px',
  gap: '8px',
  width: '305px',
  height: '48px',
  background: theme?.color.bg.primary,
  border: `1px solid ${theme?.color.dropdown.border}`,
  borderRadius: '4px',
  lineHeight: '0',
}));

export const StyledName = styled('span')(({ theme }) => ({
  marginRight: 'auto',
  fontWeight: 500,
  fontSize: '15px',
  color: theme?.color.button.secondary.typo?.default,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: 1,
  whiteSpace: 'nowrap',
}));
