import { createApi } from '@reduxjs/toolkit/query/react';

import {
  baseQueryHandler,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { IOption } from '~/types/form';

import { FETCH_EXANTE_COUNTERPARTY } from './endpoints';

export const exanteCounterpartiesApi = createApi({
  reducerPath: 'exanteCounterpartiesApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getExanteCounterpartiesOptions: builder.query<IOption[], void>({
      query: () => ({
        url: FETCH_EXANTE_COUNTERPARTY,
      }),
      transformResponse: (response: Record<'values', string[]>) =>
        transformVariantsToSelectOptions(response.values),
    }),
  }),
});

export const { useGetExanteCounterpartiesOptionsQuery } =
  exanteCounterpartiesApi;
