import { ICellValue, Checkbox, IColumn } from 'react-ui-kit-exante';

import { transformVariantsToSelectOptions } from '~/shared/utils';
import { IInterestRate } from '~/types/interestRates';

import { CurrencyCell } from './components';
import { tradingDaysOptions, percentageInputProps } from './constants';
import {
  renderFigureCell,
  renderTradingDaysCell,
  transferValueToPercent,
} from './helpers';
import { ELayer } from './types';

const overrideColumn = {
  Header: 'Override',
  accessor: 'overridden',
  id: 'overridden',
  width: 100,
  Cell: ({ row: { values } }: ICellValue<IInterestRate>) => {
    return <Checkbox checked={Boolean(values.overridden)} disabled />;
  },
  type: 'checkbox',
  editParams: {
    editable: true,
    inputType: 'checkbox',
  },
};

const nonOverriddenColumns = ['negativeBenchmark', 'positiveBenchmark', 'days'];

export const columns: IColumn<IInterestRate>[] = [
  {
    Header: 'Currency',
    id: 'currency',
    accessor: 'currency',
    width: 140,
    Cell: ({ row: { values } }: ICellValue<IInterestRate>) => {
      return <CurrencyCell value={values.currency} />;
    },
  },
  {
    Header: 'Negative benchmark, %',
    id: 'negativeBenchmark',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'negativeBenchmark'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'Positive benchmark, %',
    id: 'positiveBenchmark',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'positiveBenchmark'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'Negative markup, %',
    id: 'negativeMarkup',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'negativeMarkup'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'Positive markup, %',
    id: 'positiveMarkup',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'positiveMarkup'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'Negative rebate, %',
    id: 'negativeRebate',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'negativeRebate'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'Positive rebate, %',
    id: 'positiveRebate',
    accessor: (row: IInterestRate) =>
      transferValueToPercent(row, 'positiveRebate'),
    editParams: {
      editable: true,
    },
    type: 'numberRange',
    filterInputProps: percentageInputProps,
    Cell: renderFigureCell,
    formatting: 'number',
  },
  {
    Header: 'International market days',
    id: 'days',
    accessor: 'days',
    editParams: {
      editable: true,
      inputType: 'select',
      options: tradingDaysOptions,
    },
    Cell: renderTradingDaysCell,
    type: 'select',
    filterOptions: transformVariantsToSelectOptions(tradingDaysOptions),
  },
];

export const getColumnsByLayer = (layer: ELayer) => {
  if (layer === ELayer.Groups || layer === ELayer.Accounts) {
    return columns.reduce(
      (acc: IColumn<IInterestRate>[], column: IColumn<IInterestRate>) => {
        const { id } = column;
        if (id === 'currency') {
          acc.push(column, overrideColumn);
        } else if (nonOverriddenColumns.includes(id)) {
          acc.push({
            ...column,
            editParams: {
              editable: false,
            },
          });
        } else {
          acc.push(column);
        }

        return acc;
      },
      [],
    );
  }

  return columns;
};

export const columnSets = {
  [ELayer.Default]: columns,
  [ELayer.Groups]: getColumnsByLayer(ELayer.Groups),
  [ELayer.Accounts]: getColumnsByLayer(ELayer.Accounts),
};

export const getColumnSetByLayerName = (
  layerName: keyof typeof columnSets,
  additionalColumnProps?: Record<string, unknown>,
) => {
  const set = columnSets[layerName];

  return set.map((column) => ({
    ...column,
    ...(additionalColumnProps || {}),
  }));
};
