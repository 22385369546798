import { has, get } from 'lodash';
import {
  FieldNamesMarkedBoolean,
  UseFormGetValues,
  UseFormReset,
} from 'react-hook-form';

import { IServiceResponse, IServiceResponseRTK } from '~/types/api';
import { TFormHandlerNew } from '~/types/form';

import { sendNotification } from '../../sendNotification';
import { prepareServiceMessageToArray } from '../../services';

import { isAnyFieldDirty, TDirtyTree } from './helpers';

interface ICreateFormSubmitHandlerArguments<T> {
  accountPermissionsQueryRefetch?: () => void;
  callback?: () => void;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  getValues: UseFormGetValues<T>;
  handlersMap: TFormHandlerNew<T> | null;
  reset: UseFormReset<T>;
}

interface IInternalMessages {
  success: string[];
  error: string[];
}

export function createFormSubmitHandler<T>({
  accountPermissionsQueryRefetch,
  callback,
  dirtyFields,
  getValues,
  handlersMap,
  reset,
}: ICreateFormSubmitHandlerArguments<T>) {
  return async (newData: T) => {
    const previousValues = getValues();
    const refetchList = new Set<string>();

    const dirtyKeys = Object.keys(dirtyFields) as (keyof T)[];
    const promisesQueue: Promise<{
      response: IServiceResponse<T[keyof T]> | IServiceResponseRTK<T[keyof T]>;
      moduleName: keyof T;
    }>[] = [];

    const messages: IInternalMessages = {
      success: [],
      error: [],
    };

    dirtyKeys.forEach((key) => {
      const currentModuleName = newData[key];
      // todo fix types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentModuleDirtyIndicator: TDirtyTree<T> = dirtyFields[key];

      if (
        isAnyFieldDirty(currentModuleDirtyIndicator) &&
        typeof handlersMap?.[key] === 'function'
      ) {
        refetchList.add(String(key));
        promisesQueue.push(
          handlersMap[key](currentModuleName, newData).then((result) => ({
            response: result,
            moduleName: key,
          })),
        );
      }
    });

    const responses = await Promise.allSettled(promisesQueue);

    // Somewhere here
    if (dirtyKeys[0] === 'services' && accountPermissionsQueryRefetch) {
      await accountPermissionsQueryRefetch();
    }

    const updatedData = responses.reduce((acc, curr) => {
      if (curr.status === 'fulfilled') {
        const { response, moduleName } = curr.value;
        let data;
        let responseMessages;
        // TODO when we delete all src/resources need remove 'else' block
        if (has(response, 'data.data')) {
          data = get(response, 'data.data', {});
          responseMessages = get(response, 'data.messages', {
            success: '',
            error: '',
          });
        } else if (has(response, 'error.data')) {
          data = get(response, 'error.data', {});
          responseMessages = get(response, 'error.messages', {
            success: '',
            error: '',
          });
        } else {
          data = response.data;
          responseMessages = get(response, 'messages', {
            success: '',
            error: '',
          });
        }
        if (responseMessages) {
          messages.success = messages.success.concat(
            prepareServiceMessageToArray(get(responseMessages, 'success', '')),
          );
          messages.error = messages.error.concat(
            prepareServiceMessageToArray(get(responseMessages, 'error', '')),
          );
        }
        if (data) {
          return { ...acc, [moduleName]: data };
        }
        return acc;
      }
      return acc;
    }, {} as T);

    if (messages.success.length > 0) {
      sendNotification(messages.success.join('\n'), 'success');
    }
    if (messages.error.length > 0) {
      sendNotification(messages.error.join('\n'), 'error');
    }

    const newState = { ...previousValues, ...updatedData };

    reset(newState);

    if (callback) {
      callback();
    }

    return {
      newState,
      refetchList,
    };
  };
}
