import { flatten, uniq } from 'lodash';
import { IColumn } from 'react-ui-kit-exante';

export function prepareDefaultColumnKeys(
  columns: IColumn<Record<string, unknown>>[],
  defaultColumnKeys: string[],
) {
  return columns.reduce(
    (acc, { accessor }) => ({
      ...acc,
      [accessor]: defaultColumnKeys.includes(accessor),
    }),
    {},
  );
}

export function prepareInitialSelectedColumns(
  columns: IColumn<Record<string, unknown>>[],
  tableIds: string[],
  defaultDisplayedColumns: string[],
) {
  const tablesColumnKeys = tableIds.reduce<string[][]>((acc, id) => {
    const key = localStorage.getItem(id);

    return key ? [...acc, JSON.parse(key)] : acc;
  }, []);
  const lsColumnKeys = uniq(flatten(tablesColumnKeys));

  const parsedKeys = !lsColumnKeys?.length
    ? defaultDisplayedColumns
    : lsColumnKeys;

  return prepareDefaultColumnKeys(columns, parsedKeys);
}

export function setColumnKeysToLocalStorage(
  columnIds: string[],
  columnKeys: Record<string, boolean>,
) {
  columnIds.forEach((id) => {
    const preparedColumnKeys = Object.keys(columnKeys).filter(
      (item) => columnKeys[item],
    );

    return localStorage.setItem(id, JSON.stringify(preparedColumnKeys));
  });
}
