import Big from 'big.js';

import {
  CommissionRulesAsArray,
  CommissionRulesAsObject,
  TTypeOfCommissionRule,
} from '~/types/commissions';

/**
 * Converts a structured object with commission rules grouped by type back into the original
 * object format where each rule is a property with its type included.
 * This function serves as the inverse of convertCommissionsRulesToArray, effectively reversing
 * the transformation applied by it. It takes an object where keys are commission types and
 * values are arrays of rules, and reconstructs the original object format where each rule is
 * an individual property, including the 'type' field within each rule.
 *
 * @param {Object} object - The object to be transformed, with commission types as keys
 *                         and arrays of rules as values.
 * @returns {Object} - The reconstructed original object format, where each rule is a property
 *                     named by its original key, and includes its type.
 *
 * @example
 * // Example input object
 * const input = {
 *     "rebate": [
 *         {
 *             "rate": "7",
 *             "rule": "percent",
 *             "name": "rebate"
 *         },
 *         {
 *             "rate": "0.9",
 *             "rule": "fixed",
 *             "name": "rebate3"
 *         }
 *     ],
 *     "exchange": [
 *         {
 *             "rate": "0.6",
 *             "rule": "fixed",
 *             "name": "exchange"
 *         },
 *     ],
 *     "internal": [
 *         {
 *             "rate": "0.8",
 *             "rule": "fixed",
 *             "name": "internal"
 *         },
 *     ],
 * };
 *
 * const originalFormat = convertCommissionsRulesToObject(input);
 * console.log(originalFormat);
 * // Output object will resemble the structure of the 'rules' parameter in convertCommissionsRulesToArray
 * const output = {
 *     "rebate": {
 *         "rate": "0.7",
 *         "rule": "percent",
 *         "type": "rebate"
 *     },
 *     "rebate3": {
 *         "rate": "0.9",
 *         "rule": "fixed",
 *         "type": "rebate"
 *     },
 *     "exchange": {
 *         "rate": "0.6",
 *         "rule": "fixed",
 *         "type": "exchange"
 *     },
 *     "internal": {
 *         "rate": "0.8",
 *         "rule": "fixed",
 *         "type": "internal"
 *     }
 * };
 */
export function convertCommissionsRulesToObject(
  object: CommissionRulesAsArray,
): CommissionRulesAsObject {
  return Object.entries(object).reduce<CommissionRulesAsObject>(
    (acc, [type, rules]) => {
      const ruleType = type as TTypeOfCommissionRule;

      rules.forEach(({ name, rate, rule }) => {
        acc[name] = {
          rule,
          rate:
            rule === 'percent'
              ? new Big(rate).div(100).toString()
              : String(rate),
          type: ruleType,
        };
      });

      return acc;
    },
    {},
  );
}
