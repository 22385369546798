import { useCallback, useContext, useRef } from 'react';
import { IColumn } from 'react-ui-kit-exante';

import { FilterLayers } from '~/types/symbolPermissions';

import {
  NameCell,
  OverrideCell,
  PublicCell,
  ShortCell,
  TradeCell,
  ViewCell,
} from '../components';
import {
  SymbolPermissionsContext,
  WIDTH_CELL_WITH_CHECKBOX,
  WIDTH_CELL_WITH_SELECT,
  WIDTH_NAME_CELL,
} from '../constants';

import { TSymbolPermissionsCellState } from './useHandleCellClick';

export const useColumns = (
  handleCellClick: ({
    column,
    row,
    tableState: { expanded },
    value,
    onChangeSource,
  }: TSymbolPermissionsCellState) => Promise<void>,
): IColumn<Record<string, unknown>>[] => {
  const [state] = useContext(SymbolPermissionsContext);
  const { queryParams, select } = state.filters;
  const { layer } = select;

  const prevColumns = useRef<IColumn<Record<string, unknown>>[]>([]);
  const getProps = useCallback(() => ({ layer }), [layer]);

  if (!queryParams) {
    return prevColumns.current;
  }

  const columns: IColumn<Record<string, unknown>> = [
    {
      Cell: NameCell,
      Header: 'name',
      accessor: 'displayName',
      disableSortBy: true,
      id: 'name',
      width: WIDTH_NAME_CELL,
    },
    {
      Cell: PublicCell,
      Header: 'public',
      accessor: 'public',
      disableSortBy: true,
      maxWidth: WIDTH_CELL_WITH_CHECKBOX,
      getProps,
    },
    {
      Cell: OverrideCell,
      Header: 'override',
      accessor: 'override',
      disableSortBy: true,
      maxWidth: WIDTH_CELL_WITH_CHECKBOX,
      getProps: () => ({ layer: select.layer }),
    },
    {
      Cell: ViewCell,
      Header: 'view',
      accessor: 'view',
      disableSortBy: true,
      maxWidth: WIDTH_CELL_WITH_CHECKBOX,
      getProps,
    },
    {
      Cell: TradeCell,
      Header: 'trade',
      accessor: 'trade',
      disableSortBy: true,
      maxWidth: WIDTH_CELL_WITH_CHECKBOX,
      getProps,
    },
    {
      Cell: ShortCell,
      Header: 'short',
      accessor: 'short',
      disableSortBy: true,
      getProps: () => ({ handleCellClick, layer: select.layer }),
      maxWidth:
        select.layer === FilterLayers.Groups ||
        select.layer === FilterLayers.Accounts
          ? WIDTH_CELL_WITH_SELECT
          : WIDTH_CELL_WITH_CHECKBOX,
    },
  ];

  prevColumns.current = columns;

  return columns;
};
