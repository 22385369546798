import * as Yup from 'yup';

import { Mode } from './constants';

export const getValidationSchema = (mode: Mode) => {
  if (mode === Mode.Lock) {
    return Yup.object({
      accountId: Yup.string(),
      amount: Yup.number(),
      asset: Yup.string().required('Asset is required'),
      blockedQtty: Yup.number()
        .min(1, 'Quantity should be more than 0')
        .required('Quantity is required')
        .typeError('Quantity should be a number'),
      comment: Yup.string(),
      operationType: Yup.string().required('Operation type is required'),
    });
  }

  return Yup.object({
    asset: Yup.string(),
    uuid: Yup.string(),
  });
};
