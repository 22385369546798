import cn from 'classnames';
import { Panel } from 'react-ui-kit-exante';

import { useGetCoreAccountQuery } from '~/api';
import { FormArrayContainer, FormInputContainer } from '~/containers/form';

import { getOriginatorIdsOverrideStructure } from './helpers';
import { useHasPermissionForWrite } from './hooks';
import { IOriginatorIdsProps } from './types';

export const OriginatorIds = ({ accountId, path }: IOriginatorIdsProps) => {
  const structure = getOriginatorIdsOverrideStructure(path);
  const { data: account } = useGetCoreAccountQuery(
    {
      accountId: String(accountId),
    },
    { skip: !accountId },
  );

  const accountIsRejected = accountId ? account?.status === 'rejected' : false;

  const hasPermissionFor = useHasPermissionForWrite({ accountId, path });

  if (!hasPermissionFor.read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled: accountIsRejected || !hasPermissionFor.write,
      })}
      title="Originator Ids"
    >
      <FormInputContainer label="ID" name={`${path}.originatorIds.default`} />

      <FormArrayContainer
        name={`${path}.originatorIds.overrides`}
        structure={structure}
        title="Overrides"
      />
    </Panel>
  );
};
