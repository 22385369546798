import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  useUpdateUserClientsMutation,
  useUpdateUserGroupSettingsMutation,
  useUpdateUserMutation,
} from '~/api';
import {
  useGetAccountTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetUserClientsPermissionsTypesQuery,
} from '~/api/types/types.api';
import { USER_TYPE_OPTIONS, SEX_OPTIONS } from '~/constants/options';
import { usePickUserPermissions } from '~/hooks';
import { userPageContext } from '~/pages/UserPage/context/userPageContext';
import { FixedLoader, SaveButton, TabContentHeader } from '~/shared/components';
import { OriginatorIds } from '~/shared/components/OriginatorIds';
import { RoutingTagsForm } from '~/shared/components/RoutingTagsForm';
import {
  createFormSubmitHandler,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { TFormHandlerNew } from '~/types/form';
import { IFormUserClient } from '~/types/users';

import { GeneralSettings } from '../GeneralSettings';
import { GroupSettings } from '../GroupSettings';
import { UserClients } from '../UserClients';
import { UserFormTemplate } from '../UserFormTemplate';
import { IDataAvailabilityState, TFormData } from '../types';
import { getValidationSchema } from '../validationSchema';

import { COMPANY_CLASSIFICATION_OPTIONS } from './constants';
import { createHandlersMap, getDefaultValues } from './helpers';

interface IUserGeneralSettingsFormProps extends TFormData {
  dataAvailabilityState: IDataAvailabilityState;
}

export const SettingsFormContainer = ({
  dataAvailabilityState,
  ...formData
}: IUserGeneralSettingsFormProps) => {
  const dispatch = useDispatch();
  const { user } = formData;
  const { setDirtyTabs } = useContext(userPageContext);
  const userName = user?.username || '';

  const userPermissions = usePickUserPermissions([
    'User info',
    'User info: originator ids',
    'User info: user type',
  ]);
  const hasUserInfoWriteAccess = userPermissions['User info'].write;

  const previousUserClients = useRef<IFormUserClient[] | undefined>(
    formData.userClients,
  );

  const validationSchema = useMemo(
    () => getValidationSchema(userPermissions['User info: user type'].write),
    [userPermissions],
  );
  const formInstance = useForm({
    defaultValues: getDefaultValues(formData),
    resolver: yupResolver(validationSchema),
  });

  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { data: userClientPermissionTypes } =
    useGetUserClientsPermissionsTypesQuery();

  const userClientPermissionsOptions = transformVariantsToSelectOptions(
    userClientPermissionTypes?.values,
  );
  const options = useMemo(
    () => ({
      accountTypesOptions,
      companyClassificationOptions: COMPANY_CLASSIFICATION_OPTIONS,
      legalEntitiesOptions: legalEntityOptions,
      userClientPermissionsOptions,
      userTypeOptions: USER_TYPE_OPTIONS,
      sexOptions: SEX_OPTIONS,
    }),
    [accountTypesOptions, legalEntityOptions, userClientPermissionsOptions],
  );

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = formInstance;

  // formState should be in object for useEffect https://react-hook-form.com/docs/useform/formstate
  const wrapperFormState = { formState: formInstance.formState };
  const [updateUser] = useUpdateUserMutation();
  const [updateUserClients] = useUpdateUserClientsMutation();
  const [updateUserGroupSettings] = useUpdateUserGroupSettingsMutation();

  const handlersMap = useMemo<TFormHandlerNew<TFormData> | null>(
    () =>
      createHandlersMap({
        dispatch,
        user,
        previousUserClients,
        userPermissions,
        updateUser,
        updateUserClients,
        updateUserGroupSettings,
      }),
    [
      dispatch,
      user,
      userPermissions,
      updateUser,
      updateUserClients,
      updateUserGroupSettings,
    ],
  );

  const handleFormSubmit = useMemo(() => {
    if (!hasUserInfoWriteAccess) {
      return () => {};
    }

    return createFormSubmitHandler<TFormData>({
      dirtyFields,
      handlersMap,
      reset,
      getValues,
    });
  }, [hasUserInfoWriteAccess, dirtyFields, getValues, handlersMap, reset]);

  useEffect(() => {
    setDirtyTabs('general', wrapperFormState.formState.isDirty);
  }, [setDirtyTabs, wrapperFormState.formState.isDirty]);

  if (!user) {
    return <div>No user</div>;
  }

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isSubmitting && <FixedLoader />}

        <UserFormTemplate
          clients={
            <UserClients
              dataAvailabilityState={dataAvailabilityState}
              options={options.userClientPermissionsOptions}
            />
          }
          dataAvailabilityState={dataAvailabilityState}
          general={
            <GeneralSettings
              extraData={formData.user?.info.extraData}
              hasUserInfoWriteAccess={hasUserInfoWriteAccess}
              options={options}
            />
          }
          groupSettings={
            <GroupSettings
              dataAvailabilityState={dataAvailabilityState}
              userName={userName}
            />
          }
          header={
            <TabContentHeader
              title="Info"
              actions={
                hasUserInfoWriteAccess ? (
                  <SaveButton
                    type="submit"
                    disabled={isSubmitting || !isDirty}
                  />
                ) : undefined
              }
            />
          }
          originatorIds={<OriginatorIds path="user.info" />}
          routingTags={<RoutingTagsForm name="user.info.routingTags" />}
        />
      </form>
    </FormProvider>
  );
};
