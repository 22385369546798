import { useState, useMemo, useCallback } from 'react';
import { useModal } from 'react-ui-kit-exante';

import { useAccordion } from '~/hooks';
import { LockIcon } from '~/images/icons';
import { AccordionProvider, AccordionWithContext } from '~/shared/components';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { IPosition, ILockedAsset } from '~/types/accounts';
import { IOption } from '~/types/form';

import { DEFAULT_DISPLAYED_COLUMN_KEYS, getColumns } from './columns';
import {
  AccountPositions,
  AssetTable,
  AssetTitle,
  AssetLock,
  ColumnsSelect,
} from './components';
import {
  ACCOUNT_ASSET_ACCORDION_LS_KEY,
  ACCOUNT_ASSET_TABLE_ID,
  MAX_ROWS_TO_SHOW,
  POSITIONS_CONFIG,
  TABLE_ROW_HEIGHT,
  TABLE_IDS,
} from './constants';
import {
  normalizePositions,
  omitRequiredColumns,
  setAccordionLsPrefix,
  setTableIdsPrefix,
  transformLockedAssetsToUuidOptions,
} from './helpers';
import { useSelectedColumns } from './hooks';
import { IFormData } from './types';

export interface IPositionsContainerProps {
  accountId?: string;
  closeDefault?: boolean;
  currency: string;
  idPrefix?: string;
  invalidateData?: () => void;
  positions: IPosition[];
}

export const PositionsContainer = ({
  accountId = '',
  closeDefault = false,
  currency,
  idPrefix,
  invalidateData,
  positions,
}: IPositionsContainerProps) => {
  const withLock = !!accountId;
  const [formData, setFormData] = useState<IFormData | null>(null);
  const [uuidOptions, setUuidOptions] = useState<IOption[]>([]);
  const [lockDataByUuid, setLockDataByUuid] = useState<
    Record<string, ILockedAsset>
  >({});
  const [canUnlock, setCanUnlock] = useState(false);

  const modal = useModal();
  const columns = useMemo(() => getColumns(currency), [currency]);
  const groupedPositions = useMemo(
    () => normalizePositions(positions),
    [positions],
  );
  const tableIdsWithPrefix = useMemo(
    () => setTableIdsPrefix(TABLE_IDS, idPrefix),
    [idPrefix],
  );

  const { selectedColumn, handleColumnChange, resetToDefaultColumns } =
    useSelectedColumns({
      columns,
      defaultDisplayedColumns: DEFAULT_DISPLAYED_COLUMN_KEYS,
      tableIds: tableIdsWithPrefix,
      withLock,
    });

  const { accordion, handleAccordionChange } = useAccordion({
    items: POSITIONS_CONFIG,
    lsKey: setAccordionLsPrefix(ACCOUNT_ASSET_ACCORDION_LS_KEY, idPrefix),
    closeDefault,
  });

  const displayedColumnKeys = useMemo(
    () =>
      Object.keys(selectedColumn).filter((key) => Boolean(selectedColumn[key])),
    [selectedColumn],
  );

  const columnsWithoutRequiredFields = useMemo(
    () => omitRequiredColumns(columns),
    [columns],
  );

  const setDataForModal = useCallback(
    ({ currency: rowCurrency, lock: rowLock, symbolId, type }: IPosition) => {
      setFormData({
        accountId,
        asset: type === 'CURRENCY' ? rowCurrency : symbolId || '',
      });

      setCanUnlock(!!rowLock.length);

      setLockDataByUuid(
        rowLock.reduce<Record<string, ILockedAsset>>((acc, item) => {
          acc[item.uuid] = item;

          return acc;
        }, {}),
      );

      setUuidOptions(transformLockedAssetsToUuidOptions(rowLock));
    },
    [accountId],
  );

  const modalCloseHandle = useCallback(() => {
    modal.onClose();

    setFormData(null);
    setCanUnlock(false);
    setLockDataByUuid({});
    setUuidOptions([]);
  }, [modal]);

  const displayLockModal = useCallback(
    (row: IPosition) => {
      setDataForModal(row);

      modal.onOpen();
    },
    [setDataForModal, modal],
  );

  const updateDataForModal = useCallback(
    (asset: string) => {
      const newPosition = positions.find(
        ({ symbolId, currency: positionCurreny, type }) =>
          symbolId === asset ||
          (positionCurreny === asset && type === 'CURRENCY'),
      );

      if (newPosition) {
        setDataForModal(newPosition);
      }
    },
    [setDataForModal, positions],
  );

  const rowActions = useMemo(
    () => ({
      show: true,
      additionalActions: [
        {
          label: <LockIcon />,
          onClick: displayLockModal,
          title: 'Lock',
          order: 0,
        },
      ],
    }),
    [displayLockModal],
  );

  const { assetLockOptions, assetUnlockOptions } = useMemo(() => {
    const { assetsForLock, assetsForUnLock } = positions.reduce<
      Record<'assetsForLock' | 'assetsForUnLock', string[]>
    >(
      (
        acc,
        { symbolId, currency: positionCurrency, type, lock: positionLock = [] },
      ) => {
        const asset = type === 'CURRENCY' ? positionCurrency : symbolId || '';

        acc.assetsForLock.push(asset);

        if (positionLock.length) {
          acc.assetsForUnLock.push(asset);
        }

        return acc;
      },
      {
        assetsForLock: [],
        assetsForUnLock: [],
      },
    );

    return {
      assetLockOptions: transformVariantsToSelectOptions(assetsForLock),
      assetUnlockOptions: transformVariantsToSelectOptions(assetsForUnLock),
    };
  }, [positions]);

  const tables = useMemo(
    () =>
      groupedPositions.map(({ id, title, data, iconName }) => {
        let height = data.length * TABLE_ROW_HEIGHT;

        if (data.length > MAX_ROWS_TO_SHOW) {
          height = TABLE_ROW_HEIGHT * MAX_ROWS_TO_SHOW;
        }

        return (
          <AccordionWithContext
            key={id}
            id={id}
            title={
              <AssetTitle
                title={title}
                iconName={iconName}
                count={data.length}
              />
            }
            onChange={handleAccordionChange}
          >
            <AssetTable
              columns={columns}
              currency={currency}
              displayedColumnKeys={displayedColumnKeys}
              height={height}
              positions={data}
              requiredColumnKeys={POSITIONS_CONFIG[id].requiredColumnKeys}
              rowActions={rowActions}
              tableId={`${ACCOUNT_ASSET_TABLE_ID}-${id}`}
              withLock={withLock}
            />
          </AccordionWithContext>
        );
      }),
    [
      columns,
      currency,
      displayedColumnKeys,
      groupedPositions,
      handleAccordionChange,
      rowActions,
      withLock,
    ],
  );

  return (
    <AccordionProvider value={accordion}>
      <AccountPositions
        columnsSelect={
          <ColumnsSelect
            columns={columnsWithoutRequiredFields}
            selectedColumn={selectedColumn}
            onChange={handleColumnChange}
            onReset={resetToDefaultColumns}
          />
        }
        tables={tables}
      />

      {formData ? (
        <AssetLock
          assetLockOptions={assetLockOptions}
          assetUnlockOptions={assetUnlockOptions}
          canUnlock={canUnlock}
          formData={formData}
          invalidateData={invalidateData}
          isOpened={modal.isOpened}
          lockDataByUuid={lockDataByUuid}
          onClose={modalCloseHandle}
          updateDataForModal={updateDataForModal}
          uuidOptions={uuidOptions}
        />
      ) : null}
    </AccordionProvider>
  );
};
