import { FilterValue, IColumn } from 'react-ui-kit-exante';

import { AutoCompleteSearch } from 'types/common';
import { useGetAssetTypesQuery } from '~/api/types/types.api';
import {
  transformVariantsToSelectOptions,
  transformDataToSelectOptions,
} from '~/shared/utils';
import { ITrade, TTradeColumns } from '~/types/trades';

import { TRADE_COLUMNS_PROPS } from './constants';

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: {
    executionCounterparty: string[];
    legalEntity: string[];
    settlementCounterparty: string[];
    tradeType: string[];
  };
  fetchAccounts: AutoCompleteSearch;
  fetchSymbols: AutoCompleteSearch;
  fetchUsers: AutoCompleteSearch;
  tradeColumns: TTradeColumns[];
}

export function useColumns({
  onFilter,
  onRemove,
  types: {
    executionCounterparty,
    legalEntity,
    settlementCounterparty,
    tradeType,
  },
  tradeColumns,
}: IGetColumns): IColumn<ITrade>[] {
  const { data: assetTypes } = useGetAssetTypesQuery();

  const handleFilterOptions: Record<string, unknown> = {
    executionCounterparty: transformVariantsToSelectOptions(
      executionCounterparty,
    ),
    legalEntity: transformVariantsToSelectOptions(legalEntity),
    settlementCounterparty: transformVariantsToSelectOptions(
      settlementCounterparty,
    ),
    symbolType: transformDataToSelectOptions(assetTypes?.values || [], {
      valueKey: 'type',
      labelKey: 'type',
      sort: false,
    }),
    tradeType: transformVariantsToSelectOptions(tradeType),
  };

  return tradeColumns.map((item) => {
    if (!TRADE_COLUMNS_PROPS[item.id]) {
      const capitalized = item.id.charAt(0).toUpperCase() + item.id.slice(1);
      const header = capitalized.replace(/([A-Z])/g, ' $1').trim();

      return {
        Header: header,
        accessor: item.id,
        disableFilters: true,
        disableSortBy: true,
      };
    }

    return {
      ...TRADE_COLUMNS_PROPS[item.id],
      ...(TRADE_COLUMNS_PROPS[item.id].filterOptions &&
      handleFilterOptions[item.id]
        ? {
            filterOptions: handleFilterOptions[item.id],
          }
        : {}),
      ...(TRADE_COLUMNS_PROPS[item.id].onFilter ? { onFilter } : {}),
      ...(TRADE_COLUMNS_PROPS[item.id].onRemove ? { onRemove } : {}),
    };
  });
}
