import { useEffect } from 'react';

import { EMPTY_ARRAY, EMPTY_OPTION_NULLABLE } from '~/constants';
import { useLogHandleTime, useProviders } from '~/hooks';
import { IClientTabComponentProps } from '~/pages/ClientPage/TabManagement/types';
import { StaticCenteredLoader } from '~/shared/components';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import { InfoFormContainer } from './InfoFormContainer';
import { useClientInfoData } from './hooks';

export const ClientInfoTabContainer = ({
  clientId,
  setRefetch,
}: IClientTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'client-info-entry-screen',
  );
  setStartHandleTime();

  const {
    queries: { clientQuery, clientAccountsQuery, clientUsersQuery },
    isDataLoading,
    dataAvailabilityState,
  } = useClientInfoData(clientId);
  const { clientProviders } = useProviders();

  useEffect(() => {
    const queries = [clientQuery, clientAccountsQuery, clientUsersQuery];
    const isLoading = queries.some(({ isFetching }) => isFetching);

    setRefetch('info', {
      refetch: () => Promise.all(queries.map(({ refetch }) => refetch())),
      isLoading,
    });
  }, [clientQuery, clientAccountsQuery, clientUsersQuery, setRefetch]);

  useEffect(() => {
    if (clientQuery.data && clientProviders) {
      logHandleTime();
    }
  }, [clientQuery.data, clientProviders, logHandleTime]);

  if (isDataLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }
  const clientProvidersEmptyOption = [
    EMPTY_OPTION_NULLABLE,
    ...clientProviders,
  ];

  const clientAccounts = clientAccountsQuery?.data?.accounts?.length
    ? [
        EMPTY_OPTION_NULLABLE,
        ...transformVariantsToSelectOptions(
          clientAccountsQuery.data.accounts.map(({ id }) => id),
        ),
      ]
    : EMPTY_ARRAY;

  return (
    <InfoFormContainer
      client={clientQuery.data}
      clientUsers={clientUsersQuery.data}
      clientAccounts={clientAccounts}
      clientProviders={clientProvidersEmptyOption}
      dataAvailabilityState={dataAvailabilityState}
    />
  );
};
