import { get } from 'lodash';

import { ExpiredInstrumentAction, expiredInstrumentActions } from '~/api';

import { TableData } from './types';

export const isExpiredInstrumentAction = (
  str: string,
): str is ExpiredInstrumentAction => {
  return Object.values(expiredInstrumentActions).some((t) => t === str);
};

export const getRequestById = (obj: unknown, instrument: TableData) => {
  return (
    get(obj, String(instrument.optionGroupId)) ||
    get(obj, String(instrument.symbolId))
  );
};

export const getChangeVisibility =
  (rowId: string) => (id: string, visible: boolean) => {
    const row = document.querySelector(`[data-row-id="${rowId}"]`);
    const elem = row?.querySelector<HTMLElement>(`[data-input-id="${id}"]`);

    if (elem) {
      elem.style.display = visible ? 'initial' : 'none';
    }
  };
