import { ISymbolTreeStructure } from '~/types/symbolPermissions';

export function matchingIdAndPositionInTable(
  array: ISymbolTreeStructure[],
  prefix = '',
): Record<string, string> {
  return array.reduce((acc, item, index) => {
    if (item.id) {
      return {
        ...acc,
        ...(item.subRows
          ? matchingIdAndPositionInTable(
              item.subRows,
              `${prefix}${index}.subRows.`,
            )
          : {}),
        [item.id]: `${prefix}${index}`,
      };
    }
    return acc;
  }, {});
}
