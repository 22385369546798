import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { useUpdateExpiredSymbolsMutation } from '~/api/expiredInstruments/expiredInstruments.api';
import { SaveButton } from '~/shared/components';

import {
  resetFields,
  selectRequestsToUpdate,
} from '../../expiredInstrumentsSlice';

export const SaveExpiredInstrumentsButton = () => {
  const [updateExpiredSymbols] = useUpdateExpiredSymbolsMutation();
  const requests = useSelector(selectRequestsToUpdate);
  const dispatch = useDispatch();

  const handleSave = async () => {
    const response = await updateExpiredSymbols();

    if (!('error' in response)) {
      Notification.success({ title: 'Instrument was successfully updated' });
      dispatch(resetFields());
    }
  };

  return (
    <SaveButton
      onClick={handleSave}
      disabled={Object.keys(requests).length === 0}
    />
  );
};
