import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

export const symbolsApi = createApi({
  reducerPath: 'symbolsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['SymbolIdInfo'],
  endpoints: () => ({}),
});
