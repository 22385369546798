import { IRow } from 'react-ui-kit-exante';

import { ITreeNode } from '../types';

export function canExpandDefinition(row: Partial<IRow<ITreeNode>>): boolean {
  if (row && row.original) {
    return ['node', 'root'].includes(row.original.type);
  }
  return false;
}
