import { get } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { interestRatesApi } from '~/api';
import { TInterestRatesMap } from '~/api/interestRates/types';
import { IInterestRate } from '~/types/interestRates';

import { prepareDataForUpdate } from '../../helpers';

export const useInterestRates = (preCall: () => void) => {
  const [tableData, setTableData] = useState<TInterestRatesMap>({});
  const [loading, setLoading] = useState(false);
  const { data, isLoading, isFetching, refetch } =
    interestRatesApi.useGetInterestRatesQuery();

  const [updateInterestRate] = interestRatesApi.useUpdateInterestRateMutation();

  useEffect(() => {
    if (data) {
      preCall();
      setTableData(data);
    }
  }, [data, preCall]);

  const handleUpdate = useCallback(
    async (previous: IInterestRate, updated: IInterestRate) => {
      setLoading(true);
      const preparedDataForUpdate = prepareDataForUpdate(previous, updated);
      const response = await updateInterestRate({
        payload: preparedDataForUpdate,
      });

      if ('data' in response) {
        setTableData((prevState: TInterestRatesMap) => {
          const newState = { ...prevState };
          const newCurrencyData = get(response, 'data');

          if (newCurrencyData !== null) {
            newState[previous.currency] = newCurrencyData;
          }

          return newState;
        });

        Notification.success('Interest Rate updated successfully');
      }

      setLoading(false);
    },
    [updateInterestRate],
  );

  return {
    loading: loading || isLoading || isFetching,
    data: tableData,
    onUpdate: handleUpdate,
    refetch,
  };
};
