import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useGetCoreUserQuery, useGetCurrentUserAccessRightsQuery } from '~/api';

import { TradeFormValues } from '../types';

interface IUsePresetDefaultValues {
  accountId: string;
  setValue: UseFormSetValue<TradeFormValues>;
}

/**
 * `usePresetDefaultValues` is a React hook that pre-sets the default values
 * for dropdown lists for `accountId` and `user` on Add Manual Trade page.
 *
 * `accountId` is available when navigating from an account page,
 * and `username` is always available.
 *
 * @param {Object} props - The properties passed to the hook.
 * @param {string} props.accountId - The account ID value. It's present when navigating from an account page.
 * @param {Function} props.setValue - The function to set the default values in the dropdowns.
 *
 * @example
 * usePresetDefaultValues({ accountId: '1234', username: 'john.doe', setValue: setDropdownValue });
 */
export function usePresetDefaultValues({
  accountId,
  setValue,
}: IUsePresetDefaultValues) {
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const { data: currentUserData } = useGetCoreUserQuery(
    { id: Number(userId) },
    {
      skip: !userId,
    },
  );

  const { username } = currentUserData || {};

  useEffect(() => {
    if (accountId && username) {
      setValue('nonSwitched.accountId', {
        label: accountId,
        value: accountId,
      });
      setValue('nonSwitched.user', { label: username, value: username });
      return;
    }
    if (username) {
      setValue('nonSwitched.user', { label: username, value: username });
    }
  }, [accountId, username]);
}
