import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader, Notification } from 'react-ui-kit-exante';

import { useCreateAccountMutation, useGetAccountsQuery } from '~/api';
import { useGetAccountPurposeTypesQuery } from '~/api/types/types.api';
import { BO_SUPPORTED_ACCOUNT_TYPES_OPTIONS } from '~/constants/accountTypes';
import { useAccountStatuses } from '~/hooks/useAccountStatuses';
import { getClientPageRoute } from '~/routes';
import { SaveButton, CloseButton } from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import { FormInputContainer, FormSelectContainer } from '../form';

import { AccountIdInput } from './AccountIdInput';
import { NewAccount } from './NewAccount';
import { defaultValues } from './constants';
import {
  getDefaultTemplate,
  getNextAccountId,
  getPurposeOptionsByType,
  getValidationSchema,
  prepareFormData,
  withInheritOption,
} from './helpers';
import { TParams } from './types';

export const NewAccountContainer = () => {
  const { clientId }: Record<string, string> = useParams<TParams>();
  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();
  const { accountStatusTypes, accountStatusesOptions } = useAccountStatuses();
  const [createAccount] = useCreateAccountMutation();

  const accountPurposesOptions = transformVariantsToSelectOptions(
    accountPurposeTypes?.values,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        accountPurposes: accountPurposeTypes?.values || [],
        accountStatuses: accountStatusTypes || [],
      }),
    [accountPurposeTypes, accountStatusTypes],
  );
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, setValue, watch } = formInstance;

  const clientAccountsQuery = useGetAccountsQuery(
    { clientId },
    {
      skip: !clientId,
    },
  );
  const nextAccountId = getNextAccountId(clientAccountsQuery.data?.accounts);
  const defaultTemplate = getDefaultTemplate(
    clientAccountsQuery.data?.accounts,
  );
  const setDefaultTemplate = useCallback(() => {
    if (defaultTemplate) {
      setValue('template', defaultTemplate);
    }
  }, [defaultTemplate, setValue]);

  const onSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async (values) => {
      setLoading(true);
      const defaultAccountId = `${clientId}.${nextAccountId}`;
      const prepared = prepareFormData(values, defaultAccountId);

      if (!prepared) {
        Notification.error({
          title: 'Account data is invalid',
        });
      } else {
        await createAccount({
          ...prepared,
          clientId,
        });

        clientAccountsQuery.refetch();
        navigate(getClientPageRoute(clientId));
      }

      setLoading(false);
    },
    [clientAccountsQuery, clientId, createAccount, navigate, nextAccountId],
  );

  const onClose = useCallback(() => {
    navigate(getClientPageRoute(clientId));
  }, [clientId, navigate]);

  const watchedType = watch('type');
  const isAccountPurposeDisabled = watchedType !== 'Liabilities';
  const availablePurposeOptions = watchedType
    ? getPurposeOptionsByType(
        watchedType,
        accountPurposesOptions,
        accountPurposeTypes?.values || [],
      )
    : [];

  useEffect(() => {
    setDefaultTemplate();
  }, [setDefaultTemplate]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NewAccount
          header={
            <EntryScreenActions
              closeButton={<CloseButton onClick={onClose} />}
              saveButton={loading ? <Loader /> : <SaveButton />}
              title="Create new account"
            />
          }
          fields={[
            <AccountIdInput key="accountId" clientId={clientId} />,
            <FormSelectContainer
              key="type"
              name="type"
              label="Type"
              options={withInheritOption(BO_SUPPORTED_ACCOUNT_TYPES_OPTIONS)}
            />,
            <FormSelectContainer
              disabled={isAccountPurposeDisabled}
              key="accountPurpose"
              name="accountPurpose"
              label="Purpose"
              options={availablePurposeOptions}
            />,
            <FormSelectContainer
              key="status"
              name="status"
              label="Status"
              options={accountStatusesOptions}
            />,
            <FormInputContainer
              key="template"
              name="template"
              label="Template"
            />,
          ]}
        />
      </form>
    </FormProvider>
  );
};
