import memoize from 'lodash/memoize';

import { DEFAULT_LOCALE } from '~/constants';

export type TFormatFunction = (value: number) => string;

export interface ICreateCurrencyFormatter {
  format: TFormatFunction;
}

function createCurrencyFormatter(
  locale: string,
  currency?: string,
): ICreateCurrencyFormatter {
  if (currency) {
    try {
      return new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      });
    } catch (e) {
      return {
        format: (value) =>
          `${currency} ${new Intl.NumberFormat(locale, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 20,
          }).format(value)}`,
      };
    }
  } else {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20,
    });
  }
}

function memoizeResolver(locale: string = DEFAULT_LOCALE, currency?: string) {
  return `${locale}-${currency}`;
}

export const currencyFormatter = memoize(
  createCurrencyFormatter,
  memoizeResolver,
);
