import {
  IPositionsContainerProps,
  PositionsContainer,
} from './PositionsContainer';

export const EnchantedPositionsContainer = ({
  positions,
  ...rest
}: IPositionsContainerProps) => {
  if (!positions.length) {
    return null;
  }

  return <PositionsContainer positions={positions} {...rest} />;
};
