import { combineReducers } from '@reduxjs/toolkit';

import {
  accessRightsApi,
  accountPermissionsApi,
  accountsApi,
  auditLogApi,
  commissionGroupsApi,
  commissionsApi,
  exanteCounterpartiesApi,
  executionSchemesApi,
  expiredInstrumentsApi,
  globalSummaryTasksApi,
  interCommodityApi,
  interestRatesApi,
  legalEntitiesApi,
  limitsApi,
  nonTradingCommissionsApi,
  notificationsApi,
  overnightsApi,
  riskArraysApi,
  symbolPermissionsApi,
  symbolsApi,
  tradesApi,
  transactionsApi,
  transferApi,
  typesApi,
  underlyingApi,
  usersApi,
} from '~/api';
import { expiredInstrumentsSlice } from '~/pages/ExpiredInstruments/expiredInstrumentsSlice';

import { accountQueries } from './account';
import { bookmarkQueries } from './bookmark';
import { commissionsSlice } from './commissions/commissionsSlice';
import { currenciesSlice } from './currencies/currencies.slice';
import { limitsSlice } from './limits/limitsSlice';
import { mirroringRuleQueries } from './mirroringRule';
import { nonTradingCommissionsSlice } from './nonTradingCommissions/slice';
import { overnightsSlice } from './overnights/overnightsSlice';
import { symbolsSlice } from './symbols/slice';
import { treeStructureSlice } from './treeStructure';
import { userQueries } from './user';

export const rootReducer = combineReducers({
  [accessRightsApi.reducerPath]: accessRightsApi.reducer,
  [accountPermissionsApi.reducerPath]: accountPermissionsApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [auditLogApi.reducerPath]: auditLogApi.reducer,
  [commissionGroupsApi.reducerPath]: commissionGroupsApi.reducer,
  [commissionsApi.reducerPath]: commissionsApi.reducer,
  [commissionsSlice.name]: commissionsSlice.reducer,
  [currenciesSlice.name]: currenciesSlice.reducer,
  [exanteCounterpartiesApi.reducerPath]: exanteCounterpartiesApi.reducer,
  [executionSchemesApi.reducerPath]: executionSchemesApi.reducer,
  [expiredInstrumentsApi.reducerPath]: expiredInstrumentsApi.reducer,
  [expiredInstrumentsSlice.name]: expiredInstrumentsSlice.reducer,
  [globalSummaryTasksApi.reducerPath]: globalSummaryTasksApi.reducer,
  [interCommodityApi.reducerPath]: interCommodityApi.reducer,
  [interestRatesApi.reducerPath]: interestRatesApi.reducer,
  [legalEntitiesApi.reducerPath]: legalEntitiesApi.reducer,
  [limitsApi.reducerPath]: limitsApi.reducer,
  [limitsSlice.name]: limitsSlice.reducer,
  [nonTradingCommissionsApi.reducerPath]: nonTradingCommissionsApi.reducer,
  [nonTradingCommissionsSlice.name]: nonTradingCommissionsSlice.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [overnightsApi.reducerPath]: overnightsApi.reducer,
  [overnightsSlice.name]: overnightsSlice.reducer,
  [riskArraysApi.reducerPath]: riskArraysApi.reducer,
  [symbolPermissionsApi.reducerPath]: symbolPermissionsApi.reducer,
  [symbolsApi.reducerPath]: symbolsApi.reducer,
  [symbolsSlice.name]: symbolsSlice.reducer,
  [tradesApi.reducerPath]: tradesApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [transferApi.reducerPath]: transferApi.reducer,
  [treeStructureSlice.name]: treeStructureSlice.reducer,
  [typesApi.reducerPath]: typesApi.reducer,
  [underlyingApi.reducerPath]: underlyingApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  ...accountQueries,
  ...bookmarkQueries,
  ...mirroringRuleQueries,
  ...userQueries,
});
