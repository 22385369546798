import {
  DEFAULT_POSITION,
  MINIMAL_REQUEST_BORDER_INDEX,
  MINIMUM_REQUIRED_INDEX_COUNT,
} from './constants';
import { Entity } from './types';

export const setDataPosition = <DataItem>(item: DataItem): DataItem | null =>
  item ?? null;

export function getEntity<Data>(data: Data[], cursor: number): Entity<Data> {
  if (!data || !data.length) {
    return DEFAULT_POSITION;
  }

  const previousDataItem = data[cursor - 1];
  const currentDataItem = data[cursor];
  const nextDataItem = data[cursor + 1];

  return {
    previous: setDataPosition(previousDataItem),
    current: setDataPosition(currentDataItem),
    next: setDataPosition(nextDataItem),
  };
}

/**
 * Find the number through which we will request data
 * For example cursor +/- 10% of limit we should request data
 */
export const getRequestStep = (limit: number) => Math.floor((limit * 10) / 100);

export function getAssumedPreviousIndex(cursor: number, limit: number) {
  const index = cursor - getRequestStep(limit);
  const assumed =
    index > MINIMUM_REQUIRED_INDEX_COUNT
      ? index
      : cursor - MINIMAL_REQUEST_BORDER_INDEX;

  if (assumed < 0) {
    return 0;
  }

  return assumed;
}

export function getAssumedNextIndex(cursor: number, limit: number) {
  const index = cursor + getRequestStep(limit);

  return index > MINIMUM_REQUIRED_INDEX_COUNT
    ? index
    : cursor + MINIMAL_REQUEST_BORDER_INDEX;
}

export const shouldCheckNextData = (cursor: number, limit: number) =>
  cursor > limit - getRequestStep(limit);

export const shouldCheckPreviousData = (
  cursor: number,
  skip: number,
  limit: number,
) => skip > 0 && cursor < getRequestStep(limit);
