import { Skeleton } from '../../Skeleton';

import { Wrapper } from './SkeletonUISelectedList.styled';

const skeletonOptions = [
  { width: 140, key: 1 },
  { width: 100, key: 2 },
  { width: 160, key: 3 },
  { width: 140, key: 4 },
  { width: 150, key: 5 },
  { width: 170, key: 6 },
  { width: 150, key: 7 },
  { width: 150, key: 8 },
  { width: 140, key: 9 },
  { width: 130, key: 10 },
  { width: 130, key: 11 },
  { width: 140, key: 12 },
  { width: 170, key: 13 },
  { width: 130, key: 14 },
];

export const SkeletonUISelectedList = () => (
  <Wrapper data-test-id="selected-list-skeleton">
    {skeletonOptions.map((item) => {
      const { width, key } = item;
      return (
        <Skeleton
          key={key}
          width={width}
          variant="text"
          animation="wave"
          height={30}
        />
      );
    })}
  </Wrapper>
);
