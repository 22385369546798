import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler, transformDataToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';

import { EMPTY_SCHEMA } from './constants';
import { FETCH_EXECUTION_SCHEMES } from './endpoints';
import { ExecutionSchemesResponse } from './types';

export const executionSchemesApi = createApi({
  reducerPath: 'executionSchemesApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getExecutionSchemesOptions: builder.query<IOption[], void>({
      query: () => ({
        url: FETCH_EXECUTION_SCHEMES,
      }),
      transformResponse: (response: ExecutionSchemesResponse[]) => [
        EMPTY_SCHEMA,
        ...transformDataToSelectOptions(response, {
          valueKey: 'id',
          labelKey: 'name',
          sort: true,
        }),
      ],
    }),
  }),
});

export const { useGetExecutionSchemesOptionsQuery } = executionSchemesApi;
