import { isFunction } from 'lodash';
import { useState, useReducer, useCallback, useRef } from 'react';

import { DEFAULT_LIMIT } from '../constants';

type TPaginationState = { skip: number };

class SearchResults<Key = string, Entity = unknown> extends Map<Key, Entity> {
  initialValue: Entity;

  constructor(initialValue: Entity) {
    super();
    this.initialValue = initialValue;
  }

  get = (key: Key) => {
    return super.get(key) || this.initialValue;
  };
}

export const usePagination = <Entity>(
  paginationEnabled: boolean,
  total: number,
) => {
  const searchResults = useRef(new SearchResults<string, Entity[]>([]));
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [{ skip }, dispatchSkip] = useReducer(
    (
      state: TPaginationState,
      action: {
        type: 'increment' | 'reset';
      },
    ): TPaginationState => {
      if (action.type === 'increment') {
        return {
          skip: state.skip + limit,
        };
      }
      if (action.type === 'reset') {
        return {
          skip: 0,
        };
      }

      throw Error(`Unknown action type: ${action.type}`);
    },
    {
      skip: 0,
    },
  );

  const showPagination = paginationEnabled && Math.min(skip, total) <= total;

  const loadNext = useCallback(
    async <Params extends Record<string, unknown>, T>(
      fetchNext: (params: Params) => Promise<T>,
      params: Params,
      onSuccess?: (response: T) => void,
      onError?: () => void,
    ) => {
      if (showPagination) {
        try {
          const response = await fetchNext({
            limit,
            skip,
            ...params,
          });
          dispatchSkip({ type: 'increment' });
          if (isFunction(onSuccess)) {
            onSuccess(response);
          }
        } catch (err) {
          if (isFunction(onError)) {
            onError();
          }
        }
      }
    },
    [limit, showPagination, skip],
  );

  const increaseSkip = useCallback(
    () => dispatchSkip({ type: 'increment' }),
    [],
  );

  const resetSkip = useCallback(() => dispatchSkip({ type: 'reset' }), []);

  const addToSearchResults = useCallback(
    (searchQuery: string, payload: Entity[]) => {
      const newSearchResult = searchResults.current
        .get(searchQuery)
        .concat(payload);
      searchResults.current.set(searchQuery, newSearchResult);
    },
    [],
  );

  const readSearchResults = useCallback((searchQuery: string) => {
    return searchResults.current.get(searchQuery);
  }, []);

  const clearSearchResults = useCallback((searchQuery: string) => {
    searchResults.current.set(searchQuery, []);
  }, []);

  return {
    loadNext,
    setLimit,
    limit,
    skip: Math.min(skip, total),
    showPagination,
    increaseSkip,
    resetSkip,
    addToSearchResults,
    readSearchResults,
    clearSearchResults,
  };
};
