import { IconButton, Loader } from 'react-ui-kit-exante';

import { useAccountStatuses } from '~/hooks/useAccountStatuses';
import { SaveButton } from '~/shared/components';

import { FormSelectContainer } from '../../../form';
import { FORM_ID } from '../../constants';

import {
  StyledHeader,
  StyledTitle,
  StyledStatus,
  StyledButtons,
  StyledSaveButtonContainer,
} from './UsersPermissionHeader.styled';

interface IUsersPermissionHeaderProps {
  onAdd?: () => void;
  disabledSave?: boolean;
  loading?: boolean;
}

export const UsersPermissionHeader = ({
  onAdd,
  disabledSave,
  loading = false,
}: IUsersPermissionHeaderProps) => {
  const { accountStatusesOptions } = useAccountStatuses();

  return (
    <StyledHeader>
      <StyledTitle>Users permission</StyledTitle>
      <StyledStatus>
        <FormSelectContainer
          label="Account status"
          name="status"
          options={accountStatusesOptions}
        />
      </StyledStatus>
      <StyledButtons>
        <IconButton
          label="Add"
          iconColor="action"
          iconName="AddIcon"
          onClick={onAdd}
        />
        <StyledSaveButtonContainer>
          {loading ? (
            <Loader />
          ) : (
            <SaveButton form={FORM_ID} disabled={disabledSave} />
          )}
        </StyledSaveButtonContainer>
      </StyledButtons>
    </StyledHeader>
  );
};
