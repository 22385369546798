import { useCallback, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import { TCustomEvents, IFormInputType } from '~/types/form';

interface IFormInputContainerProps {
  InputLabelProps?: Partial<Record<'shrink', boolean>>;
  className?: string;
  customEvents?: TCustomEvents;
  disableWarning?: boolean;
  disabled?: boolean;
  formatValue?: (value: string) => string;
  fullWidth?: boolean;
  label?: string;
  message?: string;
  multiline?: boolean;
  name: string;
  readonly?: boolean;
  sx?: Record<string, unknown>;
  type?: IFormInputType;
}

export const FormInputContainer = ({
  InputLabelProps = {},
  className,
  customEvents = {},
  disableWarning,
  disabled = false,
  formatValue = (value) => (value === null ? '' : value),
  fullWidth = true,
  label = '',
  message = '',
  multiline = false,
  name,
  readonly = false,
  sx = {},
  type = 'text',
}: IFormInputContainerProps) => {
  const inputMode = type === 'number' ? 'numeric' : undefined;
  const inputType = type === 'number' ? 'text' : type;

  const formContext = useFormContext();
  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error, isDirty },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name, { disabled });

  // fix for WBU-257, section "Add Manual transaction"
  useEffect(() => {
    if (!disabled) {
      onChange(value);
    }
  }, [onChange, value, disabled]);

  const { onBlur: onBlurCustomEvents } = customEvents;

  const blurHandler = useCallback(() => {
    if (onBlurCustomEvents) {
      onBlurCustomEvents(formContext);
    }

    onBlurReactHookForm();
  }, [formContext, onBlurCustomEvents, onBlurReactHookForm]);

  return (
    <Input
      InputLabelProps={InputLabelProps}
      className={className}
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      inputMode={inputMode}
      label={label}
      message={error?.message || message}
      multiline={multiline}
      name={name}
      onBlur={blurHandler}
      onChange={onChange}
      readOnly={readonly}
      sx={sx}
      type={inputType}
      value={formatValue(value)}
      warning={!disableWarning && isDirty}
    />
  );
};
