import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Modal } from 'react-ui-kit-exante';

import { EMPTY_OBJECT } from '~/constants';
import { SaveButton, TabContentHeader } from '~/shared/components';

import {
  PasswordManagementContext,
  DEFAULT_VALUES,
  ActionTypes,
} from '../constants';
import { IFormData, TPasswordFormDefaultValues } from '../types';

import { CloseButton } from './CloseButton';
import { TokenFormComponent } from './TokenFormComponent';
import { getValidationSchema } from './validationSchema';

interface ITokenFormContainerProps {
  passwordFormDefaultValues: TPasswordFormDefaultValues;
  setDisplayAddTokenForm: (value: boolean) => void;
  setPasswordFormDefaultValues: (value: TPasswordFormDefaultValues) => void;
}

export const TokenFormContainer = ({
  passwordFormDefaultValues,
  setDisplayAddTokenForm,
  setPasswordFormDefaultValues,
}: ITokenFormContainerProps) => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const [state, dispatch] = useContext(PasswordManagementContext);
  const isNewForm = isEmpty(passwordFormDefaultValues);

  const tokens = [
    ...state.data.password,
    ...state.data.email,
    ...state.data.sms,
    ...state.data.totp,
  ];
  const formInstance = useForm<IFormData>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...passwordFormDefaultValues,
    },
    resolver: yupResolver(getValidationSchema(tokens, isNewForm)),
  });

  const {
    formState: { isDirty },
    handleSubmit,
    watch,
  } = formInstance;

  const closeForm = useCallback(() => {
    setDisplayAddTokenForm(false);
    setPasswordFormDefaultValues(EMPTY_OBJECT);
  }, [setDisplayAddTokenForm, setPasswordFormDefaultValues]);

  const onSubmitHandler = useCallback(
    (data: IFormData) => {
      const { revalue, value, ...payload } = data;

      dispatch({
        type: isNewForm ? ActionTypes.ADD_TOKEN : ActionTypes.EDIT_TOKEN,
        payload:
          data.type !== 'password' && data.type !== 'totp'
            ? payload
            : {
                ...payload,
                value,
              },
      });

      closeForm();
    },
    [closeForm, dispatch, isNewForm],
  );

  const onCloseButtonClickHandler = useCallback(() => {
    if (isDirty) {
      setIsOpenedModal(true);
    } else {
      closeForm();
    }
  }, [isDirty, setIsOpenedModal, closeForm]);

  const onCloseModal = useCallback(() => {
    setIsOpenedModal(false);
  }, [setIsOpenedModal]);

  const handleConfirmCloseForm = useCallback(() => {
    setIsOpenedModal(false);
    closeForm();
  }, [setIsOpenedModal, closeForm]);

  const tokenType = watch('type');

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <TabContentHeader
          title={`${isNewForm ? 'Add' : 'Edit'} password`}
          actions={
            <>
              <CloseButton onClick={onCloseButtonClickHandler} />
              <SaveButton disabled={!isDirty} />
            </>
          }
        />

        <TokenFormComponent
          passwordFormDefaultValues={passwordFormDefaultValues}
          tokenType={tokenType}
        />

        <Modal
          isOpened={isOpenedModal}
          onClose={onCloseModal}
          title="Confirm close form"
          confirmButton={{
            handleConfirm: handleConfirmCloseForm,
          }}
        >
          <div>Do you want to close this form?</div>
        </Modal>
      </form>
    </FormProvider>
  );
};
