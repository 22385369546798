import { useCallback } from 'react';

import { useLazyGetSymbolIdInfoQuery } from '~/api';
import { IOption } from '~/types/form';

export function useBrokerClientAccounts() {
  const [fetchSymbolIdInfo] = useLazyGetSymbolIdInfoQuery();

  const changeSymbolIdHandle = useCallback(
    async (target: IOption) => {
      const { value = '' } = target || {};

      if (value) {
        await fetchSymbolIdInfo({
          symbolId: value,
          fields: ['brokerClientAccounts'],
        });
      }
    },
    [fetchSymbolIdInfo],
  );

  return changeSymbolIdHandle;
}
