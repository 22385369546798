import { IRowData, FilterLayers } from '~/types/symbolPermissions';

export const FIELDS_FOR_POST: Array<keyof IRowData> = [
  'id',
  'public',
  'short',
  'trade',
  'view',
  'override',
];

export const FIELDS_FOR_NODE = ['type', 'exchange', 'ticker'] as const;

export const WIDTH_CELL_WITH_CHECKBOX = 100;
export const WIDTH_CELL_WITH_SELECT = 200;
export const WIDTH_NAME_CELL = 200;

export const BLOCKED_MESSAGE: Record<FilterLayers, string> = {
  [FilterLayers.Default]: '',
  [FilterLayers.Accounts]: 'Please, select account',
  [FilterLayers.Users]: 'Please, select user',
  [FilterLayers.Groups]: 'Please, select group',
  [FilterLayers.Effective]: 'Please, select user and account',
};
