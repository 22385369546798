import cn from 'classnames';
import { omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  IconButton,
  OnSaveEditableRow,
  Panel,
  Select,
  Table,
  useData,
} from 'react-ui-kit-exante';

import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { usePickUserPermissions } from '~/hooks';
import { regularCommissionsService } from '~/resources';
import { commissionsTableRowMap } from '~/resources/regularCommissions/regularCommissions.mappers';
import {
  TAccountRegularCommissionsTable,
  TAccountRegularCommissionsTableRow,
  TRegularCommissions,
} from '~/resources/regularCommissions/types';
import { DeleteButton, FormItem } from '~/shared/components';
import { transformVariantsToSelectOptions, getTableId } from '~/shared/utils';
import { selectRegularCommissions } from '~/store/account';
import { IOption } from '~/types/form';
import type { TApplicationState } from '~/types/store';

import RegularCommissionsContainerStyles from './RegularCommissionsStyle.module.css';
import { columns } from './columns';

import '~/styles/Global.css';

interface IRegularCommissionsProps {
  accountId: string;
}

export const RegularCommissionsContainer = ({
  accountId,
}: IRegularCommissionsProps) => {
  const [regularCommissions, setRegularCommissions] = useState<
    TRegularCommissions[]
  >([]);
  const [selectedCommission, setSelectedCommission] =
    useState<TRegularCommissions | null>(null);

  const userPermissions = usePickUserPermissions([
    'Account info',
    'Regular commissions',
  ]);

  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'regularCommissions',
  });

  const tableId = getTableId('RegularCommissions');

  const storeRegularCommissions = useSelector((state: TApplicationState) =>
    selectRegularCommissions(state, accountId),
  );

  const regularCommissionsIsRejected =
    storeRegularCommissions?.status === 'rejected';

  const getRegularCommissionsOptions = useCallback(async () => {
    // todo duplicated request. Fix it.
    // First one here – useAccountSettingsData.ts (useGetRegularCommissionsQuery/resolveAccountRegularCommissions)
    const response =
      await regularCommissionsService.resolveRegularCommissions();
    const mapToOptions = response.map((option) => option.name);
    setRegularCommissions(response);
    return transformVariantsToSelectOptions(mapToOptions);
  }, []);

  const regularCommissionOptions = useData<IOption[] | null>({
    onFetch: getRegularCommissionsOptions,
  });

  useEffect(() => {
    regularCommissionOptions.fetchData();
    // we need to make a request only 1 time when component mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTableLoading = regularCommissionOptions.isLoading || !value;

  const handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = ({ target: { value: optionValue = '' } = {} }) => {
    if (regularCommissions) {
      const selectedValue = regularCommissions.find(
        (commission) => commission?.name === optionValue,
      );
      setSelectedCommission(selectedValue || null);
    }
  };

  const onAddCommission = () => {
    onChange([
      ...value,
      commissionsTableRowMap(null, selectedCommission, accountId),
    ]);
    setSelectedCommission(null);
  };

  const selectDisable =
    regularCommissionOptions.data?.length === 0 ||
    regularCommissionOptions.isLoading;

  const selectValue = useMemo(
    () =>
      !regularCommissionOptions.data?.length || !selectedCommission
        ? ''
        : selectedCommission.name,
    [selectedCommission, regularCommissionOptions],
  );

  const options = useMemo(
    () =>
      regularCommissionOptions.data?.map((option) => ({
        ...option,
        disabled: value
          ? (value as TAccountRegularCommissionsTable)
              .map(({ name }) => name)
              .includes(option.value)
          : false,
      })),
    [regularCommissionOptions.data, value],
  );

  const onUpdate: OnSaveEditableRow<TAccountRegularCommissionsTableRow> =
    useCallback(
      async (
        previousData: TAccountRegularCommissionsTableRow,
        updatedData: TAccountRegularCommissionsTableRow,
      ) => {
        const tableData: TAccountRegularCommissionsTable = [...value];
        const updatedIndex = tableData.findIndex(
          (val) => val.name === updatedData.name,
        );
        tableData[updatedIndex] = {
          ...omit({ ...tableData[updatedIndex], ...updatedData }, ['actions']),
        }; // todo not pass action column in ui-kit
        await onChange(tableData);
      },
      [onChange, value],
    );

  const deleteClickHandler = useCallback(
    (rowValue: TAccountRegularCommissionsTableRow) => {
      const updatedData = (value as TAccountRegularCommissionsTable).filter(
        ({ commissionId }) => commissionId !== rowValue.commissionId,
      );
      onChange(updatedData);
    },
    [onChange, value],
  );

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onUpdate,
      additionalActions: [
        {
          label: <DeleteButton iconSize={16} />,
          onClick: deleteClickHandler,
          title: 'Remove',
        },
      ],
    }),
    [deleteClickHandler, onUpdate],
  );
  const getRowProps = useCallback(() => {
    if (isDirty) {
      return { style: { color: '#EC9F0B' } };
    }

    return {};
  }, [isDirty]);

  if (!userPermissions['Regular commissions'].read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled:
          regularCommissionsIsRejected ||
          !userPermissions['Account info'].write,
      })}
      disableBodyPaddings
      title="Regular Commissions"
    >
      <FormItem withMargin withPadding>
        <Select
          label="Select commission"
          options={options || EMPTY_ARRAY}
          disabled={selectDisable}
          onChange={handleChange}
          value={selectValue}
          fullWidth
        />
        <IconButton
          iconColor="promo"
          iconName="AddIcon"
          onClick={onAddCommission}
          disabled={!selectedCommission}
          className={RegularCommissionsContainerStyles.AddButton}
        />
      </FormItem>
      <Table
        columns={columns}
        data={value || EMPTY_ARRAY}
        isLoading={isTableLoading}
        isFlexLayout
        tableId={tableId}
        disableSortBy
        noDataHeight={NO_DATA_HEIGHT}
        rowActions={rowActions}
        getRowProps={getRowProps}
        saveViewParamsAfterLeave
      />
    </Panel>
  );
};
