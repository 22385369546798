import { TServiceStateMap } from '~/containers/UserAccessRightsContainer/types';
import {
  createServiceResponse,
  displayErrorNotification,
  is403GetError,
} from '~/shared/utils';
import { IAtpPermissionsResponse } from '~/types/accountPermissions/atpPermissions';
import { TDefaultAccountAccess } from '~/types/accountPermissions/defaultAccountAccess';
import { IServiceResponse, IServiceResponseRTK } from '~/types/api';
import { IServicesParams, TServices } from '~/types/services';
import { ICoreFormUser } from '~/types/users';

import { getServicesDifference } from './helpers';
import { ServicesRepository } from './services.repository';
import { UserServices, UserServicesResponse } from './types';

export class ServicesService {
  public async getServices(): Promise<TServices | null> {
    try {
      const { data } = await ServicesRepository.fetchServices();

      return data.services;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  public async getUserServices(
    userId: number,
  ): Promise<UserServicesResponse | null> {
    try {
      const { data } = await ServicesRepository.fetchUserServices(userId);

      return data;
    } catch (error) {
      return null;
    }
  }

  public async addServicePermission(
    userName: string,
    serviceName: string,
    params: IServicesParams,
  ) {
    try {
      await ServicesRepository.addServicePermission(
        userName,
        serviceName,
        params,
      );

      return true;
    } catch (error) {
      return false;
    }
  }

  public async deleteServicePermission(userName: string, serviceName: string) {
    try {
      await ServicesRepository.deleteServicePermission(userName, serviceName);
    } catch (error) {
      console.error(`network error: ${error}`);
    }
  }

  public async addServicesPermissions(
    userName: string,
    services: Record<string, IServicesParams>,
  ) {
    const promises = Object.keys(services).map((serviceName) =>
      this.addServicePermission(userName, serviceName, services[serviceName]),
    );

    return Promise.all(promises);
  }

  public async addService(services: string[], userId: string) {
    try {
      const { data } = await ServicesRepository.addService(services, userId);

      return {
        data,
      };
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  public async deleteService(serviceBindingId: number) {
    try {
      const { data } = await ServicesRepository.deleteService(serviceBindingId);

      return {
        data,
      };
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  public async deleteServicesPermissions(
    userName: string,
    serviceNames: string[],
  ) {
    const promises = serviceNames.map((serviceName) =>
      this.deleteServicePermission(userName, serviceName),
    );

    return Promise.all(promises);
  }

  public async updateServicesPermissions(
    userName: string,
    changedServices: TServiceStateMap,
    userServices: UserServices,
    user: ICoreFormUser,
    updateAtpPermissions?: (props: {
      userId: string;
      atpPermissions: IAtpPermissionsResponse;
    }) => Promise<
      | IServiceResponse<TDefaultAccountAccess>
      | IServiceResponseRTK<TDefaultAccountAccess>
    >,
  ): Promise<IServiceResponse<TServiceStateMap>> {
    const { addedServices, deletedServices } = getServicesDifference(
      changedServices,
      user,
      userServices,
    );

    const deleteServicesPromises = deletedServices.map((serviceBindingId) =>
      this.deleteService(serviceBindingId),
    );

    const addServicesPromise = this.addService(addedServices, String(user.id));

    const { superuser, ...atpPermissions } = changedServices.atp.permissions;

    const updateAtpPermissionsPromise = updateAtpPermissions
      ? updateAtpPermissions({
          userId: userName,
          atpPermissions,
        })
      : Promise.resolve();

    const result = await Promise.all([
      updateAtpPermissionsPromise,
      addServicesPromise,
      ...deleteServicesPromises,
    ]);

    const hasError = result.find(
      (resultItem) => typeof resultItem === 'object' && 'error' in resultItem,
    );

    if (hasError) {
      return createServiceResponse({
        data: changedServices,
        messages: {
          error: 'Services permissions update failed due to an error',
        },
      });
    }

    return createServiceResponse({
      data: changedServices,
      messages: { success: 'Services permissions are successfully updated' },
    });
  }
}
