/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-loop-func */

export type TDirtyTree<T extends Record<keyof T, unknown>> =
  | boolean
  | Record<keyof T, boolean>
  | TDirtyTree<T>[]
  | { [key in keyof T]: TDirtyTree<T> };

export function isAnyFieldDirty<T>(tree: TDirtyTree<T>) {
  let isDirty = false;
  let queue: TDirtyTree<T>[] = [];

  queue.push(tree);

  while (queue.length > 0) {
    const tempNode = queue.shift();

    if (typeof tempNode === 'undefined') {
      break;
    }

    if (typeof tempNode === 'boolean') {
      if (tempNode) {
        isDirty = true;
        queue = [];
      }
      continue;
    }

    if (Array.isArray(tempNode)) {
      tempNode.forEach((item) => queue.push(item));
      continue;
    }

    Object.keys(tempNode).forEach((key) => {
      const currentItem = tempNode[key as keyof T];
      queue.push(currentItem);
    });
  }

  return isDirty;
}
