import cn from 'classnames';
import { useMemo } from 'react';

import { isExpired } from '../../utils/dates';
import { defaultLocaleFormatter } from '../../utils/formatters';
import { formatCurrency } from '../../utils/formatters/formatCurrency';

import PositiveNegativeStyles from './PositiveNegative.module.css';

type TValue = number | string | null;

interface IPositiveNegativeProps {
  value?: TValue;
  valueFormatter?(value?: TValue): string;
  expirationDate?: string | null;
}

export const PositiveNegative = ({
  value,
  valueFormatter,
  expirationDate,
}: IPositiveNegativeProps) => {
  const isPositive = useMemo(() => checkIsValuePositive(value), [value]);
  const expired = isExpired(expirationDate);

  const formatted = useMemo(
    () =>
      valueFormatter
        ? valueFormatter(value)
        : formatCurrency(defaultLocaleFormatter, value),
    [value, valueFormatter],
  );

  return (
    <mark
      className={cn(PositiveNegativeStyles.Value, {
        [PositiveNegativeStyles.Positive]: isPositive,
        [PositiveNegativeStyles.Negative]: !isPositive,
        [PositiveNegativeStyles.Expired]: expired,
      })}
    >
      {formatted}
    </mark>
  );
};

function checkIsValuePositive(value?: string | number | null) {
  const numbered = Number(value);

  return numbered >= 0;
}
