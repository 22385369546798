import { cloneDeep } from 'lodash';

import { DEFAULT_PROVIDERS, IDefaultProviders } from '~/constants';

interface IData {
  accountId: string;
  id: string;
  data: {
    type: string;
    name: string;
    accountName: string;
  };
}

export function mapperCounterParties(data: IData[]): IDefaultProviders {
  const { accountProviders, clientProviders } = data.reduce((acc, item) => {
    if (!acc.accountProviders[item.id]) {
      acc.clientProviders.push({
        value: item.id,
        label: item.data.name,
      });

      acc.accountProviders[item.id] = [];
    }

    acc.accountProviders[item.id].push({
      value: item.accountId,
      label: `${item.data.name}:${item.data.accountName}`,
    });

    return acc;
  }, cloneDeep(DEFAULT_PROVIDERS));

  return {
    accountProviders: Object.fromEntries(
      Object.entries(accountProviders).map(([key, value]) => [
        key,
        value.sort((a, b) => Number(a.label) - Number(b.label)),
      ]),
    ),
    clientProviders: clientProviders.sort(
      (a, b) => Number(a.label) - Number(b.label),
    ),
  };
}
