import { cloneDeep } from 'lodash';

import type { ActionTypes } from '../constants';
import { isEqualStates } from '../helpers';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

export function updateAuthToken(
  state: IStatePasswordManager,
  payload: TPasswordManagerPayload[ActionTypes.UPDATE_AUTH_TOKEN],
) {
  const { token, checked } = payload;

  const newState = cloneDeep(state);

  if (!checked) {
    if (token.type === 'password') {
      newState.data.authFlow.firstStep = new Set([token.name]);
    } else {
      newState.data.authFlow.secondSteps.add(token.name);
    }
  } else if (token.type !== 'password') {
    newState.data.authFlow.secondSteps.delete(token.name);
  }

  return {
    ...newState,
    disableSaveButton: isEqualStates(newState),
  };
}
