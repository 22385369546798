import { isEqual } from 'lodash';
import { useCallback, MutableRefObject } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  useCreateNonTradingCommissionMutation,
  useDeleteNonTradingCommissionMutation,
  useUpdateNonTradingCommissionMutation,
} from '~/api';

import {
  IFormNonTradingCommission,
  IRequestNonTradingCommission,
} from '../types';

interface IData {
  commissions: Array<IFormNonTradingCommission>;
}

function getCommissionForRequest(
  commission: IFormNonTradingCommission,
  id: number,
) {
  const result: IRequestNonTradingCommission = {
    groupid: id,
    name: commission.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    type: commission.type,
    value: {},
  };

  if (commission.interval) {
    result.interval = { type: commission.interval };
  }

  if (commission.isFree) {
    result.value = { fixed: 0 };
  } else {
    result.value = {
      [commission.value]: Number(commission.commission),
      ...(commission.min ? { min: Number(commission.min) } : {}),
      ...(commission.max ? { max: Number(commission.max) } : {}),
    };
  }

  return result;
}

export function useSave(
  initialFields: MutableRefObject<Array<IFormNonTradingCommission>>,
  commissionsForDelete: Set<number>,
  invalidateAndRefetch: () => void,
) {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('id');

  const [createNonTradingCommission] = useCreateNonTradingCommissionMutation();
  const [updateNonTradingCommission] = useUpdateNonTradingCommissionMutation();
  const [deleteNonTradingCommission] = useDeleteNonTradingCommissionMutation();

  return useCallback(
    async (data: IData) => {
      try {
        const putCommissions = data.commissions.filter(
          (commission, index) =>
            // commission should be in initialFields.current
            initialFields.current[index] &&
            // commission should not have nonFilledName
            !commission.nonFilledName &&
            // commission should not be in commissions for delete
            !commissionsForDelete.has(index) &&
            // commission should be different from initialFields.current
            !isEqual(commission, initialFields.current[index]),
        );

        const postCommissions = data.commissions
          .filter(
            (commission, index) =>
              // commission should not be in initialFields.current or commission have nonFilledName
              (!initialFields.current[index] ||
                (commission.nonFilledName &&
                  (commission.min ||
                    commission.max ||
                    commission.interval ||
                    commission.commission ||
                    commission.value))) &&
              // commission should not be in commissions for delete
              !commissionsForDelete.has(index),
          )
          .map((commission) =>
            getCommissionForRequest(commission, Number(groupId)),
          );

        const deleteCommissions = data.commissions
          .filter(
            (commission, index) =>
              // commission should be in initialFields.current
              initialFields.current[index] &&
              // commission should not have nonFilledName
              !commission.nonFilledName &&
              // commission should be in commissions for delete
              commissionsForDelete.has(index),
          )
          .map(({ commissionId }) => commissionId);

        if (postCommissions.length) {
          await createNonTradingCommission(postCommissions);
        }

        await Promise.all(
          putCommissions.map((commission) =>
            updateNonTradingCommission({
              commission: getCommissionForRequest(commission, Number(groupId)),
              id: commission.commissionId,
            }),
          ),
        );

        await Promise.all([
          deleteCommissions.map((commissionId) =>
            deleteNonTradingCommission(commissionId!),
          ),
        ]);

        invalidateAndRefetch();

        Notification.success({
          title: 'Success saving commissions',
        });
      } catch (error) {
        Notification.error({
          title: 'Error saving commissions',
        });
      }
    },
    [
      invalidateAndRefetch,
      commissionsForDelete,
      createNonTradingCommission,
      deleteNonTradingCommission,
      groupId,
      initialFields,
      updateNonTradingCommission,
    ],
  );
}
