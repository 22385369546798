import { keys } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  IColumn,
  IRowExpand,
  Select,
  Table,
  useTheme,
  TCellData,
  Notification,
} from 'react-ui-kit-exante';

import { usePrevious, useTableVirtualized } from '~/hooks';
import { useCopyPasteValues } from '~/pages/RiskArrays/context/CopyPasteValuesContext';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import { getTableId } from '~/shared/utils';

import RiskArraysTableStyles from './RiskArraysTable.module.css';
import { getColumns } from './columns';
import { paginationOptions } from './constants';
import { useRiskArrayContext } from './context/RiskArrayTreeContext';
import { canExpandDefinition, normalizeNumberInput } from './helpers';
import { ITreeNode } from './types';

export const RiskArraysTable = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);

  const {
    getTree,
    expandRow,
    getIconsMap,
    isLoading,
    search,
    getExpandedRows,
    loadNext,
    pagination: { showPagination, total, skip, limit, setLimit },
  } = useRiskArrayContext();
  const { getQueryToUpdate } = useCopyPasteValues();
  const theme = useTheme();

  const iconsMap = getIconsMap();
  const columns = getColumns({ iconsMap });
  const [tableData, setTableData] = useState<ITreeNode[]>([]);

  useEffect(() => {
    const tree = getTree();

    if (!isLoading) {
      setTableData(tree);
    }
  }, [getTree, isLoading]);

  const { virtualized, updateTableSizes } = useTableVirtualized(
    tableRef.current,
  );

  const handleCellClick = useCallback(
    ({
      row,
      column,
    }: {
      row: IRowExpand<ITreeNode>;
      column: IColumn<ITreeNode>;
    }) => {
      if (column.id === 'name') {
        expandRow(row);
      }
    },
    [expandRow],
  );

  const expandedRows = getExpandedRows();

  const prevTableData = usePrevious(tableData);

  const handleCellUpdate = useCallback(
    (values: TCellData<ITreeNode>[]) => {
      const [treeNode] = values;
      const { isAutoCalculated } = treeNode.row.values;
      if (isAutoCalculated) {
        Notification.warning({
          title:
            'Pasting is not allowed when IS AUTO is enabled. If you need to change Risk Array, please, disable IS AUTO checkbox',
        });
        return;
      }
      values.forEach((value) => {
        const {
          id: columnId,
          row: {
            original: { id: name },
          },
          value: newValue,
        } = value;
        const handleUpdateFunction = getQueryToUpdate(name, columnId);
        if (
          handleUpdateFunction &&
          typeof handleUpdateFunction === 'function'
        ) {
          handleUpdateFunction({
            target: { value: normalizeNumberInput(newValue) },
          });
        }
      });
    },
    [getQueryToUpdate],
  );

  useEffect(() => {
    if (
      prevTableData.length !== tableData.length ||
      (tableData.length !== 0 && virtualized.height === 0)
    ) {
      updateTableSizes();
    }
  }, [prevTableData, tableData, updateTableSizes, virtualized.height]);

  return (
    <div className={RiskArraysTableStyles.Container}>
      <WrapperLoader isLoading={isLoading}>
        {showPagination ? (
          <div
            style={{
              color: theme.color.typo.promo,
              fontSize: theme.size.text.md,
            }}
          >{`Showing: ${skip}/${total}`}</div>
        ) : null}

        <div ref={tableRef}>
          <Table<ITreeNode>
            columns={columns}
            data={tableData}
            defaultSortBy={[]}
            expanded={{
              canExpandDefinition,
              customExpandControl: true,
              listOfInitialExpandedRowKeys:
                Boolean(search) ||
                (keys(expandedRows).length > 0 ? expandedRows : false),
            }}
            handleCellClick={handleCellClick}
            isFlexLayout
            tableId={getTableId('RiskArrays')}
            virtualized={virtualized}
            copyPasteMode
            onTableCellUpdate={handleCellUpdate}
            isPinnedHeader
          />
        </div>

        {showPagination ? (
          <div>
            <div className={RiskArraysTableStyles.Pagination}>
              <button
                disabled={total === skip}
                className={RiskArraysTableStyles.Button}
                style={{
                  color: theme.color.typo.action,
                }}
                onClick={loadNext}
              >
                Load next ...
              </button>
              <Select
                size="small"
                sx={{
                  width: '60px',
                }}
                value={limit}
                options={paginationOptions}
                onChange={(e) => {
                  setLimit(Number(e.target.value));
                }}
              />
            </div>
          </div>
        ) : null}
      </WrapperLoader>
    </div>
  );
};
