import { useState } from 'react';
import { Input, Modal, Notification } from 'react-ui-kit-exante';

import { text } from './constants';

export interface INewGroupModalProps {
  isOpened: boolean;
  onClose: () => unknown;
  onSubmit: (value: string) => Promise<unknown>;
}

export const NewGroupModal = ({
  isOpened,
  onClose,
  onSubmit,
}: INewGroupModalProps) => {
  const [groupName, setGroupName] = useState('');

  const handleSubmit = async () => {
    if (groupName) {
      try {
        await onSubmit(groupName);
        onClose();
        setGroupName('');
      } catch (err) {
        const errorMessage =
          err instanceof Error ? err.message : text.DEFAULT_ERROR_MESSAGE;

        Notification.error({
          title: errorMessage,
        });
      }
    }
  };

  const onCloseAddModal = () => {
    onClose();
    setGroupName('');
  };

  const handleChangeGroupName = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(value);
  };

  return (
    <Modal
      onClose={onCloseAddModal}
      isOpened={isOpened}
      title={text.TITLE_MODAL_ADD_GROUP}
      confirmButton={{
        handleConfirm: handleSubmit,
      }}
      keepMounted={false}
    >
      <Input
        value={groupName}
        onChange={handleChangeGroupName}
        placeholder={text.NAME_FIELD_PLACEHOLDER}
        fullWidth
      />
    </Modal>
  );
};
