import { DEFAULT_OPTION_NULLABLE } from '~/constants';
import { transformDataToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';

export function transformSymbolPermissionsGroupsToOptions({
  data,
}: {
  data?: { id: number; name: string }[];
}) {
  if (!data) {
    return {
      data: [DEFAULT_OPTION_NULLABLE] as unknown as IOption[],
    };
  }

  return {
    data: [
      DEFAULT_OPTION_NULLABLE,
      ...transformDataToSelectOptions(data, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as IOption[],
  };
}
