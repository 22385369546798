import { PROXY_CORE_PATH } from '~/shared/utils';

import { limitsApi } from './limitsApi';

export const { useDeleteGroupLimitsMutation } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteGroupLimits: builder.mutation({
      query: (value: number) => ({
        url: `${PROXY_CORE_PATH}v2.0/limits_set/${value}`,
        method: 'DELETE',
      }),
    }),
  }),
});
