import { AutocompleteAsync, Select, styled } from 'react-ui-kit-exante';

export const WrapperStyled = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  paddingTop: '24px',
}));

export const AutocompleteAsyncStyled = styled(AutocompleteAsync)(() => ({
  width: '280px',
  maxWidth: '280px',
}));

export const SelectStyled = styled(Select)(() => ({
  width: '280px',
  maxWidth: '280px',
}));
