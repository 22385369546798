import { isEqual } from 'lodash';
import { MutableRefObject, useEffect } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  useUpdateAccountMutation,
  useUpdateCashConversionSettingsMutation,
  useUpdateCommissionSettingsMutation,
  useUpdateExtReportsSettingsMutation,
  useUpdateGroupSettingsMutation,
  useUpdateInternalSettingsMutation,
  useUpdateMarginSettingsMutation,
  useUpdateRebatesMutation,
  useUpdateRegularPaymentsSettingsMutation,
  useUpdateRiskSettingsMutation,
  useUpdateRoutingSettingsMutation,
} from '~/api';
import { regularCommissionsService } from '~/resources';
import { TAccountRegularCommissionsTableRow } from '~/resources/regularCommissions/types';
import { createServiceResponse } from '~/shared/utils';
import { EMPTY_SERVICE_RESPONSE } from '~/shared/utils/services';
import {
  IAccountGroupSettings,
  ICoreAccount,
  IExtReportsSettings,
  IInternalSettings,
  IRegularPaymentsSettings,
  TAccountCashConversion,
  TAccountCommission,
  TAccountRiskSettings,
  TAccountRoutingSettings,
  TMarginSettings,
  TRebateAccount,
  TRebateAccountsData,
} from '~/types/accounts';

interface ICreateHandlersMapArguments {
  account?: ICoreAccount | null;
  previousRegularCommissions: MutableRefObject<
    TAccountRegularCommissionsTableRow[] | undefined
  >;
  previousRebates: MutableRefObject<TRebateAccount[] | undefined | null>;
  previousGroupSettings: MutableRefObject<IAccountGroupSettings | undefined>;
}

export const useHandlersMap = ({
  account,
  previousRegularCommissions,
  previousRebates,
  previousGroupSettings,
}: ICreateHandlersMapArguments) => {
  const [updateGroupSettings, { error: updateGroupSettingsError }] =
    useUpdateGroupSettingsMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [updateRegularPaymentsSettings] =
    useUpdateRegularPaymentsSettingsMutation();
  const [updateExtReportsSettings] = useUpdateExtReportsSettingsMutation();
  const [updateInternalSettings] = useUpdateInternalSettingsMutation();
  const [updateCashConversionSettings] =
    useUpdateCashConversionSettingsMutation();
  const [updateCommissionSettings] = useUpdateCommissionSettingsMutation();
  const [updateRoutingSettings] = useUpdateRoutingSettingsMutation();
  const [updateRebates] = useUpdateRebatesMutation();
  const [updateMarginSettings] = useUpdateMarginSettingsMutation();
  const [updateRiskSettings] = useUpdateRiskSettingsMutation();

  useEffect(() => {
    if (updateGroupSettingsError) {
      Notification.error({
        title: 'Error',
        description: String(updateGroupSettingsError),
      });
    }
  }, [updateGroupSettingsError]);

  if (!account) {
    return null;
  }

  return {
    account: async (accountData: ICoreAccount | null | undefined) => {
      if (!accountData) {
        return EMPTY_SERVICE_RESPONSE;
      }
      const response = await updateAccount({
        ...accountData,
        accountId: accountData.id,
      });

      if ('error' in response) {
        return EMPTY_SERVICE_RESPONSE;
      }

      Notification.success({ title: 'Settings were successfully updated' });
      return response.data;
    },
    regularPayments: (data: IRegularPaymentsSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateRegularPaymentsSettings({
        accountId: account.id,
        regularPaymentsSettings: data,
      });
    },
    extReports: (data: IExtReportsSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateExtReportsSettings({
        accountId: account.id,
        extReportsSettings: data,
      });
    },
    internal: (data: IInternalSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateInternalSettings({
        accountId: account.id,
        extReportsSettings: data,
      });
    },
    cashConversion: (data: TAccountCashConversion | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateCashConversionSettings({
        accountId: account.id,
        cashConversionSettings: data,
      });
    },
    commission: (data: TAccountCommission | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateCommissionSettings({
        accountId: account.id,
        commissionSettings: data,
      });
    },
    routing: (data: TAccountRoutingSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateRoutingSettings({
        accountId: account.id,
        routingSettings: data,
      });
    },
    regularCommissions: (
      data:
        | Partial<{
            commissionId: number;
            accountId: string;
            active: boolean;
            currency: string;
            name: string;
            rate: string;
            frequency: string;
          }>[]
        | undefined,
    ) => {
      if (
        !data ||
        !isTAccountRegularCommissionsTableRow(previousRegularCommissions)
      ) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return regularCommissionsService.updateRegularCommissions(
        account.id,
        previousRegularCommissions,
        data,
      );
    },
    rebates: async (data: TRebateAccountsData | null | undefined) => {
      if (!data || !isTRebateAccount(previousRebates)) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }
      const response = await updateRebates({
        accountId: account.id,
        previousRebates: previousRebates.current,
        rebates: data,
      });

      if ('error' in response) {
        return {
          error: response.error,
        };
      }
      if (response.data.data) {
        previousRebates.current = response.data.data;
      }

      return createServiceResponse({
        data: response.data.data,
        error: response.data.error,
        messages: response.data.messages,
      });
    },
    margin: (data: TMarginSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateMarginSettings({
        accountId: account.id,
        marginSettings: data,
      });
    },
    risk: (data: TAccountRiskSettings | null | undefined) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return updateRiskSettings({ accountId: account.id, riskSettings: data });
    },
    groupSettings: (data: IAccountGroupSettings | undefined) => {
      if (!isEqual(data, previousGroupSettings.current) && data) {
        return updateGroupSettings({
          accountId: account.id,
          data,
        });
      }

      return Promise.resolve(EMPTY_SERVICE_RESPONSE);
    },
  };
};

function isTAccountRegularCommissionsTableRow(
  val: ICreateHandlersMapArguments['previousRegularCommissions'],
): val is MutableRefObject<TAccountRegularCommissionsTableRow[]> {
  return Boolean(val.current);
}

function isTRebateAccount(
  val: ICreateHandlersMapArguments['previousRebates'],
): val is MutableRefObject<TRebateAccount[]> {
  return Boolean(val.current);
}
