import { useGetAccountOverridesQuery } from '~/api';

export const useAccountOverrides = (accountId: string) => {
  const { data, error } = useGetAccountOverridesQuery({ accountId });

  const getOverrides = () => {
    if (error || !data) {
      return {
        overnights: false,
        interests: false,
        commissions: false,
        limits: false,
        permissions: false,
      };
    }

    return data;
  };

  return {
    getOverrides,
  };
};
