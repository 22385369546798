import { styled } from 'react-ui-kit-exante';

type StyledBackgroundProps = {
  opacity: number;
};

export const StyledWrapperLoader = styled('div')(() => ({
  position: 'relative',
}));

export const StyledBackground = styled('div')<StyledBackgroundProps>(
  ({ opacity, theme }) => ({
    backgroundColor: theme?.color.bg.primary,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    opacity,
  }),
);
