import { RowType } from '~/types/common';

interface IRow {
  original?: {
    rowType: RowType;
  };
}

export function canExpandDefinition(row: IRow): boolean {
  return row?.original?.rowType === RowType.Node;
}
