import { ISortBy } from 'react-ui-kit-exante';

import { FROM_TO_DATE_FIELDS } from '~/constants';
import { createEmptyObject, prepareSortingForApi } from '~/shared/utils';
import { prepareDateRangeStringForAPI } from '~/shared/utils/dates';
import { IMirroringRule } from '~/types/mirroringRules';

export const expectedFields: (keyof IMirroringRule)[] = [
  'accountPurposes',
  'accountTypes',
  'blackListAccounts',
  'categories',
  'custodians',
  'endDateTime',
  'id',
  'name',
  'operationTypes',
  'rebateTag',
  'sourceAccounts',
  'sourceLegalEntities',
  'startDateTime',
  'status',
  'targetAccount',
  'transferMultiplier',
  'transferTag',
];

export const MIRRORING_RULES_PARAMS_MAPPER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  fromTo: {
    parser: (values: (string | null)[]) =>
      prepareDateRangeStringForAPI(FROM_TO_DATE_FIELDS, values),
  },
  page: {
    parser: createEmptyObject,
  },
};
