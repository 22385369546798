import { PanelGroup, Panel } from 'react-ui-kit-exante';

import { RiskArraysTable } from './RiskArraysTable';
import { RiskArrayHeader } from './components/RiskArrayHeader/RiskArrayHeader';
import { CopyPasteValuesProvider } from './context/CopyPasteValuesContext';
import {
  RiskArrayFormContextProvider,
  RiskArrayTreeContextProvider,
} from './context/RiskArrayTreeContext';

const styles = { width: '100%', overflow: 'hidden' };

export const RiskArrays = () => {
  return (
    <CopyPasteValuesProvider>
      <RiskArrayTreeContextProvider>
        <RiskArrayFormContextProvider>
          <div>
            <PanelGroup>
              <div style={styles}>
                <Panel>
                  <RiskArrayHeader />
                  <RiskArraysTable />
                </Panel>
              </div>
            </PanelGroup>
          </div>
        </RiskArrayFormContextProvider>
      </RiskArrayTreeContextProvider>
    </CopyPasteValuesProvider>
  );
};
