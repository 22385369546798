import { createApi } from '@reduxjs/toolkit/query/react';

import { clientsService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

export const clientApi = createApi({
  reducerPath: 'clientQuery',
  tagTypes: ['Client'],
  baseQuery: async (args) =>
    transformToBaseQueryResponse(await clientsService.resolveClient(args)),
  endpoints: (build) => ({
    getClient: build.query({
      query: (id?: string) => id,
      providesTags: ['Client'],
    }),
  }),
});

export const { useGetClientQuery } = clientApi;
