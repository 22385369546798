import { ClientAccountsTabContainer } from '~/containers/ClientAccountsTabContainer';
import { ClientActivityTabContainer } from '~/containers/ClientActivityTabContainer';
import { ClientInfoTabContainer } from '~/containers/ClientInfoTabContainer';
import { ClientSummaryTabContainer } from '~/containers/ClientSummaryTabContainer';
import { ITab } from '~/types/tabs';

import { TTabsIds } from '../context/types';

import { IClientTabComponentProps } from './types';

export const TABS: ITab<TTabsIds, IClientTabComponentProps>[] = [
  {
    id: 'summary',
    title: 'Summary',
    Component: ClientSummaryTabContainer,
  },
  { id: 'info', title: 'Info', Component: ClientInfoTabContainer },
  {
    id: 'accounts',
    title: 'Accounts',
    Component: ClientAccountsTabContainer,
  },
  {
    id: 'activity',
    title: 'Activity',
    Component: ClientActivityTabContainer,
  },
];
