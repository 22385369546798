import { ReactNode } from 'react';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import { NoDataWrapper } from '~/shared/components';

import { IDataAvailabilityState } from '../types';

import InfoFormTemplateStyles from './InfoFormTemplate.module.css';

interface IInfoFormTemplateProps {
  clientUsers: ReactNode;
  dataAvailabilityState: IDataAvailabilityState;
  info: ReactNode;
  header: ReactNode;
}

export const InfoFormTemplate = ({
  clientUsers,
  dataAvailabilityState,
  info,
  header,
}: IInfoFormTemplateProps) => {
  return (
    <>
      {header}

      <PanelGroup panelsPerLine={2}>
        <Panel title="Info">
          <NoDataWrapper hasData={dataAvailabilityState.hasClientData}>
            {info}
          </NoDataWrapper>
        </Panel>

        <div className={InfoFormTemplateStyles.SubPanel}>{clientUsers}</div>
      </PanelGroup>
    </>
  );
};
