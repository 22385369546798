import { styled } from 'react-ui-kit-exante';

export const StyledTokenList = styled('div')(({ theme }) => ({
  background: theme?.color.bg.primary,
  padding: '20px',
}));

export const StyledSection = styled('div')(() => ({
  '&:not(:first-of-type)': {
    marginTop: '24px',
  },
}));

export const StyledTitle = styled('h2')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '12px',
  textTransform: 'uppercase',
  color: theme.color.typo.promo,
}));
