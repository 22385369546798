import { IFormArrayItemStructure } from '~/types/form';

export function getEmptyRow(structure: Array<IFormArrayItemStructure>) {
  return structure.reduce((acc, item, index) => {
    switch (item.type) {
      case 'select':
      case 'multiselect':
        return {
          ...acc,
          [index]: [],
        };
      default:
        return {
          ...acc,
          [index]: '',
        };
    }
  }, {});
}
