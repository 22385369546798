import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, IColumn, IRow } from 'react-ui-kit-exante';

import {
  updateInstrumentOverride,
  updateNodeOverride,
} from '~/store/overnights';
import { IOvernightTree } from '~/types/overnights';
import { FilterLayers } from '~/types/symbolPermissions';

interface IOverrideCellProps {
  column: IColumn<IOvernightTree>;
  value: boolean;
  row: IRow<IOvernightTree>;
}

export const OverrideCell = ({ column, value, row }: IOverrideCellProps) => {
  const dispatch = useDispatch();
  const { layer } = column.getProps();
  const disabled = layer === FilterLayers.Default;

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const actionFn =
        row.original.rowType === 'node'
          ? updateNodeOverride
          : updateInstrumentOverride;

      dispatch(
        actionFn({
          path: row.original.path,
          value: e.target.checked,
          column: column.id,
        }),
      );
    },
    [column, dispatch, row],
  );

  return (
    <Checkbox disabled={disabled} checked={value} onChange={onChangeHandler} />
  );
};
