import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function expandedRowsSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.EXPANDED_ROWS_SET],
) {
  return {
    ...state,
    expandedRows: payload,
  };
}
