import { InstrumentIcon } from '~/shared/components';

import { StyledWrapper, StyledTitle } from './AssetTitle.styled';

interface IAssetTitleProps {
  title: string;
  count: number;
  iconName: string;
}

export const AssetTitle = ({ title, count, iconName }: IAssetTitleProps) => {
  return (
    <StyledWrapper>
      <InstrumentIcon iconName={iconName} />
      <StyledTitle>{`${title} (${count})`}</StyledTitle>
    </StyledWrapper>
  );
};
