import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetCurrentUserAccessRightsQuery } from '~/api';
import { transformVariantsToSelectOptions } from '~/shared/utils';

import { innerTabs } from './constants';

export function useInnerActivityTabs() {
  const { data: currentUserPermissions } = useGetCurrentUserAccessRightsQuery();
  const hasAuditLogAccess = currentUserPermissions?.read['Audit log'];

  const auditLogTab = 'audit logs';
  const notificationsTab = 'notifications';

  const location = useLocation();
  const isAccounts = location?.pathname?.split('/')[2] === 'accounts';

  const innerTabsOptions = useMemo(
    () =>
      transformVariantsToSelectOptions(
        innerTabs.filter((item) => {
          if (item === auditLogTab) {
            return hasAuditLogAccess;
          }

          if (item === notificationsTab && isAccounts) {
            return false;
          }

          return item;
        }),
        { capitalized: true },
      ),
    [hasAuditLogAccess],
  );

  return { innerTabs, auditLogTab, innerTabsOptions };
}
