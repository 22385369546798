import { get, has } from 'lodash';

export const getIsFieldExistsAndEmpty = (
  obj: Record<string, unknown>,
  field: string,
) => {
  const hasField = obj && field && has(obj, field);

  if (hasField) {
    const value = get(obj, field);
    const isEmpty = !value || (Array.isArray(value) && !value.length);

    return isEmpty;
  }

  return false;
};
