import { ActionTypes } from '../constants';
import {
  IStateSymbolPermissions,
  TActionsSymbolPermissionsState,
} from '../types';

import { changedDefaultNodesReset } from './changedDefaultNodesReset';
import { changedDefaultNodesUpdate } from './changedDefaultNodesUpdate';
import { downloadedPathsAdd } from './downloadedPathsAdd';
import { downloadedPathsReset } from './downloadedPathsReset';
import { expandedRowsReset } from './expandedRowsReset';
import { expandedRowsSet } from './expandedRowsSet';
import { filtersAccountSet } from './filtersAccountSet';
import { filtersGroupSet } from './filtersGroupSet';
import { filtersLayerSet } from './filtersLayerSet';
import { filtersRelatedGroupSet } from './filtersRelatedGroupSet';
import { filtersReset } from './filtersReset';
import { filtersUserSet } from './filtersUserSet';
import { filtersWithExpiredSet } from './filtersWithExpiredSet';
import { needRefreshPageSet } from './needRefreshPageSet';
import { permissionsByPathsReset } from './permissionsByPathsReset';
import { permissionsByPathsSet } from './permissionsByPathsSet';
import { permissionsByPathsUpdate } from './permissionsByPathsUpdate';
import { permissionsForInstrumentSet } from './permissionsForInstrumentSet';
import { permissionsForNodeSet } from './permissionsForNodeSet';
import { permissionsReset } from './permissionsReset';

export const reducer = (
  state: IStateSymbolPermissions,
  { type, payload }: TActionsSymbolPermissionsState,
) => {
  switch (type) {
    case ActionTypes.CHANGED_DEFAULT_NODES_RESET: {
      return changedDefaultNodesReset(state);
    }
    case ActionTypes.CHANGED_DEFAULT_NODES_UPDATE: {
      return changedDefaultNodesUpdate(state, payload);
    }
    case ActionTypes.DOWNLOADED_PATHS_ADD: {
      return downloadedPathsAdd(state, payload);
    }
    case ActionTypes.DOWNLOADED_PATHS_RESET: {
      return downloadedPathsReset(state);
    }
    case ActionTypes.EXPANDED_ROWS_RESET: {
      return expandedRowsReset(state);
    }
    case ActionTypes.EXPANDED_ROWS_SET: {
      return expandedRowsSet(state, payload);
    }
    case ActionTypes.FILTERS_ACCOUNT_SET: {
      return filtersAccountSet(state, payload);
    }
    case ActionTypes.FILTERS_GROUP_SET: {
      return filtersGroupSet(state, payload);
    }
    case ActionTypes.FILTERS_LAYER_SET: {
      return filtersLayerSet(state, payload);
    }
    case ActionTypes.FILTERS_RELATED_GROUP_SET: {
      return filtersRelatedGroupSet(state, payload);
    }
    case ActionTypes.FILTERS_RESET: {
      return filtersReset(state, payload);
    }
    case ActionTypes.FILTERS_USER_SET: {
      return filtersUserSet(state, payload);
    }
    case ActionTypes.FILTERS_WITH_EXPIRED_SET: {
      return filtersWithExpiredSet(state, payload);
    }
    case ActionTypes.NEED_REFRESH_PAGE_SET: {
      return needRefreshPageSet(state, payload);
    }
    case ActionTypes.PERMISSIONS_BY_PATHS_RESET: {
      return permissionsByPathsReset(state);
    }
    case ActionTypes.PERMISSIONS_BY_PATHS_SET: {
      return permissionsByPathsSet(state, payload);
    }
    case ActionTypes.PERMISSIONS_BY_PATHS_UPDATE: {
      return permissionsByPathsUpdate(state, payload);
    }
    case ActionTypes.PERMISSIONS_FOR_INSTRUMENT_SET: {
      return permissionsForInstrumentSet(state, payload);
    }
    case ActionTypes.PERMISSIONS_FOR_NODE_SET: {
      return permissionsForNodeSet(state, payload);
    }
    case ActionTypes.PERMISSIONS_RESET: {
      return permissionsReset(state);
    }
    default:
      return state;
  }
};
