import { useCallback, useMemo, useState } from 'react';
import { IColumn } from 'react-ui-kit-exante';

import {
  prepareDefaultColumnKeys,
  prepareInitialSelectedColumns,
  setColumnKeysToLocalStorage,
} from './helpers';

interface IUseSelectedColumnsProps {
  columns: IColumn<Record<string, unknown>>[];
  defaultDisplayedColumns?: string[];
  tableIds: string[];
  withLock: boolean;
}

// TODO: change logic to group just cash and asset columns
// we have 1 cash table with different columns
// and 7 asset tables with the same columns
// so we shouldn't set in LS 8 keys with column list
export function useSelectedColumns({
  columns,
  defaultDisplayedColumns = [],
  tableIds,
  withLock,
}: IUseSelectedColumnsProps) {
  const columnIds = useMemo(
    () => tableIds.map((id) => `${id}-columns`),
    [tableIds],
  );
  const [selectedColumn, setSelectedColumn] = useState<Record<string, boolean>>(
    () => {
      const newKeys = {
        ...prepareInitialSelectedColumns(
          columns,
          columnIds,
          defaultDisplayedColumns,
        ),
        ...(withLock ? { actions: true } : { blockedQtty: true }),
      };

      setColumnKeysToLocalStorage(columnIds, newKeys);

      return newKeys;
    },
  );

  const handleColumnChange = useCallback(
    (key: string, value: boolean) => {
      setSelectedColumn((prev) => {
        const newKeys: Record<string, boolean> = {
          ...prev,
          [key]: value,
          ...(withLock ? { actions: true } : { blockedQtty: true }),
        };

        setColumnKeysToLocalStorage(columnIds, newKeys);

        return newKeys;
      });
    },
    [columnIds, withLock],
  );
  const resetToDefaultColumns = useCallback(() => {
    let defaultColumnKeys = prepareDefaultColumnKeys(
      columns,
      defaultDisplayedColumns,
    );

    defaultColumnKeys = {
      ...defaultColumnKeys,
      ...(withLock ? { actions: true } : { blockedQtty: true }),
    };

    setSelectedColumn(defaultColumnKeys);
    setColumnKeysToLocalStorage(columnIds, defaultColumnKeys);
  }, [columnIds, columns, defaultDisplayedColumns, withLock]);

  return { selectedColumn, handleColumnChange, resetToDefaultColumns };
}
