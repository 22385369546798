import { Controller } from 'react-hook-form';
import { Checkbox } from 'react-ui-kit-exante';

export interface CheckboxCellProps {
  name: string;
  defaultValue: string | boolean | undefined;
  disabled?: boolean;
  warning?: boolean;
}

export const FormCheckboxCell = (props: CheckboxCellProps) => {
  const { disabled, defaultValue, name, warning } = props;

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <Checkbox
            checked={field.value}
            disabled={disabled}
            warning={warning}
            {...field}
          />
        );
      }}
    />
  );
};
