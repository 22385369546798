export const COLUMNS_CONFIG = {
  width: {
    accountId: 150,
    userId: 200,
    symbolId: 190,
    tradeType: 200,
    price: 150,
    processAccruedInterest: 250,
    accruedInterest: 200,
    quantity: 200,
    side: 150,
    comment: 250,
    internalComment: 250,
    takeCommission: 200,
    gwTime: 250,
    valueDate: 150,
    counterparty: 200,
    settlementCounterparty: 250,
  },
  header: {
    accountId: 'Account ID',
    userId: 'User ID',
    symbolId: 'Symbol ID',
    tradeType: 'Trade type',
    price: 'Price',
    processAccruedInterest: 'Process accrued interest',
    accruedInterest: 'Accrued interest',
    quantity: 'Quantity',
    side: 'Side',
    comment: 'Comment',
    internalComment: 'Internal comment',
    takeCommission: 'Take commission',
    gwTime: 'GW time',
    valueDate: 'Value date',
    counterparty: 'Counterparty',
    settlementCounterparty: 'Settlement counterparty',
  },
};
