import { apiRequest } from '~/shared/utils';

import { FETCH_API_STATUS } from './endpoints';
import { IFetchStatusResponse } from './types';

export class ApplicationRepository {
  public static async fetchAPIVersion() {
    return apiRequest<IFetchStatusResponse>({ url: FETCH_API_STATUS });
  }
}
