import { createSlice } from '@reduxjs/toolkit';

import { nonTradingCommissionsApi } from '~/api';
import { IStateNonTradingCommissions } from '~/types/nonTradingCommissions';
import type { TApplicationState } from '~/types/store';

import { INITIAL_STATE } from './constants';

export const nonTradingCommissionsSlice = createSlice({
  name: 'nonTradingCommissions',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      nonTradingCommissionsApi.endpoints?.getNonTradingCommissionGroups
        .matchFulfilled,
      (state, { payload: groups }) => {
        const groupsById: IStateNonTradingCommissions['groupsById'] = {};
        const groupsByName: IStateNonTradingCommissions['groupsByName'] = {};

        groups.forEach((group) => {
          groupsById[group.id] = group;
          groupsByName[group.name] = group;
        });

        state.groupsById = groupsById;
        state.groupsByName = groupsByName;
      },
    );
    builder.addMatcher(
      nonTradingCommissionsApi.endpoints?.getNonTradingCommissions
        .matchFulfilled,
      (state, { payload: commissions }) => {
        const uniqCommissionsNames = Array.from(
          new Set(commissions.map(({ name }) => name)),
        );

        state.commissionNames = new Set(uniqCommissionsNames.sort());
      },
    );
  },
});

export const selectGroupsById = (state: TApplicationState) =>
  state.nonTradingCommissions.groupsById;

export const selectGroupsByName = (state: TApplicationState) =>
  state.nonTradingCommissions.groupsByName;

export const selectGroupByName = (state: TApplicationState, name: string) =>
  state.nonTradingCommissions.groupsByName[name];

export const selectCommissionNames = (state: TApplicationState) =>
  state.nonTradingCommissions.commissionNames;
