export const COLUMNS_CONFIG = {
  width: {
    assetId: 200,
    symbolId: 200,
    amount: 150,
    price: 150,
    accountId: 250,
    operationType: 250,
    comment: 250,
    internalComment: 250,
    useAutoconversion: 200,
    valueDate: 150,
    useRevenue: 150,
    transferId: 150,
    clientCounterparty: 200,
    exanteCounterparty: 200,
    commissionValue: 180,
    commissionCurrency: 200,
    commissionOperationType: 250,
  },
  header: {
    assetId: 'Asset ID',
    symbolId: 'Symbol ID',
    amount: 'Amount',
    price: 'Price',
    accountId: 'Account ID',
    operationType: 'Operation type',
    comment: 'Comment',
    internalComment: 'Internal comment',
    useAutoconversion: 'Use autoconversion',
    valueDate: 'Value date',
    useRevenue: 'Use revenue',
    transferId: 'Transfer ID',
    clientCounterparty: 'Client counterparty',
    exanteCounterparty: 'Exante counterparty',
    commissionValue: 'Commission value',
    commissionCurrency: 'Commission currency',
    commissionOperationType: 'Commission operation type',
  },
};

export const defaultValues = {
  bulkManualTransaction: false,
};

export const BULK_MANUAL_TRANSACTION = 'bulkManualTransaction';
export const BULK_MANUAL_TRANSACTION_LABEL =
  '4 eyes check for BULK transactions';
