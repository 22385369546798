import { useMemo } from 'react';

import { useUpdateRiskArrayMutation } from '~/api';

export const useUpdateTree = () => {
  const [update] = useUpdateRiskArrayMutation();

  const value = useMemo(() => {
    return {
      update,
    };
  }, [update]);
  return value;
};
