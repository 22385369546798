import { ReactNode } from 'react';
import { PanelGroup } from 'react-ui-kit-exante';

interface IUserAccessRightsProps {
  header: ReactNode;
  commonPermissions: ReactNode;
  servicesPermissions: ReactNode;
  backofficePermissions: ReactNode;
}

export const UserAccessRights = ({
  header,
  commonPermissions,
  servicesPermissions,
  backofficePermissions,
}: IUserAccessRightsProps) => {
  return (
    <>
      {header}

      <PanelGroup panelsPerLine={1}>
        {commonPermissions}
        {servicesPermissions}
        {backofficePermissions}
      </PanelGroup>
    </>
  );
};
