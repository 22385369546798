import { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  AutocompleteAsync,
  TAutocompleteAsyncFetchData,
} from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { IOption } from '~/types/form';

interface IFormSelectAsyncContainerProps {
  autoSelect?: boolean;
  disabled?: boolean;
  fetchData: TAutocompleteAsyncFetchData;
  freeSolo?: boolean;
  fullWidth?: boolean;
  label?: string;
  name: string;
  onChange?(value: IOption): void;
  disableWarning?: boolean;
  getValue?: (value: IOption) => void;
}

export const FormSelectAsyncContainer = ({
  autoSelect = false,
  disabled = false,
  fetchData,
  freeSolo = false,
  fullWidth = true,
  label = '',
  name,
  onChange: onChangeHandler,
  disableWarning,
  getValue,
}: IFormSelectAsyncContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error, isDirty },
  } = useController({
    control,
    name,
  });

  const changeHandler = useCallback(
    (_: unknown, newValue: IOption) => {
      onChange(newValue);
      if (onChangeHandler) {
        onChangeHandler(newValue);
      }
      if (getValue) {
        getValue(newValue);
      }
    },
    [getValue, onChange, onChangeHandler],
  );

  return (
    <AutocompleteAsync
      autoSelect={autoSelect}
      disabled={disabled}
      error={!!error}
      fetchData={fetchData}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      message={error?.message}
      onChange={changeHandler}
      options={EMPTY_ARRAY}
      placeholder={label}
      value={value}
      warning={!disableWarning && isDirty}
    />
  );
};
