import { omitBy, isUndefined } from 'lodash';

import { IOption } from '~/types/form';

import { SYMBOLS_ENDPOINT } from '../endpoints';

import { symbolsApi } from './symbolsAPI';

export const { useLazySearchBySymbolsQuery } = symbolsApi.injectEndpoints({
  endpoints: (builder) => ({
    searchBySymbols: builder.query<
      {
        total: number;
        canceled?: boolean;
        nextSkip: number | null;
        options: Array<IOption>;
      },
      {
        skip: number | null;
        search: string;
        limit?: number;
        archived?: boolean;
      }
    >({
      queryFn: async (
        { skip, search, limit = 100, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        if (skip === null) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
            },
          };
        }

        const response = await fetchWithBaseQuery({
          url: SYMBOLS_ENDPOINT,
          params: omitBy({ limit, skip, search, ...restParams }, isUndefined),
          requestOptions: {},
        });

        if ('error' in response) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
              canceled: true,
            },
          };
        }

        const { pagination } = response.data;

        return {
          data: {
            total: pagination.total,
            nextSkip: skip + limit < pagination.total ? skip + limit : null,
            options: response.data.data.map(({ id }: { id: string }) => ({
              label: id,
              value: id,
            })),
          },
        };
      },
    }),
  }),
});
