import { ActionTypes } from '../constants';
import { getQueryParams } from '../helpers';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function filtersGroupSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.FILTERS_GROUP_SET],
) {
  const select = {
    ...state.filters.select,
    group: payload,
  };
  const { relatedGroup } = state.filters;
  const queryParams = getQueryParams({ ...select, relatedGroup });

  return {
    ...state,
    filters: {
      ...state.filters,
      select,
      queryParams,
    },
  };
}
