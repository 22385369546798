import { createApi } from '@reduxjs/toolkit/query/react';

import { bookmarksService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';
import { TParams } from '~/types/api';

export const bookmarkApi = createApi({
  reducerPath: 'bookmarkQuery',
  tagTypes: ['Bookmark'],
  baseQuery: async (args) => {
    if (!args) {
      return Promise.resolve({ data: {} });
    }

    return transformToBaseQueryResponse(
      await bookmarksService.fetchBookmarks(args),
    );
  },
  endpoints: (build) => ({
    getBookmarks: build.query({
      query: (params: TParams) => {
        return params;
      },
      providesTags: ['Bookmark'],
    }),
    getBookmark: build.query({
      queryFn: async (id: number) => {
        try {
          const bookmark = await bookmarksService.fetchBookmark(id);
          return {
            data: bookmark,
          };
        } catch (e) {
          return {
            error: e,
          };
        }
      },
    }),
  }),
});

export const {
  useGetBookmarksQuery,
  useGetBookmarkQuery,
  useLazyGetBookmarkQuery,
} = bookmarkApi;
