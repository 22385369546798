import { FormCheckboxContainer } from '../../../../form';

import AtpPermissionsStyles from './AtpPermissions.module.css';

interface IAtpPermissionsProps {
  labelAtpPermissions: string[][] | null;
}

export const AtpPermissions = ({
  labelAtpPermissions,
}: IAtpPermissionsProps) => {
  if (!labelAtpPermissions) {
    return null;
  }

  return (
    <div className={AtpPermissionsStyles.Wrapper}>
      {labelAtpPermissions.map(([name, label]) => (
        <FormCheckboxContainer
          key={`services.atp.permissions.${name}`}
          label={label}
          name={`services.atp.permissions.${name}`}
        />
      ))}
    </div>
  );
};
