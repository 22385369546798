/**
 * Removes the currency notation in parentheses from the end of a string.
 * The currency notation must be in uppercase letters and can be of any length.
 *
 * @param {string} headerName - The header name from which to remove the currency.
 * @returns {string} The string without the currency notation at the end.
 *
 * @example
 * console.log(removeCurrencyFromHeader("Gibraltar (EUR)")); // "Gibraltar"
 * console.log(removeCurrencyFromHeader("Gibraltar (US)")); // "Gibraltar"
 * console.log(removeCurrencyFromHeader("Gibraltar (EURO)")); // "Gibraltar"
 * console.log(removeCurrencyFromHeader("Gibraltar (G)")); // "Gibraltar"
 * console.log(removeCurrencyFromHeader("Gibraltar")); // "Gibraltar", unchanged if no currency notation
 */
export function removeCurrencyFromHeader(headerName: string): string {
  // The regular expression / \([A-Z]+\)$/ breaks down as follows:
  // 1. " " - a space before the parentheses, indicating a space before the currency notation.
  // 2. "\(" - the opening parenthesis, escaped with a backslash because parentheses are special characters.
  // 3. "[A-Z]+" - one or more characters from the range A-Z, representing the currency notation.
  // 4. "\)" - the closing parenthesis, also escaped with a backslash.
  // 5. "$" - the end of the string, indicating that the pattern must occur at the very end of the string.
  return headerName.replace(/ \([A-Z]+\)$/, '');
}
