import { Children, ReactNode, isValidElement } from 'react';

import {
  StyledHeader,
  StyledHistoricalMode,
  StyledIndicator,
  StyledWrapper,
  StyledList,
  StyledItem,
} from './DetailedAccountInfo.styled';

interface IAccountTotalsProps {
  accountValuesList: ReactNode;
  availableList: ReactNode;
  historicalMode: ReactNode;
  marginIndicator: ReactNode;
  internalComment?: ReactNode;
}

export const DetailedAccountInfo = ({
  accountValuesList,
  availableList,
  historicalMode,
  marginIndicator,
  internalComment,
}: IAccountTotalsProps) => {
  return (
    <div>
      <StyledHeader>
        {internalComment}
        <StyledHistoricalMode>{historicalMode}</StyledHistoricalMode>
      </StyledHeader>
      <StyledIndicator>{marginIndicator}</StyledIndicator>

      <StyledWrapper>
        <StyledList>
          {Children.map(accountValuesList, (item) => (
            <StyledItem
              key={isValidElement(item) ? item.key : item?.toString()}
            >
              {item}
            </StyledItem>
          ))}
        </StyledList>

        <StyledList>
          {Children.map(availableList, (item) => (
            <StyledItem
              key={isValidElement(item) ? item.key : item?.toString()}
            >
              {item}
            </StyledItem>
          ))}
        </StyledList>
      </StyledWrapper>
    </div>
  );
};
