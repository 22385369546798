import { IStateSymbolPermissionsQueryParams } from '~/pages/SymbolPermissionsPage/types';
import { apiRequest, IOptionsArguments } from '~/shared/utils';
import { IDataWithPagination, TParams } from '~/types/api';
import {
  FilterLayers,
  ISymbolPermissionsPost,
  TSymbols,
} from '~/types/symbolPermissions';

import {
  getFetchSymbolPermissionsEndpoint,
  getFetchSymbolPermissionsTreeEndpoint,
  getPostPermissionsEndpoint,
} from './endpoints';

export class SymbolPermissionsRepository {
  public static fetchSymbolsTree(
    layer: FilterLayers,
    params: Omit<IStateSymbolPermissionsQueryParams, 'layer'>,
  ) {
    return apiRequest({
      url: getFetchSymbolPermissionsTreeEndpoint(layer, params),
      params,
    });
  }

  public static postSymbolsTreeWithPermissions(
    data: Partial<ISymbolPermissionsPost>[],
    type: FilterLayers,
    entity: string | null,
  ) {
    return apiRequest({
      url: getPostPermissionsEndpoint(type, entity),
      method: 'POST',
      data,
    });
  }

  public static fetchSymbolsPermissions(
    params: TParams,
    layer: FilterLayers,
    options?: IOptionsArguments,
  ) {
    return apiRequest<IDataWithPagination<TSymbols>>(
      {
        params,
        url: getFetchSymbolPermissionsEndpoint(layer, params),
      },
      options,
    );
  }
}
