import { ReactNode, HTMLProps, forwardRef } from 'react';
import { CenteredLoader } from 'react-ui-kit-exante';

import { StyledWrapperLoader, StyledBackground } from './WrapperLoader.styled';

interface IWrapperLoader {
  children: ReactNode;
  opacity?: number;
  isLoading: boolean;
}

export const WrapperLoader = forwardRef<
  HTMLDivElement,
  IWrapperLoader & HTMLProps<HTMLDivElement>
>(({ children, opacity = 1, isLoading }, ref) => {
  return (
    <StyledWrapperLoader ref={ref}>
      {children}

      {isLoading && (
        <StyledBackground opacity={opacity}>
          <CenteredLoader />
        </StyledBackground>
      )}
    </StyledWrapperLoader>
  );
});

WrapperLoader.displayName = 'WrapperLoader';
