export const COMPANY_CLASSIFICATION_OPTIONS = [
  {
    value: 'Active Nonfinancial Organization',
    label: 'Active Nonfinancial Organization',
  },
  {
    value: 'Passive Nonfinancial Organization',
    label: 'Passive Nonfinancial Organization',
  },
  {
    value: 'Financial Institution',
    label: 'Financial Institution',
  },
  {
    value: 'Governmental Entity',
    label: 'Governmental Entity',
  },
  {
    value: 'International Organization',
    label: 'International Organization',
  },
];
