import { TRiskArrayTreeResponse } from '~/api';

export const createIconsMap = (data: TRiskArrayTreeResponse | undefined) => {
  const map: Record<string, string> = {};

  if (data) {
    data.data.forEach(({ name, icon }) => {
      map[name] = map[name] || icon || '';
    });
  }

  return map;
};
