import { apiRequest, IRequestArguments } from './apiRequest';
import { displayErrorNotification, is403GetError, parseError } from './helpers';
import { IOptionsArguments } from './types';

interface IBaseQueryHandlerArguments extends IRequestArguments {
  requestOptions?: IOptionsArguments;
  onError?: (error: Error) => void;
  onSuccess?: (data: unknown) => void;
  ignoreForbiddenError?: boolean;
}

export async function baseQueryHandler({
  method = 'GET',
  requestOptions,
  onError,
  onSuccess,
  ignoreForbiddenError,
  ...rest
}: IBaseQueryHandlerArguments) {
  try {
    const response = await apiRequest(
      {
        method,
        ...rest,
      },
      requestOptions,
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    return {
      data: response.data,
      meta: rest,
    };
  } catch (error) {
    if (ignoreForbiddenError || is403GetError(error)) {
      return {
        data: null,
        meta: rest,
      };
    }

    if (onError && error instanceof Error) {
      onError(error);
    }

    displayErrorNotification(error);

    const { title, description } = parseError(error);

    return {
      error: description || title,
    };
  }
}
