import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { useAddUnderlyingMutation, useLazyGetAllUnderlyingQuery } from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { getColumns } from '~/pages/UnderlyingSettings/helpers';
import { selectCurrenciesOptions } from '~/store/currencies';
import { IIntermonthSpreadMargin } from '~/types/dictionary';

import { tableId, defaultSort } from './constants';

export const UnderlyingSettings = () => {
  const [addUnderlyingSettingsMutation, actionAddState] =
    useAddUnderlyingMutation();
  const currenciesOptions = useSelector(selectCurrenciesOptions);
  const [fetchData] = useLazyGetAllUnderlyingQuery();

  const fetchAndPrepareData = useCallback(
    async ({
      params,
    }: {
      params: {
        skip: number;
        limit: number;
        underlying?: string;
        sorting: unknown;
      };
    }) => {
      const res = await fetchData({
        skip: params.skip,
        limit: params.limit,
        ...(params.sorting
          ? {
              order: get(params, 'sorting.0.desc') ? 'desc' : 'asc',
              orderBy: get(params, 'sorting.0.id'),
            }
          : {}),
        ...(params.underlying
          ? {
              underlying: params.underlying,
            }
          : {}),
      });
      return res.data;
    },
    [fetchData],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: fetchAndPrepareData },
      saveViewParamsAfterLeave: true,
      tableId,
      sorting: { getDefaultSorting: () => defaultSort },
    }),

    [fetchAndPrepareData],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  const columns = getColumns(currenciesOptions, setFilter, removeFilter);
  const isLoadingTable = actionAddState.isLoading || isLoading;

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const filterProps = {
    removeAllFilters: resetFilters,
    filters,
    manualFilters: true,
  };

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: async (
        _prevValues: IIntermonthSpreadMargin,
        values: IIntermonthSpreadMargin,
      ) => {
        const {
          currency,
          underlying,
          backBackCharge,
          extremeLeverageRate,
          frontBackCharge,
          frontFrontCharge,
          lastFrontMonth,
          longOptionMinimum,
          shortOptionMinimum,
        } = values;

        const prepareValue = (value: string | number | null) =>
          value === '' ? null : value;

        const body = [
          {
            currency,
            underlying,
            lastFrontMonth: prepareValue(lastFrontMonth),
            backBackCharge: prepareValue(backBackCharge),
            frontBackCharge: prepareValue(frontBackCharge),
            frontFrontCharge: prepareValue(frontFrontCharge),
            longOptionMinimum: prepareValue(longOptionMinimum),
            shortOptionMinimum: prepareValue(shortOptionMinimum),
            extremeLeverageRate: prepareValue(extremeLeverageRate),
          },
        ];

        await addUnderlyingSettingsMutation(body);
        refetch();
      },
    }),
    [addUnderlyingSettingsMutation, refetch],
  );

  return (
    <Table
      columns={columns}
      copyPasteMode
      data={data?.data || EMPTY_ARRAY}
      defaultSortBy={defaultSort}
      filteringProps={filterProps}
      filtersExpanded
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoadingTable}
      manualSortBy
      onSort={setSorting}
      pageSize={50}
      rowActions={rowActions}
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={tableId}
      title="Underlying Settings"
      isPinnedHeader
    />
  );
};
