import { NavLink } from 'react-router-dom';
import { Panel, styled } from 'react-ui-kit-exante';

export const StyledLink = styled(NavLink)(({ theme }) => ({
  color: theme.color.typo.action,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.color.typo.promo,
  },
}));

export const StyledHelperText = styled('p')(({ theme }) => ({
  margin: '6px 0 0 13px',
  fontSize: '14px',
  color: theme.color.typo.ghost,
  display: 'flex',

  '& > span': {
    marginRight: '5px',
  },
}));

export const StyledPanel = styled(Panel)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
}));
