import { uniqueId } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { TCustomEvents } from '~/types/form';

import { InputMask, TInputMaskProps } from './InputMask';

interface IFormInputMaskContainerProps extends TInputMaskProps {
  customEvents?: TCustomEvents;
  disabled?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  label?: string;
  name: string;
}

export const FormInputMaskContainer = ({
  customEvents = {},
  disabled = false,
  readonly = false,
  fullWidth = true,
  label = '',
  name,
  ...rest
}: IFormInputMaskContainerProps) => {
  // InputMask doesn't rerender when form changed to null, so force reload
  const [inputKey, setInputKey] = useState(uniqueId);
  const formContext = useFormContext();
  const {
    field: { onChange, onBlur: onBlurReactHookForm, value },
    fieldState: { error, isDirty },
  } = useController({
    control: formContext.control,
    name,
  });

  formContext.register(name, { disabled });

  // fix for WBU-257, section "Add Manual transaction"
  useEffect(() => {
    if (!disabled) {
      onChange(value);
    }
  }, [onChange, value, disabled]);

  const { onBlur: onBlurCustomEvents } = customEvents;

  const blurHandler = useCallback(() => {
    if (onBlurCustomEvents) {
      onBlurCustomEvents(formContext);
    }

    onBlurReactHookForm();
  }, [formContext, onBlurCustomEvents, onBlurReactHookForm]);

  useEffect(() => {
    if (value === null) {
      setInputKey(uniqueId());
    }
  }, [value]);

  return (
    <InputMask
      key={inputKey}
      disabled={disabled}
      warning={isDirty}
      readOnly={readonly}
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={error?.message}
      name={name}
      onBlur={blurHandler}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};
