import { omit } from 'lodash';

const lastModificationDate = 'lastModificationDate';

export const prepareClientsParamsToRequest = <
  T extends Record<string, unknown>,
>(
  params: T,
): Omit<T, typeof lastModificationDate> => {
  const value = params[lastModificationDate];
  if (Array.isArray(value)) {
    const [start, end] = value;

    return {
      ...omit(params, lastModificationDate),
      startLastModificationDate: start,
      endLastModificationDate: end,
    };
  }
  return params;
};
