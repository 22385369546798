import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  IColumn,
  IRowExpand,
  Notification,
} from 'react-ui-kit-exante';

import { updateInstrumentValue, updateNodeValue } from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

interface IInheritedCellProps {
  column: IColumn<ICommissionTree>;
  row: IRowExpand<ICommissionTree>;
  value: boolean;
}

export const InheritedCell = ({ row, column, value }: IInheritedCellProps) => {
  const { layer } = column.getProps();
  const dispatch = useDispatch();

  const isNodeCell = row.original.rowType === RowType.Node;
  const disabled = row.depth === 0;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (layer !== FilterLayers.Default && !row.original.override) {
        Notification.warning({
          title: `You can not change non-overridable commission`,
        });

        return;
      }

      const actionForUpdateValue = isNodeCell
        ? updateNodeValue
        : updateInstrumentValue;

      dispatch(
        actionForUpdateValue({
          path: row.original.path,
          value: event.target.checked,
          column: column.id,
        }),
      );
    },
    [column, dispatch, isNodeCell, row, layer],
  );

  return (
    <Checkbox disabled={disabled} checked={value} onChange={onChangeHandler} />
  );
};
