export function findEmptyFieldIndexes(
  array: Array<Array<unknown>>,
): [number, number] | false {
  for (let i = 0; i < array.length; i += 1) {
    for (let j = 0; j < array[i].length; j += 1) {
      if (array[i][j] === '') {
        return [i, j];
      }
    }
  }

  return false;
}
