import { NON_BREAKING_SPACE } from './common';

export const FROM_TO_DATE_FIELDS = ['fromDate', 'toDate'];
export const CREATED_DATE_FIELDS = ['startCreated', 'endCreated'];
export const CREATION_DATE_FIELDS = ['startCreationDate', 'endCreationDate'];
export const ARCHIVE_DATE_FIELDS = ['startArchivedDate', 'endArchivedDate'];
export const LAST_MODIFICATION_DATE_FIELDS = [
  'startLastModificationDate',
  'endLastModificationDate',
];
export const LAST_LOGIN_DATE_FIELDS = [
  'startLastLoginTime',
  'endLastLoginTime',
];
export const BIRTH_DATE_FIELDS = ['startBirthDate', 'endBirthDate'];
export const START_END_DATE_FIELDS = ['startDate', 'endDate'];

export const EMPTY_OPTION_NULLABLE = {
  label: NON_BREAKING_SPACE,
  value: 'null',
};

export const DEFAULT_OPTION_NULLABLE = {
  label: 'Default',
  value: null,
};
