import { camelCase } from 'lodash';

import { reduceByKeys } from '~/shared/utils';
import { IAccountStatistic } from '~/types/accounts';

interface IPreparedAccountStatistic extends Record<string, string> {
  allAccountsCount: string;
  allClientsCount: string;
  allUsersCount: string;
  sumNATForAllTypes: string;
  sumRequiredMargin: string;
}

export const prepareData = (data: IAccountStatistic) => {
  const result = reduceByKeys(
    data,
    (acc, key) => {
      if (key !== 'sumNetAssetValueByType') {
        acc[key] = String(data[key]);
      }
      return acc;
    },
    {} as IPreparedAccountStatistic,
  );

  const { sumNetAssetValueByType } = data;

  sumNetAssetValueByType.forEach(({ type, total }) => {
    const formattedType = camelCase(type);

    result[formattedType] = total;
  });

  return result;
};
