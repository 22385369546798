import { formatCurrency, defaultLocaleFormatter } from '~/shared/utils';
import { IPosition } from '~/types/accounts';

export const getAdditionalColumns = (
  currency: string,
  displayLock: boolean,
) => {
  if (!displayLock) {
    return [[], []];
  }

  return [
    [
      {
        Header: 'Locked Value',
        formatting: 'number',
        maxWidth: 60,
        tooltip: {
          type: 'custom',
          options: {
            Title: (value: string) =>
              value ? formatCurrency(defaultLocaleFormatter, value) : '',
          },
        },
        accessor: (row: IPosition) => row?.blockedQtty,
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
      },
      {
        Header: `Converted Locked Value, ${currency}`,
        formatting: 'number',
        maxWidth: 80,
        tooltip: {
          type: 'custom',
          options: {
            Title: (value: string) =>
              value ? formatCurrency(defaultLocaleFormatter, value) : '',
          },
        },
        accessor: (row: IPosition) => row?.convertedLockedValue,
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
      },
    ],
    [
      {
        Header: 'Locked Quantity',
        formatting: 'number',
        maxWidth: 60,
        tooltip: {
          type: 'custom',
          options: {
            Title: (value: string) =>
              value ? formatCurrency(defaultLocaleFormatter, value) : '',
          },
        },
        accessor: (row: IPosition) =>
          row?.lock?.reduce((acc, { amount }) => acc + amount, 0),
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
      },
    ],
  ];
};
