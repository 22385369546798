import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { SearchInput } from 'react-ui-kit-exante';

import { DEFAULT_DEBOUNCE_TIMEOUT, MIN_SEARCH_LENGTH } from '~/constants';

export interface ISearchProps {
  onSearch?: (query: string) => unknown;
  getIsActiveSearch?: (value: string) => boolean;
  onReset?: () => unknown;
  searchValue: string;
  setSearchValue: (value: string) => unknown;
  preventSearch?: (value: string) => boolean;
}

export const Search = ({
  onReset = () => {},
  onSearch = () => {},
  getIsActiveSearch,
  searchValue,
  setSearchValue,
  preventSearch,
}: ISearchProps) => {
  const handleSearch = useCallback(
    async (value: string) => {
      const search = value.trim();

      if (search.length < MIN_SEARCH_LENGTH) {
        onReset();
      } else {
        await onSearch(value);
      }
    },
    [onSearch, onReset],
  );

  const debouncedHandleChangeInput = useMemo(
    () => debounce(handleSearch, DEFAULT_DEBOUNCE_TIMEOUT),
    [handleSearch],
  );

  const handleChange = useCallback(
    (value: string) => {
      if (typeof preventSearch === 'function') {
        const shouldPrevent = preventSearch(value);
        if (shouldPrevent) {
          return;
        }
      }
      setSearchValue(value);
      debouncedHandleChangeInput(value);
    },
    [preventSearch, setSearchValue, debouncedHandleChangeInput],
  );

  useEffect(() => {
    if (getIsActiveSearch && getIsActiveSearch(searchValue)) {
      handleSearch(searchValue);
    }
  }, [getIsActiveSearch, handleSearch, searchValue]);

  return (
    <SearchInput
      size="medium"
      onChange={handleChange}
      inputProps={{ value: searchValue, style: { width: '400px' } }}
    />
  );
};
