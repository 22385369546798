const isInsideRun = () => window.runUIhistoryContainer && window.WEB_BO_UI;

type ApiType = 'AUDIT_LOG' | 'BO' | 'BO_SWAGGER' | 'WFE';

export const DEFAULT_DOMAINS = {
  BO: {
    stage: 'node-back-stage.exante.eu',
    test: 'node-back-test.exante.eu',
  },
  BO_SWAGGER: {
    stage: 'backoffice-stage.exante.eu',
    test: 'backoffice-test.exante.eu',
  },
  AUDIT_LOG: {
    stage: 'auditlog-stage.exante.eu',
    test: 'auditlog-test.exante.eu',
  },
  WFE: {
    stage: 'wfe-stage.exante.eu',
    test: 'wfe-test.exante.eu',
  },
};

export const getEnv = () => {
  const isStage = window.location.host.includes('stage');
  const isTest = window.location.host.includes('test');

  if (isTest) {
    return 'test';
  }

  if (isStage) {
    return 'stage';
  }

  return 'stage';
};

export const ENVIRONMENT = getEnv();

const getFallbackErrorMessage = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] WEB-BO application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getApiDomain = (
  apiType: ApiType,
  fallback: string = DEFAULT_DOMAINS[apiType][ENVIRONMENT],
) => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.[apiType];

    if (url) {
      return url;
    }

    console.warn(
      getFallbackErrorMessage(
        `getBase${apiType}Url`,
        `window.WEB_BO_UI.api_urls.${apiType}`,
        `DEFAULT_DOMAINS[${apiType}]`,
      ),
    );

    return DEFAULT_DOMAINS[apiType][ENVIRONMENT] || fallback;
  }

  return DEFAULT_DOMAINS[apiType][ENVIRONMENT] || fallback;
};
