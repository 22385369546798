import { TextField as MUITextField } from '@mui/material';
import { PropsWithChildren } from 'react';

import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';

import { IIconWrapperProps, ITextFieldProps } from './types';
import { getBorderColor } from './utils';

export const Root = styled('div', {
  shouldForwardProp: blockNonNativeProps('fullWidth'),
})(({ fullWidth }: { fullWidth: boolean }) => ({
  position: 'relative',
  display: 'flex',
  width: fullWidth ? '100%' : 'auto',
}));

export const IconWrapper = styled('span', {
  shouldForwardProp: blockNonNativeProps('hasHelperText'),
})(({ hasHelperText }: IIconWrapperProps) => ({
  position: 'absolute',
  left: '12px',
  top: hasHelperText ? 'calc(50% - 6px)' : 'calc(50% + 2px)',
  transform: 'translateY(-50%)',
})) as React.ComponentType<PropsWithChildren<IIconWrapperProps>>;

export const TextField = styled(
  ({ InputProps, ...props }: ITextFieldProps) => (
    <MUITextField
      {...props}
      InputProps={{
        disableUnderline: true,
        ...InputProps,
      }}
      variant="filled"
    />
  ),
  {
    shouldForwardProp: blockNonNativeProps(['hasLeftIcon', 'warning']),
  },
)<ITextFieldProps>(
  ({ hasLeftIcon, error, warning, size, theme, InputProps }) => {
    const borderColor = getBorderColor(theme, error, warning);

    return {
      '& .MuiInputLabel-root	': {
        fontFamily: theme.font.main,
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '24px',
        color: theme.color.typo.secondary,
        transform: 'translate(12px,13px) scale(1)',
        ...(hasLeftIcon && { transform: 'translate(44px,13px) scale(1)' }),
        '&.Mui-disabled': {
          opacity: 0.4,
        },
        ...(InputProps?.readOnly && { opacity: 0.4 }),
        '& .MuiInputLabel-asterisk': {
          color: error ? theme.color.input.error : theme.color.typo.action,
        },
      },
      '& .MuiInputLabel-shrink': {
        fontSize: '11px',
        lineHeight: '16px',
        color: theme.color.typo.promo,
        transform: 'translate(12px,4px) scale(1)',
        ...(hasLeftIcon && { transform: 'translate(44px,4px) scale(1)' }),
      },
      '& .MuiInputLabel-shrink:not(.Mui-error)': {
        color: theme.color.typo.promo,
      },
      '& .MuiFormLabel-filled': {
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
      '&:hover': {
        '.MuiInputLabel-root:not(.Mui-error, .Mui-disabled)': {
          color: theme.color.typo.promo,
        },
      },
      '& .MuiFilledInput-root': {
        border: `1px solid ${borderColor}`,
        borderRadius: 4,
        backgroundColor: theme.color.input.bg.default,
        '&.Mui-focused': {
          backgroundColor: theme.color.input.bg.focus,
        },
        '&:hover': {
          backgroundColor: theme.color.input.bg.hover,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.color.input.bg.default,
          border: `1px solid ${theme.color.input.border}`,
        },
        '.MuiInputBase-readOnly': {
          backgroundColor: theme.color.input.bg.default,
          color: 'rgba(0,0,0,0.38)',
          cursor: 'default',
        },
      },
      '& .MuiFilledInput-input': {
        color: theme.color.typo.primary,
        fontFamily: theme.font.main,
        ...(size === 'small' && {
          fontSize: '12px',
          lineHeight: '16px',
          padding: '8px',
          height: '14px',
          paddingLeft: hasLeftIcon ? '44px' : '12px',
        }),
        ...(size === 'medium' && {
          fontSize: '15px',
          lineHeight: '24px',
          padding: '18px 12px 4px',
          height: '24px',
          paddingLeft: hasLeftIcon ? '44px' : '12px',
        }),
        '&::placeholder': {
          fontFamily: theme.font.main,
          fontSize: '12px',
          color: theme.color.typo.secondary,
          opacity: 1,
        },
        '&.Mui-disabled': {
          '-webkit-text-fill-color': theme.color.typo.promo,
        },
      },
      '& .MuiFormHelperText-root': {
        margin: 0,
        padding: '0 12px',
        fontFamily: theme.font.main,
        fontSize: '11px',
        lineHeight: '16px',
        fontWeight: '400',
        color: theme.color.typo.secondary,
      },
      '& .Mui-error': {
        '& .MuiFilledInput-input': {
          '&::placeholder': {
            color: theme.color.input.error,
          },
        },
        '&.MuiInputLabel-root, &.MuiFormHelperText-root': {
          color: theme.color.input.error,
        },
      },
    };
  },
) as React.ComponentType<ITextFieldProps>;
