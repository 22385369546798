import { ITableViewRules } from '~/types/commissions';

/**
 * Transforms an array of objects into a string representation, formatting each `rate` value
 * according to its `rule` property. For "percent" rules, appends a "%" symbol, and for "fixed"
 * rules, appends " EUR". Returns a dash if the array is empty.
 *
 * @param {Array.<ITableViewRules>} rules Array of objects with rules and rates.
 * - Input example: [{ rule: 'percent', rate: '5' }, { rule: 'fixed', rate: '20' }]
 * @param {string} currency
 * - Input example: EUR
 * @returns {string} A string representing the formatted rates, separated by commas,
 *                   or a dash if the array is empty.
 * - Output example: "5%, 20 EUR"
 */
export function convertRulesToString(
  rules: ITableViewRules[],
  currency: string,
): string {
  if (!rules.length) {
    return '-';
  }

  return rules
    .map(({ rule, rate }) => {
      if (rule === 'percent') {
        return `${rate}%`;
      }
      if (rule === 'fixed') {
        return `${rate} ${currency}`;
      }

      return '';
    })
    .join(', ');
}
