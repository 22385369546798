import { createApi } from '@reduxjs/toolkit/query/react';

import { globalSummaryService } from '~/resources';
import { IHistoricalGlobalSummaryFilters } from '~/resources/globalSummary/types';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

interface IClientSummaryApiBaseQueryParams {
  date: string;
  currency: string;
  accounts: string[];
}

const defaultParams: IHistoricalGlobalSummaryFilters = {
  byAccounts: false,
  byPositionSide: false,
  excludeAssetAccounts: false,
  expandFxSpots: false,
  filterZeroCurrencies: true,
  reconciliationMode: true,
  showArchived: false,
  useAccPnl: true,
};

export const clientSummaryApi = createApi({
  reducerPath: 'clientSummaryQuery',
  baseQuery: async (args: IClientSummaryApiBaseQueryParams) =>
    transformToBaseQueryResponse(
      await globalSummaryService.getHistoricalGlobalSummary(
        {
          date: args.date,
          currency: args.currency,
          filters: defaultParams,
        },
        { accounts: args.accounts },
      ),
    ),
  endpoints: (build) => ({
    getClientAccountsSummary: build.query({
      query: (params: IClientSummaryApiBaseQueryParams) => params,
    }),
  }),
});

export const { useGetClientAccountsSummaryQuery } = clientSummaryApi;
