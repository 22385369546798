import { styled } from 'react-ui-kit-exante';

export const UpdateTableButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  color: theme.color.typo.secondary,
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  height: '28px',
  outline: 'none',
  padding: 0,

  '& > span': {
    height: '28px',
    width: '28px',
  },

  '&:hover svg': {
    transform: 'rotate(45deg)',
  },
}));

export const UpdateTableTextContainer = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  color: theme.color.typo.secondary,
  display: 'flex',
  flexDirection: 'column',
}));

export const UpdateTableText = styled('span')(({ theme }) => ({
  color: theme.color.typo.promo,
  fontSize: '16px',
  lineHeight: '16px',
}));

export const LastUpdateText = styled('span')(() => ({
  fontSize: '11px',
  lineHeight: '12px',
}));

export const UpdateTableLoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.color.typo.secondary,
  display: 'flex',
  gap: '8px',
  height: '24px',
  justifyContent: 'center',

  '& .loader': {
    height: '24px',
    lineHeight: '24px',
    width: '24px',
  },

  '& .loader > div': {
    height: '24px',
    lineHeight: '24px',
    width: '24px',
  },
}));
