import { uniqueId } from 'lodash';
import { ChangeEvent, ReactNode, useMemo } from 'react';
import { Button } from 'react-ui-kit-exante';

interface IFormInputFileContainerProps {
  accept?: string;
  children: ReactNode;
  multiple?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormInputFileContainer = ({
  accept = '*',
  children,
  multiple = false,
  onChange,
}: IFormInputFileContainerProps) => {
  const id = useMemo(() => uniqueId('form-input-file-container-'), []);

  return (
    <label htmlFor={id}>
      <input
        accept={accept}
        hidden
        id={id}
        multiple={multiple}
        onChange={onChange}
        type="file"
      />

      <Button component="span">{children}</Button>
    </label>
  );
};
