import { useMemo } from 'react';
import { Bookmarks, Table } from 'react-ui-kit-exante';

import { useGetLegalEntityTypesQuery } from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { useLogHandleTime } from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import {
  DEFAULT_SORTING_CURRENCY_RISK,
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
} from '~/pages/GlobalSummaryByAccountsPage/columns';
import {
  GLOBAL_SUMMARY_CURRENCY_RISK,
  CURRENCY_RISK_COLUMNS,
} from '~/pages/GlobalSummaryByAccountsPage/constants';
import { prepareTableTitle } from '~/pages/GlobalSummaryByAccountsPage/helpers';
import { useGlobalSummary } from '~/pages/GlobalSummaryByAccountsPage/hooks';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { transformVariantsToSelectOptions } from '~/shared/utils';

type PageProps = {
  isCurrencyRisk: boolean;
};

type TGlobalSummaryCurrencyRisk = PageProps & IBookmarkResponseProps;

export const GlobalSummaryCurrencyRiskComponent = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
  isCurrencyRisk,
}: TGlobalSummaryCurrencyRisk) => {
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'global-summary-currency-risk',
  );

  const {
    accountTypes,
    additionalActions,
    assetTypes,
    currency,
    data,
    date,
    filteringProps,
    isLoading,
    removeFilter,
    setFilter,
    setSorting,
    ...serverPaginationProps
  } = useGlobalSummary({
    isCurrencyRisk,
    logHandleTime,
    setStartHandleTime,
    tableId: GLOBAL_SUMMARY_CURRENCY_RISK,
  });

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        currency,
        legalEntityOptions,
        assetTypes,
      }).filter(({ accessor }) => CURRENCY_RISK_COLUMNS.includes(accessor)),
    [setFilter, removeFilter, currency, legalEntityOptions, assetTypes],
  );

  const title = prepareTableTitle(date, 'Global Summary currency risk');

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filteringProps.filters)}
        onSaveAsNew={(name) =>
          handleSaveAsNewBookmark(name, filteringProps.filters)
        }
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filteringProps.filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={data?.data || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORTING_CURRENCY_RISK}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      filteringProps={filteringProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={GLOBAL_SUMMARY_CURRENCY_RISK}
      title={title}
      isPinnedHeader
    />
  );
};

export const GlobalSummaryCurrencyRisk = (props: PageProps) => {
  return (
    <WithBookmarks
      component={GlobalSummaryCurrencyRiskComponent}
      pageName="GlobalSummaryCurrencyRisk"
      tableId={GLOBAL_SUMMARY_CURRENCY_RISK}
      {...props}
    />
  );
};
