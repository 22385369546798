import { ReactNode, SyntheticEvent, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Tabs as KitTabs, Tab } from 'react-ui-kit-exante';

import { ITab } from '~/types/tabs';

import TabsStyles from './Tabs.module.css';

interface ITabsProps<TabsIds extends string, ComponentProps> {
  activeTab: number;
  onChange: (event: SyntheticEvent | null, newValue: number) => void;
  tabs: ITab<TabsIds, ComponentProps>[];
  actions?: ReactNode;
}

export const Tabs = <
  TabsIds extends string,
  ComponentProps extends Record<keyof ComponentProps, unknown>,
>({
  activeTab,
  onChange,
  tabs,
  actions,
}: ITabsProps<TabsIds, ComponentProps>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentTab = Number(localStorage.getItem('currentTab'));

  const clearQueryParamsBeforeChange = () => {
    // Delete all query params
    Array.from(searchParams.entries())
      .map(([key]) => key)
      .forEach((key) => searchParams.delete(key));

    // Reset query params. Pass state to don't lose it
    setSearchParams(searchParams, { state: location.state });
  };

  const handleChange = (event: SyntheticEvent | null, newValue: number) => {
    clearQueryParamsBeforeChange();
    onChange(event, newValue);

    localStorage.setItem('currentTab', newValue.toString());
  };

  useEffect(() => {
    if (currentTab) {
      handleChange(null, currentTab);
    }

    return () => localStorage.removeItem('currentTab');
  }, []);

  return (
    <div className={TabsStyles.Wrapper}>
      <KitTabs value={activeTab} onChange={handleChange}>
        {tabs.map((tab) => (
          <Tab key={tab.id || tab.title} label={tab.title} />
        ))}
      </KitTabs>

      {actions}
    </div>
  );
};
