import { AUTHORIZATION_HEADER_KEY, SESSION_ID_HEADER_KEY } from '~/constants';

import { TAccessToken } from '../types';

/**
 * Generates default headers for an HTTP request, including content type and optional session or authorization headers.
 *
 * This function creates a base header object with 'Content-Type' set to 'application/json'.
 * If a sessionId is provided, it includes the session ID header.
 * If an accessToken is provided, it parses the token and includes the authorization header.
 * If both sessionId and accessToken are provided, only sessionId is used.
 *
 * @param {string} [sessionId=''] - The session ID to include in the headers.
 * @param {string} [accessToken=''] - The access token to include in the headers.
 * @returns {Object} The default headers object for an HTTP request.
 */
export function getDefaultHeaders(sessionId = '', accessToken = '') {
  const baseHeaders: { 'Content-Type': string } = {
    'Content-Type': 'application/json',
  };

  if (sessionId) {
    return { ...baseHeaders, [SESSION_ID_HEADER_KEY]: sessionId };
  }

  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);

      return {
        ...baseHeaders,
        [AUTHORIZATION_HEADER_KEY]: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}
