import { createSelector } from '@reduxjs/toolkit';

import { selectEntity } from '~/store/treeStructure';
import type { TApplicationState } from '~/types/store';
import { FilterLayers } from '~/types/treeStructure';

export const selectCurrentLimitSet = createSelector(
  [selectEntity, (state: TApplicationState) => state],
  (entityId, state) => {
    if (state.treeStructure.layer === FilterLayers.Default) {
      return Object.values(state.limits.sets).find((item) => item.isDefault);
    }

    if (state.treeStructure.layer === FilterLayers.Groups && entityId) {
      return state.limits.sets[entityId];
    }

    return undefined;
  },
);
