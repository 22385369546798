import { styled } from 'react-ui-kit-exante';

import { FormSelectContainer } from '~/containers/form';

export const StyledFormSelect = styled(FormSelectContainer)(() => ({
  marginTop: '16px',
}));

export const StyledButton = styled('button')(({ theme }) => ({
  display: 'inline-flex',
  fontWeight: 500,
  fontSize: '13px',
  border: 0,
  background: 'transparent',
  marginTop: '20px',
  cursor: 'pointer',

  '& + &': {
    marginLeft: '16px',
  },
  '&[type="submit"]': {
    color: theme?.color.typo?.action,
  },
  '&[type="button"]': {
    color: theme?.color.typo?.promo,
  },
}));
