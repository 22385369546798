import { useCallback, useEffect } from 'react';
import { useData, Notification } from 'react-ui-kit-exante';

import {
  interestRatesApi,
  useLazyGetRegularPaymentsSettingsQuery,
  useUpdateRegularPaymentsSettingsMutation,
} from '~/api';
import { TGroupId } from '~/types/interestRates/interestRates';

import { DEFAULT_ACCOUNT_GROUP } from '../../constants';

const DEFAULT_GROUP = {
  groupName: 'Default',
  groupId: null,
  regularPaymentsSettings: null,
};

export const useAccountGroup = (id: string | null, preCall: () => void) => {
  const { data: groupList = [] } =
    interestRatesApi.useGetInterestsGroupsQuery();
  const [getRegularPaymentsSettings] = useLazyGetRegularPaymentsSettingsQuery();
  const [updateRegularPaymentsSettings] =
    useUpdateRegularPaymentsSettingsMutation();

  const getGroupNameById = useCallback(
    async (interestsSetId: TGroupId | null) => {
      if (interestsSetId === null) {
        return DEFAULT_ACCOUNT_GROUP;
      }

      if (groupList === null) {
        return DEFAULT_ACCOUNT_GROUP;
      }

      const groupName = groupList.find(
        ({ id: groupId }) => groupId === interestsSetId,
      );

      return groupName?.name || DEFAULT_ACCOUNT_GROUP;
    },
    [groupList],
  );

  const getAccountGroup = useCallback(async () => {
    preCall();

    if (!id) {
      return DEFAULT_GROUP;
    }

    const regularPaymentsSettings = await getRegularPaymentsSettings({
      accountId: id,
    });

    if ('error' in regularPaymentsSettings) {
      return {
        groupName: null,
        groupId: null,
        regularPaymentsSettings: {},
      };
    }
    const groupId = regularPaymentsSettings?.data?.interestsSetId as TGroupId;
    const groupName = await getGroupNameById(groupId);

    return {
      groupName,
      groupId,
      regularPaymentsSettings,
    };
  }, [preCall, id, getGroupNameById, getRegularPaymentsSettings]);

  const { data, fetchData, isLoading } = useData({
    onFetch: getAccountGroup,
  });

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const onChangeAccountGroup = useCallback(
    async (groupId: number | null) => {
      const paymentsSettings = data?.regularPaymentsSettings;

      if (paymentsSettings && id) {
        const res = await updateRegularPaymentsSettings({
          accountId: id,
          regularPaymentsSettings: {
            ...data.regularPaymentsSettings,
            interestsSetId: String(groupId) === 'default' ? null : groupId,
          },
        });

        if (!('error' in res)) {
          await fetchData();
          Notification.success({
            title: 'Interests set for account successfully changed',
          });
        }
      }
    },
    [
      data?.regularPaymentsSettings,
      fetchData,
      id,
      updateRegularPaymentsSettings,
    ],
  );

  return {
    accountData: data || DEFAULT_GROUP,
    accountDataLoading: isLoading,
    onChangeAccountGroup,
  };
};
