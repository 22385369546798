import {
  DEFAULT_ACCOUNT_ACCESS,
  accountAccessTypes,
} from '~/api/accounts/constants';
import { UserServices } from '~/resources/services/types';
import { IAccountPermissionData } from '~/types/accountPermissions';
import { IAtpPermissionsResponse } from '~/types/accountPermissions/atpPermissions';
import { TDefaultAccountAccess } from '~/types/accountPermissions/defaultAccountAccess';
import { IAuth, TServices } from '~/types/services';
import { ICoreFormUser } from '~/types/users';

import { IServiceState, TServiceStateMap } from './types';

const EXCLUDED_SERVICES = ['authdb'];
const DEFAULT_SERVICES_STATE = {} as TServiceStateMap;

type TInnerAtpPermissions = IAtpPermissionsResponse | null | undefined;

interface IInitServicesConfigArguments {
  atpPermissions: TInnerAtpPermissions;
  defaultAccountAccess: TDefaultAccountAccess | undefined;
}

export function getLabelAtpPermissions(
  permissions: IServiceState['permissions'],
) {
  if (!permissions) {
    return null;
  }

  return Object.keys(permissions).reduce((acc, name) => {
    if (name === 'superuser') {
      return acc;
    }

    acc.push([
      name,
      name
        .replace(/^.|_(atp)(_|$)/g, (str: string) => str.toUpperCase())
        .replace(/_mam(_|$)/g, '_MAT$1')
        .replace(/_/g, ' '),
    ]);

    return acc;
  }, [] as [string, string][]);
}

export function initServicesConfig({
  atpPermissions,
  defaultAccountAccess,
}: IInitServicesConfigArguments): Record<string, TInnerAtpPermissions> {
  if (!atpPermissions) {
    return {
      atp: {} as TInnerAtpPermissions,
    };
  }

  return {
    atp: {
      ...atpPermissions,
      superuser:
        defaultAccountAccess === null
          ? DEFAULT_ACCOUNT_ACCESS
          : (defaultAccountAccess as (typeof accountAccessTypes)[number]),
    },
  };
}

export function buildServicesState(
  user: ICoreFormUser | undefined,
  services: TServices | null | undefined,
  permissions: IAccountPermissionData | null | undefined,
  userServices: UserServices,
): TServiceStateMap {
  if (!user || !services) {
    return DEFAULT_SERVICES_STATE;
  }

  const atpPermissions = permissions?.atpPermissions;
  const defaultAccountAccess = permissions?.defaultAccountAccess;

  const servicesConfig = initServicesConfig({
    atpPermissions,
    defaultAccountAccess,
  });

  const existingPermissions = userServices.reduce((acc, { service }) => {
    acc.add(service);
    return acc;
  }, new Set<string>());

  const renderingServices = services.filter(
    (service) => !EXCLUDED_SERVICES.includes(service),
  );

  return renderingServices.reduce<TServiceStateMap>((acc, curr) => {
    let modifiedItem = {
      enabled: false,
    };

    if (existingPermissions.has(curr)) {
      modifiedItem = { ...modifiedItem, enabled: true };
    }

    if (servicesConfig[curr]) {
      modifiedItem = {
        ...modifiedItem,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        permissions: { ...servicesConfig[curr] },
      };
    }

    return { ...acc, [curr]: modifiedItem };
  }, {} as TServiceStateMap);
}
