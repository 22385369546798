import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TSaveButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'>;

export const SaveButton = ({ label = 'Save', ...props }: TSaveButtonProps) => {
  return (
    <IconButton
      {...props}
      iconName="SaveIcon"
      iconColor="action"
      label={label}
      type="submit"
      iconSize={24}
    />
  );
};
