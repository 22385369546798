import { isKeyOf } from './isKeyOf';

export type TReduceFn<T, U> = (acc: U, key: keyof T) => U;

export const reduceByKeys = <T extends Partial<Record<keyof T, unknown>>, U>(
  targetObj: T,
  callbackFn: TReduceFn<T, U>,
  initialValue: U,
) => {
  return Object.keys(targetObj).reduce((acc, key) => {
    if (isKeyOf(key, targetObj)) {
      return callbackFn(acc, key);
    }

    return acc;
  }, initialValue);
};
