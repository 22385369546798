import { useCallback, useMemo } from 'react';

import {
  useGetCashConversionSettingsQuery,
  useGetCommissionSettingsQuery,
  useGetCoreAccountQuery,
  useGetGroupSettingsQuery,
  useGetMarginSettingsQuery,
  useGetRebatesQuery,
  useGetRiskSettingsQuery,
  useGetRoutingSettingsQuery,
  useGetExtReportsSettingsQuery,
  useGetInternalSettingsQuery,
  useGetRegularPaymentsSettingsQuery,
  useUpdateAccountMutation,
} from '~/api';
import { TAccountRegularCommissionsTableRow } from '~/resources/regularCommissions/types';
import { useGetRegularCommissionsQuery } from '~/store/account';
import { IUseQueryReturn } from '~/types/rtkQuery';

export function useAccountSettingsData(accountId: string) {
  const [updateAccount] = useUpdateAccountMutation();
  const accountQuery = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );

  const regularPaymentsQuery = useGetRegularPaymentsSettingsQuery({
    accountId,
  });

  const extReportsQuery = useGetExtReportsSettingsQuery({ accountId });

  const internalQuery = useGetInternalSettingsQuery({ accountId });

  const cashConversionQuery = useGetCashConversionSettingsQuery({ accountId });

  const commissionQuery = useGetCommissionSettingsQuery({
    accountId,
  });

  const accountGroupSettingsQuery = useGetGroupSettingsQuery(
    {
      accountId,
    },
    { skip: !accountId },
  );

  const routingQuery = useGetRoutingSettingsQuery({ accountId });

  const regularCommissionsQuery =
    useGetRegularCommissionsQuery<
      IUseQueryReturn<TAccountRegularCommissionsTableRow[]>
    >(accountId);

  const rebatesQuery = useGetRebatesQuery({ accountId });

  const marginQuery = useGetMarginSettingsQuery({ accountId });

  const riskQuery = useGetRiskSettingsQuery({ accountId });

  const isAccountLoading = accountQuery.isLoading || accountQuery.isFetching;

  const isRoutingTagsLoading =
    routingQuery.isLoading || routingQuery.isFetching;

  const isRegularCommissionsLoading =
    regularCommissionsQuery.isLoading || regularCommissionsQuery.isFetching;

  const isGroupSettingsLoading =
    accountGroupSettingsQuery.isLoading || accountGroupSettingsQuery.isFetching;

  const isGeneralSettingsLoading =
    isAccountLoading ||
    isRegularCommissionsLoading ||
    extReportsQuery.isLoading ||
    extReportsQuery.isFetching ||
    internalQuery.isLoading ||
    internalQuery.isFetching ||
    cashConversionQuery.isLoading ||
    cashConversionQuery.isFetching ||
    commissionQuery.isLoading ||
    commissionQuery.isFetching ||
    isRoutingTagsLoading ||
    isGroupSettingsLoading;

  const isRebatesLoading = rebatesQuery.isLoading || rebatesQuery.isFetching;

  const isRiskLoading =
    marginQuery.isLoading ||
    marginQuery.isFetching ||
    riskQuery.isLoading ||
    riskQuery.isFetching;

  const isDataLoading =
    isAccountLoading ||
    isGeneralSettingsLoading ||
    isRoutingTagsLoading ||
    isRegularCommissionsLoading ||
    isRebatesLoading ||
    isRiskLoading;

  // isError needs for disable rerendering of components https://jira.exante.eu/browse/WBU-933
  const isError =
    accountQuery.error ||
    regularPaymentsQuery.error ||
    extReportsQuery.error ||
    internalQuery.error ||
    cashConversionQuery.error ||
    commissionQuery.error ||
    routingQuery.error ||
    regularCommissionsQuery.error ||
    rebatesQuery.error ||
    marginQuery.error ||
    riskQuery.error ||
    accountGroupSettingsQuery.error;

  const queriesList: [
    string,
    (
      | typeof accountQuery
      | typeof regularPaymentsQuery
      | typeof extReportsQuery
      | typeof internalQuery
      | typeof cashConversionQuery
      | typeof commissionQuery
      | typeof routingQuery
      | typeof regularCommissionsQuery
      | typeof rebatesQuery
      | typeof marginQuery
      | typeof riskQuery
      | typeof accountGroupSettingsQuery
    ),
  ][] = useMemo(() => {
    return [
      ['account', accountQuery],
      ['regularPayments', regularPaymentsQuery],
      ['extReports', extReportsQuery],
      ['internal', internalQuery],
      ['cashConversion', cashConversionQuery],
      ['commission', commissionQuery],
      ['routing', routingQuery],
      ['regularCommissions', regularCommissionsQuery],
      ['rebates', rebatesQuery],
      ['margin', marginQuery],
      ['risk', riskQuery],
      ['groupSettings', accountGroupSettingsQuery],
    ];
  }, [
    accountGroupSettingsQuery,
    accountQuery,
    cashConversionQuery,
    commissionQuery,
    extReportsQuery,
    internalQuery,
    marginQuery,
    rebatesQuery,
    regularCommissionsQuery,
    regularPaymentsQuery,
    riskQuery,
    routingQuery,
  ]);

  const queriesMap = useMemo(() => {
    const map = new Map();

    queriesList.forEach(([queryName, query]) => map.set(queryName, query));

    return map;
  }, [queriesList]);

  const getQuery = useCallback(
    (queryName: string) => {
      if (queriesMap.has(queryName)) {
        return queriesMap.get(queryName);
      }
      console.error(
        `[useAccountSettingsData] Query with name ${queryName} does not exist`,
      );
      return null;
    },
    [queriesMap],
  );

  const refetchAll = useCallback(() => {
    return Promise.all(queriesList.map(([, query]) => query.refetch()));
  }, [queriesList]);

  const refetchQuery = useCallback(
    async (queryName: string) => {
      const query = getQuery(queryName);

      if (query) {
        return query.refetch();
      }

      return null;
    },
    [getQuery],
  );

  return {
    mutations: {
      updateAccount,
    },
    queries: {
      accountQuery,
      regularPaymentsQuery,
      extReportsQuery,
      internalQuery,
      cashConversionQuery,
      commissionQuery,
      routingQuery,
      regularCommissionsQuery,
      rebatesQuery,
      marginQuery,
      riskQuery,
      accountGroupSettingsQuery,
    },
    isDataLoading,
    isError,
    refetchAll,
    refetchQuery,
  };
}
