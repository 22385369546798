import { ICellValue } from 'react-ui-kit-exante';

import {
  CheckboxCell,
  InputCell,
  SelectAction,
  ConvertToCell,
} from './components';
import type { TableData } from './types';

export const getColumns = () => [
  {
    Header: 'Expired Instrument',
    id: 'symbolId',
    accessor: 'symbolId',
    width: 125,
    sortType: 'basic',
    filter: 'text',
    Cell: ({ row }: ICellValue<TableData>) => {
      return row.original.optionGroupId || row.original.symbolId;
    },
  },
  {
    Header: 'Action',
    id: 'action',
    accessor: 'action',
    width: 125,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }: ICellValue<TableData>) => {
      return <SelectAction originalValues={row.original} rowId={row.id} />;
    },
  },
  {
    Header: 'Close price',
    id: 'closePrice',
    accessor: 'closePrice',
    width: 125,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }: ICellValue<TableData>) => {
      return <InputCell id="closePrice" originalValues={row.original} />;
    },
  },
  {
    Header: 'Convert to spot',
    id: 'convertInto',
    accessor: 'convertInto',
    width: 125,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }: ICellValue<TableData>) => {
      return (
        <ConvertToCell
          id="convertInto"
          originalValues={row.original}
          rowId={row.id}
        />
      );
    },
  },
  {
    Header: 'Underlying price',
    id: 'underlyingPrice',
    accessor: 'underlyingPrice',
    width: 125,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }: ICellValue<TableData>) => {
      return (
        <InputCell
          id="underlyingPrice"
          hide={() => !row.original.optionGroupId}
          originalValues={row.original}
        />
      );
    },
  },
  {
    Header: 'Commission',
    id: 'takeCommission',
    accessor: 'takeCommission',
    width: 125,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }: ICellValue<TableData>) => {
      return <CheckboxCell id="takeCommission" originalValues={row.original} />;
    },
  },
];
