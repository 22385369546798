import { ILogHandleTimeRequest } from '~/types/influxProxy';

import { InfluxProxyRepository } from './influxProxy.repository';

export class InfluxProxyService {
  public async postLogHandleTime(data: ILogHandleTimeRequest) {
    try {
      InfluxProxyRepository.fetchLogHandleTime(data);
    } catch (error) {
      console.error(`network error: ${error}`);
    }
  }
}
