import { TradeFormValues } from './types';

export const DEFAULT_VALUES: TradeFormValues = {
  nonSwitched: {
    accountId: '',
    accruedInterest: '',
    brokerAccountId: '',
    brokerClientId: '',
    comment: '',
    counterparty: '',
    execBroker: '',
    executionSchemeId: '',
    internalComment: '',
    price: '',
    processAccruedInterest: false,
    quantity: '',
    settlementBrokerAccountId: '',
    settlementBrokerClientId: '',
    settlementCounterparty: '',
    side: 'buy',
    symbolId: '',
    symbolIdOverride: '',
    takeCommission: false,
    tradeType: '',
    use4EyesCheck: false,
    user: '',
    valueDate: new Date(),
  },
  switched: {
    accountId: '',
    comment: '',
    counterparty: '',
    internalComment: '',
    price: '',
    quantity: '',
    settlementCounterparty: '',
    side: 'sell',
    symbolId: '',
    takeCommission: false,
    tradeType: '',
    user: '',
    valueDate: new Date(),
  },
};

export const FIELDS_FOR_RESET_AFTER_SUCCESS_SUBMIT = [
  'nonSwitched.brokerAccountId',
  'nonSwitched.brokerClientId',
  'nonSwitched.price',
  'nonSwitched.quantity',
  'nonSwitched.settlementBrokerAccountId',
  'nonSwitched.settlementBrokerClientId',
  'nonSwitched.symbolId',
  'nonSwitched.valueDate',
] as const;

export const WATCHED_NON_SWITCHED_FIELDS_FOR_COMMISSION = [
  'nonSwitched.accountId',
  'nonSwitched.price',
  'nonSwitched.quantity',
  'nonSwitched.symbolId',
  'nonSwitched.takeCommission',
] as const;

export const WATCHED_SWITCHED_FIELDS_FOR_COMMISSION = [
  'switched.accountId',
  'switched.price',
  'switched.quantity',
  'switched.symbolId',
  'switched.takeCommission',
] as const;

export const WATCHED_FIELDS_FOR_SWITCH_FORM = [
  'nonSwitched.counterparty',
  'nonSwitched.price',
  'nonSwitched.quantity',
  'nonSwitched.settlementCounterparty',
  'nonSwitched.side',
  'nonSwitched.symbolId',
  'nonSwitched.tradeType',
  'nonSwitched.user',
  'nonSwitched.valueDate',
] as const;

export const DEPENDED_FIELDS_FOR_SWITCH_FORM = [
  'switched.counterparty',
  'switched.price',
  'switched.quantity',
  'switched.settlementCounterparty',
  'switched.side',
  'switched.symbolId',
  'switched.tradeType',
  'switched.user',
  'switched.valueDate',
] as const;

export const SIDE_OPTIONS = [
  { value: 'buy', label: 'Buy' },
  { value: 'sell', label: 'Sell' },
];
