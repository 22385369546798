import { cloneDeep, flatten } from 'lodash';

import type { ActionTypes } from '../constants';
import { IStatePasswordManager, TPasswordManagerPayload } from '../types';

export function updateInitialTokens(
  state: IStatePasswordManager,
  newTokens: TPasswordManagerPayload[ActionTypes.UPDATE_INITIAL_TOKENS],
) {
  const newData = {
    ...state.data,
    ...newTokens,
  };

  const dictIdName = flatten(Object.values(newTokens)).reduce<
    Record<number, string>
  >((acc, token) => {
    acc = {
      ...acc,
      [token.id]: token.name,
    };

    return acc;
  }, {});

  return {
    ...state,
    data: cloneDeep(newData),
    initial: cloneDeep(newData),
    dictIdName,
  };
}
