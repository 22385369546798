import { useCallback } from 'react';
import {
  matchPath as match,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useGetCoreUserQuery, useLazyGetUsersQuery } from '~/api';
import { useEntitiesNavigation, useNavigationParams } from '~/hooks';
import { TabsContextProvider } from '~/hooks/useTabs/useTabs';
import {
  getUserPageRoute,
  USER_INTERNAL_PATH,
  USER_PATH,
  USERS_INTERNAL_PATH,
  USERS_PATH,
} from '~/routes';
import {
  NavigationArrows,
  StaticCenteredLoader,
  CloseButton,
} from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { paramsSerializer } from '~/shared/utils/apiRequest/helpers';
import { ILocationState, TParams } from '~/types/router';
import { TUserWithIdOnly } from '~/types/users';

import { TabManagement } from './TabManagement';
import { useUserTabs } from './TabManagement/hooks';
import { UserPageTemplate } from './UserPageTemplate';
import { UserPageContextManager } from './context';
import { useUserPageContextManager } from './hooks';

export const UserPageComponent = () => {
  const { id } = useParams<TParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestParams, cursor } = (location.state as ILocationState) || {};
  const [getUsers] = useLazyGetUsersQuery();

  const tabs = useUserTabs(Number(id));

  const { data, isLoading } = useGetCoreUserQuery(
    { id: Number(id) },
    { skip: !id, refetchOnMountOrArgChange: true },
  );
  const userName = data?.username || '';

  const { refreshActiveTab, setRefreshFn, isRefreshing } =
    useUserPageContextManager();

  const params = useNavigationParams<TUserWithIdOnly>({
    requestParams,
    cursor,
    fetchData: async (args: TParams) => {
      const response = await getUsers({ ...args, idsOnly: true });

      if (!response.data || response.error) {
        return [];
      }

      return response.data.users;
    },
    path: getUserPageRoute,
  });
  const {
    isNextDisabled,
    isPreviousDisabled,
    handleNextClick,
    handlePreviousClick,
  } = useEntitiesNavigation(params);

  const getBasePath = (currentPath: string) => {
    const defaultBasePath = USERS_PATH;

    if (match(USER_PATH, currentPath)) {
      return USERS_PATH;
    }
    if (match(USER_INTERNAL_PATH, currentPath)) {
      return USERS_INTERNAL_PATH;
    }

    return defaultBasePath;
  };

  const handleCloseClick = useCallback(() => {
    const basePath = getBasePath(location.pathname);
    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${basePath}?${paramsSerializer(rest)}`);
  }, [location.pathname, navigate, requestParams]);

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="80vh" />;
  }

  if (!id || !data) {
    return <div>No user</div>;
  }

  return (
    <UserPageTemplate
      header={
        <EntryScreenActions
          title={data.username}
          navigationArrows={
            <NavigationArrows
              previousDisabled={isPreviousDisabled}
              nextDisabled={isNextDisabled}
              onPreviousClick={handlePreviousClick}
              onNextClick={handleNextClick}
            />
          }
          onRefresh={refreshActiveTab}
          isRefreshLoading={isRefreshing}
          closeButton={<CloseButton onClick={handleCloseClick} />}
        />
      }
      tabManagement={
        <TabManagement
          tabs={tabs}
          userId={data.id}
          userName={userName}
          setRefetch={setRefreshFn}
        />
      }
    />
  );
};

export const UserPage = () => {
  return (
    <TabsContextProvider>
      <UserPageContextManager>
        <UserPageComponent />
      </UserPageContextManager>
    </TabsContextProvider>
  );
};
