import { useState, useLayoutEffect, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CodeEditor, FormHelperText } from 'react-ui-kit-exante';

import { FormItem } from '~/shared/components';

interface IFormCodeEditorContainerProps {
  disabled?: boolean;
  height?: string;
  label?: string;
  mode?: string;
  name: string;
  theme?: string;
  validateMode?: 'onChange' | 'onBlur' | 'onSubmit' | 'onTouched' | 'all';
}

export const FormCodeEditorContainer = ({
  disabled = false,
  label,
  mode = 'json',
  name,
  theme = 'github',
  validateMode = 'onSubmit',
  ...props
}: IFormCodeEditorContainerProps) => {
  const formContext = useFormContext();
  const { control, trigger } = formContext;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const [editorValue, setEditorValue] = useState(() => {
    if (mode === 'json' && typeof value !== 'string') {
      return JSON.stringify(value, null, 2);
    }

    return value;
  });

  const onChangeHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);

      if (validateMode === 'onChange') {
        trigger(newValue);
      }
    },
    [validateMode, trigger, name, onChange],
  );

  // useLayoutEffect need because have problem with caret position
  // https://jira.exante.eu/browse/WBU-148 point 3
  useLayoutEffect(() => {
    if (mode === 'json' && typeof value !== 'string') {
      setEditorValue(JSON.stringify(value, null, 2));
    } else {
      setEditorValue(value);
    }
  }, [mode, value]);

  formContext.register(name, { disabled });

  return (
    <FormItem direction="column">
      {label && <h3>{label}</h3>}
      <CodeEditor
        disableContentPaddings
        mode={mode}
        onChange={onChangeHandler}
        theme={theme}
        value={editorValue}
        tabSize={2}
        {...props}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormItem>
  );
};
