import { ReactElement, ReactNode } from 'react';
import { Panel } from 'react-ui-kit-exante';

import BackofficePermissionsStyles from './BackofficePermissions.module.css';

interface IBackofficePermissionsProps {
  actions: ReactElement;
  table: ReactNode;
}

export const BackofficePermissions = ({
  actions,
  table,
}: IBackofficePermissionsProps) => {
  return (
    <Panel
      className={BackofficePermissionsStyles.Panel}
      title="Backoffice permissions"
      disableBodyPaddings
      action={actions}
    >
      <div className={BackofficePermissionsStyles.Content}>{table}</div>
    </Panel>
  );
};
