import { isEmpty } from 'lodash';

import type { TApplicationState } from '~/types/store';

export const selectLimitsTree = (state: TApplicationState) => {
  if (state.limits.refreshMode.isEnabled) {
    return state.limits.refreshMode.tree;
  }

  if (!isEmpty(state.limits.tree.searchMode)) {
    return state.limits.tree.searchMode;
  }

  return state.limits.tree.standardMode;
};
