import { useState, useEffect } from 'react';

export const useLocalStoragePolling = (key: string, interval = 1000) => {
  const [data, setData] = useState<string>(localStorage.getItem(key) || '');

  useEffect(() => {
    const checkLocalStorage = () => {
      const currentData = localStorage.getItem(key);
      // Обработка случая, когда currentData равно null
      const valueToSet = currentData === null ? '' : currentData;

      if (valueToSet !== data) {
        setData(valueToSet);
      }
    };

    const intervalId = setInterval(checkLocalStorage, interval);

    return () => clearInterval(intervalId);
  }, [data, key, interval]);

  return data;
};
