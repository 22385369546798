import { TUserPermission } from '../../../../resources/usersPermission/types';
import { UsersPermissionItem } from '../UsersPermissionItem';

import UsersPermissionListStyles from './UsersPermissionList.module.css';

interface IUsersPermissionListProps {
  users: TUserPermission[];
  onRemove: (index: number, isNew?: boolean) => void;
}

export const UsersPermissionList = ({
  users,
  onRemove,
}: IUsersPermissionListProps) => {
  return (
    <ul className={UsersPermissionListStyles.List}>
      {users.map(
        ({ overrideAccountStatus, userId, status, ...rest }, index) => (
          <UsersPermissionItem
            index={index}
            key={userId}
            userId={String(userId)}
            onRemove={onRemove}
            {...rest}
          />
        ),
      )}
    </ul>
  );
};
