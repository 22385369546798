import { IBrokerClientAccount } from '~/types/symbols';

export interface ISymbolsState {
  symbolIdInfo: IBrokerClientAccount[];
  counterparties: Record<string, IBrokerClientAccount>;
  settlementCounterparties: Record<string, IBrokerClientAccount[]>;
}

export const INITIAL_STATE: ISymbolsState = {
  symbolIdInfo: [],
  counterparties: {},
  settlementCounterparties: {},
};
