import { omit } from 'lodash';
import { useState } from 'react';

import { IInterestRate } from '~/types/interestRates';

import { defaultFilters } from '../../components/InterestRatesTable/constants';

export const useClientFilters = () => {
  const [filters, setFilters] =
    useState<Partial<Record<keyof IInterestRate, unknown>>>(defaultFilters);

  const onRemove = (columnName: keyof IInterestRate) =>
    setFilters((prev) => omit(prev, columnName));

  const removeAllFilters = () => {
    setFilters(() => ({}));
  };

  return {
    filters,
    setFilters,
    onRemove,
    removeAllFilters,
  };
};
