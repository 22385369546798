import { AccountsStatistic } from './components';
import { useAccountsStatistic } from './hooks';

type TAccountsStatisticContainerProps = {
  filters: Record<string, unknown>;
};

export const AccountsStatisticContainer = ({
  filters,
}: TAccountsStatisticContainerProps) => {
  const { data, isLoading, isFetching, error } = useAccountsStatistic(filters);

  return (
    <AccountsStatistic
      loading={isLoading || isFetching}
      items={data}
      error={Boolean(error)}
    />
  );
};
