export enum MirroringRuleOptions {
  Categories = 'Categories',
  Transactions = 'Transactions',
}

export const radioOptions = [
  {
    label: MirroringRuleOptions.Categories,
    value: MirroringRuleOptions.Categories,
  },
  {
    label: MirroringRuleOptions.Transactions,
    value: MirroringRuleOptions.Transactions,
  },
];

export enum TransferMultiplierOptions {
  Positive = '1',
  Negative = '-1',
}

export const transferMultiplierOptions = [
  {
    label: TransferMultiplierOptions.Positive,
    value: TransferMultiplierOptions.Positive,
  },
  {
    label: TransferMultiplierOptions.Negative,
    value: TransferMultiplierOptions.Negative,
  },
];
