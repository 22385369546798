import {
  PanelGroup,
  Panel,
  TAutocompleteAsyncFetchData,
} from 'react-ui-kit-exante';

import {
  FormCheckboxContainer,
  FormDatePickerContainer,
  FormInputContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';
import { PanelHeaderControls } from '~/shared/components';
import { IOption } from '~/types/form';

import { MENU_PROPS } from '../../constants';

import TransactionStyles from './Transaction.module.css';

interface ITransactionProps {
  disabledFields: Record<string, boolean>;
  fetchData: Record<string, TAutocompleteAsyncFetchData>;
  onCloseHandler(): void;
  onSubmitHandler(): Promise<void>;
  options: Record<string, Array<IOption>>;
  disableSaveButton: boolean;
}

export const Transaction = ({
  disableSaveButton,
  disabledFields,
  fetchData,
  onCloseHandler,
  onSubmitHandler,
  options,
}: ITransactionProps) => {
  return (
    <form onSubmit={onSubmitHandler}>
      <Panel
        action={
          <PanelHeaderControls
            onClose={onCloseHandler}
            disableSaveButton={disableSaveButton}
          />
        }
        disableBodyPaddings
        title="Add manual transaction"
      >
        <PanelGroup className={TransactionStyles.Wrapper}>
          <Panel className={TransactionStyles.Column}>
            <FormSelectAsyncContainer
              fetchData={fetchData.accountId}
              label="Account id"
              name="accountId"
            />

            <FormSelectAsyncContainer
              autoSelect
              fetchData={fetchData.asset}
              freeSolo
              label="Asset"
              name="asset"
            />

            <FormSelectAsyncContainer
              autoSelect
              fetchData={fetchData.symbolId}
              freeSolo
              label="Symbol"
              name="symbolId"
            />

            <FormToggleButtonGroupContainer
              alwaysSelected
              exclusive
              label="Type"
              name="transactionType"
              options={options.transactionType}
            />

            <FormInputContainer label="Amount" name="amount" />

            <FormInputContainer label="Price" name="price" />

            <FormInputContainer
              label="Market price"
              name="extraData.marketPrice"
            />

            <FormSelectContainer
              label="Operation type"
              menuProps={MENU_PROPS}
              name="operationType"
              options={options.operationType}
            />

            <FormInputContainer
              disabled={disabledFields.dependentFieldsFromOperationType}
              label="Transfer Id"
              name="transferId"
            />

            <FormInputContainer
              disabled={disabledFields.dependentFieldsFromOperationType}
              label="Client counterparty"
              name="clientCounterparty"
            />

            <FormSelectContainer
              disabled={disabledFields.dependentFieldsFromOperationType}
              label="Internal counterparty"
              menuProps={MENU_PROPS}
              name="exanteCounterparty"
              options={options.externalCounterparty}
            />
          </Panel>

          <Panel className={TransactionStyles.Column}>
            <FormDatePickerContainer label="Value date" name="valueDate" />

            <FormInputContainer
              label="Settlement counterparty"
              name="settlementCounterparty"
            />

            <FormInputContainer
              label="Counterparty account Id"
              name="counterpartyAccountId"
            />

            <FormInputContainer
              disabled={disabledFields.executionCounterparty}
              label="Execution counterparty"
              name="executionCounterparty"
            />

            <FormCheckboxContainer
              disabled={disabledFields.autoConversion}
              label="Use autoconversion"
              name="useAutoCashConversion"
            />

            <FormInputContainer label="Comment" name="comment" />

            <FormInputContainer
              label="Internal comment"
              name="internalComment"
            />

            <FormCheckboxContainer
              label="Take commission"
              name="takeCommission"
            />

            <FormSelectContainer
              disabled={disabledFields.commission}
              label="Commission currency"
              menuProps={MENU_PROPS}
              name="commission.currency"
              options={options.currencies}
            />

            <FormInputContainer
              disabled={disabledFields.commission}
              label="Commission value"
              name="commission.value"
            />

            <FormSelectContainer
              disabled={disabledFields.commission}
              label="Commission operation type"
              menuProps={MENU_PROPS}
              name="commission.type"
              options={options.operationType}
            />

            <FormCheckboxContainer
              label="Use 4 eyes check"
              name="extraData.4EyesCheck"
            />
          </Panel>
        </PanelGroup>
      </Panel>
    </form>
  );
};
