import * as React from 'react';

import { Tooltip } from '../Tooltip';

import { Line } from './Indicator.styled';
import { IIndicatorLine } from './types';

export interface IIndicatorPartProps extends IIndicatorLine {
  disableAnimation?: boolean;
}

export const IndicatorPart = ({
  value,
  color,
  tooltipText,
  dataTestId,
  className,
  disableAnimation = false,
}: IIndicatorPartProps) => {
  const component = (
    <Line
      width={`${value}%`}
      backgroundColor={color}
      data-test-id={dataTestId}
      className={className}
      disableAnimation={disableAnimation}
    />
  );

  if (!tooltipText) {
    return component;
  }

  return <Tooltip title={tooltipText}>{component}</Tooltip>;
};
