import * as Yup from 'yup';

import { IFormArrayItemStructure } from '~/types/form';

export const RULES_FORM_UNIQ_NAME_TEST_NAME = 'rules-form-unique-name';

export const FORM_ARRAY_STRUCTURE: Array<IFormArrayItemStructure> = [
  {
    label: 'Name',
    type: 'text',
    name: 'name',
    sx: { width: '144px' },
  },
  {
    label: 'Rule',
    type: 'select',
    options: [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Percent', value: 'percent' },
    ],
    name: 'rule',
    sx: { width: '112px' },
  },
  {
    label: 'Rate',
    type: 'number',
    name: 'rate',
    sx: { width: '80px' },
  },
];

export const RULES_FORM_VALIDATION_SCHEMA = Yup.object({
  rules: Yup.array()
    .of(
      Yup.object({
        name: Yup.string().required('Name is required'),
        rule: Yup.string()
          .oneOf(
            ['fixed', 'percent'],
            'Rule must be either "fixed" or "percent"',
          )
          .required('Rule is required'),
        rate: Yup.number()
          .typeError('Rate is required')
          .required('Rate is required'),
      }),
    )
    .test(RULES_FORM_UNIQ_NAME_TEST_NAME, 'Name must be unique', (rules) => {
      const names = rules?.map((rule) => rule.name);
      return names?.length === new Set(names).size;
    }),
});
