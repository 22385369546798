import * as Yup from 'yup';

import type { IUserToken } from '~/types/users';

import { TOKEN_TYPES_OPTIONS } from '../constants';

export const getValidationSchema = (
  tokens: IUserToken[],
  isAddForm: boolean,
) => {
  const isPasswordType = TOKEN_TYPES_OPTIONS.get('password')?.value;
  const isTotpType = TOKEN_TYPES_OPTIONS.get('totp')?.value;

  return Yup.object({
    name: Yup.mixed().test({
      name: 'token-name-validation',
      test(value, { path, createError }) {
        if (!value.trim()) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (tokens.some(({ name }) => name === value) && isAddForm) {
          return createError({
            message: 'Token with this name already exists',
            path,
          });
        }

        return true;
      },
    }),
    value: Yup.string()
      .when('type', {
        is: isPasswordType,
        then: Yup.string()
          .required('Password is required')
          .min(10, 'Password must be at least 10 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/,
            'Password must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character',
          ),
      })
      .when('type', {
        is: isTotpType,
        then: Yup.string()
          .required('Value is required')
          .length(10, 'Value must be 10 characters'),
      }),
    revalue: Yup.string().when('type', {
      is: isPasswordType,
      then: Yup.string()
        .required('Password is required')
        .oneOf([Yup.ref('value'), null], 'Passwords must match'),
    }),
  });
};
