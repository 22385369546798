import { useCallback } from 'react';
import { IconButton } from 'react-ui-kit-exante';

interface IFormArrayDeleteButton {
  index: number;
  openModal: (index: number) => void;
}

export const FormArrayDeleteButton = ({
  index,
  openModal,
}: IFormArrayDeleteButton) => {
  const clickHandler = useCallback(() => {
    openModal(index);
  }, [index, openModal]);

  return (
    <IconButton
      iconName="DeleteIcon"
      iconColor="radical"
      iconSize={24}
      onClick={clickHandler}
    />
  );
};
