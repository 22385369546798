import { get, set } from 'lodash';
import { ReactNode } from 'react';
import { ICellValue } from 'react-ui-kit-exante';

import {
  IInterestRateByAccount,
  IInterestRateByGroup,
  TInterestRatesMap,
  TUpdatedFields,
} from '~/api/interestRates/types';
import { isKeyOf, capitalize } from '~/shared/utils';
import { ICurrency, TCurrency } from '~/types/currencies';
import { IInterestRate } from '~/types/interestRates';

import { InterestRateCell } from './components/InterestRateCell';
import {
  FIGURE_CELLS_TOOLTIP,
  TRADING_DAYS_CELL_TOOLTIP,
  DEFAULT_PERCENT_VALUE,
  DEFAULT_DAYS_VALUE,
  excludedFields,
  uneditableFields,
  percentFields,
} from './constants';
import {
  ITab,
  TBrandedAccountId,
  TInterestRateWithOverriddenMap,
} from './types';

export const prepareDataForUpdate = (
  previous: IInterestRate,
  updated: IInterestRate,
) => {
  const result = {};

  const keys = Object.keys(updated);
  keys.forEach((key) => {
    if (excludedFields.includes(key)) {
      return;
    }
    if (uneditableFields.includes(key)) {
      set(result, key, {
        value: get(previous, key),
        updated: false,
      });
      return;
    }

    const updatedValue = get(updated, key);
    const previousValue = get(previous, key);
    const preparedUpdatedValue = percentFields.includes(key)
      ? Number(updatedValue) / 100
      : updatedValue;

    set(result, key, {
      value: preparedUpdatedValue,
      updated: Number(previousValue) !== preparedUpdatedValue,
    });
  });

  return result as TUpdatedFields<IInterestRate>;
};

export const renderFigureCell = ({
  value,
}: ICellValue<IInterestRate>): ReactNode => (
  <InterestRateCell title={FIGURE_CELLS_TOOLTIP} value={value} />
);

export const renderTradingDaysCell = ({
  value,
}: ICellValue<IInterestRate>): ReactNode => (
  <span title={TRADING_DAYS_CELL_TOOLTIP}>{value}</span>
);

export const transferValueToPercent = (row: IInterestRate, key: string) => {
  const value = get(row, key);

  return (parseFloat(value) * 100).toFixed(4);
};

export const getDefaultInterestRates = (currency: TCurrency) => ({
  currency,
  positiveMarkup: DEFAULT_PERCENT_VALUE,
  negativeMarkup: DEFAULT_PERCENT_VALUE,
  positiveRebate: DEFAULT_PERCENT_VALUE,
  negativeRebate: DEFAULT_PERCENT_VALUE,
  days: DEFAULT_DAYS_VALUE,
  positiveBenchmark: DEFAULT_PERCENT_VALUE,
  negativeBenchmark: DEFAULT_PERCENT_VALUE,
});

export const isRecord = (input: unknown): input is Record<string, unknown> => {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
};

export const injectOverriddenRates = (
  originalRates: TInterestRatesMap,
  overriddenRates: (IInterestRateByGroup | IInterestRateByAccount)[],
): TInterestRateWithOverriddenMap => {
  const rates = { ...originalRates };
  overriddenRates.forEach(
    ({
      currency,
      positive,
      negative,
      rebatePositive,
      rebateNegative,
      ...rest
    }) => {
      const overriddenDefaultState = getDefaultInterestRates(currency);
      const originState = rates[currency];

      rates[currency] = {
        ...(originState || overriddenDefaultState),
        positiveMarkup: positive,
        negativeMarkup: negative,
        positiveRebate: rebatePositive,
        negativeRebate: rebateNegative,
        overridden: !('overridden' in rest),
      };
    },
  );

  return rates;
};

export const prepareDataForTable = (
  currencies: ICurrency[],
  ratesMap: TInterestRateWithOverriddenMap,
) => {
  return currencies.map(
    ({ currency }) => ratesMap[currency] || getDefaultInterestRates(currency),
  );
};

export const haveBeenUpdated = (
  dataForUpdate: TUpdatedFields<IInterestRate>,
) => {
  return Object.keys(dataForUpdate).some((fieldName) => {
    if (fieldName === 'overridden') {
      return false;
    }
    return (
      !uneditableFields.includes(fieldName) &&
      isKeyOf(fieldName, dataForUpdate) &&
      dataForUpdate[fieldName].updated
    );
  });
};

export const createBrandedAccountsList = (
  options: { label: string; value: string }[],
) => {
  return options as {
    value: TBrandedAccountId;
    label: string;
  }[];
};

const isCurrency = (
  value: string,
  currencies: ICurrency[],
): value is TCurrency => {
  return currencies.some(({ currency }) => currency === value);
};

export const injectGroupRatesIntoAccountRates = (
  ratesByAccount: TInterestRateWithOverriddenMap,
  ratesByGroup: TInterestRateWithOverriddenMap,
  currencies: ICurrency[],
) => {
  const copy = { ...ratesByAccount };

  Object.keys(ratesByGroup).forEach((key) => {
    if (isCurrency(key, currencies)) {
      if (!get(ratesByAccount, [key, 'overridden'], false)) {
        copy[key] = {
          ...ratesByGroup[key],
          overridden: false,
        };
      }
    }
  });

  return copy;
};

export const hasIcon = <
  T extends Record<string, unknown> | undefined | boolean,
>(
  data: T,
): data is T & { icon: string } => {
  return Boolean(data && typeof data === 'object' && data.icon);
};
