import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { mirroringRulesService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

export const mirroringRuleApi = createApi({
  reducerPath: 'mirroringRuleQuery',
  tagTypes: ['MirroringRule'],
  baseQuery: async (args) =>
    transformToBaseQueryResponse(
      await mirroringRulesService.resolveMirroringRule(args),
    ),
  endpoints: (build) => ({
    getMirroringRule: build.query({
      query: (id: number) => id,
      providesTags: ['MirroringRule'],
    }),
  }),
});

export const { useGetMirroringRuleQuery } = mirroringRuleApi;
