import { Children, ReactChild } from 'react';

import {
  StyledHeader,
  StyledTitle,
  StyledActions,
  StyledActionContainer,
} from './TabContentHeader.styled';

interface IFormHeaderProps {
  title: string;
  actions?: ReactChild | ReactChild[];
}

export const TabContentHeader = ({ title, actions }: IFormHeaderProps) => {
  return (
    <StyledHeader>
      <StyledTitle>{title}</StyledTitle>

      <StyledActions>
        {Children.map(actions, (action) => (
          <StyledActionContainer>{action}</StyledActionContainer>
        ))}
      </StyledActions>
    </StyledHeader>
  );
};
