import { useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { NO_DATA_HEIGHT } from '~/constants';
import { useCurrency } from '~/hooks';
import { getTableId } from '~/shared/utils';
import { getFooterPropsWithTotal } from '~/shared/utils/table';
import { IOrderMargin } from '~/types/accounts/metrics';

import { StyledWrapper } from '../MarginStructure.styled';

import { getColumns } from './columns';

const TABLE_ID = getTableId('OrderMargin');

interface IOrderMarginProps {
  data: IOrderMargin[];
  isLoading: boolean;
}

export const OrderMargin = ({ data, isLoading }: IOrderMarginProps) => {
  const currency = useCurrency();
  const columns = useMemo(() => getColumns(currency), [currency]);

  return (
    <StyledWrapper>
      <Table
        columns={columns}
        data={data}
        getFooterProps={getFooterPropsWithTotal}
        isFlexLayout
        isLoading={isLoading}
        noDataHeight={NO_DATA_HEIGHT}
        saveViewParamsAfterLeave
        showTableInfo
        tableId={TABLE_ID}
      />
    </StyledWrapper>
  );
};
