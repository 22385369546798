import { Checkbox, IColumn } from 'react-ui-kit-exante';

import { FilterLayers, ISymbolTreeStructure } from '~/types/symbolPermissions';

interface IViewCellProps {
  column: IColumn<ISymbolTreeStructure>;
  value: boolean;
}

export const ViewCell = ({ column, value }: IViewCellProps) => {
  const { layer } = column.getProps();
  const disabled = layer === FilterLayers.Accounts;

  return <Checkbox checked={value} disabled={disabled} />;
};
