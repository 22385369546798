import { MutableRefObject } from 'react';

import { clientsService } from '~/resources';
import {
  createServiceResponse,
  EMPTY_SERVICE_RESPONSE,
} from '~/shared/utils/services';
import { clientApi, clientUsersApi } from '~/store/client';
import { IClientUser, ICoreClient } from '~/types/clients';
import { IOption, TFormHandlerNew } from '~/types/form';
import { TDispatch } from '~/types/store';

import { TFormData } from '../types';

interface ICreateHandlersMapArguments {
  dispatch: TDispatch;
  client?: ICoreClient | null;
  previousClientUsers: MutableRefObject<IClientUser[] | undefined>;
  clientAccounts: IOption[];
}

export const createHandlersMap = ({
  dispatch,
  client,
  clientAccounts,
  previousClientUsers,
}: ICreateHandlersMapArguments): TFormHandlerNew<TFormData> | null => {
  if (!client) {
    return null;
  }

  return {
    client: (data) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return clientsService.updateFormClient(data.id, data).then((result) => {
        dispatch(
          clientApi.util.updateQueryData(
            'getClient',
            client.id,
            () => result.data,
          ),
        );

        if (result.error) {
          dispatch(clientApi.util.invalidateTags(['Client']));
        }

        return result;
      });
    },
    clientUsers: (data) => {
      if (!data || !isIFormClientUser(previousClientUsers)) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      return clientsService
        .updateClientUsers(data, previousClientUsers)
        .then((result) => {
          dispatch(
            clientUsersApi.util.updateQueryData(
              'getClientUsers',
              client.id,
              () => result.data,
            ),
          );

          return result;
        });
    },
    clientAccounts: () =>
      Promise.resolve(
        createServiceResponse({
          data: clientAccounts,
        }),
      ),
  };
};

function isIFormClientUser(
  value: ICreateHandlersMapArguments['previousClientUsers'],
): value is MutableRefObject<IClientUser[]> {
  return Boolean(value.current);
}
