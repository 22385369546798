import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

/* TODO
   It is not good way for redirect, we need use 'redirect' function from react-router-dom https://reactrouter.com/en/main/fetch/redirect
   But I don't know how to use it in case of Router component in ApplicationContainer.tsx for micro-frontend
 */
export const Redirect302 = ({
  to,
  oldPageName,
  newPageName,
}: {
  to: string;
  oldPageName: string;
  newPageName: string;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    Notification.warning({
      title: `Page ${oldPageName} is deprecated and does not exist anymore. You will be redirected to ${newPageName} Page`,
    });

    navigate(to);
  }, [oldPageName, newPageName, navigate, to]);

  return null;
};
