import { Select, IconButton, styled } from 'react-ui-kit-exante';

import { MAX_WIDTH } from '../../constants';

export const WrapperStyled = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  paddingTop: '24px',

  button: {
    whiteSpace: 'nowrap',
  },
}));

export const WidthDelimiterStyled = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  maxWidth: MAX_WIDTH,
  width: '100%',

  '& > :first-child': {
    flexGrow: 1,
  },
}));

export const SelectStyled = styled(Select)(() => ({
  width: '100%',
}));

export const IconAddStyled = styled(IconButton)(() => ({
  marginLeft: 'auto',
}));
