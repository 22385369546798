import { ReactChild, useCallback, useMemo, useState } from 'react';

import { useDirtyTabs } from '~/hooks';
import { useTabsContext } from '~/hooks/useTabs/useTabs';
import { isDatePresent } from '~/shared/utils/dates';
import { IRefreshActiveTabQuery } from '~/types/refetch';

import {
  AccountPageContextProvider,
  DEFAULT_DIRTY_TABS_STATE,
} from './accountPageContext';
import { tabsIds, TTabsIds } from './constants';
import { IAccountPageContext } from './types';

interface IAccountPageContextManagerProps {
  children: ReactChild;
}

export const AccountPageContextManager = ({
  children,
}: IAccountPageContextManagerProps) => {
  const [date, setDate] = useState<Date | null>(null);
  const [activeTab] = useTabsContext();
  const { dirtyTabs, setDirtyTabs } = useDirtyTabs<TTabsIds>(
    DEFAULT_DIRTY_TABS_STATE,
  );

  const activeTabName = tabsIds[Number(activeTab)];
  const [refreshQueries, setRefreshQueries] = useState<
    Record<string, IRefreshActiveTabQuery>
  >({});

  const handleDateChange = useCallback((newDate: Date | null) => {
    if (newDate === null || isDatePresent(newDate)) {
      setDate(newDate);
    }
  }, []);
  const handleResetDate = useCallback(() => setDate(null), []);

  const handleSetRefreshFn = useCallback(
    (key: string, refreshFn: IRefreshActiveTabQuery) => {
      setRefreshQueries((prev) => ({
        ...prev,
        [key]: refreshFn,
      }));
    },
    [],
  );

  const contextValue = useMemo<IAccountPageContext>(
    () => ({
      accountSummary: {
        filters: {
          date,
          setDate: handleDateChange,
          resetDate: handleResetDate,
        },
      },
      dirtyTabs,
      setDirtyTabs,
      activeTabName,
      refreshActiveTabQuery: refreshQueries?.[activeTabName] || {},
      setRefreshFn: handleSetRefreshFn,
    }),
    [
      activeTabName,
      date,
      dirtyTabs,
      handleDateChange,
      handleResetDate,
      handleSetRefreshFn,
      refreshQueries,
      setDirtyTabs,
    ],
  );

  return (
    <AccountPageContextProvider value={contextValue}>
      {children}
    </AccountPageContextProvider>
  );
};
