import React, { createContext, useContext, ReactNode, useMemo } from 'react';

import {
  addQueryToUpdate,
  getQueryToUpdate,
  getQueriesToUpdate,
} from './helpers';

interface CopyPasteValuesContextProps {
  addQueryToUpdate: typeof addQueryToUpdate;
  getQueryToUpdate: typeof getQueryToUpdate;
  getQueriesToUpdate: typeof getQueriesToUpdate;
}
const CopyPasteValuesContext = createContext<
  CopyPasteValuesContextProps | undefined
>(undefined);

interface CopyPasteValuesProviderProps {
  children: ReactNode;
}

export const CopyPasteValuesProvider = ({
  children,
}: CopyPasteValuesProviderProps): JSX.Element => {
  const contextValue = useMemo(
    () => ({
      addQueryToUpdate,
      getQueryToUpdate,
      getQueriesToUpdate,
    }),
    [],
  );

  return (
    <CopyPasteValuesContext.Provider value={contextValue}>
      {children}
    </CopyPasteValuesContext.Provider>
  );
};

export function useCopyPasteValues(): CopyPasteValuesContextProps {
  const context = useContext(CopyPasteValuesContext);
  if (!context) {
    throw new Error(
      'useCopyPasteValues must be used within a CopyPasteValuesProvider',
    );
  }
  return context;
}
