/* eslint-disable import/no-mutable-exports */
import { ApplicationService } from './application';
import { BookmarksService } from './bookmarks';
import { BrandingService } from './branding';
import { CardsManagementService } from './cardsManagement';
import { ClientsService } from './clients';
import { CurrenciesService } from './currencies';
import { GlobalSummaryService } from './globalSummary';
import { InfluxProxyService } from './influxProxy';
import { MirroringRulesService } from './mirroringRules/mirroringRules.service';
import { RegularCommissionsService } from './regularCommissions';
import { ServicesService } from './services';
import { SessionService } from './session';
import { SwaggerService } from './swagger';
import { SymbolPermissionsService } from './symbolPermissions';
import { UsersPermissionService } from './usersPermission';

let applicationService: ApplicationService;
let bookmarksService: BookmarksService;
let cardsManagementService: CardsManagementService;
let clientsService: ClientsService;
let currenciesService: CurrenciesService;
let influxProxyService: InfluxProxyService;
let sessionService: SessionService;
let symbolPermissionsService: SymbolPermissionsService;
let globalSummaryService: GlobalSummaryService;
let servicesService: ServicesService;
let regularCommissionsService: RegularCommissionsService;
let swaggerService: SwaggerService;
let accountUsersService: UsersPermissionService;
let mirroringRulesService: MirroringRulesService;
let brandingService: BrandingService;

export function initResources() {
  bookmarksService = new BookmarksService();
  cardsManagementService = new CardsManagementService();
  influxProxyService = new InfluxProxyService();
  sessionService = new SessionService();
  currenciesService = new CurrenciesService();
  regularCommissionsService = new RegularCommissionsService();
  clientsService = new ClientsService();
  symbolPermissionsService = new SymbolPermissionsService();
  applicationService = new ApplicationService();
  globalSummaryService = new GlobalSummaryService();
  servicesService = new ServicesService();
  swaggerService = new SwaggerService();
  accountUsersService = new UsersPermissionService();
  mirroringRulesService = new MirroringRulesService();
  brandingService = new BrandingService();
}

export {
  accountUsersService,
  applicationService,
  bookmarksService,
  brandingService,
  cardsManagementService,
  clientsService,
  currenciesService,
  influxProxyService,
  sessionService,
  symbolPermissionsService,
  globalSummaryService,
  regularCommissionsService,
  servicesService,
  swaggerService,
  mirroringRulesService,
};
