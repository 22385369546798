type TSwitchProps =
  | {
      condition: boolean;
      children: JSX.Element;
    }
  | {
      condition: boolean[];
      children: JSX.Element[];
    };

export const Switch = ({ condition, children }: TSwitchProps): JSX.Element => {
  if (Array.isArray(condition) && Array.isArray(children)) {
    return (
      <>
        {condition.map((shouldRender, i) => {
          if (shouldRender) {
            return children[i];
          }
          return null;
        })}
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{condition ? children : null}</>;
};
