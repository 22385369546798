import cn from 'classnames';
import { Modal } from 'react-ui-kit-exante';

import { MAIN_USER_TOKEN_NAME } from '~/constants';
import { DeleteButton } from '~/shared/components/DeleteButton';

import TokenDeleteIconStyles from './TokenDeleteIcon.module.css';

interface ITokenDeleteIconComponentProps {
  activeToken: boolean;
  handleConfirmDelete: () => void;
  handleOpenModal: () => void;
  isOpenedModal: boolean;
  onCloseModal: () => void;
  tokenName: string;
}

export const TokenDeleteIconComponent = ({
  activeToken,
  handleConfirmDelete,
  handleOpenModal,
  isOpenedModal,
  onCloseModal,
  tokenName,
}: ITokenDeleteIconComponentProps) => {
  return (
    <>
      <span
        className={cn({
          [TokenDeleteIconStyles.Disabled]:
            activeToken || tokenName === MAIN_USER_TOKEN_NAME,
        })}
      >
        <DeleteButton onClick={handleOpenModal} />
      </span>

      <Modal
        isOpened={isOpenedModal}
        onClose={onCloseModal}
        title="Confirm Deletion"
        confirmButton={{
          handleConfirm: handleConfirmDelete,
        }}
      >
        <div>Do you want to delete this token?</div>
      </Modal>
    </>
  );
};
