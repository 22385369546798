import { FilterValue, ICellValue, IColumn } from 'react-ui-kit-exante';

import { toFixed } from '~/shared/utils';
import { IAccount } from '~/types/accounts';

export const DISPLAYED_COLUMN_KEYS: Array<keyof IAccount> = [
  'id',
  'created',
  'netAssetValue',
  'ownershipType',
  'marginUtilization',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export const getColumns = ({
  onFilter,
  onRemove,
}: IGetColumns): IColumn<
  Array<
    Pick<
      IAccount,
      'id' | 'created' | 'netAssetValue' | 'ownershipType' | 'marginUtilization'
    >
  >
> => [
  {
    Header: 'ID',
    accessor: 'id',
    onFilter,
    onRemove,
    required: true,
  },
  {
    Header: 'Created',
    accessor: 'created',
    type: 'dateRange',
    sortDescFirst: true,
    formatting: 'date',
    onFilter,
    onRemove,
  },
  {
    Header: 'Net asset value, EUR',
    accessor: 'netAssetValue',
    type: 'numberRange',
    formatting: 'number',
    onFilter,
    onRemove,
  },
  {
    Header: 'Ownership Type',
    accessor: 'ownershipType',
    onFilter,
    onRemove,
  },
  {
    Header: 'Margin utilization, %',
    accessor: 'marginUtilization',
    type: 'numberRange',
    Cell: ({ row: { values } }: ICellValue<IAccount>) => {
      try {
        return toFixed(values.marginUtilization);
      } catch (e) {
        return '-';
      }
    },
    onFilter,
    onRemove,
  },
];
