import { ChangeEvent } from 'react';
import { IconButton, Panel, Table } from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import { FormInputFileContainer } from '~/containers/form';
import { CloseButton } from '~/shared/components';
import { downloadFromUrl, getTableId } from '~/shared/utils';
import {
  TRANSACTION_TEMPLATE,
  TRANSACTION_TEMPLATE_FILENAME,
} from '~/shared/utils/files/paths';
import {
  TTransactionImportColumn,
  TTransactionCSV,
  TTransactionImportGetRowProps,
} from '~/types/transactions';

import TransactionImportStyles from './TransactionImport.module.css';

interface ITransactionImport {
  additionalActions: ActionButtonProps[] | undefined;
  onInputFile(event: ChangeEvent<HTMLInputElement>): void;
  columns: TTransactionImportColumn[];
  displayTable: boolean;
  getRowProps: TTransactionImportGetRowProps;
  inputFileText: string;
  isLoading: boolean;
  tableData: TTransactionCSV[];
  onClose(): void;
}

export const TransactionImport = ({
  additionalActions,
  columns,
  displayTable,
  onInputFile,
  inputFileText,
  tableData,
  getRowProps,
  isLoading,
  onClose,
}: ITransactionImport) => {
  const onDownload = () => {
    downloadFromUrl({
      url: TRANSACTION_TEMPLATE,
      filename: TRANSACTION_TEMPLATE_FILENAME,
    });
  };

  return (
    <section className={TransactionImportStyles.TransactionImport}>
      <Panel
        action={
          <div className={TransactionImportStyles.ActionsWrapper}>
            <IconButton
              iconColor="action"
              iconName="DownloadIcon"
              iconSize={36}
              label="Download import template"
              onClick={onDownload}
            />

            <FormInputFileContainer accept=".csv" onChange={onInputFile}>
              {inputFileText}
            </FormInputFileContainer>

            <CloseButton onClick={onClose} />
          </div>
        }
        disableBodyPaddings
        title="Bulk import (transactions)"
      />

      {displayTable && (
        <Table
          additionalActions={additionalActions}
          columns={columns}
          data={tableData}
          defaultSortBy={[]}
          getRowProps={getRowProps}
          hasPagination
          isLoading={isLoading}
          tableId={getTableId('TransactionImport')}
        />
      )}
    </section>
  );
};
