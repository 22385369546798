import { useContext } from 'react';

import { accountPageContext } from '../context';

export const useRefreshActiveTab = () => {
  const context = useContext(accountPageContext);

  if (context === undefined) {
    throw new Error(
      'Component must be used within a AccountPageContextProvider',
    );
  }

  const {
    refreshActiveTabQuery: { refetch, isLoading },
    setRefreshFn,
    activeTabName,
  } = context;

  return {
    refreshActiveTab: refetch,
    isRefreshing: isLoading,
    setRefreshFn,
    activeTabName,
  };
};
