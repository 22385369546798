import { IFooter } from '~/types/table';

export const TotalFooter = <Data extends Record<keyof Data, unknown>>({
  column: { preFilteredRows },
}: IFooter<Data>) => {
  if (preFilteredRows.length === 1) {
    return null;
  }

  return <b>Total</b>;
};
