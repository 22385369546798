import { TExtendedColumnWithAdditionalFields } from 'react-ui-kit-exante';

import { IPosition } from '~/types/accounts';

import styles from './styles.module.css';

export function getFooterPropsWithTotal(
  column: TExtendedColumnWithAdditionalFields<IPosition>,
) {
  if (column.filteredRows?.length < 2) {
    return {};
  }

  return { className: styles.Total };
}
