import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useLazySearchCommissionsInstrumentsQuery } from '~/api';
import {
  DEFAULT_DEBOUNCE_TIMEOUT,
  MAX_REQUEST_LIMIT,
  MIN_SEARCH_LENGTH,
} from '~/constants';
import { resetTable, selectFiltersQueryParams } from '~/store/commissions';

interface IUseSearchParamsProps {
  setAllExpanded: Dispatch<SetStateAction<boolean>>;
}

export function useLayoutSearchParams({
  setAllExpanded,
}: IUseSearchParamsProps) {
  const dispatch = useDispatch();

  const filtersQueryParams = useSelector(selectFiltersQueryParams);

  const [searchInstruments] = useLazySearchCommissionsInstrumentsQuery();

  const handleSearchInstruments = useCallback(
    async (value: string) => {
      const search = value.trim();

      if (search === '') {
        setAllExpanded(false);
      }

      if (search.length < MIN_SEARCH_LENGTH) {
        dispatch(resetTable({}));

        return;
      }

      setAllExpanded(false);

      await searchInstruments({
        search: value,
        limit: MAX_REQUEST_LIMIT,
        skip: 0,
        queryParams: filtersQueryParams,
      });

      // Expanding for all rows doesn't work without changing state for some reasons
      setAllExpanded(true);
    },
    [setAllExpanded, searchInstruments, filtersQueryParams, dispatch],
  );

  const debounceHandleChangeInput = useMemo(
    () => debounce(handleSearchInstruments, DEFAULT_DEBOUNCE_TIMEOUT),
    [handleSearchInstruments],
  );

  const handleChange = useCallback(
    (value: string) => {
      debounceHandleChangeInput(value);
    },
    [debounceHandleChangeInput],
  );

  return {
    onSearch: handleChange,
    onReset: () => {
      dispatch(resetTable({}));
      setAllExpanded(false);
    },
  };
}
