import { pick, cloneDeep, get, groupBy, set } from 'lodash';

import { EMPTY_ARRAY } from '~/constants';
import {
  IAccountSummary,
  ICrossRateResponse,
  ILockedAsset,
  ILockedAssetResponse,
  IAccount,
  ICoreAccount,
  TRebateAccount,
} from '~/types/accounts';
import {
  EMarginType,
  IMappedMarginStructure,
  TMarginStructure,
} from '~/types/accounts/metrics';

import {
  expectedMetricsFields,
  expectedAccountSummaryFields,
  expectedAccountFields,
  ACCOUNT_UPDATE_EXPECTED_FIELDS,
} from './constants';
import { IGroupedMarginStructure } from './types';

export function accountSummaryMapper(
  summary: IAccountSummary,
  locks: ILockedAssetResponse[],
  crossRates: ICrossRateResponse[],
): IAccountSummary {
  const REGEX_GET_TEXT_BETWEEN_SLASH_AND_DOT = /.*\/(.*)\..*/;
  const mappedLocks: Record<string, ILockedAsset[]> = {};
  const mappedCrossRates: Record<string, number> = {};

  crossRates.forEach(({ symbolId, crossRate }) => {
    mappedCrossRates[
      symbolId.replace(REGEX_GET_TEXT_BETWEEN_SLASH_AND_DOT, '$1')
    ] = parseFloat(crossRate);
  });

  locks?.forEach((lock) => {
    if (mappedLocks[lock.asset]) {
      mappedLocks[lock.asset].push({
        ...lock,
        amount: Number(lock.amount),
      });
    } else {
      mappedLocks[lock.asset] = [{ ...lock, amount: Number(lock.amount) }];
    }
  });

  const metrics = pick(summary.metrics, expectedMetricsFields);

  const positions = summary.positions.map((position) => {
    const asset =
      position[position.type === 'CURRENCY' ? 'currency' : 'symbolId'] || '';
    const convertedLockedValue =
      position.type === 'CURRENCY' && Number(position.blockedQtty)
        ? Number(position.blockedQtty) / mappedCrossRates[position.currency]
        : null;

    return {
      ...position,
      convertedLockedValue,
      lock: mappedLocks[asset] ? mappedLocks[asset] : [],
    };
  });

  return pick(
    {
      positions,
      metrics,
      closeSessionTime: summary.closeSessionTime,
    },
    expectedAccountSummaryFields,
  );
}

export function accountMapper(account: IAccount): IAccount {
  return pick(account, expectedAccountFields);
}

export function accountsMapper(accounts: IAccount[]): IAccount[] {
  return accounts.map((item) => accountMapper(item));
}

export const prepareAccountForSend = (
  account: { accountId: string } & Partial<ICoreAccount>,
) => {
  const data = pick(account, ACCOUNT_UPDATE_EXPECTED_FIELDS);
  const overrides = get(data, 'originatorIds.overrides', []);

  let overridesMap = null;

  if (overrides.length > 0) {
    overridesMap = {};

    overrides.forEach((override) => {
      overridesMap[override[0]] = override[1];
    });
  }

  set(data, 'originatorIds.overrides', overridesMap);

  return data;
};

export const prepareRebateForSend = (rebates: TRebateAccount[]) => {
  return rebates?.map((rebate) => ({
    ...rebate,
    percent: Number(rebate.percent),
  }));
};

type TDataWithAssetsList<Data> = Record<keyof Data, unknown> &
  Record<'assetsList', string[] | null>;

type TTransformationReturn<Data extends TDataWithAssetsList<Data>> = Omit<
  Data,
  'assetsList'
> &
  Record<'asset', string | null>;

const transformAssetsListToAsset = <Data extends TDataWithAssetsList<Data>>(
  data: Data[],
) => {
  return data.reduce<TTransformationReturn<Data>[]>(
    (acc, { assetsList, ...rest }) => [
      ...acc,
      { ...rest, asset: assetsList ? assetsList.join(', ') : null },
    ],
    [],
  );
};

export const marginStructureMapper = (
  marginStructure: TMarginStructure[],
): IMappedMarginStructure => {
  const groupedMetrics: IGroupedMarginStructure = groupBy(
    marginStructure,
    'type',
  ) as never;

  return {
    margin: groupedMetrics[EMarginType.Margin] || EMPTY_ARRAY,
    blockedFunds: groupedMetrics[EMarginType.BlockedFunds] || EMPTY_ARRAY,
    orderMargin: groupedMetrics[EMarginType.OrderMargin] || EMPTY_ARRAY,
    scanningRisk: transformAssetsListToAsset(
      groupedMetrics[EMarginType.ScanningRisk] || EMPTY_ARRAY,
    ),
    interspreadMargin: transformAssetsListToAsset(
      groupedMetrics[EMarginType.InterspreadMargin] || EMPTY_ARRAY,
    ),
    optionMinimum: transformAssetsListToAsset(
      groupedMetrics[EMarginType.OptionMinimum] || EMPTY_ARRAY,
    ),
  };
};

export const prepareAccountResponseToView = (account: ICoreAccount) => {
  const copy = cloneDeep(account);
  const overrides = get(copy, 'originatorIds.overrides', []);

  set(copy, 'originatorIds.overrides', Object.entries(overrides));
  return copy;
};
