import { useCallback, useState } from 'react';

import { getInitialAccordionState } from './helpers';

interface IUseAccordionProps {
  items: Record<string, unknown>;
  lsKey?: string;
  closeDefault?: boolean;
}

export function useAccordion({
  items,
  lsKey,
  closeDefault = false,
}: IUseAccordionProps) {
  const [accordion, setAccordion] = useState<Record<string, boolean>>(() =>
    getInitialAccordionState({ items, lsKey, closeDefault }),
  );

  const handleAccordionChange = useCallback(
    (key: string) =>
      setAccordion((prev) => {
        const newState = Object.keys(prev).reduce(
          (acc, curr) => (curr === key ? { ...acc, [curr]: !prev[curr] } : acc),
          prev,
        );

        if (lsKey) {
          localStorage.setItem(lsKey, JSON.stringify(newState));
        }

        return newState;
      }),
    [lsKey],
  );

  return { accordion, handleAccordionChange };
}
