import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TCloseButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'>;

export const CloseButton = (props: TCloseButtonProps) => {
  return (
    <IconButton
      {...props}
      iconName="CloseIcon"
      iconColor="action"
      label="Close"
      type="button"
      iconSize={24}
    />
  );
};
