import { isEmpty, isObject, get } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import {
  useSaveCommissionsMutation,
  useUpdateCommissionSettingsMutation,
} from '~/api';
import {
  filtersSetRelatedGroup,
  selectChangedCommissions,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
  selectFiltersRelatedGroup,
} from '~/store/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';

import { usePrepareDataForSend } from './usePrepareDataForSend';

export const useSaveData = () => {
  const dispatch = useDispatch();
  const filtersLayer = useSelector(selectFiltersLayer);
  const filtersGroup = useSelector(selectFiltersGroup);
  const filtersAccount = useSelector(selectFiltersAccount);
  const relatedGroup = useSelector(selectFiltersRelatedGroup);
  const { nodes: changedNodes, instruments: changedInstruments } = useSelector(
    selectChangedCommissions,
  );
  const [saveCommissions] = useSaveCommissionsMutation();
  const prepareDataForSend = usePrepareDataForSend();
  const [updateCommissionSettings] = useUpdateCommissionSettingsMutation();

  const hasChangedCommissions =
    !isEmpty(changedNodes) || !isEmpty(changedInstruments);

  const hasChangedGroup =
    filtersLayer === FilterLayers.Accounts && filtersGroup !== relatedGroup;

  const saveData = useCallback(async () => {
    if (hasChangedGroup && filtersAccount) {
      const response = await updateCommissionSettings({
        accountId: filtersAccount,
        commissionSettings: {
          commissionGroupId:
            filtersGroup === 'null' || filtersGroup === null
              ? null
              : Number(filtersGroup),
        },
      });

      if ('error' in response) {
        Notification.error({
          title: 'Commission group saving failed',
        });
        return;
      }

      if (response.data) {
        const { data } = response;

        if (
          data?.data &&
          isObject(data.data) &&
          'commissionGroupId' in data.data
        ) {
          Notification.success({
            title: 'Commission group saved successfully',
          });

          dispatch(filtersSetRelatedGroup(get(data.data, 'commissionGroupId')));
        }
      }
    }

    if (hasChangedCommissions) {
      let result: Record<string, unknown> = { data: false, error: false };

      const nodes = Object.values(changedNodes).map((node) =>
        prepareDataForSend(node as ICommissionTree, filtersLayer),
      );
      const instruments = Object.values(changedInstruments).map((instrument) =>
        prepareDataForSend(instrument, filtersLayer),
      );

      result = await saveCommissions({
        changedCommissions: [...nodes, ...instruments],
        layer: filtersLayer,
        entity:
          filtersLayer === FilterLayers.Groups ? filtersGroup : filtersAccount,
      });

      if ('data' in result) {
        Notification.success({
          title: 'Commissions saved successfully',
        });
      }
    }
  }, [
    changedInstruments,
    changedNodes,
    dispatch,
    filtersAccount,
    filtersGroup,
    filtersLayer,
    hasChangedCommissions,
    hasChangedGroup,
    prepareDataForSend,
    saveCommissions,
    updateCommissionSettings,
  ]);

  return [saveData];
};
