import { Tooltip } from 'react-ui-kit-exante';

import {
  AVAILABLE_PROMPT,
  MARGIN_CALL_PROMPT,
} from '~/containers/DetailedAccountInfoContainer/constants';
import { WarningLite } from '~/images/icons';

import AvailableLabelStyles from './AvailableLabel.module.css';

interface IAvailableLabelProps {
  isMarginCall: boolean;
}

export const AvailableLabel = ({ isMarginCall }: IAvailableLabelProps) => {
  return (
    <>
      {`${isMarginCall ? 'Margin Call' : 'Available'} `}&nbsp;
      <Tooltip title={isMarginCall ? MARGIN_CALL_PROMPT : AVAILABLE_PROMPT}>
        <span className={AvailableLabelStyles.WrapperIcon}>
          <WarningLite />
        </span>
      </Tooltip>
    </>
  );
};
