import { IAccount } from '~/types/accounts';
import { IOption } from '~/types/form';

import { CurrenciesRadio } from './constants';

export const getRadioValue = (
  initialValue: IAccount['availableCurrencies'],
  currenciesSelectOptions: IOption[],
) => {
  const options = currenciesSelectOptions.map(({ value }) => value);
  if (
    initialValue?.length === currenciesSelectOptions.length &&
    initialValue?.every((currency: string) => options.includes(currency))
  ) {
    return CurrenciesRadio.All;
  }
  if (initialValue?.length === 0) {
    return CurrenciesRadio.None;
  }
  if (!initialValue) {
    return CurrenciesRadio.Inherited;
  }
  return CurrenciesRadio.Manual;
};
