import { get, has, isPlainObject, uniq, values } from 'lodash';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { IOption } from 'react-ui-kit-exante';
import * as yup from 'yup';

import {
  IEditedUserPermission,
  INewUserPermission,
  TUserPermission,
  TUsersPermissionResponse,
} from '~/resources/usersPermission/types';
import { findAllIndexes } from '~/shared/utils';

import { IFormData } from './types';

export const getDefaultUserPermission = (
  accountId: string,
): TUserPermission => {
  return {
    overrideAccountStatus: false,
    status: 'blocked',
    username: null,
    userId: null,
    isNew: true,
    accountId,
    id: null,
  };
};

// TODO Move to common utils
export const createArraysValidation =
  <T extends Record<keyof T, IOption | string | null>>(uniqKey: keyof T) =>
  (initialValues: T[], { path, createError }: yup.TestContext) => {
    const emptyIndexes: number[] = [];
    const idKeys: string[] = [];

    const indexes = idKeys.reduce<number[][]>((acc, curr) => {
      const allKeyIndexes = findAllIndexes(idKeys, curr);

      return [...acc, allKeyIndexes];
    }, []);
    const duplicateIndexes = uniq(
      indexes.reduce((acc, curr) => {
        if (curr.length > 1) {
          return acc.concat(curr);
        }

        return acc;
      }, []),
    );

    if (duplicateIndexes.length > 0) {
      return new yup.ValidationError(
        duplicateIndexes.map((item) =>
          createError({
            message: `Field should be unique`,
            path: `${path}.${item}.${String(uniqKey)}`,
          }),
        ),
      );
    }

    initialValues.forEach((item, index) => {
      const currentItem = item[uniqKey];
      if (!currentItem) {
        emptyIndexes.push(index);
      } else {
        idKeys.push(
          typeof currentItem !== 'string'
            ? String(currentItem.value)
            : currentItem,
        );
      }
    });
    if (emptyIndexes.length > 0) {
      return new yup.ValidationError(
        emptyIndexes.map((item) =>
          createError({
            message: 'Field can not be empty',
            path: `${path}.${item}.${String(uniqKey)}`,
          }),
        ),
      );
    }

    return true;
  };

const isNewUser = (user: TUserPermission): user is INewUserPermission => {
  return Boolean(user.isNew);
};

const isEditedUser = (user: TUserPermission): user is IEditedUserPermission => {
  return !user.isNew;
};

export const separateUsers = (users: TUserPermission[]) => {
  return users.reduce<{
    editedUsersPermission: IEditedUserPermission[];
    newUsersPermission: INewUserPermission[];
  }>(
    (acc, item) => {
      if (isNewUser(item)) {
        acc.newUsersPermission.push(item);
      } else if (isEditedUser(item)) {
        acc.editedUsersPermission.push(item);
      }

      return acc;
    },
    {
      editedUsersPermission: [],
      newUsersPermission: [],
    },
  );
};

export const isOption = (
  value: unknown,
): value is { value: string; label: string } => {
  return isPlainObject(value) && has(value, 'value') && has(value, 'label');
};

export const getExcludedKeys = (
  prevState: (Record<string, unknown> | undefined)[],
  currentState: Record<string, unknown>[],
  key = 'id',
) => {
  const initialKeys = new Set();
  const currentKeys = new Set();

  prevState.forEach((it) => it && initialKeys.add(it[key]));
  currentState.forEach((it) => currentKeys.add(it[key]));

  const excluded: string[] = [];

  initialKeys.forEach((id) => {
    if (!currentKeys.has(id)) {
      excluded.push(String(id));
    }
  });

  return excluded;
};

export const createNewState = (
  newItems: TUsersPermissionResponse[],
  prevState: IFormData,
  state: IFormData,
) => {
  const hash = newItems.reduce<Record<string, number>>(
    (acc, { userId, id }) => {
      acc[userId] = id;
      return acc;
    },
    {},
  );

  const newState = { ...prevState, ...state };

  newState.users = newState.users.map(({ userId, id, ...rest }) => {
    return {
      ...rest,
      userId,
      id: id || get(hash, String(userId)),
      isNew: false,
    };
  });

  return newState;
};

export const prepareDataToUpdateUsersPermission = ({
  newData,
  updatedFields,
}: {
  newData: IFormData;
  updatedFields: Partial<FieldNamesMarkedBoolean<IFormData>>;
}) => {
  const result = newData.users.reduce<IFormData['users']>((acc, item, i) => {
    const isNewRow = item.isNew;
    const haveBeenChanged = values(updatedFields.users?.[i]).some(
      (it) => it === true,
    );
    if (isNewRow || haveBeenChanged) {
      acc.push({
        ...item,
        userId: isOption(item.userId) ? item.userId.value : item.userId,
      });
    }
    return acc;
  }, []);

  return result;
};

export const isUpdateUserPermissionsResponse = (
  val: unknown,
): val is {
  newItems: TUsersPermissionResponse[] | null;
  editedUsersPermission: IEditedUserPermission[];
} => {
  return has(val, 'newItems') && has(val, 'editedUsersPermission');
};
