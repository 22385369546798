import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';
import { ActionButtonProps } from 'react-ui-kit-exante/build/Components/Table/ActionButton/ActionButton.types';

import { EXPORT_TRADES_PARAMS_TRANSFORMER } from '~/api/trades/trades.api.constants';
import {
  useCurrentUserHasAllBrandingPermission,
  useLocalStoragePolling,
  usePickUserPermissions,
} from '~/hooks';
import { TRADE_ADD_PATH, TRADE_IMPORT_PATH } from '~/routes';
import { DownloadButton } from '~/shared/components';
import { paramsTransformer, updateAccountIdInParams } from '~/shared/utils';
import { createLinkToDownloadCSV } from '~/shared/utils/table';

export function useAdditionalActions(
  withAddButton: boolean,
  filters: Record<string, unknown>,
  globalFilters: Record<string, unknown> | undefined,
) {
  const navigate = useNavigate();

  const selectedColumns = useLocalStoragePolling('web-bo-trades-table-columns');
  const lsViewParams = useLocalStoragePolling(
    `web-bo-trades-table-view-params`,
  );

  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();
  const userPermissions = usePickUserPermissions(['Inject a manual trade']);

  const goToBulkImport = useCallback(() => {
    navigate(TRADE_IMPORT_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToAddTradePage = useCallback(() => {
    navigate(TRADE_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const additionalActions: ActionButtonProps[] = useMemo(() => {
    const actions: ActionButtonProps[] = [];

    if (currentUserHasAllBrandingPermission) {
      actions.push({
        component: (
          <IconButton
            iconName="DownloadIcon"
            iconColor="action"
            iconSize={24}
            label="Bulk import"
            onClick={goToBulkImport}
          />
        ),
      });
    }

    if (
      currentUserHasAllBrandingPermission &&
      userPermissions['Inject a manual trade'].write &&
      withAddButton
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            iconSize={24}
            label="Add trade"
            onClick={goToAddTradePage}
          />
        ),
      });
    }

    const resultParams = paramsTransformer({
      params: updateAccountIdInParams({
        ...globalFilters,
        ...filters,
        limit: Infinity,
        fields: (() => {
          try {
            return selectedColumns
              ? JSON.parse(selectedColumns).filter(
                  (item: string) => item !== 'actions',
                )
              : JSON.parse(lsViewParams || '{}').defaultColumnOrder.filter(
                  (item: string) => item !== 'actions',
                );
          } catch (e) {
            return [];
          }
        })(),
      }),
      mapper: EXPORT_TRADES_PARAMS_TRANSFORMER,
    });

    actions.push({
      component: (
        <DownloadButton
          link={createLinkToDownloadCSV('/api/trades/csv', resultParams)}
          fileName="trades.csv"
        />
      ),
    });

    return actions;
  }, [
    currentUserHasAllBrandingPermission,
    filters,
    globalFilters,
    goToAddTradePage,
    goToBulkImport,
    lsViewParams,
    selectedColumns,
    withAddButton,
  ]);

  return additionalActions;
}
