import { IColumn } from 'react-ui-kit-exante';

import { TRebateAccountsData } from '~/types/accounts';

export const columns: IColumn<TRebateAccountsData>[] = [
  {
    Header: 'Rebate account',
    accessor: 'id',
    disableFilters: true,
  },
  {
    Header: 'Rebate rate',
    accessor: 'percent',
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
];
