import { ThemeColorType } from 'react-ui-kit-exante';

import { EMPTY_FUNCTION } from '~/constants';

import { AddButton } from '../AddButton';
import { CloseButton } from '../CloseButton';
import { DeleteButton } from '../DeleteButton';
import { RefreshButton } from '../RefreshButton';
import { SaveButton } from '../SaveButton';

import styles from './PanelHeaderControls.module.css';

export interface IPanelHeaderControlsProps {
  closeIconColor?: ThemeColorType;
  deleteIconColor?: ThemeColorType;
  disableSaveButton?: boolean;
  hideSaveButton?: boolean;
  isRefreshLoading?: boolean;
  labelAdd?: string;
  onAdd?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
  onSave?: (() => void) | null;
}

export const PanelHeaderControls = ({
  closeIconColor = 'action',
  deleteIconColor = 'action',
  disableSaveButton = false,
  hideSaveButton = false,
  isRefreshLoading,
  labelAdd,
  onAdd,
  onClose,
  onDelete,
  onRefresh,
  onSave,
}: IPanelHeaderControlsProps) => {
  return (
    <div className={styles.PanelHeaderControls}>
      {!hideSaveButton && (
        <SaveButton
          type="submit"
          disabled={disableSaveButton}
          onClick={onSave || EMPTY_FUNCTION}
        />
      )}

      {onAdd ? <AddButton onClick={onAdd} label={labelAdd || ''} /> : null}

      {onDelete ? (
        <DeleteButton iconColor={deleteIconColor} onClick={onDelete} />
      ) : null}

      {onRefresh ? (
        <div className={styles.RefreshButton}>
          <RefreshButton
            disabled={isRefreshLoading}
            onRefresh={onRefresh}
            type="button"
          />
        </div>
      ) : null}

      {onClose ? (
        <CloseButton iconColor={closeIconColor} onClick={onClose} />
      ) : null}
    </div>
  );
};
