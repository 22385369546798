import { UseFormGetValues } from 'react-hook-form';

import { FormSelectContainer } from '~/containers/form';
import { InterestPageLayer } from '~/pages/InterestRatesPage/constants';
import {
  COMMISSIONS_PATH,
  INTERESTS_PATH,
  LIMITS_PATH,
  NON_TRADING_COMMISSIONS,
  OVERNIGHTS_PATH,
  SYMBOL_PERMISSIONS_PATH,
} from '~/routes';
import { FilterLayers as CommissionsFilterLayers } from '~/types/commissions';
import { IOption } from '~/types/form';
import { FilterLayers as OvernightsFilterLayers } from '~/types/overnights';
import { FilterLayers as SymbolPermissionsFilterLayers } from '~/types/symbolPermissions';
import { FilterLayers } from '~/types/treeStructure';
import { TPossibleAccessRights } from '~/types/users';

import { TFormData } from '../types';

import {
  StyledHelperText,
  StyledLink,
  StyledPanel,
} from './GroupSettings.styled';

import '~/styles/Global.css';

interface IGroupSettingsComponentProps {
  accountId: string;
  getValues: UseFormGetValues<TFormData>;
  groupSettingsIsRejected: boolean;
  options: Record<string, IOption[]>;
  overrides: Record<string, boolean> | null;
  userPermissions: Record<string, TPossibleAccessRights>;
}

export const GroupSettingsComponent = ({
  accountId,
  getValues,
  groupSettingsIsRejected,
  options,
  overrides,
  userPermissions,
}: IGroupSettingsComponentProps) => {
  const hasInterestsOvernightsSettings =
    userPermissions['Account info: interests/overnights settings'].read &&
    userPermissions['Account info: interests/overnights settings'].write;

  const displayPermissions =
    typeof getValues('groupSettings.permissionsSetId') !== 'undefined';

  return (
    <StyledPanel title="Group Settings">
      {userPermissions['Account interest rates'].read ? (
        <div>
          <FormSelectContainer
            disabled={
              !userPermissions['Account interest rates'].write ||
              !hasInterestsOvernightsSettings ||
              !userPermissions['Account info'].write
            }
            label="Interests"
            name="regularPayments.interestsSetId"
            options={options.interests}
          />
          <StyledHelperText>
            <span>
              {overrides?.interests
                ? 'Overrides on Account level exist'
                : 'No overrides on Account level'}
            </span>
            <StyledLink
              target="_blank"
              to={`${INTERESTS_PATH}?layer=${InterestPageLayer.Accounts}&account=${accountId}`}
            >
              Configure
            </StyledLink>
          </StyledHelperText>
        </div>
      ) : null}

      {userPermissions['Mixed commissions'].read ? (
        <div>
          <FormSelectContainer
            disabled={
              !userPermissions['Mixed commissions'].write ||
              !userPermissions['Account info'].write
            }
            label="Commissions"
            name="commission.commissionGroupId"
            options={options.commissions}
          />
          <StyledHelperText>
            <span>
              {overrides?.commissions
                ? 'Overrides on Account level exist'
                : 'No overrides on Account level'}
            </span>
            <StyledLink
              target="_blank"
              to={`${COMMISSIONS_PATH}?layer=${CommissionsFilterLayers.Accounts}&account=${accountId}`}
            >
              Configure
            </StyledLink>
          </StyledHelperText>
        </div>
      ) : null}

      {userPermissions['Account markup overnight rates'].read ? (
        <div>
          <FormSelectContainer
            disabled={
              !userPermissions['Account markup overnight rates'].write ||
              !hasInterestsOvernightsSettings ||
              !userPermissions['Account info'].write
            }
            label="Overnights"
            name="regularPayments.overnightsSetId"
            options={options.overnights}
          />
          <StyledHelperText>
            <span>
              {overrides?.overnights
                ? 'Overrides on Account level exist.'
                : 'No overrides on Account level'}
            </span>
            <StyledLink
              target="_blank"
              to={`${OVERNIGHTS_PATH}?layer=${OvernightsFilterLayers.Accounts}&account=${accountId}`}
            >
              Configure
            </StyledLink>
          </StyledHelperText>
        </div>
      ) : null}

      {userPermissions['Account symbol permissions'].read &&
      displayPermissions ? (
        <div>
          <FormSelectContainer
            disabled={
              !userPermissions['Account symbol permissions'].write ||
              !userPermissions['Account info'].write
            }
            label="Permissions"
            name="groupSettings.permissionsSetId"
            options={options.permissions}
          />
          <StyledHelperText>
            <span>
              {overrides?.permissions
                ? 'Overrides on Account level exist'
                : 'No overrides on Account level'}
            </span>
            <StyledLink
              target="_blank"
              to={`${SYMBOL_PERMISSIONS_PATH}?layer=${SymbolPermissionsFilterLayers.Accounts}&account=${accountId}`}
            >
              Configure
            </StyledLink>
          </StyledHelperText>
        </div>
      ) : null}

      {userPermissions['Account limits'].read && !groupSettingsIsRejected ? (
        <div>
          <FormSelectContainer
            disabled={
              !userPermissions['Account limits'].write ||
              !userPermissions['Account info'].write
            }
            label="Limits"
            name="groupSettings.limitsSetId"
            options={options.limits}
          />
          <StyledHelperText>
            <span>
              {overrides?.limits
                ? 'Overrides on Account level exist'
                : 'No overrides on Account level'}
            </span>
            <StyledLink
              target="_blank"
              to={`${LIMITS_PATH}?layer=${FilterLayers.Accounts}&accountId=${accountId}`}
            >
              Configure
            </StyledLink>
          </StyledHelperText>
        </div>
      ) : null}

      <div>
        <FormSelectContainer
          label="Commission Group"
          name="commission.serviceFeeGroupId"
          options={options.commissionGroupsTypes}
        />
        <StyledHelperText>
          <span>To add a new group go to</span>
          <StyledLink target="_blank" to={NON_TRADING_COMMISSIONS}>
            Non-trading commissions
          </StyledLink>
        </StyledHelperText>
      </div>
    </StyledPanel>
  );
};
