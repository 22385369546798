import { useMemo } from 'react';

import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '~/constants';
import { currencyFormatter, formatCurrency } from '~/shared/utils';

interface IUseTotalNavProps {
  currency?: string;
  label?: string;
  nav?: number | null;
}

export function useTotalNav({
  currency = DEFAULT_CURRENCY,
  label = 'Total nav',
  nav,
}: IUseTotalNavProps) {
  const formattedNav = useMemo(() => {
    const formatter = currencyFormatter(DEFAULT_LOCALE, currency);

    return formatCurrency(formatter, nav);
  }, [currency, nav]);

  return { value: formattedNav, label };
}
