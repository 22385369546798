import { TBookmarks, TBookmarksAPI } from '~/types/bookmarks';

export function bookmarkMapper(bookmark: TBookmarksAPI): TBookmarks {
  return {
    ...bookmark,
    filters: bookmark.filters.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value,
      }),
      {},
    ),
  };
}

export function bookmarksMapper(bookmarks: TBookmarksAPI[]): TBookmarks {
  const bookmarksListClone = bookmarks.map(bookmarkMapper);

  return bookmarksListClone?.sort((a, b) => b.id - a.id)?.[0] || {};
}
