import { IColumn } from 'react-ui-kit-exante';

import { typeIconNames } from '~/constants';
import { InstrumentIcon } from '~/shared/components';
import { TAccountRiskSettingsSummary } from '~/types/accounts';

export const columns: IColumn<TAccountRiskSettingsSummary>[] = [
  {
    Header: 'Type',
    accessor: 'type',
    disableFilters: true,
    Cell: ({ value }: IColumn<TAccountRiskSettingsSummary>) => {
      const name = value.toUpperCase();
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InstrumentIcon iconName={typeIconNames[name]} />
          <span>{name}</span>
        </div>
      );
    },
    minWidth: 200,
  },
  {
    Header: 'Rate',
    accessor: 'rate',
    disableFilters: true,
    type: 'textInput',
    editParams: {
      editable: true,
    },
  },
];
