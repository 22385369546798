import { clone, isEqual } from 'lodash';

import { PATH_DELIMITER } from '~/constants/common';

import { ActionTypes, FIELDS_FOR_NODE } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function permissionsForNodeSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.PERMISSIONS_FOR_NODE_SET],
) {
  const [newPermissions, initialPermissions] = payload;
  const initial = clone(state.permissions.initial);
  const changed = clone(state.permissions.changed);

  const metadata = newPermissions.id
    .split(PATH_DELIMITER)
    .reduce<Record<string, string>>((acc, item, index) => {
      acc[FIELDS_FOR_NODE[index]] = item;

      return acc;
    }, {});

  if (!initial[initialPermissions.id]) {
    initial[initialPermissions.id] = {
      ...initialPermissions,
      ...metadata,
    };
  }

  if (!isEqual(newPermissions, initial[newPermissions.id])) {
    changed[newPermissions.id] = {
      ...newPermissions,
      ...metadata,
    };
  } else {
    delete changed[newPermissions.id];
  }

  return {
    ...state,
    permissions: {
      changed,
      initial,
    },
  };
}
