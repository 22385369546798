import { INonTradingCommissionGroupsResponse } from '~/types/nonTradingCommissions';

import { ValueCell } from '../components';

import { removeDotsForTableColumns } from './removeDotsForTableColumns';

export function getColumns(groups?: INonTradingCommissionGroupsResponse[]) {
  if (!groups) {
    return [];
  }

  return [
    {
      Header: 'Commission Name',
      accessor: 'commissionName',
    },
    ...groups.map(({ name, currency }) => ({
      Header: currency ? `${name} (${currency})` : name,
      accessor: removeDotsForTableColumns(name),
      Cell: ValueCell,
      disableSortBy: true,
    })),
  ];
}
