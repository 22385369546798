import { useFormContext } from 'react-hook-form';

import { FormCheckboxContainer } from '../../form';
import { IFormData } from '../types';

import { PanelWrapper } from './ServicesPermissionsContainer.styled';
import {
  ServicePermissionContainer,
  ServicesPermissions,
  Service,
} from './components';

export const ServicesPermissionsContainer = () => {
  const { watch } = useFormContext<IFormData>();
  const services = watch('services');

  return (
    <ServicesPermissions
      group={Object.entries(services).map(([serviceName, serviceState]) => (
        <Service
          key={serviceName}
          service={
            <PanelWrapper
              key={serviceName}
              title={serviceName}
              action={
                <FormCheckboxContainer
                  name={`services.${serviceName}.enabled`}
                />
              }
            >
              {null}
            </PanelWrapper>
          }
          servicePermissions={
            <ServicePermissionContainer
              serviceName={serviceName}
              serviceState={serviceState}
            />
          }
        />
      ))}
    />
  );
};
