import { ReactChild } from 'react';

import { StyledHeaderWrapper } from './UserPageTemplate.styled';

interface IUserPageTemplateProps {
  header: ReactChild;
  tabManagement: ReactChild;
}

export const UserPageTemplate = ({
  header,
  tabManagement,
}: IUserPageTemplateProps) => {
  return (
    <>
      <StyledHeaderWrapper>{header}</StyledHeaderWrapper>

      <div>{tabManagement}</div>
    </>
  );
};
