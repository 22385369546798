import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';

export function getAllPathsSet(
  strings: string[],
  separator = REGEX_LAST_SEGMENT_IN_PATH,
): Set<string> {
  const set = new Set(strings);

  strings.forEach((string) => {
    while (string.match(separator)) {
      string = string.replace(separator, '');
      set.add(string);
    }
  });

  return set;
}
