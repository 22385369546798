import { TCreateAccountPayload } from '~/api/accounts/types';

import { TAccountFormState } from '../types';

type TValidPayload = Omit<TCreateAccountPayload, 'clientId'>;

const isInherit = (str: unknown): str is 'inherit' => str === 'inherit';

const isValidPayload = (
  values: Record<string, unknown>,
): values is TValidPayload => {
  return (
    !isInherit(values?.type) &&
    !isInherit(values?.accountPurpose) &&
    Boolean(values?.id)
  );
};

export const prepareFormData = (
  values: TAccountFormState,
  defaultAccountId: string,
): Omit<TCreateAccountPayload, 'clientId'> | null => {
  const copy = { ...values };
  if (!copy.id) {
    copy.id = defaultAccountId;
  }
  if (isInherit(copy.type)) {
    delete copy.type;
  }

  if (
    ['Equity', 'Assets'].includes(String(copy.type)) ||
    copy.accountPurpose === 'null'
  ) {
    copy.accountPurpose = null;
  }

  if (isInherit(copy.accountPurpose)) {
    delete copy.accountPurpose;
  }

  return isValidPayload(copy) ? copy : null;
};
