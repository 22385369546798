import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { InputDatePicker } from 'react-ui-kit-exante';

import { toDate } from '~/shared/utils/dates';

interface IFormDateTimePickerContainerProps {
  disabled?: boolean;
  label?: string;
  name: string;
}

export const FormDateTimePickerContainer = ({
  disabled = false,
  label = '',
  name,
}: IFormDateTimePickerContainerProps) => {
  const formContext = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control: formContext.control,
    name,
  });

  const controlProps = useMemo(() => ({ warning: isDirty }), [isDirty]);

  return (
    <InputDatePicker
      dateFormat="dd.MM.yyyy HH:mm"
      disabled={disabled}
      inputProps={{
        size: 'medium',
        label,
      }}
      name={name}
      onChange={onChange}
      selected={toDate(value)}
      showTimeInput
      {...controlProps}
    />
  );
};
