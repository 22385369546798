import {
  convertCommissionsRulesToArray,
  getTotal,
} from '~/shared/utils/commissions';
import { ICommissionTreeResponse } from '~/types/commissions';
import { RowType } from '~/types/common';

import { getFetchCommissionsInstrumentsEndpoint } from '../endpoints';
import {
  ISearchCommissionsInstrumentsParams,
  ISearchInstrumentsReturn,
} from '../types';

import { commissionsApi } from './commissionsApi';

export const {
  useLazySearchCommissionsInstrumentsQuery,
  endpoints: {
    searchCommissionsInstruments: {
      matchFulfilled: searchCommissionsInstrumentsMatchFulfilled,
    },
  },
} = commissionsApi.injectEndpoints({
  endpoints: (builder) => ({
    searchCommissionsInstruments: builder.query<
      ISearchInstrumentsReturn,
      ISearchCommissionsInstrumentsParams
    >({
      // We need custom error type here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { search, skip, queryParams, instruments = [] } = params;

        if (!queryParams) {
          return { data: { instruments: [], isFinish: true } };
        }

        const { layer, entity } = queryParams;
        const newParams = { search, skip, limit: Infinity };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsInstrumentsEndpoint(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            error: {
              message: error,
            },
          };
        }

        const { data: dataInstruments } = data;

        const newInstruments = [
          ...instruments,
          ...dataInstruments.map(
            ({
              commission: { rules, ...commission },
              ...instrument
            }: ICommissionTreeResponse) => {
              const arrayedRules = convertCommissionsRulesToArray(rules);

              return {
                ...instrument,
                ...commission,
                ...arrayedRules,
                total: getTotal(arrayedRules),
                rowType: RowType.Instrument,
              };
            },
          ),
        ];

        return {
          data: {
            instruments: newInstruments,
            isFinish: true,
          },
        };
      },
      providesTags: ['CommissionsSearchInstruments'],
    }),
  }),
  overrideExisting: true,
});
