import { useMemo } from 'react';

import {
  useGetCommissionGroupsForOptionsQuery,
  useGetLimitGroupsQuery,
  useGetNonTradingCommissionGroupsQuery,
  useGetOvernightsGroupQuery,
  useGetSymbolPermissionsGroupsQuery,
} from '~/api';
import { transformCommissionsGroupsToOptions } from '~/api/commissions/helpers';
import { transformLimitGroupsToOptions } from '~/api/limits/helpers';
import { transformSymbolPermissionsGroupsToOptions } from '~/api/symbolPermissions/helpers';
import { useGroups } from '~/pages/InterestRatesPage/hooks';
import { transformCommissionGroupsToOptions } from '~/shared/utils/commissions';
import { IOption } from '~/types/form';

export const useOptions = () => {
  const { data: commissions } = useGetCommissionGroupsForOptionsQuery(
    undefined,
    {
      selectFromResult: transformCommissionsGroupsToOptions,
    },
  );

  const { data: overnightSets } = useGetOvernightsGroupQuery();

  const { data: limits } = useGetLimitGroupsQuery(undefined, {
    selectFromResult: transformLimitGroupsToOptions,
  });

  const { data: permissions } = useGetSymbolPermissionsGroupsQuery(undefined, {
    selectFromResult: transformSymbolPermissionsGroupsToOptions,
  });

  const { data: commissionGroupsTypes } =
    useGetNonTradingCommissionGroupsQuery();

  const { groupsOptions: interests } = useGroups();

  return useMemo(
    () => ({
      commissionGroupsTypes: transformCommissionGroupsToOptions(
        commissionGroupsTypes,
      ),
      commissions,
      interests: (interests || []) as IOption[],
      limits,
      overnights: overnightSets
        ? (overnightSets.reduce(
            (acc, { name: label, id: value }) => {
              acc.push({ label, value: String(value) });
              return acc;
            },
            [{ label: 'Default', value: null }] as {
              value: string | null;
              label: string;
            }[],
          ) as IOption[])
        : [],
      permissions,
    }),
    [
      commissionGroupsTypes,
      commissions,
      interests,
      limits,
      overnightSets,
      permissions,
    ],
  );
};
