import { useContext } from 'react';
import { Panel } from 'react-ui-kit-exante';

import { useCurrency } from '~/hooks';
import { clientPageContext } from '~/pages/ClientPage/context/ClientPageContext';
import { StaticCenteredLoader } from '~/shared/components';
import { formatDateToYYYYMMDD } from '~/shared/utils';
import { useGetClientAccountsSummaryQuery } from '~/store/client/clientSummary';
import { IAccountSummary } from '~/types/accounts';
import { IUseQueryReturn } from '~/types/rtkQuery';

import { EnchantedPositionsContainer } from '../../PositionsContainer';

interface IClientSummaryProps {
  accountIDs: string[];
}

export const ClientSummary = ({ accountIDs }: IClientSummaryProps) => {
  const currency = useCurrency();
  const {
    clientAccountsSummary: {
      filters: { date },
    },
  } = useContext(clientPageContext);

  const { data: clientAccountsSummary, isLoading } =
    useGetClientAccountsSummaryQuery<IUseQueryReturn<IAccountSummary>>({
      date: formatDateToYYYYMMDD(date || new Date()),
      currency,
      accounts: accountIDs,
    });

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  if (!clientAccountsSummary?.positions?.length) {
    return <Panel>No data</Panel>;
  }

  return (
    <EnchantedPositionsContainer
      positions={clientAccountsSummary.positions}
      currency={currency}
    />
  );
};
