interface ILeftArrowIconProps {
  width?: string;
  height?: string;
}

export const LeftArrowIcon = ({
  width = '12',
  height = '22',
}: ILeftArrowIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 22"
    >
      <path
        stroke="#818C99"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11 21L1 11 11 1"
      />
    </svg>
  );
};
