export enum EMarginType {
  BlockedFunds = 'blocked_funds',
  InterspreadMargin = 'interspread_margin',
  Margin = 'margin',
  OptionMinimum = 'option_minimum',
  OrderMargin = 'order_margin',
  ScanningRisk = 'scanning_risk',
}

export interface IMargin {
  type: EMarginType;
  price: string | null;
  position: string | null;
  margin: string | null;
  leverageRate: string | null;
  extremeMargin: string | null;
  effectiveQtty: string | null;
  currencyLeverageRateShort: string | null;
  currencyLeverageRateLong: string | null;
  currency: string | null;
  crossrate: string | null;
  convertedMargin: string | null;
  convertedExtremeMargin: string | null;
  assetType: string | null;
  asset: string | null;
}

export interface IBlockedFunds {
  asset: string;
  convertedExtremeMargin: string | null;
  convertedMargin: string | null;
  currency: string | null;
  extremeMargin: string | null;
  leverageRate: string | null;
  lockId: number;
  lockedQtty: string | null;
  margin: string | null;
  reason: string | null;
  type: EMarginType.BlockedFunds;
}

export interface IOrderMargin {
  asset: string | null;
  convertedMargin: string | null;
  currency: string | null;
  margin: string | null;
  type: EMarginType.OrderMargin;
}

interface IBaseScanningRisk {
  assetsLongList: string[] | null;
  assetsShortList: string[] | null;
  convertedExtremeMargin: string | null;
  convertedMargin: string | null;
  currency: string | null;
  extremeMargin: string | null;
  leverageRate: string | null;
  margin: string | null;
  type: EMarginType.ScanningRisk;
  underlying: string | null;
}

export interface IScanningRiskResponse extends IBaseScanningRisk {
  assetsList: string[] | null;
}

export interface IScanningRisk extends IBaseScanningRisk {
  asset: string | null;
}

interface IBaseInterspreadMargin {
  assetsLongList: string[] | null;
  assetsShortList: string[] | null;
  convertedExtremeMargin: string | null;
  convertedMargin: string | null;
  currency: string | null;
  extremeMargin: string | null;
  leverageRate: string | null;
  margin: string | null;
  spreads: {
    frontFront: string | null;
    frontBack: string | null;
    backBack: string | null;
  }; // Move to Interface if it will be use
  type: EMarginType.InterspreadMargin;
  underlying: string | null;
}

export interface IInterspreadMarginResponse extends IBaseInterspreadMargin {
  assetsList: string[] | null;
}

export interface IInterspreadMargin extends IBaseInterspreadMargin {
  asset: string | null;
}

interface IBaseOptionMinimum {
  assetsLongList: string[] | null;
  assetsShortList: string[] | null;
  convertedExtremeMargin: string | null;
  convertedMargin: string | null;
  currency: string | null;
  extremeMargin: string | null;
  leverageRate: string | null;
  margin: string | null;
  type: EMarginType.OptionMinimum;
  underlying: string | null;
}

export interface IOptionMinimumResponse extends IBaseOptionMinimum {
  assetsList: string[] | null;
}

export interface IOptionMinimum extends IBaseOptionMinimum {
  asset: string | null;
}

export interface IMappedMarginStructure {
  margin: IMargin[];
  blockedFunds: IBlockedFunds[];
  orderMargin: IOrderMargin[];
  interspreadMargin: IInterspreadMargin[];
  scanningRisk: IScanningRisk[];
  optionMinimum: IOptionMinimum[];
}

export type TMarginStructure =
  | IMargin
  | IBlockedFunds
  | IOrderMargin
  | IInterspreadMargin
  | IScanningRisk
  | IOptionMinimum;

export interface IAccountMetrics {
  totalOrderMargin?: string | null;
  totalCash?: string | null;
  totalBlockedMargin?: string | null;
  requiredMargin?: string | null;
  percentageTotalDailyPnl?: string | null;
  netAssetValue?: string | null;
  marginUtilization?: string | null;
  freeMoney?: string | null;
  convertedDailyPnl?: string | null;
  totalFreeMoney?: string | null;
  cashFreeMoney?: string | null;

  marginStructure?: TMarginStructure[];
}
