import {
  FilterLayers,
  IGetResolveOvernightsTreeParams,
  IStateOvernights,
} from '~/types/overnights';

export function getQueryParams({
  layer,
  account,
  group,
}: IStateOvernights['filters']['select'] & {
  relatedGroup: IStateOvernights['filters']['relatedGroup'];
}): IGetResolveOvernightsTreeParams | false {
  if (layer === FilterLayers.Default) {
    return { layer };
  }

  if (layer === FilterLayers.Accounts && account) {
    return {
      layer,
      entity: account,
    };
  }

  if (layer === FilterLayers.Groups) {
    return {
      layer: FilterLayers.Groups,
      entity: group,
    };
  }

  return false;
}
