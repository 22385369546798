import { isError } from 'lodash';

import { getPostCommissionsEndpoint } from '../endpoints';

import { commissionsApi } from './commissionsApi';

export const {
  useSaveCommissionsMutation,
  endpoints: {
    saveCommissions: { matchFulfilled: saveCommissionsMatchFulfilled },
  },
} = commissionsApi.injectEndpoints({
  endpoints: (builder) => ({
    saveCommissions: builder.mutation({
      query: ({ changedCommissions, layer, entity }) => ({
        url: getPostCommissionsEndpoint(layer, entity),
        method: 'POST',
        data: changedCommissions,
      }),
      transformErrorResponse: (e) => {
        if (isError(e)) {
          return e.message;
        }
        return e;
      },
    }),
  }),
  overrideExisting: true,
});
