import { pick } from 'lodash';

import { EMPTY_ARRAY } from '~/constants';

import { COMMISSION_SETTINGS_DELETE_EXPECTED_FIELDS } from './constants';
import {
  TAccountRegularCommission,
  TAccountRegularCommissions,
  TAccountRegularCommissionsTableRow,
  TRegularCommissions,
} from './types';

export const commissionsTableRowMap = (
  commission: TAccountRegularCommission | null,
  findedCommission?: TRegularCommissions | null,
  accountId?: string,
): TAccountRegularCommissionsTableRow => {
  return {
    commissionId: findedCommission?.id,
    accountId: commission?.accountId || accountId,
    name: findedCommission?.name,
    rate: findedCommission?.rate,
    currency: findedCommission?.currency,
    frequency: findedCommission?.rule.type,
    active: commission?.active ?? true,
  };
};

export const commissionsAccountMapper = (
  accountRegularCommissions: TAccountRegularCommissions,
  regularCommissions: TRegularCommissions[],
): TAccountRegularCommissionsTableRow[] => {
  return (
    accountRegularCommissions?.map((commission) => {
      const findedCommission = regularCommissions.find(
        (regularCommission) => regularCommission.id === commission.commissionId,
      );
      return commissionsTableRowMap(commission, findedCommission);
    }) || EMPTY_ARRAY
  );
};

export const prepareCommissionsSettingsForDelete = (
  commission: TAccountRegularCommissionsTableRow,
) => {
  return pick(commission, COMMISSION_SETTINGS_DELETE_EXPECTED_FIELDS);
};
