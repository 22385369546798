import { IAccount } from '~/types/accounts';

const getTime = (timestamp: string) => new Date(timestamp).getTime();

const sortByCreated = (a: IAccount, b: IAccount) =>
  getTime(b.created) - getTime(a.created);

export const getDefaultTemplate = (accounts: IAccount[] | undefined) => {
  if (!accounts || accounts.length) {
    return '';
  }
  const [firstAccount] = accounts.sort(sortByCreated);

  if (firstAccount) {
    return firstAccount.id;
  }
  return '';
};
