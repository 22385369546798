import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { IChangedOvernights } from '~/types/overnights';

export function getNodesForReload(
  changedOvernights: IChangedOvernights,
): string[] {
  const ids = new Set<string>();
  const { nodes, instruments } = changedOvernights;

  Object.values(instruments).forEach(({ path, override }) => {
    if (!override) {
      ids.add(path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''));
    }
  });

  Object.values(nodes).forEach(({ path, override }) => {
    if (!override) {
      ids.add(path);
    }
  });

  return Array.from(ids);
}
