import { IconButton } from 'react-ui-kit-exante';

import CardFormActionsStyles from './CardFormActions.module.css';

interface ICardFormActionsProps {
  onDelete(): void;
  onClose(): void;
}

export const CardFormActions = ({
  onDelete,
  onClose,
}: ICardFormActionsProps) => {
  return (
    <div className={CardFormActionsStyles.Wrapper}>
      <IconButton iconName="DeleteIcon" onClick={onDelete} iconSize={20} />
      <IconButton iconName="CloseIcon" onClick={onClose} />
    </div>
  );
};
