import { ILegalEntityDetail } from '~/types/legalEntities';

import { FETCH_LEGAL_ENTITIES_ENDPOINT } from '../endpoints';

import { legalEntitiesApi } from './legalEntitiesApi';

export const { useLazyGetLegalEntitiesQuery, useGetLegalEntitiesQuery } =
  legalEntitiesApi.injectEndpoints({
    endpoints: (builder) => ({
      getLegalEntities: builder.query<ILegalEntityDetail[], void>({
        query: () => ({
          url: FETCH_LEGAL_ENTITIES_ENDPOINT,
        }),
        providesTags: ['LegalEntities'],
        transformResponse: (response) => response.values,
      }),
    }),
  });
