import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  IColumn,
  IRowExpand,
  Notification,
} from 'react-ui-kit-exante';

import { LIMITS_MESSAGES } from '~/constants';
import { updateNodeValue, updateInstrumentValue } from '~/store/limits';
import { selectLayer } from '~/store/treeStructure';
import { RowType } from '~/types/common';
import { ILimitTree } from '~/types/limits';
import { FilterLayers } from '~/types/treeStructure';

interface UpperCapCellProps {
  column: IColumn<ILimitTree>;
  value: boolean | string;
  row: IRowExpand<ILimitTree>;
}

export const UpperCapCell = ({ column, value, row }: UpperCapCellProps) => {
  const dispatch = useDispatch();
  const filtersLayer = useSelector(selectLayer);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!row.values.override && FilterLayers.Default !== filtersLayer) {
        Notification.warning({
          title: LIMITS_MESSAGES.NON_OVERRIDE_LIMIT,
        });

        return;
      }

      const actionFn =
        row.original.rowType === RowType.Node
          ? updateNodeValue
          : updateInstrumentValue;

      dispatch(
        actionFn({
          path: row.original.path,
          value: e.target.checked,
          column: column.id,
        }),
      );
    },
    [filtersLayer, column, dispatch, row],
  );

  const getChecked = () => {
    if (value === 'upper_cap') {
      return true;
    }
    if (typeof value === 'boolean') {
      return value;
    }

    return false;
  };

  return <Checkbox checked={getChecked()} onChange={onChangeHandler} />;
};
