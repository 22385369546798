interface IGetInitialAccordionStateArguments {
  items: Record<string, unknown>;
  lsKey?: string;
  closeDefault: boolean;
}

export function getInitialAccordionState({
  items,
  lsKey,
  closeDefault,
}: IGetInitialAccordionStateArguments) {
  const lsState = lsKey ? localStorage.getItem(lsKey) : null;

  if (!lsState) {
    return Object.keys(items).reduce<Record<string, boolean>>(
      (acc, curr) => ({ ...acc, [curr]: !closeDefault }),
      {},
    );
  }

  return JSON.parse(lsState);
}
