import { array, lazy, number, object, string } from 'yup';

import { YUP_SELECTED_VALUE } from '~/constants';

export const getValidationSchema = () =>
  object({
    accountPurposes: array().nullable(),
    accountTypes: array().nullable(),
    blackListAccounts: array().nullable(),
    categories: array().nullable(),
    custodians: array().nullable(),
    endDateTime: string().nullable(),
    name: string().nullable(),
    operationTypes: array().nullable(),
    sourceAccounts: array().nullable(),
    sourceLegalEntities: array().nullable(),
    startDateTime: string().required(),
    tags: object({
      transfer: object({
        from: object({
          legalEntity: string().nullable(),
          environment: string().nullable(),
        }).nullable(),
        to: object({
          legalEntity: string().nullable(),
          environment: string().nullable(),
        }).nullable(),
      }).nullable(),
      rebate: object({
        from: object({
          account: lazy((value) =>
            typeof value === 'string'
              ? string().nullable()
              : object(YUP_SELECTED_VALUE).nullable(),
          ),
          legalEntity: string().nullable(),
        }),
      }).nullable(),
    }).nullable(),
    targetAccount: lazy((value) =>
      typeof value === 'string'
        ? string().required()
        : object(YUP_SELECTED_VALUE).required(),
    ),
    transferMultiplier: number().required(),
  });

export const getValidationSchemaWrapped = () =>
  object({
    mirroringRule: getValidationSchema(),
  });
