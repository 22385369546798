import { ActionTypes } from '../../../constants';
import { ICommonPropertiesSymbolPermissions } from '../../../types';

import { getAndInsertInstrumentsToNode } from './getAndInsertInstrumentsToNode';

export async function handleColumnNameClick({
  changedPermissions,
  dispatch,
  downloadedPaths,
  expanded,
  expandedRows,
  filters,
  matchIdAndPositionInNonSearchTable,
  permissionsByPath,
  refreshQueue,
  row,
  setTableData,
  updateMatchIdAndPositionInNonSearchTable,
  updateTableSizes,
  withExpired,
}: Pick<
  ICommonPropertiesSymbolPermissions,
  | 'changedPermissions'
  | 'dispatch'
  | 'downloadedPaths'
  | 'expanded'
  | 'expandedRows'
  | 'filters'
  | 'matchIdAndPositionInNonSearchTable'
  | 'permissionsByPath'
  | 'row'
  | 'setTableData'
  | 'updateMatchIdAndPositionInNonSearchTable'
  | 'updateTableSizes'
  | 'withExpired'
> & { refreshQueue: Set<string> }): Promise<boolean | void> {
  const dontHaveInstrumentsInCache =
    row.original.rowType === 'node' &&
    !downloadedPaths.has(row.original.path) &&
    !row.subRows.length;

  // if row is expanded we should close this row and all subrows
  if (row.isExpanded) {
    row.toggleRowExpanded();
    refreshQueue.delete(row.original.id);

    dispatch({
      type: ActionTypes.EXPANDED_ROWS_SET,
      payload: {
        ...expandedRows,
        [row.id]: !expandedRows[row.id],
      },
    });

    updateTableSizes();

    return;
  }

  // if we can download instruments and this instruments doesn't exist in the cache
  if (dontHaveInstrumentsInCache) {
    await getAndInsertInstrumentsToNode({
      changedPermissions,
      dispatch,
      expanded,
      filters,
      matchIdAndPositionInNonSearchTable,
      permissionsByPath,
      refreshQueue,
      row,
      setTableData,
      skip: 0,
      updateMatchIdAndPositionInNonSearchTable,
      withExpired,
    });

    return;
  }

  // processing the click on the cells 'id' or 'name'
  if (row.canExpand && row.toggleRowExpanded) {
    row.toggleRowExpanded();

    dispatch({
      type: ActionTypes.EXPANDED_ROWS_SET,
      payload: {
        ...expandedRows,
        [row.id]: true,
      },
    });

    updateTableSizes();
  }
}
