export const FIELDS_FOR_POST_DEFAULT_TREE = [
  'benchmarkNegative',
  'benchmarkPositive',
  'markupNegative',
  'markupPositive',
];

export const EMPTY_OVERNIGHTS_TREE = {
  data: { tree: [], positionByIdInTree: {} },
};
