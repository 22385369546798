import { TMirroringRulePayload } from '~/types/mirroringRules';

export const defaultValues: TMirroringRulePayload = {
  name: '',
  startDateTime: new Date(),
  tags: {
    transfer: {
      from: {
        legalEntity: '',
        environment: '',
      },
      to: {
        legalEntity: '',
        environment: '',
      },
    },
    rebate: {
      from: {
        account: '',
        legalEntity: '',
      },
    },
  },
  targetAccount: '',
  transferMultiplier: 1,
};
