import { styled } from 'react-ui-kit-exante';

export const StyledHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
}));

export const StyledHistoricalMode = styled('div')(() => ({
  marginLeft: 'auto',
}));

export const StyledIndicator = styled('div')(() => ({
  marginBottom: '16px',
}));

export const StyledWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const StyledList = styled('ul')(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  width: '50%',

  ':not(:last-child)': {
    paddingRight: '32px',
  },
}));

export const StyledItem = styled('li')(({ theme }) => ({
  ':not(:empty)': {
    fontSize: '13px',
    paddingTop: '8px',
  },

  ':first-child': {
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.color.typo.primary,
    paddingTop: 0,
  },

  ':not(:first-child) dt': {
    color: theme.color.typo.primary,
  },
  ':not(:first-child) dd': {
    color: theme.color.typo.primary,
  },
}));
