import {
  ChangeEventHandler,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { Loader, Notification, Select } from 'react-ui-kit-exante';

import { transformVariantsToSelectOptions } from '~/shared/utils';
import { selectCurrencies } from '~/store/currencies';
import { EStatus } from '~/types/api';

import { LOCAL_STORAGE_KEY } from './constants';
import { prepareCurrency, createCurrencyChangeEvent } from './helpers';
import { PreparedCurrency } from './types';

let event: ReturnType<typeof createCurrencyChangeEvent> | null = null;

export const CurrencySelectorContainer = () => {
  const [currency, setCurrency] = useState('');
  const { data: currencies, status, error } = useSelector(selectCurrencies);

  const preparedCurrencies = useMemo(() => {
    return prepareCurrency(currencies);
  }, [currencies]);

  const currenciesSelectOptions = useMemo(
    () =>
      transformVariantsToSelectOptions(
        preparedCurrencies.map((item) => item.currency),
      ),
    [preparedCurrencies],
  );

  useEffect(() => {
    if (error) {
      Notification.error({
        title: error,
      });
    }
  }, [error]);

  const updateCurrency = useCallback(
    (value: string) => {
      setCurrency(value);

      const currencyItem = preparedCurrencies?.find(
        (item) => item.currency === value,
      );
      if (currencyItem) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currencyItem));
      }

      if (event !== null) {
        window.dispatchEvent(event);
      }
    },
    [preparedCurrencies],
  );

  useEffect(() => {
    if (event === null) {
      event = createCurrencyChangeEvent();
    }
  }, []);

  const getInitialCurrency = useCallback(() => {
    const currentValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (currentValue) {
      try {
        const parsed: PreparedCurrency[number] = JSON.parse(currentValue);
        const isExistedCurrency = preparedCurrencies.some(
          (item) => item.currency === parsed?.currency,
        );
        if (isExistedCurrency) {
          return parsed?.currency;
        }
      } catch {
        return '';
      }
    }
    return '';
  }, [preparedCurrencies]);

  const setFirstCurrencyFromList = useCallback(() => {
    if (preparedCurrencies.length) {
      const defaultValue = preparedCurrencies[0].currency;
      updateCurrency(defaultValue);
    }
  }, [preparedCurrencies, updateCurrency]);

  useEffect(() => {
    const initialCurrency = getInitialCurrency();
    if (!initialCurrency) {
      setFirstCurrencyFromList();
    } else {
      updateCurrency(initialCurrency);
    }
  }, [
    preparedCurrencies,
    setFirstCurrencyFromList,
    getInitialCurrency,
    updateCurrency,
  ]);

  const handleChangeCurrency: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    updateCurrency(value);
  };

  if (status === EStatus.Idle || status === EStatus.Loading) {
    return <Loader />;
  }
  if (error) {
    return null;
  }

  return (
    <Select
      value={currency}
      options={currenciesSelectOptions}
      onChange={handleChangeCurrency}
      size="small"
      menuItemProps={{
        sx: {
          color: '#b8bbb7',
        },
      }}
      menuProps={{
        sx: {
          maxHeight: 400,
        },
      }}
    />
  );
};
