// todo move it to correct file
import { accountTypes, types } from '~/constants/accountTypes';

export const ACCOUNT_FORM = 'account-form';

export const SAN_SYMBOLS_LENGTH = 9;
export const PAN_SYMBOLS_LENGTH = 16;
export const SAN_ERROR_MESSAGE = `Must be equal to ${SAN_SYMBOLS_LENGTH} numbers`;
export const PAN_ERROR_MESSAGE = `Must be equal to ${PAN_SYMBOLS_LENGTH} numbers`;

const toOption = (value: string) => ({ value, label: value });

export const accountPurposesByAccountType = (
  defaultAccountPurposes: string[],
) => ({
  [`${accountTypes.Liabilities}-${types.Liabilities}`]: {
    default: 'Trading',
    options: ['Trading', ...defaultAccountPurposes],
  },
  [`${accountTypes.Partner}-${types.Liabilities}`]: {
    default: 'Partner',
    options: ['Partner', ...defaultAccountPurposes],
  },
  [`${accountTypes.Payment}-${types.Liabilities}`]: {
    default: 'Payment',
    options: ['Payment', ...defaultAccountPurposes],
  },
  [`${accountTypes.Equity}-${types.Equity}`]: {
    default: 'null',
    options: defaultAccountPurposes,
  },
  [`${accountTypes.Assets}-${types.Assets}`]: {
    default: 'null',
    options: defaultAccountPurposes,
  },
});

export const getAccountPurposeOptionsByType = (
  accountType: keyof typeof accountTypes,
  type: keyof typeof types,
  defaultAccountPurposes: string[],
) => {
  const key = `${accountType}-${type}`;
  const map = accountPurposesByAccountType(defaultAccountPurposes);

  if (key in map) {
    const uniqPurposes = Array.from(new Set(map[key].options) || []);
    return uniqPurposes.map(toOption);
  }

  return [];
};
