import { IOption } from '~/types/form';

import {
  FormDatePickerContainer,
  FormInputContainer,
  FormInputMaskContainer,
  FormSelectContainer,
} from '../../form';

import CardFormTemplateStyles from './CardFormTemplate.module.css';

const SAN_FORMAT = '#########';
const PAN_FORMAT = '##**********####';

interface ICardFormTemplateProps {
  selectedCardIndex: string;
  currenciesOptions: IOption[];
  interCashCardStatusesOptions: IOption[];
  limitsGroupOptions: IOption[];
}

export const CardFormTemplate = ({
  selectedCardIndex,
  currenciesOptions,
  interCashCardStatusesOptions,
  limitsGroupOptions,
}: ICardFormTemplateProps) => {
  return (
    <div className={CardFormTemplateStyles.Wrapper}>
      <FormInputContainer
        label="Id"
        name={`cards.${selectedCardIndex}.id`}
        readonly
      />
      <FormInputContainer
        label="Issue id"
        name={`cards.${selectedCardIndex}.issueId`}
        type="number"
      />
      <FormInputMaskContainer
        label="SAN"
        name={`cards.${selectedCardIndex}.san`}
        format={SAN_FORMAT}
      />
      <FormInputMaskContainer
        label="PAN"
        name={`cards.${selectedCardIndex}.pan`}
        format={PAN_FORMAT}
        allowEmptyFormatting
      />
      <FormSelectContainer
        label="Limits group"
        name={`cards.${selectedCardIndex}.limitsGroup`}
        options={limitsGroupOptions}
      />
      <FormSelectContainer
        label="Status"
        name={`cards.${selectedCardIndex}.status`}
        options={interCashCardStatusesOptions}
      />
      <FormSelectContainer
        label="Currency"
        name={`cards.${selectedCardIndex}.currency`}
        options={currenciesOptions}
      />
      <FormDatePickerContainer
        label="Expiry date"
        name={`cards.${selectedCardIndex}.expiryDate`}
      />
    </div>
  );
};
