import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

export const auditLogApi = createApi({
  reducerPath: 'auditLogApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['AuditLogs'],
  endpoints: () => ({}),
});
