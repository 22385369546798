import { has, isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Table,
  AutocompleteAsync,
  Select,
  Notification,
  ISelectOption,
  TCellData,
} from 'react-ui-kit-exante';

import {
  useLazySearchCommissionsInstrumentsQuery,
  useLazyGetCommissionsInstrumentsQuery,
  useLazyGetCommissionSettingsQuery,
} from '~/api';
import {
  useAddNewCommissionGroupMutation,
  useGetCommissionGroupsQuery,
  useRemoveCommissionGroupMutation,
} from '~/api/commissionGroups/commissionGroups.api';
import { EMPTY_ARRAY } from '~/constants';
import { useAutocomplete, usePrevious, useTableVirtualized } from '~/hooks';
import { prepareValue } from '~/pages/RiskArrays/helpers';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { Switch } from '~/shared/components/Switch';
import { getTableId, transformDataToSelectOptions } from '~/shared/utils';
import {
  selectCommissionsTree,
  selectExpandedRows,
  filtersSetGroup,
  filtersSetLayer,
  resetTable,
  selectDownloadedPaths,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
  filtersSetAccount,
  filtersSetRelatedGroup,
  selectChangedCommissions,
  selectFiltersRelatedGroup,
  updateNodeValue,
  updateInstrumentValue,
} from '~/store/commissions';
import { getQueryParams } from '~/store/commissions/helpers';
import { FilterLayers, tabs, ICommissionTree } from '~/types/commissions';

import CommissionsStyles from './Commissions.module.css';
import { SelectStyles } from './constants';
import { canExpandDefinition } from './helpers';
import { getAndInsertInstrumentsToNode } from './helpers/getAndInsertInstrumentsToNode';
import {
  useColumns,
  useCommissionTree,
  useHandleCellClick,
  useLayoutSearchParams,
  useSaveData,
} from './hooks';

const TABLE_HEADER_HEIGHT = 64;

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

export const Commissions = () => {
  const dispatch = useDispatch();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [, searchState] = useLazySearchCommissionsInstrumentsQuery();

  const tree = useSelector(selectCommissionsTree);
  const prevTree = usePrevious(tree);
  const expandedRows = useSelector(selectExpandedRows);

  const { fetchCommissionsTree, isLoadingTree, getCommissionsTree } =
    useCommissionTree();

  const { handleCellClick } = useHandleCellClick();

  const columns = useColumns();

  const { virtualized, updateTableSizes } = useTableVirtualized(
    tableRef.current,
    TABLE_HEADER_HEIGHT,
  );

  const [getInstruments] = useLazyGetCommissionsInstrumentsQuery();

  const filtersLayer = useSelector(selectFiltersLayer);
  const filtersGroup = useSelector(selectFiltersGroup);
  const filtersAccount = useSelector(selectFiltersAccount);
  const downloadedPaths = useSelector(selectDownloadedPaths);
  const [addNewCommissionGroup] = useAddNewCommissionGroupMutation();
  const [removeCommissionGroup] = useRemoveCommissionGroupMutation();
  const { nodes: changedNodes, instruments: changedInstruments } = useSelector(
    selectChangedCommissions,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveData] = useSaveData();
  const [allExpanded, setAllExpanded] = useState(false);
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const [fetchCommissionSettings] = useLazyGetCommissionSettingsQuery();

  const relatedGroup = useSelector(selectFiltersRelatedGroup);

  const layoutSearchParams = useLayoutSearchParams({ setAllExpanded });

  const hasChangedGroup =
    filtersLayer === FilterLayers.Accounts && filtersGroup !== relatedGroup;
  const hasChangedCommissions =
    !isEmpty(changedNodes) || !isEmpty(changedInstruments);

  const { data: groups } = useGetCommissionGroupsQuery({
    ignoreForbiddenError: true,
  });

  const commissionGroups = useMemo(() => {
    if (!groups) {
      return EMPTY_ARRAY;
    }

    return transformDataToSelectOptions(groups, {
      valueKey: 'id',
      labelKey: 'name',
      sort: true,
    }) as ISelectOption[];
  }, [groups]);

  const commissionsGroupsForAccount = useMemo(() => {
    if (!groups) {
      return EMPTY_ARRAY;
    }

    return [
      { label: 'Default', value: 'default' },
      ...transformDataToSelectOptions(groups, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as ISelectOption[];
  }, [groups]);

  const getAccountsCommissionsSet = useCallback(
    async (account: string) => {
      const response = await fetchCommissionSettings({ accountId: account });

      if (response.data) {
        dispatch(filtersSetRelatedGroup(response.data.commissionGroupId));
      }
    },
    [dispatch, fetchCommissionSettings],
  );

  const onChangeLayerHandler = useCallback(
    (tagName: string, _tabIndex: number | null, isMount = false) => {
      dispatch(resetTable({}));
      dispatch(filtersSetLayer(tagName));

      setSearchParams((params) => {
        if (!isMount) {
          params.delete('account');
        }

        params.set('layer', tagName);

        return params;
      });
    },
    [dispatch, setSearchParams],
  );

  const onChangeGroupHandler = useCallback(
    async (value: number | null) => {
      dispatch(
        filtersSetGroup({
          value,
          shouldResetDownloadedPaths: filtersLayer === FilterLayers.Groups,
        }),
      );

      if (filtersLayer === FilterLayers.Groups) {
        dispatch(
          resetTable({
            shouldResetExpandedRows: true,
            shouldResetDownloadedPaths: true,
          }),
        );
        fetchCommissionsTree({ params: { entity: value } });
      } else {
        dispatch(
          resetTable({
            shouldResetExpandedRows: false,
            shouldResetDownloadedPaths: false,
          }),
        );
        const groupQueryParams = getQueryParams({
          layer: FilterLayers.Groups,
          group: value,
          account: null,
          relatedGroup: null,
        });

        for (const path of downloadedPaths) {
          await getAndInsertInstrumentsToNode({
            dispatch,
            getInstruments,
            path,
            queryParams: groupQueryParams,
            skip: 0,
          });
        }
      }
    },
    [
      dispatch,
      downloadedPaths,
      fetchCommissionsTree,
      filtersLayer,
      getInstruments,
    ],
  );

  const onChangeAccountHandler = useCallback(
    (_: unknown, data: { value: string }) => {
      if (!data) {
        return;
      }

      const { value } = data;
      dispatch(filtersSetAccount(value));
      setSearchParams((params) => {
        if (['null', 'undefined'].includes(value)) {
          return params;
        }
        if (value) {
          params.set('account', value);
        } else {
          params.delete('account');
        }

        return params;
      });
      getCommissionsTree({
        entity: value,
        layer: FilterLayers.Accounts,
      });
    },
    [dispatch, getCommissionsTree, setSearchParams],
  );

  const setDefaultGroup = useCallback(
    (force?: boolean) => {
      if (
        force ||
        (!filtersGroup && commissionGroups && commissionGroups.length > 0)
      ) {
        const [defaultGroup] = commissionGroups;
        if (typeof defaultGroup.value === 'number') {
          onChangeGroupHandler(defaultGroup.value);
        }
      }
    },
    [filtersGroup, commissionGroups, onChangeGroupHandler],
  );

  const setDefaultAccount = useCallback(async () => {
    const autocompleteFn = getAccountsAutocompleteFn();
    const data = await autocompleteFn('');

    if (data.options) {
      const [defaultAccount] = data.options;
      if (defaultAccount) {
        onChangeAccountHandler(null, { value: String(defaultAccount.value) });

        setSearchParams((params) => {
          // params.set('account', defaultAccount.value);

          return params;
        });
      }
    }
  }, [getAccountsAutocompleteFn, onChangeAccountHandler, setSearchParams]);

  const handleDeleteGroup = useCallback(async () => {
    const res = await removeCommissionGroup({
      data: {
        groupId: `${filtersGroup}`,
      },
    });

    if (!has(res, 'error')) {
      Notification.success({
        title: 'Group was successfully deleted',
      });
      setDefaultGroup(true);
    }
  }, [setDefaultGroup, filtersGroup, removeCommissionGroup]);

  const handleAddGroup = useCallback(
    async (groupName: string) => {
      const res = await addNewCommissionGroup({
        data: {
          name: groupName,
        },
      });

      if (!has(res, 'error')) {
        Notification.success({
          title: 'Group was successfully added',
        });
      }
    },
    [addNewCommissionGroup],
  );

  const initialLayer = searchParams.get('layer');

  const preparedTabs = tabs.map((tab) => {
    return {
      tabName: tab,
      render: tab,
    };
  });

  useEffect(() => {
    if (!filtersGroup && filtersLayer === FilterLayers.Groups) {
      setDefaultGroup();
    }
  }, [setDefaultGroup, filtersLayer]);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Accounts && filtersAccount) {
      getAccountsCommissionsSet(filtersAccount);
    }
  }, [filtersAccount, filtersLayer, getAccountsCommissionsSet]);

  useEffect(() => {
    const initialAccount = searchParams.get('account');

    if (initialAccount) {
      onChangeAccountHandler(null, { value: initialAccount });
    } else if (
      !initialAccount &&
      [FilterLayers.Accounts].includes(filtersLayer)
    ) {
      setDefaultAccount();
    }
  }, [filtersLayer]);

  useEffect(() => {
    const layerFromSearchParams = searchParams.get('layer');

    if (layerFromSearchParams) {
      onChangeLayerHandler(layerFromSearchParams, null, true);
    }
  }, []);

  const handleCellUpdate = useCallback(
    (values: TCellData<ICommissionTree>[]) => {
      values.forEach((value) => {
        const {
          id,
          row: {
            original: { path, rowType },
          },
          value: newValue,
        } = value;

        const actionFn =
          rowType === 'node' ? updateNodeValue : updateInstrumentValue;

        if (newValue) {
          dispatch(
            actionFn({
              path,
              value: prepareValue(newValue),
              column: id,
            }),
          );
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    fetchCommissionsTree();
  }, []);

  useEffect(() => {
    if (
      prevTree.length !== tree.length ||
      (tree.length !== 0 && virtualized.height === 0)
    ) {
      updateTableSizes();
    }
  }, [prevTree, tree, updateTableSizes, virtualized.height]);

  return (
    <LayersLayout
      title="Commissions"
      tabs={preparedTabs}
      initialTab={initialLayer || FilterLayers.Default}
      pageControls={
        filtersLayer === FilterLayers.Default ? null : (
          <Switch
            condition={[
              filtersLayer === FilterLayers.Accounts,
              filtersLayer === FilterLayers.Groups,
            ]}
          >
            {[
              <>
                <AutocompleteAsync
                  fetchData={getAccountsAutocompleteFn()}
                  onChange={onChangeAccountHandler}
                  options={EMPTY_ARRAY}
                  placeholder="Accounts"
                  value={filtersAccount}
                  sx={SelectStyles}
                />
                <Select
                  label="Groups"
                  options={commissionsGroupsForAccount || EMPTY_ARRAY}
                  onChange={({ target: { value } }) => {
                    onChangeGroupHandler(
                      value === 'default' ? null : Number(value),
                    );
                  }}
                  value={filtersGroup === null ? 'default' : filtersGroup}
                  sx={SelectStyles}
                />
              </>,

              <Select
                key="groups"
                label="Groups"
                options={commissionGroups || EMPTY_ARRAY}
                onChange={({ target: { value } }) =>
                  onChangeGroupHandler(
                    value === 'default' ? null : Number(value),
                  )
                }
                value={filtersGroup ? `${filtersGroup}` : 'default'}
                sx={SelectStyles}
              />,
            ]}
          </Switch>
        )
      }
      searchParams={layoutSearchParams}
      table={
        <div ref={tableRef}>
          <Table
            className={CommissionsStyles.Table}
            columns={columns}
            copyPasteMode
            data={tree}
            defaultSortBy={[]}
            expanded={{
              canExpandDefinition,
              customExpandControl: true,
              listOfInitialExpandedRowKeys: expandedRows || allExpanded,
            }}
            handleCellClick={handleCellClick}
            isFlexLayout
            isLoading={isLoadingTree || searchState.isFetching}
            onTableCellUpdate={handleCellUpdate}
            saveViewParamsAfterLeave
            showTableInfo
            tableId={getTableId('Commissions')}
            virtualized={virtualized}
            isPinnedHeader
          />
        </div>
      }
      isSaveDisabled={!hasChangedGroup && !hasChangedCommissions}
      loading={isLoadingTree || searchState.isFetching}
      onAddGroup={handleAddGroup}
      onChangeLayer={onChangeLayerHandler}
      onDeleteGroup={handleDeleteGroup}
      onRefresh={() => fetchCommissionsTree({ forceRefetch: true })}
      onSave={saveData}
    />
  );
};
