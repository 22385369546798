import { MutableRefObject } from 'react';
import { UseFormReset } from 'react-hook-form';
import { array, object, string } from 'yup';

import {
  PAN_ERROR_MESSAGE,
  PAN_SYMBOLS_LENGTH,
  SAN_ERROR_MESSAGE,
  SAN_SYMBOLS_LENGTH,
} from '~/pages/AccountPage/constants';
import {
  validateIssueId,
  validateSanPanFields,
} from '~/pages/AccountPage/helpers';
import { cardsManagementService } from '~/resources';
import { getArrayDifference } from '~/shared/utils';
import { ICard, TEditableCard } from '~/types/cardsManagement';

import { LIMITS_GROUP } from './constants';
import { ICardForm } from './types';

export function prepareCardsOptions(cards: ICard[] | null) {
  if (!cards) {
    return [];
  }

  return cards.map((card, index) => ({
    name: card.issueId || `Card id: ${card.id || ''}`,
    id: index.toString(),
  }));
}

export function createDefaultCard(accountId?: string): TEditableCard {
  return {
    accountId: accountId || '',
    currency: 'EUR',
    issueId: null,
    expiryDate: '',
    limitsGroup: LIMITS_GROUP[0],
    status: 'Card is requested',
  };
}

export function createCardsSubmitHandler(
  previousCards: MutableRefObject<ICard[]>,
  reset: UseFormReset<ICardForm>,
  accountId: string,
) {
  return async ({ cards }: ICardForm) => {
    const { createdElements, editedElements, deletedElements } =
      getArrayDifference(previousCards.current, cards, 'id');

    const crudResponse = await cardsManagementService.cardsCUD(
      accountId,
      createdElements,
      editedElements,
      deletedElements,
    );

    const failedCreatedCards =
      createdElements.length > 0
        ? crudResponse[0].reduce<ICard[]>((acc, curr, index) => {
            if (curr === null) {
              return [...acc, createdElements[index]];
            }
            return acc;
          }, [])
        : [];

    // this request we need to update card ids (it generates on back-end)
    const updatedCards = await cardsManagementService.fetchCardsByAccountId(
      accountId,
    );

    // we reassign refs – it is ok
    // eslint-disable-next-line no-param-reassign
    previousCards.current = updatedCards;
    reset({ cards: updatedCards.concat(failedCreatedCards) });
  };
}

export const getValidationSchema = (previousCards: MutableRefObject<ICard[]>) =>
  object({
    cards: array().of(
      object({
        san: validateSanPanFields(
          SAN_SYMBOLS_LENGTH,
          SAN_ERROR_MESSAGE,
          'san',
          previousCards,
        ),
        pan: validateSanPanFields(
          PAN_SYMBOLS_LENGTH,
          PAN_ERROR_MESSAGE,
          'pan',
          previousCards,
        ),
        status: string().required(),
        issueId: validateIssueId(previousCards),
      }),
    ),
  });
