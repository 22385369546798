export const tabsIds = [
  'general',
  'accessRights',
  'passwordManagement',
  'accountPermissions',
  'activity',
];

export const DEFAULT_DIRTY_TABS_STATE = {
  general: false,
  accessRights: false,
  accountPermissions: false,
  activity: false,
};
