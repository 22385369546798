import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILocationState } from '~/types/router';

interface IUseBackArguments {
  defaultPath: string;
  state?: Record<string, unknown>;
}

export function useBack({ defaultPath, state }: IUseBackArguments) {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    const previousPage = (location.state as ILocationState)?.previousPath;

    if (previousPage) {
      if (state) {
        navigate(previousPage, {
          state,
        });
      } else {
        navigate(-1);
      }
    } else {
      navigate(defaultPath);
    }
  }, [state, defaultPath, location, navigate]);
}
