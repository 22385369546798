import { TCurrency } from '~/types/currencies';
import {
  IInterestRate,
  IInterestRatesBenchmark,
  IInterestRatesMarkup,
} from '~/types/interestRates';

import {
  IBenchmark,
  IMarkup,
  TInterestRatesMap,
  TUpdatedFields,
} from './types';

type TFields = {
  updated: boolean;
}[];

export const haveBeenUpdated = (fields: TFields) => {
  return fields.some(({ updated }) => updated);
};

export const getValues = <T>(data: TUpdatedFields<T>) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce<T>((acc, key) => {
    const { value } = data[key];
    return {
      ...acc,
      [key]: value,
    };
  }, {} as T);
};

export const createMarkupMap = (markup: IInterestRatesMarkup[]) => {
  return markup.reduce<Record<TCurrency, IMarkup>>(
    (acc, { currency, positive, negative, rebateNegative, rebatePositive }) => {
      acc[currency] = {
        positiveMarkup: positive,
        negativeMarkup: negative,
        positiveRebate: rebatePositive,
        negativeRebate: rebateNegative,
      };
      return acc;
    },
    {},
  );
};

export const createBenchmarkMap = (benchmark: IInterestRatesBenchmark[]) => {
  return benchmark.reduce<Record<TCurrency, IBenchmark>>(
    (acc, { currency, days, negative, positive }) => {
      acc[currency] = {
        days,
        positiveBenchmark: positive,
        negativeBenchmark: negative,
      };
      return acc;
    },
    {},
  );
};

export const createInterestRatesMap = (
  markup: IInterestRatesMarkup[],
  benchmark: IInterestRatesBenchmark[],
) => {
  const markupMap = createMarkupMap(markup);
  const benchmarkMap = createBenchmarkMap(benchmark);
  const currencies = Object.keys(markupMap) as TCurrency[];

  return currencies.reduce<TInterestRatesMap>((acc, currency) => {
    acc[currency] = {
      currency,
      ...markupMap[currency],
      ...benchmarkMap[currency],
    };
    return acc;
  }, {});
};

export const addUpdatedBenchmarkData = (
  currency: TCurrency,
  updatedObject: IInterestRate,
  updatedBenchmarkData: IInterestRatesBenchmark[] | null,
) => {
  if (updatedBenchmarkData) {
    const updatedRow = updatedBenchmarkData.find(
      (item) => item.currency === currency,
    );
    if (updatedRow) {
      return {
        ...updatedObject,
        positiveBenchmark: updatedRow.positive,
        negativeBenchmark: updatedRow.negative,
        days: updatedRow?.days,
      };
    }
  }
  return updatedObject;
};

export const addUpdatedMarkupData = (
  currency: TCurrency,
  updatedObject: IInterestRate,
  updatedMarkupData: IInterestRatesMarkup[] | null,
) => {
  if (updatedMarkupData) {
    const updatedRow = updatedMarkupData.find(
      (item) => item.currency === currency,
    );
    if (updatedRow) {
      return {
        ...updatedObject,
        positiveMarkup: updatedRow.positive,
        negativeMarkup: updatedRow.negative,
        positiveRebate: updatedRow.rebatePositive,
        negativeRebate: updatedRow.rebateNegative,
      };
    }
  }
  return updatedObject;
};

export const getUpdateBenchmarkPayload = (
  data: TUpdatedFields<IInterestRate>,
) => ({
  currency: data.currency.value,
  days: data.days.value,
  positive: data.positiveBenchmark.value,
  negative: data.negativeBenchmark.value,
});

export const getUpdateMarkupPayload = (
  data: TUpdatedFields<IInterestRate>,
) => ({
  currency: data.currency.value,
  active: true,
  negative: data.negativeMarkup.value,
  positive: data.positiveMarkup.value,
  rebateNegative: data.negativeRebate.value,
  rebatePositive: data.positiveRebate.value,
});

export const getUpdateGroupRatePayload = (
  data: TUpdatedFields<IInterestRate>,
) => {
  return {
    active: true,
    currency: data.currency.value,
    negative: data.negativeMarkup.value,
    positive: data.positiveMarkup.value,
    rebateNegative: data.negativeRebate.value,
    rebatePositive: data.positiveRebate.value,
  };
};
