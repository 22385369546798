import cn from 'classnames';
import { memo } from 'react';

import { ParthenonIcon } from '~/images/icons';

import NameCellStyles from './NameCell.module.css';

interface INameCellProps {
  name: string;
  icon?: string | null;
}

const white = /\b\w*marker-white\w*\b/;

const ICON_WIDTH = 14;
const ICON_HEIGHT = 14;

export const NameCell = memo(({ name = '', icon = '' }: INameCellProps) => {
  return (
    <div className={NameCellStyles.Container}>
      <div
        className={cn(NameCellStyles.Icon, {
          [NameCellStyles.CircleIcon]: icon?.match(white),
        })}
      >
        {icon ? (
          <img
            src={icon}
            width={ICON_WIDTH}
            height={ICON_HEIGHT}
            alt={`${name}-icon`}
          />
        ) : (
          <ParthenonIcon width={ICON_WIDTH} height={ICON_HEIGHT} />
        )}
      </div>
      <div className={NameCellStyles.Text}>{name}</div>
    </div>
  );
});

NameCell.displayName = 'RiskArrayNameCell';
