import { FormInputContainer, FormSelectContainer } from '~/containers/form';
import { TTokenType } from '~/types/users';

import { TOKEN_TYPES_OPTIONS } from '../constants';
import { TPasswordFormDefaultValues } from '../types';

import { StyledTokenForm } from './TokenForm.styled';

interface ITokenFormComponentProps {
  tokenType: TTokenType;
  passwordFormDefaultValues: TPasswordFormDefaultValues;
}

export const TokenFormComponent = ({
  passwordFormDefaultValues,
  tokenType,
}: ITokenFormComponentProps) => {
  const passwordTokenType = TOKEN_TYPES_OPTIONS.get('password')?.value;
  const totpTokenType = TOKEN_TYPES_OPTIONS.get('totp')?.value;

  return (
    <StyledTokenForm>
      <FormInputContainer
        label="Name"
        name="name"
        readonly={!!passwordFormDefaultValues.name}
      />

      <FormSelectContainer
        label="Token type"
        name="type"
        options={Array.from(TOKEN_TYPES_OPTIONS.values())}
        readOnly={!!passwordFormDefaultValues.name}
      />

      {tokenType === passwordTokenType && (
        <>
          <FormInputContainer label="Password" name="value" type="password" />

          <FormInputContainer
            label="Re-type password"
            name="revalue"
            type="password"
          />
        </>
      )}

      {tokenType === totpTokenType && (
        <FormInputContainer label="Value" name="value" />
      )}
    </StyledTokenForm>
  );
};
