import { styled } from 'react-ui-kit-exante';

export const StyledInput = styled('input')(() => ({
  appearance: 'textfield',
  margin: 0,

  '::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },
  '::-webkit-outer-spin-button': {
    appearance: 'none',
    margin: 0,
  },
}));

export const StyledButton = styled('button')(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: '100%',
  lineHeight: 'inherit',
  border: 0,
  padding: 0,
  background: 'transparent',
  color: theme?.color.typo.primary,
}));
