import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

export const commissionsApi = createApi({
  reducerPath: 'commissionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'CommissionGroups',
    'CommissionsInstruments',
    'CommissionsSearchInstruments',
    'CommissionsTree',
  ],
  endpoints: () => ({}),
});
