import { keys } from 'lodash';

import { ITreeNode } from '../types';

export const getIsValidUpdate = (fields?: ITreeNode) => {
  if (!fields) {
    return { isValid: true };
  }
  const fieldNames = keys(fields);
  let isAutoCalculated = false;
  fieldNames.forEach((key) => {
    if (key === 'isAutoCalculated') {
      isAutoCalculated = Boolean(fields[key]);
    }
  });

  let isValid = true;

  if (isAutoCalculated) {
    fieldNames.forEach((key) => {
      if (key.startsWith('risk-array')) {
        isValid = false;
      }
    });
  }
  if (!isValid) {
    return {
      isValid,
      errorMsg: 'Riskarray present when isAutoCalculated is set to true',
    };
  }
  return { isValid };
};
