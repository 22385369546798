import { IMirroringRule } from '~/types/mirroringRules';

import { transformToArray } from './helper';

interface IMirroringRulesFilterProps {
  data: IMirroringRule[];
  accountTypes: string | string[] | unknown;
  accountPurposes: string | string[] | unknown;
}

export const mirroringRulesFilter = ({
  data,
  accountTypes,
  accountPurposes,
}: IMirroringRulesFilterProps): IMirroringRule[] => {
  if (!accountTypes && !accountPurposes) {
    return data;
  }

  const accountTypesFilter = transformToArray(accountTypes);
  const accountPurposesFilter = transformToArray(accountPurposes);

  return data.filter((rule) => {
    let hasFound =
      rule.accountTypes?.some((type) => accountTypesFilter.includes(type)) ||
      false;

    if (!hasFound) {
      hasFound =
        rule.accountPurposes?.some((type) =>
          accountPurposesFilter.includes(type),
        ) || false;
    }

    return hasFound;
  });
};
