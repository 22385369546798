import { isEqual } from 'lodash';

import { IOption } from '~/types/form';

const optionKeys: Array<keyof IOption> = ['label', 'value'];
const optionKeysPlaceChanged: Array<keyof IOption> = ['value', 'label'];

function isOptionValue(item: unknown): item is IOption {
  return (
    item instanceof Object &&
    (isEqual(optionKeys, Object.keys(item)) ||
      isEqual(optionKeysPlaceChanged, Object.keys(item)))
  );
}

// value can be any type doesn't matter
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueFromOption(item: any) {
  return isOptionValue(item) ? item.value : item;
}
