import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import {
  limitsApi,
  useSaveLimitsMutation,
  useUpdateLimitSetMutation,
} from '~/api';
import {
  INITIAL_GLOBAL_CASH,
  resetChangedLimits,
  selectHasChangedGlobalCash,
  selectHasChangedLimits,
  setDataForResetGlobalCashForm,
  updateGlobalCashFormState,
} from '~/store/limits';

export const useSave = () => {
  const dispatch = useDispatch();
  const hasChangedLimits = useSelector(selectHasChangedLimits);
  const hasChangedGlobalCash = useSelector(selectHasChangedGlobalCash);

  const [saveLimits] = useSaveLimitsMutation();
  const [updateLimitSet] = useUpdateLimitSetMutation();

  return useCallback(async () => {
    if (hasChangedLimits) {
      const result = await saveLimits(null);

      if ('data' in result && result.data === true) {
        Notification.success({
          title: 'Limits updated successfully',
        });

        dispatch(resetChangedLimits());
      }
    }

    if (hasChangedGlobalCash) {
      const result = await updateLimitSet(null);

      if ('data' in result) {
        Notification.success({
          title: 'Global cash updated successfully',
        });

        const { dataForResetFormAfterSuccessSubmit, ...initialFormData } =
          structuredClone(INITIAL_GLOBAL_CASH);

        dispatch(limitsApi.util.invalidateTags(['LimitSets']));
        dispatch(updateGlobalCashFormState(initialFormData));
        dispatch(setDataForResetGlobalCashForm(result.data));
      }
    }
  }, [
    dispatch,
    hasChangedGlobalCash,
    hasChangedLimits,
    saveLimits,
    updateLimitSet,
  ]);
};
