import { clone, isEqual } from 'lodash';

import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function permissionsForInstrumentSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.PERMISSIONS_FOR_INSTRUMENT_SET],
) {
  const [newPermissions, initialPermissions] = payload;
  const initial = clone(state.permissions.initial);
  const changed = clone(state.permissions.changed);

  if (!initial[initialPermissions.id]) {
    initial[initialPermissions.id] = initialPermissions;
  }

  if (!isEqual(newPermissions, initial[newPermissions.id])) {
    changed[newPermissions.id] = newPermissions;
  } else {
    delete changed[newPermissions.id];
  }

  return {
    ...state,
    permissions: {
      changed,
      initial,
    },
  };
}
