import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Panel } from 'react-ui-kit-exante';

import { useGetInterCashCardStatusTypesQuery } from '~/api/types/types.api';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { selectCurrenciesOptionsByWeight } from '~/store/currencies';

import { CardFormActions } from '../CardFormActions';
import { CardFormTemplate } from '../CardFormTemplate';
import { LIMITS_GROUP } from '../constants';

interface ICardFormProps {
  // TODO fix type
  // Can't type with react-hook-form types.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: (Record<string, any> & { _id: string })[];
  selectedCardIndex: string;
  onClose(): void;
  onDelete(): void;
}

export const CardFormContainer = ({
  fields,
  selectedCardIndex,
  onClose,
  onDelete,
}: ICardFormProps) => {
  const numericSelectedCardIndex = Number(selectedCardIndex);
  // private form field
  const uniqFormKey =
    // eslint-disable-next-line no-underscore-dangle
    fields[numericSelectedCardIndex]?._id || numericSelectedCardIndex;

  const currenciesOptions = useSelector(selectCurrenciesOptionsByWeight);
  const { data: interCashCardStatusTypes } =
    useGetInterCashCardStatusTypesQuery();
  const interCashCardStatusesOptions = transformVariantsToSelectOptions(
    interCashCardStatusTypes?.values,
  );
  const limitsGroupOptions = useMemo(
    () => transformVariantsToSelectOptions(LIMITS_GROUP, { capitalized: true }),
    [],
  );

  if (selectedCardIndex === '') {
    return null;
  }

  return (
    <Panel
      key={uniqFormKey}
      title="Card info"
      action={<CardFormActions onDelete={onDelete} onClose={onClose} />}
    >
      <CardFormTemplate
        selectedCardIndex={selectedCardIndex}
        currenciesOptions={currenciesOptions}
        interCashCardStatusesOptions={interCashCardStatusesOptions}
        limitsGroupOptions={limitsGroupOptions}
      />
    </Panel>
  );
};
