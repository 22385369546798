import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton, Notification } from 'react-ui-kit-exante';

import { useGetCoreAccountQuery, useLazyGetAccountsQuery } from '~/api';
import { SummaryWithAccountInfo } from '~/containers/DetailedAccountInfoContainer';
import {
  useEntitiesNavigation,
  useNavigationParams,
  usePickUserPermissions,
} from '~/hooks';
import { TabsContextProvider } from '~/hooks/useTabs/useTabs';
import {
  ACCOUNTS_PATH,
  getAccountPageRoute,
  TRADE_ADD_PATH,
  TRANSACTION_ADD_PATH,
  TRANSFER_ADD_PATH,
} from '~/routes';
import {
  CloseButton,
  NavigationArrows,
  StaticCenteredLoader,
} from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { paramsSerializer } from '~/shared/utils/apiRequest/helpers';
import { TAccountWithIdOnly } from '~/types/accounts';
import { ILocationState, TParams } from '~/types/router';

import { AccountPageTemplate } from './AccountPageTemplate';
import { TabManagement } from './TabManagement';
import { useAccountTabs } from './TabManagement/hooks';
import { AccountPageContextManager } from './context';
import { useAccountSummary, useRefreshActiveTab } from './hooks';

export const AccountPageContainer = () => {
  const { id: paramsId } = useParams<TParams>();
  const accountId = paramsId!;
  const [fetchAccountIds] = useLazyGetAccountsQuery();

  const location = useLocation();
  const navigate = useNavigate();
  const { requestParams, cursor } = (location.state as ILocationState) || {};
  const { refreshActiveTab, isRefreshing, setRefreshFn } =
    useRefreshActiveTab();
  const { tabs } = useAccountTabs();

  const userPermissions = usePickUserPermissions([
    'Add/withdraw asset',
    'Inject a manual trade',
    'Transfer',
  ]);

  // error needs for fix bug with refetching when we have 403 error and subcomponents try to refetch data https://jira.exante.eu/browse/WBU-934
  const { data, isLoading, error } = useGetCoreAccountQuery(
    { accountId },
    {
      refetchOnMountOrArgChange: true,
      skip: !accountId,
    },
  );

  const { refetch: refetchAccountSummary, isFetching: fetchingAccountSummary } =
    useAccountSummary(accountId, data?.archived);

  const handleRefresh = useCallback(async () => {
    try {
      await Promise.all([refetchAccountSummary(), refreshActiveTab?.()]);
    } catch (e) {
      Notification.warning({
        title: 'Not all the data has been updated',
      });
    }
  }, [refetchAccountSummary, refreshActiveTab]);

  // todo loader on navigation
  const params = useNavigationParams<TAccountWithIdOnly>({
    requestParams,
    cursor,
    fetchData: async (rest: Record<string, unknown>) => {
      const response = await fetchAccountIds({
        ...rest,
        idsOnly: true,
      });

      if ('error' in response || !response.data) {
        return [];
      }

      return response.data.accounts;
    },
    path: getAccountPageRoute,
  });
  const {
    isNextDisabled,
    isPreviousDisabled,
    handleNextClick,
    handlePreviousClick,
  } = useEntitiesNavigation(params);

  const handleCloseClick = useCallback(() => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};
    navigate(`${ACCOUNTS_PATH}?${paramsSerializer(rest)}`);
  }, [navigate, requestParams]);

  const goToAddTradePage = useCallback(() => {
    navigate(TRADE_ADD_PATH, {
      state: {
        previousPath: `/bo/accounts/${accountId}`,
        accountId: accountId || '',
      },
    });
  }, [navigate, accountId]);

  const goToAddTransactionPage = useCallback(() => {
    navigate(TRANSACTION_ADD_PATH, {
      state: { previousPath: `/bo/accounts/${accountId}`, accountId },
    });
  }, [navigate, accountId]);

  const goToAddTransferPage = useCallback(() => {
    navigate(TRANSFER_ADD_PATH, {
      state: { previousPath: `/bo/accounts/${accountId}`, accountId },
    });
  }, [navigate, accountId]);

  const addTradeButton = () => (
    <IconButton
      iconName="AddIcon"
      iconColor="action"
      iconSize={24}
      label="Add trade"
      onClick={goToAddTradePage}
    />
  );

  const addTransactionButton = () => (
    <IconButton
      iconName="AddIcon"
      iconColor="action"
      label="Add transaction"
      iconSize={24}
      onClick={goToAddTransactionPage}
    />
  );

  const addTransferButton = () => (
    <IconButton
      iconName="AddIcon"
      iconColor="action"
      label="Add transfer"
      iconSize={24}
      onClick={goToAddTransferPage}
    />
  );

  if (isLoading && !error) {
    return <StaticCenteredLoader withBackground height="80vh" />;
  }

  return (
    <AccountPageTemplate
      header={
        <EntryScreenActions
          addTradeButton={
            userPermissions['Inject a manual trade'].write
              ? addTradeButton()
              : null
          }
          addTransactionButton={
            userPermissions['Add/withdraw asset'].write
              ? addTransactionButton()
              : null
          }
          addTransferButton={
            userPermissions.Transfer.write ? addTransferButton() : null
          }
          closeButton={<CloseButton onClick={handleCloseClick} />}
          isRefreshLoading={isRefreshing || fetchingAccountSummary}
          navigationArrows={
            <NavigationArrows
              previousDisabled={isPreviousDisabled}
              nextDisabled={isNextDisabled}
              onPreviousClick={handlePreviousClick}
              onNextClick={handleNextClick}
            />
          }
          onRefresh={handleRefresh}
          title={accountId}
        />
      }
      accountInfo={
        <SummaryWithAccountInfo
          accountId={accountId}
          archived={data?.archived}
        />
      }
      tabManagement={
        <TabManagement
          tabs={tabs}
          accountId={accountId}
          setRefetch={setRefreshFn}
        />
      }
    />
  );
};

export const AccountPage = () => (
  <TabsContextProvider>
    <AccountPageContextManager>
      <AccountPageContainer />
    </AccountPageContextManager>
  </TabsContextProvider>
);
