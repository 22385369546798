import * as yup from 'yup';

import { createArraysValidation } from './helpers';

export const validationSchema = yup.object({
  users: yup.mixed().test({
    name: 'account-users-validation',
    test: createArraysValidation('userId'),
  }),
});
