import { API_ENDPOINTS_EXCEPTIONS } from './constants';

const apiEndpoints = {
  account: '/account_types',
  accountOwnership: '/account_ownership',
  accountPurposes: '/account_purposes',
  accountStatus: '/account_status',
  asset: '/asset',
  categories: '/category',
  client: '/client',
  counter_parties: '/counterparty-accounts',
  exante: '/exante',
  executionCounterparty: '/additional_execution_counterparty',
  feePolicy: '/fee_policy',
  incorporation: '/incorporation',
  interCashCardStatus: '/intercash_card_status',
  legalEntity: '/legal_entity',
  logic: '/logic_type',
  operation: '/operation',
  settlementCounterparty: '/additional_settlement_counterparty',
  superUser: '/superuser',
  trade: '/trade',
  userClientPermissions: '/user_client_permissions',
};

export const getTypeEndpoint = (key: keyof typeof apiEndpoints) => {
  if (API_ENDPOINTS_EXCEPTIONS.includes(key)) {
    return `/api${apiEndpoints[key]}`;
  }

  return `/api/types${apiEndpoints[key]}`;
};
