import { EMPTY_COMMISSIONS_INSTRUMENTS } from '../constants';
import { getFetchCommissionsInstrumentsEndpoint } from '../endpoints';
import { IGetInstrumentsParams, IGetInstrumentsReturn } from '../types';

import { commissionsApi } from './commissionsApi';

const commissionsApiInjected = commissionsApi.injectEndpoints({
  endpoints: (builder) => ({
    getCommissionsInstruments: builder.query<
      IGetInstrumentsReturn,
      IGetInstrumentsParams
    >({
      // We need custom error type here
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (
        params,
        { dispatch, ...rest },
        __,
        fetchWithBaseQuery,
      ) => {
        const { path, limit, skip, queryParams } = params;

        if (!queryParams) {
          return EMPTY_COMMISSIONS_INSTRUMENTS;
        }

        const { layer, entity } = queryParams;
        const newParams = { path, limit, skip };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchCommissionsInstrumentsEndpoint(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            error: {
              message: error,
            },
          };
        }

        if (data.pagination.total > skip + limit) {
          dispatch(
            commissionsApiInjected.endpoints.getCommissionsInstruments.initiate(
              {
                path,
                limit,
                skip: skip + limit,
                queryParams,
              },
            ),
          );
        }

        return {
          data: {
            ...data,
            path,
          },
        };
      },
      providesTags: ['CommissionsInstruments'],
    }),
  }),
});

export const {
  useLazyGetCommissionsInstrumentsQuery,
  endpoints: {
    getCommissionsInstruments: {
      matchFulfilled: getCommissionsInstrumentsMatchFulfilled,
    },
  },
} = commissionsApiInjected;
