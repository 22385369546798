import * as Yup from 'yup';
import { OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';

import { YUP_SELECTED_VALUE } from '~/constants';

interface IValidationFreeSoloAutocompleteAsyncOptions {
  isRequired: boolean;
  isNullable: boolean;
}

// extension of the infered type by null
type YupString = Yup.StringSchema<
  string | null | undefined,
  AnyObject,
  string | null | undefined
>;
type YupObject = OptionalObjectSchema<
  {
    label: YupString;
    value: YupString;
  },
  AnyObject,
  TypeOfShape<{ label: YupString; value: YupString }> | null
>;

export function validationFreeSoloAutocompleteAsync(
  message: string,
  options?: Partial<IValidationFreeSoloAutocompleteAsyncOptions>,
) {
  const { isNullable, isRequired } = options || {};

  let objectSchema: YupObject =
    Yup.object(YUP_SELECTED_VALUE).typeError(message);
  let stringSchema: YupString = Yup.string();

  if (isRequired) {
    stringSchema = stringSchema.required(message);
  }

  if (isNullable) {
    objectSchema = objectSchema.nullable();
    stringSchema = stringSchema.nullable();
  }

  return Yup.lazy((value) =>
    typeof value === 'object' ? objectSchema : stringSchema,
  );
}
