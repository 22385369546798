export function migrateWbu1163() {
  try {
    const key = 'web-bo-global-summary-currency-risk-view-params';

    const ls = JSON.parse(localStorage.getItem(key) || '{}');

    if (ls?.sorting?.[0]?.id && ls?.sorting?.[0]?.id !== 'currency') {
      localStorage.removeItem(key);
    }
  } catch {
    console.error('Failed to migrate wbu1163');
  }
}
