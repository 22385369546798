import { useEffect } from 'react';

import { useLogHandleTime } from '~/hooks';
import { IUserTabComponentProps } from '~/pages/UserPage/TabManagement/types';
import { useUserPageContextManager } from '~/pages/UserPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { PermissionsFormContainer } from './PermissionsFormContainer';
import { useAccountPermissionsData } from './useAccountPermissionsData';

export const AccountPermissionsContainer = ({
  userName,
}: IUserTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'user-account-permissions-entry-screen',
  );
  const { setRefreshFn } = useUserPageContextManager();
  setStartHandleTime();

  const {
    queries: { accountPermissionsQuery },
    isDataLoading,
  } = useAccountPermissionsData(userName);

  useEffect(() => {
    if (accountPermissionsQuery.data && !isDataLoading) {
      logHandleTime();
    }
  }, [accountPermissionsQuery, isDataLoading, logHandleTime]);

  useEffect(() => {
    setRefreshFn('accountPermissions', {
      refetch: async () => accountPermissionsQuery.refetch(),
      isLoading: accountPermissionsQuery.isFetching,
    });
  }, [accountPermissionsQuery]);

  if (isDataLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  if (!accountPermissionsQuery.data) {
    return <div>No data</div>;
  }

  return (
    <PermissionsFormContainer
      userName={userName}
      accountPermissions={accountPermissionsQuery.data}
    />
  );
};
