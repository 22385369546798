import { createApi } from '@reduxjs/toolkit/query/react';
import { omit } from 'lodash';

import { SESSION_ID_RUN_UI_WORKFLOW_KEY } from '~/constants';
import {
  baseQueryHandler,
  getAccessTokenFromCookies,
  getSessionIdFromCookie,
  paramsTransformer,
} from '~/shared/utils';
import { getDefaultHeaders } from '~/shared/utils/apiRequest/helpers';

import {
  defaultTradesResponse,
  REQUESTED_FIELDS,
  TRADES_PARAMS_MAPPER,
} from './trades.api.constants';
import {
  FETCH_TRADES,
  getFetchCommissionEndpoint,
  getPostTradeEndpoint,
  getRollbackTradeURL,
  getTradeColumnsEndpoint,
  getUpdateTradeEndpoint,
} from './trades.api.endpoints';
import { getDataForPostManualTrade } from './trades.api.helpers';
import { tradesMappers } from './trades.api.mappers';
import {
  FetchTradeColumnsResponse,
  FetchTradeCommissionParams,
  FetchTradeCommissionResponse,
  FetchTradesResponse,
  PostTradesParams,
  PostTradesResponse,
  TradeCommissionResponse,
} from './trades.api.types';

const tagTypes = ['Trades', 'Trade', 'TradesCommission'] as const;

export const tradesApi = createApi({
  reducerPath: 'tradesApi',
  baseQuery: baseQueryHandler,
  tagTypes,
  endpoints: function tradesApiEndpoints(builder) {
    return {
      getTradeColumns: builder.query<FetchTradeColumnsResponse, void>({
        query: () => ({
          url: getTradeColumnsEndpoint(),
        }),
      }),
      getTrades: builder.query<FetchTradesResponse, Record<string, unknown>>({
        queryFn: async function getTradesHandler(
          params,
          _,
          __,
          fetchWithBaseQuery,
        ) {
          const preparedParams = paramsTransformer({
            params,
            mapper: TRADES_PARAMS_MAPPER,
          });

          const { data, error } = await fetchWithBaseQuery({
            url: FETCH_TRADES,
            params: { ...preparedParams, fields: REQUESTED_FIELDS },
          });

          if (error || !data) {
            return {
              data: defaultTradesResponse,
            };
          }

          return {
            data: {
              trades: tradesMappers(data.data),
              pagination: data.pagination,
            },
          };
        },
        providesTags: ['Trades'],
      }),
      getTradeCommission: builder.query<
        FetchTradeCommissionResponse,
        FetchTradeCommissionParams
      >({
        queryFn: async function fetchCommissionHandler(
          { params, options },
          _,
          __,
          fetchWithBaseQuery,
        ) {
          const url = getFetchCommissionEndpoint(params.accountId);
          const { data: response, error } = (await fetchWithBaseQuery({
            url,
            requestOptions: options,
            params: { ...omit(params, 'accountId') },
          })) as { data: TradeCommissionResponse; error: unknown };

          if (error) {
            return {
              error: String(error),
            };
          }
          return {
            data: `${response.commission} ${response.commissionCurrency}`,
          };
        },
        providesTags: ['TradesCommission'],
      }),
      postTrades: builder.mutation<PostTradesResponse, PostTradesParams>({
        queryFn: async function fetchPostTradesHandler(
          { params, onSuccess, onError },
          _api,
          _extraOptions,
          fetchWithBaseQuery,
        ) {
          const { accountId, use4EyesCheck: shouldSendThroughWorkflowApi } =
            params;
          const postTradeEndpoint = getPostTradeEndpoint(
            accountId,
            shouldSendThroughWorkflowApi,
          );

          const response = await fetchWithBaseQuery({
            url: postTradeEndpoint,
            method: 'POST',
            data: getDataForPostManualTrade(params),
            ...(window.runUIhistoryContainer && shouldSendThroughWorkflowApi
              ? {
                  headers: getDefaultHeaders(
                    getSessionIdFromCookie(SESSION_ID_RUN_UI_WORKFLOW_KEY),
                    getAccessTokenFromCookies(),
                  ),
                }
              : {}),
          });

          const responseData = response.data;

          if (response.error) {
            onError?.(response.error);

            return {
              error: response.error,
            };
          }

          onSuccess?.();

          return {
            data: responseData,
          };
        },
        invalidatesTags: function invalidateTagsAfterPostTrades(res, error) {
          if (error || !res) {
            return [];
          }
          const { orderId } = res;
          return ['Trades', { type: 'Trade', id: orderId }];
        },
      }),
      updateTrade: builder.mutation({
        query: ({ orderId, orderPos, ...data }) => ({
          url: getUpdateTradeEndpoint(orderId, orderPos),
          method: 'POST',
          data,
        }),
      }),

      rollbackTrade: builder.mutation<
        unknown,
        { orderId: string; orderPosition: number }
      >({
        query: ({ orderId, orderPosition }) => ({
          url: getRollbackTradeURL(orderId, orderPosition),
          method: 'POST',
        }),
      }),
    };
  },
});

export const {
  useGetTradeColumnsQuery,
  useLazyGetTradeCommissionQuery,
  useLazyGetTradesQuery,
  usePostTradesMutation,
  useUpdateTradeMutation,
  useRollbackTradeMutation,
} = tradesApi;
