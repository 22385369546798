import { useCallback, useContext, useState } from 'react';

import { IUserToken } from '~/types/users';

import { ActionTypes, PasswordManagementContext } from '../../constants';

import { TokenDeleteIconComponent } from './TokenDeleteIconComponent';

interface ITokenDeleteIconContainerProps {
  activeToken: boolean;
  token: IUserToken;
}

export const TokenDeleteIconContainer = ({
  activeToken,
  token,
}: ITokenDeleteIconContainerProps) => {
  const [, dispatch] = useContext(PasswordManagementContext);
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  const onCloseModal = useCallback(() => setIsOpenedModal(false), []);

  const handleConfirmDelete = useCallback(() => {
    dispatch({ type: ActionTypes.DELETE_TOKEN, payload: token });
  }, [dispatch, token]);

  const handleOpenModal = useCallback(() => setIsOpenedModal(true), []);

  return (
    <TokenDeleteIconComponent
      activeToken={activeToken}
      handleConfirmDelete={handleConfirmDelete}
      handleOpenModal={handleOpenModal}
      isOpenedModal={isOpenedModal}
      onCloseModal={onCloseModal}
      tokenName={token.name}
    />
  );
};
