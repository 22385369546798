/**
 * Calculates the commission and returns a string concatenating the commission value and type, or an empty string if neither fixed nor percent commissions are provided.
 *
 * @param {Object} params - The parameters object.
 * @param {string} [params.fixed] - The fixed commission amount as a string.
 * @param {string} [params.percent] - The percentage commission as a string.
 * @param {string} [params.type] - The commission type, either 'per action' or 'regular'.
 * @param {string} [params.intervalType] - The interval type for regular commissions, either 'monthly', 'weekly', or 'daily'.
 * @returns {string} - The formatted commission string or an empty string.
 *
 * @example
 * // Returns "100 / transaction"
 * getCommissionValue({ fixed: "100", type: "per action" });
 *
 * @example
 * // Returns "10% / monthly"
 * getCommissionValue({ percent: "10", type: "regular", intervalType: "monthly" });
 *
 * @example
 * // Returns "5%"
 * getCommissionValue({ percent: "5" });
 *
 * @example
 * // Returns an empty string
 * getCommissionValue({});
 */
interface IGetCommissionValue {
  fixed: string;
  percent: string;
  type: 'per action' | 'regular';
  intervalType: 'monthly' | 'weekly' | 'daily';
}
export function getCommissionValue({
  fixed,
  percent,
  type,
  intervalType,
}: Partial<IGetCommissionValue>): string {
  // Return an empty string if neither fixed nor percent is provided
  if (!fixed && !percent) {
    return '';
  }

  let commission = '';
  let commissionType = '';

  // Define commission
  if (fixed) {
    commission = fixed;
  } else if (percent) {
    commission = `${percent}%`;
  }

  // Define commission type
  if (type === 'per action') {
    commissionType = 'transaction';
  } else if (type === 'regular' && intervalType) {
    commissionType = intervalType;
  }

  // Return result
  if (commissionType) {
    return `${commission} / ${commissionType}`;
  }

  return commission;
}
