import { capitalize } from 'lodash';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Tabs as TabsUIKit, Tab } from 'react-ui-kit-exante';

import { setNewLayer } from '~/store/treeStructure';
import { FilterLayers, TabAccount, TabGroup } from '~/types/treeStructure';

import { AccountsFilter } from '../AccountsFilter';
import { GroupsFilter } from '../GroupsFilter';

import { WrapperStyled } from './Tabs.styled';

interface ITreeStructureProps {
  onFilterChange: () => void;
  tabAccount: TabAccount;
  tabGroup: TabGroup;
}

export const Tabs = ({
  onFilterChange,
  tabAccount,
  tabGroup,
}: ITreeStructureProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const paramsLayer = searchParams.get('layer') as FilterLayers;

  const tabs: string[] = useMemo(
    () => [
      'default',
      ...(tabGroup ? ['groups'] : []),
      ...(tabAccount ? ['accounts'] : []),
    ],
    [tabGroup, tabAccount],
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const tabHandleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      if (newValue === 0) {
        navigate('');

        onFilterChange();
      }

      dispatch(setNewLayer({ layer: tabs[newValue] }));
      setSelectedTab(newValue);
    },
    [onFilterChange, dispatch, navigate, tabs],
  );

  useEffect(() => {
    if (Object.values(FilterLayers).includes(paramsLayer)) {
      dispatch(
        setNewLayer({
          layer: paramsLayer,
        }),
      );
      setSelectedTab(tabs.findIndex((item) => item === paramsLayer));
    }
  }, []);

  return (
    <WrapperStyled>
      <TabsUIKit value={selectedTab} onChange={tabHandleChange}>
        {tabs.map((tab) => (
          <Tab label={capitalize(tab)} key={tab} />
        ))}
      </TabsUIKit>

      {tabs[selectedTab] === 'groups' && (
        <GroupsFilter tabGroup={tabGroup} onFilterChange={onFilterChange} />
      )}

      {tabs[selectedTab] === 'accounts' && (
        <AccountsFilter
          tabAccount={tabAccount}
          onFilterChange={onFilterChange}
        />
      )}
    </WrapperStyled>
  );
};
