import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  IParams,
  IUseEntitiesNavigationProps,
  TCustomOnClick,
} from '../useEntitiesNavigation';

import { getPath } from './helpers';
import { IObjectWithId, TNavigationEntityPath } from './types';

interface IUseNavigationParamsArguments<DataItem> {
  requestParams?: IParams;
  cursor?: number;
  fetchData?: IUseEntitiesNavigationProps<DataItem>['fetchData'];
  path: TNavigationEntityPath;
}

export function useNavigationParams<DataItem extends IObjectWithId>({
  requestParams,
  cursor,
  fetchData,
  path,
}: IUseNavigationParamsArguments<DataItem>) {
  const navigate = useNavigate();

  const handleNavigateButtonsClick = useCallback<TCustomOnClick<DataItem>>(
    ({ item, params, cursor: newCursor }) => {
      if (item?.id === null || item?.id === undefined) {
        // for unexpected behaviour
        console.error(
          'Id not found to change page. Path: useNavigationParams/useNavigationParams.ts',
        );

        return;
      }

      const preparedPath = getPath(path, item.id);

      navigate(preparedPath, {
        state: { requestParams: params, cursor: newCursor },
      });
    },
    [navigate, path],
  );

  const params: IUseEntitiesNavigationProps<DataItem> = useMemo(
    () => ({
      params: requestParams || {},
      cursor: cursor || 0,
      fetchData: requestParams ? fetchData : undefined,
      onNext: handleNavigateButtonsClick,
      onPrevious: handleNavigateButtonsClick,
    }),
    [cursor, fetchData, handleNavigateButtonsClick, requestParams],
  );

  return params;
}
