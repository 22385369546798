import { apiRequest } from '~/shared/utils';

import { USER_ACCOUNTS } from './endpoints';
import {
  INewUserPermission,
  IUpdateUserPermissionData,
  TUsersPermissionResponse,
} from './types';

export class UsersPermissionRepository {
  public static fetchUsersPermission(accountId: string) {
    const url = USER_ACCOUNTS;

    return apiRequest<TUsersPermissionResponse[]>({
      url,
      params: { accountId },
    });
  }

  public static updateUserPermission(data: IUpdateUserPermissionData) {
    const url = `${USER_ACCOUNTS}/${data.id}`;

    return apiRequest<TUsersPermissionResponse[]>({
      method: 'POST',
      url,
      data,
    });
  }

  public static addUserPermission(data: Omit<INewUserPermission, 'isNew'>[]) {
    const url = USER_ACCOUNTS;

    return apiRequest<TUsersPermissionResponse[]>({
      method: 'POST',
      url,
      data,
    });
  }

  public static removeUserPermission(userAccountId: string) {
    const url = `${USER_ACCOUNTS}/${userAccountId}`;

    return apiRequest<string>({ method: 'DELETE', url });
  }
}
