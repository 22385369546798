import { ActionTypes } from '../constants';
import { getQueryParams } from '../helpers';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function filtersUserSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.FILTERS_USER_SET],
) {
  const select = {
    ...state.filters.select,
    user: payload,
    group: null,
  };
  const relatedGroup = null;
  const queryParams = getQueryParams({ ...select, relatedGroup });

  return {
    ...state,
    filters: {
      ...state.filters,
      relatedGroup,
      select,
      queryParams,
    },
  };
}
