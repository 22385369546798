import { useContext } from 'react';

import { PasswordManagementContext } from '../constants';

import {
  StyledTitle,
  StyledSection,
  StyledTokenList,
} from './TokenList.styled';
import { TokenListSection } from './TokenListSection';

interface ITokenListComponentProps {
  handleEditClick: (tokenName: string) => void;
}

export const TokenListComponent = ({
  handleEditClick,
}: ITokenListComponentProps) => {
  const [state] = useContext(PasswordManagementContext);

  const {
    data: { password, sms, email, totp },
  } = state;

  return (
    <StyledTokenList>
      <StyledSection>
        <StyledTitle>Password</StyledTitle>

        {!password.length ? (
          <div>No passwords</div>
        ) : (
          <TokenListSection
            handleEditClick={handleEditClick}
            tokenType="password"
          />
        )}
      </StyledSection>

      <StyledSection>
        <StyledTitle>E-mail</StyledTitle>

        {!email.length ? (
          <div>No e-mails</div>
        ) : (
          <TokenListSection
            handleEditClick={handleEditClick}
            tokenType="email"
          />
        )}
      </StyledSection>

      <StyledSection>
        <StyledTitle>SMS</StyledTitle>

        {!sms.length ? (
          <div>No SMS</div>
        ) : (
          <TokenListSection handleEditClick={handleEditClick} tokenType="sms" />
        )}
      </StyledSection>

      <StyledSection>
        <StyledTitle>One Time Password</StyledTitle>

        {!totp.length ? (
          <div>No one time passwords</div>
        ) : (
          <TokenListSection
            handleEditClick={handleEditClick}
            tokenType="totp"
          />
        )}
      </StyledSection>
    </StyledTokenList>
  );
};
