import { useGetMarginSettingsQuery, useGetRiskSettingsQuery } from '~/api';

interface IUseGetRequestsStatusReturn {
  isLoading: Record<'marginSettings' | 'riskSettings', boolean>;
  isRejected: Record<'marginSettings' | 'riskSettings', boolean>;
}

export function useGetRequestsStatus(
  accountId: string,
): IUseGetRequestsStatusReturn {
  const {
    data: marginSettings,
    error: marginSettingsError,
    isLoading: marginSettingsLoading,
    isFetching: marginSettingsFetching,
  } = useGetMarginSettingsQuery({
    accountId,
  });
  const {
    data: riskSettings,
    error: riskSettingsError,
    isLoading: riskSettingsLoading,
    isFetching: riskSettingsFetching,
  } = useGetRiskSettingsQuery({ accountId });

  return {
    isLoading: {
      marginSettings: marginSettingsLoading || marginSettingsFetching,
      riskSettings: riskSettingsLoading || riskSettingsFetching,
    },
    isRejected: {
      marginSettings: Boolean(marginSettingsError || !marginSettings),
      riskSettings: Boolean(riskSettingsError || !riskSettings),
    },
  };
}
