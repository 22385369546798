import type { ISymbolPermissions } from '~/pages/SymbolPermissionsPage/types';

import type { RowType } from './common';

export interface ISymbolsListItem {
  id: string;
  path: string;
  typeExchangeTicker: null | string;
  isNode: boolean;
  name: string;
  icon: null;
  isExpired: boolean;
  typeIndex: null;
  CUSIP: null | string;
  ISIN: null | string;
  SEDOL: null | string;
  displayName: string;
  isTradable: boolean;
  searchWeight: number;
  nearMaturityDateYear: number | null;
  nearMaturityDateMonth: number | null;
  farMaturityDateYear: number | null;
  farMaturityDateMonth: number | null;
  optionRight: null;
  underlying: string;
  initialMargin: number | null;
  priceUnit: number;
  contractMultiplier: number;
  currency: string;
  isCompoundSymbol: boolean | null;
}
export interface ISymbol {
  id: string;
  displayName: string;
  path: string;
  isExpired: boolean;
  permissions: ISymbolPermissions;
  data: {
    shortName: string;
    ticker: string;
    _symbolId: string;
    exchangeId: {
      name: string;
    };
  };
}

export type TSymbols = ISymbol[];

export interface ISymbolFlatStructure {
  id: string;
  path: string;
  displayName: string;
  icon: string | null;
  permissions: ISymbolPermissions;
}

export interface ISymbolTreeStructure {
  icon: string | null;
  id: string;
  displayName: string;
  path: string;
  subRows?: ISymbolTreeStructure[];
  rowType: RowType;
  isExpired?: boolean;
}

export interface ISymbolTreeResponse {
  tree: ISymbolTreeStructure[];
  matchIdAndPositionInNonSearchTable: Record<string, string | number>;
  newPermissionsByPath: Record<string, ISymbolPermissions>;
}

export interface ISymbolPermissionsPost {
  allowShort: boolean;
  canTrade: boolean;
  canView: boolean;
  override: boolean;
  path: string;
  public: boolean;
  symbolId: string;
}

export interface IRowData {
  id: string;
  public: boolean;
  view: boolean;
  trade: boolean;
  short: boolean;
  override: boolean;
}

export enum FilterLayers {
  Default = 'Default',
  Groups = 'Groups',
  Users = 'Users',
  Accounts = 'Accounts',
  Effective = 'Effective',
}
