import { FETCH_COMMISSION_GROUPS_ENDPOINT } from '../endpoints';
import { ICommissionGroupsResponse } from '../types';

import { commissionsApi } from './commissionsApi';

export const { useGetCommissionGroupsForOptionsQuery } =
  commissionsApi.injectEndpoints({
    endpoints: (builder) => ({
      getCommissionGroupsForOptions: builder.query<
        ICommissionGroupsResponse[],
        void
      >({
        query: () => ({
          url: FETCH_COMMISSION_GROUPS_ENDPOINT,
        }),
        providesTags: ['CommissionGroups'],
      }),
    }),
  });
