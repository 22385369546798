import { pick } from 'lodash';
import { useMemo } from 'react';
import { UseFormGetValues } from 'react-hook-form';

import { useGetGroupSettingsQuery } from '~/api';
import { useAccountOverrides, usePickUserPermissions } from '~/hooks';

import { TFormData } from '../types';

import { GroupSettingsComponent } from './component';
import { useOptions } from './hooks';

interface IGroupSettingsProps {
  accountId: string;
  getValues: UseFormGetValues<TFormData>;
}

export const GroupSettings = ({
  accountId,
  getValues,
}: IGroupSettingsProps) => {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: interests/overnights settings',
    'Account interest rates',
    'Account limits',
    'Account markup overnight rates',
    'Account symbol permissions',
    'Mixed commissions',
  ]);
  const { getOverrides } = useAccountOverrides(accountId);

  const hideGroupSettings = useMemo(
    () =>
      Object.values(
        pick(userPermissions, [
          'Account interest rates',
          'Account limits',
          'Account markup overnight rates',
          'Account symbol permissions',
          'Mixed commissions',
        ]),
      ).every((permission) => !permission.read),
    [userPermissions],
  );

  const { status: groupSettingsStatus } = useGetGroupSettingsQuery(
    {
      accountId,
    },
    { skip: !accountId },
  );

  const groupSettingsIsRejected = groupSettingsStatus === 'rejected';

  const options = useOptions();

  if (hideGroupSettings) {
    return null;
  }

  return (
    <GroupSettingsComponent
      accountId={accountId}
      getValues={getValues}
      groupSettingsIsRejected={groupSettingsIsRejected}
      options={options}
      overrides={getOverrides()}
      userPermissions={userPermissions}
    />
  );
};
