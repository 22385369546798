import { useEffect, useMemo } from 'react';

import { useGetAccountsQuery } from '~/api';
import { IClientTabComponentProps } from '~/pages/ClientPage/TabManagement/types';
import { StaticCenteredLoader } from '~/shared/components';

import { ActivityContainer } from '../ActivityContainer';

export const ClientActivityTabContainer = ({
  clientId,
  setRefetch,
}: IClientTabComponentProps) => {
  const clientAccountsQuery = useGetAccountsQuery(
    { clientId },
    {
      skip: !clientId,
    },
  );

  useEffect(() => {
    setRefetch('activity', {
      refetch: clientAccountsQuery.refetch,
      isLoading: clientAccountsQuery.isFetching,
    });
  }, [clientAccountsQuery, setRefetch]);

  const filters = useMemo(() => {
    const accounts = clientAccountsQuery.data?.accounts;
    const accountIDs = accounts?.reduce(
      (prev, curr, index) => (index === 0 ? curr.id : `${prev}|${curr.id}`),
      '',
    );

    return {
      accountId: accountIDs,
      clientId,
    };
  }, [clientAccountsQuery, clientId]);

  if (clientAccountsQuery.isLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <ActivityContainer
      entity="client-activity-entry-screen"
      filters={filters}
      setRefetch={setRefetch}
    />
  );
};
