import { getApiDomain } from '../apiRequest';

/**
 * Creates a URL link for downloading a CSV file, using the specified API endpoint and parameters.
 *
 * @param {string} endpoint - The API endpoint path to which the parameters will be appended.
 * @param {Record<string, unknown>} params - An object containing the parameters to be added to the query.
 * Each key-value pair in the params object will be transformed into query string parameters.
 * Values that are null or undefined or empty string will be ignored.
 *
 * @returns {string} The complete URL for downloading the CSV, composed of the API host, endpoint, and the query string parameters.
 *
 * @example
 * // Example of using createLinkToDownloadCSV to generate a URL for downloading a CSV file.
 * const endpoint = '/export/csv';
 * const params = {
 *   startDate: '2021-01-01',
 *   endDate: '2021-01-31',
 *   userId: 123
 * };
 * const downloadURL = createLinkToDownloadCSV(endpoint, params);
 * console.log(downloadURL);
 * // Output: "http://api.example.com/export/csv?startDate=2021-01-01&endDate=2021-01-31&userId=123"
 */
export function createLinkToDownloadCSV(
  endpoint: string,
  params: Record<string, unknown>,
): string {
  // Retrieve the API host
  const API_HOST = `https://${getApiDomain('BO')}`;

  // Initialize URLSearchParams object to create the query string
  const searchParams = new URLSearchParams();

  // Append parameters to the query string, skipping null and undefined values
  Object.entries(params).forEach(([key, value]) => {
    if (
      (typeof value === 'string' && !value) ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && !value?.length)
    ) {
      return;
    }

    searchParams.append(key, String(value));
  });

  // Return the full URL for downloading
  return `${API_HOST + endpoint}?${searchParams.toString()}`;
}
