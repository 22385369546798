import { Table, styled } from 'react-ui-kit-exante';

import { IOvernightTree } from '~/types/overnights';

export const TableContainer = styled(Table<IOvernightTree>)(() => ({
  height: '100%',

  '& div[role="rowgroup"] > div': {
    overflowX: 'hidden !important',
  },

  '& div[role="cell"] > div': {
    width: '100%',
  },
}));
