import { ISortBy } from 'react-ui-kit-exante';

import { ARCHIVE_DATE_FIELDS, CREATED_DATE_FIELDS } from '~/constants';
import {
  prepareNumberArrayForRequest,
  prepareSortingForApi,
  createEmptyObject,
} from '~/shared/utils';
import {
  prepareDateRangeStringForAPI,
  setStartEndForDateRangeString,
} from '~/shared/utils/dates';
import {
  IAccountSummaryMetrics,
  IAccountSummary,
  IAccount,
} from '~/types/accounts';
import { TMapper } from '~/types/api';
import { TNumberRangeValues } from '~/types/filters';

export const expectedMetricsFields: (keyof IAccountSummaryMetrics)[] = [
  'totalOrderMargin',
  'totalCash',
  'totalBlockedMargin',
  'requiredMargin',
  'percentageTotalDailyPnl',
  'netAssetValue',
  'marginUtilization',
  'freeMoney',
  'convertedDailyPnl',
];

export const expectedAccountSummaryFields: (keyof IAccountSummary)[] = [
  'positions',
  'metrics',
  'closeSessionTime',
];

export const expectedAccountFields: (keyof IAccount)[] = [
  'id',
  'accountOwner',
  'created',
  'marginTradingAllowed',
  'internalComment',
  'generatedComment',
  'type',
  'subOwnerDescription',
  'status',
  'ownershipType',
  'iban',
  'comment',
  'clientId',
  'autoLiquidationAllowed',
  'archivedDate',
  'archived',
  'accountPurpose',
  'calcInterestsPositiveFlag',
  'calcInterestsNegativeFlag',
  'calcOvernightsFlag',
  'useFreemoneyPremium',
  'useExcessMarginFee',
  'availableCurrencies',
  'useIntradayMargin',
  'sumOrderMargin',
  'strictOrderMarginPolicy',
  'preorderMarginCheck',
  'preorderCommissionCheck',
  'navViolationCheck',
  'concentrationMargin',
  'useMarginSlippage',
  'marginThresholdOverride',
  'takeMinimumFee',
  'commissionGroupId',
  'calcCommissionRebate',
  'interestsSetId',
  'calcInterestRebate',
  'overnightsSetId',
  'interestUseNegativePnl',
  'interestUsePositivePnl',
  'takeCashConversionCommission',
  'cashConversionLimit',
  'convertCashInto',
  'permissionsSetId',
  'routingTags',
  'mirroringLevel',
  'autoDebit',
  'autoDebitAccountId',
  'excludeFromGlobalMetrics',
  'clientName',
  'clientCode',
  'totalOrderMargin',
  'totalFreeMoney',
  'totalCash',
  'totalBlockedMargin',
  'sharePrice',
  'requiredMargin',
  'netAssetValue',
  'marginUtilization',
  'originatorIds',
  'branding',
  'clientLegalEntity',
  'permissionGroupId',
  'serviceFeeGroupId',
];

export const ACCOUNTS_PARAMS_MAPPER: TMapper = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  created: {
    parser: (values: [string, string]) => {
      return prepareDateRangeStringForAPI(
        CREATED_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  archivedDate: {
    parser: (values: [string, string]) =>
      prepareDateRangeStringForAPI(
        ARCHIVE_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      ),
  },
  page: {
    parser: createEmptyObject,
  },
  totalOrderMargin: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'totalOrderMargin'),
  },
  totalFreeMoney: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'totalFreeMoney'),
  },
  totalCash: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'totalCash'),
  },
  totalBlockedMargin: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'totalBlockedMargin'),
  },
  sharePrice: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'sharePrice'),
  },
  requiredMargin: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'requiredMargin'),
  },
  netAssetValue: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'netAssetValue'),
  },
};

export const ACCOUNT_UPDATE_EXPECTED_FIELDS: (keyof IAccount)[] = [
  'type',
  'internalComment',
  'status',
  'ownershipType',
  'clientId',
  'autoLiquidationAllowed',
  'archived',
  'accountPurpose',
  'comment',
  'subOwnerDescription',
  'availableCurrencies',
  'counterpartyAccountId',
  'originatorIds',
  'branding',
];

export const DEFAULT_ACCOUNT_METRICS_FIELDS = [
  'netAssetValue',
  'requiredMargin',
  'totalOrderMargin',
  'totalBlockedMargin',
  'freeMoney',
  'totalCash',
  'marginUtilization',
  'marginStructure',
  'cashFreeMoney',
  'totalFreeMoney',
];

export const DEFAULT_ACCOUNT_ACCESS: (typeof accountAccessTypes)[number] =
  'blocked';

export const accountAccessTypes = [
  'blocked',
  'read_only',
  'close_only',
  'full_access',
] as const;
