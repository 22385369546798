import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { IColumn } from 'react-ui-kit-exante';

import { NameCell } from '~/shared/components';
import { selectFiltersLayer } from '~/store/overnights';
import { FilterLayers, IStateOvernights } from '~/types/overnights';

import { ValueCell, OverrideCell } from './components';
import { renderCurrencyCell } from './helpers';

export const useColumns = (
  queryParams: IStateOvernights['filters']['queryParams'],
): IColumn<Record<string, unknown>>[] => {
  const prevColumns = useRef<IColumn<Record<string, unknown>>[]>([]);
  const filtersLayer = useSelector(selectFiltersLayer);

  if (!queryParams) {
    return prevColumns.current;
  }

  const columns = [
    {
      Cell: NameCell,
      Header: 'Name',
      accessor: 'displayName',
      disableSortBy: true,
      id: 'name',
      width: 2000,
    },
    {
      Cell: OverrideCell,
      Header: 'Override',
      accessor: 'override',
      disableSortBy: true,
      maxWidth: 100,
      getProps: () => ({ layer: filtersLayer }),
    },
    ...(filtersLayer === FilterLayers.Default
      ? [
          {
            Cell: ValueCell,
            Header: 'Benchmark Negative',
            accessor: 'benchmarkNegative',
            disableSortBy: true,
            maxWidth: 100,
          },
          {
            Cell: ValueCell,
            Header: 'Benchmark Positive',
            accessor: 'benchmarkPositive',
            disableSortBy: true,
            maxWidth: 100,
          },
        ]
      : []),
    {
      Cell: ValueCell,
      Header: 'Markup Negative',
      accessor: 'markupNegative',
      disableSortBy: true,
      maxWidth: 100,
    },
    {
      Cell: ValueCell,
      Header: 'Markup Positive',
      accessor: 'markupPositive',
      disableSortBy: true,
      maxWidth: 100,
    },
    ...(filtersLayer !== FilterLayers.Accounts
      ? [
          {
            Header: 'Currency',
            accessor: 'currency',
            disableSortBy: true,
            maxWidth: 100,
            Cell: renderCurrencyCell,
          },
        ]
      : []),
  ];

  prevColumns.current = columns;

  return columns;
};
