export function findDuplicateIndexes(
  array: Array<unknown>,
): [number, number] | false {
  for (let i = 0; i < array.length - 1; i += 1) {
    for (let j = i + 1; j < array.length; j += 1) {
      if (array[i] === array[j]) {
        return [i, j];
      }
    }
  }

  return false;
}
