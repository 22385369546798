import { useCallback, useEffect, useMemo, useState } from 'react';

import { EMPTY_ARRAY } from '~/constants';

import { usePrevious } from '../usePrevious';

import { DEFAULT_CURSOR, DEFAULT_LIMIT, DEFAULT_SKIP } from './constants';
import {
  getAssumedNextIndex,
  getAssumedPreviousIndex,
  getEntity,
  shouldCheckNextData,
  shouldCheckPreviousData,
} from './helpers';
import {
  Entity,
  IUseEntitiesNavigationProps,
  IUseEntitiesNavigationReturn,
} from './types';

let hasMoreNextData = true;

export function useEntitiesNavigation<Data>({
  cursor: initialCursor,
  params,
  fetchData,
  onNext,
  onPrevious,
}: IUseEntitiesNavigationProps<Data>): IUseEntitiesNavigationReturn<Data> {
  const initial = useMemo(
    () => ({
      limit: params.limit ?? DEFAULT_LIMIT,
      skip: params.skip ?? DEFAULT_SKIP,
      cursor: initialCursor ?? DEFAULT_CURSOR,
    }),
    [initialCursor, params],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(initial.limit);
  const [skip, setSkip] = useState(initial.skip);
  const [data, setData] = useState<Data[]>(EMPTY_ARRAY);
  const [cursor, setCursor] = useState(initial.cursor);
  const [entity, setEntity] = useState<Entity<Data>>(() =>
    getEntity(data, initialCursor),
  );

  const previousSkip = usePrevious(skip);

  const isPreviousDisabled = useMemo(
    () => isLoading || entity.previous === null,
    [entity, isLoading],
  );
  const isNextDisabled = useMemo(
    () => isLoading || entity.next === null,
    [isLoading, entity],
  );
  const modifiedParamsBySkip = useMemo(() => {
    const result = { ...params, skip, limit };

    return result;
  }, [limit, params, skip]);

  const setLoadingOn = useCallback(() => setIsLoading(true), []);
  const setLoadingOff = useCallback(() => setIsLoading(false), []);

  const getData = useCallback(
    async (newParams?: Record<string, unknown>) => {
      if (!fetchData) {
        return Promise.resolve(EMPTY_ARRAY);
      }

      setLoadingOn();

      try {
        const response = await fetchData({
          ...modifiedParamsBySkip,
          ...newParams,
        });

        setLoadingOff();

        return response;
      } catch (error) {
        console.error(error);

        setLoadingOff();

        return Promise.resolve(EMPTY_ARRAY);
      }
    },
    [fetchData, modifiedParamsBySkip, setLoadingOff, setLoadingOn],
  );

  const getInitialData = useCallback(async () => {
    const response = await getData();

    setData((prevData) => {
      if (!response.length) {
        return prevData;
      }

      return response;
    });
  }, [getData]);

  const getPreviousData = useCallback(async () => {
    const response = await getData();

    setData((prevData) => {
      if (!response.length) {
        return prevData;
      }

      setCursor(
        response.length / (response.length / initial.limit) + initial.cursor,
      );

      return response.concat(prevData);
    });
  }, [getData, initial]);

  const getNextData = useCallback(
    async (newParams?: Record<string, unknown>) => {
      const response = await getData(newParams);

      if (response.length < initial.limit) {
        hasMoreNextData = false;
      }

      setData((prevData) => {
        if (!response.length) {
          return prevData;
        }

        return prevData.concat(response);
      });
    },
    [getData, initial],
  );

  const handlePreviousClick = useCallback(() => {
    if (isPreviousDisabled) {
      return;
    }

    setCursor((prevCursor) => {
      const newCursor = prevCursor - 1;

      const assumedIndex = getAssumedPreviousIndex(newCursor, initial.limit);

      if (skip !== 0 && assumedIndex === 0) {
        setSkip((prevSkip) =>
          prevSkip - initial.limit >= 0 ? prevSkip - initial.limit : 0,
        );
      }

      if (onPrevious) {
        onPrevious({
          item: data[newCursor],
          params: modifiedParamsBySkip,
          cursor: newCursor,
        });
      }

      return newCursor;
    });
  }, [
    data,
    initial,
    isPreviousDisabled,
    modifiedParamsBySkip,
    onPrevious,
    skip,
  ]);

  const handleNextClick = useCallback(() => {
    if (isNextDisabled) {
      return;
    }

    setCursor((prevCursor) => {
      const newCursor = prevCursor + 1;

      const assumedIndex = getAssumedNextIndex(newCursor, initial.limit);

      if (hasMoreNextData && !data[assumedIndex]) {
        setSkip((prevSkip) => prevSkip + initial.limit);
      }

      if (onNext) {
        onNext({
          item: data[newCursor],
          params: modifiedParamsBySkip,
          cursor: newCursor,
        });
      }

      return newCursor;
    });
  }, [data, initial, isNextDisabled, modifiedParamsBySkip, onNext]);

  const init = useCallback(() => {
    const isLeftCriticalBorder = shouldCheckPreviousData(
      initial.cursor,
      initial.skip,
      initial.limit,
    );
    const isRightCriticalBorder = shouldCheckNextData(
      initial.cursor,
      initial.limit,
    );

    if (isLeftCriticalBorder) {
      setSkip((prevSkip) =>
        prevSkip - initial.limit >= 0 ? prevSkip - initial.limit : 0,
      );
      setLimit((prevLimit) => prevLimit * 2);

      return;
    }

    if (isRightCriticalBorder) {
      setLimit((prevLimit) => prevLimit * 2);
      getNextData({ limit: initial.limit * 2 });

      return;
    }

    getInitialData();
  }, [getInitialData, getNextData, initial, setLimit, setSkip]);

  const handleKeyboardPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey && event.key === 'ArrowRight') {
        handleNextClick();
      }

      if (event.shiftKey && event.key === 'ArrowLeft') {
        handlePreviousClick();
      }
    },
    [handleNextClick, handlePreviousClick],
  );

  useEffect(() => {
    if (!data.length) {
      init();
    }
    // Only first render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEntity(getEntity(data, cursor));
  }, [cursor, data]);

  useEffect(() => {
    if (skip < previousSkip) {
      getPreviousData();
    }

    if (skip > previousSkip) {
      getNextData();
    }
  }, [cursor, getNextData, getPreviousData, limit, previousSkip, skip]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardPress);

    return () => document.removeEventListener('keydown', handleKeyboardPress);
  }, [handleKeyboardPress]);

  return {
    handleNextClick,
    handlePreviousClick,
    isLoading,
    isPreviousDisabled,
    isNextDisabled,
    entity,
  };
}
