export enum TableId {
  AccessRights = 'access-rights-table',
  AccountActivityAuditLogs = 'account-activity-audit-logs-table',
  AccountActivityNotifications = 'account-activity-notifications-table',
  AccountActivityTrades = 'account-activity-trades-table',
  AccountActivityTransactions = 'account-activity-transactions-table',
  Accounts = 'accounts-table',
  Asset = 'asset-table',
  AuditLogs = 'audit-logs-table',
  BlockedFunds = 'blocked-funds-table',
  ClientAccounts = 'client-accounts-table',
  Clients = 'clients-table',
  Commissions = 'commissions-table',
  ExpiredInstruments = 'expired-instruments-table',
  GlobalSummary = 'global-summary',
  GlobalSummaryByAccounts = 'global-summary-by-accounts',
  GlobalSummaryByPosition = 'global-summary-by-position',
  GlobalSummaryCurrencyRisk = 'global-summary-currency-risk',
  InterCommodity = 'inter-commodity-table',
  InterestRates = 'interest-rates-table',
  LegalEntities = 'legal-entities-table',
  Limits = 'limits-table',
  MarginStructure = 'margin-structure-table',
  MirroringRules = 'mirroring-rules-table',
  NonTradingCommissions = 'non-trading-commissions-table',
  OrderMargin = 'order-margin-table',
  Overnights = 'overnights-table',
  Rebates = 'rebates-table',
  Referrals = 'referrals-table',
  RegularCommissions = 'regular-commissions-table',
  RiskArrays = 'risk-arrays',
  RiskSettings = 'risk-settings-table',
  SymbolPermissions = 'symbol-permissions-table',
  TradeImport = 'trade-import-table',
  Trades = 'trades-table',
  TransactionImport = 'transaction-import-table',
  Transactions = 'transactions-table',
  UnderlyingSettings = 'underlying-settings-table',
  UserManagement = 'user-management-table',
  Users = 'users-table',
}

export const PROJECT_PREFIX = 'web-bo';

export const getTableId = (tableName: keyof typeof TableId) => {
  return `${PROJECT_PREFIX}-${TableId[tableName]}`;
};
