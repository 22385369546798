export function clearTableLocalStorageForActionColumn(tableId: string) {
  try {
    const lsColumns = JSON.parse(
      localStorage.getItem(`${tableId}-columns`) || '[]',
    );

    if (lsColumns.length && !lsColumns.includes('actions')) {
      localStorage.removeItem(`${tableId}-columns`);
      localStorage.removeItem(`${tableId}-view-params`);
      window.location.reload();
    }
  } catch (e) {
    console.error(e);
  }
}
