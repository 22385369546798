import {
  apiRequest,
  IOptionsArguments,
  paramsTransformer,
} from '~/shared/utils';
import { IDataWithPagination, TParams } from '~/types/api';
import { IClient, ICoreClient, IClientUser } from '~/types/clients';

import { CLIENTS_PARAMS_MAPPER } from './clients.mapper';
import {
  CREATE_CLIENT,
  FETCH_CLIENT_USERS,
  FETCH_CLIENTS,
  getClientByIdAPI,
} from './endpoints';
import { ICreateClientPayload } from './types';

export class ClientsRepository {
  public static fetchClients(params: TParams, options?: IOptionsArguments) {
    const preparedParams = paramsTransformer({
      params,
      mapper: CLIENTS_PARAMS_MAPPER,
    });

    return apiRequest<IDataWithPagination<IClient[]>>(
      {
        params: preparedParams,
        url: FETCH_CLIENTS,
      },
      options,
    );
  }

  public static fetchClient(id: string) {
    return apiRequest({
      url: getClientByIdAPI(id),
    });
  }

  public static putClient(id: string, data: ICoreClient) {
    return apiRequest({
      url: getClientByIdAPI(id),
      method: 'POST',
      data,
    });
  }

  public static createClient(data: ICreateClientPayload) {
    if (data.branding === '') {
      data.branding = null;
    }

    return apiRequest<ICoreClient>({
      url: CREATE_CLIENT,
      method: 'POST',
      data,
    });
  }

  public static getClientUsers(clientId: string) {
    return apiRequest({
      params: { clientId },
      url: FETCH_CLIENT_USERS,
    });
  }

  public static deleteClientUser(params: IClientUser) {
    return apiRequest({
      method: 'DELETE',
      params: { clientId: params.clientId, userId: params.userId },
      url: FETCH_CLIENT_USERS,
    });
  }

  public static postClientUser(clientUsers: IClientUser[]) {
    return apiRequest({
      data: clientUsers,
      method: 'POST',
      url: FETCH_CLIENT_USERS,
    });
  }
}
