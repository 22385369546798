import { ReactChild } from 'react';

import {
  StyledHeaderWrapper,
  StyledHeader,
} from './AccountPageTemplate.styled';

interface IAccountPageTemplateProps {
  header: ReactChild;
  accountInfo: ReactChild;
  tabManagement: ReactChild;
}

export const AccountPageTemplate = ({
  header,
  accountInfo,
  tabManagement,
}: IAccountPageTemplateProps) => {
  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{header}</StyledHeader>
        <div>{accountInfo}</div>
      </StyledHeaderWrapper>

      <div>{tabManagement}</div>
    </>
  );
};
