import { EMPTY_ARRAY } from '~/constants';
import { IRequestArguments } from '~/types/api';

import { BrandingRepository } from './branding.repository';

export class BrandingService {
  public async resolveBranding(args?: IRequestArguments): Promise<string[]> {
    try {
      const { data } = await BrandingRepository.fetchBrandingList();

      return data;
    } catch (error) {
      if (error instanceof Error) {
        if (args?.onError) {
          args.onError(error);
        }
      }

      return EMPTY_ARRAY;
    }
  }
}
