import { styled } from 'react-ui-kit-exante';

export const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme?.color.bg.primary,
  padding: '24px',
}));

export const StyledHeader = styled('div')(() => ({
  marginBottom: '8px',
}));
