import { useMemo, useState, MouseEvent, ChangeEvent } from 'react';
import { Popover, Button, Checkbox, SearchInput } from 'react-ui-kit-exante';

import { IOption } from '~/types/form';

import {
  StyledPopoverContainer,
  StyledOptionName,
  StyledOptions,
  StyledOptionLabel,
  StyledOptionIconImg,
  StyledOptionIconDiv,
} from './ButtonSelect.styled';

type ButtonSelectProps = {
  label: string;
  options: IOption[];
  onChange: (
    value: string,
  ) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  value: string[];
  withIcon?: boolean;
};

export const ButtonSelect = ({
  options,
  label,
  onChange,
  value,
  withIcon,
}: ButtonSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.value.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [options, searchValue]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue('');
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button onClick={handleClick} color="secondary">
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPopoverContainer>
          <SearchInput onChange={setSearchValue} />

          <StyledOptions>
            {filteredOptions.map((option) => (
              <Checkbox
                key={option.value}
                name={option.label}
                onChange={onChange(option.value)}
                checked={value.includes(option.value)}
                label={
                  <StyledOptionLabel>
                    {withIcon && typeof option.icon === 'string' && (
                      <StyledOptionIconImg
                        src={option.icon}
                        alt={option.label}
                      />
                    )}
                    {withIcon && !option.icon && <StyledOptionIconDiv />}
                    <StyledOptionName>{option.label}</StyledOptionName>
                  </StyledOptionLabel>
                }
              />
            ))}
          </StyledOptions>
        </StyledPopoverContainer>
      </Popover>
    </>
  );
};
