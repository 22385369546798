import { useMemo } from 'react';

import { useAutocomplete } from '~/hooks';

export function useFetchData() {
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const getUsersAutocompleteFn = useAutocomplete('users');
  const getSymbolsAutocompleteFn = useAutocomplete('symbols');

  return useMemo(
    () => ({
      accountId: getAccountsAutocompleteFn(),
      symbolId: getSymbolsAutocompleteFn(),
      user: getUsersAutocompleteFn(),
    }),
    [
      getAccountsAutocompleteFn,
      getSymbolsAutocompleteFn,
      getUsersAutocompleteFn,
    ],
  );
}
