import { ActionTypes } from '../constants';
import { getQueryParams } from '../helpers';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function needRefreshPageSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.NEED_REFRESH_PAGE_SET],
) {
  if (!payload) {
    return {
      ...state,
      needRefreshPage: payload,
    };
  }

  const { relatedGroup } = state.filters;
  const { select } = state.filters;
  const { group, account, user } = select;

  const newGroup =
    (account || user) && group !== relatedGroup ? relatedGroup : group;
  const newSelect = {
    ...select,
    group: newGroup,
  };
  const queryParams = getQueryParams({ ...newSelect, relatedGroup });

  return {
    ...state,
    filters: {
      ...state.filters,
      select: newSelect,
      queryParams,
    },
    needRefreshPage: payload,
    permissions: {
      changed: {},
      initial: {},
    },
  };
}
