export const TRANSACTION_TEMPLATE =
  'data:text/csv;charset=utf-8,assetId,symbolId,amount,price,accountId,' +
  'operationType,comment,internalComment,useAutoconversion,valueDate,' +
  'useRevenue,transferId,clientCounterparty,externalCounterparty,' +
  'commissionValue,commissionCurrency,commissionOperationType,executionCounterparty';
export const TRANSACTION_TEMPLATE_FILENAME = 'transactionCsv.csv';
export const TRADE_TEMPLATE =
  'data:text/csv;charset=utf-8,accountId,userId,symbolId,tradeType,price,' +
  'processAccruedInterest,accruedInterest,quantity,side,comment,' +
  'internalComment,takeCommission,gwTime,valueDate,counterparty,' +
  'settlementCounterparty';
export const TRADE_TEMPLATE_FILENAME = 'tradeCsv.csv';
