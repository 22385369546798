import { useMemo } from 'react';

import { IAccountTabComponentProps } from '~/pages/AccountPage/TabManagement/types';

import { ActivityContainer } from '../ActivityContainer';

export const AccountActivityContainer = ({
  accountId,
  setRefetch,
}: IAccountTabComponentProps) => {
  const filters = useMemo(
    () => ({
      accountId,
    }),
    [accountId],
  );
  return (
    <ActivityContainer
      entity="account-activity-entry-screen"
      filters={filters}
      setRefetch={setRefetch}
    />
  );
};
