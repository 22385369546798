export const VALUE_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Percent', value: 'percent' },
];

export const TYPE_OPTIONS = [
  { label: 'Regular', value: 'regular' },
  { label: 'Per action', value: 'per action' },
];

export const INTERVAL_OPTIONS = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Daily', value: 'daily' },
];

export const EMPTY_FORM_ITEM = {
  nonFilledName: '',
  name: '',
  isFree: false,
  value: '',
  type: undefined,
  interval: undefined,
  commission: '',
  min: '',
  max: '',
};
