import { IInterestRate } from '~/types/interestRates';

export const FIGURE_CELLS_TOOLTIP = 'Positive - pay on, negative - charge off';
export const TRADING_DAYS_CELL_TOOLTIP = 'Trading days quantity per year';

export const tradingDaysOptions = ['360', '365'];
export const percentageInputProps = {
  step: '0.0001',
};
export const excludedFields = ['actions'];
export const uneditableFields = ['currency'];
export const percentFields = [
  'negativeBenchmark',
  'positiveBenchmark',
  'negativeMarkup',
  'positiveMarkup',
  'negativeRebate',
  'positiveRebate',
];

export const DEFAULT_PERCENT_VALUE = '0.0000';
export const DEFAULT_DAYS_VALUE: IInterestRate['days'] = 365;

export const tabsIds = ['default', 'groups', 'accounts'] as const;

export const DEFAULT_ACCOUNT_GROUP = 'Default';
export const ACCOUNTS_TAB = 2;

export enum InterestPageLayer {
  Default = 'Default',
  Groups = 'Groups',
  Accounts = 'Accounts',
}
