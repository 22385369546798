import { isEqual } from 'lodash';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useLazyGetOvernightsTreeQuery } from '~/api';
import {
  selectFiltersLayer,
  selectFiltersQueryParams,
  selectSearchIsActive,
} from '~/store/overnights';
import {
  FilterLayers,
  IGetResolveOvernightsTreeParams,
} from '~/types/overnights';

export const useOvernightsTree = () => {
  const [getOvernightsTree, isLoading] = useLazyGetOvernightsTreeQuery();
  const layer = useSelector(selectFiltersLayer);
  const queryParams = useSelector(selectFiltersQueryParams);
  const searchIsActive = useSelector(selectSearchIsActive);

  const prevParams = useRef<IGetResolveOvernightsTreeParams | false>(false);

  const fetchOvernightsTree = useCallback(async () => {
    if (
      !queryParams ||
      searchIsActive ||
      isEqual(queryParams, prevParams.current) ||
      (layer === FilterLayers.Accounts &&
        queryParams.layer === FilterLayers.Groups)
    ) {
      return;
    }

    prevParams.current = queryParams;

    getOvernightsTree(queryParams);
  }, [getOvernightsTree, layer, queryParams, searchIsActive]);

  return {
    fetchOvernightsTree,
    isLoading: isLoading.isLoading || isLoading.isFetching,
  };
};
