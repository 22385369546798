import { useCallback, useEffect, useState } from 'react';

import { getInitialCurrency, getNewCurrencyFromEvent } from '~/shared/utils';

export function useCurrency() {
  const [currency, setCurrency] = useState(() =>
    getInitialCurrency(localStorage.getItem('currency')),
  );

  const handleChangeCurrency = useCallback(
    (event: Event) => {
      const newCurrencyId = getNewCurrencyFromEvent(event);

      if (newCurrencyId) {
        setCurrency(newCurrencyId);
      }
    },
    [setCurrency],
  );

  useEffect(() => {
    window.addEventListener('onChangeCurrency', handleChangeCurrency);

    return () => {
      window.removeEventListener('onChangeCurrency', handleChangeCurrency);
    };
  }, [handleChangeCurrency]);

  return currency;
}
