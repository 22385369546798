import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { servicesService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

export const servicesApi = createApi({
  reducerPath: 'servicesQuery',
  baseQuery: async () =>
    transformToBaseQueryResponse(await servicesService.getServices()),
  endpoints: (build) => ({
    getServices: build.query({
      query: () => {},
    }),
  }),
});

export const { useGetServicesQuery } = servicesApi;
