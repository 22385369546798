import {
  ILegalEntityDetail,
  ILegalEntityFormData,
} from '~/types/legalEntities';

export function transformLegalEntityToFormData(
  data: ILegalEntityDetail,
): ILegalEntityFormData {
  return Object.entries(data).reduce<ILegalEntityFormData>(
    (acc, [key, value]) => {
      if (key === 'is_default') {
        return {
          ...acc,
          isDefault: value,
        };
      }

      if (key === 'id') {
        return acc;
      }

      return {
        ...acc,
        [key]: value,
      };
    },
    {} as ILegalEntityFormData,
  );
}
