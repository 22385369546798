import { ActionTypes } from '../constants';
import {
  IStatePasswordManager,
  TActionsPasswordManagementState,
} from '../types';

import { addToken } from './addToken';
import { deleteToken } from './deleteToken';
import { editToken } from './editToken';
import { setInitial } from './setInitial';
import { updateAuthToken } from './updateAuthToken';
import { updateFinish } from './updateFinish';
import { updateInitialAuth } from './updateInitialAuth';
import { updateInitialTokens } from './updateInitialTokens';

export const reducer = (
  state: IStatePasswordManager,
  { type, payload }: TActionsPasswordManagementState,
) => {
  switch (type) {
    case ActionTypes.ADD_TOKEN:
      return addToken(state, payload);
    case ActionTypes.DELETE_TOKEN:
      return deleteToken(state, payload);
    case ActionTypes.EDIT_TOKEN:
      return editToken(state, payload);
    case ActionTypes.SET_INITIAL:
      return setInitial(state, payload);
    case ActionTypes.UPDATE_AUTH_TOKEN:
      return updateAuthToken(state, payload);
    case ActionTypes.UPDATE_FINISH:
      return updateFinish(state);
    case ActionTypes.UPDATE_INITIAL_AUTH:
      return updateInitialAuth(state, payload);
    case ActionTypes.UPDATE_INITIAL_TOKENS:
      return updateInitialTokens(state, payload);
    default:
      return state;
  }
};
