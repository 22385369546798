import cn from 'classnames';

import { useBrandingList, usePickUserPermissions } from '~/hooks';
import { IOption } from '~/types/form';
import { IExtraData } from '~/types/users/extraData';

import {
  FormCheckboxContainer,
  FormDatePickerContainer,
  FormInputContainer,
  FormSelectContainer,
  FormCodeEditorContainer,
} from '../../form';

import GeneralSettingsStyles from './GeneralSettings.module.css';

const MIN_BIRTH_DATE = new Date('1900-01-01');
const MAX_BIRTH_DATE = new Date();

interface IGeneralSettingsProps {
  options: Record<string, Array<IOption>>;
  extraData?: IExtraData;
  hasUserInfoWriteAccess: boolean;
}

export const GeneralSettings = ({
  options,
  extraData,
  hasUserInfoWriteAccess,
}: IGeneralSettingsProps) => {
  const { brandingListWithoutAll } = useBrandingList();
  const { companyClassificationOptions, sexOptions, userTypeOptions } = options;

  const userPermissions = usePickUserPermissions([
    'User blocking',
    'User info: branding',
    'User info: user type',
  ]);

  return (
    <div
      className={cn(GeneralSettingsStyles.Wrapper, {
        Disabled: !hasUserInfoWriteAccess,
      })}
    >
      <FormInputContainer readonly label="ID" name="user.id" />
      <FormInputContainer label="First name" name="user.info.firstName" />
      <FormInputContainer label="Last name" name="user.info.lastName" />
      <FormInputContainer label="Middle name" name="user.info.middleName" />
      <FormInputContainer label="Email" name="user.info.email" type="email" />
      {extraData?.residence && (
        <FormInputContainer
          label="Country of residence"
          name="user.info.extraData.residence"
        />
      )}
      <FormSelectContainer
        label="Sex"
        name="user.info.sex"
        options={sexOptions}
      />
      <FormInputContainer label="Lei" name="user.info.lei" />
      <FormDatePickerContainer
        label="Birth date"
        name="user.info.birthDate"
        minDate={MIN_BIRTH_DATE}
        maxDate={MAX_BIRTH_DATE}
      />
      <FormSelectContainer
        label="Company classification"
        name="user.info.companyClassification"
        options={companyClassificationOptions}
      />
      <FormInputContainer
        readonly
        label="Creation date"
        name="user.info.creationDate"
      />
      {userPermissions['User info: user type'].read ? (
        <FormSelectContainer
          disabled={!userPermissions['User info: user type'].write}
          label="User Type"
          name="user.info.userType"
          options={userTypeOptions}
        />
      ) : null}
      <FormInputContainer
        label="Primary Phone"
        name="user.info.primaryPhone"
        type="tel"
      />
      <FormInputContainer
        readonly
        label="Last login time"
        name="user.info.lastLoginTime"
      />
      <FormInputContainer
        label="Preferred language"
        name="user.info.preferableLanguage"
      />
      <FormInputContainer label="Residence" name="user.info.residence" />
      <FormInputContainer
        label="Preferable currency"
        name="user.info.preferableCurrency"
      />
      <FormInputContainer label="Jurisdiction" name="user.info.jurisdiction" />
      <FormInputContainer label="Passport" name="user.info.passport" />
      {userPermissions['User info: branding'].read ? (
        <FormSelectContainer
          disabled={!userPermissions['User info: branding'].write}
          label="Branding"
          name="user.info.branding"
          options={brandingListWithoutAll}
        />
      ) : null}
      <FormInputContainer
        label="Internal info"
        name="user.info.extraData.internalInfo"
      />
      <FormCheckboxContainer label="Archived" name="user.info.archived" />
      {userPermissions['User blocking'].write && (
        <FormCheckboxContainer label="Blocked" name="user.info.blocked" />
      )}
      <FormCodeEditorContainer
        height="150px"
        label="Notifications"
        name="user.info.notifications"
        validateMode="onChange"
      />
    </div>
  );
};
