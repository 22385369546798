export const RefreshIcon = () => {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_18562_6258)">
        <path
          d="M0.999996 2.66666V6.66666H5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.67333 10C3.10559 11.2269 3.92489 12.2801 5.00777 13.0009C6.09065 13.7217 7.37845 14.0711 8.67714 13.9963C9.97583 13.9216 11.215 13.4268 12.2081 12.5865C13.2011 11.7462 13.8942 10.606 14.1828 9.33758C14.4715 8.06917 14.3401 6.74131 13.8084 5.55407C13.2768 4.36684 12.3737 3.38454 11.2352 2.75518C10.0968 2.12583 8.7846 1.88352 7.49645 2.06475C6.2083 2.24599 5.01394 2.84095 4.09333 3.76001L0.999996 6.66667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width="16"
            height="16"
            fill="transparent"
            transform="translate(0.333328)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
