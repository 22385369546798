import cn from 'classnames';
import {
  useController,
  useFormContext,
  UseFormGetValues,
} from 'react-hook-form';
import { Panel, Table } from 'react-ui-kit-exante';

import { NO_DATA_HEIGHT } from '~/constants';
import { FormCheckboxContainer, FormInputContainer } from '~/containers/form';
import { usePickUserPermissions } from '~/hooks';
import { getTableId } from '~/shared/utils';

import { TFormData } from '../types';

import {
  StyledPanel,
  StyledColumn,
  StyledCheckboxes,
} from './RiskSettings.styled';
import { columns } from './columns';
import { useGetRequestsStatus } from './hooks';

import '~/styles/Global.css';

interface IRiskSettingsProps {
  accountId: string;
  getValues: UseFormGetValues<TFormData>;
}

const STUB_IF_WE_DONT_HAVE_PERMISSIONS = {};

export const RiskSettings = ({ accountId, getValues }: IRiskSettingsProps) => {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: leverages and margin settings',
  ]);

  const displayMarginThresholdOverride =
    typeof getValues('margin.marginThresholdOverride') !== 'undefined';

  const { isLoading, isRejected } = useGetRequestsStatus(accountId);

  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: 'risk.rates',
  });

  const tableData = Object.keys(value || STUB_IF_WE_DONT_HAVE_PERMISSIONS).map(
    (key) => ({
      type: key,
      rate: value[key],
    }),
  );

  const handleCellDataEdit = (
    rowIndex: number,
    fieldName: string,
    val: string,
  ) => {
    if (tableData) {
      const field = tableData[rowIndex].type;
      onChange({ ...value, [field]: val || null });
    }
  };

  if (!userPermissions['Account info: leverages and margin settings'].read) {
    return null;
  }

  return (
    <Panel
      className={cn({
        Disabled:
          !userPermissions['Account info'].write ||
          !userPermissions['Account info: leverages and margin settings'].write,
      })}
      title="Risk settings"
    >
      <StyledPanel>
        <StyledColumn>
          <Table
            columns={columns}
            data={tableData || []}
            isLoading={isLoading.riskSettings}
            isFlexLayout
            tableId={getTableId('RiskSettings')}
            disableSortBy
            noDataHeight={NO_DATA_HEIGHT}
            handleCellDataEdit={handleCellDataEdit}
            saveViewParamsAfterLeave
          />
        </StyledColumn>

        <StyledCheckboxes>
          <FormCheckboxContainer
            disabled={isRejected.riskSettings}
            label="Use portfolio margin"
            name="risk.usePortfolioMargin"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Pre-order margin check"
            name="margin.preorderMarginCheck"
          />
          <FormCheckboxContainer
            label="Auto-liquidation allowed"
            name="account.autoLiquidationAllowed"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Concentration margin"
            name="margin.concentrationMargin"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Margin trading allowed"
            name="margin.marginTradingAllowed"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Skip wash trade check"
            name="margin.skipWashTradeCheck"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Nav violation check"
            name="margin.navViolationCheck"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Strict order margin policy"
            name="margin.strictOrderMarginPolicy"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Use intraday margin"
            name="margin.useIntradayMargin"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Margin slippage"
            name="margin.useMarginSlippage"
          />
          <FormCheckboxContainer
            disabled={isRejected.marginSettings}
            label="Skip revert position check"
            name="margin.skipRevertPositionCheck"
          />
          {displayMarginThresholdOverride ? (
            <FormInputContainer
              disabled={isRejected.marginSettings}
              label="Margin threshold override"
              name="margin.marginThresholdOverride"
            />
          ) : null}
        </StyledCheckboxes>
      </StyledPanel>
    </Panel>
  );
};
