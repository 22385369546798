import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, useModal } from 'react-ui-kit-exante';

import {
  useGetAccountPurposeTypesQuery,
  useGetAccountTypesQuery,
  useGetCategoriesTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOperationTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_OPTION_NULLABLE } from '~/constants';
import { mirroringRulePageContext } from '~/pages/MirroringRulePage/context/mirroringRulePageContext';
import { getValidationSchemaWrapped } from '~/pages/MirroringRulePage/validationSchema';
import { mirroringRulesService } from '~/resources';
import { MIRRORING_RULES_PATH } from '~/routes';
import { FixedLoader, SaveButton, TabContentHeader } from '~/shared/components';
import {
  createFormSubmitHandler,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { paramsSerializer } from '~/shared/utils/apiRequest/helpers';
import { IOption, TFormHandlerNew } from '~/types/form';
import { ILocationState } from '~/types/router';

import { Info } from '../Info';
import { MirroringRuleFormTemplate } from '../MirroringRuleFormTemplate';
import { RemoveButton } from '../RemoveButton';
import { IDataAvailabilityState, TFormData } from '../types';
import { useActiveOption } from '../useActiveOption';

import { createHandlersMap } from './helpers';

interface IMirroringRuleInfoFormProps extends TFormData {
  dataAvailabilityState: IDataAvailabilityState;
}

export const InfoFormContainer = ({
  dataAvailabilityState,
  ...formData
}: IMirroringRuleInfoFormProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestParams } = (location.state as ILocationState) || {};
  const confirmModal = useModal();
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntityOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );

  const { data: categoryTypes } = useGetCategoriesTypesQuery();

  const categoriesTypeOptions = transformVariantsToSelectOptions(
    categoryTypes?.values,
    { capitalized: true },
  );
  const { data: operationTypes } = useGetOperationTypesQuery();
  const operationTypeOptions = (operationTypes?.values || []).map(
    (operationType) => ({
      value: operationType,
      label: operationType.split('/').map(capitalize).join('/'),
    }),
  );
  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();
  const accountPurposesOptions = transformVariantsToSelectOptions(
    accountPurposeTypes?.values,
  );
  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const { setDirtyTabs } = useContext(mirroringRulePageContext);
  const { mirroringRule } = formData;
  const validationSchema = useMemo(() => getValidationSchemaWrapped(), []);
  const formInstance = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });

  const legalEntitiesEmptyOptions: IOption[] = useMemo(
    () => [EMPTY_OPTION_NULLABLE, ...legalEntityOptions],
    [legalEntityOptions],
  );

  const options = useMemo(
    () => ({
      legalEntitiesOptions: legalEntityOptions,
      legalEntitiesEmptyOptions,
      operationTypeOptions,
      categoriesTypeOptions,
      accountPurposesOptions,
      accountTypesOptions,
    }),
    [
      legalEntityOptions,
      legalEntitiesEmptyOptions,
      operationTypeOptions,
      categoriesTypeOptions,
      accountPurposesOptions,
      accountTypesOptions,
    ],
  );

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = formInstance;

  const isTransactionsActive = useMemo(
    () => Boolean(mirroringRule?.operationTypes?.length),
    [mirroringRule],
  );

  const { activeOption, handleActiveOptionChange } =
    useActiveOption(isTransactionsActive);

  const handlersMap = useMemo<TFormHandlerNew<TFormData> | null>(
    () =>
      createHandlersMap({
        dispatch,
        mirroringRule,
        activeOption,
      }),
    [dispatch, mirroringRule, activeOption],
  );

  const handleFormSubmit = useMemo(
    () =>
      createFormSubmitHandler<TFormData>({
        dirtyFields,
        handlersMap,
        reset,
        getValues,
      }),
    [dirtyFields, getValues, handlersMap, reset],
  );

  const handleRemoveClick = () => {
    confirmModal.onOpen();
  };

  const handleRemove = useCallback(() => {
    if (mirroringRule) {
      mirroringRulesService.removeMirroringRule(mirroringRule.id).then(() => {
        const { sorting, limit, skip, page, ...rest } = requestParams || {};

        navigate(`${MIRRORING_RULES_PATH}?${paramsSerializer(rest)}`);
      });
    }

    confirmModal.onClose();
  }, [confirmModal, mirroringRule, requestParams, navigate]);

  /**
   * When formData changes, form values are not changing
   * Because defaultValues are cached on the first render
   * https://react-hook-form.com/api/useform/ rule about defaultValues
   */
  useEffect(() => {
    const isFormValueNotEqualToFormDataValue =
      getValues().mirroringRule?.id !== formData.mirroringRule?.id;

    if (isFormValueNotEqualToFormDataValue) {
      reset(formData);
    }
  }, [getValues, formData.mirroringRule?.id]);

  useEffect(() => {
    setDirtyTabs('info', isDirty);
  }, [dirtyFields, isDirty, setDirtyTabs]);

  if (!mirroringRule) {
    return <div>No mirroring rules</div>;
  }

  return (
    <>
      <FormProvider {...formInstance}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {isSubmitting && <FixedLoader />}

          <MirroringRuleFormTemplate
            dataAvailabilityState={dataAvailabilityState}
            info={
              <Info
                options={options}
                activeOption={activeOption}
                handleActiveOptionChange={handleActiveOptionChange}
              />
            }
            header={
              <TabContentHeader
                title="Mirroring Rule"
                actions={
                  <>
                    <SaveButton
                      type="submit"
                      disabled={isSubmitting || !isDirty}
                    />
                    <RemoveButton
                      type="button"
                      onClick={handleRemoveClick}
                      iconSize={20}
                    />
                  </>
                }
              />
            }
          />
        </form>
      </FormProvider>

      <Modal
        isOpened={confirmModal.isOpened}
        onClose={confirmModal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: handleRemove,
        }}
      >
        <div>{`Do you want to delete ${
          mirroringRule.name || 'this record'
        }?`}</div>
      </Modal>
    </>
  );
};
