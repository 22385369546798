import { createApi } from '@reduxjs/toolkit/query/react';

import { cardsManagementService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

export const cardsManagementApi = createApi({
  reducerPath: 'cardsManagementQuery',
  baseQuery: async (args) =>
    transformToBaseQueryResponse(
      await cardsManagementService.fetchCardsByAccountId(args),
    ),
  endpoints: (build) => ({
    getCards: build.query({
      query: (id: string) => id,
    }),
  }),
});

export const { useGetCardsQuery } = cardsManagementApi;
