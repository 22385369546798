import { TServiceStateMap } from '~/containers/UserAccessRightsContainer/types';
import { ICoreFormUser } from '~/types/users';

import { UserServices } from './types';

export function getServicesDifference(
  services: TServiceStateMap,
  user: ICoreFormUser,
  userServices: UserServices,
) {
  const userServicesDict = userServices.reduce((acc, { service }) => {
    acc.add(service);
    return acc;
  }, new Set());
  const servicesKeys = Object.keys(services);
  const addedServices = servicesKeys.filter((key) => {
    return services[key].enabled && !userServicesDict.has(key);
  });

  const deletedServices = userServices.flatMap(({ service, id }) => {
    if (!services[service].enabled) {
      return id;
    }
    return [];
  });

  return { addedServices, deletedServices };
}
