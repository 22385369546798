import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { clientsService } from '~/resources';
import { transformToBaseQueryResponse } from '~/shared/utils/rtkQuery';

export const clientUsersApi = createApi({
  reducerPath: 'clientUsersQuery',
  baseQuery: async (args) =>
    transformToBaseQueryResponse(
      await clientsService.resolveCoreClientUsers(args),
    ),
  endpoints: (build) => ({
    getClientUsers: build.query({
      query: (clientId: string) => clientId,
    }),
  }),
});

export const { useGetClientUsersQuery } = clientUsersApi;
