import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { usePrevious } from '~/hooks';

import {
  DEPENDED_FIELDS_FOR_SWITCH_FORM,
  WATCHED_FIELDS_FOR_SWITCH_FORM,
} from '../constants';
import { TradeFormValues } from '../types';

interface IUseSwitchTrade {
  setIsSwitchTrade: (value: boolean) => void;
  setValue: UseFormSetValue<TradeFormValues>;
  watch: (name: typeof WATCHED_FIELDS_FOR_SWITCH_FORM) => string;
}

export function useSwitchTrade({
  setValue,
  watch,
  setIsSwitchTrade,
}: IUseSwitchTrade) {
  const watchSwitchedFields = watch(WATCHED_FIELDS_FOR_SWITCH_FORM);

  const prevWatchSwitchedFields = usePrevious(watchSwitchedFields);

  const onChangeSwitchTrade = useCallback(
    (_: unknown, value: boolean) => {
      setIsSwitchTrade(value);
    },
    [setIsSwitchTrade],
  );

  useEffect(() => {
    if (!isEqual(prevWatchSwitchedFields, watchSwitchedFields)) {
      WATCHED_FIELDS_FOR_SWITCH_FORM.forEach((fieldName, index) => {
        if (fieldName === 'nonSwitched.side') {
          setValue(
            'switched.side',
            watchSwitchedFields[index] === 'buy' ? 'sell' : 'buy',
          );
        } else {
          setValue(
            DEPENDED_FIELDS_FOR_SWITCH_FORM[index],
            watchSwitchedFields[index],
          );
        }
      });
    }
  }, [prevWatchSwitchedFields, watchSwitchedFields, setValue]);

  return { onChangeSwitchTrade };
}
