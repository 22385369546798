export enum CurrenciesRadio {
  Inherited = 'Inherited',
  All = 'All',
  None = 'None',
  Manual = 'Manual',
}

export const radioOptions = [
  {
    label: CurrenciesRadio.Inherited,
    value: CurrenciesRadio.Inherited,
  },
  {
    label: CurrenciesRadio.All,
    value: CurrenciesRadio.All,
  },
  {
    label: CurrenciesRadio.None,
    value: CurrenciesRadio.None,
  },
  {
    label: CurrenciesRadio.Manual,
    value: CurrenciesRadio.Manual,
  },
];

export const AVAILABLE_CURRENCIES_FORM_NAME = 'account.availableCurrencies';
