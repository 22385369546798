export const SettingsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3931 12.3497C15.3931 10.2787 13.7141 8.59973 11.6431 8.59973C9.572 8.59973 7.89307 10.2787 7.89307 12.3497C7.89307 14.4208 9.572 16.0997 11.6431 16.0997C13.7141 16.0997 15.3931 14.4208 15.3931 12.3497Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3389 20.4657C15.6252 20.8801 14.8594 21.1976 14.0618 21.4098L12.093 19.8367C11.792 19.8545 11.4902 19.8545 11.1893 19.8367L9.22051 21.4089C8.42277 21.197 7.6567 20.8802 6.94238 20.4667L6.66113 17.9626C6.43585 17.7626 6.22266 17.5495 6.0227 17.3242L3.51957 17.0429C3.108 16.3296 2.79306 15.5648 2.58301 14.7685L4.15613 12.7998C4.13832 12.4988 4.13832 12.197 4.15613 11.896L2.58394 9.92729C2.79576 9.12955 3.1126 8.36348 3.52613 7.64917L6.03019 7.36792C6.23016 7.14263 6.44335 6.92944 6.66863 6.72948L6.94988 4.22635C7.66366 3.81195 8.42946 3.49447 9.22707 3.28229L11.1958 4.85541C11.4968 4.8376 11.7986 4.8376 12.0996 4.85541L14.0683 3.28323C14.8658 3.49357 15.6319 3.80882 16.3464 4.22073L16.6277 6.72479C16.853 6.92475 17.0662 7.13794 17.2661 7.36323L19.7693 7.64448C20.1837 8.35826 20.5011 9.12405 20.7133 9.92166L19.1402 11.8904C19.158 12.1914 19.158 12.4932 19.1402 12.7942L20.7124 14.7629C20.502 15.5604 20.1868 16.3265 19.7749 17.041L17.2708 17.3223C17.0709 17.5476 16.8577 17.7608 16.6324 17.9607L16.3389 20.4657Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
