import { IMirroringRuleTabComponentProps } from '~/pages/MirroringRulePage/TabManagement/types';
import { StaticCenteredLoader } from '~/shared/components';

import { InfoFormContainer } from './InfoFormContainer';
import { useMirroringRuleData } from './useMirroringRuleInfoData';

export const MirroringRuleInfoContainer = ({
  mirroringRuleId,
}: IMirroringRuleTabComponentProps) => {
  const {
    queries: { mirroringRuleQuery },
    isDataLoading,
    dataAvailabilityState,
  } = useMirroringRuleData(mirroringRuleId);

  if (isDataLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <InfoFormContainer
      mirroringRule={mirroringRuleQuery.data}
      dataAvailabilityState={dataAvailabilityState}
    />
  );
};
