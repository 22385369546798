import { apiRequest } from '~/shared/utils';

import {
  getAccountRegularCommissionsAPI,
  getRegularCommissionsAPI,
} from './endpoints';
import { prepareCommissionsSettingsForDelete } from './regularCommissions.mappers';
import {
  TAccountRegularCommissions,
  TAccountRegularCommissionsTableRow,
  TRegularCommissions,
} from './types';

export class RegularCommissionsRepository {
  public static fetchRegularCommissions() {
    return apiRequest<TRegularCommissions[]>({
      url: getRegularCommissionsAPI(),
      method: 'GET',
    });
  }

  public static fetchAccountRegularCommissions(accountId: string) {
    const params = {
      accountId,
    };
    return apiRequest<TAccountRegularCommissions>({
      url: getAccountRegularCommissionsAPI(),
      method: 'GET',
      params,
    });
  }

  public static updateAccountRegularCommissions(
    regularCommissions: TAccountRegularCommissionsTableRow[],
  ) {
    return apiRequest({
      url: getAccountRegularCommissionsAPI(),
      method: 'POST',
      data: regularCommissions,
    });
  }

  public static deleteAccountRegularCommissions(
    commissions: TAccountRegularCommissionsTableRow,
  ) {
    const params = prepareCommissionsSettingsForDelete(commissions);
    return apiRequest<TAccountRegularCommissions>({
      url: getAccountRegularCommissionsAPI(),
      method: 'DELETE',
      params,
    });
  }
}
