import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Tooltip } from 'react-ui-kit-exante';

import {
  ExpiredInstrumentsUpdateFields,
  expiredInstrumentActions,
} from '~/api';

import {
  selectRequestsToUpdate,
  updateFields,
} from '../../expiredInstrumentsSlice';
import { getRequestById } from '../../helpers';
import { TableData } from '../../types';

type CheckboxCellProps = {
  id: ExpiredInstrumentsUpdateFields;
  originalValues: TableData;
};

let timer: ReturnType<typeof setTimeout>;

export const CheckboxCell = ({ id, originalValues }: CheckboxCellProps) => {
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);

  const requests = useSelector(selectRequestsToUpdate);
  const values = getRequestById(requests, originalValues);
  const value = values?.[id] || '';

  useEffect(() => {
    const request = getRequestById(requests, originalValues);
    if (request && request.action !== expiredInstrumentActions.doNothing) {
      setShowWarning(false);
    }
  }, [
    originalValues,
    originalValues.optionGroupId,
    originalValues.symbolId,
    requests,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const request = getRequestById(requests, originalValues);

    if (!request || request.action === expiredInstrumentActions.doNothing) {
      setShowWarning(true);
      timer = setTimeout(() => {
        setShowWarning(false);
      }, 3000);
      return;
    }
    dispatch(
      updateFields({
        id: originalValues.optionGroupId || originalValues.symbolId,
        key: id,
        value: e.target.checked,
        type: originalValues.optionGroupId ? 'optionGroup' : 'symbol',
      }),
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div data-input-id={id}>
      <Tooltip
        title="Please choose the action"
        placement="top"
        open={showWarning}
      >
        <Checkbox checked={Boolean(value)} onChange={handleChange} />
      </Tooltip>
    </div>
  );
};
