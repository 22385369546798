import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

export const formatNumber = (value?: number | string | null) => {
  const numberedValue = Number(value);

  if (numberedValue === undefined || Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return numberedValue.toFixed(2).replace(/\.00$/, '');
};
