import { merge } from 'lodash';
import {
  createTheme,
  ThemeOptions,
  defaultTheme as kitTheme,
  useTheme as useKitTheme,
} from 'react-ui-kit-exante';

import { TWebBOTheme, WebBOThemeExtensions } from './theme.types';

// Add here any project-specific theme extensions
export const webboThemeValues: WebBOThemeExtensions = {};

export const theme = createTheme(
  merge(kitTheme, webboThemeValues) as ThemeOptions,
) as TWebBOTheme;
export const useTheme = useKitTheme<TWebBOTheme>;
