import { pick } from 'lodash';

import { MAX_REQUEST_LIMIT } from '~/constants';
import { FIELDS_FOR_POST } from '~/pages/SymbolPermissionsPage/constants';
import {
  IStateSymbolPermissionsQueryParams,
  ISymbolPermissions,
  TChangedPermissions,
} from '~/pages/SymbolPermissionsPage/types';
import {
  displayErrorNotification,
  IOptionsArguments,
  is403GetError,
  recursiveRequest,
  sendNotification,
} from '~/shared/utils';
import { IDataWithPagination } from '~/types/api';
import { RowType } from '~/types/common';
import {
  FilterLayers,
  ISymbol,
  ISymbolPermissionsPost,
  ISymbolTreeResponse,
  ISymbolTreeStructure,
} from '~/types/symbolPermissions';

import { DEFAULT_SYMBOLS_PERMISSIONS_SET } from './constants';
import { convertPermissionsStructureFromFlatToTree } from './helpers';
import { SymbolPermissionsRepository } from './symbolPermissions.repository';

interface ISearchInstrumentsParams {
  search: string;
  limit?: number;
  skip?: number;
  instruments?: ISymbolTreeStructure[];
  preparedParams: IStateSymbolPermissionsQueryParams & {
    withExpired: boolean;
  };
  options: IOptionsArguments;
}

export class SymbolPermissionsService {
  public resolveSymbolsTree = async (
    preparedParams: IStateSymbolPermissionsQueryParams & {
      withExpired: boolean;
    },
    permissionsByPath: Record<string, ISymbolPermissions>,
    changedPermissions: TChangedPermissions,
  ): Promise<ISymbolTreeResponse | null> => {
    try {
      const { layer, ...restParams } = preparedParams;

      const { data } = await SymbolPermissionsRepository.fetchSymbolsTree(
        layer,
        restParams,
      );

      return convertPermissionsStructureFromFlatToTree(
        data.data,
        permissionsByPath,
        changedPermissions,
      );
    } catch (error) {
      return null;
    }
  };

  public resolveInstruments = async (
    parentPath: string,
    changedPermissions: TChangedPermissions,
    permissionsByPath: Record<string, ISymbolPermissions>,
    limit: number,
    skip: number,
    preparedParams: IStateSymbolPermissionsQueryParams & {
      withExpired: boolean;
    },
    filterLayer: FilterLayers,
  ): Promise<IDataWithPagination<ISymbolTreeStructure[]> | null> => {
    try {
      const { layer, ...restParams } = preparedParams;

      const { data } =
        await SymbolPermissionsRepository.fetchSymbolsPermissions(
          {
            path: parentPath,
            limit,
            skip,
            ...restParams,
          },
          layer,
        );

      // TODO need refactor for instruments and split logic
      return {
        ...data,
        data: data.data.map(
          ({ displayName, id, permissions, path, isExpired }: ISymbol) => {
            const { id: skippedId, ...changedPermission } =
              filterLayer !== FilterLayers.Default
                ? pick(
                    changedPermissions[
                      Object.keys(changedPermissions)
                        .filter((key) => (path || '').startsWith(key))
                        .sort((a, b) => b.length - a.length)[0]
                    ] || {},
                    ['short', 'trade', 'view'],
                  )
                : pick(changedPermissions[id], FIELDS_FOR_POST);

            return {
              icon: null,
              displayName,
              path,
              id,
              isExpired,
              rowType: RowType.Instrument,
              ...DEFAULT_SYMBOLS_PERMISSIONS_SET,
              ...permissions,
              ...(permissionsByPath[path]?.override
                ? permissionsByPath[path]
                : {}),
              ...(permissionsByPath[path]?.override === false
                ? {
                    ...permissions,
                    override: false,
                  }
                : {}),
              ...(permissions.public ? changedPermission : {}),
            };
          },
        ),
      };
    } catch (error) {
      return null;
    }
  };

  public resolveAllInstruments = async (
    parentPath: string,
    changedPermissions: TChangedPermissions,
    preparedParams: IStateSymbolPermissionsQueryParams & {
      withExpired: boolean;
    },
  ): Promise<ISymbolTreeStructure[] | null> => {
    try {
      const { layer, ...restParams } = preparedParams;

      const result = await recursiveRequest<ISymbol>(
        SymbolPermissionsRepository.fetchSymbolsPermissions,
        {
          path: parentPath,
          ...restParams,
        },
        layer,
      );

      return result.reduce<ISymbolTreeStructure[]>(
        (acc, { permissions, ...instrument }) => {
          acc.push({
            icon: null,
            rowType: RowType.Instrument,
            ...DEFAULT_SYMBOLS_PERMISSIONS_SET,
            ...instrument,
            ...permissions,
            ...changedPermissions[instrument.id],
          });

          return acc;
        },
        [],
      );
    } catch (error) {
      return null;
    }
  };

  public async searchInstruments({
    search,
    limit = MAX_REQUEST_LIMIT,
    skip = 0,
    instruments = [],
    preparedParams,
    options,
  }: ISearchInstrumentsParams): Promise<ISymbolTreeStructure[] | null> {
    try {
      const { layer, ...restParams } = preparedParams;

      const { data } =
        await SymbolPermissionsRepository.fetchSymbolsPermissions(
          {
            search,
            limit,
            skip,
            ...restParams,
          },
          layer,
          options,
        );

      if (!data) {
        return null;
      }

      const { pagination, data: newInstruments } = data;

      const resultInstruments = [
        ...instruments,
        ...newInstruments.map<ISymbolTreeStructure>(
          ({ displayName, id, path, permissions, isExpired }) => ({
            icon: null,
            id,
            isExpired,
            displayName,
            rowType: RowType.Instrument,
            path,
            ...DEFAULT_SYMBOLS_PERMISSIONS_SET,
            ...permissions,
          }),
        ),
      ];

      if (pagination.total > skip + MAX_REQUEST_LIMIT) {
        return await this.searchInstruments({
          search,
          limit,
          skip: skip + limit,
          instruments: resultInstruments,
          preparedParams,
          options,
        });
      }

      return resultInstruments;
    } catch (error) {
      return null;
    }
  }

  public postPermissions = async (
    data: Partial<ISymbolPermissionsPost>[],
    type: FilterLayers,
    entity: string | null,
  ) => {
    try {
      await SymbolPermissionsRepository.postSymbolsTreeWithPermissions(
        data,
        type,
        entity,
      );

      sendNotification('Permissions successfully saved', 'success');

      return true;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return false;
    }
  };
}
