import { styled } from 'react-ui-kit-exante';

export const StyledWrapper = styled('div')(({ theme }) => ({
  '.TableFooter div': {
    background: theme?.color.bg.primary,
    color: theme.color.typo.primary,
  },
  'div[data-test-id="table__body--action-cell"] button:first-child': {
    display: 'none',
  },
}));
