import { ReactNode } from 'react';

import { StyledHeaderWrapper, StyledHeader } from './ClientPageTemplate.styled';

interface IClientPageTemplateProps {
  header: ReactNode;
  detailedClientSummary: ReactNode;
  tabManagement: ReactNode;
}

export const ClientPageTemplate = ({
  header,
  detailedClientSummary,
  tabManagement,
}: IClientPageTemplateProps) => {
  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{header}</StyledHeader>
        <div>{detailedClientSummary}</div>
      </StyledHeaderWrapper>

      <div>{tabManagement}</div>
    </>
  );
};
