import { styled } from 'react-ui-kit-exante';

export const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, min-content)',
  backgroundColor: theme?.color.bg.primary,
  padding: '0 24px 24px',
  gap: '32px',
  minHeight: '70px',
  alignItems: 'baseline',
  overflowX: 'auto',
}));

export const StyledLoader = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, min-content)',
  padding: '0 24px 24px',
  minHeight: '70px',
}));

export const StyledItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledTitle = styled('div')(({ theme }) => ({
  fontSize: '13px',
  color: theme?.color.typo.secondary,
  whiteSpace: 'nowrap',
}));

export const StyledValue = styled('div')(({ theme }) => ({
  fontSize: '20px',
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  color: theme?.color.typo.secondary,
}));
