import * as Yup from 'yup';

import { FormMode } from './types';

export const getValidationSchema = (
  mode: FormMode,
  existNames: Set<string | null>,
) => {
  return Yup.object({
    applyExecutionScheme: Yup.boolean().nullable(),
    autoconversionByDefault: Yup.boolean().required(
      'Autoconversion by default is required',
    ),
    availableCurrencies: Yup.array().of(Yup.string()).nullable(),
    calcInterestsNegativeFlag: Yup.boolean().nullable(),
    calcInterestsPositiveFlag: Yup.boolean().nullable(),
    calcOvernightsFlag: Yup.boolean().nullable(),
    company: Yup.string().required('Company is required'),
    convertCashInto: Yup.string().nullable(),
    defaultAccountType: Yup.string().nullable(),
    forceAutoconversionCurrencies: Yup.array().of(Yup.string()).nullable(),
    forceConvertCashInto: Yup.string().nullable(),
    isDefault: Yup.boolean().required('Is default is required'),
    lei: Yup.string()
      .nullable()
      .when(['underEMIR'], {
        is: true,
        then: Yup.string()
          .typeError('LEI is required when Under EMIR is true')
          .min(16, 'Must be at least 16 characters'),
        otherwise: Yup.string()
          .min(16, 'Must be at least 16 characters')
          .nullable(),
      }),
    name: Yup.mixed().test({
      name: 'legal-entity-name-validation',
      test(value, { path, createError }) {
        if (!value?.trim() && mode === FormMode.Add) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (existNames.has(value?.trim())) {
          return createError({
            message: 'This name already exists',
            path,
          });
        }

        return true;
      },
    }),
    order: Yup.number()
      .required('Order is required')
      .typeError('Should be a number')
      // check on smallint https://jira.exante.eu/browse/WBU-967
      .min(-32768, 'Order must be at least -32768')
      .max(32767, 'Order must be at most 32767'),
    underEMIR: Yup.boolean(),
    useExcessMarginFee: Yup.boolean().nullable(),
    useFreemoneyPremium: Yup.boolean().nullable(),
  });
};
