import List from '@mui/material/List';
import { FC } from 'react';
import Highlighter from 'react-highlight-words';

import { DefaultThemeProvider } from '../../../theme';
import SkeletonUISelectedList from '../SkeletonUISelectedList';

import { SelectedListItem } from './SelectedListItem';
import { IUISelectedListProps } from './types';

export const SelectedList: FC<IUISelectedListProps> = ({
  selected,
  warned,
  options,
  onListItemClick,
  searchValue,
  sx,
  isLoading,
}) => {
  return (
    <DefaultThemeProvider>
      {isLoading ? (
        <SkeletonUISelectedList />
      ) : (
        <List disablePadding sx={sx}>
          {options?.map((option) => {
            const highlightedText = (
              <Highlighter
                highlightClassName="searchHighlight"
                searchWords={[searchValue || '']}
                autoEscape
                textToHighlight={option.name}
              />
            );

            return (
              <SelectedListItem
                key={option.id}
                isSelected={selected === option.id}
                isWarned={warned === option.id}
                onClick={(event) => onListItemClick(option, option.id, event)}
                text={highlightedText}
              />
            );
          })}
        </List>
      )}
    </DefaultThemeProvider>
  );
};
