import { orderBy } from 'lodash';

import { EMPTY_ARRAY } from '~/constants';
import { CurrencyWithIcon } from '~/store/currencies/currencies.types';

import { capitalize } from '../capitalize';
import { sortAlphabetically } from '../sortAlphabetically';

interface ITransformDataToSelectOptions<T> {
  valueKey: keyof T;
  labelKey: keyof T;
  sort: boolean;
}

interface IOptions {
  capitalized: boolean;
  shouldSortAlphabetically: boolean;
}

export function transformVariantsToSelectOptions<T>(
  variants: T[] = [],
  options: Partial<IOptions> = {},
) {
  const { capitalized = false, shouldSortAlphabetically = false } = options;

  const items: string[] = shouldSortAlphabetically
    ? sortAlphabetically(variants.map((variant) => String(variant)))
    : variants.map((t) => String(t));

  return items.map((variant) => {
    return {
      label: capitalized ? capitalize(variant) : variant,
      value: variant,
    };
  });
}

export function transformCurrenciesToSelectOptionsWithIcon(
  variants: CurrencyWithIcon[],
  capitalized = false,
) {
  return variants.map(({ currency, icon }) => ({
    label: capitalized ? capitalize(currency) : currency,
    value: currency,
    icon,
  }));
}

export function transformCurrenciesToSelectOptionsWithImgIcon(
  variants: CurrencyWithIcon[],
  capitalized = false,
) {
  return variants.map(({ currency, icon }) => ({
    label: capitalized ? capitalize(currency) : currency,
    value: currency,
    icon: <img alt="icon" height="100%" src={icon} width="100%" />,
  }));
}

export function transformDataToSelectOptions<T>(
  data: T[],
  { valueKey, labelKey, sort = false }: ITransformDataToSelectOptions<T>,
) {
  if (!data) {
    return EMPTY_ARRAY;
  }

  let result = data.map((item) => ({
    label: String(item[labelKey]),
    value: item[valueKey],
  }));

  if (sort) {
    result = orderBy(result, [(item) => item.label.toLowerCase()], ['asc']);
  }

  return result;
}
