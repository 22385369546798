import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { accountPageContext } from '../../../pages/AccountPage/context';
import { useUsersPermission } from '../hooks';
import { validationSchema } from '../schema';
import { IFormData } from '../types';

import { UsersPermissionForm } from './UsersPermissionForm';

interface IUsersPermissionFormProviderProps {
  accountId: string;
  initial: IFormData;
}

export const UsersPermissionFormProvider = ({
  accountId,
  initial,
}: IUsersPermissionFormProviderProps) => {
  const formInstance = useForm({
    defaultValues: initial,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: {
      dirtyFields,
      isDirty,
      defaultValues: { users: defaultUsers = [] } = {},
    },
  } = formInstance;

  const { updateUsersPermission } = useUsersPermission({
    accountId,
    initialUsersPermission: defaultUsers,
  });

  const { setDirtyTabs } = useContext(accountPageContext);

  useEffect(() => {
    setDirtyTabs('usersPermission', isDirty);
  }, [isDirty, setDirtyTabs]);

  const handleFormSubmit = async (data: IFormData) => {
    return updateUsersPermission({
      newData: data,
      allData: getValues(),
      updatedFields: dirtyFields,
      onSuccess: reset,
    });
  };

  return (
    <FormProvider {...formInstance}>
      <UsersPermissionForm
        accountId={accountId}
        onSubmit={handleSubmit(handleFormSubmit)}
        isDirty={Boolean(dirtyFields.users?.length || dirtyFields.status)}
      />
    </FormProvider>
  );
};
