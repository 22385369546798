import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';

import { TInterestRatesMap } from '~/api/interestRates/types';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { ICurrency } from '~/types/currencies';

import { getColumnSetByLayerName } from '../../columns';
import { InterestRatesTable } from '../../components';
import { prepareDataForTable } from '../../helpers';
import { useRatesByGroup, useGroups, useClientFilters } from '../../hooks';
import { ELayer, TSetRefetch } from '../../types';

interface IGroupRatesProps {
  group: number | null;
  currencies: ICurrency[];
  defaultRates: TInterestRatesMap;
  loadingDefault: boolean;
  setRefetchCurrentTabQuery: TSetRefetch;
  onRefresh: () => Promise<void>;
}

export const GroupRatesLayer = ({
  group,
  currencies,
  defaultRates,
  loadingDefault,
  setRefetchCurrentTabQuery,
  onRefresh,
}: IGroupRatesProps) => {
  const { filters, onRemove, removeAllFilters } = useClientFilters();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'interests-rates-group-rates-list',
  );

  const {
    ratesByGroup,
    onUpdate: updateRatesByGroup,
    loading: loadingRatesByGroup,
    refetch,
  } = useRatesByGroup({
    group,
    defaultRates,
    preCall: setStartHandleTime,
  });

  const { refetch: refetchGroups } = useGroups();

  useEffect(() => {
    setRefetchCurrentTabQuery({
      refetch: async () => Promise.all([refetch(), refetchGroups()]),
      isLoading: loadingRatesByGroup,
    });
  }, [setRefetchCurrentTabQuery, refetch, loadingRatesByGroup, refetchGroups]);

  const preparedGroupTableData = useMemo(
    () => prepareDataForTable(currencies, ratesByGroup),
    [currencies, ratesByGroup],
  );

  const prevPreparedGroupTableData = usePrevious(preparedGroupTableData);

  const tableIsLoading = !group || loadingDefault || loadingRatesByGroup;

  useEffect(() => {
    if (
      group &&
      preparedGroupTableData &&
      !isEqual(preparedGroupTableData, prevPreparedGroupTableData)
    ) {
      logHandleTime();
    }
  }, [
    group,
    preparedGroupTableData,
    prevPreparedGroupTableData,
    logHandleTime,
  ]);

  const columns = getColumnSetByLayerName(ELayer.Groups, { onRemove });

  return (
    <InterestRatesTable
      loading={tableIsLoading}
      onUpdate={updateRatesByGroup}
      data={preparedGroupTableData}
      columns={columns}
      filters={filters}
      removeAllFilters={removeAllFilters}
      onRefresh={onRefresh}
    />
  );
};
