type HandleChangeFunction = (event: { target: { value?: unknown } }) => void;

interface QueryToUpdate {
  id: string;
  name: string;
  handleChange: HandleChangeFunction;
}

const queriesToUpdate: Map<
  string,
  Map<string, HandleChangeFunction>
> = new Map();

export function addQueryToUpdate({
  id,
  name,
  handleChange,
}: QueryToUpdate): void {
  if (queriesToUpdate.has(id)) {
    const queriesMap = queriesToUpdate.get(id);
    queriesMap?.set(name, handleChange);
  } else {
    const newQueryMap: Map<string, HandleChangeFunction> = new Map();
    newQueryMap.set(name, handleChange);
    queriesToUpdate.set(id, newQueryMap);
  }
}

export function getQueryToUpdate(
  id: string,
  name: string,
): HandleChangeFunction | null {
  if (queriesToUpdate.has(id)) {
    const queriesMap = queriesToUpdate.get(id);
    return queriesMap?.get(name) || null;
  }
  return null;
}

export function getQueriesToUpdate(): Map<
  string,
  Map<string, HandleChangeFunction>
> {
  return queriesToUpdate;
}
