import { IAccountSummary } from '~/types/accounts';

export const initialAccountSummary: Omit<IAccountSummary, 'positions'> = {
  metrics: {
    totalOrderMargin: null,
    totalCash: null,
    totalBlockedMargin: null,
    requiredMargin: null,
    percentageTotalDailyPnl: null,
    netAssetValue: null,
    marginUtilization: null,
    freeMoney: null,
    convertedDailyPnl: null,
  },
  closeSessionTime: null,
};
