import { Loader as UiKitLoader } from 'react-ui-kit-exante';

import LoaderStyles from './Loader.module.css';

export const Loader = () => {
  return (
    <div className={LoaderStyles.Container}>
      <UiKitLoader size="l" />
    </div>
  );
};
