import { isString, isNumber } from 'lodash';

export const toFixed = (value: string | number, digitsAfter = 2) => {
  if (!isString(value) && !isNumber(value)) {
    return '';
  }
  const floatNumber = isString(value) ? Number.parseFloat(value) : value;
  const fixed = Number(floatNumber.toFixed(digitsAfter));

  return String(fixed);
};
