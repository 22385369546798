import { Link } from 'react-router-dom';
import { styled } from 'react-ui-kit-exante';

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.color.typo.primary,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const MinMaxStyled = styled('div')(({ theme }) => ({
  color: theme.color.typo.secondary,
}));
