import { isEqual } from 'lodash';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useLazyGetCommissionsTreeQuery } from '~/api';
import {
  selectFiltersLayer,
  selectFiltersQueryParams,
  selectSearchIsActive,
} from '~/store/commissions';
import {
  FilterLayers,
  IGetResolveCommissionsTreeParams,
} from '~/types/commissions';

export function useCommissionTree() {
  const [getCommissionsTree, { isLoading, isFetching }] =
    useLazyGetCommissionsTreeQuery();

  const queryParams = useSelector(selectFiltersQueryParams);
  const prevParams = useRef<IGetResolveCommissionsTreeParams | false>(false);
  const searchIsActive = useSelector(selectSearchIsActive);
  const layer = useSelector(selectFiltersLayer);

  const fetchCommissionsTree = useCallback(
    async ({ params = {}, forceRefetch = false } = {}) => {
      if (
        !forceRefetch &&
        (!queryParams ||
          searchIsActive ||
          isEqual(queryParams, prevParams.current) ||
          (layer === FilterLayers.Accounts &&
            queryParams.layer === FilterLayers.Groups))
      ) {
        return;
      }
      prevParams.current = queryParams;

      if (queryParams) {
        getCommissionsTree(
          {
            ...queryParams,
            ...params,
          },
          !forceRefetch,
        );
      }
    },
    [getCommissionsTree, layer, queryParams, searchIsActive],
  );

  return {
    fetchCommissionsTree,
    isLoadingTree: isLoading || isFetching,
    getCommissionsTree,
  };
}
