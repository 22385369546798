import { IFooter } from '~/types/table';

import { CashSum, ICashSumProps } from '../../components/CashSum';

export const calculateColumnValuesSum =
  <Data extends { [Key in keyof Data]: Data[Key] }>(
    key: keyof Data,
    cashSumProps?: Omit<ICashSumProps, 'values'>,
  ) =>
  // eslint-disable-next-line react/display-name
  ({ data, column: { preFilteredRows } }: IFooter<Data>) => {
    if (preFilteredRows.length === 1) {
      return null;
    }
    return (
      <CashSum {...cashSumProps} values={data.map((item: Data) => item[key])} />
    );
  };
