import { isEqual } from 'lodash';
import { useMemo, useEffect } from 'react';

import { TInterestRatesMap } from '~/api/interestRates/types';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { ICurrency } from '~/types/currencies';
import { TGroupId } from '~/types/interestRates/interestRates';

import { getColumnSetByLayerName } from '../../columns';
import { InterestRatesTable } from '../../components';
import { prepareDataForTable } from '../../helpers';
import {
  useRatesByAccount,
  useRatesByGroup,
  useClientFilters,
} from '../../hooks';
import { ELayer, TBrandedAccountId, TSetRefetch } from '../../types';

interface IAccountRatesProps {
  groupId: number | null;
  account: TBrandedAccountId;
  currencies: ICurrency[];
  defaultRates: TInterestRatesMap;
  loadingDefault: boolean;
  setRefetchCurrentTabQuery: TSetRefetch;
  onRefresh: () => Promise<void>;
}

export const AccountRatesLayer = ({
  groupId,
  account,
  currencies,
  defaultRates,
  loadingDefault,
  setRefetchCurrentTabQuery,
  onRefresh,
}: IAccountRatesProps) => {
  const { filters, onRemove, removeAllFilters } = useClientFilters();
  const { logHandleTime } = useLogHandleTime('interests-rates-accounts-list');

  const {
    ratesByGroup,
    loading: loadingRatesByGroup,
    refetch: refetchRatesByGroup,
  } = useRatesByGroup({
    group: groupId as TGroupId,
    defaultRates,
  });

  const {
    ratesByAccount,
    onUpdate: updateRatesByAccount,
    loading: loadingRatesByAccount,
    refetch: refetchRatesByAccount,
  } = useRatesByAccount({
    accountId: account,
    defaultRates,
    ratesByGroup: groupId ? ratesByGroup : null,
    currencies,
  });

  useEffect(() => {
    setRefetchCurrentTabQuery({
      refetch: async () => {
        if (groupId) {
          await refetchRatesByGroup();
        }
        await refetchRatesByAccount();
      },
      isLoading: loadingRatesByAccount || loadingRatesByGroup,
    });
  }, [
    setRefetchCurrentTabQuery,
    refetchRatesByAccount,
    loadingRatesByAccount,
    loadingRatesByGroup,
    refetchRatesByGroup,
    groupId,
  ]);

  const preparedAccountTableData = useMemo(
    () => prepareDataForTable(currencies, ratesByAccount),
    [currencies, ratesByAccount],
  );
  const prevPreparedAccountTableData = usePrevious(preparedAccountTableData);

  const tableIsLoading = Boolean(
    !account ||
      loadingDefault ||
      loadingRatesByAccount ||
      (groupId && loadingRatesByGroup),
  );

  useEffect(() => {
    if (
      preparedAccountTableData &&
      !isEqual(prevPreparedAccountTableData, preparedAccountTableData)
    ) {
      logHandleTime();
    }
  }, [prevPreparedAccountTableData, preparedAccountTableData, logHandleTime]);

  const columns = getColumnSetByLayerName(ELayer.Accounts, { onRemove });

  return (
    <InterestRatesTable
      loading={tableIsLoading}
      onUpdate={updateRatesByAccount}
      data={preparedAccountTableData}
      columns={columns}
      filters={filters}
      removeAllFilters={removeAllFilters}
      onRefresh={onRefresh}
    />
  );
};
