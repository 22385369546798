import {
  useGetCashConversionSettingsQuery,
  useGetCommissionSettingsQuery,
  useGetCoreAccountQuery,
  useGetInternalSettingsQuery,
  useGetRegularPaymentsSettingsQuery,
  useGetRoutingSettingsQuery,
} from '~/api';

const isRejected = (status: string) => status === 'rejected';

export function useRejectedRequestFor(accountId: string) {
  const { status: cashConversionSettingsStatus } =
    useGetCashConversionSettingsQuery({ accountId });
  const { status: storeAccountStatus } = useGetCoreAccountQuery(
    { accountId },
    { skip: !accountId },
  );
  const { status: commissionSettingsStatus } = useGetCommissionSettingsQuery({
    accountId,
  });
  const { status: internalSettingsStatus } = useGetInternalSettingsQuery({
    accountId,
  });
  const { status: regularPaymentsSettingsStatus } =
    useGetRegularPaymentsSettingsQuery({ accountId });
  const { status: routingSettingsStatus } = useGetRoutingSettingsQuery({
    accountId,
  });

  return {
    account: isRejected(storeAccountStatus),
    cashConversionSettings: isRejected(cashConversionSettingsStatus),
    commissionSettings: isRejected(commissionSettingsStatus),
    internalSettings: isRejected(internalSettingsStatus),
    regularPaymentsSettings: isRejected(regularPaymentsSettingsStatus),
    routingSettings: isRejected(routingSettingsStatus),
  };
}
