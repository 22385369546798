import cn from 'classnames';
import { useCallback, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Panel, IconButton, Modal, useModal } from 'react-ui-kit-exante';

import { CONFIRM_DELETE_MESSAGE, EMPTY_ARRAY } from '~/constants';
import { usePickUserPermissions } from '~/hooks';
import { clientsService } from '~/resources';
import { NoDataWrapper } from '~/shared/components';
import { IOption } from '~/types/form';

import { FormMultiSelectContainer, FormSelectAsyncContainer } from '../../form';
import { IDataAvailabilityState } from '../types';

import UserClientsStyles from './UserClients.module.css';

interface IClientsProps {
  dataAvailabilityState: IDataAvailabilityState;
  options: Array<IOption>;
}

export const UserClients = ({
  dataAvailabilityState,
  options,
}: IClientsProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'userClients',
  });
  const userName = useWatch({ control, name: 'user.username' });

  const confirmModal = useModal();
  const confirmIndex = useRef<number | null>(null);

  const userPermissions = usePickUserPermissions(['User client', 'User info']);
  const hasUserInfoWriteAccess =
    userPermissions['User info'].write && userPermissions['User client'].write;

  const searchClients = useMemo(
    () => clientsService.autoCompleteSearchClients.bind(null, 0),
    [],
  );

  const handleAddRow = useCallback(
    () =>
      append({
        userId: userName,
        clientId: null,
        rights: EMPTY_ARRAY,
      }),
    [append, userName],
  );
  const handleDeleteClick = (index: number) => () => {
    confirmIndex.current = index;
    confirmModal.onOpen();
  };

  const handleRemoveRow = () => {
    if (confirmIndex.current === null) {
      return;
    }

    remove(confirmIndex.current);
    confirmModal.onClose();
  };

  if (!userPermissions['User client'].read) {
    return null;
  }

  return (
    <Panel
      className={cn(UserClientsStyles.Container, {
        Disabled: !hasUserInfoWriteAccess,
      })}
      title="Clients"
    >
      <NoDataWrapper hasData={dataAvailabilityState.hasUserClientsData}>
        {fields.map((field, index) => (
          <div key={field.id} className={UserClientsStyles.Item}>
            <FormSelectAsyncContainer
              label="Client id"
              name={`userClients.${index}.clientId`}
              fetchData={searchClients}
            />
            <FormMultiSelectContainer
              label="Rights"
              name={`userClients.${index}.rights`}
              options={options}
            />

            <div className={UserClientsStyles.DeleteWrapper}>
              <IconButton
                iconName="DeleteIcon"
                iconColor="radical"
                iconSize={24}
                onClick={handleDeleteClick(index)}
              />
            </div>
          </div>
        ))}

        <div className={UserClientsStyles.AddWrapper}>
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            onClick={handleAddRow}
          />
        </div>

        <Modal
          isOpened={confirmModal.isOpened}
          onClose={confirmModal.onClose}
          title="Are you sure?"
          confirmButton={{
            handleConfirm: handleRemoveRow,
          }}
          keepMounted={false}
        >
          <div>{CONFIRM_DELETE_MESSAGE}</div>
        </Modal>
      </NoDataWrapper>
    </Panel>
  );
};
