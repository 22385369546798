import { Dispatch } from '@reduxjs/toolkit';
import { IRowExpand } from 'react-ui-kit-exante';

import { expandedRowsUpdate } from '~/store/commissions';
import { ICommissionTree } from '~/types/commissions';

export function closeRowAnsSubRows(
  row: IRowExpand<ICommissionTree>,
  dispatch: Dispatch,
) {
  row.toggleRowExpanded?.();

  dispatch(expandedRowsUpdate([row.id, false]));

  if (row.subRows.length) {
    row.subRows.forEach((subRow) => {
      if (subRow.isExpanded) {
        closeRowAnsSubRows(subRow, dispatch);
      }
    });
  }
}
