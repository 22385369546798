import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

export function formatPercentage(
  value?: string | number | null,
  digits = 2,
): string {
  const numberedValue = Number(value);

  if (!value || Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  const percentage = (numberedValue * 100).toFixed(digits).replace(/\.00$/, '');

  return `${percentage}%`;
}
