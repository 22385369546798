import { get, keys, cloneDeep, set } from 'lodash';
import { Notification } from 'react-ui-kit-exante';

import { EMPTY_OBJECT } from '~/constants';

import { ITreeNode } from '../types';

import { getIsValidUpdate } from './getIsValidUpdate';
import { toExpandedTreePath } from './toExpandedTreePath';

interface IPrepareQueriesToUpdate {
  (props: {
    requestsToUpdate: Map<string, Map<string, Record<string, string | number>>>;
    cache: ITreeNode[] | null;
    originalCache: ITreeNode[] | null;
    setTree: React.Dispatch<React.SetStateAction<ITreeNode[]>>;
    tree: ITreeNode[];
  }): {
    id: string;
    data: Record<string, unknown>;
  }[];
}

const getRiskArraysData = (
  cacheData: Record<string, string>,
): { riskarray: string[] } => {
  const riskarray = Array(16)
    .fill(null)
    .map((_, i) => {
      return cacheData[`risk-array-${i}`] || '0.0';
    });

  return {
    riskarray,
  };
};

export const prepareQueriesToUpdate: IPrepareQueriesToUpdate = ({
  requestsToUpdate,
  cache,
  originalCache,
  setTree,
  tree,
}) => {
  const updatedItems = [...requestsToUpdate];
  const requests = updatedItems.flatMap(([symbolId, updatedData]) => {
    const updatedObject: { id: string; data: Record<string, unknown> } = {
      id: symbolId,
      data: {},
    };
    const queriesToUpdate = [...updatedData];
    const [query] = queriesToUpdate;
    const [, queryData] = query;
    const { path } = queryData;
    const riskArraysHaveBeenChanged = queriesToUpdate.some(([fieldName]) =>
      fieldName.startsWith('risk-array'),
    );

    if (riskArraysHaveBeenChanged) {
      const { isValid, errorMsg } = getIsValidUpdate(
        get(tree, toExpandedTreePath(path)),
      );

      if (!isValid && errorMsg) {
        Notification.warning(errorMsg);
      }
    }

    queriesToUpdate.forEach(([, fieldData]) => {
      const dataFromCache = get(cache, toExpandedTreePath(fieldData.path));
      const newValue = fieldData.value;
      dataFromCache[fieldData.fieldName] = newValue;

      if (dataFromCache.isAutoCalculated) {
        const cacheData = get(cache, toExpandedTreePath(fieldData.path));
        keys(cacheData).forEach((key) => {
          if (key.startsWith('risk-array') && cache) {
            const fullPathToField = `${toExpandedTreePath(
              fieldData.path,
            )}.${key}`;

            const originalValue = get(originalCache, fullPathToField);

            set(cache, fullPathToField, originalValue);
          }
        });
        if (cache) {
          setTree(cloneDeep(cache));
        }
      }

      updatedObject.data = {
        ...updatedObject.data,
        symbolId: dataFromCache.id,
        usePortfolioMargin: Boolean(dataFromCache.usePortfolioMargin),
        isAutoCalculated: Boolean(dataFromCache.isAutoCalculated),
        delta: dataFromCache.delta,
        ...(dataFromCache.isAutoCalculated
          ? EMPTY_OBJECT
          : getRiskArraysData(dataFromCache)),
      };
    });

    if (!updatedObject) {
      return [];
    }
    return updatedObject;
  });

  return requests;
};
