import { useState } from 'react';
import { IconButton, Loader, Notification } from 'react-ui-kit-exante';

import { apiRequest } from '~/shared/utils/apiRequest';

import { StyledDownloadButton } from './DownloadButton.styled';

const statuses = {
  pending: 'pending',
  loading: 'loading',
  failed: 'failed',
  completed: 'completed',
};

export const DownloadButton = ({
  link,
  fileName,
}: {
  link: string;
  fileName: string;
}) => {
  const [status, setStatus] = useState(statuses.pending);

  const download = async () => {
    setStatus(statuses.loading);

    try {
      const { data: blob } = await apiRequest<Blob>(
        {
          url: link,
        },
        { responseType: 'blob' },
      );

      const linkElement = document.createElement('a');
      linkElement.href = window.URL.createObjectURL(blob);
      linkElement.setAttribute('download', fileName);
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
      setStatus(statuses.completed);
    } catch (err) {
      Notification.error({ title: String(err) });
      setStatus(statuses.failed);
    }
  };

  return (
    <StyledDownloadButton>
      {status === statuses.loading ? (
        <Loader />
      ) : (
        <IconButton iconName="DownloadIcon" onClick={download} />
      )}
    </StyledDownloadButton>
  );
};
