import { useGetCoreUserQuery, useGetCurrentUserAccessRightsQuery } from '~/api';

export function useCurrentUserHasAllBrandingPermission() {
  const { data: { userId } = {} } = useGetCurrentUserAccessRightsQuery();
  const {
    data: currentUser,
    isLoading,
    isFetching,
  } = useGetCoreUserQuery(
    { id: Number(userId) },
    {
      skip: !userId,
    },
  );

  return {
    currentUser,
    currentUserHasAllBrandingPermission:
      currentUser?.info.brandingPermission === 'ALL',
    isLoadingUser: isLoading || isFetching,
  };
}
