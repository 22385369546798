/* eslint-disable react/no-unstable-nested-components */
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { interestRatesApi } from '~/api';
import { EMPTY_ARRAY } from '~/constants';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { SaveButton } from '~/shared/components';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { Switch } from '~/shared/components/Switch';
import { selectCurrencies } from '~/store/currencies';
import { EStatus } from '~/types/api';
import { TGroupId } from '~/types/interestRates/interestRates';

import { AccountSelector } from './components/AccountSelector';
import { GroupsSelector } from './components/GroupsSelector/GroupsSelector';
import { InterestPageLayer } from './constants';
import {
  DefaultRatesLayer,
  GroupRatesLayer,
  AccountRatesLayer,
} from './containers';
import { prepareDataForTable } from './helpers';
import {
  useAccountGroup,
  useGroups,
  useInterestRates,
  useRatesByGroup,
  useViewOptions,
} from './hooks';
import { IQueryState } from './types';

const tabIndexes = Object.keys(InterestPageLayer);

export const InterestRatesPage = () => {
  const { account, activeTab, setAccount, setActiveTab } = useViewOptions();
  const [refetchCurrentTabQuery, setRefetchCurrentTabQuery] =
    useState<IQueryState>({
      refetch: () => Promise.resolve(),
      isLoading: false,
    });
  const { data: currencies = EMPTY_ARRAY, status } =
    useSelector(selectCurrencies);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'interests-rates-default-list',
  );

  const {
    data: defaultRates,
    loading: loadingDefaultRates,
    onUpdate: updateDefaultRates,
    refetch: refetchDefaultRates,
  } = useInterestRates(setStartHandleTime);
  const previousDefaultRates = usePrevious(defaultRates);
  const {
    accountData: { groupId },
    accountDataLoading,
    onChangeAccountGroup,
  } = useAccountGroup(account, setStartHandleTime);

  const loading = loadingDefaultRates || status === EStatus.Loading;

  useEffect(() => {
    if (defaultRates && !isEqual(previousDefaultRates, defaultRates)) {
      logHandleTime();
    }
  }, [logHandleTime, defaultRates, previousDefaultRates]);

  const handleRefresh = useCallback(async () => {
    const isDefaultTab = activeTab === InterestPageLayer.Default;

    if (isDefaultTab) {
      await refetchDefaultRates();
    } else {
      await refetchDefaultRates();
      await refetchCurrentTabQuery.refetch();
    }
  }, [activeTab, refetchDefaultRates, refetchCurrentTabQuery]);

  const [group, setGroup] = useState<TGroupId | null>(null);

  useEffect(() => {
    if (activeTab === InterestPageLayer.Accounts) {
      setGroup(groupId as TGroupId);
    }
  }, [activeTab, groupId]);

  const [createInterestsGroup] =
    interestRatesApi.useCreateInterestsGroupMutation();

  const getGroupId = () => {
    if (activeTab === InterestPageLayer.Groups) {
      return group;
    }
    if (activeTab === InterestPageLayer.Accounts) {
      return groupId;
    }
    return null;
  };

  const {
    ratesByGroup,
    loading: loadingRatesByGroup,
    refetch,
  } = useRatesByGroup({
    group: getGroupId(),
    defaultRates,
    preCall: setStartHandleTime,
  });

  const { groups, refetch: refetchGroups } = useGroups();

  const [removeInterestsGroup] =
    interestRatesApi.useRemoveInterestsGroupMutation();

  useEffect(() => {
    setRefetchCurrentTabQuery({
      refetch: async () => Promise.all([refetch(), refetchGroups()]),
      isLoading: loadingRatesByGroup,
    });
  }, [setRefetchCurrentTabQuery, refetch, loadingRatesByGroup, refetchGroups]);

  const setInitialGroup = useCallback(() => {
    if (groups && groups.length && activeTab === InterestPageLayer.Groups) {
      const { id } = groups[0];

      setGroup(id);
    }
  }, [activeTab, groups]);

  useEffect(() => {
    setInitialGroup();
  }, [setInitialGroup]);

  const preparedGroupTableData = useMemo(
    () => prepareDataForTable(currencies, ratesByGroup),
    [currencies, ratesByGroup],
  );

  const prevPreparedGroupTableData = usePrevious(preparedGroupTableData);

  const onDeleteGroup = useCallback(async () => {
    if (!group) {
      return;
    }

    await removeInterestsGroup({ id: group });
  }, [group, removeInterestsGroup]);

  const createNewGroup = useCallback(
    async (groupName: string) => {
      const response = await createInterestsGroup({
        payload: {
          name: groupName,
        },
      });
      if ('data' in response) {
        setGroup(get(response, 'data.id', null));
      }

      return response;
    },
    [createInterestsGroup],
  );

  useEffect(() => {
    if (
      group &&
      preparedGroupTableData &&
      !isEqual(preparedGroupTableData, prevPreparedGroupTableData)
    ) {
      logHandleTime();
    }
  }, [
    group,
    preparedGroupTableData,
    prevPreparedGroupTableData,
    logHandleTime,
  ]);

  if (!activeTab) {
    return null;
  }

  return (
    <LayersLayout
      title="Interest Rates"
      initialTab={activeTab}
      tabs={tabIndexes}
      pageControls={
        activeTab === InterestPageLayer.Default ? null : (
          <Switch
            condition={[
              activeTab === InterestPageLayer.Groups,
              activeTab === InterestPageLayer.Accounts,
            ]}
          >
            {[
              <GroupsSelector
                key={0}
                group={group}
                setGroup={setGroup}
                currencies={currencies}
                defaultRates={defaultRates}
                setRefetchCurrentTabQuery={setRefetchCurrentTabQuery}
                layer={activeTab}
              />,
              <>
                <AccountSelector
                  groupId={groupId || null}
                  account={account}
                  setAccount={setAccount}
                  key={1}
                  currencies={currencies}
                  defaultRates={defaultRates}
                  setRefetchCurrentTabQuery={setRefetchCurrentTabQuery}
                />
                <GroupsSelector
                  key={0}
                  group={group}
                  setGroup={setGroup}
                  currencies={currencies}
                  defaultRates={defaultRates}
                  setRefetchCurrentTabQuery={setRefetchCurrentTabQuery}
                  loading={accountDataLoading}
                  layer={activeTab}
                />
                <SaveButton
                  label="Save Group"
                  type="submit"
                  disabled={
                    activeTab !== InterestPageLayer.Accounts ||
                    group === groupId
                  }
                  onClick={() => onChangeAccountGroup(group)}
                />
              </>,
            ]}
          </Switch>
        )
      }
      table={(activeTabIndex) => (
        <Switch
          condition={[
            activeTabIndex === 0,
            activeTabIndex === 1,
            activeTabIndex === 2,
          ]}
        >
          {[
            <DefaultRatesLayer
              key={0}
              currencies={currencies}
              defaultRates={defaultRates}
              loadingDefault={loading}
              updateDefaultRates={updateDefaultRates}
              onRefresh={handleRefresh}
            />,
            <GroupRatesLayer
              key={1}
              group={group}
              currencies={currencies}
              defaultRates={defaultRates}
              loadingDefault={loading}
              setRefetchCurrentTabQuery={setRefetchCurrentTabQuery}
              onRefresh={handleRefresh}
            />,
            <AccountRatesLayer
              groupId={groupId}
              account={account}
              key={2}
              currencies={currencies}
              defaultRates={defaultRates}
              loadingDefault={false}
              setRefetchCurrentTabQuery={setRefetchCurrentTabQuery}
              onRefresh={handleRefresh}
            />,
          ]}
        </Switch>
      )}
      onChangeLayer={(layer) => {
        setActiveTab(layer as InterestPageLayer);
        if (layer !== InterestPageLayer.Accounts) {
          setAccount('');
        }
      }}
      searchParams={{
        hidden: true,
      }}
      onDeleteGroup={onDeleteGroup}
      onAddGroup={createNewGroup}
      loading={false}
    />
  );
};
