export type TTabsIds =
  | 'summary'
  | 'margin'
  | 'settings'
  | 'activity'
  | 'cards'
  | 'permissions'
  | 'usersPermission';

export const tabsIds: TTabsIds[] = [
  'summary',
  'margin',
  'settings',
  'activity',
  'cards',
  'usersPermission',
];
