import { clone } from 'lodash';

import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function permissionsByPathsUpdate(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.PERMISSIONS_BY_PATHS_UPDATE],
) {
  return {
    ...state,
    permissionsByPath: {
      ...state.permissionsByPath,
      ...clone(payload),
    },
  };
}
