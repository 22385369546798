import { useCallback, useContext } from 'react';

import { useLazyGetUserGroupSettingsQuery } from '~/api';

import { ActionTypes, SymbolPermissionsContext } from '../constants';
import { TFilterChangeHandle } from '../types';

export const useUserGroup = (filterChangeHandle: TFilterChangeHandle) => {
  const [, dispatch] = useContext(SymbolPermissionsContext);
  const [getUserGroupSettings, { isLoading: loading }] =
    useLazyGetUserGroupSettingsQuery();

  const onUserSelect = useCallback(
    async (selectedUserId: string) => {
      const response = await getUserGroupSettings({ userName: selectedUserId });

      if (response.data) {
        dispatch({
          type: ActionTypes.FILTERS_RELATED_GROUP_SET,
          payload: response.data.permissionsSetId,
        });

        filterChangeHandle();
      }
    },
    [dispatch, filterChangeHandle, getUserGroupSettings],
  );

  return {
    loadingUserSet: loading,
    onUserSelect,
  };
};
