import { useCallback } from 'react';

import {
  useLazySearchByAssetsQuery,
  useLazySearchByAccountsQuery,
  useLazySearchByUsersQuery,
  useLazySearchBySymbolsQuery,
} from '~/api';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  options: [],
  canceled: true,
};

export function useAutocomplete(
  type: 'assets' | 'accounts' | 'users' | 'symbols',
  options?: Record<string, boolean>,
) {
  const [searchByAccounts] = useLazySearchByAccountsQuery();
  const [searchByAssets] = useLazySearchByAssetsQuery();
  const [searchBySymbols] = useLazySearchBySymbolsQuery();
  const [searchByUsers] = useLazySearchByUsersQuery();

  const searchFn = {
    accounts: searchByAccounts,
    assets: searchByAssets,
    symbols: searchBySymbols,
    users: searchByUsers,
  }[type];

  const getAutocompleteFn = useCallback(
    (skip: number | null = 0) => {
      return async (search: string) => {
        const response = await searchFn({
          search,
          skip,
          ...((type === 'accounts' || type === 'users') && {
            archived: Boolean(options?.archived),
          }),
        });

        if ('error' in response || !response.data) {
          return defaultAutocompleteState;
        }

        return {
          ...response.data,
          fetchData: getAutocompleteFn(
            typeof response?.data?.nextSkip === 'number'
              ? response.data.nextSkip
              : null,
          ),
        };
      };
    },
    [options?.archived, type, searchFn],
  );

  return getAutocompleteFn;
}
