import { FormLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormLabelProps } from '@mui/material/FormLabel';

import { blockNonNativeProps } from '../../../helpers';
import { KitTheme, styled } from '../../../theme';

import { StyledFormControlLabelProps } from './types';

const getLabelColor = (theme: KitTheme, checked: boolean, warning: boolean) => {
  if (warning) {
    return theme.color.input.warning;
  }

  if (checked) {
    return theme.color.typo.primary;
  }

  return theme.color.typo.promo;
};

export const FormControlLabelStyled = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />,
  { shouldForwardProp: blockNonNativeProps('warning') },
)<StyledFormControlLabelProps>(({ theme, checked, warning }) => ({
  marginLeft: 0,
  '.MuiFormControlLabel-label': {
    color: getLabelColor(theme, checked, !!warning),
    fontFamily: theme.font.main,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    marginRight: '20px',
  },
}));

export const Root = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const FormLabelStyled = styled((props: FormLabelProps) => (
  <FormLabel {...props} />
))(({ theme }) => ({
  lineHeight: '16px',
  color: theme?.color.typo.secondary,
  fontFamily: theme?.font.main,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
}));
