import { isRecord } from '~/pages/InterestRatesPage/helpers';

interface IConfig {
  exclude: string[];
}

export function deepOmitFalsyValues(
  obj: Record<string, unknown>,
  config?: IConfig,
): Record<string, unknown> {
  const excludedFields = new Set(config?.exclude);

  return Object.entries(obj).reduce((acc, item) => {
    const [key, value] = item;

    if (excludedFields.has(key)) {
      return {
        ...acc,
        [key]: value,
      };
    }

    if (!value) {
      return acc;
    }

    if (isRecord(value)) {
      const newValue = deepOmitFalsyValues(value);

      if (!Object.keys(newValue).length) {
        return acc;
      }

      return {
        ...acc,
        [key]: newValue,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}
