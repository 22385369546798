import { useCallback, useState } from 'react';

import { MirroringRuleOptions } from '~/pages/MirroringRulePage/constants';

export const useActiveOption = (isTransactionsActive: boolean) => {
  const [activeOption, setActiveOption] = useState<MirroringRuleOptions>(
    isTransactionsActive
      ? MirroringRuleOptions.Transactions
      : MirroringRuleOptions.Categories,
  );

  const handleActiveOptionChange = useCallback(
    (value: MirroringRuleOptions) => setActiveOption(value),
    [],
  );

  return {
    activeOption,
    handleActiveOptionChange,
  };
};
