import { useCallback } from 'react';

import {
  useLazyGetGroupSettingsQuery,
  useLazyGetLimitGroupsQuery,
} from '~/api';
import { transformLimitGroupsToOptions } from '~/api/limits/helpers';
import { useAutocomplete } from '~/hooks';
import { TabAccount } from '~/types/treeStructure';

export function useTabAccount(): TabAccount {
  const [getLimitGroupsOptions] = useLazyGetLimitGroupsQuery();

  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const fetchAccountsAutocompleteFn = getAccountsAutocompleteFn();

  const [fetchGroupSettings] = useLazyGetGroupSettingsQuery();

  const getGroupOptions = useCallback(async () => {
    const { data } = await getLimitGroupsOptions();

    if (!data) {
      return [];
    }

    const { data: options } = transformLimitGroupsToOptions({
      data,
    });

    return options;
  }, [getLimitGroupsOptions]);

  const getAccountInfo = useCallback(
    async (accountId = '') => {
      let result = await fetchAccountsAutocompleteFn(accountId);

      // if probably accountId may have been incorrectly specified in the URL
      if (!result.options.length) {
        result = await fetchAccountsAutocompleteFn('');
      }

      const [defaultAccount] = result.options;

      const { data } = await fetchGroupSettings({
        accountId: defaultAccount.value,
      });

      return { accountId: defaultAccount.value, groupId: data?.limitsSetId };
    },
    [fetchGroupSettings, fetchAccountsAutocompleteFn],
  );

  return {
    getGroupOptions,
    getAccounts: fetchAccountsAutocompleteFn,
    getAccountInfo,
  };
}
