import { useContext } from 'react';

import { TabManagement as TabManagementContainer } from '~/containers/TabManagement';
import { TSetRefreshFn } from '~/types/refetch';
import { ITab } from '~/types/tabs';

import { accountPageContext } from '../context';
import { TTabsIds } from '../context/constants';

import { IAccountTabComponentProps } from './types';

interface ITabManagementProps {
  tabs: ITab<TTabsIds, IAccountTabComponentProps>[];
  accountId: string;
  setRefetch: TSetRefreshFn;
}

export const TabManagement = ({
  tabs,
  accountId,
  setRefetch,
}: ITabManagementProps) => {
  const { dirtyTabs } = useContext(accountPageContext);

  return (
    <TabManagementContainer
      componentProps={{ accountId, setRefetch }}
      dirtyTabs={dirtyTabs}
      tabs={tabs}
    />
  );
};
