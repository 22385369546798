import { useGetAccountStatusTypesQuery } from '~/api/types/types.api';
import { transformAccountStatusesToOptions } from '~/shared/utils';

export const useAccountStatuses = () => {
  const { data: accountStatusTypes } = useGetAccountStatusTypesQuery();

  const accountStatusesOptions = transformAccountStatusesToOptions(
    accountStatusTypes?.values,
  );

  return {
    accountStatusTypes: accountStatusTypes?.values || [],
    defaultAccountStatus: accountStatusTypes?.default || '',
    accountStatusesOptions,
  };
};
