import { boolean, mixed, object, string } from 'yup';

import { oneOf } from '~/shared/utils';
import { IOption } from '~/types/form';

import { transformToArrayOfStrings } from './helpers';
import { validateClientUsers } from './validateClientUsers';

export const getValidationSchema = ({
  clientTypes,
  clientProviders,
  incorporationTypes,
}: Record<string, Array<IOption>>) => {
  return object({
    client: object({
      name: string().required('Client name is required'),
      owner: object().nullable(),
      lastModificationDate: string().nullable(),
      archived: boolean().nullable(),
      withdrawalRestriction: boolean().nullable(),
      documentsPending: boolean().nullable(),
      clientInfo: object({
        email: string().nullable(),
        primaryPhone: string().nullable(),
        secondaryPhone: string().nullable(),
        internalInfo: string().nullable(),
      }).nullable(),
      tradeCommissionChargeAccountId: string().nullable(),
      counterpartyId: string()
        .oneOf([...clientProviders.map((item) => item.value), null])
        .nullable(),
      exanteType: oneOf(
        transformToArrayOfStrings(clientTypes, 'value'),
        'PROFESSIONAL',
        true,
      ),
      incorporationType: oneOf(
        transformToArrayOfStrings(incorporationTypes, 'value'),
        'INDIVIDUAL JOINT',
        true,
      ),
    }),
    clientUsers: mixed().test({
      name: 'client-users-validation',
      test: validateClientUsers,
    }),
  });
};
