import { useEffect } from 'react';

import { useLogHandleTime } from '~/hooks';
import type { IAccountTabComponentProps } from '~/pages/AccountPage/TabManagement/types';
import { useRefreshActiveTab } from '~/pages/AccountPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';
import { useGetCardsQuery } from '~/store/account';
import { ICard } from '~/types/cardsManagement';
import { IUseQueryReturn } from '~/types/rtkQuery';

import { CardsManagementContainer } from './CardsManagementContainer';

export const CardsManagementWithData = ({
  accountId,
}: IAccountTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'account-cards-entry-screen',
  );
  setStartHandleTime();

  const {
    data = [],
    isFetching,
    refetch,
  } = useGetCardsQuery<IUseQueryReturn<ICard[]>>(accountId);

  const { setRefreshFn } = useRefreshActiveTab();

  useEffect(() => {
    if (!isFetching) {
      logHandleTime();
    }
  }, [isFetching, data, logHandleTime]);

  useEffect(() => {
    setRefreshFn('cards', {
      refetch,
      isLoading: isFetching,
    });
  }, [setRefreshFn, refetch, isFetching]);

  if (isFetching) {
    return <StaticCenteredLoader withBackground height="340px" />;
  }

  return <CardsManagementContainer cards={data} accountId={accountId} />;
};
