/**
 * Finds the index of the last non-unique string in an array of string.
 *
 * @param {string[]} strings - An array of strings.
 * @return {number} The index of the last non-unique string, or -1 if all strings are unique.
 *
 * @example
 * // Returns 3
 * findLastNonUniqueStringIndex(['Alice', 'Bob', 'Alice', 'Charlie', 'Bob']);
 *
 * @example
 * // Returns -1 (all names are unique)
 * findLastNonUniqueStringIndex(['Alice', 'Bob', 'Charlie']);
 */
export function findLastNonUniqueStringIndex(strings: string[]) {
  let lastIndex = -1;
  const stringCounts = new Map();

  strings.forEach((string, index) => {
    if (stringCounts.has(string)) {
      const data = stringCounts.get(string);
      data.count += 1;
      data.lastIndex = index; // Update the last index of the duplicate string
      lastIndex = index; // Update the global last index of a duplicate
    } else {
      stringCounts.set(string, { count: 1, lastIndex: index });
    }
  });

  const hasDuplicates = Array.from(stringCounts.values()).some(
    (data) => data.count > 1,
  );
  return hasDuplicates ? lastIndex : -1;
}
