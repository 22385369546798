import { pick } from 'lodash';

import { ITrade } from '~/types/trades';

import { expectedFields } from './trades.api.constants';

export function tradeMapper(trade: ITrade): ITrade {
  return pick(trade, expectedFields);
}

export function tradesMappers(trades: ITrade[]): ITrade[] {
  return trades.map((item) => item);
}
