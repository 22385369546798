import { isObject } from 'lodash';

import { formatDateToYYYYMMDD } from '~/shared/utils';
import { ITradePostRequest } from '~/types/trades';

import { ignoredBrokerFields } from './trades.api.constants';

function getInitialStateForManualTrade(
  accountId: string,
  shouldSendThroughWorkflowApi?: boolean,
) {
  const initialStateForManualTrade = {
    source: 'wbo',
  };

  if (shouldSendThroughWorkflowApi) {
    return {
      ...initialStateForManualTrade,
      accountId,
      extraData: {
        '4EyesCheck': true,
      },
    };
  }

  return initialStateForManualTrade;
}

export function getDataForPostManualTrade(tradeFormData: ITradePostRequest) {
  const {
    accountId,
    use4EyesCheck: shouldSendThroughWorkflowApi,
    ...restTradeFormData
  } = tradeFormData;

  const initialStateForManualTrade = getInitialStateForManualTrade(
    accountId,
    shouldSendThroughWorkflowApi,
  );

  return Object.entries(restTradeFormData).reduce((acc, item) => {
    const [key, value] = item;

    if (!value && typeof value !== 'number') {
      return acc;
    }

    if (key === 'valueDate') {
      return {
        ...acc,
        valueDate: formatDateToYYYYMMDD(new Date(String(value))),
      };
    }

    if (key === 'counterparty') {
      return {
        ...acc,
        counterparty: String(value).split(':')[0],
      };
    }

    if (key === 'extraData') {
      return {
        ...acc,
        extraData: {
          ...('extraData' in acc ? acc.extraData : {}),
          ...(isObject(value) ? value : {}),
        },
      };
    }

    if (!value && ignoredBrokerFields.includes(key)) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, initialStateForManualTrade);
}
