/**
 * Custom realization of lodash.set
 * @param object
 * @param path
 * @param value
 */
export function deepSet(
  object: Record<string | number | symbol, any>,
  path: string,
  value: unknown,
) {
  const pathWithoutSquareBrackets = path.replace(/\[(\w+)\]/g, '.$1');

  const pathArr = pathWithoutSquareBrackets.split('.');
  const lastKey = pathArr.pop();
  const lastObj = pathArr.reduce((acc, key) => acc[key], object);

  if (lastKey !== undefined) {
    lastObj[lastKey] = value;
  }
}
