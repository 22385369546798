import { IColumn } from 'react-ui-kit-exante';

import { IPosition, ILockedAsset } from '~/types/accounts';
import { IOption } from '~/types/form';

import { POSITIONS_CONFIG, REQUIRED_COLUMNS } from './constants';
import { INormalizedPositions } from './types';

/**
 * Groups array of object by POSITIONS_CONFIG.types.
 * And sets these groups in right POSITION_CONFIG.order
 */
export function normalizePositions(
  positions: IPosition[],
): INormalizedPositions[] {
  const configKeys = Object.keys(POSITIONS_CONFIG);
  const groupedPositions = configKeys.reduce((acc, key) => {
    const { id, title, types, order, iconName } = POSITIONS_CONFIG[key];
    const group = positions.filter((pos) => types.includes(pos.type));
    const position = {
      id,
      title,
      data: group,
      iconName,
    };

    acc.splice(order, 1, position);

    return acc;
  }, new Array(configKeys.length));

  return groupedPositions.filter((item) => Boolean(item.data.length));
}

export function omitRequiredColumns(
  columns: IColumn<Record<string, unknown>>[],
) {
  return columns.filter(
    (column) => !REQUIRED_COLUMNS.includes(column.accessor),
  );
}

export function setTableIdsPrefix(ids: string[], prefix?: string) {
  if (!prefix) {
    return ids;
  }

  return ids.map((id) => `${prefix}-${id}`);
}

export function setAccordionLsPrefix(lsKey: string, prefix?: string) {
  if (!prefix) {
    return lsKey;
  }

  return `${prefix}-${lsKey}`;
}

/**
 * Transforms locked assets to options for the Select component,
 * with each new object consisting of 'value' (the uuid of the original object),
 * and 'label' (concatenation of 'amount' and 'reason' from the original object).
 * The transformed array is sorted first by 'amount', then by 'label'.
 *
 * @param {Array<Object>} data - The original locked assets array.
 * @returns {Array<Object>} The transformed array of options.
 */
export function transformLockedAssetsToUuidOptions(
  data: ILockedAsset[],
): IOption[] {
  return data
    .map((item) => {
      return {
        value: item.uuid,
        label: item.reason
          ? `${item.amount}: ${item.reason}`
          : String(item.amount),
        amount: item.amount, // Add 'amount' for the sorting process
      };
    })
    .sort((a, b) => {
      // Sort first by 'amount'
      const amountDifference = a.amount - b.amount;

      // If 'amount' is the same, sort by 'label'
      if (amountDifference === 0) {
        return a.label.localeCompare(b.label);
      }

      return amountDifference;
    })
    .map((item) => {
      // Remove 'amount' from the final objects
      return {
        value: item.value,
        label: item.label,
      };
    });
}
