import { IColumn } from 'react-ui-kit-exante';

import { TUserAccessRightsRow, IUserAccessRights } from '~/types/users';

import { UserAccessRightsCell } from './components';

export const ACCESS_RIGHTS_TABLE: IColumn<IUserAccessRights>[] = [
  {
    Header: 'PERMISSION',
    accessor: 'operation',
    width: Number.MAX_SAFE_INTEGER,
  },
  {
    Header: 'VIEW',
    id: 'read',
    maxWidth: 80,
    accessor: (data: IUserAccessRights) => data.actions.read,
    Cell: (props: IColumn<TUserAccessRightsRow>) => (
      <UserAccessRightsCell operationType="read" props={props} />
    ),
  },
  {
    Header: 'EDIT',
    id: 'write',
    maxWidth: 80,
    accessor: (data: IUserAccessRights) => data.actions.write,
    Cell: (props: IColumn<TUserAccessRightsRow>) => (
      <UserAccessRightsCell operationType="write" props={props} />
    ),
  },
];
