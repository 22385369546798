import { TYPES_LEGAL_ENTITY_ENDPOINT } from '../endpoints';

import { legalEntitiesApi } from './legalEntitiesApi';

export const { useDeleteLegalEntityMutation } =
  legalEntitiesApi.injectEndpoints({
    endpoints: (builder) => ({
      deleteLegalEntity: builder.mutation({
        query: (legalEntityName: string) => ({
          url: `${TYPES_LEGAL_ENTITY_ENDPOINT}/${legalEntityName}`,
          method: 'DELETE',
        }),
      }),
    }),
  });
