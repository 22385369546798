export const LockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 5.5H3C2.72386 5.5 2.5 5.72386 2.5 6V13C2.5 13.2761 2.72386 13.5 3 13.5H13C13.2761 13.5 13.5 13.2761 13.5 13V6C13.5 5.72386 13.2761 5.5 13 5.5Z"
        stroke="#6D8288"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.25C8.41421 10.25 8.75 9.91421 8.75 9.5C8.75 9.08579 8.41421 8.75 8 8.75C7.58579 8.75 7.25 9.08579 7.25 9.5C7.25 9.91421 7.58579 10.25 8 10.25Z"
        fill="#6D8288"
      />
      <path
        d="M5.5 5.5V3.5C5.5 2.83696 5.76339 2.20107 6.23223 1.73223C6.70107 1.26339 7.33696 1 8 1C8.66304 1 9.29893 1.26339 9.76777 1.73223C10.2366 2.20107 10.5 2.83696 10.5 3.5V5.5"
        stroke="#6D8288"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
