import { mixed, object } from 'yup';

import { createArraysValidation } from '../UserGeneralSettingsContainer/validateUsers';

export const getValidationSchema = () =>
  object({
    permissions: mixed().test({
      name: 'user-clients-validation',
      test: createArraysValidation('accountId'),
    }),
  });
