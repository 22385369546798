import { useCallback, useContext } from 'react';
import { Radio } from 'react-ui-kit-exante';

import { IUserToken } from '~/types/users';

import { ActionTypes, PasswordManagementContext } from '../../constants';

interface ITokenRadioProps {
  checked: boolean;
  token: IUserToken;
}

export const TokenRadio = ({ checked, token }: ITokenRadioProps) => {
  const [, dispatch] = useContext(PasswordManagementContext);
  const onClickHandler = useCallback(() => {
    dispatch({
      type: ActionTypes.UPDATE_AUTH_TOKEN,
      payload: {
        token,
        checked,
      },
    });
  }, [checked, dispatch, token]);

  const disabled = token.type === 'password' && checked;

  return (
    <span>
      {disabled ? (
        <Radio checked={checked} />
      ) : (
        <Radio checked={checked} onClick={onClickHandler} />
      )}
    </span>
  );
};
