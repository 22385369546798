import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

interface IGetAdditionalFilters {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
}

export function getAdditionalFilters({
  onFilter,
  onRemove,
}: IGetAdditionalFilters): TAdditionalFilter<Record<string, unknown>>[] {
  return [
    {
      Header: 'Ids only',
      accessor: 'idsOnly',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
  ];
}

export const predefinedFilters = {
  userType: 'internal',
};

export const getDefaultFilters = () => ({ id: '' });
