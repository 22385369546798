import { Arrow } from './Arrow';
import NavigationArrowsStyles from './NavigationArrows.module.css';
import { NAVIGATION_DISABLED_TEXT } from './constants';

interface INavigationArrowsProps {
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  onPreviousClick(): void;
  onNextClick(): void;
}

export const NavigationArrows = ({
  onPreviousClick,
  onNextClick,
  previousDisabled,
  nextDisabled,
}: INavigationArrowsProps) => {
  return (
    <div>
      <Arrow
        className={NavigationArrowsStyles.Button}
        direction="left"
        onClick={onPreviousClick}
        disabled={previousDisabled}
        title={previousDisabled ? NAVIGATION_DISABLED_TEXT : undefined}
      />
      <Arrow
        className={NavigationArrowsStyles.Button}
        direction="right"
        onClick={onNextClick}
        disabled={nextDisabled}
        title={nextDisabled ? NAVIGATION_DISABLED_TEXT : undefined}
      />
    </div>
  );
};
