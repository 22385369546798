import { useContext } from 'react';

import { IUserToken } from '~/types/users';

import { PasswordManagementContext } from '../../constants';
import { useCanChangeTokens } from '../../hooks';
import { IStatePasswordManager } from '../../types';
import { TokenDeleteIconContainer } from '../TokenDeleteIcon';
import { TokenEditIcon } from '../TokenEditIcon';
import { TokenRadio } from '../TokenRadio';

import { StyledList, StyledItem, StyledName } from './TokenListSection.styled';

interface ITokenListSectionProps {
  handleEditClick: (tokenName: string) => void;
  tokenType: Exclude<keyof IStatePasswordManager['data'], 'authFlow'>;
}

export const TokenListSection = ({
  handleEditClick,
  tokenType,
}: ITokenListSectionProps) => {
  const [state] = useContext(PasswordManagementContext);
  const { data } = state;
  const tokens = data[tokenType];
  const authTokens =
    tokenType === 'password'
      ? data.authFlow.firstStep
      : data.authFlow.secondSteps;

  const canChangeTokens = useCanChangeTokens();

  const canEdit = tokenType === 'password';

  return (
    <StyledList>
      {tokens.map((token: IUserToken) => (
        <StyledItem key={token.name}>
          {canChangeTokens ? (
            <>
              <TokenRadio checked={authTokens.has(token.name)} token={token} />

              <StyledName>{token.name}</StyledName>

              {canEdit && (
                <TokenEditIcon
                  handleEditClick={handleEditClick}
                  tokenName={token.name}
                />
              )}

              <TokenDeleteIconContainer
                activeToken={authTokens.has(token.name)}
                token={token}
              />
            </>
          ) : (
            <StyledName>{token.name}</StyledName>
          )}
        </StyledItem>
      ))}
    </StyledList>
  );
};
