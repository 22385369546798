import { Button as ButtonUI } from '@mui/material';

import { blockNonNativeProps } from '../../helpers';
import { styled } from '../../theme';

import { StyledButtonProps } from './types';

export const StyledButton = styled(ButtonUI, {
  shouldForwardProp: blockNonNativeProps([
    'color',
    'size',
    'loading',
    'hasText',
    'textColor',
  ]),
})<StyledButtonProps>(
  ({ disabled, hasText, size, color, loading, theme, textColor }) => {
    const transparentColor = theme.color.typo[textColor || 'secondary'];

    return {
      position: 'relative',
      fontFamily: theme.font.main,
      fontWeight: 500,
      textTransform: 'capitalize',
      color: theme.color.button.primary.typo?.default,
      border: `2px solid transparent`,
      '& .MuiButton-startIcon': {
        ...(!hasText && { margin: 0 }),
        ...(hasText && { marginRight: size === 'small' ? '4px' : '8px' }),
      },
      ...(size === 'small' && {
        height: '32px',
        minWidth: '32px',
        padding: '8px 12px',
        fontSize: '12px',
        lineHeight: '16px',
        borderRadius: '3px',
      }),
      ...(size === 'medium' && {
        height: '48px',
        minWidth: '48px',
        padding: hasText ? '8px 24px' : '12px',
        fontSize: '15px',
        lineHeight: '32px',
        borderRadius: '4px',
      }),
      ...(color === 'primary' && {
        background: theme.color.button.primary.bg?.default,
        color: theme.color.button.primary.typo?.default,
        '&:hover': {
          color: theme.color.button.primary.typo?.hover,
          background: theme.color.button.primary.bg?.hover,
        },
        '&:active, &:focus-visible': {
          background: theme.color.button.primary.bg?.focus,
          color: theme.color.button.primary.typo?.focus,
        },
        '&.Mui-disabled': {
          opacity: disabled && !loading ? 0.4 : 1,
          color: loading
            ? theme.color.button.primary.bg?.focus
            : theme.color.button.primary.typo?.disabled,
        },
        ...(loading && {
          background: theme.color.bg.primary,
          borderColor: theme.color.button.primary.bg?.default,
          color: theme.color.button.primary.bg?.focus,
          '.spinner-icon': {
            color: theme.color.button.primary.bg?.focus,
          },
        }),
      }),
      ...(color === 'secondary' && {
        background: theme.color.bg.primary,
        color: theme.color.button.secondary.typo?.default,
        borderColor: theme.color.button.secondary.bg?.default,
        '&:hover': {
          background: theme.color.bg.primary,
          color: theme.color.button.secondary.typo?.hover,
          borderColor: theme.color.button.secondary.bg?.hover,
        },
        '&:active, &:focus-visible': {
          background: theme.color.bg.primary,
          color: theme.color.button.secondary.typo?.focus,
          borderColor: theme.color.button.secondary.bg?.focus,
        },
        '&.Mui-disabled': {
          background: theme.color.bg.primary,
          opacity: disabled && !loading ? 0.4 : 1,
          color: theme.color.button.secondary.typo?.disabled,
          borderColor: theme.color.button.secondary.bg?.default,
        },
        ...(loading && {
          background: theme.color.bg.primary,
          color: theme.color.button.secondary.typo?.focus,
          borderColor: theme.color.button.secondary.bg?.focus,
          '.spinner-icon': {
            color: theme.color.button.secondary.bg?.focus,
          },
        }),
      }),
      // @ts-expect-error -- intended color. Probably mistake in type IUIButtonProps
      ...(color === 'transparent' && {
        background: 'transparent',
        backgroundColor: 'transparent',
        padding: '0',
        color: transparentColor,
        fontSize: '13px',
        lineHeight: '24px',
        height: '24px',
        minWidth: 'unset',
        '&:hover': {
          background: 'transparent',
          backgroundColor: 'transparent',
          color:
            textColor === 'action'
              ? theme.color.button.primary.bg?.hover
              : theme.color.typo.promo,
        },
        '&:active, &:focus-visible': {
          background: 'transparent',
          color:
            textColor === 'action'
              ? theme.color.button.primary.bg?.focus
              : theme.color.typo.promo,
        },
        '&.Mui-disabled': {
          background: 'transparent',
          opacity: disabled && !loading ? 0.4 : 1,
          color: theme.color.button.secondary.typo?.disabled,
        },
      }),
      // @ts-expect-error -- intended color. Probably mistake in type IUIButtonProps
      ...(color === 'red' && {
        background: theme.color.button.radical.bg?.default,
        '&:hover': {
          background: theme.color.button.radical.bg?.hover,
          color: theme.color.button.radical.typo?.hover,
        },
        '&:active, &:focus-visible': {
          background: theme.color.button.radical.bg?.focus,
          color: theme.color.button.radical.typo?.focus,
        },
        '&.Mui-disabled': {
          opacity: disabled && !loading ? 0.4 : 1,
          color: loading
            ? theme.color.button.radical.bg?.focus
            : theme.color.button.radical.typo?.disabled,
        },
        ...(loading && {
          background: theme.color.bg.primary,
          borderColor: theme.color.button.radical.bg?.default,
          color: theme.color.button.radical.bg?.focus,
          '.spinner-icon': {
            color: theme.color.button.radical.bg?.focus,
          },
        }),
      }),
    };
  },
) as React.ComponentType<StyledButtonProps>;
