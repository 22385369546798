import { apiRequest, paramsTransformer } from '~/shared/utils';
import { TParams } from '~/types/api';
import { IMirroringRule, TMirroringRulePayload } from '~/types/mirroringRules';

import { MIRRORING_RULES_PARAMS_MAPPER } from './constants';
import {
  FETCH_MIRRORING_RULES,
  FETCH_MIRRORING_RULES_PROXY,
  getMirroringRulesEndpoint,
} from './endpoints';

export class MirroringRulesRepository {
  public static fetchMirroringRules(params: TParams) {
    const preparedParams = paramsTransformer({
      params,
      mapper: MIRRORING_RULES_PARAMS_MAPPER,
    });

    return apiRequest({
      params: { ...preparedParams },
      method: 'GET',
      url: FETCH_MIRRORING_RULES,
    });
  }

  public static fetchMirroringRule(id: number, params: TParams) {
    return apiRequest<IMirroringRule>({
      params,
      method: 'GET',
      url: getMirroringRulesEndpoint(id),
    });
  }

  public static updateMirroringRule(id: number, data: IMirroringRule) {
    return apiRequest<IMirroringRule>({
      data,
      method: 'POST',
      url: getMirroringRulesEndpoint(id),
    });
  }

  public static createMirroringRule(data: TMirroringRulePayload) {
    return apiRequest<IMirroringRule[]>({
      data,
      method: 'POST',
      url: FETCH_MIRRORING_RULES_PROXY,
    });
  }

  public static deleteMirroringRule(id: number) {
    return apiRequest({
      method: 'DELETE',
      url: getMirroringRulesEndpoint(id),
    });
  }
}
