import { usePickUserPermissions } from '~/hooks';
import { TPossibleAccessRights } from '~/types/users';

import { IRoutingTagsFormProps } from '../types';

export function useHasPermissionForWrite({
  accountId,
  name,
}: Partial<IRoutingTagsFormProps>): TPossibleAccessRights {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: routing settings',
    'User info',
    'User info: routing tags',
  ]);

  if (accountId) {
    return {
      write:
        userPermissions['Account info'].write &&
        userPermissions['Account info: routing settings'].write,
      read: userPermissions['Account info: routing settings'].read,
    };
  }

  if (name?.startsWith('user')) {
    return {
      write:
        userPermissions['User info'].write &&
        userPermissions['User info: routing tags'].write,
      read: userPermissions['User info: routing tags'].read,
    };
  }

  return {
    write: false,
    read: false,
  };
}
