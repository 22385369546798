import { AxiosResponse } from 'axios';

import { MAX_REQUEST_LIMIT } from '~/constants';
import { IDataWithPagination, TParams } from '~/types/api';
import { FilterLayers } from '~/types/symbolPermissions';

// TODO remove recursiveRequest and make recursive request in symbol permissions like in overnights
export async function recursiveRequest<T>(
  request: (
    params: TParams,
    layer: FilterLayers,
  ) => Promise<AxiosResponse<IDataWithPagination<T[]>>>,
  params: TParams,
  layer: FilterLayers,
  skip = 0,
): Promise<T[]> {
  const { data } = await request(
    {
      ...params,
      limit: MAX_REQUEST_LIMIT,
      skip,
    },
    layer,
  );

  return [
    ...data.data,
    ...(data.pagination.total > skip + MAX_REQUEST_LIMIT
      ? await recursiveRequest<T>(
          request,
          params,
          layer,
          skip + MAX_REQUEST_LIMIT,
        )
      : []),
  ];
}
