import { Checkbox, IColumn } from 'react-ui-kit-exante';

import { FilterLayers, ISymbolTreeStructure } from '~/types/symbolPermissions';

interface IPublicCellProps {
  column: IColumn<ISymbolTreeStructure>;
  value: boolean;
}

export const PublicCell = ({ column, value }: IPublicCellProps) => {
  const { layer } = column.getProps();
  const disabled = layer !== FilterLayers.Default;

  return <Checkbox checked={value} disabled={disabled} />;
};
