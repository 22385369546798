import { isExpired } from '../../../../shared/utils/dates';

import { AssetIcon } from './AssetIcon';
import InstrumentCellStyles from './InstrumentCell.module.css';

interface IInstrumentCellProps {
  asset: string;
  iconName: string;
  expirationDate?: string | null;
}

export const InstrumentCell = ({
  asset,
  iconName,
  expirationDate,
}: IInstrumentCellProps) => {
  const expired = isExpired(expirationDate);

  return (
    <div className={InstrumentCellStyles.Wrapper}>
      <div className={InstrumentCellStyles.Icon}>
        <AssetIcon
          asset={asset}
          iconName={iconName}
          isExpired={expired}
          isFullIcon
        />
      </div>

      {asset}
    </div>
  );
};
