import { ReactNode } from 'react';

import { FullHeightPanel } from '~/shared/components';

import { StyledContainer, StyledHeader } from './NewMirroringRule.styled';

interface NewMirroringRuleProps {
  header: ReactNode;
  fields: ReactNode;
}

export const NewMirroringRule = ({ header, fields }: NewMirroringRuleProps) => {
  return (
    <>
      <StyledHeader>{header}</StyledHeader>
      <FullHeightPanel>
        <StyledContainer>{fields}</StyledContainer>
      </FullHeightPanel>
    </>
  );
};
