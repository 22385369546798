import { DEFAULT_CURRENCY } from '~/constants';
import { ICurrency } from '~/containers/AccountSummaryContainer/types';

export const getInitialCurrency = (strValue: string | null): string => {
  try {
    const parsed: ICurrency = strValue ? JSON.parse(strValue) : {};

    if (typeof parsed === 'string') {
      return getInitialCurrency(parsed);
    }

    return parsed.id || DEFAULT_CURRENCY;
  } catch {
    return DEFAULT_CURRENCY;
  }
};
