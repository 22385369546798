import { get } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { FilterLayers } from '~/types/symbolPermissions';

import {
  ActionTypes,
  BLOCKED_MESSAGE,
  SymbolPermissionsContext,
} from '../constants';
import { TFilterChangeHandle } from '../types';

interface IUseFilters {
  resetDataOnFilterChange: TFilterChangeHandle;
  setBlockTableMessage: Dispatch<SetStateAction<string>>;
}

export function useFilters({
  resetDataOnFilterChange,
  setBlockTableMessage,
}: IUseFilters) {
  const [effectiveSubLayer, setEffectiveSubLayer] = useState<
    FilterLayers.Accounts | FilterLayers.Users | null
  >(null);

  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const [, setSearchParams] = useSearchParams();
  const {
    filters: {
      select: { layer, account, user },
    },
  } = state;

  const needReloadData = useRef(false);

  const onChangeLayerHandler = useCallback(
    (value: FilterLayers) => {
      dispatch({
        type: ActionTypes.FILTERS_LAYER_SET,
        payload: value,
      });

      resetDataOnFilterChange();

      setEffectiveSubLayer(null);

      setBlockTableMessage(get(BLOCKED_MESSAGE, value, ''));
    },
    [dispatch, resetDataOnFilterChange, setBlockTableMessage],
  );

  const onChangeAccountAutocompleteHandler = useCallback(
    (value: string) => {
      dispatch({
        type: ActionTypes.FILTERS_ACCOUNT_SET,
        payload: value,
      });

      if (layer === FilterLayers.Effective) {
        if (value) {
          setEffectiveSubLayer(FilterLayers.Accounts);
        } else {
          setEffectiveSubLayer(null);
          dispatch({
            type: ActionTypes.FILTERS_USER_SET,
            payload: '',
          });
        }
      } else {
        setBlockTableMessage('');
      }
    },
    [layer, dispatch, setBlockTableMessage],
  );

  const onChangeAccountSelectHandler = useCallback(
    (value: string) => {
      dispatch({
        type: ActionTypes.FILTERS_ACCOUNT_SET,
        payload: value,
      });

      setBlockTableMessage('');
    },
    [dispatch, setBlockTableMessage],
  );

  const onChangeUserAutocompleteHandler = useCallback(
    async (value: string) => {
      dispatch({
        type: ActionTypes.FILTERS_USER_SET,
        payload: value,
      });

      if (layer === FilterLayers.Effective) {
        if (value) {
          setEffectiveSubLayer(FilterLayers.Users);
        } else {
          setEffectiveSubLayer(null);
          dispatch({
            type: ActionTypes.FILTERS_ACCOUNT_SET,
            payload: '',
          });
        }
      } else {
        setBlockTableMessage('');
      }
    },
    [layer, dispatch, setBlockTableMessage],
  );

  const onChangeUserSelectHandler = useCallback(
    (value: string) => {
      dispatch({
        type: ActionTypes.FILTERS_USER_SET,
        payload: value,
      });

      setSearchParams((params) => {
        if (value) {
          params.set('user', value);
        } else {
          params.delete('user');
        }

        return params;
      });

      setBlockTableMessage('');
    },
    [dispatch, setBlockTableMessage, setSearchParams],
  );

  const onChangeGroupHandler = useCallback(
    (value: number | null) => {
      dispatch({
        type: ActionTypes.FILTERS_GROUP_SET,
        payload: value,
      });
      setBlockTableMessage('');

      if (layer !== FilterLayers.Default && (account || user)) {
        needReloadData.current = true;
      } else {
        resetDataOnFilterChange();
      }
    },
    [
      account,
      dispatch,
      layer,
      resetDataOnFilterChange,
      setBlockTableMessage,
      user,
    ],
  );

  const onChangeWithExpiredHandler = useCallback(
    (checked: boolean) => {
      dispatch({
        type: ActionTypes.FILTERS_WITH_EXPIRED_SET,
        payload: checked,
      });
    },
    [dispatch],
  );

  return {
    effectiveSubLayer,
    needReloadData,
    onChangeLayerHandler,
    onChangeAccountAutocompleteHandler,
    onChangeAccountSelectHandler,
    onChangeUserAutocompleteHandler,
    onChangeUserSelectHandler,
    onChangeGroupHandler,
    onChangeWithExpiredHandler,
  };
}
