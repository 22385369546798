import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';
import axiosRetry from 'axios-retry';

import { getAccessTokenFromCookies, getSessionIdFromCookie } from '../session';

import { getApiDomain } from './getApiDomain';
import { getDefaultHeaders, paramsSerializer } from './helpers';
import { IOptionsArguments } from './types';

export interface IRequestArguments {
  url: string;
  method?: Method;
  headers?: AxiosRequestHeaders;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => error?.response?.status === 500,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiRequest<T = any>(
  args: IRequestArguments,
  options?: Partial<IOptionsArguments>,
): Promise<AxiosResponse<T>> {
  const { url: originalUrl, method = 'GET', data, headers, params } = args;

  const baseUrl = `https://${getApiDomain('BO')}`;
  const fullApiUrl = /^(https?:\/\/)/.test(originalUrl)
    ? originalUrl
    : `${baseUrl}${originalUrl}`;
  const sessionId = getSessionIdFromCookie();

  const config: AxiosRequestConfig = {
    url: fullApiUrl,
    method,
    data: method !== 'GET' ? data : null,
    headers:
      headers || getDefaultHeaders(sessionId, getAccessTokenFromCookies()),
    params,
    paramsSerializer,
    ...options,
  };

  return axios(config);
}
