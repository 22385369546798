import { IColumn } from 'react-ui-kit-exante';

import { ILimitTree } from '~/types/limits';

export function updateSubRows(
  row: ILimitTree,
  column: IColumn<ILimitTree>,
  value: boolean | number,
) {
  if (row.subRows) {
    row.subRows.forEach((subRow) => {
      // row with defined override don't should be updated from parent node https://jira.exante.eu/browse/WBU-922
      if (!subRow.override) {
        subRow[column] = value;
      }

      updateSubRows(subRow, column, value);
    });
  }
}
