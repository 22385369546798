interface IWarningLiteProps {
  className?: string;
}

export const WarningLite = ({ className }: IWarningLiteProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className={className}
    >
      <circle
        cx="7"
        cy="7"
        r="6.5"
        stroke="currentColor"
        transform="rotate(-180 7 7)"
      />
      <path fill="currentColor" d="M8 5H10V7H8z" transform="rotate(-180 8 5)" />
      <path
        fill="currentColor"
        d="M8 11H10V16H8z"
        transform="rotate(-180 8 11)"
      />
    </svg>
  );
};
