import { createSlice } from '@reduxjs/toolkit';
import { omit, get } from 'lodash';

import { expiredInstrumentActions } from '~/api';
import type { TApplicationState } from '~/types/store';

export const expiredInstrumentsSlice = createSlice({
  name: 'expiredInstruments',
  initialState: {
    requestsToUpdate: {},
  },
  reducers: {
    updateFields: (state, { payload }) => {
      const { id, key, value, type } = payload;

      if (key === 'action' && value === expiredInstrumentActions.doNothing) {
        state.requestsToUpdate = omit(state.requestsToUpdate, id);
      } else {
        state.requestsToUpdate = {
          ...state.requestsToUpdate,
          [id]: {
            ...get(state.requestsToUpdate, id),
            takeCommission: false,
            [key]: value,
            type,
          },
        };
      }
    },
    resetFields: (state) => {
      state.requestsToUpdate = {};
    },
  },
});

export const selectRequestsToUpdate = (state: TApplicationState) => {
  return state.expiredInstruments.requestsToUpdate;
};

export const { updateFields, resetFields } = expiredInstrumentsSlice.actions;
