import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TCloseButtonProps = Omit<IconButtonProps, 'iconName'>;

export const CloseButton = ({
  iconColor = 'action',
  ...props
}: TCloseButtonProps) => {
  return (
    <IconButton
      {...props}
      iconName="CloseIcon"
      iconColor={iconColor}
      label=""
      type="button"
      iconSize={24}
    />
  );
};
