import { IChangeCurrencyDetail } from '~/containers/AccountSummaryContainer/types';

export const isChangeCurrencyEvent = (
  event: Event,
): event is CustomEvent<IChangeCurrencyDetail> => {
  if ('detail' in event) {
    const { detail } = event as CustomEvent;
    return typeof detail.getCurrentCurrency === 'function';
  }
  return false;
};
