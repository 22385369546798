import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal, Notification } from 'react-ui-kit-exante';

import { useDeleteLegalEntityMutation } from '~/api';
import { LEGAL_ENTITIES_PATH } from '~/routes';

export function useDelete(name: string | undefined) {
  const navigate = useNavigate();
  const modal = useModal();
  const [deleteLegalEntityMutation] = useDeleteLegalEntityMutation();

  const closeHandle = useCallback(() => {
    navigate(LEGAL_ENTITIES_PATH, {
      state: {
        previousPath: window.location.href,
      },
    });
  }, [navigate]);

  const deleteHandle = useCallback(async () => {
    if (name) {
      const response = await deleteLegalEntityMutation(name);

      if ('data' in response) {
        Notification.success({
          title: 'Legal entity has been deleted',
        });

        closeHandle();
      }
    }
  }, [deleteLegalEntityMutation, closeHandle, name]);

  return {
    modal,
    closeHandle,
    deleteHandle,
  };
}
