import { versionMapper } from './application.mapper';
import { ApplicationRepository } from './application.repository';

export class ApplicationService {
  private getUiVersion() {
    return process?.env?.REACT_APP_VERSION || null;
  }

  public async getBOVersion() {
    let api: string | null = null;
    let ui: string | null = null;
    try {
      const {
        data: {
          version: { full },
        },
      } = await ApplicationRepository.fetchAPIVersion();
      api = full;
    } catch (error) {
      console.error(`network error: ${error}`);
    }

    ui = this.getUiVersion();

    return versionMapper(ui, api);
  }
}
