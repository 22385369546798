import { useGetAccountsQuery } from '~/api';
import { useGetClientQuery, useGetClientUsersQuery } from '~/store/client';
import { IUsePageDataReturn } from '~/types/rtkQuery';

import {
  IClientAccountsInfoQueries,
  IClientInfoQueries,
  IClientUsersInfoQueries,
  IDataAvailabilityState,
} from '../types';

export const useClientInfoData = (
  clientId: string,
): IUsePageDataReturn<
  IClientInfoQueries & IClientAccountsInfoQueries & IClientUsersInfoQueries,
  IDataAvailabilityState
> => {
  const clientQuery = useGetClientQuery(clientId);
  const clientAccountsQuery = useGetAccountsQuery(
    { clientId },
    {
      skip: !clientId,
    },
  );
  const clientUsersQuery = useGetClientUsersQuery(clientId, {
    skip: !clientId,
  });

  // isFetching is needed because we should rebuild form when navigate between users by navigation arrows
  const isClientLoading = clientQuery.isLoading || clientQuery.isFetching;
  const isClientAccountsLoading =
    clientAccountsQuery.isLoading || clientAccountsQuery.isFetching;
  const isClientUsersLoading =
    clientUsersQuery.isLoading || clientUsersQuery.isFetching;

  const isDataLoading =
    isClientLoading || isClientAccountsLoading || isClientUsersLoading;

  const hasClientData = Boolean(clientQuery.data);
  const hasClientAccountsData = Boolean(clientAccountsQuery.data);
  const hasClientUsersData = Boolean(clientUsersQuery.data);

  return {
    queries: {
      clientQuery,
      clientAccountsQuery,
      clientUsersQuery,
    },
    isDataLoading,
    dataAvailabilityState: {
      hasClientData,
      hasClientAccountsData,
      hasClientUsersData,
    },
  };
};
