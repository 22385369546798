import { IAccount } from '~/types/accounts';

const DEFAULT_NEXT_ACCOUNT_ID = '001';
const DEFAULT_NEXT_ACCOUNT_ID_LENGTH = 3;

const parseAccountIdToNumericString = (id: string) => {
  const [, accountId] = id.split('.');
  const numericAccountId = accountId.replaceAll(/\D/g, '');
  const hasNumbers = numericAccountId !== '';

  if (hasNumbers) {
    return numericAccountId;
  }
  return [];
};

const sortDesc = (a: string, b: string) => parseInt(b, 10) - parseInt(a, 10);

export const getNextAccountId = (accounts: IAccount[] | undefined) => {
  if (!accounts || !accounts.length) {
    return DEFAULT_NEXT_ACCOUNT_ID;
  }

  if (accounts && accounts.length) {
    const [previousAccountId] = accounts
      .flatMap(({ id }) => parseAccountIdToNumericString(id))
      .sort(sortDesc);

    const numericId = parseInt(previousAccountId, 10);
    const validNumericId = Number.isNaN(numericId) ? 1 : numericId + 1;

    return validNumericId
      .toString()
      .padStart(
        previousAccountId &&
          previousAccountId.length >= DEFAULT_NEXT_ACCOUNT_ID_LENGTH
          ? previousAccountId?.length
          : DEFAULT_NEXT_ACCOUNT_ID_LENGTH,
        '0',
      );
  }
  return DEFAULT_NEXT_ACCOUNT_ID;
};
