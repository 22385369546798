import { pick } from 'lodash';
import { useState, useCallback } from 'react';
import { UseFormGetValues, UseFormReset } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { usePostTradesMutation } from '~/api';
import { useBack } from '~/hooks';
import { TRADES_PATH } from '~/routes';
import { flattenOptionValues, sendNotification } from '~/shared/utils';
import {
  selectSymbolIdSettlementCounterparties,
  selectSymbolIdCounterparties,
} from '~/store/symbols';
import { ITradePostRequest } from '~/types/trades';

import { useAccountTabs } from '../../AccountPage/TabManagement/hooks';
import {
  DEFAULT_VALUES,
  FIELDS_FOR_RESET_AFTER_SUCCESS_SUBMIT,
} from '../constants';
import { TradeFormValues, IFlattenTradeSwitchedValues } from '../types';

function toPostTradeParams(
  params: IFlattenTradeSwitchedValues,
  nonSwitchedUse4EyesCheck: unknown,
): ITradePostRequest {
  return Object.entries(params).reduce<ITradePostRequest>(
    (acc, [key, v]) => {
      const accumulator: { [key: string]: any } = acc;

      if (key === 'quantity' || key === 'price') {
        accumulator[key] = Number(v);
      } else if (key) {
        accumulator[key] = v;
      }
      return acc;
    },
    { use4EyesCheck: Boolean(nonSwitchedUse4EyesCheck) } as ITradePostRequest,
  );
}

interface IUseSubmitProps {
  accountId: string;
  getValues: UseFormGetValues<TradeFormValues>;
  isSwitchTrade: boolean;
  reset: UseFormReset<TradeFormValues>;
}

export function useSubmit({
  accountId,
  getValues,
  isSwitchTrade,
  reset,
}: IUseSubmitProps) {
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const { activityTabIndex } = useAccountTabs();

  const [postTrade] = usePostTradesMutation();

  const settlementCounterparties = useSelector(
    selectSymbolIdSettlementCounterparties,
  );

  const counterparties = useSelector(selectSymbolIdCounterparties);

  const successCallback = useCallback(() => {
    const values = getValues();

    sendNotification(
      `Trade has been submitted${
        values.nonSwitched.use4EyesCheck ? ' for approval' : ''
      }`,
      'success',
    );

    reset({
      ...values,
      ...pick(DEFAULT_VALUES, FIELDS_FOR_RESET_AFTER_SUCCESS_SUBMIT),
    });
  }, [getValues, reset]);

  const redirectToTrade = useBack({
    defaultPath: TRADES_PATH,
  });

  const redirectToTradePage = () => {
    redirectToTrade();

    localStorage.setItem(
      'currentTab',
      accountId ? String(activityTabIndex) : '0',
    );
  };

  const onSubmitHandler = useCallback(
    async ({ nonSwitched, switched }: TradeFormValues) => {
      setDisableSaveButton(true);

      const brokerClientId =
        counterparties[nonSwitched.counterparty]?.clientId || '';
      const brokerAccountId =
        counterparties[nonSwitched.counterparty]?.accountId || '';

      const settlementBrokerClientId = (
        settlementCounterparties[nonSwitched.settlementCounterparty] || []
      )
        .map((item) => item?.clientId)
        .filter(Boolean)
        .join(', ');

      const settlementBrokerAccountId = (
        settlementCounterparties[nonSwitched.settlementCounterparty] || []
      )
        .map((item) => item?.accountId)
        .filter(Boolean)
        .join(', ');

      const resultNonSwitched = await postTrade({
        params: {
          ...flattenOptionValues(nonSwitched),
          brokerAccountId,
          brokerClientId,
          settlementBrokerAccountId,
          settlementBrokerClientId,
        },
      });

      if ('data' in resultNonSwitched && resultNonSwitched.data) {
        if (isSwitchTrade) {
          const switchTradeRequestParams: IFlattenTradeSwitchedValues =
            flattenOptionValues(switched);

          const resultSwitched = await postTrade({
            params: toPostTradeParams(
              {
                ...switchTradeRequestParams,
                brokerAccountId,
                brokerClientId,
                settlementBrokerAccountId,
                settlementBrokerClientId,
              },
              nonSwitched.use4EyesCheck,
            ),
          });

          if ('data' in resultSwitched && resultSwitched.data) {
            successCallback();
            redirectToTradePage();
          }
        } else {
          successCallback();
          redirectToTradePage();
        }
      }

      setDisableSaveButton(false);
    },
    [
      counterparties,
      isSwitchTrade,
      postTrade,
      redirectToTradePage,
      settlementCounterparties,
      successCallback,
    ],
  );

  return { disableSaveButton, onSubmitHandler, redirectToTradePage };
}
