import { Loader } from 'react-ui-kit-exante';

import styles from './CenteredLoader.module.css';

// TODO: add possibility stretch panel to 100% height
// TODO: possible this loader should be deleted instead of CentredLoader from ui-kit
export const CenteredLoader = () => {
  return (
    <div className={styles.Wrapper}>
      <Loader size="l" />
    </div>
  );
};
