import { get, set } from 'lodash';
import { RefObject } from 'react';
import { IColumn, IRow } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { IRowData, ISymbolTreeStructure } from '~/types/symbolPermissions';

import { TMatchIdAndPositionInTable } from '../../../types';

import { getNewData } from './getNewData';

interface IRecursiveSetPermissions {
  subRows: IRow<ISymbolTreeStructure>[];
  matchInfo: RefObject<TMatchIdAndPositionInTable>;
  prevState: ISymbolTreeStructure[];
  column: IColumn<ISymbolTreeStructure>;
  newValue: boolean | null;
}

export function recursiveSetPermissions({
  subRows,
  matchInfo,
  prevState,
  column,
  newValue,
}: IRecursiveSetPermissions) {
  subRows.forEach((subRow: IRow<ISymbolTreeStructure>) => {
    if (matchInfo.current) {
      const subRowPath = matchInfo.current[subRow.original.id];

      const subRowData: IRowData = get(prevState, subRowPath, EMPTY_ARRAY);

      if (!subRowData.override) {
        const newSubRowData: IRowData = getNewData(
          {
            ...subRowData,
            [column.id]:
              column.id === 'override' ? subRow.values.override : newValue,
          },
          column.id,
          subRow.original.id,
          subRow.values,
          !newValue,
        );

        if (
          column.id === 'override' ||
          column.id === 'public' ||
          newSubRowData.public
        ) {
          set(prevState, subRowPath, newSubRowData);
        }

        if (subRow.subRows) {
          recursiveSetPermissions({
            subRows: subRow.subRows,
            matchInfo,
            prevState,
            column,
            newValue,
          });
        }
      }
    }
  });

  return prevState;
}
