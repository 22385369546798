import { useCallback, useRef } from 'react';

export function useSearchIsActive() {
  const searchIsActive = useRef(false);

  const updateSearchIsActive = useCallback((isActive: boolean) => {
    searchIsActive.current = isActive;
  }, []);

  return { searchIsActive, updateSearchIsActive };
}
