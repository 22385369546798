import { clientApi, clientSummaryApi, clientUsersApi } from '../client';

import { servicesApi } from './services';

export const userQueries = {
  [servicesApi.reducerPath]: servicesApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [clientUsersApi.reducerPath]: clientUsersApi.reducer,
  [clientSummaryApi.reducerPath]: clientSummaryApi.reducer,
};
