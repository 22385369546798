import { IColumn, IRowExpand, Notification } from 'react-ui-kit-exante';

import { FilterLayers, ICommissionTree } from '~/types/commissions';

export function checkPossibilityChangeData(
  column: IColumn<ICommissionTree>,
  row: IRowExpand<ICommissionTree>,
) {
  const { layer } = column.getProps();

  if (layer === FilterLayers.Default && row.original.inherited) {
    Notification.warning({
      title: `You can not change inherited ${
        row.original.rowType === 'node' ? 'node' : 'instrument'
      }`,
    });

    return false;
  }

  if (column.id === 'exchange' && layer !== FilterLayers.Default) {
    Notification.warning({
      title: 'You can not change exchange on non-default layer',
    });

    return false;
  }

  if (layer !== FilterLayers.Default && !row.original.override) {
    Notification.warning({
      title: `You can not change non-overridable commission`,
    });

    return false;
  }

  return true;
}
