import { IconButton, IconButtonProps } from 'react-ui-kit-exante';

type TRestoreButtonProps = Omit<IconButtonProps, 'iconName' | 'iconColor'>;

export const RestoreButton = (props: TRestoreButtonProps) => {
  return (
    <IconButton
      iconName="RepeatIcon"
      iconColor="radical"
      type="button"
      iconSize={24}
      {...props}
    />
  );
};
