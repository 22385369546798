import { createApi } from '@reduxjs/toolkit/query/react';
import { pick } from 'lodash';

import { baseQueryHandler } from '~/shared/utils';

import { endpoints } from './endpoints';
import { createFullRiskArrayNode } from './helpers';
import {
  TRiskArrayBySymbolID,
  TRiskArrayFullNode,
  TRiskArrayNode,
  TRiskArrayTreeNodeResponse,
  TRiskArrayTreeResponse,
} from './types';

type TErrorResponse = {
  error: unknown;
};

type TGetRiskArraysTreeParams = {
  withExpired?: boolean;
};

export const riskArraysApi = createApi({
  reducerPath: 'riskArraysApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['RiskArray'],
  endpoints: (builder) => ({
    getRiskArraysTree: builder.query<
      TRiskArrayTreeResponse,
      TGetRiskArraysTreeParams | undefined
    >({
      query(params?: TGetRiskArraysTreeParams) {
        return {
          url: `${endpoints.base}/tree`,
          params: {
            ...(params || {}),
            includeStrikes: true,
          },
        };
      },
      providesTags: ['RiskArray'],
    }),

    getRiskArrayNode: builder.query<
      {
        data: TRiskArrayFullNode[];
        requestParams: {
          path?: string;
          index?: string;
          search?: string;
          withExpired?: boolean;
          limit?: number;
          skip?: number;
        };
        pagination: {
          total: number;
        };
      },
      {
        path?: string;
        index?: string;
        search?: string;
        withExpired?: boolean;
        limit?: number;
        skip?: number;
      }
    >({
      queryFn: async (params, _, __, fetchWithBasicQuery) => {
        const response: TRiskArrayTreeNodeResponse | TErrorResponse =
          await fetchWithBasicQuery({
            url: endpoints.base,
            params: {
              ...pick(params, [
                'path',
                'search',
                'withExpired',
                'limit',
                'skip',
              ]),
              includeStrikes: true,
            },
          });

        if ('error' in response) {
          return {
            error: String(response.error) || `Unexpected error`,
          };
        }

        const symbolIds = response.data.data.map(({ id }) => id).join('|');

        const riskArrayNode = await fetchWithBasicQuery({
          url: endpoints.riskarrays,
          params: {
            symbolId: symbolIds, // Pass symbolIds as an array
            withExpired: params.withExpired,
          },
        });

        const fullData: TRiskArrayBySymbolID = riskArrayNode.data
          .map((item: TRiskArrayNode) => item)
          .reduce((accNode: TRiskArrayNode, node: TRiskArrayNode) => {
            return {
              ...accNode,
              [node.symbolId]: node,
            };
          }, {} as TRiskArrayBySymbolID);

        const resultData = createFullRiskArrayNode(
          response.data.data,
          fullData,
        );

        return {
          data: {
            data: resultData,
            requestParams: params,
            pagination: {
              total: response.data.pagination.total,
            },
          },
        };
      },
      providesTags: (result) => {
        if (!result) {
          return ['RiskArray'];
        }
        return [
          ...result.data.map(({ id }) => ({ type: 'RiskArray' as const, id })),
          'RiskArray',
        ];
      },
    }),
    updateRiskArray: builder.mutation<
      unknown,
      { id: string; data: Record<string, unknown> }
    >({
      query({ id, data }) {
        return {
          url: endpoints.riskarrays,
          method: 'POST',
          data,
          params: {
            symbolId: id,
          },
        };
      },
      invalidatesTags: (_, error, params) => {
        if (error) {
          return [];
        }
        return ['RiskArray', { type: 'RiskArray', id: params.id }];
      },
    }),
  }),
});

export const {
  useLazyGetRiskArrayNodeQuery,
  useLazyGetRiskArraysTreeQuery,
  useUpdateRiskArrayMutation,
} = riskArraysApi;
