import { Skeleton, styled } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-start',
}));

export const SkeletonContainer = styled(Skeleton)(() => ({
  transform: 'none',
  width: '280px',
}));
