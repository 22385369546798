import { ReactNode } from 'react';

import { FullHeightPanel } from '~/shared/components';

import { StyledFields, StyledHeader } from './NewAccountStyled';

interface IFieldsProps {
  header: ReactNode;
  fields: ReactNode;
}

export const NewAccount = ({ header, fields }: IFieldsProps) => {
  return (
    <>
      <StyledHeader>{header}</StyledHeader>
      <FullHeightPanel>
        <StyledFields>{fields}</StyledFields>
      </FullHeightPanel>
    </>
  );
};
