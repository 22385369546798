import { ReactNode, useCallback } from 'react';

import { TopArrowIcon } from '~/images/icons';

import {
  StyledWrapper,
  StyledButton,
  StyledArrow,
  StyledHiddenChildren,
} from './Accordion.styled';

export interface IAccordionProps {
  title: ReactNode;
  id: string;
  onChange(id: string): void;
  isActive: boolean;
  children: ReactNode;
}

export const Accordion = ({
  title,
  id,
  onChange,
  isActive,
  children,
}: IAccordionProps) => {
  const handleAccordionClick = useCallback(() => onChange(id), [id, onChange]);

  return (
    <StyledWrapper>
      <StyledButton type="button" onClick={handleAccordionClick}>
        <StyledArrow isRight={!isActive}>
          <TopArrowIcon />
        </StyledArrow>
        {title}
      </StyledButton>

      <StyledHiddenChildren isHidden={!isActive}>
        {children}
      </StyledHiddenChildren>
    </StyledWrapper>
  );
};
