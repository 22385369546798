import { EMPTY_ARRAY } from '~/constants';
import { IRequestArguments } from '~/types/api';
import { ICurrency } from '~/types/currencies';

import { CurrenciesRepository } from './currencies.repository';

export class CurrenciesService {
  public async resolveCurrencies(
    args?: IRequestArguments,
  ): Promise<ICurrency[]> {
    try {
      const { data } = await CurrenciesRepository.fetchCurrencies();

      return data.values?.map((currency) => ({
        currency: currency.id,
        ...currency,
      }));
    } catch (error) {
      if (args?.onError) {
        args.onError(error as Error);
      }

      return EMPTY_ARRAY;
    }
  }
}
