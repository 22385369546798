import { pick } from 'lodash';
import { ISortBy } from 'react-ui-kit-exante';

import { LAST_MODIFICATION_DATE_FIELDS } from '~/constants';
import {
  createEmptyObject,
  flattenObject,
  prepareNumberArrayForRequest,
  prepareSortingForApi,
} from '~/shared/utils';
import {
  prepareDateRangeStringForAPI,
  setStartEndForDateRangeString,
} from '~/shared/utils/dates';
import { TMapper } from '~/types/api';
import {
  TFlattenCoreClient,
  ICoreClient,
  TFlattenClient,
  IClient,
  IFormClientUser,
} from '~/types/clients';
import { TNumberRangeValues } from '~/types/filters';

import {
  expectedFields,
  UPDATE_CLIENT_EXPECTED_FIELDS,
  UPDATE_CLIENT_INFO_EXPECTED_FIELDS,
} from './constants';

export function clientMapper(client: IClient): TFlattenClient {
  return flattenObject(pick(client, expectedFields));
}

export function coreClientMapper(client: ICoreClient): TFlattenCoreClient {
  return flattenObject(pick(client, expectedFields));
}

export function clientsMapper(clients: IClient[]): TFlattenClient[] {
  return clients.map((client) => clientMapper(client));
}

export function clientInfoMapper(name: string, value: string) {
  return { [`clientInfo_${name}`]: value };
}

export const prepareClientForSend = (client: TFlattenCoreClient) => {
  const data = pick<TFlattenCoreClient, keyof TFlattenCoreClient>(
    client,
    UPDATE_CLIENT_EXPECTED_FIELDS,
  );
  const clientInfo = pick<TFlattenCoreClient, keyof TFlattenCoreClient>(
    client,
    UPDATE_CLIENT_INFO_EXPECTED_FIELDS,
  );
  return {
    ...data,
    clientInfo,
  };
};

export const transformFormClientUsersToOriginal = (
  clientUsers: IFormClientUser[],
) =>
  clientUsers.map(({ clientId, userId, rights }) => ({
    userId: typeof userId !== 'string' ? userId.value : userId,
    clientId,
    rights,
  }));

export const CLIENTS_PARAMS_MAPPER: TMapper = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  lastModificationDate: {
    parser: (values: [string, string]) => {
      return prepareDateRangeStringForAPI(
        LAST_MODIFICATION_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  email: {
    parser: (value: string) => clientInfoMapper('email', value),
  },
  internalInfo: {
    parser: (value: string) => clientInfoMapper('internalInfo', value),
  },
  primaryPhone: {
    parser: (value: string) => clientInfoMapper('primaryPhone', value),
  },
  secondaryPhone: {
    parser: (value: string) => clientInfoMapper('secondaryPhone', value),
  },
  page: {
    parser: createEmptyObject,
  },
  netAssetValue: {
    parser: (values: TNumberRangeValues) =>
      prepareNumberArrayForRequest(values, 'netAssetValue'),
  },
};
