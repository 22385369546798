import UserAccessRightsControlsStyles from './UserAccessRightsControls.module.css';

interface UserAccessRightsControlsProps {
  onSetAllValues: () => void;
  onClearAllValues: () => void;
}
export const UserAccessRightsControls = ({
  onSetAllValues,
  onClearAllValues,
}: UserAccessRightsControlsProps) => {
  return (
    <div className={UserAccessRightsControlsStyles.AccessRightsControls}>
      <button
        className={UserAccessRightsControlsStyles.AccessRightButton}
        type="button"
        onClick={onSetAllValues}
      >
        Set all
      </button>
      <button
        className={UserAccessRightsControlsStyles.AccessRightButton}
        type="button"
        onClick={onClearAllValues}
      >
        Clear all
      </button>
    </div>
  );
};
