import { ISortBy } from 'react-ui-kit-exante';

import { FROM_TO_DATE_FIELDS } from '~/constants';
import { createEmptyObject, prepareSortingForApi } from '~/shared/utils';
import { prepareDateRangeStringForAPI } from '~/shared/utils/dates';
import { ITransaction } from '~/types/transactions';

export const expectedFields: (keyof ITransaction)[] = [
  'timestamp',
  'symbolType',
  'symbolId',
  'sum',
  'price',
  'orderPos',
  'orderId',
  'operationType',
  'id',
  'asset',
  'accountId',
  'valueDate',
  'comment',
  'internalComment',
  'settlementCounterparty',
  'transferId',
  'internalCounterparty',
  'clientCounterparty',
  'who',
  'clientType',
  'baseCurrency',
  'category',
  'convertedSum',
  'exchangeCommission',
  'settlementCurrencyMovement',
  'uuid',
  'legalEntity',
  'extraData',
  'executionCounterparty',
  'settlementCurrency',
  'blockedQtty',
];

export const REQUESTED_FIELDS = [
  'id',
  'accountId',
  'timestamp',
  'operationType',
  'sum',
  'convertedSum',
  'asset',
  'symbolId',
  'symbolType',
  'who',
  'comment',
  'internalComment',
  'clientType',
  'internalCounterparty',
  'valueDate',
  'orderId',
  'orderPos',
  'price',
  'exchangeCommission',
  'category',
  'settlementCurrencyMovement',
  'settlementCounterparty',
  'clientCounterparty',
  'baseCurrency',
  'transferId',
  'uuid',
  'legalEntity',
  'executionCounterparty',
  'extraData',
  'settlementCurrency',
  'parentUuid',
  'chainId',
  'blockedQtty',
];

export const TRANSACTIONS_PARAMS_MAPPER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  fromTo: {
    parser: (values: (string | null)[]) =>
      prepareDateRangeStringForAPI(FROM_TO_DATE_FIELDS, values),
  },
  who: {
    parser: (value: string | null) => ({
      who: value?.toLowerCase() || null,
    }),
  },
  page: {
    parser: createEmptyObject,
  },
};

export const EXPORT_TRANSACTIONS_PARAMS_TRANSFORMER = {
  sorting: {
    parser: ({
      order,
      order_by,
    }: {
      order: 'desc';
      order_by: 'tradeTime';
    }) => ({
      order,
      orderBy: order_by,
    }),
  },
  fromTo: {
    parser: (values: (string | null)[]) =>
      prepareDateRangeStringForAPI(FROM_TO_DATE_FIELDS, values),
  },
  page: {
    parser: createEmptyObject,
  },
};

export const arraySupportedFields = ['accountId', 'symbolId', 'asset'];
