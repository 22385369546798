import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { useDirtyTabs } from '~/hooks';
import { useTabsContext } from '~/hooks/useTabs/useTabs';
import { isDatePresent } from '~/shared/utils/dates';
import { IRefreshActiveTabQuery } from '~/types/refetch';

import { TABS } from '../TabManagement/constants';

import {
  ClientPageContextProvider,
  clientPageContext,
} from './ClientPageContext';
import { DEFAULT_DIRTY_TABS_STATE } from './constants';
import { IClientPageContext, TTabsIds } from './types';

export const ClientPageContextManager = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const { dirtyTabs, setDirtyTabs } = useDirtyTabs<TTabsIds>(
    DEFAULT_DIRTY_TABS_STATE,
  );
  const [activeTab] = useTabsContext();
  const [refreshQueries, setRefreshQueries] = useState<
    Record<string, IRefreshActiveTabQuery>
  >({});
  const activeTabName = TABS[Number(activeTab)]?.id;

  const handleDateChange = useCallback((newDate: Date) => {
    if (isDatePresent(newDate)) {
      setDate(newDate);
    }
  }, []);
  const handleResetDate = useCallback(() => setDate(null), []);

  const handleSetRefreshFn = useCallback(
    (key: string, refreshFn: IRefreshActiveTabQuery) => {
      setRefreshQueries((prev) => ({
        ...prev,
        [key]: refreshFn,
      }));
    },
    [],
  );
  const contextValue = useMemo<IClientPageContext>(
    () => ({
      clientAccountsSummary: {
        filters: {
          date,
          setDate: handleDateChange,
          resetDate: handleResetDate,
        },
      },
      dirtyTabs,
      setDirtyTabs,
      refreshActiveTabQuery: refreshQueries?.[activeTabName] || {},
      setRefreshFn: handleSetRefreshFn,
    }),
    [
      activeTabName,
      date,
      dirtyTabs,
      handleDateChange,
      handleResetDate,
      handleSetRefreshFn,
      refreshQueries,
      setDirtyTabs,
    ],
  );

  return (
    <ClientPageContextProvider value={contextValue}>
      {children}
    </ClientPageContextProvider>
  );
};

export const useClientPageContextManager = () => {
  const context = useContext(clientPageContext);

  if (context === undefined) {
    throw new Error(
      'Component must be used within a ClientPageContextProvider',
    );
  }

  const {
    refreshActiveTabQuery: { refetch, isLoading },
    setRefreshFn,
    activeTabName,
  } = context;

  return {
    refreshActiveTab: refetch,
    isRefreshing: isLoading,
    setRefreshFn,
    activeTabName,
  };
};
