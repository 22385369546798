import { EMPTY_OPTION_NULLABLE } from '~/constants';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';

import { TAccountFormState } from '../types';

import { withInheritOption } from './withInheritOption';

export const getPurposeOptionsByType = (
  type: Exclude<TAccountFormState['type'], undefined>,
  accountPurposesOptions: IOption[],
  defaultAccountPurposes: string[],
) => {
  if (['Equity', 'Assets'].includes(String(type))) {
    return [
      EMPTY_OPTION_NULLABLE,
      // todo: probably we should delete row below
      ...transformVariantsToSelectOptions(defaultAccountPurposes),
    ];
  }
  return withInheritOption(accountPurposesOptions);
};
