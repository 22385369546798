import { useCallback, useState, useEffect } from 'react';
import { Input, Modal, Notification } from 'react-ui-kit-exante';

import { usePrevious } from '~/hooks';

export interface EditGroupModalProps {
  isOpened: boolean;
  onClose: () => unknown;
  onSubmit: (value: string, id: number) => Promise<unknown>;
  name: string;
  id: number;
  groupNames: Set<string>;
}

export const EditGroupModal = ({
  isOpened,
  onClose,
  onSubmit,
  name,
  id,
  groupNames,
}: EditGroupModalProps) => {
  const [groupName, setGroupName] = useState(name);
  const [errorMessage, setErrorMessage] = useState('');
  const prevName = usePrevious(name);

  const handleSubmit = useCallback(async () => {
    if (!groupName) {
      setErrorMessage('Group name cannot be empty');
    } else if (groupNames.has(groupName)) {
      setErrorMessage('Group name already exists');
    } else {
      try {
        await onSubmit(groupName, id);
        onClose();
      } catch (err) {
        Notification.error({
          title: err instanceof Error ? err.message : 'Unknown error',
        });
      }
    }
  }, [groupNames, groupName, id, onClose, onSubmit]);

  const onCloseAddModal = () => {
    onClose();
    setGroupName('');
    setErrorMessage('');
  };

  const handleChangeGroupName = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setGroupName(value);
  };

  useEffect(() => {
    if (name !== prevName) {
      setGroupName(name);
    }
  }, [name, prevName]);

  return (
    <Modal
      onClose={onCloseAddModal}
      isOpened={isOpened}
      title="Enter the new name of group"
      confirmButton={{
        handleConfirm: handleSubmit,
      }}
      keepMounted={false}
    >
      <Input
        error={!!errorMessage}
        fullWidth
        message={errorMessage}
        onChange={handleChangeGroupName}
        placeholder="Group name"
        value={groupName}
      />
    </Modal>
  );
};
