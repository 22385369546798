import { useGetCurrentUserAccessRightsQuery } from '~/api';
import { TPossibleAccessRights, ListAccessTypes } from '~/types/users';

export function usePickUserPermissions(
  permissions: ListAccessTypes[],
): Record<ListAccessTypes, TPossibleAccessRights> {
  const { data: userPermissions } = useGetCurrentUserAccessRightsQuery();

  return permissions.reduce((acc, permission) => {
    return {
      ...acc,
      [permission]: {
        read: !!userPermissions?.read[permission],
        write: !!userPermissions?.write[permission],
      },
    };
  }, {} as Record<ListAccessTypes, TPossibleAccessRights>);
}
