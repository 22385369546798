import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants';

import { ICreateCurrencyFormatter } from './currencyFormatter';
import { formatNumber } from './formatNumber';

export function formatCurrency(
  formatter: ICreateCurrencyFormatter,
  value?: string | number | null,
) {
  const fixedNumber = formatNumber(value);
  const numberedValue = Number(fixedNumber);

  if (Number.isNaN(numberedValue)) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }

  return formatter.format(numberedValue);
}
