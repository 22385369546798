import { UseFormReturn } from 'react-hook-form';

import { IFormArrayItemStructure } from '~/types/form';

export function clearOverridesError(formContext: UseFormReturn, path: string) {
  formContext.clearErrors(`${path}.originatorIds.overrides`);
}

export const getOriginatorIdsOverrideStructure = (
  path: string,
): Array<IFormArrayItemStructure> => [
  {
    customEvents: {
      onBlur: (formContext) => clearOverridesError(formContext, path),
    },
    label: 'Name',
    type: 'text',
  },
  {
    label: 'Value',
    type: 'text',
  },
];
