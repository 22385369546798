import { useGetUserBrandingsQuery } from '~/api';
import { transformVariantsToSelectOptions } from '~/shared/utils';

export function useBrandingList() {
  const { data, isLoading, isFetching } = useGetUserBrandingsQuery();

  const brandingList = transformVariantsToSelectOptions(data || []);

  const haveAllBranding = brandingList.some((item) => item.value === 'ALL');

  const isLoadingBrandingList = isLoading || isFetching;

  const brandingListWithoutAll = brandingList.filter(
    (item) => item?.value !== 'ALL',
  );

  return {
    brandingList,
    isLoading: isLoadingBrandingList,
    haveAllBranding,
    brandingListWithoutAll,
  };
}
