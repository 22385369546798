import { styled, Tooltip } from 'react-ui-kit-exante';

export const StyledText = styled('div')(({ theme }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: theme?.color.typo.primary,
}));

export const StyledTooltip = styled(Tooltip)(() => ({
  minWidth: '464px',
  // reset z-index to avoid conflicts with dropdowns in select
  zIndex: 1300,

  '> div': {
    minWidth: 'inherit',
  },
}));
