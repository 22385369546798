import { get, pick, set } from 'lodash';
import { useCallback, useContext } from 'react';
import {
  IColumn,
  IRowExpand,
  Notification,
  TTableState,
} from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { sendNotification } from '~/shared/utils';
import {
  FilterLayers,
  IRowData,
  ISymbolTreeStructure,
} from '~/types/symbolPermissions';

import {
  ActionTypes,
  FIELDS_FOR_POST,
  SymbolPermissionsContext,
} from '../../constants';
import { ICommonPropertiesSymbolPermissions } from '../../types';

import {
  getNewData,
  handleColumnNameClick,
  recursiveSetPermissions,
} from './helpers';

export type TSymbolPermissionsCellState = {
  column: IColumn<ISymbolTreeStructure>;
  row: IRowExpand<
    ISymbolTreeStructure & {
      pid: number;
      subrows: IRowExpand<ISymbolTreeStructure[]>;
    }
  >;
  tableState: TTableState;
  value: boolean | null;
  onChangeSource: unknown;
};

export function useHandleCellClick({
  matchIdAndPositionInNonSearchTable,
  matchIdAndPositionInSearchTable,
  refreshQueue,
  searchIsActive,
  setTableData,
  updateMatchIdAndPositionInNonSearchTable,
  updateTableSizes,
}: Pick<
  ICommonPropertiesSymbolPermissions,
  | 'matchIdAndPositionInNonSearchTable'
  | 'matchIdAndPositionInSearchTable'
  | 'searchIsActive'
  | 'setTableData'
  | 'updateMatchIdAndPositionInNonSearchTable'
  | 'updateTableSizes'
> & { refreshQueue: Set<string> }) {
  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const {
    downloadedPaths,
    expandedRows,
    filters,
    permissions: { changed: changedPermissions },
    permissionsByPath,
  } = state;
  const {
    select: { layer, user, account },
    withExpired,
  } = filters;

  const handleCellClick = useCallback(
    async ({
      column,
      row,
      tableState: { expanded },
      value,
      onChangeSource,
    }: TSymbolPermissionsCellState) => {
      if (layer === FilterLayers.Accounts && !account) {
        Notification.warning({ title: 'Please select account' });
        return;
      }
      if (layer === FilterLayers.Users && !user) {
        Notification.warning({ title: 'Please select user' });
        return;
      }

      const newValue =
        column.id === 'short' && layer !== FilterLayers.Default
          ? value
          : !value;

      const isNonOverridableTool =
        layer !== FilterLayers.Default &&
        !row.values.override &&
        column.id !== 'override';

      const isNonPublishableTool =
        layer === FilterLayers.Default &&
        !row.values.public &&
        (column.id === 'view' || column.id === 'trade');

      const isEffectiveLayer = layer === FilterLayers.Effective;

      if (
        column.id === 'short' &&
        !onChangeSource &&
        layer !== FilterLayers.Default
      ) {
        return;
      }

      if (layer !== FilterLayers.Default && column.id === 'public') {
        return;
      }

      if (
        [FilterLayers.Default, FilterLayers.Effective].includes(layer) &&
        column.id === 'override'
      ) {
        return;
      }

      if (
        [FilterLayers.Accounts, FilterLayers.Effective].includes(layer) &&
        column.id === 'view'
      ) {
        return;
      }

      if (column.id === 'name') {
        await handleColumnNameClick({
          changedPermissions,
          dispatch,
          downloadedPaths,
          expanded,
          expandedRows,
          filters,
          matchIdAndPositionInNonSearchTable,
          permissionsByPath,
          refreshQueue,
          row,
          setTableData,
          updateMatchIdAndPositionInNonSearchTable,
          updateTableSizes,
          withExpired,
        });

        return;
      }

      if (row.original.rowType === 'node') {
        if (isEffectiveLayer) {
          sendNotification(
            'You cannot change access rights to an effective layer',
            'warning',
          );

          return;
        }

        if (isNonOverridableTool) {
          sendNotification(
            'You cannot change access rights to a non-overridable tool',
            'warning',
          );

          return;
        }

        if (layer === FilterLayers.Default) {
          dispatch({
            type: ActionTypes.CHANGED_DEFAULT_NODES_UPDATE,
            payload: {
              id: row.original.id,
              type: column.id,
              value: Boolean(newValue),
            },
          });
        }

        const matchInfo = searchIsActive.current
          ? matchIdAndPositionInSearchTable
          : matchIdAndPositionInNonSearchTable;

        setTableData((prevState) => {
          if (matchInfo.current && prevState) {
            const path = matchInfo.current[row.original.id];
            const rowData = get(prevState, path, EMPTY_ARRAY);
            const newRowData = getNewData(
              {
                ...rowData,
                [column.id]: newValue,
              },
              column.id,
              row.original.id,
              row.values,
              !newValue,
            );

            dispatch({
              type: ActionTypes.PERMISSIONS_FOR_NODE_SET,
              payload: [
                pick(newRowData, FIELDS_FOR_POST),
                pick(rowData, FIELDS_FOR_POST),
              ],
            });

            set(prevState, path, newRowData);

            recursiveSetPermissions({
              subRows: row.subRows,
              matchInfo,
              prevState,
              column,
              newValue,
            });

            return prevState;
          }
          return prevState;
        });

        return;
      }

      if (isEffectiveLayer) {
        sendNotification(
          'You cannot change access rights to an effective layer',
          'warning',
        );

        return;
      }

      if (isNonPublishableTool) {
        sendNotification(
          'You cannot change access rights to a non-public tool',
          'warning',
        );
        return;
      }

      if (isNonOverridableTool) {
        sendNotification(
          'You cannot change access rights to a non-overridable tool',
          'warning',
        );

        return;
      }

      dispatch({
        type: ActionTypes.EXPANDED_ROWS_SET,
        payload: expanded,
      });

      setTableData((prevState) => {
        const matchInfo = searchIsActive.current
          ? matchIdAndPositionInSearchTable
          : matchIdAndPositionInNonSearchTable;

        if (matchInfo.current && prevState) {
          const path = matchInfo.current[row.original.id];
          const rowData: IRowData = get(prevState, path, EMPTY_ARRAY);

          const newRowData = getNewData(
            {
              ...rowData,
              [column.id]: newValue,
            },
            column.id,
            row.original.path,
            row.values,
            !newValue,
          );

          dispatch({
            type: ActionTypes.PERMISSIONS_FOR_INSTRUMENT_SET,
            payload: [
              pick(newRowData, FIELDS_FOR_POST),
              pick(rowData, FIELDS_FOR_POST),
            ],
          });

          set(prevState, path, newRowData);

          const parentPath = matchInfo.current[row.original.pid];
          const parentRowData = get(prevState, parentPath, EMPTY_ARRAY);

          const hasFalsySibling = Boolean(
            parentRowData.subRows?.find(
              (subRow: ISymbolTreeStructure) =>
                !subRow[column.id as keyof ISymbolTreeStructure],
            ),
          );

          const newParentRowData = getNewData(
            {
              ...parentRowData,
              [column.id]: !hasFalsySibling,
            },
            column.id,
            row.original.path,
            row.values,
            !newValue,
          );

          set(prevState, parentPath, newParentRowData);

          return prevState;
        }

        return prevState;
      });
    },
    [
      account,
      changedPermissions,
      dispatch,
      downloadedPaths,
      expandedRows,
      filters,
      layer,
      matchIdAndPositionInNonSearchTable,
      matchIdAndPositionInSearchTable,
      permissionsByPath,
      refreshQueue,
      searchIsActive,
      setTableData,
      updateMatchIdAndPositionInNonSearchTable,
      updateTableSizes,
      user,
      withExpired,
    ],
  );

  return {
    handleCellClick,
    expandedRows,
  };
}
