import { formatDateRange } from '../formatDateRange';

import { prepareDateRangeStringForAPI } from './prepareDateRangeStringForAPI';

interface IParseTimeParams {
  values: (string | null)[];
  nameParams: string[];
}

export const parseTimeParams = ({ values, nameParams }: IParseTimeParams) => {
  const formattedDateRange = formatDateRange(values);

  return prepareDateRangeStringForAPI(nameParams, formattedDateRange);
};
