import { isString, omit } from 'lodash';
import { Sorting } from 'react-ui-kit-exante';

export const updateAccountIdInParams = (
  params: Record<string, string | string[] | number | Sorting | unknown>,
  newFieldName = 'accountId_regexp',
) => {
  const { accountId } = params || {};

  if (!isString(accountId)) {
    return params;
  }

  return {
    ...omit(params, 'accountId'),
    [newFieldName]: accountId,
  };
};
