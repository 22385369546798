import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select } from 'react-ui-kit-exante';

import { FullHeightPanel } from '~/shared/components';
import { NotificationsTable } from '~/shared/components/NotificationsTable';
import { isMemberOf, getTableId } from '~/shared/utils';
import { TActivityEntities } from '~/types/common';
import { IRefreshActiveTabQuery, TSetRefreshFn } from '~/types/refetch';
import { SelectOnChange } from '~/types/ui-kit';

import { AuditLogContainer } from '../AuditLogContainer';
import { TradesContainer } from '../TradesContainer';
import { TransactionsContainer } from '../TransactionsContainer';
import { TGlobalFilters } from '../TransactionsContainer/types';

import { useInnerActivityTabs } from './hooks';
import { InnerTab } from './types';

const tableIds = {
  trades: getTableId('AccountActivityTrades'),
  transactions: getTableId('AccountActivityTransactions'),
  auditLogs: getTableId('AccountActivityAuditLogs'),
  notifications: getTableId('AccountActivityNotifications'),
};

interface IActivityContainerProps {
  setRefetch: TSetRefreshFn;
  auditLogsFilters?: TGlobalFilters;
  entity: TActivityEntities;
  filters?: TGlobalFilters;
}

export const ActivityContainer = ({
  setRefetch,
  auditLogsFilters,
  entity,
  filters,
}: IActivityContainerProps) => {
  const [innerTab, setInnerTab] = useState<InnerTab>('trades');
  const [, setSearchParams] = useSearchParams();

  const { auditLogTab, innerTabs, innerTabsOptions } = useInnerActivityTabs();

  const clearTableFilters = () => setSearchParams('');

  const handleUpdateRefetch = useCallback(
    (state: IRefreshActiveTabQuery) => {
      setRefetch('activity', state);
    },
    [setRefetch],
  );

  const handleChangeInnerTab: SelectOnChange = ({ target: { value } }) => {
    if (isMemberOf(value, [...innerTabs])) {
      clearTableFilters();
      setInnerTab(value);
    }
  };

  const renderInnerTabComponent = (innerTabName: InnerTab) => {
    const components = {
      trades: TradesContainer,
      transactions: TransactionsContainer,
      auditLogs: AuditLogContainer,
      notifications: NotificationsTable,
    };

    const tableName =
      innerTabName === 'audit logs' ? 'auditLogs' : innerTabName;

    const InnerTabComponent = components[tableName];

    const tableId = tableIds[tableName];

    const selectTable = (
      <Select
        label="Table"
        value={innerTab}
        options={innerTabsOptions}
        onChange={handleChangeInnerTab}
        sx={{
          width: '200px',
        }}
      />
    );

    if (innerTabName === auditLogTab && auditLogsFilters) {
      return (
        <InnerTabComponent
          entity={entity}
          globalFilters={auditLogsFilters}
          tableId={tableId}
          withAddButton={false}
          updateRefetch={handleUpdateRefetch}
          selectTable={selectTable}
        />
      );
    }

    return (
      <InnerTabComponent
        entity={entity}
        globalFilters={filters}
        tableId={tableId}
        withAddButton={false}
        updateRefetch={handleUpdateRefetch}
        selectTable={selectTable}
      />
    );
  };
  return <FullHeightPanel>{renderInnerTabComponent(innerTab)}</FullHeightPanel>;
};
