import { FilterLayers, IStateOvernights } from '~/types/overnights';

const REGULAR_PAYMENT_SETTINGS = {
  calcInterestRebate: false,
  calcInterestsNegativeFlag: false,
  calcInterestsPositiveFlag: false,
  calcOvernightsFlag: false,
  interestUseNegativePnl: false,
  interestUsePositivePnl: false,
  interestsSetId: null,
  overnightsSetId: null,
  useExcessMarginFee: false,
  useFreemoneyPremium: false,
};

export const DEFAULT_CHANGED_OVERNIGHTS = {
  nodes: {},
  instruments: {},
};

export const INITIAL_STATE: IStateOvernights = {
  changedOvernights: DEFAULT_CHANGED_OVERNIGHTS,
  dict: {
    regularPaymentSettings: REGULAR_PAYMENT_SETTINGS,
  },
  filters: {
    select: {
      layer: FilterLayers.Default,
      account: null,
      group: undefined,
    },
    queryParams: {
      layer: FilterLayers.Default,
    },
    relatedGroup: null,
  },
  table: {
    defaultTree: [],
    defaultPositionByIdInTree: {},
    downloadedPaths: [],
    expandedRows: {},
    positionByIdInTree: {},
    tree: [],
  },
  search: {
    isActive: false,
    tree: [],
  },
};

export const FIELDS_FOR_POST_DEFAULT_TREE = [
  'benchmarkNegative',
  'benchmarkPositive',
  'markupNegative',
  'markupPositive',
];

export const FIELDS_FOR_POST_NON_DEFAULT_TREE = [
  'markupNegative',
  'markupPositive',
];
