import { FormProvider } from 'react-hook-form';

import { FormArrayContainer } from '~/containers/form';

import { StyledButton } from './RulesForm.styled';
import { FORM_ARRAY_STRUCTURE } from './constants';
import { useRulesForm } from './hooks';
import { IRulesForm } from './types';

export const RulesForm = ({
  column,
  handleTooltipClose,
  row,
  rules,
}: IRulesForm) => {
  const { formInstance, submitHandle } = useRulesForm(rules, row, column);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={submitHandle}>
        <FormArrayContainer
          name="rules"
          structure={FORM_ARRAY_STRUCTURE}
          textForAddButton="Add Сommission"
          title={column.Header}
        />

        <StyledButton type="submit">OK</StyledButton>
        <StyledButton type="button" onClick={handleTooltipClose}>
          Cancel
        </StyledButton>
      </form>
    </FormProvider>
  );
};
