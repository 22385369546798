import { FilterValue, TAdditionalFilter } from 'react-ui-kit-exante';

import { getPredefinedDateRanges } from '~/shared/utils';
import {
  createEndDate,
  createStartDate,
  createStringDateUTCWithoutTimezone,
  threeDaysAgo,
  today,
} from '~/shared/utils/dates';

export function getDefaultFilters() {
  const start = createStartDate(threeDaysAgo, true);
  const end = createEndDate(today, true);

  const res = {
    fromTo: [
      createStringDateUTCWithoutTimezone(start),
      createStringDateUTCWithoutTimezone(end),
    ],
  };

  return res;
}

interface IGetAdditionalFilters {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  defaultFilters: Record<string, unknown>;
  accountTypes: {
    value: string;
    label: string;
  }[];
}

export function getAdditionalFilters({
  onFilter,
  onRemove,
  defaultFilters,
  accountTypes,
}: IGetAdditionalFilters): TAdditionalFilter<Record<string, unknown>>[] {
  return [
    {
      Header: 'From/to',
      accessor: 'fromTo',
      type: 'dateTimeRange',
      defaultFilterValue: [defaultFilters.fromDate, defaultFilters.toDate],
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      predefinedDateRanges: getPredefinedDateRanges(onFilter),
    },
    {
      Header: 'Account type',
      accessor: 'accountTypes',
      type: 'multiSelect',
      filterOptions: accountTypes,
      onFilter,
      onRemove,
    },
  ];
}
