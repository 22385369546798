import {
  ILegalEntityDetail,
  ILegalEntityFormData,
} from '~/types/legalEntities';

import { TYPES_LEGAL_ENTITY_ENDPOINT } from '../endpoints';

import { legalEntitiesApi } from './legalEntitiesApi';

export const { useAddLegalEntityMutation } = legalEntitiesApi.injectEndpoints({
  endpoints: (builder) => ({
    addLegalEntity: builder.mutation<ILegalEntityDetail, ILegalEntityFormData>({
      query: (data) => ({
        url: TYPES_LEGAL_ENTITY_ENDPOINT,
        method: 'POST',
        data,
      }),
    }),
  }),
});
