import { EMPTY_ARRAY, EMPTY_FUNCTION, EMPTY_OBJECT } from '~/constants';

import { IBookmarkInitialProps, IDefaultBookmarkResponseProps } from './types';

export const getDefaultBookmark = (
  pageName: string,
): IBookmarkInitialProps => ({
  id: null,
  name: `All ${pageName}`,
  table: '',
  filters: EMPTY_OBJECT,
  columns: EMPTY_ARRAY,
});

export const getDefaultBookmarkResponse = (
  pageName: string,
): IDefaultBookmarkResponseProps => ({
  selectedBookmark: {
    id: null,
    name: `All ${pageName}`,
    table: '',
    filters: EMPTY_OBJECT,
    columns: EMPTY_ARRAY,
  },
  handleSaveBookmark: EMPTY_FUNCTION,
  handleSaveAsNewBookmark: EMPTY_FUNCTION,
  handleShareBookmark: EMPTY_FUNCTION,
  handleDeleteBookmark: EMPTY_FUNCTION,
});

export const getBookmarkColumns = (tableId: string) => {
  const lsKeyColumns = `${tableId}-columns`;
  const paramsColumns = localStorage.getItem(lsKeyColumns) || '';

  try {
    if (paramsColumns) {
      const paramsColumnsParsed = JSON.parse(paramsColumns);

      return paramsColumnsParsed || EMPTY_ARRAY;
    }

    const lsKey = `${tableId}-view-params`;
    const params = localStorage.getItem(lsKey) || '';

    const paramsParsed = JSON.parse(params);

    return paramsParsed.defaultColumnOrder || EMPTY_ARRAY;
  } catch (error) {
    console.error(error);

    return EMPTY_ARRAY;
  }
};

export const getPageHeader = () => {
  return document.getElementsByTagName('h1')[0]?.textContent || '';
};
