import { apiRequest } from '~/shared/utils';
import { TParams } from '~/types/api';
import { TBookmarksAPI, TSingleBookmark } from '~/types/bookmarks';

import { BOOKMARKS_API, getOneBookmarkAPI } from './endpoints';
import { TCreateBookmarkAPIPayload, TUpdateBookmarkAPIPayload } from './types';

export class BookmarksRepository {
  public static fetchBookmark(id: number) {
    return apiRequest<TSingleBookmark>({
      url: getOneBookmarkAPI(id),
      method: 'GET',
    });
  }

  public static fetchBookmarks(params: TParams) {
    return apiRequest<TBookmarksAPI[]>({
      params,
      url: BOOKMARKS_API,
      method: 'GET',
    });
  }

  public static createBookmark(createPayload: TCreateBookmarkAPIPayload) {
    return apiRequest<TBookmarksAPI>({
      url: BOOKMARKS_API,
      method: 'POST',
      data: createPayload,
    });
  }

  public static updateBookmark(
    id: number,
    updatePayload: TUpdateBookmarkAPIPayload,
  ) {
    return apiRequest<TBookmarksAPI>({
      url: getOneBookmarkAPI(id),
      method: 'PATCH',
      data: updatePayload,
    });
  }

  public static deleteBookmark(id: number) {
    return apiRequest({
      url: getOneBookmarkAPI(id),
      method: 'DELETE',
    });
  }
}
