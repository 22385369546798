import * as Yup from 'yup';

interface IValidationJSONMeta {
  createError: (
    params?: Yup.CreateErrorOptions | undefined,
  ) => Yup.ValidationError;
  path: string;
}

export function validationJSON(
  value: unknown,
  { createError, path }: IValidationJSONMeta,
) {
  if (typeof value === 'string' && !value.trim()) {
    try {
      JSON.parse(value);
    } catch (e) {
      return createError({
        path,
        message: 'Notifications field is required',
      });
    }
  } else if (typeof value === 'object') {
    try {
      JSON.parse(JSON.stringify(value));
    } catch (e) {
      return new Yup.ValidationError([
        createError({
          message: `Notifications field is not valid JSON`,
          path,
        }),
      ]);
    }
  } else if (typeof value === 'string') {
    try {
      JSON.parse(value);
    } catch (e) {
      return new Yup.ValidationError([
        createError({
          message: `Notifications field is not valid JSON`,
          path,
        }),
      ]);
    }
  }

  return true;
}
