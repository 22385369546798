import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetAccountsQuery } from '~/api';
import { NoAccountIDsWrapper } from '~/containers/ClientSummaryTabContainer/NoAccountIDsWrapper';
import { useEntitiesNavigation, useNavigationParams } from '~/hooks';
import { TabsContextProvider } from '~/hooks/useTabs/useTabs';
import { clientsService } from '~/resources';
import { CLIENTS_PATH, getClientPageRoute } from '~/routes';
import {
  CloseButton,
  NavigationArrows,
  StaticCenteredLoader,
} from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { paramsSerializer } from '~/shared/utils/apiRequest/helpers';
import { useGetClientQuery } from '~/store/client';
import { ICoreClient, TClientWithIdOnly } from '~/types/clients';
import { ILocationState, TParams } from '~/types/router';
import { IUseQueryReturn } from '~/types/rtkQuery';

import { ClientPageTemplate } from './ClientPageTemplate';
import { TabManagement } from './TabManagement';
import {
  ClientPageContextManager,
  useClientPageContextManager,
} from './context';

export const ClientPageComponent = () => {
  const { id } = useParams<TParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestParams, cursor } = (location.state as ILocationState) || {};

  const { refreshActiveTab, setRefreshFn, isRefreshing } =
    useClientPageContextManager();

  const { data, isLoading, isFetching } = useGetClientQuery<
    IUseQueryReturn<ICoreClient | null>
  >(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const {
    data: clientAccounts,
    isLoading: clientAccountsLoading,
    isFetching: clientAccountsFetching,
  } = useGetAccountsQuery(
    { clientId: id },
    {
      skip: !id,
    },
  );

  const clientName = data?.name || '';

  const params = useNavigationParams<TClientWithIdOnly>({
    requestParams,
    cursor,
    fetchData: clientsService.resolveClientIds,
    path: getClientPageRoute,
  });
  const {
    isNextDisabled,
    isPreviousDisabled,
    handleNextClick,
    handlePreviousClick,
  } = useEntitiesNavigation(params);

  const handleCloseClick = useCallback(() => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${CLIENTS_PATH}?${paramsSerializer(rest)}`);
  }, [navigate, requestParams]);

  if (
    isLoading ||
    isFetching ||
    clientAccountsLoading ||
    clientAccountsFetching
  ) {
    return <StaticCenteredLoader withBackground height="80vh" />;
  }

  if (!id || !data) {
    return <div>No client</div>;
  }

  const accounts = clientAccounts?.accounts;
  const accountIDs = accounts?.map((item) => item.id) || [];

  return (
    <ClientPageTemplate
      header={
        <EntryScreenActions
          title={data.id}
          navigationArrows={
            <NavigationArrows
              previousDisabled={isPreviousDisabled}
              nextDisabled={isNextDisabled}
              onPreviousClick={handlePreviousClick}
              onNextClick={handleNextClick}
            />
          }
          onRefresh={refreshActiveTab}
          isRefreshLoading={isRefreshing}
          closeButton={<CloseButton onClick={handleCloseClick} />}
        />
      }
      detailedClientSummary={<NoAccountIDsWrapper accountIDs={accountIDs} />}
      tabManagement={
        <TabManagement
          clientId={data.id}
          clientName={clientName}
          setRefetch={setRefreshFn}
        />
      }
    />
  );
};

export const ClientPage = () => {
  return (
    <TabsContextProvider>
      <ClientPageContextManager>
        <ClientPageComponent />
      </ClientPageContextManager>
    </TabsContextProvider>
  );
};
