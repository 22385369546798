import { defaultLocaleFormatter } from '../../utils/formatters';
import { formatCurrency } from '../../utils/formatters/formatCurrency';
import { PositiveNegative } from '../PositiveNegative';

import { ICashSumProps } from './types';

export const CashSum = ({ values, colored = false }: ICashSumProps) => {
  if (values.length === 1) {
    return null;
  }

  const valuesSum = values.reduce<number>((acc, curr) => {
    const numberedValue = Number(curr);

    if (Number.isNaN(numberedValue)) {
      return acc;
    }

    return numberedValue + acc;
  }, 0);

  const formattedCashSum = formatCurrency(defaultLocaleFormatter, valuesSum);

  return (
    <b>{colored ? <PositiveNegative value={valuesSum} /> : formattedCashSum}</b>
  );
};
