import cn from 'classnames';
import { ReactNode } from 'react';

import FormItemStyles from './FormItem.module.css';

export interface IFormRowProps {
  children: ReactNode;
  className?: string;
  direction?: 'row' | 'column';
  withMargin?: boolean;
  withPadding?: boolean;
}

export const FormItem = ({
  children,
  className,
  direction = 'row',
  withMargin = false,
  withPadding = false,
}: IFormRowProps) => {
  const classNames = cn(
    FormItemStyles.FormItem,
    withMargin && FormItemStyles.FormItemMargin,
    withPadding && FormItemStyles.FormItemPadding,
    direction === 'column' && FormItemStyles.FormItemColumn,
    direction === 'row' && FormItemStyles.FormItemRow,
    className,
  );

  return <div className={classNames}>{children}</div>;
};
