import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useCallback, useMemo } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';
import { object, string } from 'yup';

import { useGetUserLegalEntitiesQuery } from '~/api';
import {
  useGetClientTypesQuery,
  useGetIncorporationTypesQuery,
  useGetLegalEntityTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY } from '~/constants';
import { useBrandingList } from '~/hooks';
import { clientsService } from '~/resources';
import { CLIENTS_PATH } from '~/routes';
import { SaveButton, CloseButton } from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { oneOf, transformVariantsToSelectOptions } from '~/shared/utils';
import { IOption } from '~/types/form';

import { FormInputContainer, FormSelectContainer } from '../form';

import { ClientIdInputContainer } from './ClientIdInputContainer';
import { NewClient } from './NewClient';
import { defaultValues } from './constants';

export const ClientAddContainer = () => {
  const { haveAllBranding, brandingListWithoutAll } = useBrandingList();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data: legalEntities } = useGetLegalEntityTypesQuery();
  const { data: incorporationTypes } = useGetIncorporationTypesQuery();

  const incorporationTypesOptions = transformVariantsToSelectOptions(
    incorporationTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { data: clientTypes } = useGetClientTypesQuery();
  const clientTypesOptions = transformVariantsToSelectOptions(
    clientTypes?.values,
    { shouldSortAlphabetically: true },
  );
  const { data: userLegalEntitiesList } = useGetUserLegalEntitiesQuery();
  const userLegalEntitiesOptions: IOption[] = transformVariantsToSelectOptions(
    userLegalEntitiesList,
  );

  const createClientSchema = useMemo(
    () =>
      object({
        id: string()
          .required('Client Id field is required')
          .length(7, 'Client Id must be in format AAA 9999')
          .transform((value: string) =>
            value.replaceAll(' ', '').toUpperCase(),
          ),
        incorporationType: oneOf(
          incorporationTypes?.values || [],
          'INDIVIDUAL JOINT',
          true,
        ),
        legalEntity: oneOf(legalEntities?.values || EMPTY_ARRAY, 'Malta', true),
        name: string().nullable(),
        clientType: oneOf(clientTypes?.values || [], 'PROFESSIONAL', true),
        branding: haveAllBranding
          ? string()
          : string().required('Branding is a required field'),
      }),
    [clientTypes, incorporationTypes, legalEntities, haveAllBranding],
  );

  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(createClientSchema),
  });

  const { handleSubmit } = formInstance;

  const onSubmit: SubmitHandler<typeof defaultValues> = useCallback(
    async (values) => {
      setLoading(true);
      const createdClient = await clientsService.createClient(values);
      setLoading(false);
      if (createdClient) {
        const createdUserId = createdClient.id;
        navigate(`${CLIENTS_PATH}/${createdUserId}`);
      }
    },
    [navigate],
  );

  const onClose = useCallback(() => navigate(CLIENTS_PATH), [navigate]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NewClient
          header={
            <EntryScreenActions
              closeButton={<CloseButton onClick={onClose} />}
              saveButton={loading ? <Loader /> : <SaveButton />}
              title="Create new client"
            />
          }
          fields={[
            <ClientIdInputContainer key="id" />,
            <FormInputContainer key="name" name="name" label="Name" />,
            <FormSelectContainer
              key="clientType"
              name="clientType"
              label="Client type"
              options={clientTypesOptions}
            />,
            <FormSelectContainer
              key="incorporationType"
              name="incorporationType"
              label="Incorporation type"
              options={incorporationTypesOptions}
            />,
            <FormSelectContainer
              key="legalEntity"
              name="legalEntity"
              label="Legal Entity"
              options={userLegalEntitiesOptions}
            />,
            <FormSelectContainer
              key="branding"
              name="branding"
              label="Branding"
              options={brandingListWithoutAll}
            />,
          ]}
        />
      </form>
    </FormProvider>
  );
};
