import {
  ACCOUNTS_PATH,
  AUDIT_LOG_PATH,
  CLIENTS_PATH,
  COMMISSIONS_PATH,
  EXPIRED_INSTRUMENTS,
  GLOBAL_SUMMARY_BY_ACCOUNTS_PATH,
  GLOBAL_SUMMARY_BY_POSITION_SIDE_PATH,
  GLOBAL_SUMMARY_CURRENCY_RISK,
  GLOBAL_SUMMARY_PATH,
  INTERESTS_PATH,
  INTER_COMMODITY_PATH,
  LEGAL_ENTITIES_PATH,
  LIMITS_PATH,
  MIRRORING_RULES_PATH,
  NON_TRADING_COMMISSIONS,
  OVERNIGHTS_PATH,
  RISK_ARRAYS_PATH,
  SWAGGERS_PATH,
  SYMBOL_PERMISSIONS_PATH,
  TRADES_PATH,
  TRANSACTIONS_PATH,
  UNDERLYING_SETTINGS,
  USERS_INTERNAL_PATH,
  USERS_PATH,
} from '~/routes';

export const NAV_LINKS = [
  {
    value: ACCOUNTS_PATH,
    label: 'Accounts',
  },
  {
    value: AUDIT_LOG_PATH,
    label: 'Audit Logs',
  },
  {
    value: CLIENTS_PATH,
    label: 'Clients',
  },
  {
    value: COMMISSIONS_PATH,
    label: 'Commissions',
  },
  {
    value: EXPIRED_INSTRUMENTS,
    label: 'Expired Instruments',
  },
  {
    value: GLOBAL_SUMMARY_BY_ACCOUNTS_PATH,
    label: 'GS by accounts',
  },
  {
    value: GLOBAL_SUMMARY_BY_POSITION_SIDE_PATH,
    label: 'GS by position side',
  },
  {
    value: GLOBAL_SUMMARY_CURRENCY_RISK,
    label: 'GS currency risk',
  },
  {
    value: GLOBAL_SUMMARY_PATH,
    label: 'Global summary',
  },
  {
    value: INTERESTS_PATH,
    label: 'Interest Rates',
  },
  {
    value: INTER_COMMODITY_PATH,
    label: 'Inter commodity',
  },
  {
    value: LEGAL_ENTITIES_PATH,
    label: 'Legal entities',
  },
  {
    value: LIMITS_PATH,
    label: 'Limits',
  },
  {
    value: MIRRORING_RULES_PATH,
    label: 'Mirroring Rules',
  },
  {
    value: NON_TRADING_COMMISSIONS,
    label: 'Non-Trading Commissions',
  },
  {
    value: OVERNIGHTS_PATH,
    label: 'Overnights',
  },
  {
    value: RISK_ARRAYS_PATH,
    label: 'Risk Arrays',
  },
  {
    value: SWAGGERS_PATH,
    label: 'Swaggers',
  },
  {
    value: SYMBOL_PERMISSIONS_PATH,
    label: 'Symbol Permissions',
  },
  {
    value: TRADES_PATH,
    label: 'Trades',
  },
  {
    value: TRANSACTIONS_PATH,
    label: 'Transactions',
  },
  {
    value: USERS_INTERNAL_PATH,
    label: 'User management',
  },
  {
    value: USERS_PATH,
    label: 'Users',
  },
  {
    value: UNDERLYING_SETTINGS,
    label: 'Underlying Settings',
  },
];
