import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  useLazyGetTaskIdQuery,
  useGetHistoryLastUpdateForDayQuery,
  useGetTaskStatusQuery,
} from '~/api';
import {
  GET_TASK_ID_DATE_FORMAT,
  Statuses,
} from '~/api/globalSummaryTasks/constants';

import { POLLING_INTERVAL } from '../constants';

type TUseRefetchGlobalSummaryProps = {
  reconciliationMode: boolean;
  refetch: () => Promise<void>;
  date: number | Date;
};

type TTaskStatusDataRefProps = {
  error?: boolean;
  status?: string;
};

export const useRefetchGlobalSummary = ({
  date,
  reconciliationMode,
  refetch,
}: TUseRefetchGlobalSummaryProps) => {
  const [loading, setLoading] = useState(false);
  const taskStatusDataRef = useRef<TTaskStatusDataRefProps>({});

  const [fetchTaskId, { data: { taskId = '' } = {} } = {}] =
    useLazyGetTaskIdQuery();

  const formattedDate = format(date, GET_TASK_ID_DATE_FORMAT);

  const {
    data: { lastUpdate = '' } = {},
    refetch: refetchHistoryLastUpdateForDay,
  } = useGetHistoryLastUpdateForDayQuery({
    day: formattedDate,
    reconciliationMode,
  });

  const taskStatusResponse = useGetTaskStatusQuery(
    { taskId, date: formattedDate },
    {
      pollingInterval: loading ? POLLING_INTERVAL : undefined,
      skip:
        !taskId ||
        taskStatusDataRef.current?.status === Statuses.Done ||
        taskStatusDataRef.current?.error,
    },
  );

  const { data } = taskStatusResponse || {};
  const { status = '', error = '' } = data || {};

  const start = () => {
    setLoading(true);
    fetchTaskId({ date: formattedDate });

    Notification.success({
      title: 'The table is updated. It can take some time.',
    });
  };

  const refetchGlobalSummary = useCallback(async () => {
    await refetch();

    Notification.success({
      title:
        'The Global Summary by accounts table has been successfully updated.',
    });
    setLoading(false);
  }, [refetch]);

  useEffect(() => {
    taskStatusDataRef.current = {
      error: !!error,
      status,
    };
    const successfullyCompleted = status === Statuses.Done;

    if (successfullyCompleted) {
      refetchGlobalSummary();
      refetchHistoryLastUpdateForDay();
    } else if (error) {
      setLoading(false);

      Notification.warning({
        title: 'The table has not been updated. Please try to update later.',
      });
    }
  }, [error, refetchGlobalSummary, refetchHistoryLastUpdateForDay, status]);

  return {
    start,
    lastUpdate,
    loading,
  };
};
