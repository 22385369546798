import { FC, ReactNode } from 'react';

interface INoDataWrapperProps {
  hasData: boolean;
  children: ReactNode;
}

export const NoDataWrapper: FC<INoDataWrapperProps> = ({
  hasData,
  children,
}) => {
  if (!hasData) {
    return <div>Something went wrong with data fetching...</div>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
