import { useContext } from 'react';

import { userPageContext } from '../context/userPageContext';

export const useUserPageContextManager = () => {
  const context = useContext(userPageContext);

  if (context === undefined) {
    throw new Error(
      'Component must be used within a UserPageContextManagerProvider',
    );
  }

  const {
    refreshActiveTabQuery: { refetch, isLoading },
    setRefreshFn,
    activeTabName,
  } = context;

  return {
    refreshActiveTab: refetch,
    isRefreshing: isLoading,
    setRefreshFn,
    activeTabName,
  };
};
