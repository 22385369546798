import { styled } from 'react-ui-kit-exante';

type StyledWrapperProps = {
  withBackground: boolean;
};

export const StyledWrapper = styled('div')<StyledWrapperProps>(
  ({ withBackground, theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(withBackground && {
      background: theme?.color.bg.primary,
    }),
  }),
);
