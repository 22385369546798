import { useEffect } from 'react';

import { useLazyGetLegalEntitiesQuery } from '~/api';

export function useLegalEntities(preCall: () => void) {
  const [getLegalEntities, { isLoading, isFetching, data: tableData = [] }] =
    useLazyGetLegalEntitiesQuery();

  useEffect(() => {
    preCall();

    getLegalEntities();
  }, []);

  return { tableData, isLoading: isLoading || isFetching };
}
