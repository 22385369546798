import cn from 'classnames';
import { CSSProperties, memo, useRef } from 'react';

import { typeIconNames } from '~/constants';

import InstrumentIconStyles from './InstrumentIcon.module.css';

interface IInstrumentIconProps {
  iconName?: string;
  image?: string;
  className?: string;
}

const InstrumentIconComponent = (props: IInstrumentIconProps) => {
  const { iconName, className, image } = props;

  const iconRef = useRef<HTMLDivElement>(null);

  const effectiveIconName = iconName?.split(' ')[0] || typeIconNames.OTHER;
  const instrumentIconClassNames = cn(
    className,
    InstrumentIconStyles.Icon,
    InstrumentIconStyles.Circle,
    InstrumentIconStyles[effectiveIconName],
  );

  return (
    <span
      ref={iconRef}
      style={
        {
          '--instrument-icon-border-color': `var(--${effectiveIconName}-border-color)`,
          '--instrument-icon-stop-color-1': `var(--${effectiveIconName}-stop-color-1)`,
          '--instrument-icon-stop-color-2': `var(--${effectiveIconName}-stop-color-2)`,
          backgroundImage: image ? `url(${image})` : '',
        } as CSSProperties
      }
      className={instrumentIconClassNames}
    />
  );
};

export const InstrumentIcon = memo(InstrumentIconComponent);
