import { yupResolver } from '@hookform/resolvers/yup';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal, Notification, ToggleButtonGroup } from 'react-ui-kit-exante';

import { usePostLockedAssetMutation } from '~/api';
import { useGetOperationTypesQuery } from '~/api/types/types.api';
import { FormInputContainer, FormSelectContainer } from '~/containers/form';
import { ILockedAsset } from '~/types/accounts';
import { IOption } from '~/types/form';

import { IFormData } from '../../types';

import AssetLockStyles from './AssetLock.module.css';
import { DEFAULT_LOCK_VALUES, DEFAULT_UNLOCK_VALUES, Mode } from './constants';
import { getValidationSchema } from './helpers';

interface IAssetLockProps {
  assetLockOptions: IOption[];
  assetUnlockOptions: IOption[];
  canUnlock: boolean;
  formData: IFormData;
  invalidateData?: () => void;
  isOpened: boolean;
  lockDataByUuid: Record<string, ILockedAsset>;
  onClose: () => void;
  uuidOptions: IOption[];
  updateDataForModal: (asset: string) => void;
}

export const AssetLock = ({
  assetLockOptions,
  assetUnlockOptions,
  canUnlock,
  formData,
  invalidateData,
  isOpened,
  lockDataByUuid,
  onClose,
  uuidOptions,
  updateDataForModal,
}: IAssetLockProps) => {
  // https://jira.exante.eu/browse/RUI-536
  const [toggleKey, setToggleKey] = useState(`RUI-536-${Date.now()}`);
  const [mode, setMode] = useState<Mode>(Mode.Lock);
  const [postLockedAsset] = usePostLockedAssetMutation();
  const formInstance = useForm({
    defaultValues: {
      ...DEFAULT_LOCK_VALUES,
      ...formData,
    },
    resolver: yupResolver(getValidationSchema(mode)),
  });

  const { data: operationTypes } = useGetOperationTypesQuery();
  const operationTypeOptions = (operationTypes?.values || []).map(
    (operationType) => ({
      value: operationType,
      label: operationType.split('/').map(capitalize).join('/'),
    }),
  );

  const modeOptions = useMemo(
    () =>
      Object.keys(Mode).map((key) => ({
        label: key,
        value: Mode[key as keyof typeof Mode],
      })),
    [],
  );

  const formReset = useCallback(
    (modeForReset: string, { shouldResetAsset = false } = {}) => {
      const defaultValues =
        modeForReset === Mode.Lock
          ? DEFAULT_LOCK_VALUES
          : DEFAULT_UNLOCK_VALUES;

      formInstance.reset({
        ...defaultValues,
        ...{
          accountId: formInstance.getValues('accountId'),
          asset: shouldResetAsset
            ? defaultValues.asset
            : formInstance.getValues('asset'),
        },
      });

      if (modeForReset === Mode.Unlock) {
        formInstance.reset({
          uuid: uuidOptions[0].value,
        });
      }
    },
    [formInstance, uuidOptions],
  );

  const onCloseHandler = useCallback(() => {
    onClose();
    formReset(mode, { shouldResetAsset: true });
    setMode(Mode.Lock);
  }, [formReset, mode, onClose]);

  const onSubmitHandler = async (data: IFormData) => {
    const lockByUuid = lockDataByUuid[data.uuid || ''];

    const dataForSend =
      mode === Mode.Lock
        ? {
            ...data,
            blockedQtty: `-${data.blockedQtty}`,
          }
        : {
            accountId: lockByUuid.accountId,
            amount: 0,
            asset: lockByUuid.asset,
            blockedQtty: lockByUuid.amount,
            comment: lockByUuid.reason,
            operationType: lockByUuid.operationType,
            parentUuid: lockByUuid.uuid,
          };

    const result = await postLockedAsset(dataForSend);

    if (!('error' in result)) {
      Notification.success({
        title: `Asset ${mode.toLowerCase()}ed successfully`,
      });

      if (invalidateData) {
        invalidateData();
      }

      onCloseHandler();
    }
  };
  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  const confirmButton = useMemo(
    () => ({
      confirmButtonName: mode,
      handleConfirm: submitHandle,
    }),
    [mode, submitHandle],
  );

  const onToggleButtonGroupChangeHandler = useCallback(
    (value: string) => {
      if (canUnlock) {
        setMode(value as Mode);

        formReset(value);
      } else {
        Notification.warning({
          title: 'This asset is not locked',
        });
      }

      setToggleKey(`RUI-536-${Date.now()}`);
    },
    [formReset, canUnlock],
  );

  return (
    <Modal
      confirmButton={confirmButton}
      isOpened={isOpened}
      onClose={onCloseHandler}
      title=""
    >
      <ToggleButtonGroup
        fullWidth
        exclusive
        key={toggleKey}
        onChange={onToggleButtonGroupChangeHandler}
        options={modeOptions}
        value={mode}
      />

      <FormProvider {...formInstance}>
        <form className={AssetLockStyles.FormAssets} onSubmit={submitHandle}>
          <FormSelectContainer
            label="Asset"
            name="asset"
            onChange={updateDataForModal}
            options={mode === Mode.Lock ? assetLockOptions : assetUnlockOptions}
          />

          {mode === Mode.Lock ? (
            <FormInputContainer
              label="Quantity"
              name="blockedQtty"
              type="number"
            />
          ) : (
            <FormSelectContainer
              label="Quantity"
              name="uuid"
              options={uuidOptions}
            />
          )}

          {mode === Mode.Lock ? (
            <FormSelectContainer
              label="Operation type"
              name="operationType"
              options={operationTypeOptions}
            />
          ) : null}

          {mode === Mode.Lock ? (
            <FormInputContainer label="Reason" name="comment" />
          ) : null}
        </form>
      </FormProvider>
    </Modal>
  );
};
