import { marginColorMap, OVERRUN_MODE_VALUE } from '~/constants';
import { PositiveNegative } from '~/shared/components/PositiveNegative';
import {
  formatCurrency,
  formatPercentage,
  ICreateCurrencyFormatter,
} from '~/shared/utils';
import { IAccountSummaryMetrics, TNumberedMetrics } from '~/types/accounts';
import { IMarginData } from '~/types/margin';

import { AvailableLabel } from './components/DetailedAccountInfo/AvailableLabel';

interface IGetAccountValuesArguments {
  currencyFormatter: ICreateCurrencyFormatter;
  metrics: TNumberedMetrics;
}

export function getAccountValues({
  currencyFormatter,
  metrics: { percentageTotalDailyPnl, convertedDailyPnl, netAssetValue },
}: IGetAccountValuesArguments) {
  return [
    {
      label: 'Account value',
      value: formatCurrency(currencyFormatter, netAssetValue),
    },
    {
      label: 'P&L per day',
      value: (
        <PositiveNegative
          value={convertedDailyPnl}
          valueFormatter={(value) => formatCurrency(currencyFormatter, value)}
        />
      ),
    },
    {
      label: 'Daily P&L %',
      value: (
        <PositiveNegative
          value={percentageTotalDailyPnl}
          valueFormatter={(value) => formatPercentage(value)}
        />
      ),
    },
  ];
}

interface IGetAvailableValuesArguments {
  currencyFormatter: ICreateCurrencyFormatter;
  metrics: TNumberedMetrics;
  marginData: IMarginData;
}

export function getAvailableValues({
  metrics: {
    marginUtilization,
    totalBlockedMargin,
    totalOrderMargin,
    requiredMargin,
  },
  currencyFormatter,
  marginData: { isMarginCall, available, positionMargin, mode },
}: IGetAvailableValuesArguments) {
  return [
    {
      label: <AvailableLabel isMarginCall={isMarginCall} />,
      value: formatCurrency(currencyFormatter, available),
    },
    {
      label: 'Used for margin',
      value: `${formatPercentage(marginUtilization)} / ${formatCurrency(
        currencyFormatter,
        requiredMargin,
      )}`,
    },
    {
      label: 'Position margin',
      value: formatCurrency(currencyFormatter, positionMargin || 0),
      legendColor: marginColorMap[mode].positionMargin,
    },
    {
      label: 'Order margin',
      value: formatCurrency(currencyFormatter, totalOrderMargin || 0),
      legendColor: marginColorMap[mode].totalOrderMargin,
    },
    {
      label: 'Funds on hold',
      value: formatCurrency(currencyFormatter, totalBlockedMargin || 0),
      legendColor: marginColorMap[mode].totalBlockedMargin,
    },
  ];
}

export function getMetricNumbers(
  metrics: IAccountSummaryMetrics,
): TNumberedMetrics {
  return Object.keys(metrics).reduce<TNumberedMetrics>(
    (acc, curr) => ({
      ...acc,
      [curr]: Number(metrics[curr as keyof IAccountSummaryMetrics]) || 0,
    }),
    {} as TNumberedMetrics,
  );
}

export function getMarginData({
  netAssetValue,
  requiredMargin,
  freeMoney,
  totalBlockedMargin,
  marginUtilization,
}: TNumberedMetrics): IMarginData {
  const isMarginCall = netAssetValue < requiredMargin;
  const available = isMarginCall ? requiredMargin - netAssetValue : freeMoney;
  const positionMargin = requiredMargin - totalBlockedMargin;
  const moneyUsedFromMarginPercent = marginUtilization * 100;
  const mode =
    moneyUsedFromMarginPercent > OVERRUN_MODE_VALUE ? 'overrun' : 'normal';

  return {
    isMarginCall,
    available,
    moneyUsedFromMarginPercent,
    positionMargin,
    mode,
  };
}
