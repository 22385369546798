import { DEFAULT_OPTION_NULLABLE } from '~/constants';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { TCurrency } from '~/types/currencies';
import { IOption } from '~/types/form';
import { TApplicationState } from '~/types/store';

import { CurrencyWithIcon } from './currencies.types';

export function selectCurrencies(state: TApplicationState) {
  return state.currencies;
}

export function selectCurrenciesOptions(state: TApplicationState): string[] {
  const currencies = selectCurrencies(state);

  return currencies.data.map((currency) => currency.currency);
}

export function selectIsNavCurrencies(state: TApplicationState): string[] {
  const currencies = selectCurrencies(state);

  return currencies.data.reduce<string[]>((acc, cur) => {
    if (cur.isNav) {
      acc.push(cur.currency);
    }
    return acc;
  }, []);
}

export function selectIsNavCurrenciesOptions(state: TApplicationState) {
  const isNavCurrencies = selectIsNavCurrencies(state);

  return transformVariantsToSelectOptions(isNavCurrencies);
}

export function selectCurrenciesWithIcon(
  state: TApplicationState,
): CurrencyWithIcon[] {
  const currencies = selectCurrencies(state);
  return [...currencies.data]
    .sort((a, b) => {
      return b.weight - a.weight;
    })
    .map((currency) => ({
      currency: currency.currency,
      icon: currency.icon,
    }));
}

export function selectCurrenciesOptionsByWeight(
  state: TApplicationState,
): IOption[] {
  const { data: currencies } = selectCurrencies(state);

  const sortedCurrencies = [...currencies]
    .sort((a, b) => {
      if (a.weight === b.weight) {
        return a.currency > b.currency ? 1 : -1;
      }
      return a.weight > b.weight ? -1 : 1;
    })
    .map(({ currency }) => currency);

  return transformVariantsToSelectOptions(sortedCurrencies);
}

export function selectCurrenciesOptionsByWeightWithDefault(
  state: TApplicationState,
) {
  const { data: currencies } = selectCurrencies(state);

  const sortedCurrencies = [...currencies]
    .sort((a, b) => {
      if (a.weight === b.weight) {
        return a.currency > b.currency ? 1 : -1;
      }
      return a.weight > b.weight ? -1 : 1;
    })
    .map(({ currency }) => currency);

  return [
    DEFAULT_OPTION_NULLABLE,
    ...transformVariantsToSelectOptions<TCurrency>(sortedCurrencies),
  ];
}
