import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useEntitiesNavigation, useNavigationParams } from '~/hooks';
import { mirroringRulesService } from '~/resources';
import { getMirroringRulePageRoute, MIRRORING_RULES_PATH } from '~/routes';
import {
  CloseButton,
  NavigationArrows,
  StaticCenteredLoader,
} from '~/shared/components';
import { EntryScreenActions } from '~/shared/components/EntryScreenActions';
import { paramsSerializer } from '~/shared/utils/apiRequest/helpers';
import { useGetMirroringRuleQuery } from '~/store/mirroringRule';
import {
  IMirroringRule,
  TMirroringRuleWithIdOnly,
} from '~/types/mirroringRules';
import { ILocationState, TParams } from '~/types/router';
import { IUseQueryReturn } from '~/types/rtkQuery';

import { MirroringRulePageTemplate } from './MirroringRulePageTemplate';
import { TabManagement } from './TabManagement';
import { MirroringRulePageContextManager } from './context';

export const MirroringRulePage = () => {
  const { id } = useParams<TParams>();
  const location = useLocation();
  const navigate = useNavigate();
  const { requestParams, cursor } = (location.state as ILocationState) || {};

  const { data, isLoading } = useGetMirroringRuleQuery<
    IUseQueryReturn<IMirroringRule | null>
    // if there isn't id we can't open the page and skip will trigger also
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  >(Number(id)!, { skip: !id, refetchOnMountOrArgChange: true });

  const params = useNavigationParams<TMirroringRuleWithIdOnly>({
    requestParams,
    cursor,
    fetchData: mirroringRulesService.resolveMirroringRuleIds,
    path: getMirroringRulePageRoute,
  });
  const {
    isNextDisabled,
    isPreviousDisabled,
    handleNextClick,
    handlePreviousClick,
  } = useEntitiesNavigation(params);

  const handleCloseClick = useCallback(() => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${MIRRORING_RULES_PATH}?${paramsSerializer(rest)}`);
  }, [navigate, requestParams]);

  if (isLoading) {
    return <StaticCenteredLoader withBackground height="80vh" />;
  }

  if (!id || !data) {
    return <div>No mirroring rules</div>;
  }

  return (
    <MirroringRulePageContextManager>
      <MirroringRulePageTemplate
        header={
          <EntryScreenActions
            title={data?.name || String(data.id)}
            navigationArrows={
              <NavigationArrows
                previousDisabled={isPreviousDisabled}
                nextDisabled={isNextDisabled}
                onPreviousClick={handlePreviousClick}
                onNextClick={handleNextClick}
              />
            }
            closeButton={<CloseButton onClick={handleCloseClick} />}
          />
        }
        tabManagement={
          <TabManagement
            mirroringRuleId={data.id}
            mirroringRuleName={data?.name || String(data.id)}
          />
        }
      />
    </MirroringRulePageContextManager>
  );
};
