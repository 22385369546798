import { pick } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Bookmarks,
  IconButton,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useGetClientTypesQuery,
  useGetExanteTypesQuery,
  useGetIncorporationTypesQuery,
  useGetLegalEntityTypesQuery,
} from '~/api/types/types.api';
import { AccountsStatisticContainer } from '~/containers/AccountsStatisticContainer';
import {
  useBrandingList,
  useCurrency,
  useLogHandleTime,
  usePickUserPermissions,
  useSyncTableViewParams,
  useTotalNav,
} from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { CONTEXT_SEARCH_FIELD_NAME } from '~/pages/UsersPage/constants';
import { clientsService } from '~/resources';
import { getClientPageRoute, CLIENT_ADD_PATH } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  insertElementAfterHeader,
  getTableId,
} from '~/shared/utils';
import { IClientsState, TFlattenClient } from '~/types/clients';

import { DISPLAYED_COLUMN_KEYS, getColumns } from './columns';
import { getDefaultFilters, getAdditionalFilters } from './filters';
import { prepareClientsParamsToRequest } from './helpers';
import { DEFAULT_SORTING, getDefaultSorting } from './sorting';

export const ClientsContainer: FC<IBookmarkResponseProps> = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const [searchParams] = useSearchParams();
  const currency = useCurrency();
  const accountsStatisticContainerRef = useRef<Element | null>(null);
  const navigate = useNavigate();
  const { brandingListWithoutAll } = useBrandingList();
  const tableId = getTableId('Clients');
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const { data: clientTypes } = useGetClientTypesQuery();
  const { data: incorporationTypes } = useGetIncorporationTypesQuery();
  const { data: exanteTypes } = useGetExanteTypesQuery();
  const userPermissions = usePickUserPermissions(['Client create']);
  const filterContextCleanerWasCalled = useRef(false);

  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('clients-list');

  const getClients = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      setStartHandleTime();

      const result = await clientsService.resolveClients(
        prepareClientsParamsToRequest(params),
      );

      logHandleTime();

      return result;
    },
    [logHandleTime, setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getClients },
      sorting: { getDefaultSorting },
      filters: {
        getDefaultFilters,
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [getClients, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    params,
  } = useTableData<IClientsState>(tableDataArgs);
  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const totalNav = useTotalNav({
    currency,
    label: "Selected clients' Net Asset Value",
    nav: data?.summary?.nav,
  });

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types: {
          legalEntity: legalEntityTypes?.values || [],
          clientType: clientTypes?.values || [],
          incorporationTypes: incorporationTypes?.values || [],
          exanteTypes: exanteTypes?.values || [],
        },
        brandingList: brandingListWithoutAll,
      }),
    [
      brandingListWithoutAll,
      clientTypes?.values,
      exanteTypes,
      incorporationTypes,
      legalEntityTypes?.values,
      removeFilter,
      setFilter,
    ],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const contextSearchCheck = useCallback(
    (filtersForContextSearchCheck: Record<string, unknown>) => {
      if (filterContextCleanerWasCalled.current) {
        return filtersForContextSearchCheck;
      }

      filterContextCleanerWasCalled.current = true;

      if (!searchParams.get(CONTEXT_SEARCH_FIELD_NAME)) {
        return filtersForContextSearchCheck;
      }

      Object.keys(filtersForContextSearchCheck).forEach((key) => {
        if (key !== CONTEXT_SEARCH_FIELD_NAME) {
          removeFilter(key);
        }
      });

      return pick(filtersForContextSearchCheck, [CONTEXT_SEARCH_FIELD_NAME]);
    },
    [removeFilter, searchParams],
  );

  const filterProps = useMemo(
    () => ({
      additionalFilters,
      removeAllFilters: resetFilters,
      filters: contextSearchCheck(filters),
      manualFilters: true,
    }),
    [contextSearchCheck, additionalFilters, filters, resetFilters],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const handleRowClick = useCallback(
    ({ id }: TFlattenClient, index: number) =>
      navigate(getClientPageRoute(id), {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      }),
    [navigate, params],
  );
  const goToAddClientPage = useCallback(() => {
    navigate(CLIENT_ADD_PATH);
  }, [navigate]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'Add client',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add client"
            iconSize={24}
            onClick={goToAddClientPage}
          />
        ),
      },
    ],
    [goToAddClientPage],
  );

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  useEffect(() => {
    const container = document.createElement('div');
    accountsStatisticContainerRef.current = container;

    insertElementAfterHeader(container);
  }, []);

  return (
    <Table
      additionalActions={
        userPermissions['Client create'].write ? additionalActions : undefined
      }
      columns={columns}
      commonValue={totalNav}
      customHeadComponent={<AccountsStatisticContainer filters={filters} />}
      data={data?.clients || []}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={displayedColumnKeys}
      filteringProps={filterProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      manualSortBy
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="Clients"
    />
  );
};

export const ClientsPage = () => {
  const tableId = getTableId('Clients');

  return (
    <WithBookmarks
      component={ClientsContainer}
      pageName="Clients"
      tableId={tableId}
    />
  );
};
