export function getBaseName() {
  const url = window.location.href;
  if (url.includes('WBU-') || url.includes('WBO-')) {
    return url.split('/').slice(0, 4)[3];
  }
  if (url.includes('release')) {
    return 'release';
  }
  return '';
}
