import { NameCellComponent } from './NameCell.component';

interface RowProps {
  depth: number;
  values: { name: string };
  original: { icon: string; rowType: string };
}

interface ICellContainerProps {
  row: RowProps;
}

export const NameCell = ({ row }: ICellContainerProps) => {
  const {
    original,
    depth,
    values: { name },
  } = row;

  const isSecondLevel = depth === 1;
  const isColored = !isSecondLevel && !!original.icon;
  const displayParthenon = isSecondLevel && original.rowType === 'node';

  return (
    <NameCellComponent
      data-parthenon={displayParthenon}
      data-colored={isColored}
      isColored={isColored}
      icon={original.icon || ''}
      displayParthenon={displayParthenon}
    >
      {name}
    </NameCellComponent>
  );
};
