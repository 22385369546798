import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { interestRatesApi } from '~/api';
import { TInterestRatesMap } from '~/api/interestRates/types';
import { ICurrency } from '~/types/currencies';

import {
  haveBeenUpdated,
  injectGroupRatesIntoAccountRates,
  injectOverriddenRates,
  prepareDataForUpdate,
} from '../../helpers';
import {
  TInterestRateWithOverriddenMap,
  TInterestRateWithOverridden,
  TBrandedAccountId,
} from '../../types';

interface IUseRatesByAccount {
  accountId: TBrandedAccountId | null;
  defaultRates: TInterestRatesMap;
  ratesByGroup: TInterestRateWithOverriddenMap | null;
  currencies: ICurrency[];
}

export const useRatesByAccount = ({
  accountId,
  defaultRates,
  ratesByGroup,
  currencies,
}: IUseRatesByAccount) => {
  const [data, setData] =
    useState<TInterestRateWithOverriddenMap>(defaultRates);
  const [loading, setLoading] = useState(false);
  const {
    data: accountRates,
    isLoading,
    isFetching,
    refetch,
  } = interestRatesApi.useGetInterestRatesByAccountQuery(
    { accountId: accountId as TBrandedAccountId },
    { skip: !accountId },
  );

  const accountRatesLoading = isLoading || isFetching;

  const [updateAccountRate, { isLoading: updateAccountRateLoading }] =
    interestRatesApi.useUpdateAccountRateMutation();
  const [removeAccountRate, { isLoading: removeAccountRateLoading }] =
    interestRatesApi.useRemoveAccountRateMutation();

  useEffect(() => {
    if (accountRates) {
      let result = injectOverriddenRates({ ...defaultRates }, accountRates);
      if (!isEmpty(ratesByGroup)) {
        result = injectGroupRatesIntoAccountRates(
          result,
          ratesByGroup,
          currencies,
        );
      }

      setData(result);
    }
  }, [accountRates, currencies, defaultRates, ratesByGroup]);

  const handleUpdate = useCallback(
    async (
      previous: TInterestRateWithOverridden,
      updated: TInterestRateWithOverridden,
    ) => {
      if (!accountId) {
        Notification.warning({
          title: 'Please select an account',
        });
        return;
      }
      try {
        const { currency } = previous;
        const shouldBeOverridden = !previous.overridden && updated.overridden;
        const resetToDefault = previous.overridden && !updated.overridden;

        setLoading(true);
        const preparedDataForUpdate = prepareDataForUpdate(previous, updated);
        let response;

        if (resetToDefault) {
          response = await removeAccountRate({ accountId, currency });
        } else if (
          shouldBeOverridden ||
          haveBeenUpdated(preparedDataForUpdate)
        ) {
          response = await updateAccountRate({
            accountId,
            payload: preparedDataForUpdate,
          });
        } else {
          return;
        }

        if ('error' in response) {
          return;
        }
      } finally {
        setLoading(false);
      }
    },
    [accountId, removeAccountRate, updateAccountRate],
  );

  return {
    ratesByAccount: data,
    onUpdate: handleUpdate,
    loading:
      loading ||
      updateAccountRateLoading ||
      removeAccountRateLoading ||
      accountRatesLoading,
    refetch,
  };
};
