import { apiRequest } from '~/shared/utils';

import { FETCH_BRANDING_LIST } from './constants';

export class BrandingRepository {
  public static fetchBrandingList() {
    return apiRequest<string[]>({
      url: FETCH_BRANDING_LIST,
    });
  }
}
