import { IStateSymbolPermissions } from '../types';

export function permissionsReset(state: IStateSymbolPermissions) {
  return {
    ...state,
    permissions: {
      changed: {},
      initial: {},
    },
  };
}
