import { Notification } from 'react-ui-kit-exante';

import { is403GetError, displayErrorNotification } from '~/shared/utils';
import { TParams } from '~/types/api';
import { ICard, TEditableCard } from '~/types/cardsManagement';

import { cardsMapper } from './cardsManagement.mappers';
import { CardsManagementRepository } from './cardsManagement.repository';
import { CRUD_MESSAGES } from './constants';

export class CardsManagementService {
  public async fetchCardsByAccountId(
    id: string,
    params?: TParams,
  ): Promise<ICard[]> {
    try {
      const { data } = await CardsManagementRepository.fetchCards(id, params);

      return cardsMapper(data);
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return [];
    }
  }

  public async createCard(
    accountId: string,
    body: TEditableCard,
    showNotification = true,
  ): Promise<ICard | null> {
    try {
      const { data } = await CardsManagementRepository.createCard(
        accountId,
        body,
      );

      if (showNotification) {
        Notification.success({
          title: CRUD_MESSAGES.createSuccessful,
        });
      }

      return data;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  public async editCard(
    accountId: string,
    cardId: number,
    body: TEditableCard,
    showNotification = true,
  ): Promise<ICard | null> {
    try {
      const { data } = await CardsManagementRepository.editCard(
        accountId,
        cardId,
        body,
      );

      if (showNotification) {
        Notification.success({
          title: CRUD_MESSAGES.updateSuccessful,
        });
      }

      return data;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  public async deleteCard(
    cardId: number,
    showNotification = true,
  ): Promise<null> {
    try {
      const { data } = await CardsManagementRepository.deleteCard(cardId);

      if (showNotification) {
        Notification.success({
          title: CRUD_MESSAGES.deleteSuccessful,
        });
      }

      return data;
    } catch (error) {
      if (!is403GetError(error)) {
        displayErrorNotification(error);
      }

      return null;
    }
  }

  public async createCards(accountId: string, body: TEditableCard[]) {
    const showNotification = false;

    return Promise.all(
      body.map((item) => this.createCard(accountId, item, showNotification)),
    ).then((result) => {
      const resolved = result.filter((item) => item);

      if (resolved.length) {
        const title =
          resolved.length > 1
            ? CRUD_MESSAGES.createMultipleSuccessful
            : CRUD_MESSAGES.createSuccessful;

        Notification.success({
          title,
        });
      }

      return result;
    });
  }

  public async deleteCards(cardIds: number[]) {
    const showNotification = false;

    return Promise.all(
      cardIds.map((id) => this.deleteCard(id, showNotification)),
    ).then((result) => {
      if (result.length) {
        const title =
          result.length > 1
            ? CRUD_MESSAGES.deleteMultipleSuccessful
            : CRUD_MESSAGES.deleteSuccessful;

        Notification.success({
          title,
        });
      }

      return result;
    });
  }

  public async editCards(accountId: string, cards: ICard[]) {
    const showNotification = false;

    return Promise.all(
      cards.map((card) =>
        this.editCard(accountId, card.id, card, showNotification),
      ),
    ).then((result) => {
      const resolved = result.filter((item) => item);

      if (resolved.length) {
        const title =
          resolved.length > 1
            ? CRUD_MESSAGES.updateMultipleSuccessful
            : CRUD_MESSAGES.updateSuccessful;

        Notification.success({
          title,
        });
      }

      return result;
    });
  }

  public async cardsCUD(
    accountId: string,
    createdCards: ICard[],
    editedCards: ICard[],
    deletedCards: ICard[],
  ) {
    return Promise.all([
      this.createCards(accountId, createdCards),
      this.editCards(accountId, editedCards),
      this.deleteCards(deletedCards.map((card) => card.id)),
    ]);
  }
}
