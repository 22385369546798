import { IOption } from '~/types/form';

export interface IDefaultProviders {
  accountProviders: Record<string, IOption[]>;
  clientProviders: IOption[];
}

export const DEFAULT_PROVIDERS: IDefaultProviders = {
  accountProviders: {},
  clientProviders: [],
};
