import { createSlice } from '@reduxjs/toolkit';
import {
  clone,
  cloneDeep,
  get,
  groupBy,
  mapValues,
  pick,
  set,
  unset,
} from 'lodash';

import { overnightsApi } from '~/api';
import { EN_DASH, REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { deepSet, getAllPathsSet } from '~/shared/utils';
import { getNearestFieldBySubkey } from '~/shared/utils/getNearestFieldBySubkey';
import { RowType } from '~/types/common';
import { FilterLayers, IOvernight, IOvernightTree } from '~/types/overnights';
import type { TApplicationState } from '~/types/store';

import {
  DEFAULT_CHANGED_OVERNIGHTS,
  FIELDS_FOR_POST_DEFAULT_TREE,
  FIELDS_FOR_POST_NON_DEFAULT_TREE,
  INITIAL_STATE,
} from './constants';
import { formatPercentage, getQueryParams, updateNodeData } from './helpers';
import { IUpdateNodeOverridePayload, IUpdateNodeValuePayload } from './types';

export const overnightsSlice = createSlice({
  name: 'overnights',
  initialState: INITIAL_STATE,
  reducers: {
    dictSetRegularPaymentSettings: (state, { payload }) => {
      state.dict.regularPaymentSettings = payload;
    },
    expandedRowsSet: (state, { payload }) => {
      const [expanded, id, value] = payload;

      state.table.expandedRows = {
        ...expanded,
        [id]: value,
      };
    },
    expandedRowsUpdate: (state, { payload }) => {
      const [id, value] = payload;

      const expandedRows = clone(state.table.expandedRows);
      const newExpandedRows =
        typeof expandedRows === 'boolean'
          ? expandedRows
          : {
              ...expandedRows,
              [id]: value,
            };

      state.table.expandedRows = newExpandedRows;
    },
    filtersReset: (state, { payload }) => {
      const {
        layer = FilterLayers.Default,
        account = null,
        group = undefined,
        relatedGroup = null,
      } = payload;
      const select = { layer, account, group };
      const queryParams = getQueryParams({ ...select, relatedGroup });

      state.filters.relatedGroup = relatedGroup;
      state.filters.select = select;
      state.filters.queryParams = queryParams;

      state.table.downloadedPaths = [];
    },
    filtersSetAccount: (state, { payload }) => {
      const select = {
        ...state.filters.select,
        account: payload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: null,
      });

      state.filters.relatedGroup = null;
      state.filters.select = select;
      state.filters.queryParams = queryParams;

      state.table.downloadedPaths = [];
    },
    filtersSetGroup: (state, { payload }) => {
      const { value, shouldResetDownloadedPaths } = payload;

      const select = {
        ...state.filters.select,
        group: value,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: state.filters.relatedGroup,
      });

      state.filters.select = select;
      state.filters.queryParams = queryParams;

      if (shouldResetDownloadedPaths) {
        state.table.downloadedPaths = [];
      }
    },
    filtersSetRelatedGroup: (state, { payload }) => {
      const fixedPayload = payload === 'null' ? null : payload;

      const select = {
        ...state.filters.select,
        group: fixedPayload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: fixedPayload,
      });

      state.filters.relatedGroup = fixedPayload;
      state.filters.select = select;
      state.filters.queryParams = queryParams;

      state.table.downloadedPaths = [];
    },
    filtersSetLayer: (state, { payload }) => {
      state.filters.select = {
        ...state.filters.select,
        layer: payload,
      };
      state.filters.relatedGroup = null;
    },
    resetTable: (state, { payload }) => {
      const {
        shouldResetExpandedRows = true,
        shouldResetDownloadedPaths = true,
      } = payload;

      if (shouldResetExpandedRows) {
        state.table.expandedRows = {};
      }

      if (shouldResetDownloadedPaths) {
        state.table.downloadedPaths = [];
      }

      state.table.tree = cloneDeep(state.table.defaultTree);
      state.table.positionByIdInTree = cloneDeep(
        state.table.defaultPositionByIdInTree,
      );

      state.search.isActive = false;
      state.search.tree = [];
    },
    updateInstrumentOverride: (state, { payload }) => {
      const { path, value, column } = payload;

      const row = get(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
      );

      row[column] = value;

      deepSet(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
        row,
      );

      state.changedOvernights.instruments[path] = row;
    },
    updateNodeOverride: (state, { payload }: IUpdateNodeOverridePayload) => {
      const { path, value, column } = payload;

      const row = get(state.table.tree, state.table.positionByIdInTree[path]);

      row[column] = value;

      row.subRows.forEach((subRow: IOvernightTree) => {
        subRow[column] = value;
      });
    },
    updateInstrumentValue: (state, { payload }) => {
      const { path, value, column } = payload;

      const row = get(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
      );

      row[column] = value;

      state.changedOvernights.instruments[path] = row;
    },
    updateNodeValue: (state, { payload }: IUpdateNodeValuePayload) => {
      const { path, value, column } = payload;

      const isDefaultLayer =
        state.filters.select.layer === FilterLayers.Default;

      const row: IOvernightTree = get(
        state.table.tree,
        state.table.positionByIdInTree[path],
      );

      const nodeHasRange = Object.values(
        pick(
          row,
          isDefaultLayer
            ? FIELDS_FOR_POST_DEFAULT_TREE
            : FIELDS_FOR_POST_NON_DEFAULT_TREE,
        ),
      ).some((item) => typeof item === 'string' && item.includes(EN_DASH));

      row[column] = value;

      if (!nodeHasRange) {
        const { subRows, ...newSubRow } = row;

        state.changedOvernights.nodes[path] = newSubRow;
      }

      row.subRows?.forEach((subRow: IOvernightTree) => {
        subRow[column] = value;

        if (nodeHasRange && subRow.rowType === RowType.Instrument) {
          state.changedOvernights.instruments[subRow.path] = subRow;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      overnightsApi.endpoints.getOvernightsTree.matchFulfilled,
      (state, { payload }) => {
        state.table.defaultTree = cloneDeep(payload.tree);
        state.table.tree = cloneDeep(payload.tree);
        state.table.positionByIdInTree = cloneDeep(payload.positionByIdInTree);
        state.table.defaultPositionByIdInTree = cloneDeep(
          payload.positionByIdInTree,
        );
        state.table.expandedRows = {};
      },
    );
    builder.addMatcher(
      overnightsApi.endpoints.getOvernightsInstruments.matchFulfilled,
      (state, { payload }) => {
        const { path, data: instruments } = payload;

        const oldInstruments = get(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          [],
        );

        const newInstruments = [
          ...oldInstruments,
          ...instruments.map(({ overnight, ...instrument }) => ({
            ...instrument,
            ...Object.fromEntries(
              Object.entries(overnight).map((item) => {
                const [key, value] = item as [keyof IOvernight, number | null];

                // if instrument has changed node we use node value
                const matchedChangedNode =
                  getNearestFieldBySubkey<IOvernightTree>(
                    state.changedOvernights.nodes,
                    instrument.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
                  );

                if (matchedChangedNode) {
                  return [key, matchedChangedNode[key]];
                }

                // if instrument was changed we use changed instrument value
                if (state.changedOvernights.instruments[instrument.path]) {
                  return [
                    key,
                    state.changedOvernights.instruments[instrument.path][key],
                  ];
                }

                // if instrument starts with markup we format it as percentage
                if (key.startsWith('markup')) {
                  return [key, formatPercentage(value || 0)];
                }

                return [key, value || 0];
              }),
            ),
            rowType: RowType.Instrument,
          })),
        ];

        deepSet(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          newInstruments,
        );

        updateNodeData(newInstruments, state, path);

        state.table.positionByIdInTree = {
          ...state.table.positionByIdInTree,
          ...newInstruments.reduce<Record<string, string>>(
            (acc, item, index) => {
              acc[
                item.path
              ] = `${state.table.positionByIdInTree[path]}.subRows.${index}`;
              return acc;
            },
            {},
          ),
        };

        state.table.downloadedPaths.push(path);
      },
    );
    builder.addMatcher(
      overnightsApi.endpoints.searchInstruments.matchFulfilled,
      (state, { payload }) => {
        state.search.isActive = true;

        const groupedInstrumentsByPath = groupBy(payload.instruments, (item) =>
          item.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
        );

        const pathsWithInstruments: string[] = [];

        const tree = cloneDeep(state.table.defaultTree);

        const positionByIdInTreeForCalculate = clone(
          state.table.defaultPositionByIdInTree,
        );

        const positionByIdInTreeForState = clone(
          state.table.defaultPositionByIdInTree,
        );

        Object.entries(groupedInstrumentsByPath).forEach(
          ([path, instruments]) => {
            const position = positionByIdInTreeForCalculate[path];

            set(
              tree,
              `${position}.subRows`,
              instruments.map((instrument) =>
                mapValues(instrument, (value, key) => {
                  if (key.startsWith('markup')) {
                    return formatPercentage(Number(value) || 0);
                  }

                  return value || 0;
                }),
              ),
            );
            pathsWithInstruments.push(path);
            delete positionByIdInTreeForCalculate[path];

            instruments.forEach((instrument, index) => {
              positionByIdInTreeForState[
                instrument.path
              ] = `${position}.subRows.${index}`;
            });
          },
        );

        const allPathsSet = getAllPathsSet(
          pathsWithInstruments,
          REGEX_LAST_SEGMENT_IN_PATH,
        );

        Object.entries(positionByIdInTreeForCalculate).forEach(
          ([path, position]) => {
            if (!allPathsSet.has(path)) {
              unset(tree, position);
            }
          },
        );

        state.table.expandedRows = true;
        state.search.tree = tree;

        state.changedOvernights = DEFAULT_CHANGED_OVERNIGHTS;
        state.table.downloadedPaths = [];
        state.table.positionByIdInTree = positionByIdInTreeForState;
      },
    );
    builder.addMatcher(
      overnightsApi.endpoints.saveDefaultOvernights.matchFulfilled,
      (state) => {
        state.changedOvernights = DEFAULT_CHANGED_OVERNIGHTS;
      },
    );
    builder.addMatcher(
      overnightsApi.endpoints.saveGroupOvernights.matchFulfilled,
      (state) => {
        state.changedOvernights = DEFAULT_CHANGED_OVERNIGHTS;
      },
    );
    builder.addMatcher(
      overnightsApi.endpoints.saveAccountOvernights.matchFulfilled,
      (state) => {
        state.changedOvernights = DEFAULT_CHANGED_OVERNIGHTS;
      },
    );
  },
});

export const selectOvernightsTree = (state: TApplicationState) => {
  if (state.overnights.search.isActive) {
    return state.overnights.search.tree;
  }

  return state.overnights.table.tree;
};

export const selectDictRegularPaymentSettings = (state: TApplicationState) =>
  state.overnights.dict.regularPaymentSettings;

export const selectExpandedRows = (state: TApplicationState) =>
  state.overnights.table.expandedRows;

export const selectFiltersLayer = (state: TApplicationState) =>
  state.overnights.filters.select.layer;

export const selectFiltersAccount = (state: TApplicationState) =>
  state.overnights.filters.select.account;

export const selectFiltersGroup = (state: TApplicationState) =>
  state.overnights.filters.select.group;

export const selectFiltersSelect = (state: TApplicationState) =>
  state.overnights.filters.select;

export const selectFiltersRelatedGroup = (state: TApplicationState) =>
  state.overnights.filters.relatedGroup;

export const selectFiltersQueryParams = (state: TApplicationState) =>
  state.overnights.filters.queryParams;

export const selectSearchIsActive = (state: TApplicationState) =>
  state.overnights.search.isActive;

export const selectDownloadedPaths = (state: TApplicationState) =>
  state.overnights.table.downloadedPaths;

export const selectChangedOvernights = (state: TApplicationState) =>
  state.overnights.changedOvernights;

export const {
  dictSetRegularPaymentSettings,
  expandedRowsSet,
  expandedRowsUpdate,
  filtersReset,
  filtersSetAccount,
  filtersSetGroup,
  filtersSetLayer,
  filtersSetRelatedGroup,
  resetTable,
  updateInstrumentOverride,
  updateNodeOverride,
  updateInstrumentValue,
  updateNodeValue,
} = overnightsSlice.actions;
