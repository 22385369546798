import {
  ACCOUNTS_PATH,
  CLIENTS_PATH,
  MIRRORING_RULES_PATH,
  USERS_PATH,
} from './constants';

export const getUserPageRoute = (id: string | number) => `${USERS_PATH}/${id}`;
export const getAccountPageRoute = (id: string | number) =>
  `${ACCOUNTS_PATH}/${id}`;
export const getClientPageRoute = (id: string | number) =>
  `${CLIENTS_PATH}/${id}`;
export const getMirroringRulePageRoute = (id: string | number) =>
  `${MIRRORING_RULES_PATH}/${id}`;
