import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'react-ui-kit-exante';

import ErrorFallbackStyles from './ErrorFallback.module.css';

interface IErrorFallbackProps {
  error: Error;
  resetErrorBoundary(): void;
}

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: IErrorFallbackProps) => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const resetFilters = () => {
    const tableKey = location.pathname.split('/')[2];
    const similarTableKey =
      tableKey.at(-1) === 's' ? tableKey.slice(0, -1) : `${tableKey}s`;
    const localStorageItems = Object.entries({ ...localStorage }).map(
      (item) => {
        return { [item[0]]: JSON.parse(item[1]) };
      },
    );

    const localStorageBrokenItems = localStorageItems.filter((item) => {
      const [key] = Object.keys(item);

      return key.includes(tableKey) || key.includes(similarTableKey);
    });

    localStorageBrokenItems.forEach((item) => {
      const [key] = Object.keys(item);
      const [value] = Object.values(item);
      const resetFiltersItem = {
        ...value,
        filters: [],
      };

      localStorage.setItem(key, JSON.stringify(resetFiltersItem));
    });

    resetErrorBoundary();
    setSearchParams('');
  };

  return (
    <div className={ErrorFallbackStyles.Wrapper}>
      <div className={ErrorFallbackStyles.Info}>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
      <div>
        <Button
          size="small"
          onClick={resetErrorBoundary}
          style={{ marginRight: '10px' }}
        >
          Try again
        </Button>
        <Button size="small" onClick={resetFilters}>
          Reset filters
        </Button>
      </div>
    </div>
  );
};
