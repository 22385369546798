import { ActionTypes } from '../constants';
import { IStateSymbolPermissions, TSymbolPermissionsPayload } from '../types';

export function filtersWithExpiredSet(
  state: IStateSymbolPermissions,
  payload: TSymbolPermissionsPayload[ActionTypes.FILTERS_WITH_EXPIRED_SET],
) {
  return {
    ...state,
    filters: {
      ...state.filters,
      withExpired: payload,
    },
  };
}
