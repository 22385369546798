import { uniq } from 'lodash';
import { TestContext, ValidationError } from 'yup';

import { getValueFromOption, findAllIndexes } from '~/shared/utils';

import { IClientUsersProps, IGroupErrors } from './types';

export const validateClientUsers = (
  values: IClientUsersProps[],
  { path, createError }: TestContext,
) => {
  const userIDs = values.map((item) => getValueFromOption(item.userId));

  const emptyIndexes: IGroupErrors[] = userIDs.flatMap((value, index) => {
    if (!value) {
      return {
        index: value ? -1 : index,
        message: 'Field can not be empty',
        path: `${path}.${index}.userId`,
      };
    }

    return [];
  });

  const indexes = userIDs.reduce<number[][]>((acc, curr) => {
    if (!curr) {
      return [...acc];
    }

    const allKeyIndexes = findAllIndexes(userIDs, curr);

    if (allKeyIndexes.length === 1) {
      return [...acc];
    }

    return [...acc, allKeyIndexes];
  }, []);

  const duplicateIndexes: IGroupErrors[] = uniq(
    indexes.reduce((acc, curr) => acc.concat(curr), []),
  ).map((value) => ({
    index: value,
    message: 'Field should be unique',
    path: `${path}.${value}.userId`,
  }));

  const groupErrors = emptyIndexes.concat(duplicateIndexes);

  if (groupErrors.length) {
    return new ValidationError(groupErrors.map(createError));
  }

  return true;
};
