import { useCallback, useEffect, useState } from 'react';

import { getElementHeight, getStylePropValue } from './helpers';

const MIN_EXPANDED_TABLE_HEIGHT = 400;
const TABLE_CONTENT_SCROLL_HEIGHT = 15;

export interface IVirtualized {
  height: number;
  width: number;
  itemSize: number;
}

const ROW_HEIGHT = 32;

export const useTableVirtualized = (
  element: HTMLElement | null,
  tableHeaderHeight = 48,
): {
  virtualized: IVirtualized;
  updateTableSizes: () => void;
} => {
  const [tableHeight, setTableHeight] = useState(MIN_EXPANDED_TABLE_HEIGHT);
  const [tableWidth, setTableWidth] = useState(0);

  const updateTableSizes = useCallback(() => {
    if (!element) {
      return;
    }

    const headerRow = element.querySelector("[data-test-id='table__content']");

    const applicationPaddingBottom = getStylePropValue(
      "[class^='ApplicationWrapper_Wrapper']",
      'padding-bottom',
    );
    const mainPaddingBottom = getStylePropValue(
      '#webbo-main',
      'padding-bottom',
    );
    const panelPaddingBottom = getStylePropValue(
      'section.Panel',
      'padding-bottom',
    );
    const footerVersionContainerHeight = getElementHeight(
      "[class^='VersionContainer_version']",
    );

    if (!headerRow) {
      return;
    }

    const top = headerRow.getBoundingClientRect().top;

    const rowGroupHeight =
      window.innerHeight -
      top -
      tableHeaderHeight -
      TABLE_CONTENT_SCROLL_HEIGHT -
      applicationPaddingBottom -
      mainPaddingBottom -
      panelPaddingBottom -
      footerVersionContainerHeight;

    setTableWidth(headerRow.clientWidth);
    setTableHeight(rowGroupHeight);
  }, [element, tableHeaderHeight]);

  const virtualized = {
    height: tableHeight,
    width: tableWidth,
    itemSize: ROW_HEIGHT,
  };

  useEffect(() => {
    updateTableSizes();

    window.addEventListener('resize', updateTableSizes);
    window.addEventListener('load', updateTableSizes);

    return () => {
      window.removeEventListener('resize', updateTableSizes);
      window.removeEventListener('load', updateTableSizes);
    };
  }, [updateTableSizes]);

  return { virtualized, updateTableSizes };
};
