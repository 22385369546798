import { TCurrency } from '~/types/currencies';

import { hasIcon } from '../../helpers';
import { useCurrenciesMap } from '../../hooks/useCurrenciesMap';

import CurrencyCellStyles from './CurrencyCell.module.css';

interface ICurrencyCellProps {
  value: TCurrency;
}

export const CurrencyCell = ({ value }: ICurrencyCellProps) => {
  const { getCurrency } = useCurrenciesMap();
  const currencyData = getCurrency(value);
  const currencyShortName = value.slice(0, 2).toUpperCase();

  return (
    <span className={CurrencyCellStyles.Cell}>
      {hasIcon(currencyData) ? (
        <img
          src={currencyData.icon}
          alt="currency"
          className={CurrencyCellStyles.Icon}
        />
      ) : (
        <span className={CurrencyCellStyles.FallbackIcon}>
          {currencyShortName}
        </span>
      )}
      <span className={CurrencyCellStyles.Text}>{value}</span>
    </span>
  );
};
