import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Notification } from 'react-ui-kit-exante';

import { usePostTradesMutation } from '~/api';
import {
  useAttachCSV,
  useBack,
  useCurrentUserHasAllBrandingPermission,
  useDeepCompareEffect,
} from '~/hooks';
import { TRADES_PATH } from '~/routes';
import { sendNotification } from '~/shared/utils';
import {
  getInputFileText,
  getRowPropsHelperTrades,
} from '~/shared/utils/bulkImport';
import { getUploadActionsTrades } from '~/shared/utils/bulkImport/getUploadActionsTrades';
import {
  TTradeImportColumn,
  ITradeCSV,
  ITradePostRequest,
} from '~/types/trades';

import { TradeImport } from './TradeImport';
import { COLUMNS_CONFIG } from './constants';

const notifications = {
  success: 'All trades were uploaded successfully',
  fail: 'Some trades were not uploaded',
};

export const TradeImportPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    disableImportButtonWhenHasErrors,
    setDisableImportButtonWhenHasErrors,
  ] = useState(false);

  const [allTradesUploadSuccessfully, setAllTradesUploadSuccessfully] =
    useState(false);
  const [errors, setErrors] = useState<boolean[]>([]);
  const [postTrade] = usePostTradesMutation();
  const { tableData, columns, handleInputFile, duplicateIndexes } =
    useAttachCSV<TTradeImportColumn, ITradeCSV>(COLUMNS_CONFIG);

  const hasErrors = useMemo(() => errors.some(Boolean), [errors]);
  const { isLoadingUser, currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();
  const navigate = useNavigate();

  const resetErrors = () => {
    setErrors([]);
  };

  const handleUpload = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const onError = (index: number, error: unknown) => {
      if (error instanceof Error) {
        errors[index] = true;
      }
    };

    const handleImportTrades = async (trades: ITradePostRequest[]) => {
      const result = await Promise.all(
        trades.map((trade, index) =>
          postTrade({
            params: trade,
            onError: onError.bind(null, index),
          }),
        ),
      );

      const importErrors = result.map((res) => Boolean('error' in res));

      const success = !new Set(importErrors).has(true);

      return { errors: importErrors, allTradesUploadSuccessfully: success };
    };

    const data = await handleImportTrades(tableData);

    setErrors(data.errors);
    setAllTradesUploadSuccessfully(data.allTradesUploadSuccessfully);
    setDisableImportButtonWhenHasErrors(!data.allTradesUploadSuccessfully);

    if (data.allTradesUploadSuccessfully) {
      sendNotification(notifications.success, 'success');
    } else {
      sendNotification(notifications.fail, 'error');
    }

    setIsLoading(false);
  }, [isLoading, errors, postTrade, tableData]);

  const wrappedHandleInputFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAllTradesUploadSuccessfully(false);
    setDisableImportButtonWhenHasErrors(false);
    resetErrors();
    handleInputFile(event);
  };

  const getRowProps = useCallback(
    ({ index }: { index: number }) =>
      getRowPropsHelperTrades(
        errors,
        hasErrors,
        index,
        allTradesUploadSuccessfully,
      ),
    [allTradesUploadSuccessfully, errors, hasErrors],
  );

  const displayTable = Boolean(columns.length);
  const inputFileText = useMemo(
    () =>
      getInputFileText(displayTable, allTradesUploadSuccessfully, hasErrors),
    [allTradesUploadSuccessfully, displayTable, hasErrors],
  );

  const additionalActions = useMemo(
    () =>
      getUploadActionsTrades(
        handleUpload,
        hasErrors,
        allTradesUploadSuccessfully,
        isLoading || disableImportButtonWhenHasErrors,
        'Upload trades',
      ),
    [
      allTradesUploadSuccessfully,
      disableImportButtonWhenHasErrors,
      handleUpload,
      hasErrors,
      isLoading,
    ],
  );

  const handleCloseImport = useBack({ defaultPath: TRADES_PATH });

  useEffect(() => {
    if (!isLoadingUser && !currentUserHasAllBrandingPermission) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [isLoadingUser, navigate, currentUserHasAllBrandingPermission]);

  useDeepCompareEffect(() => {
    if (duplicateIndexes.length) {
      const startWarningString =
        duplicateIndexes.length === 1
          ? `Row ${duplicateIndexes[0]} has duplicate`
          : `Rows ${duplicateIndexes.join(', ')} has duplicates`;

      Notification.warning({
        title: `${startWarningString} that have been removed`,
      });
    }
  }, [duplicateIndexes]);

  if (isLoadingUser) {
    return <Loader />;
  }

  return (
    <TradeImport
      additionalActions={additionalActions}
      onInputFile={wrappedHandleInputFile}
      columns={columns}
      displayTable={displayTable}
      getRowProps={getRowProps}
      inputFileText={inputFileText}
      isLoading={isLoading}
      tableData={tableData}
      onClose={handleCloseImport}
    />
  );
};
